import { Checkbox, Form, Input, Modal } from 'antd';
import { Store } from 'antd/lib/form/interface';
import { observer } from 'mobx-react';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MODAL_UPDATE } from '@source/common';

import { UISelect } from '~components/UI/Select/UISelect';
import { CustomerTypeInput } from '~graphql/_sdk';

import store from './store';

const { Item, useForm } = Form;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 }
};

const CustomerTypeForm: FC = observer(() => {
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);

  const { profiles, selectedItem, isModalVisible, toggleModal, create, update, modalType } = store;

  const [form] = useForm();

  const initialValues = {
    name: selectedItem?.name,
    description: selectedItem?.description,
    defaultProfileId: selectedItem?.defaultProfileId,
    isDefault: selectedItem?.isDefault
  };

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [selectedItem]);

  const onCancel = () => {
    form.resetFields();
    toggleModal();
  };

  const submit = async (record: Store) => {
    if (!selectedItem) {
      record.code = record.name;
      await create(record as CustomerTypeInput);
    } else {
      await update(record as CustomerTypeInput);
    }
  };

  return (
    <Modal
      title={t('CUSTOMER_TYPE')}
      visible={isModalVisible}
      cancelText={t('CLOSE')}
      onCancel={onCancel}
      okText={modalType === MODAL_UPDATE ? t('UPDATE') : t('CREATE')}
      okButtonProps={{
        type: 'primary',
        form: 'customerTypeForm',
        htmlType: 'submit'
      }}
      onOk={() => {
        setSubmitting(true);
        form
          .validateFields()
          .then(async (record) => {
            await submit(record);
            form.resetFields();
            setSubmitting(false);
            toggleModal();
          })
          .catch((info) => {
            setSubmitting(false);
          });
      }}
      maskClosable={false}
      forceRender
      confirmLoading={submitting}
    >
      <Form {...layout} form={form} name="customerTypeForm">
        <Item
          label={t('NAME')}
          name="name"
          rules={[{ required: true, message: 'Name is required' }]}
        >
          <Input placeholder="Name of the customer type" />
        </Item>
        <Item label={t('DESCRIPTION')} name="description">
          <Input placeholder="Discription of the customer type" />
        </Item>
        <Item
          label={t('PROFILE_DEFAULT')}
          name="defaultProfileId"
          rules={[{ required: true, message: 'Profile is required' }]}
        >
          <UISelect
            dataOptions={{ data: profiles, textField: 'display', valueField: 'profileId' }}
            placeholder="Profile attached to customer type"
          />
        </Item>
        <Item {...tailLayout} name="isDefault" valuePropName="checked">
          <Checkbox>When register you will have this customer type</Checkbox>
        </Item>
      </Form>
    </Modal>
  );
});

export default CustomerTypeForm;
