import { Button, List } from 'antd';
import { uniqBy } from 'lodash';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import store from '../store';

const BannerInfo: FC = observer(() => {
  const { campaignInfo, preview } = store;

  const data = uniqBy(
    campaignInfo?.config?.banners?.map(item => ({
      title: item.bannerName,
      id: item.bannerId
    })),
    'id'
  );

  return (
    <List
      itemLayout="horizontal"
      dataSource={data}
      renderItem={item => (
        <List.Item>
          <List.Item.Meta
            title={
              <Button style={{ padding: 0 }} type="link" onClick={() => preview(item.id)}>
                {item.title}
              </Button>
            }
          />
        </List.Item>
      )}
    />
  );
});
export default BannerInfo;
