import { Button, Descriptions, PageHeader } from 'antd';
import { observer } from 'mobx-react';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { PlusOutlined } from '@ant-design/icons';
import { PENDING, REJECTED, ROLE_CREATE_PAYMENT, VERIFIED } from '@source/common';

import Authorized from '~components/Authorized/Authorized';
import { UISelect } from '~components/UI/Select/UISelect';
import { useStore } from '~context/react-context';
import { isMobile } from '~utils/isMobile';
import { upperCaseFirstLetter } from '~utils/upperCaseFirstLetter';

import store from './store';

const PaymentHeader: FC = observer(() => {
  const { t } = useTranslation();
  const { toggleModal, setUserSelectOption, userSelectOption, setWhere, resetStore } = store;

  const { commonDataStore } = useStore();

  useEffect(() => {
    if (commonDataStore.users && commonDataStore.users.length > 0) {
      setUserSelectOption(commonDataStore.users);
    } else {
      commonDataStore.loadAllUser();
    }

    return () => resetStore();
  }, [commonDataStore.users]);

  const template = (item) => {
    return (
      <Descriptions column={1} style={{borderBottom: '1px solid #adabab'}}>
        <Descriptions.Item span={1} label="Name">
          {item.name}
        </Descriptions.Item>
        <Descriptions.Item span={1} label="Email">
          {item.email}
        </Descriptions.Item>
      </Descriptions>
    );
  };

  const Filter = (
    <>
      <UISelect
        dataOptions={{
          data: userSelectOption,
          valueField: '_id',
          textField: 'name',
          // template: (item) => template(item)
        }}
        placeholder={t('TYPE_TO_SEARCH_USER')}
        style={{ width: 200 }}
        onChange={(value) => setWhere({ userId: value })}
        allowClear
      />
      <UISelect
        dataOptions={{
          data: [REJECTED, VERIFIED, PENDING].map((e) => ({
            value: e,
            text: upperCaseFirstLetter(e)
          })),
          valueField: 'value',
          textField: 'text'
        }}
        placeholder="Status"
        onChange={(value) => setWhere({ status: value })}
        style={{ width: 200, marginLeft: 10 }}
        allowClear
      />
    </>
  );

  return (
    <PageHeader
      style={{ background: '#fff', marginBottom: 0 }}
      title={Filter}
      extra={[
        <Authorized roles={[ROLE_CREATE_PAYMENT]} key="create">
          <Button type="primary" icon={<PlusOutlined />} onClick={() => toggleModal()}>
            {isMobile() ? '' : t('CREATE')}
          </Button>
        </Authorized>
      ]}
    />
  );
});

export default PaymentHeader;
