import { DatePicker } from 'antd';
import { RangePickerDateProps } from 'antd/lib/date-picker/generatePicker';
import moment, { Moment } from 'moment';
import React, { Component } from 'react';

import { Without } from '@source/common';

const { RangePicker } = DatePicker;

export interface UIDateRangePickerProps
  extends Without<RangePickerDateProps<Moment>, 'value' | 'onChange'> {
  value?: [Moment, Moment];
  onChange?: (value: number[]) => any;
  customRange?: { [field: string]: any };
}

export class UIDateRangePicker extends Component<UIDateRangePickerProps> {
  private onChange = (dates: Moment[]) => {
    const { onChange } = this.props;
    if (typeof onChange === 'function') {
      const newValues = dates ? dates.map((date) => date && date.valueOf()) : [];
      onChange(newValues);
    }
  };

  public render() {
    const { value, customRange, ...props } = this.props;
    const ranges = customRange || {
      'Hôm nay': [moment().startOf('day'), moment().endOf('day')],
      'Hôm qua': [
        moment().startOf('day').subtract(1, 'day'),
        moment().endOf('day').subtract(1, 'day')
      ],
      'Tuần này': [moment().startOf('week'), moment().endOf('week')],
      'Tháng này': [moment().startOf('month'), moment().endOf('month')],
      'Tháng trước': [
        moment().subtract(1, 'months').startOf('month'),
        moment().subtract(1, 'months').endOf('month')
      ]
    };

    const newValues = value
      ? value.map((date) => {
          return date && moment(date);
        })
      : [];

    return (
      <RangePicker
        style={{ width: '100%' }}
        {...props}
        ranges={ranges}
        value={newValues as [Moment, Moment]}
        onChange={this.onChange}
      />
    );
  }
}
