import { Col, Row } from 'antd';
import React, { FC, useEffect } from 'react';

import { commonDataStore } from '~common-stores/store-index';

import Form from './Form';
import Grid from './Grid';
import Header from './Header';
import store from './store';

const CampaignsScreen: FC = () => {
  useEffect(() => {
    commonDataStore.loadCommonPartnerOwner();
    commonDataStore.loadCampaignType();
    return () => store.reset();
  }, []);
  return (
    <>
      <Row style={{ flexDirection: 'column', flexWrap: 'nowrap', height: '100%' }}>
        <Col flex="auto"><Header /></Col>
        <Col flex={1}><Grid /></Col>
      </Row>
      <Form />
    </>
  );
};

export default CampaignsScreen;
