import { Card, Col, Row } from 'antd';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { formatNumber } from '~utils/formatNumber';

import store from './store';

const { Meta } = Card;
const CardHotspotSummary: FC = observer(() => {
  const { newEquipment, totalEquipment, reportDevice, hotspotOnOff } = store;
  const { t } = useTranslation();

  return (
    <Row
      gutter={16}
      //  justify="space-between"
    >
      <Col className="gutter-row" xs={24} sm={12} md={8}>
        <Card
          size="small"
          style={{
            backgroundColor: 'white',
            borderLeft: `10px solid #0078D4`,
            borderColor: '#0078D4'
          }}
          key="new-total"
        >
          <Meta
            avatar={
              <i className="fas fa-eye" style={{ color: '#0078D4', backgroundColor: 'white' }} />
            }
            title={`${t('dashboard.HOTSPOT_NEW')} / ${t('dashboard.HOTSPOT_TOTAL')}`}
            description={
              <div style={{ color: '#0078D4', textAlign: 'right', fontSize: '1.2rem' }}>
                {formatNumber(newEquipment)} / {formatNumber(totalEquipment)}
              </div>
            }
          />
        </Card>
      </Col>
      <Col className="gutter-row" xs={24} sm={12} md={8}>
        <Card
          size="small"
          style={{
            backgroundColor: 'white',
            borderLeft: `10px solid #0078D4`,
            borderColor: '#0078D4'
          }}
          key="on-off"
        >
          <Meta
            avatar={
              <i className="fas fa-eye" style={{ color: '#0078D4', backgroundColor: 'white' }} />
            }
            title={`${t('dashboard.HOTSPOT_ON')} / ${t('dashboard.HOTSPOT_OFF')}`}
            description={
              <div style={{ color: '#0078D4', textAlign: 'right', fontSize: '1.2rem' }}>
                {formatNumber(hotspotOnOff?.on)} / {formatNumber(hotspotOnOff?.total - hotspotOnOff?.on)}
                {/* {t('UPDATING')} */}
              </div>
            }
          />
        </Card>
      </Col>
      <Col className="gutter-row" xs={24} sm={12} md={8}>
        <Card
          size="small"
          style={{
            backgroundColor: 'white',
            borderLeft: `10px solid #0078D4`,
            borderColor: '#0078D4'
          }}
          key="unique-user"
        >
          <Meta
            avatar={
              <i className="fas fa-eye" style={{ color: '#0078D4', backgroundColor: 'white' }} />
            }
            title={`${t('dashboard.UNIQUE_DEVICE')}`}
            description={
              <div style={{ color: '#0078D4', textAlign: 'right', fontSize: '1.2rem' }}>
                {formatNumber(reportDevice?.totalDevice || 0)}
              </div>
            }
          />
        </Card>
      </Col>
    </Row>
  );
});

export default CardHotspotSummary;
