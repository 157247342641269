import { Card, Col, Result, Row, Spin, Tabs } from 'antd';
import { isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import React, { FC, useEffect } from 'react';

import { BarChartOutlined, PieChartOutlined } from '@ant-design/icons';

import { upperCaseFirstLetter } from '~utils/upperCaseFirstLetter';

import store from '../store';
import ColumnChart from './ColumnChart';
import PieChart from './PieChart';

const ReportCRM: FC = observer(() => {
  const { getTrackingSurvey, result, loading, campaignId } = store;
  const { TabPane } = Tabs;

  useEffect(() => {
    getTrackingSurvey();
  }, [campaignId]);

  const renderChart = () => {
    return result.map((value, index) => {
      return (
        <Col key={index.toString()} span={12}>
          <Card
            title={upperCaseFirstLetter(value.name)}
            key={index.toString()}
            style={{ marginTop: 16 }}
          >
            <Tabs type="card">
              <TabPane tab={<BarChartOutlined />} key="1">
                <ColumnChart idx={index} />
              </TabPane>
              <TabPane tab={<PieChartOutlined />} key="2">
                <PieChart idx={index} />
              </TabPane>
            </Tabs>
          </Card>
        </Col>
      );
    });
  };

  return (
    <Row gutter={16}>
      {loading ? (
        <Spin spinning style={{ paddingLeft: '40%' }} tip="Please wait a minute..." />
      ) : (
        renderChart()
      )}
    </Row>
  );
});
export default ReportCRM;
