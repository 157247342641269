import {
  debounce,
  flatMap,
  groupBy,
  isEmpty,
  meanBy,
  omit,
  set,
  sumBy,
  throttle,
  uniq,
  uniqBy
} from 'lodash';
import { action, computed, observable, reaction, runInAction, toJS } from 'mobx';
import { actionAsync, task } from 'mobx-utils';
import moment from 'moment';

import { GridReadyEvent } from '@ag-grid-community/core';
import {
  ADVERTISING,
  APPROVED,
  CREATED,
  DEFAUTL,
  DEMO,
  DeviceType,
  DISAPPROVED,
  FINISHED,
  LICENSE,
  MGN,
  OsType,
  PARTNER,
  RUNNING,
  UNLIMITED,
  WhereArgs
} from '@source/common';

import { commonDataStore } from '~common-stores/store-index';
import { ErrorNotify } from '~components/UI/Notification';
import { getConfig } from '~config/index';
import {
  CampaignLocation,
  CampaignPayload,
  CampaignSurveyFilter,
  CommonCampaignFragment,
  DateArgs,
  FullCommonFragment,
  GetReportTrackingDetailQueryVariables,
  ReportCampaignByDate,
  ReportCampaignInfo,
  SurveyTracking,
  TrackingDetailExternalFilter,
  TrackingDetailInternalFilter
} from '~graphql/_sdk';
import { getSdk } from '~graphql/sdk';

export interface ReportFilterSelectOption {
  value: string;
  text: string;
}

interface ReportFilter {
  categoryId?: string[];
  locationId?: string[];
  bannerId?: string[];
  date: {
    gte: any;
    lte: any;
  };
  tag?: string[];
  locationGroup?: string[];
}

export type CampaignCount = {
  total?: number;
  running?: number;
  ads?: { name: 'Ads'; value: number };
  default?: { name: 'Default'; value: number };
  partner?: { name: 'Partner'; value: number };
  demo?: { name: 'Demo'; value: number };
  finish?: number;
};

const filterValueMapping = {
  'ua.device': DeviceType,
  'ua.os': OsType
};

const ninCampaignStatus = [APPROVED, DISAPPROVED, CREATED];

class Store {
  @observable public loading: boolean;
  @observable public drawerVisible: boolean;
  @observable public total: number;
  @observable public osData;
  @observable public deviceData;
  @observable public campaignId: string;
  @observable public filter: WhereArgs;
  @observable public enableVisit: boolean;
  @observable public chartData: ReportCampaignByDate[];

  @observable public ownerType: string;
  @observable public campaigns: CampaignPayload[];
  @observable public campaignCount: CampaignCount;

  @observable public campaignInfo: ReportCampaignInfo;
  @observable public selectedCategory: string;
  @observable public selectedTab: string;

  @observable public locationOptions: ReportFilterSelectOption[];
  @observable public bannerOptions: ReportFilterSelectOption[];
  @observable public categoryOptions: ReportFilterSelectOption[];
  @observable public tagOptions: ReportFilterSelectOption[];
  @observable public locationGroupOptions: ReportFilterSelectOption[];
  @observable public categoryMap: { [field: string]: CampaignLocation[] };
  @observable public tagMap: { [field: string]: CampaignLocation[] };
  @observable public locationGroupMap: { [field: string]: CampaignLocation[] };

  @observable public reportFilter: ReportFilter;

  @observable public crmFilter: CampaignSurveyFilter;
  @observable public result: SurveyTracking[];

  private tags: FullCommonFragment[];

  constructor() {
    this.reset();

    reaction(
      () => this.campaignId,
      cId => cId && this.getReportCampaignInfo(cId)
    );
    runInAction(() => {
      this.result = [];
    });
  }

  @action
  public setOwnerType = (ownerType: string) => {
    this.ownerType = ownerType;
    runInAction(() => {
      if (!this.ownerType) {
        this.onCampaignFilterChange('ownerType', [MGN, LICENSE]);
      } else {
        this.onCampaignFilterChange('ownerType', [this.ownerType]);
      }
    });
  };

  @action
  public reset = () => {
    this.filter = {
      _operators: {
        status: {
          nin: ninCampaignStatus
        }
      }
    };
    this.loading = false;
    this.drawerVisible = false;
    this.campaignCount = {};
    this.onBack();
  };

  @action
  public onBack = () => {
    this.tags = [];
    this.locationOptions = [];
    this.campaignId = null;
    this.bannerOptions = [];
    this.categoryMap = {};
    this.campaignInfo = null;
    this.tagMap = {};
    this.locationGroupMap = {};
    this.reportFilter = {
      date: {
        gte: moment()
          .startOf('day')
          .toISOString(),
        lte: moment()
          .endOf('day')
          .toISOString()
      }
    };
    this.chartData = [];

    this.crmFilter = {
      exists: [],
      not_exists: [],
      inq: {},
      nin: {},
      survey: {},
      range: {}
    };
    this.loading = false;
    if (this.filter._search) {
      delete this.filter._search;
    }
  };

  @computed
  public get runningCampaignCount() {
    return Object.keys(this.campaignCount)
      .reduce((all, key) => {
        if (typeof this.campaignCount[key] === 'object') {
          all.push(this.campaignCount[key]);
        }
        return all;
      }, [])
      .filter(i => i.value !== 0);
  }

  @computed
  public get totalChartData() {
    const click = sumBy(this.chartData, 'click');
    const impression = sumBy(this.chartData, 'impression');
    return {
      click,
      impression,
      ctr: Number((click / (impression || 1)) * 100).toFixed(2)
    };
  }

  @computed
  public get statusFilter() {
    return this.filter._operators?.status?.in?.slice() || [];
  }

  @computed
  public get typeFilter() {
    return this.filter._operators?.type?.in?.slice() || [];
  }

  @computed
  public get dateFilter() {
    return [this.filter._operators?.scheduleFrom?.gte, this.filter._operators?.scheduleTo?.lte];
  }

  @computed
  public get dateReportFilter() {
    return [this.reportFilter.date?.gte, this.reportFilter.date?.lte];
  }

  @computed
  public get categoryReportFilter() {
    return this.reportFilter.categoryId?.slice();
  }

  @computed
  public get locationReportFilter() {
    return this.reportFilter.locationId?.slice();
  }

  @computed
  public get bannerReportFilter() {
    return this.reportFilter.bannerId?.slice();
  }

  @computed
  public get tagReportFilter() {
    return this.reportFilter.tag?.slice();
  }

  @computed
  public get locationGroupReportFilter() {
    return this.reportFilter.locationGroup?.slice();
  }

  @computed
  public get exportFilter() {
    const filter = {
      ...this.reportFilter,
      campaignId: this.campaignId
    };

    if (!isEmpty(this.reportFilter.categoryId)) {
      const locationId = [];
      this.reportFilter.categoryId.forEach(cates => {
        this.categoryMap[cates].forEach(loc => {
          locationId.push(loc.locationId);
        });
      });
      filter.locationId = locationId;
      delete filter.categoryId;
    }
    return filter;
  }

  public async preview(bannerId: string, redirect = 'https://google.com') {
    const { restApiEndpoint } = await getConfig();
    const params = [`bannerId=${bannerId}`, redirect && `redirect=${encodeURIComponent(redirect)}`]
      .filter(Boolean)
      .join('&');
    window.open(`${restApiEndpoint}/renderer/preview?${params}`, '_blank');
  }

  @action
  setDrawerVisible = () => {
    this.drawerVisible = !this.drawerVisible;
  };

  @action
  public setTab = (value: string) => {
    this.selectedTab = value;
    this.activeQuery(value);
  };

  private activeQuery = (tab: string) => {
    switch (tab) {
      case 'detail':
        this.reloadGridDetail();
        break;
      case 'mac':
        this.reloadGridMac();
        break;
      case 'chart':
        this.getReportCampaignStatistic();
        break;
      case 'os-de':
        this.getReportCampaignOsDevice();
        break;
      default:
        return null;
    }
  };

  @action
  public setEnableVisit = (value: boolean) => {
    this.enableVisit = value;
  };

  @action
  public getTotalReportCampaignSurvey = () => {
    return { countReportCampaignSurvey: this.total ? this.total : 0 };
  };

  @action
  public setCampaign = (campaignId: string) => {
    this.campaignId = campaignId;
    this.activeQuery(this.selectedTab);
  };

  @action
  public handleGridReady = ({ api }: GridReadyEvent) => {
    this.reload = () => {
      api.onFilterChanged();
    };
  };

  @action
  public handleGridDetailReady = ({ api }: GridReadyEvent) => {
    this.reloadGridDetail = () => {
      api.onFilterChanged();
    };
  };

  private reloadGridDetail = () => undefined;

  @action
  public handleGridMacReady = ({ api }: GridReadyEvent) => {
    this.reloadGridMac = () => {
      api.onFilterChanged();
    };
  };

  @action
  reloadGridMac = () => undefined;

  @action
  public handleOnChangeCrmFilter = (field: string, value) => {
    let filter = toJS(this.crmFilter);

    if (isEmpty(value)) {
      delete filter.inq[field];
      delete filter.nin[field];
      filter = omit(filter, [field]);

      ['exists', 'not_exists'].forEach(e => {
        filter[e] = filter[e].filter(item => item !== field);
      });

      this.crmFilter = filter;
      this.onCrmFilterChange(this.selectedTab);
      return;
    }

    if (field.includes('survey')) {
      set(filter, field, Array.isArray(value) ? value : [value]);
      this.crmFilter = filter;
      this.onCrmFilterChange(this.selectedTab);
      return;
    }

    ['exists', 'not_exists'].forEach(e => {
      filter[e] = filter[e].filter(item => item !== field);
    });

    delete filter.inq[field];
    delete filter.nin[field];

    if (['exists', 'not_exists'].indexOf(value.toString()) >= 0) {
      filter[value].push(field);
      this.crmFilter = filter;
      this.onCrmFilterChange(this.selectedTab);
      return;
    }

    if (value.toString().includes('Other')) {
      filter.nin[field] = filterValueMapping[field].filter(item => item !== 'Other');
      this.crmFilter = filter;
      this.onCrmFilterChange(this.selectedTab);
      return;
    }

    filter.inq[field] = value;
    this.crmFilter = filter;

    this.onCrmFilterChange(this.selectedTab);
  };

  private onCrmFilterChange = throttle(
    (tab: string) => {
      if (tab === 'os-de') {
        this.getReportCampaignOsDevice();
      }
      if (tab === 'mac') {
        this.reloadGridMac();
      }
    },
    500,
    { leading: false, trailing: true }
  );

  @actionAsync
  public getReportCampaignInfo = async (campaignId: string) => {
    this.loading = true;
    const sdk = await task(getSdk());
    const {
      getReportCampaignInfo: { error, data }
    } = await task(sdk.getReportCampaignInfo({ campaignId }));

    if (error) {
      ErrorNotify(error.code);
    }
    if (data) {
      this.campaignInfo = data;
      this.initReportFilterSelect(data);
    }

    this.loading = false;
  };

  @computed
  get getCampaignSurveyFilter() {
    const filter = toJS(this.crmFilter);

    if (this.reportFilter.categoryId) {
      filter.inq['meta.locationId'] = this.locationOptions.map(i => i.value);
    }
    if (this.reportFilter.bannerId) {
      filter.inq['meta.bannerId'] = this.reportFilter.bannerId;
    }
    if (this.reportFilter.locationId) {
      filter.inq['meta.locationId'] = this.reportFilter.locationId;
    }

    filter.range.createdAt = this.reportFilter.date;

    return filter;
  }

  @actionAsync
  public getReportCampaignSurvey = async ({ skip = 0 }) => {
    if (!this.campaignInfo) return { getReportCampaignSurvey: [] };

    const sdk = await task(getSdk());

    const filter = this.getCampaignSurveyFilter;

    const {
      getReportCampaignSurvey: { data, total, error }
    } = await task(
      sdk.getReportCampaignSurvey({
        campaignId: this.campaignId,
        filter,
        skip
      })
    );

    if (error) return [];

    this.total = total;

    return { getReportCampaignSurvey: data };
  };

  @actionAsync
  public getReportCampaignOsDevice = async () => {
    if (!this.campaignInfo) return;

    const sdk = await task(getSdk());

    const filter = this.getCampaignSurveyFilter;

    filter.range.createdAt = this.reportFilter.date;

    const {
      getReportCampaignOsDevice: { os, device, error }
    } = await task(
      sdk.getReportCampaignOsDevice({
        campaignId: this.campaignId,
        filter
      })
    );

    if (error) {
      ErrorNotify(error.code);
    }

    this.osData = os;
    this.deviceData = device;
  };

  @actionAsync
  public getReportCampaignStatistic = async () => {
    const sdk = await task(getSdk());

    const filter = { ...this.reportFilter, campaignId: this.campaignId };

    const {
      getReportCampaignStatistic: { byDate, error }
    } = await task(sdk.getReportCampaignStatistic(filter));

    if (error) {
      ErrorNotify(error.code);
    }
    this.chartData = byDate;
  };

  public parseExternalFilter = () => {
    const externalFilter = {};

    if (!isEmpty(this.reportFilter.locationId)) {
      Object.assign(externalFilter, { locationIds: this.reportFilter.locationId });
    }

    if (!isEmpty(this.reportFilter.categoryId)) {
      Object.assign(externalFilter, { categoryIds: this.reportFilter.categoryId });
    }

    if (!isEmpty(this.reportFilter.bannerId)) {
      Object.assign(externalFilter, { bannerIds: this.reportFilter.bannerId });
    }

    return externalFilter;
  };

  @actionAsync
  public getReportTrackingDetail = throttle(async ({ rowGroupCols = [], groupKeys = [] }) => {
    if (!this.campaignInfo) return { getReportTrackingDetail: [] };
    const sdk = await task(getSdk());

    let internalFilter: TrackingDetailInternalFilter = { campaignId: this.campaignId };

    const externalFilter: TrackingDetailExternalFilter = this.parseExternalFilter();

    const filter: any = {};
    const keys = [];

    rowGroupCols.forEach((e, i) => {
      filter[e.id] = groupKeys[i];
      keys.push(e.id);
    });

    internalFilter = {
      ...internalFilter,
      ...filter
    };

    let params: GetReportTrackingDetailQueryVariables = {
      internalFilter,
      date: this.reportFilter.date
    };

    if (!isEmpty(externalFilter)) {
      params = { ...params, externalFilter };
    }

    Object.keys(internalFilter).forEach(key => {
      if (Array.isArray(internalFilter[key]) && !internalFilter[key].length) {
        delete internalFilter[key];
      }
    });

    const { getReportTrackingDetail } = await task(sdk.getReportTrackingDetail(params));

    return getReportTrackingDetail;
  }, 100);

  @actionAsync
  public countReportTrackingDetail = async ({ rowGroupCols = [], groupKeys = [] }) => {
    if (!this.campaignInfo) return { countReportTrackingDetail: 0 };

    const sdk = await task(getSdk());

    let internalFilter: TrackingDetailInternalFilter = { campaignId: this.campaignId };

    const externalFilter: TrackingDetailExternalFilter = this.parseExternalFilter();

    const filter: any = {};
    const keys = [];

    rowGroupCols.forEach((e, i) => {
      filter[e.id] = groupKeys[i];
      keys.push(e.id);
    });

    internalFilter = {
      ...internalFilter,
      ...filter
    };

    let params: GetReportTrackingDetailQueryVariables = {
      internalFilter,
      date: this.reportFilter.date
    };

    if (!isEmpty(externalFilter)) {
      params = { ...params, externalFilter };
    }

    Object.keys(internalFilter).forEach(key => {
      if (Array.isArray(internalFilter[key]) && !internalFilter[key].length) {
        delete internalFilter[key];
      }
    });
    const { getReportTrackingDetail } = await task(sdk.getReportTrackingDetail(params));

    return { countReportTrackingDetail: getReportTrackingDetail.data?.length };
  };

  @computed
  public get getCrmSelectOptions() {
    const crm = this.campaignInfo?.config?.crm ?? {};
    const audience = [];
    const customs = [];

    Object.keys(crm).forEach(key => {
      if (key.includes('field')) {
        customs.push({ key, text: crm[key] });
      } else {
        audience.push({ key, text: crm[key] });
      }
    });

    return [audience, customs];
  }

  @computed
  public get getOsData() {
    if (!this.osData) return [];

    const keys = Object.keys(this.osData);

    const result = [];
    keys.forEach(k => {
      result.push({
        name: k,
        y: this.osData[k]
      });
    });
    return result;
  }

  @computed
  public get getDeviceData() {
    if (!this.deviceData) return [];

    const keys = Object.keys(this.deviceData);

    const result = [];
    keys.forEach(k => {
      result.push({
        name: k,
        y: this.deviceData[k]
      });
    });
    return result;
  }

  @action
  public initCampaignCount = (campaigns: CommonCampaignFragment[]) => {
    const result: CampaignCount = {
      total: campaigns.length,
      running: 0,
      ads: { name: 'Ads', value: 0 },
      default: { name: 'Default', value: 0 },
      partner: { name: 'Partner', value: 0 },
      demo: { name: 'Demo', value: 0 },
      finish: 0
    };

    campaigns.forEach(c => {
      if (c.status === RUNNING) {
        this.campaignCount.running = ~~result.running + 1;
        switch (c.type) {
          case DEFAUTL:
          case UNLIMITED:
            result.default.value = ~~result.default.value + 1;
            break;
          case ADVERTISING:
            result.ads.value = ~~result.ads.value + 1;
            break;
          case PARTNER:
            result.partner.value = ~~result.partner.value + 1;
            break;
          case DEMO:
            result.demo.value = ~~result.demo.value + 1;
            break;
          default:
            result.default.value = ~~result.default.value + 1;
            break;
        }
      }
      if (c.status === FINISHED) {
        result.finish = ~~result.finish + 1;
      }
    });
    this.campaignCount = result;
  };

  @action
  public onSelectCampaignChange = (campaignId: string) => {
    this.setCampaign(campaignId);
  };

  @action
  public onSelectReportFilterChange = (field: string, value: string[]) => {
    if (!value.length) {
      this.locationOptions = this.campaignInfo.locations.map(l => ({
        value: l.locationId,
        text: l.locationName
      }));
    } else {
      switch (field) {
        case 'categoryId':
          this.locationOptions = value
            .reduce((all, v) => {
              all.push(...this.categoryMap[v]);
              return all;
            }, [])
            .map(i => ({
              value: i.locationId,
              text: i.locationName
            }));
          delete this.reportFilter.locationGroup;
          delete this.reportFilter.tag;
          delete this.reportFilter.locationId;
          break;
        case 'locationGroup':
          this.locationOptions = value
            .reduce((all, v) => {
              all.push(...this.locationGroupMap[v]);
              return all;
            }, [])
            .map(i => ({
              value: i.locationId,
              text: i.locationName
            }));
          delete this.reportFilter.categoryId;
          delete this.reportFilter.tag;
          delete this.reportFilter.locationId;
          break;
        case 'tag':
          this.locationOptions = uniqBy(
            value.reduce((all, v) => {
              all.push(...this.tagMap[v]);
              return all;
            }, []),
            'locationId'
          ).map(i => ({
            value: i.locationId,
            text: i.locationName
          }));
          delete this.reportFilter.categoryId;
          delete this.reportFilter.locationGroup;
          delete this.reportFilter.locationId;
          break;
        default:
          return null;
      }
    }

    this.onReportFilterChange(field, value, this.selectedTab);
  };

  @action
  public onReportFilterChange = throttle(
    (field: string, value: any[], tab: string) => {
      switch (field) {
        case 'date':
          runInAction(() => {
            this.reportFilter.date = value.length
              ? {
                  gte: value[0],
                  lte: value[1]
                }
              : this.getReportDateFilter({
                  gte: this.campaignInfo.scheduleFrom,
                  lte: this.campaignInfo.scheduleTo
                });
          });
          break;
        default:
          runInAction(() => {
            this.reportFilter[field] = value && value.length ? value : undefined;
          });
      }

      this.activeQuery(tab);
    },
    500,
    { leading: false, trailing: true }
  );

  public onSearch = debounce(
    (value: string) => {
      runInAction(() => {
        this.filter._search = value;
      });

      this.reload();
    },
    500,
    { leading: false, trailing: true }
  );

  public onCampaignFilterChange = throttle(
    (field: string, value: any[]) => {
      switch (field) {
        case 'date':
          runInAction(() => {
            this.filter._operators.scheduleFrom = { gte: value[0] };
            this.filter._operators.scheduleTo = { lte: value[1] };
          });
          break;
        case 'status':
          runInAction(() => {
            Object.assign(this.filter._operators.status, { in: value.length ? value : undefined });
          });
          break;
        default:
          runInAction(() => {
            this.filter._operators[field] = {
              in: value && value.length ? value : undefined
            };
          });
      }
      this.reload();
    },
    400,
    { leading: false, trailing: true }
  );

  private reload = () => undefined;

  @action
  public initReportFilterSelect = (campaign: ReportCampaignInfo) => {
    this.bannerOptions =
      campaign.config?.banners?.map(l => ({
        value: l.bannerId,
        text: l.bannerName
      })) || [];

    const { locations, customs } = campaign;

    if (locations && locations.length) {
      this.locationOptions = locations.map(l => ({
        value: l.locationId,
        text: l.locationName
      }));
      this.categoryOptions = uniqBy(
        locations.map(i => ({
          value: i.categoryId,
          text: i.categoryName
        })),
        'value'
      );

      this.categoryMap = groupBy(locations, 'categoryId');

      const tags = uniq(flatMap(locations.map(l => l.tag)));
      if (tags.length) {
        this.tagOptions = tags.map(i => ({
          value: i,
          text: this.tags.find(t => t.value === i)?.text
        }));

        this.tagMap = tags.reduce((all, t) => {
          all[t] = locations.filter(l => l.tag && l.tag.includes(t));
          return all;
        }, {});
      }

      const locationGroups = uniq(locations.map(l => l.locationGroup));

      if (locationGroups.length) {
        this.locationGroupOptions = locationGroups.map(i => ({
          value: i,
          text: i
        }));

        this.locationGroupMap = locationGroups.reduce((all, lg) => {
          all[lg] = locations.filter(l => l.locationGroup === lg);
          return all;
        }, {});
      }
    }

    if (customs.group?.show && customs.group?.list.length) {
      this.locationOptions = customs.group?.list.map(i => ({
        text: i.groupName,
        value: i.groupName
      }));
    }

    this.reportFilter.date = this.getReportDateFilter({
      gte: campaign.scheduleFrom,
      lte: campaign.scheduleTo
    });
  };

  @action
  private getReportDateFilter = (date: DateArgs = { gte: undefined, lte: undefined }) => {
    let gte = date?.gte;
    let lte = date?.lte;

    if (!gte || !lte) {
      lte = moment().toISOString();
      gte = moment()
        .subtract(1, 'week')
        .toISOString();
    }

    return { gte, lte };
  };

  @actionAsync
  public getTrackingSurvey = async () => {
    const sdk = await task(getSdk());
    const { getTrackingSurvey } = await task(
      sdk.getTrackingSurvey({ campaignId: this.campaignId })
    );
    const { result } = getTrackingSurvey;
    this.result = result;
    this.loading = false;
  };

  @actionAsync
  public fetchCommonTag = async () => {
    const sdk = await task(getSdk());

    const { findManyCommon } = await sdk.findManyCommon({ where: { type: 'location_tag' } });

    this.tags = findManyCommon;
  };
}
export default new Store();
