import '@ant-design/compatible/assets/index.css';

import { Button, Col, InputNumber, Modal, Row } from 'antd';
import { css } from 'emotion';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

/* eslint-disable no-shadow */
import { Form } from '@ant-design/compatible';

import Header from '~components/UI/Header';
import { useStore } from '~context/react-context';
import { format } from '~utils/formatDate';

import store from './store';

const weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const date = css`
  height: 100px;
  border: 1px solid #f3f3f3;
  padding: 8px;
`;

interface Props {
  visible: boolean;
  setModalVisible: () => void;
}

const KPIRender: FC<Props> = observer(({ visible, setModalVisible }) => {
  const { t } = useTranslation();
  const { selectedItem, kpiData, updateByCalendar } = store;
  const [data, setData] = useState([]);
  const [KPI, setKPI] = useState({ click: 0, kpi: 0 });
  const [isEdit, setEdit] = useState(false);
  const { commonDataStore } = useStore();

  const locationMap = commonDataStore.locations.reduce((all, item) => {
    all[item._id] = item.name;
    return all;
  }, {});

  useEffect(() => {
    setData(kpiData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  useEffect(() => {
    const kpi = data.reduce((all, item) => {
      all += item.kpi || 0;
      return all;
    }, 0);
    const click = data.reduce((all, item) => {
      all += item.click || 0;
      return all;
    }, 0);

    setKPI({ kpi, click });
  }, [data]);

  const handleCancel = () => {
    setModalVisible();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    updateByCalendar(data);
  };

  const handleChangeKpi = (date, value) => {
    const index = data.findIndex((item) => item.date === date);

    if (index >= 0) {
      const obj = { ...data[index], kpi: value };
      const dataTmp = [...data.slice(0, index), obj, ...data.slice(index + 1, data.length)];
      setData(dataTmp);
    }
  };

  const cellRender = data.map((item, index) => {
    const isCompleted = moment(item.date).isAfter(moment());
    const dayOfWeek = moment(item.date).weekday();

    return (
      <Col key={String(index)} xs={8} md={4}>
        <div className={date} style={{ background: !isCompleted ? '#f3f3f3' : undefined }}>
          <div>
            <Row>
              <Col>
                <Header title={format(item.date, 'DD-MM')} />
              </Col>
              <Col>
                <div style={{ textAlign: 'left' }}>{weekdays[dayOfWeek]}</div>
              </Col>
            </Row>
          </div>
          <div style={{ textAlign: 'right' }}>
            {item.click || 0} /{' '}
            {isEdit && isCompleted ? (
              <InputNumber
                defaultValue={item.kpi}
                onChange={(value) => handleChangeKpi(item.date, value)}
              />
            ) : (
              item.kpi
            )}
          </div>
        </div>
      </Col>
    );
  });

  const footer = (
    <div>
      <Button type="text" ghost onClick={handleCancel}>
        {t('CLOSE')}
      </Button>
      <Button type="primary" ghost onClick={() => setEdit(!isEdit)}>
        {isEdit ? 'Save' : 'Edit'}
      </Button>
      <Button type="primary" htmlType="submit" form="kpiForm">
        {t('UPDATE')}
      </Button>
    </div>
  );

  return (
    <Modal
      title={`${locationMap[get(selectedItem, 'locationId', '')]} - ${KPI.click} / ${KPI.kpi}`}
      width="90%"
      visible={visible}
      closable={false}
      footer={footer}
    >
      <Form id="kpiForm" onSubmit={handleSubmit}>
        {/* {loading ? <Loading /> : <Row gutter={4}>{cellRender}</Row>} */}
        <Row gutter={4}>{cellRender}</Row>
      </Form>
    </Modal>
  );
});

export default KPIRender;
