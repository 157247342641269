import '@ant-design/compatible/assets/index.css';

import { Button, Checkbox, Col, Modal, Row, Select, Tabs } from 'antd';
import { CheckboxProps } from 'antd/lib/checkbox';
import Input from 'antd/lib/input';
import { get, intersection, isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import useForm from 'react-hook-form';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

import { Form } from '@ant-design/compatible';
import { LockOutlined, MailFilled, PhoneOutlined, UserOutlined } from '@ant-design/icons';
import { LICENSE, MGN } from '@source/common';

import UIField from '~components/UI/UIField';
import { useStore } from '~context/react-context';
import { UserInput } from '~graphql/_sdk';
import store from './store';

import * as yup from 'yup';
import YupPassword from 'yup-password';

YupPassword(yup);

const { Option } = Select;
const { Password } = Input;
const { TabPane } = Tabs;

export interface FormValues {
  email: string;
  name: string;
  password: string;
  profileId: string;
  address?: string;
  company?: string;
  phone?: string;
  tax?: string;
  website?: string;
  nameUser?: string;
  phoneUser?: string;
  emailUser?: string;
  isCustomer?: boolean;
  isActive?: boolean;
  isVerifyEmail?: boolean;
  isBudget?: boolean;
  isUnlimited?: boolean;
  budget?: string;
  currency?: string;
}

const validationSchema = yup.object().shape<FormValues>({
  profileId: yup.string().required(i18next.t('REQF')),
  name: yup.string().required(i18next.t('REQF')),
  phone: yup
    .string()
    .matches(/^[0-9]+$/, `${i18next.t('MUST_BE_DIGITS')}`)
    .min(10, `${i18next.t('MINIMUM')}: 10`)
    .max(10, `${i18next.t('MAXIMUM')}: 10`)
    .required(i18next.t('REQF')),
  email: yup.string().email(i18next.t('INVALID_EMAIL')).required(i18next.t('REQF')),
  password: yup
    .string()
    .required(i18next.t('REQF'))
    .min(8, i18next.t('PASSWORD_RULES'))
    .minLowercase(1, i18next.t('PASSWORD_RULES'))
    .minUppercase(1, i18next.t('PASSWORD_RULES'))
    .minNumbers(1, i18next.t('PASSWORD_RULES'))
    .minSymbols(1, i18next.t('PASSWORD_RULES'))
  // address: yup.string().required(i18next.t('REQF')),
  // company: yup.string().required(i18next.t('REQF'))
});

const generateHashCode = () => {
  return Array(10)
    .fill('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz!@#$%^&*')
    .map(function (x) {
      return x[Math.floor(Math.random() * x.length)];
    })
    .join('');
};

const UserForm: React.FC = observer(() => {
  const { t } = useTranslation();
  const [defaultKey, setDefaultKey] = useState('1');
  const {
    modalVisible,
    selectedItem,
    setModalVisible,
    createUser,
    updateUser,
    listProfile,
    onCheck,
    check
  } = store;
  const [disable, setDisable] = useState(true);

  const onDisable = () => {
    setDisable(!disable);
  };

  const id = get(selectedItem, '_id', '');
  const { userStore } = useStore();
  const { currentUser } = userStore;
  const initValues = {
    email: get(selectedItem, 'email', ''),
    phone: get(selectedItem, 'phone', ''),
    name: get(selectedItem, 'name', ''),
    profileId: get(selectedItem, 'profileId'),
    password: get(selectedItem, 'password'),
    address: get(selectedItem, 'extra.address', ''),
    company: get(selectedItem, 'extra.company', ''),
    tax: get(selectedItem, 'extra.tax', ''),
    website: get(selectedItem, 'extra.website', ''),
    nameUser: get(selectedItem, 'extra.nameUser', ''),
    phoneUser: get(selectedItem, 'extra.phoneUser', ''),
    emailUser: get(selectedItem, 'extra.emailUser', ''),
    isCustomer: get(selectedItem, 'isCustomer'),
    isActive: get(selectedItem, 'active', true),
    isVerifyEmail: get(selectedItem, 'emailVerified', true),
    isBudget: get(selectedItem, 'extra.isBudget', false),
    isUnlimited: get(selectedItem, 'extra.isUnlimited', false),
    budget: get(selectedItem, 'extra.budget', 0),
    currency: get(selectedItem, 'extra.currency', 'VNĐ')
  };
  const formProps = useForm<FormValues>({ validationSchema });
  const {
    handleSubmit,
    reset,
    setValue,
    errors,
    getValues,
    formState: { isSubmitting },
    triggerValidation
  } = formProps;

  const onCancel = () => {
    reset();
    setDefaultKey('1');
    setModalVisible(undefined, 'user');
  };

  const onSubmit = async (record: FormValues) => {
    const { name, email, password, profileId, phone, isActive, isVerifyEmail } = record;

    const input: UserInput = {
      name,
      email,
      password,
      phone,
      profileId,
      active: isActive,
      emailVerified: isVerifyEmail,
      ownerType: currentUser.ownerType === LICENSE ? LICENSE : MGN
    };
    if (!selectedItem) {
      await createUser(input);
      setDefaultKey('1');
      setModalVisible(null, 'user');
    }
  };

  const onSubmitUpdate = async () => {
    const { name, email, phone, profileId } = getValues();
    const record = {
      name,
      email,
      phone,
      profileId
    };

    Object.keys(record).forEach((key) => {
      triggerValidation(key as any);
    });
    if (isEmpty(errors)) {
      await updateUser(id, record);
      setModalVisible(null, 'user');
    }
  };

  const generatePassword = () => {
    setValue('password', generateHashCode());
  };

  useEffect(() => {
    const error = {
      '1': ['name', 'email', 'password', 'profileId'],
      '2': ['address', 'company']
    };

    function checkError() {
      if (!isEmpty(errors)) {
        const fields = Object.keys(errors);
        for (let i = 1; i <= Object.keys(error).length; i += 1) {
          if (intersection(error[i], fields).length > 0) {
            setDefaultKey(String(i));
            break;
          }
        }
      }
    }

    checkError();
  }, [errors]);

  const buttonProps = selectedItem
    ? {
        type: 'primary',
        form: 'userForm',
        onClick: () => {
          onSubmitUpdate();
        }
      }
    : {
        htmlType: 'submit',
        type: 'primary',
        form: 'userForm',
        loading: isSubmitting
      };

  return (
    <Modal
      title={t('USER')}
      visible={modalVisible}
      cancelText="Close"
      width={650}
      cancelButtonProps={{ type: 'text', ghost: true }}
      onCancel={onCancel}
      okText={selectedItem ? t('UPDATE') : t('CREATE')}
      okButtonProps={buttonProps as any}
    >
      <Form onSubmit={handleSubmit(onSubmit)} id="userForm" layout="vertical">
        <Tabs
          defaultActiveKey="1"
          activeKey={defaultKey}
          onChange={(activeKey) => setDefaultKey(activeKey)}
        >
          <TabPane tab={t('USERS_INFORMATION')} key="1">
            <UIField
              {...formProps}
              name="email"
              label={t('EMAIL')}
              fieldProps={{
                name: 'email',
                placeholder: t('INPUT_EMAIL'),
                prefix: <MailFilled style={{ color: 'rgba(0,0,0,.25)' }} />
              }}
              fieldComponent={Input}
              defaultValue={initValues.email}
            />

            <UIField
              {...formProps}
              name="name"
              label={t('NAME')}
              fieldProps={{
                name: 'name',
                placeholder: 'INPUT_NAME',
                prefix: <UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
              }}
              fieldComponent={Input}
              defaultValue={initValues.name}
            />

            <Row gutter={8} style={selectedItem ? { display: 'none' } : {}}>
              <Col span={16}>
                <UIField
                  {...formProps}
                  name="password"
                  fieldProps={{
                    name: 'password',
                    type: 'text',
                    placeholder: t('INPUT_PASSWORD'),
                    prefix: <LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
                  }}
                  fieldComponent={Input}
                  defaultValue={initValues.password}
                />
              </Col>

              <Col span={8}>
                <Button type="ghost" style={{ width: '100%' }} onClick={generatePassword}>
                  {t('GENERATE')}
                </Button>
              </Col>
            </Row>

            <UIField
              {...formProps}
              name="profileId"
              label={t('PROFILE')}
              fieldProps={{
                // disabled: selectedItem,
                placeholder: t('CHOOSE_PROFILE'),
                children: listProfile.map(({ key, ...prop }) => (
                  <Option key={key} value={prop.profileId}>
                    {prop.display}
                  </Option>
                ))
              }}
              fieldComponent={Select}
              defaultValue={initValues.profileId}
            />

            <UIField
              {...formProps}
              name="phone"
              label={t('PHONE')}
              fieldProps={{
                name: 'phone',
                placeholder: t('INPUT_PHONE'),
                prefix: <PhoneOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
              }}
              fieldComponent={Input}
              defaultValue={initValues.phone}
            />

            <Row gutter={0}>
              <Col span={8} style={selectedItem ? { display: 'none' } : {}}>
                <UIField<CheckboxProps>
                  {...formProps}
                  name="isActive"
                  fieldProps={{
                    name: 'isActive',
                    children: [t('is Active')]
                  }}
                  fieldComponent={Checkbox}
                  checkbox
                  defaultValue={initValues.isActive}
                />
              </Col>
              <Col span={8}>
                <UIField<CheckboxProps>
                  {...formProps}
                  name="isVerifyEmail"
                  fieldProps={{
                    name: 'isVerifyEmail',
                    children: [t('is Verify Email')]
                  }}
                  fieldComponent={Checkbox}
                  checkbox
                  defaultValue={initValues.isVerifyEmail}
                />
              </Col>
            </Row>
          </TabPane>
        </Tabs>
      </Form>
    </Modal>
  );
});

export default UserForm;
