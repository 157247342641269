import React from 'react';

import { usePromise } from '~utils/usePromise';

import { AppConfig, getConfig } from './index';

const Context = React.createContext<AppConfig>(null);

const ConfigProvider = ({ children }) => {
  const config = usePromise<AppConfig>(getConfig);
  return (
    <Context.Provider value={config}>{children}</Context.Provider>
  );
};

const useConfig = () : AppConfig => {
  const config = usePromise<AppConfig>(getConfig);
  return config;
}

export { Context as ConfigContext, useConfig };
export default ConfigProvider;