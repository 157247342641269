import React, { FC } from 'react';

import CustomerForm from './Form';
import CustomerGrid from './Grid';
import Header from './Header';

const CustomerScreen: FC = () => {
  return (
    <>
      <Header />
      <CustomerForm />
      <CustomerGrid />
    </>
  );
};
export default CustomerScreen;
