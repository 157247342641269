import i18next from 'i18next';
import { debounce, merge } from 'lodash';
import { action, computed, observable, runInAction } from 'mobx';
import { actionAsync, task } from 'mobx-utils';

import { GridReadyEvent } from '@ag-grid-community/core';
import * as common from '@source/common';

import userStore from '~common-stores/userStore';
import { ErrorNotify, SuccessNotify } from '~components/UI/Notification';
import * as _sdk from '~graphql/_sdk';
import { getSdk } from '~graphql/sdk';

class InfoStore {
  @observable public status: boolean;
  @observable public selectedItem: _sdk.FullNotificationFragment;
  @observable.ref public notifications: _sdk.FullNotificationFragment[];
  @observable public loading: boolean;
  @observable public where: common.WhereArgs;
  @observable public searchWhere: any;
  @observable.ref public messages: _sdk.FullMessageFragment[];
  @observable public notify: boolean;
  @observable public userAvatar: string;

  constructor() {
    runInAction(() => {
      this.status = false;
      this.selectedItem = null;
      this.notifications = [];
      this.messages = [];
      this.loading = true;
      this.where = {};
      this.searchWhere = {};
      this.notify = false;
      this.userAvatar = null;
    });
  }

  @computed public get noti() {
    return this.notifications;
  }
  @computed public get mess() {
    return this.messages;
  }

  @action setUserAvatar = (value: string) => {
    this.userAvatar = value;
  };

  @action
  public searchFilter = debounce(
    (text: string) => {
      runInAction(() => {
        this.searchWhere = { _search: text };
      });
    },
    500,
    { leading: false, trailing: true }
  );

  @computed
  get whereFilter() {
    const where = { ...this.searchWhere, ...this.where };
    return where;
  }

  @action
  public clearFilter = () => {
    this.where = {};
  };

  @action
  handleUpdate = record => {
    const { currentUser } = userStore;

    if (this.userAvatar) {
      record.avatarLink = this.userAvatar;
    }

    const updatedUser = merge({ ...record });

    this.updateUserInfo(currentUser._id, updatedUser);
  };

  @actionAsync
  public updateUserInfo = async (_id, record) => {
    const sdk = await task(getSdk());

    const {
      updateUserInfoById: { data, error }
    } = await task(sdk.updateUserInfoById({ _id, record }));

    if (error) {
      ErrorNotify(error.code);
    } else {
      SuccessNotify(i18next.t('UPDATE'));
    }
  };

  @actionAsync
  public changePassword = async (record: _sdk.ChangePasswordMutationVariables) => {
    const sdk = await task(getSdk());
    const {
      changePassword: { error }
    } = await task(sdk.changePassword({ ...record }));

    if (error) {
      ErrorNotify(error.code);
    } else {
      SuccessNotify('change');
    }
  };
  // xóa một thông báo

  @actionAsync
  removeNotification = async (_id: string) => {
    const sdk = await task(getSdk());
    const {
      removeNotificationById: { error }
    } = await task(sdk.removeNotificationById({ _id }));
    if (error) {
      ErrorNotify(error.code);
    } else {
      SuccessNotify(i18next.t('remove'));
      this.reload();
    }
  };
  // xóa táta cả thông báo
  @actionAsync
  public removeAllNotification = async () => {
    const sdk = await task(getSdk());
    await task(sdk.deleteAllNotification());
    this.notifications = [];
    SuccessNotify(i18next.t('remove'));
    this.reload();
  };

  @actionAsync
  public sendMailByLicense = async (id, email) => {
    const sdk = await task(getSdk());
    const {
      sendMailByLicense: { error }
    } = await task(sdk.sendMailByLicense({ id, email }));
    if (error) {
      ErrorNotify(error.code);
    } else {
      SuccessNotify(i18next.t('Send Mail'));
    }
  };

  @actionAsync
  public updateStatus = async (_id: string) => {
    const sdk = await task(getSdk());
    const {
      updateNotificationById: { error }
    } = await task(sdk.updateNotificationById({ _id, record: { status: true } }));
    if (error) {
      ErrorNotify(error.code);
    } else {
      SuccessNotify(i18next.t('update'));
      this.reload();
    }
  };

  @action
  public setChecked = () => {
    this.notify = !this.notify;
  };

  @actionAsync
  updateCheckNotiUser = async (_id: string) => {
    this.setChecked();
    // const sdk = await task(getSdk());
    // const {
    //   updateUserById: { error }
    // } = await task(sdk.updateUserById({ _id, record: { extra: { notify: this.notify } } }));
    // if (error) {
    //   ErrorNotify(error.code);
    // }
  };

  @action
  public setWhereFilter = where => {
    this.where = { ...where, ...this.where };
  };

  @actionAsync
  public fetchMessage = async () => {
    this.loading = true;

    const sdk = await task(getSdk());
    const { findManyMessage } = await task(sdk.findManyMessage({ where: this.whereFilter }));
    this.messages = findManyMessage;

    this.loading = false;
  };
  @action
  public handleGridReady = ({ api }: GridReadyEvent) => {
    this.reload = () => {
      api.onFilterChanged();
    };
  };
  @action
  public reload = () => undefined;
}

export default new InfoStore();
