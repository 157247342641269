import { Col, Row } from 'antd';
import { observer } from 'mobx-react';
import React, { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { commonDataStore } from '~common-stores/store-index';
import { parseQuery } from '~utils/query-string';

import CampaignBasicForm from './CampaignBasicForm';
import Grid from './Grid';
import Header from './Header';
import store from './store';

const CampaignBasicScreen: FC = observer(() => {
  const location = useLocation();
  const qs = parseQuery(location.search);

  const { setModalCreateVisible } = store;

  useEffect(() => {
    commonDataStore.loadCommonPartnerOwner();
    commonDataStore.loadCampaignType();

    if (qs.createButton) {
      setTimeout(() => {
        setModalCreateVisible();
      }, 1500);
    }

    return () => {
      store.reset();
    };
  }, []);
  return (
    <>
      <Row style={{ flexDirection: 'column', flexWrap: 'nowrap', height: '100%' }}>
        <Col flex="auto">
          <Header />
        </Col>
        <Col flex={1}>
          <Grid />
        </Col>
      </Row>
      <CampaignBasicForm />
    </>
  );
});

export default CampaignBasicScreen;
