import { Button, Dropdown, Menu, Modal } from 'antd';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactResizeDetector from 'react-resize-detector';

import { ColDef } from '@ag-grid-community/core';
import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  EllipsisOutlined
} from '@ant-design/icons';
import {
  LICENSE,
  ROLE_DELETE_EQUIPMENT,
  ROLE_GET_LOGIN_PAGE,
  ROLE_UPDATE_EQUIPMENT
} from '@source/common';

import Authorized from '~components/Authorized/Authorized';
import AgGridServer from '~components/UI/AgGrid/AgGridServer';
import { useStore } from '~context/react-context';
import { useGraphQLSDK } from '~graphql/GraphQLSDKProvider';
import { format } from '~utils/formatDate';

import store from './store';

const { confirm } = Modal;

const EquipmentGrid: FC = observer(() => {
  const { t } = useTranslation();
  const sdk = useGraphQLSDK();
  const {
    commonDataStore,
    userStore: { currentUser }
  } = useStore();

  useEffect(() => {
    commonDataStore.loadEquipmentType();
    commonDataStore.loadCommonBrandAp();
  }, []);

  const { handleGridReady, where, toggleModal: setModalVisible, remove, downloadBanner } = store;
  const showDeleteConfirm = (value, mac) => {
    confirm({
      title: (
        <div>
          Are you sure delete <b>{mac}</b>
        </div>
      ),
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        remove(value);
      }
    });
  };
  const typeOption = [
    {
      key: 'ap',
      value: 'ap',
      children: 'Access Point'
    },
    {
      key: 'router',
      value: 'router',
      children: 'Router'
    }
  ];

  const columnDefs: ColDef[] = [
    {
      headerName: '',
      field: '_id',
      cellClass: 'text-center',
      minWidth: 70,
      maxWidth: 70,
      cellRendererFramework: ({ value, data }) => {
        const moreActions = (
          <Menu>
            {(data.brand === 'brand_mikrotik' || data.brand === 'brand_unifi') && (
              <Authorized roles={[ROLE_GET_LOGIN_PAGE]}>
                <Menu.Item key="1" onClick={() => downloadBanner(value)}>
                  <DownloadOutlined />
                  Get Login Page
                </Menu.Item>
              </Authorized>
            )}

            <Authorized roles={[ROLE_DELETE_EQUIPMENT]}>
              <Menu.Item
                key="2"
                onClick={() => showDeleteConfirm(value, data.mac)}
                style={{ color: 'red' }}
              >
                <DeleteOutlined style={{ color: 'red' }} />
                Delete
              </Menu.Item>
            </Authorized>
          </Menu>
        );
        return (
          <Button.Group size="small">
            <Authorized roles={[ROLE_UPDATE_EQUIPMENT]}>
              <Button
                icon={<EditOutlined />}
                type="primary"
                onClick={() => setModalVisible(data)}
              />
            </Authorized>
            <Dropdown overlay={moreActions}>
              <Button icon={<EllipsisOutlined />} type="default" />
            </Dropdown>
          </Button.Group>
        );
      }
    },
    {
      headerName: t('NO'),
      field: '',
      cellClass: 'text-center',
      minWidth: 50,
      maxWidth: 50,
      cellRendererFramework: ({ rowIndex }) => rowIndex + 1
    },
    {
      headerName: t('NAME'),
      field: 'name',
      minWidth: 100,
      hide: true
    },
    {
      headerName: t('MAC'),
      field: 'mac',
      minWidth: 165,
      maxWidth: 165
    },
    {
      headerName: t('BRAND'),
      field: 'brand',
      colId: 'brand',
      minWidth: 150,
      maxWidth: 150,
      cellRenderer: ({ value }) => {
        const brandType = commonDataStore.brandAp.find((i) => i.value === value);
        return get(brandType, 'text', '-');
      }
    },
    {
      headerName: t('TYPE'),
      field: 'type',
      minWidth: 150,
      maxWidth: 150,
      cellRenderer: ({ value }) => {
        const type = typeOption.find((item) => item.value === value);
        return get(type, 'children', '-');
      }
    },

    {
      headerName: t('MODEL'),
      field: 'model',
      minWidth: 150,
      maxWidth: 250,
      cellRenderer: ({ value }) => {
        const model = commonDataStore.equipmentType.find((i) => i.value === value);
        return get(model, 'text', '-');
      }
    },
    {
      headerName: t('SERIAL'),
      field: 'serial',
      minWidth: 175,
      maxWidth: 175,
      hide: true
    },
    {
      headerName: t('GATEWAY'),
      field: 'gateway',
      minWidth: 175,
      maxWidth: 175,
      hide: true
    },
    {
      headerName: t('LOCATION'),
      field: 'locationId',
      cellRenderer: ({ data }) => {
        if (currentUser.ownerType === LICENSE) {
          return get(data, 'location.licenseName', '-');
        }
        return get(data, 'location.name', '-');
      },
      minWidth: 250
    },
    {
      headerName: t('CREATED_AT'),
      field: 'createdAt',
      minWidth: 120,
      maxWidth: 120,
      sort: 'desc',
      cellRenderer: ({ value }) => format(value, 'DD-MM-YYYY')
    }
  ];

  return (
    <ReactResizeDetector handleHeight>
      {({ height }) => {
        const gridHeight = height;
        return (
          <AgGridServer
            options={{ columnDefs }}
            height={gridHeight}
            onQuery={sdk.findManyEquipment}
            queryKey="findManyEquipment"
            onCountQuery={sdk.countEquipment}
            countQueryKey="countEquipment"
            onGridReady={handleGridReady}
            where={where}
          />
        );
      }}
    </ReactResizeDetector>
  );
});

export default EquipmentGrid;
