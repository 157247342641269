import { Card, Col, Row } from 'antd';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { formatNumber } from '~utils/formatNumber';

import store from './store';

const { Meta } = Card;
const CardTrafficSummary: FC = observer(() => {
  const { data } = store;
  const { t } = useTranslation();

  return (
    <Row gutter={16} justify="space-between">
      <Col className="gutter-row" xs={24} sm={12} md={8}>
        <Card
          size="small"
          style={{
            backgroundColor: 'white',
            borderLeft: `10px solid #0078D4`,
            borderColor: '#0078D4'
          }}
          key="impression"
        >
          <Meta
            avatar={
              <i className="fas fa-eye" style={{ color: '#0078D4', backgroundColor: 'white' }} />
            }
            title={`${t('IMPRESSION')} (${t('ADS')} / ${t('TOTAL')})`}
            description={
              <div style={{ color: '#0078D4', textAlign: 'right', fontSize: '1.2rem' }}>
                {formatNumber(data?.impressionAds)} / {formatNumber(data?.impression)}
              </div>
            }
          />
        </Card>
      </Col>
      <Col className="gutter-row" xs={24} sm={12} md={8}>
        <Card
          size="small"
          style={{
            backgroundColor: 'white',
            borderLeft: `10px solid #FFA242`,
            borderColor: '#FFA242'
          }}
          key="click"
        >
          <Meta
            avatar={
              <i
                className="fas fa-location-arrow"
                style={{ color: '#FFA242', backgroundColor: 'white' }}
              />
            }
            title={`${t('CLICK')} (${t('ADS')} / ${t('TOTAL')})`}
            description={
              <div style={{ color: '#FFA242', textAlign: 'right', fontSize: '1.2rem' }}>
                {formatNumber(data?.clickAds)} / {formatNumber(data?.click)}
              </div>
            }
          />
        </Card>
      </Col>
      <Col className="gutter-row" xs={24} sm={12} md={8}>
        <Card
          size="small"
          style={{
            backgroundColor: 'white',
            borderLeft: `10px solid #F75A5A`,
            borderColor: '#F75A5A'
          }}
          key="ctr"
        >
          <Meta
            avatar={
              <i
                className="fas fa-percentage"
                style={{ color: '#F75A5A', backgroundColor: 'white' }}
              />
            }
            title={`${t('CTR')} (${t('ADS')} / ${t('TOTAL')})`}
            description={
              <div style={{ color: '#F75A5A', textAlign: 'right', fontSize: '1.2rem' }}>
                {formatNumber(data?.ctrAds)}% / {formatNumber(data?.ctr)}%
              </div>
            }
          />
        </Card>
      </Col>
    </Row>
  );
});

export default CardTrafficSummary;
