import '@ant-design/compatible/assets/index.css';

import { Button, Col, Input, Row } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import useForm from 'react-hook-form';
import * as yup from 'yup';

import { Form } from '@ant-design/compatible';
import { LockFilled } from '@ant-design/icons';

import UIField from '~components/UI/UIField';
import { useStore } from '~context/react-context';

import Store from './store';
import { useTranslation } from 'react-i18next';

export interface FormValues {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

const validationSchema = yup.object().shape<FormValues>({
  oldPassword: yup.string().required('Mật khẩu hiện tại là bắt buộc!'),
  newPassword: yup.string().required('Mật khẩu mới là bắt buộc!'),
  confirmPassword: yup.string().required('Mật khẩu mới là bắt buộc!')
});
const ChangePassword: React.FC = observer(() => {
  const { authStore } = useStore();
  const { logout } = authStore;
  const { changePassword } = Store;
  const { t } = useTranslation();
  const initialValues: FormValues = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  };
  const formProps = useForm<FormValues>({ validationSchema });

  const { handleSubmit } = formProps;

  const onSubmit = async (input: FormValues) => {
    const { oldPassword, newPassword, confirmPassword } = input;
    await changePassword({
      oldPass: oldPassword,
      newPass: newPassword,
      confirmPass: confirmPassword
    });
    await logout();
  };

  return (
    <Row>
      <Col span={24}>
        <div style={{ padding: '5px', minWidth: 200, maxWidth: 300 }}>
          <h1>Thay đổi Password</h1>
          <Form layout="vertical" onSubmit={handleSubmit(onSubmit)} id="accountForm">
            <UIField
              {...formProps}
              name="oldPassword"
              label={t('CURRENT_PASSWORD')}
              fieldProps={{
                name: 'oldPassword',
                type: 'password',
                placeholder: t('CURRENT_PASSWORD'),
                prefix: <LockFilled style={{ color: 'rgba(0,0,0,.25)' }} />
              }}
              fieldComponent={Input}
              defaultValue={initialValues.oldPassword}
            />

            <UIField
              {...formProps}
              name="newPassword"
              label={t('NEW_PASSWORD')}
              fieldProps={{
                name: 'newPassword',
                type: 'password',
                placeholder: t('NEW_PASSWORD'),
                prefix: <LockFilled style={{ color: 'rgba(0,0,0,.25)' }} />
              }}
              fieldComponent={Input}
              defaultValue={initialValues.newPassword}
            />

            <UIField
              {...formProps}
              name="confirmPassword"
              label={t('VERIFY_PASSWORD')}
              fieldProps={{
                name: 'confirmPassword',
                type: 'password',
                placeholder: 'Nhập password Mới',
                prefix: <LockFilled style={{ color: 'rgba(0,0,0,.25)' }} />
              }}
              fieldComponent={Input}
              defaultValue={initialValues.confirmPassword}
            />
            <Button type="primary" htmlType="submit">
              Cập nhật Password
            </Button>
          </Form>
        </div>
      </Col>
    </Row>
  );
});

export default ChangePassword;
