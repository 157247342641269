// import './css.less';

import { Card, Space } from 'antd';
import { observer } from 'mobx-react';
import React, { FC, useEffect } from 'react';

import { UISelect } from '~components/UI/Select/UISelect';

import CardTrafficSummary from './Card';
import ChartTrafficSummary from './Chart';
import { useTranslation } from 'react-i18next';
import store from './store';

const TrafficSummaryDashboard: FC = observer(() => {
  const { t } = useTranslation();
  const { filterDate, setFilterDate, data, getDataTrafficSummary } = store;

  useEffect(() => {
    if (!data) {
      getDataTrafficSummary();
    }
  }, []);

  return (
    <Card
      title={t('dashboard.TRAFFIC_SUMMARY')}
      style={{ marginBottom: '8px' }}
      extra={
        <UISelect
          dataOptions={{
            data: [
              { value: '7days', text: '7 days' },
              { value: '15days', text: '15 days' },
              { value: '30days', text: '30 days' }
            ],
            valueField: 'value',
            textField: 'text'
          }}
          value={filterDate}
          onChange={v => setFilterDate(v)}
        />
      }
    >
      <Space direction="vertical" style={{ width: '100%' }}>
        <CardTrafficSummary />
        <ChartTrafficSummary />
      </Space>
    </Card>
  );
});

export default TrafficSummaryDashboard;
