import { Layout } from 'antd';
import { observer } from 'mobx-react';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';

import { ROLE_MENU_ALL } from '@source/common';

import LangMenu from '~components/Menu/LangMenu';
import { useStore } from '~context/react-context';
import { routes } from '~screens/routes';

import {
  container,
  content_form,
  content_header,
  login_content,
  login_footer,
  login_header,
  login_logo
} from './css';
import LoginForm from './Form';
import ModalForgotScreen from './Modal';

const { Footer } = Layout;

const LoginScreen: FC = observer(() => {
  const { t } = useTranslation();
  const {
    authStore: { isAuthenticated, removeToken },
    userStore: { currentUser }
  } = useStore();

  // useEffect(() => { removeToken() }, []);

  let logo = '';
  // let isAnPhat = false;
  // const url = window.location.href;
  // if (url.indexOf('anphat') > 0) {
  //   isAnPhat = true;
  // }

  // const config = useConfig();

  if (isAuthenticated) {
    const menuRoles = currentUser?.profile?.menuRoleIds;
    const firstRoutePath = routes
      .filter((r) => {
        if (menuRoles && menuRoles.includes(ROLE_MENU_ALL)) return true;
        return menuRoles && menuRoles.includes(r.roleId);
      })
      .shift()?.path;

    return <Redirect to={firstRoutePath || '/'} />;
  }

  if (window.location.href.indexOf('meganet') > -1) {
    logo = require('~assets/images/ads-platform.png');
  } else {
    logo = require('~assets/images/logo-no-brand.png');
  }

  return (
    <div className={container}>
      <div className={login_header}>
        <LangMenu />
      </div>
      <div className={login_content}>
        <div className={content_header}>
          <img className={login_logo} src={logo} alt="" />
          {/* <div className={content_desc}></div> */}
        </div>
        <br />
        <div className={content_form}>
          <LoginForm />
        </div>
        <div>
          <ModalForgotScreen />
        </div>
      </div>
      <Footer style={{ padding: 0 }}>
        <Footer className={login_footer}>
          <div>{t('PLATFORM_INFO')}</div>
        </Footer>
      </Footer>
    </div>
  );
});

export default LoginScreen;
