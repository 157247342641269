const translations = {
  LANGUAGE: 'Language',
  SELECT_LANGUAGE: 'Select language',
  EN: 'English',
  VN: 'Vietnamese',
  CREATE: 'Create',
  EDIT: 'Edit',
  UPDATE: 'Update',
  DELETE: 'Delete',
  SEARCH: 'Search...',
  SAVE: 'Save',
  CLOSE: 'Close',
  OK: 'OK',
  CANCEL: 'Cancle',
  KO: 'No',
  DONE: 'Done',
  CREATED: 'Created',
  UPDATED: 'Updated',
  REMOVE: 'Removed',
  SUCCESSFULL: 'successfully',
  FAILED: 'failed',
  NO: 'No.',
  NAME: 'Name',
  BACK: 'Back',
  NEXT: 'Next',
  CONGRATULATION: 'Congratulation',
  PREVIEW: 'Preview',
  TOTAL: 'Total',
  NO_DATA: 'No data',

  // Name
  BANNER_NAME: 'Banner Name'
};

export default translations;
