import { Col, Row } from 'antd';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import ReactResizeDetector from 'react-resize-detector';
// import useQuery from '~utils/useQuery';
// import i18next from 'i18next';

import { useTranslation } from 'react-i18next';

import Carousel from './Carousel';
import { container } from './css';
import Form from './Form';

const SignUp: FC = observer(() => {
  const { t } = useTranslation();

  let isAnPhat = false;
  const url = window.location.href;
  if (url.indexOf('anphat') > 0) {
    isAnPhat = true;
  }

  return (
    <>
      <div className={container}>
        <ReactResizeDetector handleWidth>
          {({ width }) => {
            const isMobile = width < 768;
            return (
              <Row gutter={16}>
                <Col xs={24} md={12}>
                  <Row justify={isMobile ? 'center' : 'end'}>
                    <div style={{ width: 500, padding: '32px 8px 24px 8px' }}>
                      <div style={{ textAlign: 'center', margin: '48px 0 32px 0' }}>
                        <div style={{ fontSize: '25px' }}>{t('REGISTER')} {t('ACCOUNT')}</div>
                      </div>
                      <div>
                        <Form />
                      </div>
                    </div>
                  </Row>
                </Col>
                <Col xs={24} md={12}>
                  <Row justify={isMobile ? 'center' : 'start'}>
                    <div style={{ width: 500, padding: '24px 8px' }}>
                      <div style={{ textAlign: 'center', margin: '32px 0 16px 0' }}>
                        <div style={{ fontSize: '25px' }}>
                          WIFI MARKETING
                          <br /> TÍCH HỢP - MEGAPLUS
                        </div>
                      </div>
                      <div style={{ textAlign: 'justify', fontSize: 18 }}>
                        <p>
                          Là giải pháp Wifi Marketing đặc trưng được MegaNet cung cấp trên cơ sở
                          tích hợp với hệ thống wifi có sẵn của quý khách hàng.
                          <br />
                          <br />
                          Quý khách chỉ cần kiểm tra thiết bị wifi hoặc router (modem) đang sử dụng
                          của mình, thuộc danh sách các thiết bị được MegaNet hỗ trợ, thì sẽ được
                          đấu nối trải nghiệm dịch vụ Wifi Marketing ngay lập tức với những thao tác
                          đăng ký rất đơn giản.
                        </p>
                      </div>
                    </div>
                    {/* <Carousel /> */}
                  </Row>
                </Col>
              </Row>
            );
          }}
        </ReactResizeDetector>
        {/* {isAnPhat ? '' : <Carousel />} */}
      </div>
    </>
  );
});

export default SignUp;
