import { Button, Result } from 'antd';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { CREATED, MGN } from '@source/common';

import { stringifyQuery } from '~utils/query-string';

import store from './store';

const CampaignFinishStep: FC = observer(() => {
  const {t }= useTranslation();
  const { setCurrentSteps } = store;
  const title = 'Setup Successfully';

  const query = { ownerType: MGN, _operators: { status: { in: [CREATED] } } };
  const qs = stringifyQuery({ where: query });

  return (
    <Result
      status="success"
      title={title}
      extra={[
        <Button type="primary" key="setNewCampaign" onClick={() => setCurrentSteps(0)} >
          {t('SET_A_NEW_CAMPAIGN')}
        </Button>,
        <Link
          key='campaignManagement'
          to={{
            pathname: `/campaigns`,
            // search: `?${qs}`
          }}
        >
          <Button>{t('CAMPAIGN_MANAGEMENT')}</Button>
        </Link>
      ]}
    />
  );
});

export default CampaignFinishStep;
