import { Space } from 'antd';
import { observer } from 'mobx-react';
import React, { FC, useEffect } from 'react';

import Loading from '~components/UI/Loading';
import { useStore } from '~context/react-context';
import useQuery from '~utils/useQuery';

import store from '../store';
import HeaderInfo from './HeaderInfo';
import Tabs from './TabCampaign';

const Report: FC = observer(() => {
  const { setCampaign, campaignId, campaignInfo } = store;
  const { commonDataStore } = useStore();
  const id = useQuery().get('id');

  useEffect(() => {
    commonDataStore.loadCampaigns();
    if (!campaignId) {
      setCampaign(id);
    }
  }, []);

  return !campaignInfo ? (
    <Loading title="Loading report..." />
  ) : (
    <Space
      direction="vertical"
      style={{
        flex: 1,
        background: '#fff',
        padding: 8
      }}
    >
      <HeaderInfo />
      <Tabs />
    </Space>
  );
});

export default Report;
