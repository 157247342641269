import '@ant-design/compatible/assets/index.css';

import { Button, Col, Modal, Row, Select } from 'antd';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Form } from '@ant-design/compatible';

import store from './store';
import TableTransfer from './TableTransfer';

export interface AssignOwnerPopupProps {
  title: string;
  initData?: any;
  collectionName?: string;
  renderOptions: Function;
}

export interface FormValues {
  user?: string;
}

const AssignOwnerPopup = observer((props: AssignOwnerPopupProps) => {
  const { t } = useTranslation();
  const { title, collectionName } = props;
  const {
    getAssignments,
    upsertAssignment,
    currentSelectUser,
    isShowModal,
    setIsShowModal,
    upsertLoading,
    dataSource
  } = store;

  const [targetkeys, setTargetKeys] = useState([]);

  const createInitTargetKeys = (data: any) => {
    if (!data) return [];
    return data.map((e) => e.resourceId);
  };

  useEffect(() => {
    if (!get(currentSelectUser, '_id')) return;
    getAssignments(currentSelectUser._id).then((value) => {
      setTargetKeys(createInitTargetKeys(value));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelectUser]);

  const handleTranferOncChange = (keys) => {
    setTargetKeys(keys);
  };

  const onSubmit = () => {
    upsertAssignment({
      model: collectionName,
      userId: currentSelectUser._id,
      resourceIds: targetkeys
    });
    setTargetKeys([]);
  };

  const onCancle = () => {
    setIsShowModal(false);
    setTargetKeys([]);
  };

  const footer = (
    <>
      <Button onClick={() => onCancle()}>{t('CANCEL')}</Button>
      <Button loading={upsertLoading} onClick={() => onSubmit()} type="primary">
        {t('UPDATE')}
      </Button>
    </>
  );

  const tableColumns = [
    {
      dataIndex: 'title',
      title: t('NAME')
    }
  ];

  return (
    <Modal
      title={title}
      visible={isShowModal}
      onCancel={() => onCancle()}
      width="max-content"
      footer={footer}
    >
      <Form style={{ width: 800 }}>
        <h3>User</h3>
        <Select value={get(currentSelectUser, 'name')} disabled />
        <Row>
          <Col sm={24} md={13} style={{ display: 'flex', marginBottom: 10 }}>
            <p>
              <span style={{ fontWeight: 'bold' }}>{t('EMAIL')}:</span>{' '}
              {get(currentSelectUser, 'email')}
            </p>
          </Col>
          <Col sm={24} md={11} style={{ display: 'flex' }}>
            <p>
              <span style={{ fontWeight: 'bold' }}>{t('PROFILE')}:</span>
              {get(currentSelectUser, 'profile.display')}
            </p>
          </Col>
        </Row>
        <TableTransfer
          dataSource={dataSource}
          targetKeys={targetkeys}
          showSearch
          onChange={handleTranferOncChange}
          filterOption={(inputValue, item) =>
            item.title?.toLowerCase().indexOf(inputValue?.toLowerCase()) !== -1
          }
          leftColumns={tableColumns}
          rightColumns={tableColumns}
          style={{ height: 500 }}
        />
      </Form>
    </Modal>
  );
});

export default AssignOwnerPopup;
