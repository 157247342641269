const createTypeDevice = (chartData): Highcharts.Options => {
  return {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie'
    },
    title: {
      style: {
        textTransform: 'uppercase',
        fontSize: '15px'
      },
      text: ''
    },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f}%'
        }
      }
    },
    series: [
      {
        type: 'pie',
        name: 'Device Types',
        data: chartData
      }
    ]
  };
};

const createTypeOs = (chartData): Highcharts.Options => {
  const series = chartData.map(i => {
    switch (i.name) {
      case 'iOS':
        i.color = '#a6a8ab';
        break;
      case 'Android':
        i.color = '#62dc85';
        break;
      case 'Windows':
        i.color = '#89bcee';
        break;
      default:
        break;
    }
    return i;
  });
  return {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie'
    },
    title: {
      style: {
        textTransform: 'uppercase',
        fontSize: '15px'
      },
      text: ''
    },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f}%'
        }
      }
    },
    series: [
      {
        type: 'pie',
        name: 'Os Types',
        data: series
      }
    ]
  };
};

export { createTypeDevice, createTypeOs };
