import { isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import HighChart from '~components/UI/HighChart/HighChart.lazy';

import store from '../store';

export interface Props {
  idx: number;
}

const PieChart: FC<Props> = observer(props => {
  const { result } = store;
  const { idx } = props;
  let data = [];
  if (!isEmpty(result)) {
    const mapObj = Object.keys(result[idx].options);
    for (let i = 0; i < mapObj.length; i += 1) {
      const obj = {
        name: mapObj[i],
        y: Object.values(result[idx].options)[i],
        sliced: true,
        selected: true
      };
      data=[...data,obj]
    }
  }

  const options: Highcharts.Options = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie'
    },
    title: {
      text: `${result[idx].name}`
    },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f}%'
        },
        showInLegend: true
      }
    },
    series: [
      {
        type: 'pie',
        name: 'Click',
        data
      }
    ]
  };
  return (
    <>
      <HighChart options={options} />
    </>
  );
});

export default PieChart;
