const translations = {
  LANGUAGE: 'Ngôn ngữ',
  INFO: 'Thông tin',
  dashboard: 'Bảng chung',
  chooseLng: 'Chọn ngôn ngữ',
  CREATED: 'Tạo',
  UPDATED: 'Cập nhật',
  REMOVE: 'Xóa',
  SUCCESSFULL: 'thành công',
  FAILED: 'thất bại',
  EN: 'Tiếng Anh',
  VN: 'Tiếng Việt',
  REQF: 'Bắt buộc nhập !!',
  CREATE: 'Tạo mới',
  UPDATE: 'Cập nhật',
  DELETE_THIS: 'Xóa cái này?',
  DELETE: 'Xóa',
  TYPE_TO_SEARCH_LOCATION: 'Nhập vào để tìm kiếm...',
  SEARCH: 'Tìm kiếm...',
  CLOSE: 'Đóng',
  CANCEL: 'Hủy',
  NO: 'STT',
  NAME: 'Tên',
  INPUT_NAME: 'Nhập tên...',
  TYPE: 'Loại',
  CHOOSE_TYPE: 'Chọn kiểu',
  CHOOSE_MODEL: 'Chọn mô hình',
  LOCATION: 'Địa điểm',
  CHOOSE_LOCATION: 'Chọn địa điềm',
  SAVE: 'Lưu',
  ARE_YOU_SURE_DELETE: 'Bạn chắc xóa ',
  KO: 'Không',
  NOTI_INCORRECT_JSON: 'Không đúng định dạng json',
  NOTI_VALUE_EXISTED: 'Giá trị đã tồn tại',
  NOTI_UPDATE_SUCCESSFULLY: 'Cập nhật thành công',
  NOTI_DELETE_SUCCESSFULLY: 'Xóa thành công',
  NOTI_CREATE_SUCCESSFULLY: 'Tạo mới thành công',
  COMMON_DATA_MODAL_TITLE: 'Quản lý tùy chỉnh',
  COMMON_DATA_PLACEHOLDER_TEXT: 'Nhập Text',
  COMMON_DATA_PLACEHOLDER_VALUE: 'Nhập Value',
  COMMON_DATA_PLACEHOLDER_TYPE: 'Loại dữ liệu',
  COMMON_DATA_PLACEHOLDER_ICON: 'Input icon: fa-user',
  COMMON_DATA_PLACEHOLDER_META: 'Nhập meta',
  COMMON_DATA_TABLE_HEADER_TEXT: 'Text',
  COMMON_DATA_TABLE_HEADER_TYPE: 'Loại dữ liệu',
  COMMON_DATA_VALIDATE_TEXT_REQUIRE: 'Text là bắt buộc!',
  COMMON_DATA_VALIDATE_VALUE_REQUIRE: 'Value là bắt buộc!',
  COMMON_DATA_VALIDATE_TYPE_REQUIRE: 'Loại dữ liệu là bắt buộc!',
  COMMON_DATA_VALIDATE_GROUP_REQUIRE: 'Nhóm là bắt buộc!',
  COMMON_DATA_VALIDATE_ICON_REQUIRE: 'Nhãn dán là bắt buộc!',
  LOCATION_STATISTIC: 'THỐNG KÊ ĐỊA ĐIỂM',
  LOCATION_ACTIVE: 'Địa điểm hoạt động',
  LOCATION_ADS: 'Location Ads',
  MONTHLY_CLICK: 'Phiên tháng',
  CTR: 'CTR',
  UNIQUE_USER: 'Unique User',
  TOTAL: 'Tổng',
  MONTHLY_KPI: 'KPI tháng',
  SITES: 'Điểm',
  ACTIVE: 'Hoạt động',
  POINT: 'Point',
  ALL: 'All',
  CAMPAIGN_STATISTIC: 'Thống kê chiến dịch',
  ADVERTISEMENT: 'Quảng cáo',
  DEFAULT: 'Mặc định',
  OTHER: 'Khác',
  BOOKING_KPI: 'Booking Kpi',
  CLICK: 'Lượt Click',
  NEW: 'Mới',
  ENDING: 'Kết thúc',
  USER_LICENSE: 'Tài khoản MegaPlus',
  DEMO: 'Tài khoản Demo',
  REAL: 'Tài khoản thực',
  NEW_IN_MONTH: 'Tài khoản mới trong tháng',
  LOCATION_TRAFFIC: 'Dữ liệu địa điểm',
  IMPRESSION: 'Lượt xem',
  TRAFFIC: 'Dữ liệu',
  'CTR_%': 'CTR (%)',
  GROUP: 'Nhóm',
  PLEASE_WAIT_A_MINUTE: 'Vui lòng đợi trong phút',
  CLICK_COUNT: 'Lượt click',
  DATE: 'Ngày',
  BANNER: 'Banner',
  DEVICE_MAC: 'Thiết bị MAC',
  DEVICE_NAME: 'Tên thiết bị',
  AP_MAC: 'AP MAC',
  SERIAL: 'Serial',
  SESSION_START: ' Session Start',
  SESSION: 'Session',
  SELECT_LOCATION: 'Chọn địa điểm...',
  TODAY_LOGIN: 'Đăng nhập hôm nay',
  ONLINE_SESSION: 'Online Session',
  AUDIENCE: 'Audience',
  OS_REPORT: 'OS Report',
  DEVICE_TYPES_REPORT: 'Device Types Report',
  '15_DAYS': '15 days',
  '30_DAYS': '30 Days',
  VISIT: 'Visit',
  SURVEY: 'Survey',
  MAC: 'MAC',
  FIRST_LOGIN: 'Lần đầu',
  LAST_LOGIN: 'Lần cuối',
  EXPORT_EXCEL: 'Export excel',
  DETAIL: 'Chi tiết',
  OS_DEVICE: 'OS/DEVICE',
  SCHEDULE: 'Lịch trình',
  DEVICE: 'Thiết bị',
  BOOKING_STATUS: 'Trình trạng đặt trước',
  TOP_50_LOCATION: '50 địa điểm hàng đầu',
  THIS_MONTH: 'Tháng này',
  Next_03_MONTH: '03 tháng kế tiếp',
  THIS_YEAR: 'Năm này',
  AVAILABLE_KPI: 'KPI có sẵn',
  LOCATION_NAME: 'Tên địa điểm',
  EVERY_DAY: 'Mỗi ngày',
  BOOKING_AVAILABLE: 'Đặt trước / Có sẵn',
  PERCENT: 'Phần trăm (%)',
  TOP_CATEGORY: 'Danh mục hàng đầu',
  THE_BEST_LOCATION: 'Địa điểm tốt nhất',
  TRAFFIC_TODAY: 'Dữ liệu hôm nay',
  MGN_LICENSE: 'MegaNet / MegaPlus',
  BOOKING_STATUS_30_DAYS: 'Trình trạng đặt trước 30 ngày',
  CAMPAIGN_ADS: 'Quảng cáo chiến dịch',
  RUNNING: 'đang chạy',
  PUBLIC: 'Công cộng',
  TRANSPORT: 'Vận chuyển',
  AIRPORT: 'Sân bay',
  UNIVERSITY: 'Trường Đại học',
  COFFEE: 'Cà phê',
  DRINK: 'Nước uống',
  KIDZONE: 'Khu trẻ em',
  HOSPITAL: 'Bệnh viện',
  MALL: 'Trung tâm mua sắm',
  RESTAURANT: 'Nhà hàng',
  'FOOD_&_BEVERAGE': 'Thực phẩm và đồ uống',
  RAILWAY: 'Đường sắt',
  GENERATE_HTML: 'Tạo HTML',
  DISABLE: 'Vô hiệu hóa',
  ENABLE: 'Kích hoạt',
  YES: 'CÓ',
  CHOOSE_NAME: 'Chọn tên...',
  STATUS: 'Trạng thái',
  CHOOSE_STATUS: 'Chọn trạng thái...',
  ADDRESS: 'Địa chỉ',
  CHOOSE_ADDRESS: 'Chọn địa chỉ...',
  CATEGORY: 'Thể loại',
  CHOOSE_CATEGORY: 'Chọn loại...',
  CITY: 'Thành phố',
  CHOOSE_CITY: 'Chọn thành phố...',
  DISTRICT: 'Huyện',
  CHOOSE_DISTRICT: 'Chọn huyện...',
  ACTIVE_DAY: 'Ngày hoạt động',
  CREATED_DATE: 'Ngày tạo',
  LICENSE: 'license',
  NO_AUTHENTICATION: 'Không chứng thực',
  BY_VOUCHER: 'Bằng chứng thực',
  BY_USERNAME_AND_PASSWORD: 'Bằng tên tài khoản và mật khẩu',
  NOT_ACTIVE: 'Không hoạt động',
  LOCATION_MANAGEMENT: 'Quản lý địa điểm',
  GENERAL_INFO: 'Thông tin chung',
  ADVANCE_INFO: 'Thông tin nâng cao',
  AUTHENTICATION_TYPE: 'Loại xác thực',
  CHOOSE_VAUTHENTICATION_TYPE: 'Lựa chọn loại xác thực',
  CLICK_PER_MONTH: 'Số click / tháng',
  SHARE: 'Chia sẻ',
  USER_MANAGER: 'Quản lý người dùng',
  UNIT_PRICE: 'Đơn giá',
  BASIC_INFO: 'Thông tin cơ bản',
  NO_ROUTER: 'Số. định tuyến',
  NO_ACCESS_POINT: 'Số. Điểm truy cập',
  CONFIG_AUTH: 'Cấu hình xác thực',
  STATISTICS: 'Số liệu thống kê',
  AVARAGE_PER_MONTH: 'Trung bình cộng / Tháng',
  LAST_THREE_MONTHS: 'Ba tháng trước',
  MAC_ADDRESS: 'Địa chỉ MAC',
  SPECIES: 'Loại',
  MODEL: 'Model',
  AMOUNT: 'Số tiền',
  APPLY: 'Áp dụng',
  SUCCESSFULLY_PURCHASED_CLOUD_SERVER_ECS: 'Đã mua thành công ECS ​​Cloud Server!',
  GO_CONSOLE: 'Bảng điều khiển',
  BUY_AGAIN: 'Mua lại',
  NEXT: 'Kế tiếp',
  NAME_IS_REQUIRED: 'Tên là bắt buộc!',
  CATEGORY_IS_REQUIRED: 'Category là bắt buộc!',
  CLICK_IS_REQUIRED: 'Vui lòng nhập số click!',
  CLICK_MUST_BE_POSITIVE: 'Số click phải dương!',
  MUST_CHOOSE_ONE_AUTHENTICATION: 'Phải chọn 1 hình thức chứng thực!',
  DESCRIPTION_IS_REQUIRED: 'Mô tả là bắt buộc!',
  GROUP_DEVICE_IS_REQUIRED: 'Nhóm thiết bị là bắt buộc!',
  MAX_OF_DEVICE_IS_REQUIRED: 'Yêu cầu tối đa thiết bị!',
  PRIORITY: 'Độ ưu tiên',
  SUM_KPI: 'KPI tổng',
  KPI_COMPLETE: 'KPI hoàn thành',
  PLEASE_SELECT_A_LOCATION: 'Vui lòng chọn một địa điểm!',
  AUTHENTICATION_IS_REQUIRED: 'Choose authentication is required',
  OWNER_IS_REQUIRED: 'Owner is required !',
  CAMPAIGN_PRIORITY: 'Độ ưu tiên chiến dịch',
  MAC_ADDRESS_IS_REQUIRED: 'Địa chỉ MAC là bắt buộc!',
  TYPE_IS_REQUIRED: 'Kiểu là bắt buộc!',
  MODEL_IS_REQUIRED: 'Mô hình là bắt buộc!',
  LOCATION_IS_REQUIRED: 'Địa điểm là bắt buộc!',
  BRAND_IS_REQUIRED: 'Loại là bắt buộc!',
  EQUIPMENT_MANAGEMENT: 'Quản lý thiết bị',
  BRAND: 'Brand',
  CHOOSE_BRAND: 'Chọn loại',
  CREATOR_NAME: 'Người tạo điểm',
  HTML_CODE: 'HTML CODE',
  INPUT_HTML_CODE: 'Nhập mã HTML',
  VIEW_BANNER: 'Xem Banner',
  OWNER: 'Sở hữu',
  CREATED_AT: 'Ngày tạo',
  BASIC: 'Căn bản',
  BANNER_CATEGORY: 'Loại Banner',
  CHOOSE_LOCATION_CATEGORY: 'Chọn danh mục địa điểm',
  UNSELECT: 'Bỏ chọn',
  SLIDE: 'Trượt',
  VIDEO: 'Video',
  FORM: 'Điền thông tin',
  SIMPLE: 'Đơn giản',
  HTML_CODE_IS_REQUIRED: 'Mã HTML là bắt buộc!',
  DATA_MUST_BE_JSON_FORMAT: 'Dữ liệu phải là định dạng JSON!',
  OPTIONS_ARE_REQUIRED: 'Tùy chọn là bắt buộc!',
  BANNER_TEMPLATE: 'Mẫu Banner',
  OPTIONS: 'Tùy chọn',
  INPUT_OPTIONS: 'Nhập tùy chọn',
  VIEW: 'Xem',
  NOTIFICATION: 'Thông báo',
  'MARKED_AS_READ ': 'Đánh dấu đã xem',
  DELETE_ALL: 'Xóa tất cả',
  VIEW_MORE: 'Xem thêm',
  USERS_INFORMATION: 'THÔNG TIN SỬ DỤNG',
  EMAIL: 'Email',
  PROFILE: 'Hồ sơ',
  OWNER_INFOMATION: 'THÔNG TIN CỦA CHỦ SỞ HỮU',
  COMPANY: 'Công ty',
  TAX: 'Thuế',
  WEBSITE: 'Website',
  REFERENCES_INFOMATION: 'TÀI LIỆU KHAM KHẢO',
  NAME_USER: 'Tên',
  PHONE_USER: 'Điện thoại',
  USER: 'Người dùng',
  INPUT_EMAIL: 'Nhập email. VD: meganet@gmail.com',
  PASSWORD: 'Mật khẩu',
  INPUT_PASSWORD: 'Nhập mật khẩu',
  GENERATE_PASS: 'Tạo mật khẩu',
  CHOOSE_PROFILE: 'Chọn hồ sơ',
  PHONE: 'Điện thoại',
  INPUT_PHONE: 'Nhập số điện thoại. VD: 0123456789...',
  NAME_COMPANY: 'Tên công ty',
  INPUT_NAME_COMPANY: 'Nhập tên công ty / Địa điểm/ ...',
  INPUT_TAX: 'Nhập thuế',
  INPUT_WEBSITE: 'Nhập website. VD: https://meganet.com.vn',
  CONTACT_USER: 'Liên hệ với người dùng',
  INPUT_NAME_CONTACT: 'Nhập tên liên hệ',
  CREATOR: 'Người tạo',
  TIME_EXPIRED: 'Hết thời gian',
  VERIFIED: 'Đã xác minh',
  WIFI_SOFTWARE_MARKETING_POLICY: 'ĐIỀU KHOẢN SỬ DỤNG PHẦN MỀM WIFI MARKETING MEGANET',
  IS_CUSTOMER: 'là khách hàng',
  RESTORE_PASSWORD: 'Khôi phục mật khẩu',
  NEW_PASSWORD: 'Mật khẩu mới',
  INPUT_NEW_PASSWORD: 'Nhập mật khẩu mới',
  VERIFY_NEW_PASSWORD: 'Xác nhận mật khẩu mới',
  INPUT_NEW_PASSWORDD: 'Nhập xác minh mật khẩu mới',
  CONFIRM: 'Xác nhận',
  SUCCESS_RECOVERY: 'Bạn đã khôi phục thành công',
  LOGIN: 'Đăng nhập',
  NEW_PASSWORD_IS_REQUIRED: 'Mật khẩu mới là bắt buộc!',
  VERIFY_NEW_PASSWORD_IS_REQUIRED: 'Xác nhận mật khẩu mới là bắt buộc!',
  ACCOUNT: 'Tài khoản',
  GO_BACK_TO_HOMEPAGE: 'Trở về trang chủ',
  YOUR_ACCOUNT_ACTIVATED_SUCCESSFULLY: 'Tài khoản của bạn đẫ được kích hoạt thành công!',
  ACTIVATION_FAILED: 'Kích hoạt không thành công. Vui lòng thử lại!',
  REMEMBER_ME: 'Lưu tài khoản',
  FORGOT_YOUR_PASSWORD: 'Bạn quên mật khẩu',
  REGISTER_A_LICENSE_ACCOUNT: 'Đăng kí tài khoản cấp phép',
  MEGANET: 'Meganet, The way to the mobile world',
  PLATFORM_ADVERTISER_MANAGERMENT_VERSION: 'Platform about Advertiser Managerment - Version 3.0',
  FORGOT_PASSWORD: 'Forgot Password',
  PROFILE_NAME: 'Tên profile',
  FLOW_STATISTIC: 'Thống kê lưu lượng',
  NO_DATA_TO_DISPLAY: 'Không có dữ liệu để hiển thị',
  SELECT_LOCATION_YOU_WANNA_SHOW: 'Chọn địa điểm bạn muốn hiển thị!',
  VERIFY_PASSWORD_IS_NOT_EXACTLY: 'Xác minh mật khẩu không chính xác!',
  YOU_DO_NOT_HAVE_PERMISSION_THIS_ACTION: 'Bạn không được phép thực hiện hành động này!',
  INPUT_COMPANY: 'Nhập công ty',
  INPUT_SIGNATURE: 'Nhập chữ kí',
  INPUT_COMFIRM_PASSWORD: 'Nhập xác nhận mật khẩu',
  AGREE_WITH: 'Đồng ý với',
  REGISTER_CONDITION: 'Điều kiện đăng ký',
  REGISTER: 'Đăng ký',
  YOU_HAVE_AN_ACCOUNT: 'Bạn đã có tài khoản',
  SCHEDULE_FROM: 'Lịch trình từ',
  SCHEDULE_TO: 'Lịch trình đến',
  CONTRACT_COSTS: 'Chi phí hợp đồng',
  COMMISSION: 'Hoa hồng',
  MONEY: 'Tiền',
  PERCENTAGE: 'Phần trăm',
  INVOICE_FEE: 'Phí hóa đơn',
  ACHIEVED_KPI: 'KPI đã chạy',
  COST_PRICE: 'Giá vốn',
  TOTAL_AMOUNT: 'Thành tiền',
  TOTAL_COST_PRICE: 'Thành tiền vốn',
  CAMPAIGN: 'Quảng cáo',
  KPI: 'KPI',
  ACTUAL_UNIT_PRICE: 'Đơn giá thực',
  CONTRACT_REVENUE: 'Doanh thu hóa đơn',
  ACTUTAL_REVENUE: 'Doanh thu thực',
  YESTERDAY: 'Hôm qua',
  TODAY: 'Hôm nay',
  REMAINING: 'Còn lại',
  KPI_MANAGEMENT: 'Thay đổi KPI',
  REPORT_LOCATION: 'Báo cáo địa điểm',
  CHOOSE_REPORT_LOCATION: 'Chọn địa điểm báo cáo',
  CHECK_ALL: 'Kiểm tra tất cả',
  CURRENT_KPI: 'KPI hiện tại',
  ADDITIONAL_KPI: 'Bổ sung KPI',
  KPI_CHANGE_WILL_BE_APPLIED: 'Thay đổi KPI sẽ được áp dụng trong 2 phút',
  SCHEDULED: 'Lịch trình',
  STOPPED: 'Dừng',
  INPUT_KPI: 'Nhập KPI (Click)',
  SET_A_NEW_CAMPAIGN: 'Đặt chiến dịch mới',
  CAMPAIGN_MANAGEMENT: 'Quản lý chiến dịch',
  CAMPAIGN_NAME: 'Tên chiến dịch',
  TYPE_CAMPAIGN: 'Loại chiến dịch',
  TYPE_BRAND_CAMPAIGN: 'Loại nhãn hiệu cho chiến dịch',
  TYPE_OF_BRAND: 'Loại nhãn hiệu',
  OPTIMIZATION_TYPE: 'Loại tối ưu hóa',
  ENTER_DESCRIPTION: 'Nhập mô tả',
  TOTAL_BOOKING: 'Tổng số đặt trước',
  TOTAL_LOCATIONS: 'Tổng số địa điểm',
  PREVIOUS: 'Trước',
  FINISH: 'Hoàn thành',
  START_DATE: 'Ngày bắt đầu',
  PICK_END_DATE: 'Chọn ngày kết thúc',
  END_DATE: 'Ngày kết thúc',
  TOTAL_BUDGET: 'Tổng ngân sách',
  TOTAL_KPI: 'Tổng KPI',
  AVAILABLE: 'Có sẵn',
  AUTO_KPI: 'KPI tự động',
  SELECT_CITY: 'Chọn thành phố',
  CLEAR_FILTER: 'Xóa filter',
  SEARCH_LOCATION: 'Tìm kiếm địa điểm...',
  KPI_MONTH: 'KPI/Tháng',
  CLICKS_DAY: 'Clicks/Ngày',
  TOTAL_LOCATION: 'Tổng địa điểm',
  SEARCH_CHECKED_LOCATION: 'Tìm kiếm địa điểm đã kiểm tra...',
  GROUP_DEVICE: 'Nhóm thiết bị',
  EXPIRY_DATE: 'Ngày hết hạn: 1 năm',
  CALCULATED_PRICE: 'Giá tính toans',
  ORDER_DETAIL: 'Chi tiết đặt hàng',
  TOTAL_PRICE: 'Tổng giá',
  UNLIMITED: 'Không giới hạn',
  PICK_LOCATION: 'Chọn địa điểm',
  SET_KPI: 'Đặt KPI',
  INFO_BOOKING: 'Thông tin',
  APP_ID_AND_APP_SECRET: 'App ID và App Secret có thể tìm thấy tại:',
  FACEBOOK_DEVELOPER: 'Facebook developer',
  PAGE: 'page',
  FACEBOOK: 'Facebook',
  APP_ID: 'ID',
  INPUT_FB_APP_ID: 'Nhập Facebook App ID',
  INPUT_ACCOUNT_KIT_SECRET: 'Nhập Account Kit Secret',
  INPUT_FB_APP_SECRET: 'Nhập Facebook App Secret',
  APP_SECRET: 'App Secret',
  ACCOUNT_KIT_SERVER: 'Account Kit Server',
  LOGIN_TYPE: 'Login Type',
  LINK_TO_SHARE: 'Link to share',
  TITLE: 'Tiêu đề',
  INPUT_TITLE: 'Tiêu đề của liên kết',
  CAPTION: 'Chú thích',
  INPUT_CAPTION: 'Chú thích của liên kết (xuất hiện bên dưới tên liên kết)',
  DESCRIPTION: 'Mô tả',
  INPUT_DESCRIPTION: 'Mô tả của liên kết (xuất hiện bên dưới chú thích liên kết)',
  THUMBNAIL_IMAGE_URL: 'URL hình ảnh thu nhỏ',
  INPUT_THUMBNAIL: 'URL của một hình ảnh đính kèm với bài viết này. Ít nhất 200px x 200px',
  GOOGLE: 'Google',
  INPUT_GOOGLE_ID: 'Nhập Google Client ID',
  INPUT_GOOGLE_SECRET: 'Nhập Google Client Secret',
  OS: 'Hệ điều hành',
  THERE_IS_NO_BANNER: 'There is no banner',
  ADD_BANNER: 'Thêm Banner',
  CALL_STATUS: 'Trạng thái cuộc gọi',
  AGE: 'Tuổi',
  GENDER: 'Giới tính',
  INCOME: 'Doanh thu',
  MARRIAGE: 'Hôn nhân',
  ALERT_CRMCONFIG:
    'Tùy chỉnh báo cáo bằng cách chọn các trường cần thiết. Người dùng phải cung cấp thông tin. Hệ thống không thu thập tự động.',
  DEFAULT_FIELDS: 'Các trường mặc định',
  AVAILABLE_LOCATIONS: 'Địa điểm có sẵn',
  ADD: 'Thêm',
  LOCATION_GROUPS: 'Nhóm địa điểm',
  ADD_LOCATION: 'Tạo địa điểm',
  ADD_GROUP: 'Tạo nhóm',
  MAIN_LANDING_PAGE: 'Trang đích',
  LOCATIONS_LANDING_PAGE: 'Trang đích theo địa điểm',
  REDIRECT_PAGE: 'Trang đích',
  REDIRECT_PAGE_IS_REQUIRED: 'Trang đích là bắt buộc',
  UPDATE_CONFIG: 'Cập nhật cấu hình',
  CAMPAIGN_CONFIG: 'Cấu hình Campaign',
  TARGET_OBJECT: 'Target Object',
  ADVANCE: 'Nâng cao',
  LOCATION_GROUP: 'Nhóm địa điểm',
  DISAPPROVE: 'Disapprove',
  APPROVE: 'Duyệt',
  APPROVE_CAMPAIGN: 'Approve Campaign',
  STOP_FOREVER: 'Dừng vĩnh viễn',
  DISABLE_CAMPAIGN: 'Dừng chiến dịch',
  COMPLETED: 'Hoàn thành',
  INPUT_TIME: 'Nhập thời gian',
  PAUSE: 'Dừng',
  CONFIG: 'Cấu hình',
  COPY: 'Copy',
  NAME_CAMPAIGN_IS_REQUIRED: 'Tên chiến dịch là bắt buộc!',
  TYPE_CAMPAIGN_IS_REQUIRED: 'Loại chiến dịch là bắt buộc!',
  YOUR_BUDGET_MUST_BE_MORE_THAN_BOOKING: 'Ngân sách của bạn phải nhiều hơn đặt KPI',
  CAMPAIGN_INFORMATION: 'Thông tin chiến dịch',
  CHOOSE_OWNER: 'Chọn chủ sở hữu',
  LANDING_PAGE: 'Trang đích',
  PLEASE: 'Vui lòng',
  CHOOSE_CAMPAIGN: 'chọn chiến dịch',
  FIRST: 'trước!',
  CAMPAIGN_INFO: 'Thông tin chiến dịch',
  FLOW_STATISTICS: 'Thống kê lưu lượng',
  INFORMATION: 'Thông tin',
  STATISTIC_DEVICE: 'Thống kê theo loại thiết bị',
  STATISTIC_OPERATOR_SYSTEM: 'Thống kê theo hệ điều hành',
  SUM_VIEW: 'Sum (Lượt xem)',
  SUM_CLICK: 'Sum (Lượt click)',
  AVG_CTR: 'Avg (CTR)',
  DETAIL_STATISTIC: 'Thống kê chi tiết',
  MAIN_STATISTIC: 'Thống kê chính',
  ACTIVE_CAMPAIGNS: 'Chiến dịch hoạt động',
  TIME: 'Thời gian',
  GENERAL: 'Chung',
  CAMPAIGN_TYPE_DEMO: 'Thử nghiệm',
  CAMPAIGN_TYPE_PARTNER: 'Đối tác điểm',
  CAMPAIGN_TYPE_REAL: 'Quảng cáo',
  CAMPAIGN_TYPE_TRASH: 'Thùng rác',
  CAMPAIGN_TYPE_UNLIMITED: 'Không giới hạn',
  CAMPAIGN_TYPE_DEFAULT: 'Mặc định',
  CAMPAIGN_TYPE_LICENSE: 'MegaPlus',
  IMAGE_TEMPLATE: 'Mẫu hình ảnh',
  IMAGE: 'Hình ảnh',
  ABORT: 'Hủy bỏ',
  DEVICE_MANAGEMENT: 'Quản lý thiết bị',
  INFORMATION_DEVICE: 'Thông tin thiết bị',
  TEXT: 'Text',
  VALUE: 'Value',
  META_DEVICE: 'Meta thiết bị',
  METHOD: 'Method',
  CHOOSE_METHOD: 'Chọn Method',
  USERNAME: 'Tài khoản',
  CLIENT_MAC: 'Mac khách hàng',
  LOGIN_URL: 'Đăng nhập Url',
  LOGIN_URL_FORMAT: 'Định dạng Url đăng nhập',
  REDIRECT_URL: 'Chuyển hướng Url',
  COMMON: 'Chung',
  CHOOSE_GROUP: 'Chọn nhóm',
  INSERT_TITLE: 'Thêm tiêu đề....',
  CHOOSE_BRAND_AP: 'Chọn Brand AP',
  CREATE_A_BEAUTY_DOCUMENT: 'Tạo một tài liệu làm đẹp !!',
  INSTRUCTION: 'Chỉ dẫn',
  INPUT_VIDEO: 'Nhập link liên kết video',
  FILE: 'File',
  GROUP_CATEGORY: 'Nhóm Category',
  PRICE: 'Giá',
  INPUT_PRICE: 'Nhập giá ....',
  DEVICE_GROUP: 'Nhóm thiết bị',
  CHOOSE_DEVICE: 'Chọn thiết bị',
  ADD_NEW_TYPE: 'Thêm loại mới',
  NEW_TYPE: 'Loại mới',
  BRAND_CAMPAIGN: 'Brand Chiến dịch',
  BRAND_AP: 'Brand AP',
  PLAN: 'Kế hoạch',
  CODE: 'Mã',
  INPUT_CODE: 'Nhập mã',
  CHOOSE_GROUP_DEVICE: 'Chọn nhóm thiết bị',
  INPUT_AMOUNT: 'Nhập số tiền ',
  ROLES_MANAGEMENT: 'Quản lý vai trò',
  RESOURCES: 'Tài nguyên',
  CONTENT_OF_TAB_PANE_2: 'Nội dung của Tab Pane 2',
  LIST: 'Danh sách',
  SELECT_CATEGORY: 'Chọn danh mục',
  GO_TO_YOUR_ORDER: 'Đến đơn hàng của bạn',
  ROLE_IS_REQUIRED: 'Vai trò là bắt buộc!',
  ROLE_NAME: 'Tên vai trò',
  ROLE: 'Vai trò',
  MENU: 'Danh mục',
  MGN_REVENUE: 'MegaNet',
  CUSTOMER_REVENUE: 'Khách hàng',
  REVENUE: 'Doanh thu',
  ACTUAL_REVENUE: 'Doanh thu thực',

  VERIFY: 'Cho phép',
  REJECT: 'Từ chối',
  PENDING: 'Chờ duyệt',
  PAYMENT_LIST: 'Payment'
};

export default translations;
