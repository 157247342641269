/* eslint-disable default-case */
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import CreateRender from './CreateRender';
import EditRender from './EditRender';
import KPIRender from './KPIRender';
import store from './store';

const KPIManagementForm: FC = observer(() => {
  const { modalType, visible, setModalVisible } = store;

  switch (modalType) {
    case 'create':
      return <CreateRender key="create" visible={visible} setModalVisible={setModalVisible} />;

    case 'edit':
      return <EditRender key="edit" visible={visible} setModalVisible={setModalVisible} />;

    case 'kpi':
      return <KPIRender key="kpi" visible={visible} setModalVisible={setModalVisible} />;
  }
});

export default KPIManagementForm;
