import { Button, Card, Col, Input, Row } from 'antd';
import { observer } from 'mobx-react';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { notRunnableStatuses, ROLE_CREATE_CAMPAIGN, runnableStatuses } from '@source/common';

import { FilterConfig } from '~components/Filter/filterType';
import UIFilterHorizontal from '~components/Filter/UIFilterHorizontal';
import OwnerType from '~components/UI/OwnerTypeRadio';
import { useStore } from '~context/react-context';
import { upperCaseFirstLetter } from '~utils/upperCaseFirstLetter';

import store from './store';
import Authorized from '~components/Authorized/Authorized';

const CampaignsHeader: FC = observer(() => {
  const { commonDataStore } = useStore();
  const { t } = useTranslation();

  const { setOwnerType, setSearchText, setFilter, setModalCreateVisible } = store;

  useEffect(() => {
    commonDataStore.loadCampaignType();
  }, []);

  const campaignStatus = [...runnableStatuses, ...notRunnableStatuses];
  const initFilterConfig: FilterConfig = {
    select: [
      {
        dataOptions: {
          data: campaignStatus.map(e => {
            return { value: e, text: upperCaseFirstLetter(e) };
          }),
          valueField: 'value',
          textField: 'text'
        },

        props: {
          placeholder: t('CHOOSE_STATUS'),
          allowClear: true,
          mode: 'multiple'
        },
        filterField: 'status'
      },
      {
        dataOptions: {
          data: commonDataStore.campaignType,
          valueField: 'value',
          textField: 'text'
        },
        props: {
          placeholder: t('CHOOSE_TYPE'),
          allowClear: true,
          mode: 'multiple'
        },
        filterField: 'type'
      }
    ],
    dateRange: [
      {
        filterFields: ['scheduleFrom', 'scheduleTo'],
        props: { allowClear: false }
      }
    ]
  };

  return (
    <Card size="small" className="card-filter" style={{ marginBottom: 12 }}>
      <Row gutter={8} style={{ flexWrap: 'nowrap' }}>
        <Col style={{ marginRight: 'auto', minWidth: 235 }}>
          <OwnerType setOwnerFunc={setOwnerType} />
        </Col>

        <Col flex={1} style={{ maxWidth: 600 }}>
          <UIFilterHorizontal
            key="filter"
            flex="1 1 160px"
            config={initFilterConfig}
            onFilterChanged={setFilter}
          />
        </Col>
        <Col flex="auto" style={{ minWidth: 150, maxWidth: 220 }}>
          <Input
            key="search"
            suffix={<SearchOutlined />}
            placeholder={t('SEARCH')}
            allowClear
            onChange={e => setSearchText(e.target.value)}
          />
        </Col>
        <Col>
          <Authorized key="create" roles={[ROLE_CREATE_CAMPAIGN]}>
            <Button
              key="btn"
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setModalCreateVisible()}
            >{t('CREATE')}</Button>
          </Authorized>
        </Col>
      </Row>
    </Card>
  );
});

export default CampaignsHeader;
