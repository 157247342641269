import { Spin } from 'antd';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import HighChart from '~components/UI/HighChart/HighChart';
import { format, RANGE_DAY_FORMAT } from '~utils/formatDate';

import store from './store';

const ChartTrafficSummary: FC = observer(() => {
  const { data, loading } = store;
  const categories = data?.detail.map((item) => format(item.date, RANGE_DAY_FORMAT));
  const { t } = useTranslation();

  const options: Highcharts.Options = {
    chart: {
      type: 'column'
    },
    title: {
      text: ''
    },

    xAxis: {
      gridLineWidth: 1,
      categories,
      crosshair: true,
      tickInterval: 3
    },
    yAxis: [
      {
        title: {
          text: t('TRAFFIC_TOTAL')
        }
      },
      {
        title: {
          text: '(CTR %)'
        },

        opposite: true
      }
    ],

    tooltip: {
      shared: true
    },
    plotOptions: {
      column: {
        pointPadding: 0,
        borderWidth: 0
      },
      candlestick: {
        lineColor: '#404048'
      }
    },
    credits: {
      enabled: false
    },
    series: [
      {
        name: t('IMPRESSION'),
        color: '#0078D4',
        type: 'column',
        data: data?.detail.map((item) => item.impression)
      },
      {
        name: t('CLICK'),
        color: '#FFA242',
        type: 'column',
        data: data?.detail.map((item) => item.click)
      },
      {
        name: t('CTR'),
        color: '#F75A5A',
        zIndex: 1,
        type: 'spline',
        data: data?.detail.map((item) => item.ctr),
        yAxis: 1,
        tooltip: {
          valueSuffix: ' %'
        }
      }
    ]
  };

  const optionsAds: Highcharts.Options = {
    chart: {
      type: 'column'
    },
    title: {
      text: ''
    },

    xAxis: {
      gridLineWidth: 1,
      categories,
      crosshair: true,
      tickInterval: 3
    },
    yAxis: [
      {
        title: {
          text: t('TRAFFIC_ADS')
        }
      },
      {
        title: {
          text: '(CTR %)'
        },

        opposite: true
      }
    ],

    tooltip: {
      shared: true
    },
    plotOptions: {
      column: {
        pointPadding: 0,
        borderWidth: 0
      },
      candlestick: {
        lineColor: '#404048'
      }
    },
    credits: {
      enabled: false
    },
    series: [
      {
        name: t('IMPRESSION'),
        color: '#0078D4',
        type: 'column',
        data: data?.detail.map((item) => item.impressionAds)
      },
      {
        name: t('CLICK'),
        color: '#00FF00',
        type: 'column',
        data: data?.detail.map((item) => item.clickAds)
      },
      {
        name: t('CTR'),
        color: '#F75A5A',
        zIndex: 1,
        type: 'spline',
        data: data?.detail.map((item) => item.ctrAds),
        yAxis: 1,
        tooltip: {
          valueSuffix: ' %'
        }
      }
    ]
  };
  return (
    <Spin spinning={loading}>
      <HighChart options={optionsAds} />
      <HighChart options={options} />
    </Spin>
  );
});
export default ChartTrafficSummary;
