/* eslint-disable no-shadow */
import { Button, Dropdown, Menu, Modal, Popconfirm } from 'antd';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  DeleteOutlined,
  DislikeOutlined,
  EditOutlined,
  EllipsisOutlined,
  LikeOutlined,
  MailOutlined,
  UnlockOutlined,
  UpCircleOutlined
} from '@ant-design/icons';
import {
  ROLE_DELETE_USER,
  ROLE_ENABLE_VERIFY_EMAIL_USER,
  ROLE_RESET_PASSWORD_USER,
  ROLE_SET_ACTIVE_USER,
  ROLE_UPDATE_USER
} from '@source/common';

import AuthorizedRoles from '~components/Authorized/Authorized';

import store from './store';

const { confirm } = Modal;

interface Prop {
  value?: any;
  data?: any;
}

const CellRenderer: FC<Prop> = observer((prop: Prop) => {
  const { t } = useTranslation();

  const { value, data } = prop;
  const {
    setActive,
    setValueVerifyEmail,
    setIsShowBudget,
    removeUser,
    resetPasswordUser,
    setDrawerVisible,
    setUpgrateLicense,
    setModalVisible,
    sendEmailVerify,
    currentUser
  } = store;

  const showDeleteConfirm = (value, text) => {
    confirm({
      title: (
        <div>
          Are you sure delete <b>{text}</b>
        </div>
      ),
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        removeUser(value);
      }
    });
  };

  const showResetPasswordConfirm = (userId: string) => {
    confirm({
      title: (
        <div>
          {t('ARE_YOU_SURE')} <b>{t('RESET_PASSWORD_DEFAULT')}</b>
        </div>
      ),
      okText: t('YES'),
      okType: 'primary',
      cancelText: t('CANCEL'),
      onOk() {
        resetPasswordUser({ userId });
      }
    });
  };

  const moreActions = () => {
    return (
      <AuthorizedRoles
        roles={[
          ROLE_SET_ACTIVE_USER,
          ROLE_ENABLE_VERIFY_EMAIL_USER,
          ROLE_DELETE_USER,
          ROLE_RESET_PASSWORD_USER
        ]}
      >
        <Menu>
          {get(data, 'extra.licenseType') === 'demo' ? (
            <AuthorizedRoles roles={[ROLE_SET_ACTIVE_USER]}>
              <Menu.Item key="1" style={{ color: 'black' }}>
                <Popconfirm
                  placement="top"
                  title={t('UPGRADE LICENSE')}
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => setUpgrateLicense(data._id)}
                >
                  <span onClick={(e) => e.stopPropagation()}>
                    <UpCircleOutlined />
                    {t('Upgrade License')}
                  </span>
                </Popconfirm>
              </Menu.Item>
            </AuthorizedRoles>
          ) : null}
          {data.active ? (
            <AuthorizedRoles roles={[ROLE_SET_ACTIVE_USER]}>
              <Menu.Item key="1" style={{ color: 'black' }}>
                <Popconfirm
                  placement="top"
                  title={t('DISABLE')}
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => setActive(data.email, false)}
                >
                  <span onClick={(e) => e.stopPropagation()}>
                    <DislikeOutlined />
                    {t('DISABLE')}
                  </span>
                </Popconfirm>
              </Menu.Item>
            </AuthorizedRoles>
          ) : null}
          {!data.active ? (
            <AuthorizedRoles roles={[ROLE_SET_ACTIVE_USER]}>
              <Menu.Item key="2" style={{ color: 'black' }}>
                <Popconfirm
                  placement="top"
                  title={t('ACTIVE')}
                  okText={t('YES')}
                  cancelText={t('CANCEL')}
                  onConfirm={() => setActive(data.email, true)}
                >
                  <span onClick={(e) => e.stopPropagation()}>
                    <LikeOutlined />
                    {t('ENABLE')}
                  </span>
                </Popconfirm>
              </Menu.Item>
            </AuthorizedRoles>
          ) : null}
          {!data.emailVerified && (
            <AuthorizedRoles roles={[ROLE_ENABLE_VERIFY_EMAIL_USER]}>
              <Menu.Item key="3" style={{ color: 'black' }}>
                <Popconfirm
                  placement="top"
                  title={t('VERIFY_EMAIL')}
                  okText={t('YES')}
                  cancelText={t('CANCEL')}
                  onConfirm={() => setValueVerifyEmail(data.email)}
                >
                  <span onClick={(e) => e.stopPropagation()}>
                    <MailOutlined />
                    {t('VERIFY_EMAIL')}
                  </span>
                </Popconfirm>
              </Menu.Item>
            </AuthorizedRoles>
          )}
          {!data.emailVerified && (
            <AuthorizedRoles roles={[ROLE_ENABLE_VERIFY_EMAIL_USER]}>
              <Menu.Item key="3" style={{ color: 'black' }}>
                <Popconfirm
                  placement="top"
                  title={t('VERIFY_EMAIL')}
                  okText={t('YES')}
                  cancelText={t('CANCEL')}
                  onConfirm={() => sendEmailVerify(data.email)}
                >
                  <span onClick={(e) => e.stopPropagation()}>
                    <MailOutlined />
                    {t('SEND_EMAIL_VERIFY')}
                  </span>
                </Popconfirm>
              </Menu.Item>
            </AuthorizedRoles>
          )}
          <AuthorizedRoles roles={[ROLE_RESET_PASSWORD_USER]}>
            <Menu.Item key="resetPassword" onClick={() => showResetPasswordConfirm(value)}>
              <UnlockOutlined />
              {t('RESET_PASSWORD_DEFAULT')}
            </Menu.Item>
          </AuthorizedRoles>
          <AuthorizedRoles roles={[ROLE_DELETE_USER]}>
            <Menu.Item
              key="5"
              style={{ color: 'red' }}
              onClick={() => showDeleteConfirm(value, data.name)}
            >
              <DeleteOutlined style={{ color: 'red' }} />
              {t('DELETE')}
            </Menu.Item>
          </AuthorizedRoles>
        </Menu>
      </AuthorizedRoles>
    );
  };

  return (
    <Button.Group size="small">
      <AuthorizedRoles roles={[ROLE_UPDATE_USER]}>
        <Button
          icon={<EditOutlined />}
          type="primary"
          onClick={() => setModalVisible(data, 'user')}
          disabled={currentUser.email === data.email}
        />
      </AuthorizedRoles>
      <Button
        style={{ color: data.active ? '#52c41a' : 'grey' }}
        type="ghost"
        onClick={() => setDrawerVisible(data)}
      >
        <i className="fas fa-info fa-1x" />
      </Button>
      <Dropdown overlay={moreActions}>
        <Button icon={<EllipsisOutlined />} />
      </Dropdown>
    </Button.Group>
  );
});

export default CellRenderer;
