import { Col, Row } from 'antd';
import { observer } from 'mobx-react';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { UISelect } from '~components/UI/Select/UISelect';
import { UIDateRangePicker } from '~components/UI/UIDateRangePicker';

import store from './store';

const Filter: FC = observer(() => {
  const { t } = useTranslation();
  const { filter, getCampaignList, campaigns, onReportFilterChange } = store;

  useEffect(() => {
    getCampaignList();
  }, []);

  return (
    <Row gutter={16} style={{ padding: '10px 3px', margin: '0px 0px', backgroundColor: '#ffffff' }}>
      <Col flex="auto" style={{ maxWidth: 350 }}>
        <UIDateRangePicker
          size="middle"
          allowClear={false}
          style={{ width: '100%', fontSize: 13 }}
          value={[filter?.date?.gte, filter?.date?.lte]}
          onChange={(e) => onReportFilterChange('date', e)}
        />
      </Col>
      <Col flex="auto" style={{ maxWidth: 350 }}>
        <UISelect
          dataOptions={{
            data: campaigns,
            valueField: 'campaignId',
            textField: 'campaignName'
            // template: selectCampaignTemplate
          }}
          allowClear
          placeholder={t('SEARCH_CAMPAIGN')}
          onChange={(e) => onReportFilterChange('campaignId', e)}
        />
      </Col>
    </Row>
  );
});

export default Filter;
