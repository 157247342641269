import { Button, Modal } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactResizeDetector from 'react-resize-detector';

import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { AUTHORIZED_ROLES_DELETE_PROFILE, AUTHORIZED_ROLES_EDIT_PROFILE } from '@source/common';

import Authorized from '~components/Authorized/Authorized';
import AgGridServer from '~components/UI/AgGrid/AgGridServer';
import { useGraphQLSDK } from '~graphql/GraphQLSDKProvider';

import store from './store';

const { confirm } = Modal;
const Grid = observer(() => {
  const { t } = useTranslation();
  const sdk = useGraphQLSDK();

  const { handleGridReady, where, handleInitEdit, removeProfileById } = store;
  const showDeleteConfirm = (value, text) => {
    confirm({
      title: (
        <div>
          {t('ARE_YOU_SURE_DELETE')} <b>{text}</b>
        </div>
      ),
      okText: t('YES'),
      okType: 'danger',
      cancelText: t('KO'),
      onOk() {
        removeProfileById(value);
      }
    });
  };

  const columnDefs = [
    {
      headerName: '',
      field: '_id',
      cellClass: 'text-center',
      minWidth: 80,
      maxWidth: 80,
      cellRendererFramework: ({ value, data }) => {
        return (
          <Button.Group size="small">
            <Authorized roles={AUTHORIZED_ROLES_EDIT_PROFILE}>
              <Button
                icon={<EditOutlined />}
                type="primary"
                onClick={() => {
                  handleInitEdit(value);
                }}
              />
            </Authorized>

            <Authorized roles={AUTHORIZED_ROLES_DELETE_PROFILE}>
              <Button
                type="primary"
                icon={<DeleteOutlined color="red" />}
                danger
                onClick={() => showDeleteConfirm(value, data.display)}
              />
            </Authorized>
          </Button.Group>
        );
      }
    },
    {
      headerName: '',
      field: '',
      cellClass: 'text-center',
      minWidth: 50,
      maxWidth: 50,
      sortable: false,
      cellRendererFramework: ({ rowIndex }) => {
        return rowIndex + 1;
      }
    },
    {
      headerName: t('PROFILE_NAME'),
      field: 'display',
      sortable: true,
      sort: 'asc',
      minWidth: 300
    },
    {
      headerName: t('DESCRIPTION'),
      field: 'description',
      sortable: false,
      minWidth: 400
    }
  ];
  return (
    <ReactResizeDetector handleHeight>
      {({ height }) => {
        return (
          <AgGridServer
            options={{ columnDefs }}
            onQuery={sdk.findManyProfileIdAndDisplay}
            queryKey="findManyProfile"
            onCountQuery={sdk.countProfile}
            countQueryKey="countProfile"
            onGridReady={handleGridReady}
            height={height - 80}
            where={where}
          />
        );
      }}
    </ReactResizeDetector>
  );
});

export default Grid;
