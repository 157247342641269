import './css.less';

import { Col, Row } from 'antd';
import { intersection } from 'lodash';
import { observer } from 'mobx-react';
import React, { FC, useEffect } from 'react';

import { ROLE_READ_ALL, ROLE_SUPERADMIN } from '@source/common';

import userStore from '~common-stores/userStore';
import filterGrid from '~components/Filter/FilterRPLocation/store';

import Filter from './Filter';
import { Report } from './Report';
import store from './store';

const ReportIncomePartnerScreen: FC = observer(() => {
  const { getReportIncome, getCustomer, setWhere, cleanUp, setSelectedCustomer } = store;

  useEffect(() => {
    if (intersection(userStore.roleIds, [ROLE_SUPERADMIN, ROLE_READ_ALL])) {
      getCustomer();
    } else {
      setSelectedCustomer(userStore.currentUser.customerId);
    }
    getReportIncome();
    setWhere(filterGrid.where);
    return () => cleanUp();
  }, [filterGrid.where]);

  return (
    <Row style={{ height: '100%', flexFlow: 'column nowrap' }}>
      <Col flex="0 0 auto">
        <Filter />
      </Col>
      <Col flex="1 1 auto">
        <Report />
      </Col>
    </Row>
  );
});
export default ReportIncomePartnerScreen;
