import { action, observable, runInAction } from 'mobx';
import { actionAsync, task } from 'mobx-utils';

import { UserRegisterInput } from '~graphql/_sdk';
import { getSdk } from '~graphql/sdk';

class SignupStore {
  @observable
  public isRegisterConditionModalVisible: boolean;
  public isSuccessModalVisible: boolean;


  constructor() {
    runInAction(() => {
      this.isRegisterConditionModalVisible = false;
      this.isSuccessModalVisible = false;
    });
  }

  @action
  toggleSuccessModal = (state: boolean = null) => {
    this.isSuccessModalVisible =
      typeof state === 'boolean' ? state : !this.isSuccessModalVisible;
  };

  @action
  toggleRegisterConditionModal = (state: boolean = null) => {
    this.isRegisterConditionModalVisible =
      typeof state === 'boolean' ? state : !this.isRegisterConditionModalVisible;
  };

  @actionAsync
  signUp = async (record: UserRegisterInput) => {
    const sdk = await task(getSdk());
    const {
      signUp: { error }
    } = await task(sdk.signUp({ record }));

    return error;
  };
}

export default new SignupStore();
