import './css.less';

import { Card, Col, Row } from 'antd';
// eslint-disable-next-line import/extensions
import { observer } from 'mobx-react';
import React, { FC, useEffect } from 'react';

import Device from './DeviceNew';
import LocationReport from './LoginReport';
import store from './store';
import Visit from './Visit';
import { useTranslation } from 'react-i18next';

const MegaNetChart: FC = observer(() => {
  const { fetchData } = store;
  const { t } = useTranslation();
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Card
      title={t('dashboard.AUDIENCE')}
      style={{ marginBottom: 8 }}
    >
      <LocationReport isShow />
      <Row gutter={8}>
        <Col span={14}>
          <Device />
        </Col>
        {/* <Col span={12}>
          <Visit />
        </Col> */}
      </Row>
    </Card>
  );
});

export default MegaNetChart;
