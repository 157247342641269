import { observer } from 'mobx-react';
import React from 'react';

import { UIButtonExportExcel } from '~components/UI/UIButtonExportExcel';
import { useStore } from '~context/react-context';
import { isMobile } from '~utils/isMobile';

import store from './store';

export const Export: React.FC = observer(() => {
  const {
    authStore: { token }
  } = useStore();
  const { whereFilter } = store;

  return (
    <UIButtonExportExcel
      key="exportExcel"
      name="Export"
      model="locations"
      style={{
        display: isMobile() ? 'none' : 'inline-block',
        backgroundColor: 'rgb(82, 196, 26)',
        color: '#fff'
      }}
      filter={whereFilter}
      token={token}
    />
  );
});
