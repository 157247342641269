import React, { FC, useEffect } from 'react';

import { commonDataStore } from '~common-stores/store-index';

import Form from './Form';
import Grid from './Grid';
import Header from './Header';

const BannerTemplateScreen: FC = () => {
  useEffect(() => {
    commonDataStore.loadBannerTemplateType();
  }, []);
  return (
    <>
      <Header />
      <Grid />
      <Form />
    </>
  );
};

export default BannerTemplateScreen;
