import { Table } from 'antd';
import i18next from 'i18next';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import ReactResizeDetector from 'react-resize-detector';

import { formatNumber } from '~utils/formatNumber';

import store from '../store';
import EditableCell from './EditCellRender';

const CustomerRevenue: FC = observer(() => {
  const { customerRevenueTableData, changeMappingCustomerLocation } = store;

  const columnsDef = [
    { title: 'Customer', dataIndex: 'name', key: 'name', width: 220 },
    {
      title: i18next.t('KPI'),
      dataIndex: 'kpiBooked',
      key: 'kpiBooked',
      width: 100,
      render: (text) => <span style={{ float: 'right' }}>{formatNumber(text)}</span>
    },
    {
      title: 'Click',
      dataIndex: 'kpiRunned',
      key: 'kpiRunned',
      width: 100,
      render: (text) => <span style={{ float: 'right' }}>{formatNumber(text)}</span>
    },
    {
      title: i18next.t('UNIT_PRICE'),
      dataIndex: 'unitPricePartner',
      key: 'unitPricePartner',
      width: 100,
      render: (text) => <span style={{ float: 'right' }}>{formatNumber(text)}</span>
    },
    {
      title: i18next.t('TOTAL_AMOUNT'),
      dataIndex: 'total',
      key: 'total',
      width: 180,
      render: (text, record) =>
        record.key.split('_&_')[0] === 'customer' ? (
          <EditableCell
            record={record}
            value={text}
            editable
            editField="revenuePartner"
            handleSave={(key, value, editField, editedValue) => {
              changeMappingCustomerLocation(key, value, editField, editedValue);
            }}
          />
        ) : (
          <span style={{ float: 'right' }}>{formatNumber(text)}</span>
        )
    }
  ];

  return (
    <ReactResizeDetector handleHeight>
      {({ height }) => {
        const tableHeight = height ?? 500;

        return (
          <Table
            size="small"
            style={{ position: 'relative' }}
            rowClassName={() => 'custom-config'}
            bordered
            columns={columnsDef}
            dataSource={customerRevenueTableData}
            pagination={false}
            scroll={{ y: tableHeight - 60 }}
          />
        );
      }}
    </ReactResizeDetector>
  );
});

export default CustomerRevenue;
