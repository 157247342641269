import { Tooltip } from 'antd';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { BookOutlined } from '@ant-design/icons';

import { flexCenter } from '~assets/css-classes';
import { dropdownMenu_header } from '~components/Layout/css';
import { useTranslation } from 'react-i18next';

const Instruction: FC = () => {
  const { t } = useTranslation();
  return (
    <span className={dropdownMenu_header}>
      <span className={flexCenter}>
        <Link to="/instruction" style={{ color: 'rgba(0,0,0,0.65)' }}>
          <BookOutlined style={{ fontSize: '16px', verticalAlign: 'middle' }} /> {t('MANUAL')}
        </Link>
      </span>
    </span>
  );
};

export default Instruction;
