import { Tabs, Tooltip } from 'antd';
import { observer } from 'mobx-react';
import React, { FC, useEffect } from 'react';
import ReactResizeDetector from 'react-resize-detector';

import { HeatMapOutlined, PushpinOutlined } from '@ant-design/icons';

import filterGrid from '~components/Filter/FilterRPLocation/store';

import Chart from './Chart';
import GridCampaign from './GridCampaign';
import GridLocation from './GridLocation';
import StatisticComponent from './Statistic';
import store from './store';

export const Report: FC = observer(() => {
  const { getReportIncome, setWhere, cleanUp } = store;

  useEffect(() => {
    getReportIncome();
    setWhere(filterGrid.where);
    return () => cleanUp();
  }, [filterGrid.where]);

  const { TabPane } = Tabs;

  return (
    <>
      <StatisticComponent />

      <ReactResizeDetector handleHeight handleWidth>
        {({ height, width }) => {
          return (
            <Tabs
              style={{
                backgroundColor: '#fff'
              }}
              size="small"
              tabPosition="left"
              defaultActiveKey={'Chart'}
            >
              <TabPane
                forceRender
                tab={
                  <Tooltip placement="right" title="Date">
                    <i className="fas fa-chart-bar" />
                  </Tooltip>
                }
                key="Chart"
              >
                <Chart widthChart={width - 90 || 1000} heightChart={height - 130 || 230} />
              </TabPane>

              <TabPane
                forceRender
                style={{ width: width - 90 || 1000, height: height - 130 || 560 }}
                tab={
                  <Tooltip placement="right" title="Campaign">
                    <i className="fas fa-th" />
                  </Tooltip>
                }
                key="Grid"
              >
                <Tabs
                  style={{
                    backgroundColor: '#fff'
                  }}
                  size="small"
                  defaultActiveKey={'Location'}
                >
                  <TabPane
                    forceRender
                    tab={
                      <span>
                        <HeatMapOutlined />
                        Location
                      </span>
                    }
                    style={{ width: width - 120 || 1000, height: height - 130 || 560 }}
                    key="Location"
                  >
                    <GridLocation />
                  </TabPane>

                  <TabPane
                    forceRender
                    style={{ width: width - 120 || 1000, height: height - 130 || 560 }}
                    tab={
                      <span>
                        <PushpinOutlined />
                        Campaign
                      </span>
                    }
                    key="Campaign"
                  >
                    <GridCampaign />
                  </TabPane>
                </Tabs>
              </TabPane>
            </Tabs>
          );
        }}
      </ReactResizeDetector>
    </>
  );
});
