import { groupBy } from 'lodash';
import { observer } from 'mobx-react';
import React, { ComponentType, FC, ReactElement } from 'react';
import Location from 'react-app-location';
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router-dom';

import {
  groupMenuId,
  ROLE_MENU_ACCOUNTS,
  ROLE_MENU_ACTIVE_CAMPAIGNS,
  ROLE_MENU_ASSIGN_CAMPAIGN,
  ROLE_MENU_ASSIGN_LOCATION,
  ROLE_MENU_ASSIGN_PROFILE,
  ROLE_MENU_AVAILABLE_KPI,
  ROLE_MENU_BANNER,
  ROLE_MENU_BANNER_IMAGE,
  ROLE_MENU_BANNER_TEMPLATE,
  ROLE_MENU_BOOK_CAMPAIGN,
  ROLE_MENU_BOOKING_ONLINE,
  ROLE_MENU_CAMPAIGN_BRAND,
  ROLE_MENU_CAMPAIGN_CONFIG,
  ROLE_MENU_CAMPAIGNS,
  ROLE_MENU_CAMPAIGNS_PRIORITY,
  ROLE_MENU_CATEGORY_GROUPS,
  ROLE_MENU_COMMON_DATA,
  ROLE_MENU_CUSTOMER,
  ROLE_MENU_CUSTOMER_TYPE,
  ROLE_MENU_DASHBOARD,
  ROLE_MENU_EQUIPMENT_BRAND,
  ROLE_MENU_EQUIPMENT_GROUP,
  ROLE_MENU_EQUIPMENT_MODEL,
  ROLE_MENU_EQUIPMENTS,
  ROLE_MENU_INCOME_CAMPAIGN,
  ROLE_MENU_INCOME_LOCATION,
  ROLE_MENU_INCOME_PARTNER,
  ROLE_MENU_INSTRUCTION,
  ROLE_MENU_KPI_MANAGEMENT,
  ROLE_MENU_LOCATION,
  ROLE_MENU_LOCATION_CATEGORY,
  ROLE_MENU_LOCATION_TAGS,
  ROLE_MENU_MANAGEMENT_PAYMENT,
  ROLE_MENU_MY_PROFILE,
  ROLE_MENU_PRICING,
  ROLE_MENU_PROFILE,
  ROLE_MENU_REPORT_CAMPAIGN,
  ROLE_MENU_REPORT_CAMPAIGN_LOCATION,
  ROLE_MENU_REPORT_CAMPAIGN_PARTNER,
  ROLE_MENU_REPORT_LOCATION,
  ROLE_MENU_REPORT_REVENUE,
  ROLE_MENU_REVENUE_CONFIG,
  ROLE_MENU_SETTING_REGION,
  ROLE_MENU_SWAP_CAMPAIGN,
  ROLE_MENU_LOGS
} from '@source/common';

import ErrorBoundary from '~components/UI/ErrorBoundary';
import { useStore } from '~context/react-context';

import ActiveCampaignScreen from './activeCampaigns/ActiveCampaignsScreen';
import LocationAlertScreen from './alert/locations/LocationAlertScreen';
import AssignCampaignScreen from './assignOwner/assign-campaign/AssignCampaignScreen';
import AssignLocationScreen from './assignOwner/assign-location/AssignLocationScreen';
import AssignProfileScreen from './assignOwner/assign-profile/AssignProfileScreen';
import BalanceScreen from './balance/BalanceScreen.lazy';
import BannerScreenNew from './banner-new/Screen.lazy';
import BookingOnlineScreen from './booking-online2/BookingOnlineScreen.lazy';
import CampaignBookingScreen from './campaign-booking/CampaignBookingScreen';
import CampaignConfigScreen from './campaign-config/CampaignConfigScreen';
import CampaignsScreen from './campaigns/CampaignsScreen';
import BannerTemplateScreen from './commonData/bannerTemplate/BannerTemplateScreen';
import BrandAPScreen from './commonData/brandAP/BrandAPScreen';
import BrandCampaignScreen from './commonData/brandCampaign/BrandCampaignScreen';
import CommonScreen from './commonData/common/CommonScreen';
import DeviceGroupScreen from './commonData/deviceGroup/DeviceGroupScreen';
import GroupCategoryScreen from './commonData/groupCategory/GroupCategoryScreen';
import InstructionScreen from './commonData/instruction/InstructionScreen';
import LocationCategoryScreen from './commonData/locationCategory/LocationCategoryScreen';
import ModelDeviceScreen from './commonData/modelDevice/ModelDeviceScreen';
import RegionScreen from './commonData/region/RegionScreen.lazy';
import TagsScreen from './commonData/tags/TagsScreen';
import CustomerScreen from './customer/CustomerScreen';
import CustomerTypeScreen from './customerType/CustomerTypeScreen';
import DashboardScreens from './dashboard-new/DashboardScreen';
import EquipmentScreen from './equipment/EquipmentScreen';
import ImageScreen from './image/ImageScreen';
import InventoryScreen from './inventory/InventoryScreen';
import KpiManagementScreen from './kpiManagement/KpiManagementScreen';
import LocationScreen from './location/LocationScreen';
import PaymentScreen from './payment/PaymentScreen';
import PricingScreen from './pricing/PricingScreen.lazy';
import EditorView from './priority/campaigns/CPEScreen';
import LocationsPriorityScreen from './priority/locations/LocationsPriorityScreen';
import ProfileScreen from './profile/ProfileScreen';
import ReportCampaignScreenNew from './report/reportCampaign/ReportCampaignScreen';
import ReportCampaignForPartnerScreen from './report/reportCampaignPartner/ReportCampaignParter';
import ReportIncomeCampaignScreen from './report/reportIncome/byCampaign/ReportIncomeCampaignScreen';
import ReportIncomeLocationScreen from './report/reportIncome/byLocation/ReportIncomeLocationScreen';
import ReportIncomePartnerScreen from './report/reportIncome/forPartner/ReportIncomePartnerScreen';
import ReportLocationsScreenNew from './report/reportLocation/ReportLocationsScreen';
import RevenueConfigScreen from './revenue/RevenueConfig/RevenueConfigScreen';
import RevenueScreen from './revenue/RevenueScreen';
import UserInfoScreen from './user/userInfo/UserInfoScreen';
import UserManagementScreen from './user/UserScreen';
import CampaignBasicScreen from './campaign-basic/CampaignBasicScreen';
import {
  BarChartOutlined,
  DashboardOutlined,
  FileImageOutlined,
  HistoryOutlined,
  PartitionOutlined,
  PictureOutlined,
  PushpinOutlined,
  SettingOutlined,
  WifiOutlined
} from '@ant-design/icons';
import LogsScreen from './logs/PlanScreen';

export interface MenuItem {
  name: string;
  path: string;
  component: ComponentType<RouteComponentProps> | ComponentType<any>;
  exact: boolean;
  hide?: boolean;
  groupId?: string;
  roleId?: string;
  icon?: ReactElement;
  isBasic?: boolean;
}

export interface MenuItems {
  name: string;
  path?: string;
  component?: ComponentType<RouteComponentProps> | ComponentType<any>;
  exact?: boolean;
  hide?: boolean;
  groupId?: string;
  roleId?: string;
  children?: MenuItem[];
  icon?: ReactElement;
  isBasic?: boolean;
}

// Default
export const LoginLocation = new Location('/login');
export const Dashboards = new Location('/dashboard');
export const LocationAlert = new Location('/alert/location');

// Banner
export const ImageLocation = new Location('/images');
export const BannerTemplateLocation = new Location('/banner-templates');
export const BannerLocation = new Location('/banners');
export const BannerNewLocation = new Location('/banner-new');

// Location
export const LocationPath = new Location('/location');
export const EquipmentLocation = new Location('/equipment');

// Campaign
export const CampaignsLocation = new Location('/campaigns');
export const CampaignsBasicLocation = new Location('/campaigns-basic');
export const CampaignBookingLocation = new Location('/campaigns/booking');
export const CampaignConfigScreenLocation = new Location('/campaigns/config');

// User
export const UserLocation = new Location('/users');
export const NotificationLocation = new Location('/notification');
export const AccountSettingsLocation = new Location('/account/settings');
export const ProfileLocation = new Location('/profiles');
export const PaymentLocation = new Location('/payments');

// Booking
export const KPIManagementLocation = new Location('/kpiManagement');
export const InventoryTable = new Location('/inventory');
export const BookingOnlineLocation = new Location('/campaigns/booking-online');

// Report
export const ReportIncomeByCampaign = new Location('/reports/income/by-campaign');
export const ReportIncomeByLocation = new Location('/reports/income/by-location');
export const ReportIncomeForPartner = new Location('/reports/income/partner');
export const ReportCampaign = new Location('/report/campaign');
export const ReportLocation = new Location('/report/location');
export const ReportCampaignForPartner = new Location('/reports/report-campaign-for-partner');

export const exampleLocation = new Location('/example');

// Order
export const OrderHistoryLocation = new Location('/order/history');
export const OrderDetailLocation = new Location('/order/detail');
export const OrderResultLocation = new Location('/order/result');

// Setting
export const CommonLocation = new Location('/common');
export const InstructionLocation = new Location('/instruction');
export const DeviceGroupLocation = new Location('/deviceGroup');
export const PlanLocation = new Location('/plan');
export const UserManual = new Location('/create-guide');
export const ShowUserGuide = new Location('/list-user-guide');
export const ShowDetailGuide = new Location('/list-user-guide/detail');
export const LocationCategory = new Location('/LocationCategory');
export const ModelDevice = new Location('/ModelDevice');
export const BrandAP = new Location('/BrandAP');
export const Tags = new Location('/Tags');
export const BrandCampaign = new Location('/BrandCampaign');
export const GroupCategory = new Location('/GroupCategory');
export const RegionLocation = new Location('/common-region');

// Assign
export const AssignCampaignScreenLocation = new Location('/assignCampaign');
export const AssignLocationScreenLocation = new Location('/assignLocation');
export const AssignProfileScreenLocation = new Location('/assignProfile');

// HelpCenter
export const HelpCenterPath = new Location('/help-center');

// Revenue
export const RevenueScreenLocation = new Location('/revenue/campaigns');
export const RevenueConfigScreenLocation = new Location('/revenue/config');

// History
export const BalanceHistory = new Location('/history');

// Priority
export const CampaignsPriorityPath = new Location('/location/campaigns-priority');
export const LocationsPriorityPath = new Location('/location/locations-priority');

// Active Campaign
export const ActiveCampaignsPath = new Location('/activeCampaigns');

// Customer
export const CustomerTypePath = new Location('/customer-type');
export const CustomerPath = new Location('/customer');

// Balance
export const BalanceLocation = new Location('/balance');

// Pricing
export const PricingLocation = new Location('/pricing');

// Logs
export const Logs = new Location('/logs');

export const routes: MenuItems[] = [
  // Dashboard
  {
    name: 'DASHBOARD',
    path: Dashboards.path,
    component: DashboardScreens,
    exact: false,
    groupId: groupMenuId.dashboard,
    roleId: ROLE_MENU_DASHBOARD,
    isBasic: true,
    icon: <DashboardOutlined />
  },
  {
    name: 'HOTSPOT',
    groupId: groupMenuId.hotspot,
    icon: <WifiOutlined />,
    isBasic: true,
    children: [
      {
        name: 'LOCATION_LIST',
        path: LocationPath.path,
        component: LocationScreen,
        exact: true,
        groupId: groupMenuId.hotspot,
        roleId: ROLE_MENU_LOCATION
      },
      {
        name: 'EQUIPMENT_LIST',
        path: EquipmentLocation.path,
        component: EquipmentScreen,
        exact: true,
        groupId: groupMenuId.hotspot,
        roleId: ROLE_MENU_EQUIPMENTS
      },
      {
        name: 'CAMPAIGNS_PRIORITY',
        path: CampaignsPriorityPath.path,
        component: EditorView,
        exact: true,
        groupId: groupMenuId.hotspot,
        roleId: ROLE_MENU_CAMPAIGNS_PRIORITY
      }
    ]
  },
  {
    name: 'CREATIVE_ADS',
    path: BannerNewLocation.path,
    component: BannerScreenNew,
    groupId: groupMenuId.campaignBasic,
    exact: false,
    roleId: ROLE_MENU_BANNER,
    isBasic: true,
    icon: <PictureOutlined />
  },
  {
    name: 'CAMPAIGN_BASIC_LIST',
    path: CampaignsBasicLocation.path,
    component: CampaignBasicScreen,
    exact: false,
    hide: true,
    groupId: groupMenuId.campaignBasic,
    roleId: ROLE_MENU_CAMPAIGNS,
    isBasic: true,
    icon: <PushpinOutlined />
  },
  {
    name: 'CAMPAIGN',
    groupId: groupMenuId.campaign,
    icon: <PushpinOutlined />,
    children: [
      {
        name: 'BOOK_CAMPAIGN',
        path: CampaignBookingLocation.path,
        component: CampaignBookingScreen,
        groupId: groupMenuId.campaign,
        exact: true,
        roleId: ROLE_MENU_BOOK_CAMPAIGN
      },
      {
        name: 'CAMPAIGN_LIST',
        path: CampaignsLocation.path,
        component: CampaignsScreen,
        exact: true,
        groupId: groupMenuId.campaign,
        roleId: ROLE_MENU_CAMPAIGNS
      },
      {
        name: 'CAMPAIGN_CONFIG',
        path: CampaignConfigScreenLocation.path,
        component: CampaignConfigScreen,
        exact: true,
        hide: true,
        groupId: groupMenuId.campaign,
        roleId: ROLE_MENU_CAMPAIGN_CONFIG
      },
      {
        name: 'KPI_MANAGEMENT',
        path: KPIManagementLocation.path,
        component: KpiManagementScreen,
        groupId: groupMenuId.campaign,
        exact: true,
        roleId: ROLE_MENU_KPI_MANAGEMENT
      },
      {
        name: 'LOCATIONS_PRIORITY',
        path: LocationsPriorityPath.path,
        component: LocationsPriorityScreen,
        exact: true,
        groupId: groupMenuId.campaign,
        roleId: ROLE_MENU_SWAP_CAMPAIGN
      },
      {
        name: 'AVAILABLE_KPI',
        path: InventoryTable.path,
        component: InventoryScreen,
        exact: true,
        groupId: groupMenuId.campaign,
        roleId: ROLE_MENU_AVAILABLE_KPI
      },
      {
        name: 'BOOKING_ONLINE',
        path: BookingOnlineLocation.path,
        component: BookingOnlineScreen,
        groupId: groupMenuId.campaign,
        exact: true,
        // hide: true,
        roleId: ROLE_MENU_BOOKING_ONLINE
      }
    ]
  },
  {
    name: 'REPORT',
    groupId: groupMenuId.report,
    icon: <BarChartOutlined />,
    isBasic: true,
    children: [
      {
        name: 'CAMPAIGN_BOOKED',
        path: ReportCampaign.path,
        component: ReportCampaignScreenNew,
        exact: true,
        groupId: groupMenuId.report,
        roleId: ROLE_MENU_REPORT_CAMPAIGN
      },
      // {
      //   name: 'CAMPAIGN_ADS',
      //   path: ReportCampaignForPartner.path,
      //   component: ReportCampaignForPartnerScreen,
      //   exact: true,
      //   // hide: true,
      //   groupId: groupMenuId.report,
      //   roleId: ROLE_MENU_REPORT_CAMPAIGN_LOCATION
      // },
      {
        name: 'TRAFFIC_LOCATION',
        path: ReportLocation.path,
        component: ReportLocationsScreenNew,
        exact: true,
        groupId: groupMenuId.report,
        roleId: ROLE_MENU_REPORT_LOCATION
      }
    ]
  },

  // User
  {
    name: 'PERMISSION',
    groupId: groupMenuId.permission,
    icon: <PartitionOutlined />,
    children: [
      {
        name: 'USER_LIST',
        path: UserLocation.path,
        component: UserManagementScreen,
        exact: true,
        groupId: groupMenuId.permission,
        roleId: ROLE_MENU_ACCOUNTS
      },
      {
        name: 'USER_SETTING',
        path: AccountSettingsLocation.path,
        component: UserInfoScreen,
        exact: true,
        hide: true,
        groupId: groupMenuId.user
        // roleId: ROLE_MENU_MY_PROFILE // authenticated
      },
      {
        name: 'PROFILE_LIST',
        path: ProfileLocation.path,
        component: ProfileScreen,
        exact: true,
        groupId: groupMenuId.permission,
        roleId: ROLE_MENU_PROFILE
      },
      {
        name: 'PAYMENT_LIST',
        path: PaymentLocation.path,
        component: PaymentScreen,
        exact: true,
        groupId: groupMenuId.permission,
        roleId: ROLE_MENU_MANAGEMENT_PAYMENT
      },
      {
        name: 'ASSIGN_CAMPAIGN',
        path: AssignCampaignScreenLocation.path,
        component: AssignCampaignScreen,
        groupId: groupMenuId.permission,
        exact: true,
        roleId: ROLE_MENU_ASSIGN_CAMPAIGN
      },
      {
        name: 'ASSIGN_LOCATION',
        path: AssignLocationScreenLocation.path,
        component: AssignLocationScreen,
        groupId: groupMenuId.permission,
        exact: true,
        roleId: ROLE_MENU_ASSIGN_LOCATION
      },
      {
        name: 'ASSIGN_PROFILE',
        path: AssignProfileScreenLocation.path,
        component: AssignProfileScreen,
        groupId: groupMenuId.permission,
        exact: true,
        roleId: ROLE_MENU_ASSIGN_PROFILE
      },
      {
        name: 'CUSTOMER_TYPE',
        path: CustomerTypePath.path,
        component: CustomerTypeScreen,
        groupId: groupMenuId.permission,
        exact: true,
        roleId: ROLE_MENU_CUSTOMER_TYPE
      },
      {
        name: 'CUSTOMER',
        path: CustomerPath.path,
        component: CustomerScreen,
        groupId: groupMenuId.permission,
        exact: true,
        roleId: ROLE_MENU_CUSTOMER
      }
    ]
  },

  // Setting
  {
    name: 'SETTING',
    groupId: groupMenuId.system,
    icon: <SettingOutlined />,
    children: [
      {
        name: 'COMMON_DATA',
        path: CommonLocation.path,
        component: CommonScreen,
        exact: true,
        groupId: groupMenuId.system,
        roleId: ROLE_MENU_COMMON_DATA
      },
      {
        name: 'BANNER_IMAGE',
        path: ImageLocation.path,
        component: ImageScreen,
        groupId: groupMenuId.system,
        exact: true,
        roleId: ROLE_MENU_BANNER_IMAGE
      },
      {
        name: 'BANNER_TEMPLATE',
        path: BannerTemplateLocation.path,
        component: BannerTemplateScreen,
        exact: true,
        groupId: groupMenuId.system,
        roleId: ROLE_MENU_BANNER_TEMPLATE
      },

      {
        name: 'EQUIPMENT_MODEL',
        path: ModelDevice.path,
        component: ModelDeviceScreen,
        groupId: groupMenuId.system,
        exact: true,
        roleId: ROLE_MENU_EQUIPMENT_MODEL
      },
      {
        name: 'EQUIPMENT_BRAND',
        path: BrandAP.path,
        component: BrandAPScreen,
        groupId: groupMenuId.system,
        exact: true,
        roleId: ROLE_MENU_EQUIPMENT_BRAND
      },
      {
        name: 'LOCATION_TAGS',
        path: Tags.path,
        component: TagsScreen,
        exact: true,
        groupId: groupMenuId.system,
        roleId: ROLE_MENU_LOCATION_TAGS
      },
      {
        name: 'CAMPAIGN_BRAND',
        path: BrandCampaign.path,
        component: BrandCampaignScreen,
        groupId: groupMenuId.system,
        exact: true,
        roleId: ROLE_MENU_CAMPAIGN_BRAND
      },
      {
        name: 'LOCATION_CATEGORY_GROUP',
        path: GroupCategory.path,
        component: GroupCategoryScreen,
        groupId: groupMenuId.system,
        exact: true,
        roleId: ROLE_MENU_CATEGORY_GROUPS
      },
      {
        name: 'EQUIPMENT_GROUP',
        path: DeviceGroupLocation.path,
        component: DeviceGroupScreen,
        groupId: groupMenuId.system,
        exact: true,
        roleId: ROLE_MENU_EQUIPMENT_GROUP
      },
      {
        name: 'LOCATION_CATEGORY',
        path: LocationCategory.path,
        component: LocationCategoryScreen,
        exact: true,
        groupId: groupMenuId.system,
        roleId: ROLE_MENU_LOCATION_CATEGORY
      },
      {
        name: 'COMMON_REGION',
        path: RegionLocation.path,
        component: RegionScreen,
        exact: true,
        groupId: groupMenuId.system,
        roleId: ROLE_MENU_SETTING_REGION
      }
    ]
  },

  // Logs
  {
    name: 'LOGS',
    path: Logs.path,
    component: LogsScreen,
    exact: false,
    groupId: groupMenuId.logs,
    roleId: ROLE_MENU_LOGS,
    icon: <HistoryOutlined />
  },

  // INSTRUCTION
  {
    name: 'INSTRUCTION',
    path: InstructionLocation.path,
    component: InstructionScreen,
    groupId: groupMenuId.system,
    exact: true,
    hide: true,
    roleId: ROLE_MENU_INSTRUCTION
  },

  // {
  //   name: 'Location Alert',
  //   path: LocationAlert.path,
  //   component: LocationAlertScreen,
  //   exact: true,
  //   groupId: groupMenuId.dashboard,
  //   roleId: ROLE_MENU_DASHBOARD
  // },
  // {
  //   name: 'BANNER_LIST_OLD',
  //   path: BannerLocation.path,
  //   component: BannerScreen,
  //   groupId: groupMenuId.campaign,
  //   exact: true,
  //   hide: true,
  //   roleId: ROLE_MENU_BANNER
  // },

  // Booking Online
  {
    name: 'BALANCE',
    path: BalanceLocation.path,
    component: BalanceScreen,
    groupId: groupMenuId.user,
    exact: true,
    hide: true,
    roleId: ROLE_MENU_BOOKING_ONLINE
  },

  // {
  //   name: 'PRICING_LIST',
  //   path: PricingLocation.path,
  //   component: PricingScreen,
  //   exact: true,
  //   groupId: groupMenuId.inventory,
  //   roleId: ROLE_MENU_PRICING
  // },
  // Priority

  // Revenue
  {
    name: 'REVENUE_CONFIG',
    path: RevenueConfigScreenLocation.path,
    component: RevenueConfigScreen,
    hide: true,
    exact: true,
    groupId: groupMenuId.campaign,
    roleId: ROLE_MENU_REVENUE_CONFIG
  },

  // {
  //   name: 'REVENUE',
  //   path: RevenueScreenLocation.path,
  //   component: RevenueScreen,
  //   exact: true,
  //   groupId: groupMenuId.campaign,
  //   roleId: ROLE_MENU_REPORT_REVENUE
  // },

  // {
  //   name: 'ACTIVE_CAMPAIGN',
  //   path: ActiveCampaignsPath.path,
  //   component: ActiveCampaignScreen,
  //   exact: true,
  //   groupId: groupMenuId.inventory,
  //   roleId: ROLE_MENU_ACTIVE_CAMPAIGNS
  // },

  // Income
  {
    name: 'INCOME_BY_CAMPAIGN',
    path: ReportIncomeByCampaign.path,
    component: ReportIncomeCampaignScreen,
    exact: true,
    hide: true,
    groupId: groupMenuId.income,
    roleId: ROLE_MENU_INCOME_CAMPAIGN
  },
  {
    name: 'INCOME_BY_LOCATION',
    path: ReportIncomeByLocation.path,
    component: ReportIncomeLocationScreen,
    exact: true,
    hide: true,
    groupId: groupMenuId.income,
    roleId: ROLE_MENU_INCOME_LOCATION
  },
  {
    name: 'INCOME_FOR_PARTNER',
    path: ReportIncomeForPartner.path,
    component: ReportIncomePartnerScreen,
    exact: true,
    hide: true,
    groupId: groupMenuId.income,
    roleId: ROLE_MENU_INCOME_PARTNER
  }
];
export const groupRoute = groupBy(routes, 'groupId');

const PrivateRoute: FC<RouteProps> = observer((props) => {
  const { authStore } = useStore();
  if (!authStore.isAuthenticated) return <Redirect to="/login" />;

  return (
    <ErrorBoundary>
      <Route {...props} />
    </ErrorBoundary>
  );
});

const Routes: FC = () => {
  const allRoutes = [];
  routes.forEach((r) => {
    if (!r.children) {
      allRoutes.push(r);
    } else {
      r.children.forEach((child) => {
        allRoutes.push(child);
      });
    }
  });
  return (
    <>
      {allRoutes.map((r) => (
        <PrivateRoute key={r.name} path={r.path} component={r.component} exact={r.exact} />
      ))}
    </>
  );
};

export default Routes;
