import { isEmpty } from 'lodash';
import { action, computed, observable, reaction, runInAction } from 'mobx';
import { actionAsync, task } from 'mobx-utils';
import moment from 'moment';

import {
  BasicCustomerFragment,
  Categories,
  CommonLocationFragment,
  FullRegionFragment
} from '~graphql/_sdk';
import { getSdk } from '~graphql/sdk';

class FilterLocationsStore {
  @observable public date;
  @observable public filter;
  @observable isDisctrict: boolean;
  @observable locationLoading: boolean;
  @observable sharedTrafficLoading: boolean;
  @observable selectedLocations;
  @observable locations: CommonLocationFragment[];
  @observable categories: Categories[];
  @observable categoryLoading: boolean;
  @observable valueDistrict;
  @observable valueCustomer;
  @observable.ref public dataSharedTraffic: BasicCustomerFragment[];
  @observable.ref public valueCategory: any;
  @observable public where: any;
  @observable.ref public disctrict: FullRegionFragment[];
  @observable.ref public tmp: FullRegionFragment[];
  @observable.ref public locationsByCondition: CommonLocationFragment[];
  @observable.ref public locationsByDistrict: CommonLocationFragment[];
  @observable public shareTrafficWhere: any;
  public city: FullRegionFragment[];

  constructor() {
    runInAction(() => {
      this.cleanUp();
    });

    reaction(
      () => this?.where?.city,
      w => {
        if (isEmpty(w)) {
          this.valueDistrict = [];
          this.where.district = [];
        }
      }
    );

    reaction(
      () => this.filter,
      filter => {
        this.loadAllLocations(filter);
      }
    );
  }

  @action
  public cleanUp = () => {
    this.dataSharedTraffic = [];
    this.valueCustomer = [];
    this.filter = {};
    this.date = {
      gte: moment()
        .subtract(30, 'days')
        .startOf('day'),
      lte: moment().startOf('day')
    };
    this.shareTrafficWhere = {
      customerId: undefined,
      date: { ...this.date }
    };
    this.where = { date: { ...this.date } };
    this.selectedLocations = [];
    this.locationsByCondition = [];
    this.locations = [];
    this.categories = [];
    this.tmp = [];
    this.disctrict = [];
    this.city = [];
  };

  @action
  public initData = (value, type: string) => {
    if (type === 'city') {
      this.city = value;
    }
    if (type === 'disctrict') {
      this.disctrict = value;
    }
  };

  @computed
  get districtByCity() {
    if (!isEmpty(this.tmp)) {
      return this.tmp;
    }
    return this.disctrict;
  }

  @actionAsync
  public loadSharedTrafficCustomer = async () => {
    this.sharedTrafficLoading = true;
    const sdk = await task(getSdk());
    const { findManyCustomer } = await task(
      sdk.findManyCustomer({ where: { shareTraffic: { enabled: true } } })
    );
    this.dataSharedTraffic = findManyCustomer;
    this.sharedTrafficLoading = false;
  };

  @actionAsync
  public loadAllLocations = async (where = {}) => {
    this.locationLoading = true;
    const sdk = await task(getSdk());
    const data = await task(sdk.findCommonLocation({ where }));
    this.locations = data.findManyLocation;
    this.locationLoading = false;
  };

  @actionAsync
  public getCategories = async () => {
    this.categoryLoading = true;
    const sdk = await task(getSdk());
    const { getCategories } = await task(sdk.getCategories());
    this.categories = getCategories;
    this.categoryLoading = false;
  };

  @action
  private setFilter = (data, field: string) => {
    this.filter = {
      ...this.filter,
      _operators: { ...this.filter._operators, [field]: { in: data } }
    };
    if (isEmpty(data)) {
      delete this.filter._operators[field];
    }
  };

  @action
  public resetFilter = () => {
    delete this.where.customerId;
  };

  @action
  public handleOnChange = (value, type: string, options?, sharetab = false) => {
    switch (type) {
      case 'cate':
        this.setFilter(value, 'categoryId');
        this.where = { ...this.where, categoryId: value };
        break;
      case 'city':
        this.tmp.length = 0;
        options.forEach(i => {
          this.tmp = [...this.tmp, ...this.disctrict.filter(d => d.city === i.label)];
        });
        this.setFilter(
          options.map(i => i.label),
          'city'
        );
        this.where = { ...this.where, city: options.map(i => i.label) };
        break;
      case 'district':
        this.setFilter(
          options.map(i => i.key.split('+')[1]),
          'district'
        );
        this.valueDistrict = value;
        this.where = { ...this.where, district: options.map(i => i.key.split('+')[1]) };
        break;
      case 'location':
        this.selectedLocations = value;
        this.where = { ...this.where, _id: value.map(i => i.value) };
        break;
      case 'customer':
        this.shareTrafficWhere = { ...this.shareTrafficWhere, customerId: value?.value };
        if (!value) {
          delete this.shareTrafficWhere.customerId;
        }
        break;
      case 'tags':
        this.setFilter(value, 'tags');
        this.where = { ...this.where, tag: value };
        break;
      case 'date':
        this.date = { gte: value[0], lte: value[1] };
        this.where = {
          ...this.where,
          date: {
            gte: value[0] !== undefined ? moment(value[0]).subtract(12, 'h') : value[0],
            lte: value[1]
          }
        };
        if (sharetab) {
          this.shareTrafficWhere = {
            ...this.shareTrafficWhere,
            date: {
              gte: value[0] !== undefined ? moment(value[0]).subtract(12, 'h') : value[0],
              lte: value[1]
            }
          };
        }
        if (!this.date?.gte || !this.date?.lte) {
          delete this.where.date;
        }
        break;
      default:
        break;
    }
  };
}
export default new FilterLocationsStore();
