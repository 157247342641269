import { Row } from 'antd';
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';

export default class CustomNoRowsOverlay extends Component<any> {
  render() {
    const { noRowsMessageFunc } = this.props;
    return (
      <Row
        style={{
          background: 'white',
          height: 'calc(100% - 100px)'
        }}
      >
        {noRowsMessageFunc()}
      </Row>
    );
  }
}
