import '@ant-design/compatible/assets/index.css';

import { Card, Col, Descriptions, Input, Radio, Row, Typography } from 'antd';
import { debounce, get, isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import shortId from 'shortid';

import { Form } from '@ant-design/compatible';
import { ArrowRightOutlined } from '@ant-design/icons';
import { optimizeTypes, UNLIMITED } from '@source/common';

import { UISelect } from '~components/UI/Select/UISelect';
import UIField from '~components/UI/UIField';
import { useStore } from '~context/react-context';
import { createMultiLevelObject } from '~utils/createMultiLevelObject';
import { format } from '~utils/formatDate';
import { formatNumber } from '~utils/formatNumber';

import store from './store';

const { Item } = Form;
const { TextArea } = Input;
const { Item: DescItem } = Descriptions;
const { Text } = Typography;

const CampaignInfoForm = observer(({ formProps }) => {
  const { t } = useTranslation();
  const { commonDataStore } = useStore();
  const {
    locationCheckedList,
    bookingRangeDate: { to, from },
    setOptimizeType,
    optimizeType,
    totalBooking,
    updateCampaignTemp,
    campaignTemp
  } = store;

  const initialValue = {
    name: get(campaignTemp, 'name'),
    type: get(campaignTemp, 'type'),
    typeBrand: get(campaignTemp, 'typeBrand'),
    landingPageUrl: get(campaignTemp, 'config.landingPageUrl'),
    description: get(campaignTemp, 'description'),
    times: get(campaignTemp, 'times'),
    random_percent: get(campaignTemp, 'random_percent'),
  };

  const onClickCard = (value) => {
    setOptimizeType(value);
  };

  const onChange = (e, field: string[]) => {
    if (!field.length) return;
    if (field.length === 1) {
      updateCampaignTemp({ [`${field}`]: e });
    }
    if (field.length > 1) {
      const obj = createMultiLevelObject(field, e);
      updateCampaignTemp(obj);
    }
  };

  const onInputChange = debounce(
    (e, field: string[]) => {
      onChange(e, field);
    },
    500,
    { leading: false, trailing: true }
  );

  return (
    <Row gutter={16} style={{ padding: 16 }}>
      <Col span={8}>
        <Descriptions size="small" column={1} title={t('campaign.INFO')} bordered>
          <DescItem label={t('SCHEDULE')}>
            {format(from, 'DD/MM/YYYY')} <ArrowRightOutlined />{' '}
            {!isEmpty(to) ? format(to, 'DD/MM/YYYY') : 'Null'}
          </DescItem>

          <DescItem label={t('TOTAL_BOOKING')}>{formatNumber(totalBooking) || UNLIMITED}</DescItem>

          <DescItem label={t('TOTAL_LOCATIONS')}>{locationCheckedList.length}</DescItem>
        </Descriptions>
      </Col>

      <Col span={16}>
        <Form layout="vertical">
          <Row gutter={16}>
            <Col span={12}>
              <UIField
                {...formProps}
                label={t('CAMPAIGN_NAME')}
                name="name"
                fieldProps={{
                  placeholder: t('CAMPAIGN_NAME'),
                  onChange: (value) => {
                    onChange(value, ['name']);
                  }
                }}
                defaultValue={initialValue.name}
                fieldComponent={Input}
              />
            </Col>
            <Col span={12}>
              <Item label={t('TYPE_CAMPAIGN')}>
                <UISelect
                  placeholder={t('COMMON_DATA_PLACEHOLDER_TYPE')}
                  dataOptions={{
                    data: commonDataStore.campaignType,
                    valueField: 'value',
                    textField: 'text'
                  }}
                  onChange={(value) => onChange(value, ['type'])}
                  value={initialValue.type}
                />
              </Item>
            </Col>

            <Col span={12}>
              <Item label={t('TYPE_BRAND_CAMPAIGN')}>
                <UISelect
                  placeholder={t('TYPE_OF_BRAND')}
                  mode="multiple"
                  allowClear
                  dataOptions={{
                    data: commonDataStore.campaignTypeBrand,
                    valueField: 'value',
                    textField: 'text'
                  }}
                  onChange={(value) => {
                    onChange(value, ['typeBrand']);
                  }}
                  value={initialValue.typeBrand}
                />
              </Item>
            </Col>
            <Col span={12}>
              <Item label={t('LANDING_PAGE')}>
                <Input
                  placeholder="https://meganet.com.vn/"
                  onChange={(value) =>
                    onInputChange(value.target.value, ['config', 'landingPageUrl'])
                  }
                  defaultValue={initialValue.landingPageUrl}
                />
              </Item>
            </Col>
            <Col span={12}>
              <Item label={t('TIMES')}>
                <Input
                  placeholder="8-10,11-20"
                  onChange={(value) => onInputChange(value.target.value, ['times'])}
                  defaultValue={initialValue.times}
                />
              </Item>
            </Col>
            <Col span={12}>
              <Item label={t('RANDOM_PERCENT')}>
                <Input
                  placeholder="0-100%"
                  onChange={(value) => onInputChange(value.target.value, ['random_percent'])}
                  defaultValue={initialValue.random_percent}
                />
              </Item>
            </Col>
          </Row>
          <Item label={t('OPTIMIZATION_TYPE')} labelAlign="left" style={{ marginBottom: '8px' }}>
            <Radio.Group value={optimizeType} style={{ display: 'flex' }}>
              {optimizeTypes.map(({ name, desc, sub }) => {
                return (
                  <Card
                    onClick={() => onClickCard(name)}
                    hoverable
                    style={{
                      borderColor: name === optimizeType ? '#000' : undefined,
                      textAlign: 'center',
                      fontSize: 13
                    }}
                    key={shortId.generate()}
                  >
                    <Text strong>{name}</Text>
                    <br />
                    <Text strong>{desc}</Text>
                    <br />
                    <Text type="secondary">{sub}</Text>
                    <br />
                    <Radio value={name} />
                  </Card>
                );
              })}
            </Radio.Group>
          </Item>
          <Item label={t('DESCRIPTION')}>
            <TextArea
              placeholder={t('ENTER_DESCRIPTION')}
              onChange={(value) => onInputChange(value.target.value, ['description'])}
              defaultValue={initialValue.description}
            />
          </Item>
        </Form>
      </Col>
    </Row>
  );
});

export default CampaignInfoForm;
