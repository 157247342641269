import { intersection } from 'lodash';

export type FilterLevelItem = { field: string; value: string[] };

export const filterMultiLevel = (data: any[], filterSeries: FilterLevelItem[]) => {
  filterSeries.forEach((item) => {
    data = data.filter((d) => {
      if (!d[item.field]) return false;
      return Array.isArray(d[item.field])
        ? intersection(item.value, d[item.field]).length > 0
        : item.value.includes(d[item.field]);
    });
  });

  return data;
};
