/* eslint-disable prefer-const */
import { debounce, reverse } from 'lodash';
import { action, computed, observable, runInAction } from 'mobx';
import { actionAsync, task } from 'mobx-utils';

import { RUNNING } from '@source/common';

import { SuccessNotify } from '~components/UI/Notification';
import { ResultInput } from '~graphql/_sdk';
import { getSdk } from '~graphql/sdk';
import { upperCaseFirstLetter } from '~utils/upperCaseFirstLetter';

class LocationsPriorityStore {
  @observable public loading: boolean;
  @observable public dataAdvance: any;
  @observable public gridAdvance: any;
  @observable public commonLocations: any;
  @observable public status: string;
  @observable public locations: ResultInput[];
  @observable public checked: boolean;
  @observable public checkedList: any;
  @observable public firstCampaign: string;
  @observable public secondCampaign: string;
  @observable public search: string;

  constructor() {
    this.resetFields();
  }

  @action
  public resetFields = () => {
    this.search = '';
    this.loading = true;
    this.firstCampaign = undefined;
    this.secondCampaign = undefined;
    this.locations = [];
    this.checkedList = [];
    this.checked = true;
    this.status = 'switch';
    this.commonLocations = [];
    this.gridAdvance = [];
    this.dataAdvance = [];
  };

  @action
  public setId = (id, status) => {
    if (status === 'first') {
      this.firstCampaign = id;
    }

    if (status === 'second') {
      this.secondCampaign = id;
    }
    this.gridAdvance = [this.firstCampaign, this.secondCampaign];
    if (this.firstCampaign && this.secondCampaign) {
      this.getCommonLocations();
    }
  };

  @action
  public handleSearch = (value) => {
    runInAction(() => {
      this.search = value;
    });
  };

  @computed
  get filterCommonLocations() {
    if (this.search) {
      const re = new RegExp(`${this.search}`, 'gi');
      return this.commonLocations.filter((i) => re.test(i.locationName));
    }
    return this.commonLocations;
  }

  @actionAsync
  public getCommonLocations = async () => {
    this.loading = true;
    const sdk = await task(getSdk());
    const { findCommonLocations } = await task(
      sdk.findCommonLocations({ campaign: this.gridAdvance })
    );
    this.commonLocations = findCommonLocations;
    if (this.checked) {
      this.locations = this.commonLocations;
    }
    this.loading = false;
  };

  @actionAsync
  public fetchCampaign = async () => {
    const sdk = await task(getSdk());
    const { findManyCampaign } = await task(
      sdk.findManyCampaign({ where: { status: RUNNING }, sort: { name: 'ASC' as any } })
    );
    this.dataAdvance = findManyCampaign;
  };

  @actionAsync
  public updateMultiPriority = async () => {
    this.loading = true;
    if (this.gridAdvance.length < 2) {
      return;
    }
    const sdk = await task(getSdk());
    const {
      updateMultiLocations: { success }
    } = await task(
      sdk.updateMultiLocations({
        campaign: this.gridAdvance,
        status: this.status,
        locations: this.locations
      })
    );
    SuccessNotify(upperCaseFirstLetter(this.status));
    this.loading = false;
  };

  @action
  public swapCampaign = () => {
    let tmp;
    tmp = this.secondCampaign;
    this.secondCampaign = this.firstCampaign;
    this.firstCampaign = tmp;
    reverse(this.gridAdvance);
  };

  @action
  public onCheckAll = (e) => {
    if (e) {
      this.checkedList = this.commonLocations;
      this.locations = this.commonLocations;
    } else {
      this.checkedList = [];
    }
    this.checked = e;
  };

  @action
  public onChange = (e) => {
    const locationsParam = e.reduce((all, i) => {
      all = [...all, { locationId: i.locationId }];
      return all;
    }, []);
    this.checkedList = e;
    this.checked = this.checkedList.length === this.commonLocations.length;
    this.locations = locationsParam;
  };

  @action
  public setSwitch = (e) => {
    if (!e) {
      this.status = 'switch';
    } else {
      this.status = 'force';
    }
  };
}

export default new LocationsPriorityStore();
