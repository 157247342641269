import '@ant-design/compatible/assets/index.css';

import { Button, Descriptions, PageHeader, Spin, Tabs } from 'antd';
import i18next from 'i18next';
import { get, set } from 'lodash';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import useForm from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ReactResizeDetector from 'react-resize-detector';
import * as yup from 'yup';

import { Form } from '@ant-design/compatible';
import { LoadingOutlined } from '@ant-design/icons';

import { useStore } from '~context/react-context';
import { DATE_FORMAT, format } from '~utils/formatDate';
import useQuery from '~utils/useQuery';

import ConfigBanner from './banner-config/BannerConfig';
import CRMConfig from './crm-config/CRMConfig';
import LocationGroupConfig from './location-group-config/LocationGroupConfig';
import RedirectPageCongig from './redirect-page-config/RedirectPageConfig';
import store from './store';
import TargetObjectConfig from './target-object-config/TargetObjectConfig';
import WebhookApiConfig from './webhook-api/WebhookAPI';
import { LICENSE } from '@source/common';

interface UrlValue {
  landingPageUrl?: string;
  impressionUrl?: string;
  fb?: {
    appId?: string;
    appSecret?: string;
    accountKitSecret?: string;
    feed?: string;
    feedBody?: {
      link?: string;
      name?: string;
      caption?: string;
      description?: string;
      picture?: string;
    };
  };
  gg?: {
    clientId?: string;
    clientSecret?: string;
  };
  webhook?: {
    api_url?: string;
    timeout?: number;
    enabled?: string;
    method?: string;
    token?: string;
    params?: any[];
  };
}

const urlSchema = yup.object().shape<UrlValue>({
  landingPageUrl: yup
    .string()
    .matches(/((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}/, 'Value should be in URL format!')
    .required(i18next.t('REDIRECT_PAGE_IS_REQUIRED'))
  // landingPageUrl: yup
  //   .string()
  //   .required(i18next.t('REDIRECT_PAGE_IS_REQUIRED'))
  //   .url('Value should be in URL format')
});

const { TabPane } = Tabs;

const CampaignConfigScreen = observer(() => {
  const { t } = useTranslation();
  const formProps = useForm<UrlValue>({ validationSchema: urlSchema });
  const { commonDataStore } = useStore();
  const { updateConfig, getCampaignById, isValidLocationGroup, campaign } = store;
  const {
    userStore: { currentUser }
  } = useStore();

  const query = useQuery();

  const id = query.get('id');

  useEffect(() => {
    if (commonDataStore.locations.length > 0 && commonDataStore.banners.length > 0) {
      getCampaignById(id);
    } else {
      commonDataStore.loadAllBanners();
      commonDataStore.loadAllLocations();
    }
  }, [commonDataStore.locations, commonDataStore.banners, id]);

  const {
    handleSubmit,
    errors,
    formState,
    formState: { isSubmitting }
  } = formProps;

  const onSubmit = async (value) => {
    const { landingPageUrl, impressionUrl, fb, gg, webhook, ...trackingUrl } = value;

    const trackingLocation = {};

    for (const [key, value] of Object.entries(trackingUrl)) {
      const fields = key.split('-');
      set(trackingLocation, `${fields[0]}.${fields[1]}`, value);
    }
    await updateConfig({ landingPageUrl, impressionUrl, fb, gg, webhook, trackingLocation });
  };

  if (store.loading || store.loadingBanner) {
    return (
      <Spin
        spinning={store.loading || store.loadingBanner}
        tip="Loading..."
        style={{ height: '100%', width: '100%', textAlign: 'center' }}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      />
    );
  }

  return (
    <>
      <PageHeader
        backIcon
        ghost={false}
        title={t('CAMPAIGN_CONFIG')}
        extra={[
          <Button
            key="button"
            type="primary"
            htmlType="submit"
            form="updateForm"
            disabled={!formState.isValid && !!errors.landingPageUrl}
            loading={isSubmitting}
          >
            {t('UPDATE_CONFIG')}
          </Button>
        ]}
        style={{ width: '100%', background: 'white' }}
      >
        <Descriptions size="small" bordered column={3}>
          <Descriptions.Item label={t('NAME')}>{get(campaign, 'name', null)}</Descriptions.Item>
          <Descriptions.Item label={t('SCHEDULE')}>
            {format(get(campaign, 'scheduleFrom', null), DATE_FORMAT)}
          </Descriptions.Item>
          <Descriptions.Item label={t('KPI')}>
            {(get(campaign, 'kpi', 0) && get(campaign, 'kpi').toLocaleString()) || 0}
          </Descriptions.Item>
        </Descriptions>
      </PageHeader>
      <ReactResizeDetector handleHeight>
        {({ height }) => {
          const gridHeight = height ? height - 118 : 500;
          return (
            <Form
              style={{ height: gridHeight }}
              id="updateForm"
              onSubmit={handleSubmit(onSubmit)}
              layout="vertical"
            >
              <Tabs
                style={{
                  height: '100%',
                  width: '100%',
                  background: '#fff',
                  padding: '10px',
                  marginTop: 10,
                  overflow: 'scroll'
                }}
                tabPosition="left"
              >
                <TabPane tab={t('AUDIENCE')} key="target-object">
                  <TargetObjectConfig />
                </TabPane>
                <TabPane tab="CRM" key="crm">
                  <CRMConfig />
                </TabPane>
                <TabPane
                  tab={
                    <>
                      <span style={{ color: 'red' }}>(*)</span>
                      <span>{t('BANNER')}</span>
                    </>
                  }
                  key="banner"
                >
                  <ConfigBanner />
                </TabPane>
                <TabPane
                  tab={
                    <div
                      style={{ color: !formState.isValid && errors.landingPageUrl ? 'red' : '' }}
                    >
                      <>
                        <span style={{ color: 'red' }}>(*)</span>
                        <span>{t('REDIRECT_PAGE')}</span>
                      </>
                    </div>
                  }
                  key="redirect"
                  forceRender
                >
                  <RedirectPageCongig formProps={formProps} />
                </TabPane>
                {/* <TabPane tab={t('ADVANCE')} key="advance" forceRender>
                  <AdvanceConfig formProps={formProps} />
                </TabPane> */}
                {currentUser.ownerType !== LICENSE ? (
                  <>
                    <TabPane
                      tab={
                        <span style={{ color: !isValidLocationGroup && 'red' }}>
                          {t('LOCATION_GROUP')}
                        </span>
                      }
                      forceRender
                      key="location-group"
                    >
                      <LocationGroupConfig />
                    </TabPane>
                    <TabPane tab={<span>Webhook Api</span>} key="webhook" forceRender>
                      <WebhookApiConfig formProps={formProps} />
                    </TabPane>
                  </>
                ) : (
                  <></>
                )}
              </Tabs>
            </Form>
          );
        }}
      </ReactResizeDetector>
    </>
  );
});

export default CampaignConfigScreen;
