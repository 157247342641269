import '../traffic-summary/css.less';

import { Card, Col, Row, Tag } from 'antd';
import { sumBy } from 'lodash';
import { observer } from 'mobx-react';
import React, { FC, useEffect } from 'react';

import { useStore } from '~context/react-context';
import { formatNumber } from '~utils/formatNumber';

import OnlineSessionGrid from './OnlineSessionGrid';
import store from './store';
import TodayLoginChart from './TodayLoginChart';
import { useTranslation } from 'react-i18next';

const SessionDashboard: FC = observer(() => {
  const { init, todayLogin } = store;
  const { t } = useTranslation();
  const { commonDataStore } = useStore();
  useEffect(() => {
    commonDataStore.loadAllLocations();
    init();
  }, []);
  const total = todayLogin.map((item) => item.count);
  return (
    <>
      <Card title={t('dashboard.SESSION')} style={{ marginBottom: '8px' }}>
        <Row gutter={8}>
          <Col span={9}>
            <Card
              style={{ height: 530 }}
              title={t('dashboard.TODAY_LOGIN')}
              extra={[
                <Tag key="1" color="#00B2FF">
                  {formatNumber(sumBy(total))}
                </Tag>
              ]}
              size="small"
            >
              <TodayLoginChart />
            </Card>
          </Col>
          <Col span={15}>
            <Card style={{ height: 530 }} title={t('dashboard.SESSION_2_HOURS')} size="small">
              <OnlineSessionGrid />
            </Card>
          </Col>
        </Row>
      </Card>
    </>
  );
});

export default SessionDashboard;
