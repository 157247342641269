import '@ant-design/compatible/assets/index.css';

import { Col, Input, Modal, Row } from 'antd';
import i18next from 'i18next';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import useForm from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { Form } from '@ant-design/compatible';

import UIField from '~components/UI/UIField';
import { CommonInput, CommonUpdateArg } from '~graphql/_sdk';

import store from './store';

interface FormValues {
  value?: string;
  text?: string;
  type?: string;
}
const isValidJSON = (string) => {
  try {
    JSON.parse(string);
  } catch (e) {
    return false;
  }
  return true;
};

//
const validationSchema = yup.object().shape<FormValues>({
  text: yup.string().required(i18next.t('COMMON_DATA_VALIDATE_TEXT_REQUIRE')),
  value: yup.string().required(i18next.t('COMMON_DATA_VALIDATE_VALUE_REQUIRE')),
  type: yup.string().required(i18next.t('COMMON_DATA_VALIDATE_TYPE_REQUIRE'))
});

const TagsForm: FC = observer(() => {
  const { t } = useTranslation();
  const { modalVisible, setModalVisible, create, update, selectedItem } = store;

  const initValues: FormValues = {
    text: get(selectedItem, 'text', ''),
    value: get(selectedItem, 'value', ''),
    type: get(selectedItem, 'type', 'tags')
  };

  const formProps = useForm({ validationSchema });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting }
  } = formProps;

  const onCancel = () => {
    reset();
    setModalVisible();
  };

  const onSubmit = async (record: FormValues) => {
    const { text, value, type } = record;
    const records = { text, value: `tags_${value}`, type };
    if (selectedItem) {
      await update(get(selectedItem, '_id'), records as CommonUpdateArg);
    } else {
      await create(records as CommonInput);
    }
    onCancel();
  };

  return (
    <Modal
      title={t('TAGS')}
      visible={modalVisible}
      cancelText={t('CLOSE')}
      cancelButtonProps={{ danger: true, ghost: true }}
      onCancel={() => onCancel()}
      okText={selectedItem ? t('UPDATE') : t('CREATE')}
      width={400}
      okButtonProps={{
        htmlType: 'submit',
        form: 'TagsForm',
        type: 'primary',
        loading: isSubmitting
      }}
    >
      <Form
        id="TagsForm"
        onSubmit={handleSubmit(onSubmit)}
        layout='vertical'
      >
        <UIField
          {...formProps}
          name="text"
          label={t('TEXT')}
          fieldProps={{
            placeholder: t('COMMON_DATA_PLACEHOLDER_TEXT')
          }}
          fieldComponent={Input}
          defaultValue={initValues.text}
        />
        <UIField
          {...formProps}
          name="value"
          label={t('VALUE')}
          fieldProps={{
            placeholder: t('COMMON_DATA_PLACEHOLDER_VALUE'),
            disabled: !!selectedItem
          }}
          fieldComponent={Input}
          defaultValue={initValues.value}
        />
        <UIField
          {...formProps}
          name="type"
          label={t('TYPE')}
          fieldProps={{
            placeholder: t('COMMON_DATA_PLACEHOLDER_TYPE'),
            readOnly: true
          }}
          fieldComponent={Input}
          defaultValue={initValues.type}
        />
      </Form>
    </Modal>
  );
});

export default TagsForm;
