import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import { get, uniq } from 'lodash';
import React, { Component } from 'react';

import { CommonFilter } from '~graphql/_sdk';
import { getSdk } from '~graphql/sdk';
import { fcName } from '~screens/commonData/instruction/Header';

interface Props extends SelectProps<any> {
  where?: CommonFilter;
}

interface State {
  loading: boolean;
  data: any;
}

class SelectCommon extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: []
    };
  }

  componentDidMount = async () => {
    await this.fetchData();
  };

  fetchData = async () => {
    this.setState({ loading: true });
    const sdk = await getSdk();
    const { findManyCommon } = await sdk.findManyCommon({ where: { type: 'instruction' } });
    const data: any = uniq(findManyCommon.map((i) => get(i.meta, 'type'))).reduce((all: any, i) => {
      const obj = { _id: i, name: fcName(i) };
      all = [...all, obj];
      return all;
    }, []);
    this.setState({ loading: false, data });
  };

  render() {
    const { loading, data } = this.state;

    return (
      <Select loading={loading} {...this.props} style={{ width: '100%' }}>
        {data &&
          data.map((item) => (
            <Select.Option key={item._id as string} value={item._id as string}>
              {item.name}
            </Select.Option>
          ))}
      </Select>
    );
  }
}

export default SelectCommon;
