import './style.less';

import { Col, Row } from 'antd';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import HighChart from '~components/UI/HighChart/HighChart.lazy';

import store from './store';
import { useTranslation } from 'react-i18next';

interface Chart {
  heightChart: number;
  widthChart?: number;
}

const UserReturn: FC<Chart> = observer((props) => {
  const { heightChart, widthChart } = props;
  const { reportUserReturn } = store;
  const { t } = useTranslation();

  const heightContent = heightChart - 80;

  const optionsUserReturn: Highcharts.Options = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
      height: heightContent
    },
    title: {
      text: t('RETURN_USER')
    },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f}%'
        },
        showInLegend: true
      }
    },
    series: [
      {
        type: 'pie',
        name: 'Click',
        data: reportUserReturn
      }
    ],
    exporting: {
      enabled: false
    },
    credits: {
      enabled: false
    }
  };

  return (
    <>
      <Row gutter={16}>
        <Col span={12}>
          <HighChart options={optionsUserReturn} />
        </Col>
        {/* <Col span={12}>
          <HighChart options={optionsUserReturn} />
        </Col> */}
      </Row>
    </>
  );
});

export default UserReturn;
