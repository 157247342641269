import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import exportData from 'highcharts/modules/export-data';
import exporting from 'highcharts/modules/exporting';
import addHeatmapModule from 'highcharts/modules/heatmap';
import noData from 'highcharts/modules/no-data-to-display';
import { merge } from 'lodash';
import React, { FC } from 'react';

interface Props extends HighchartsReact.Props {
  theme?: { [key: string]: any };
}

const defaultOptions: Highcharts.Options = {
  exporting: {
    buttons: {
      contextButton: {
        menuItems: [
          'downloadPNG',
          'downloadJPEG',
          'downloadPDF',
          'downloadSVG',
          'separator',
          'downloadCSV',
          'downloadXLS'
        ]
      }
    }
  },
  credits: {
    enabled: false
  },
  xAxis: {
    labels: {
      step: 1
    }
  },
  plotOptions: {
    series: {
      opacity: 0.9,
      dataLabels: {
        allowOverlap: true
      }
    }
  }
};

addHeatmapModule(Highcharts);
noData(Highcharts);
exporting(Highcharts);
exportData(Highcharts);
const HighChart: FC<Props> = props => {
  const { options, theme, ...remains } = props;
  if (theme) {
    Highcharts.setOptions(theme);
  }

  const highchartsOptions: Highcharts.Options = merge({}, defaultOptions, options);

  return <HighchartsReact {...remains} highcharts={Highcharts} options={highchartsOptions} />;
};

export default HighChart;
