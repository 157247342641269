export const dataChart = (text, data = [], x?: number) => {
  return {
    chart: {
      type: 'pie',
      height: 230
    },
    title: {
      text,
      align: 'center',
      useHTML: true,
      verticalAlign: 'middle',
      x,
      style: {
        fontSize: '15px',
        marginBottom: '15px',
        textAlign: 'center',
        fontWeight: 'bold'
      }
    },

    legend: {
      verticalAlign: 'middle',
      layout: 'vertical',
      align: 'right',
      itemMarginBottom: 10
    },
    tooltip: {
      headerFormat: '',
      pointFormat:
        '<span style="color:{point.color}">\u25CF</span> <b>{point.name}:{point.percentage:.1f}</b><br/>'
    },

    navigation: {
      buttonOptions: {
        verticalAlign: 'top',
        y: -10
      }
    },
    plotOptions: {
      pie: {
        size: '100%',
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '{point.percentage:.1f}%',
          distance: -20
        },
        showInLegend: true
      }
    },
    credits: {
      enabled: false
    },
    series: [
      {
        minPointSize: 10,
        innerSize: '50%',
        zMin: 0,
        marginBottom: '200',
        data
      }
    ]
  };
};
