import deepEqual from 'fast-deep-equal';

type PromiseFunc<T> = (...inputs: any) => Promise<T>;

interface PromiseCache {
  promise?: Promise<void>;
  promiseFn: PromiseFunc<any>;
  inputs: Array<any>;
  error?: any;
  response?: any;
}

const promiseCaches: PromiseCache[] = [];

function usePromise<T>(promiseFn: PromiseFunc<T>, inputs: Array<any> = [], lifespan = 0) {
  // eslint-disable-next-line no-restricted-syntax
  for (const promiseCache of promiseCaches) {
    if (promiseCache.promiseFn === promiseFn && deepEqual(inputs, promiseCache.inputs)) {
      // If an error occurred,
      if (Object.prototype.hasOwnProperty.call(promiseCache, 'error')) {
        throw promiseCache.error;
      }

      // If a response was successful,
      if (Object.prototype.hasOwnProperty.call(promiseCache, 'response')) {
        return promiseCache.response;
      }
      throw promiseCache.promise;
    }
  }

  // The request is new or has changed.
  const promiseCache: PromiseCache = {
    promise:
      // Make the promise request.
      promiseFn(...inputs)
        .then((response: any) => {
          promiseCache.response = response;
        })
        .catch((e: any) => {
          promiseCache.error = e;
        })
        .then(() => {
          if (lifespan > 0) {
            setTimeout(() => {
              const index = promiseCaches.indexOf(promiseCache);
              if (index !== -1) {
                promiseCaches.splice(index, 1);
              }
            }, lifespan);
          }
        }),
    promiseFn,
    inputs
  };
  promiseCaches.push(promiseCache);
  throw promiseCache.promise;
};

export { usePromise };
