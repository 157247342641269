import { Col, Input, Select } from 'antd';
import React, { FC, useState } from 'react';

import { upperCaseFirstLetter } from '~utils/upperCaseFirstLetter';

import store, { ReportFilterSelectOption } from '../store';

const { Option } = Select;

interface FilterElementProps {
  target: string;
  item: { text: string; key: string };
  option?: ReportFilterSelectOption[];
  enableOption?: {
    notEmpty?: boolean;
    empty?: boolean;
    value?: boolean;
  };
}

const defaultEnbaleOption = { notEmpty: true, empty: true, value: true };

const FilterElement: FC<FilterElementProps> = ({
  item: { key, text },
  target,
  option = null,
  enableOption = defaultEnbaleOption
}) => {
  const { handleOnChangeCrmFilter } = store;
  const [filterType, setFilterType] = useState('value');

  const handleOnChangeInput = (value: string | string[]) => {
    handleOnChangeCrmFilter(`${target}.${key}`, value);
  };

  const input = option ? (
    <Select
      style={{ width: '70%' }}
      disabled={filterType !== 'value'}
      options={option}
      mode="tags"
      onChange={e => handleOnChangeInput(e as string)}
      placeholder="Select value"
    />
  ) : (
    <Input
      style={{ width: '70%' }}
      disabled={filterType !== 'value'}
      onChange={e => handleOnChangeInput(e.target.value)}
      placeholder="Enter value"
    />
  );

  const handleOnChange = value => {
    if (value !== 'value') {
      handleOnChangeCrmFilter(`${target}.${key}`, value);
    } else {
      handleOnChangeCrmFilter(`${target}.${key}`, null);
    }
    setFilterType(value);
  };

  const enable = { ...defaultEnbaleOption, ...enableOption };

  return (
    <Col sm={24} key={key} style={{ marginBottom: 10 }}>
      <span>{upperCaseFirstLetter(text)}</span>
      <Input.Group compact>
        <Select
          style={{ width: '30%' }}
          defaultValue="value"
          onChange={e => handleOnChange(e)}
          allowClear
          disabled={Object.values(enable).filter(e => e).length === 1}
        >
          {enable.notEmpty && <Option value="exists">Not Empty</Option>}
          {enable.empty && <Option value="not_exists">Empty</Option>}
          {enable.value && <Option value="value">Values</Option>}
        </Select>
        {input}
      </Input.Group>
    </Col>
  );
};

export default FilterElement;
