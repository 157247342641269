import { isEmpty } from 'lodash';
import { action, observable, runInAction } from 'mobx';
import { actionAsync, task } from 'mobx-utils';

import { GridReadyEvent } from '@ag-grid-community/core';

import filterGrid from '~components/Filter/FilterRPLocation/store';
import { ReportIncomeResult } from '~graphql/_sdk';
import { getSdk } from '~graphql/sdk';

class RPIncomeLocationStore {
  @observable public loading: boolean;
  @observable public details;
  @observable public countDetail;
  @observable public count;
  @observable public categoryId: string[];
  @observable public income: ReportIncomeResult;
  @observable public filter;
  @observable public nodeHeight: number;

  public where: {};

  constructor() {
    runInAction(() => {
      this.cleanUp();
    });
  }

  @action
  public cleanUp = () => {
    this.filter = {};
    this.where = {};
    this.details = [];
    this.income = {};
  };

  @action
  public handleGridReady = ({ api }: GridReadyEvent) => {
    this.reload = () => {
      api.onFilterChanged();
    };
    this.resetRowHeight = () => {
      api.resetRowHeights();
    };
  };

  @action
  public setCategoryId = value => {
    this.categoryId = value;
  };

  @action
  public setWhere = (w, tab?: string) => {
    this.filter = w;
    if (tab === 'grid') {
      this.reload();
    }
  };

  @actionAsync
  public getReportIncome = async () => {
    const sdk = await task(getSdk());
    this.loading = true;

    const { getReportIncomeByLocation } = await task(
      sdk.getReportIncomeByLocation({
        filter: filterGrid.where
      })
    );

    this.loading = false;
    this.income = getReportIncomeByLocation;
  };

  @action
  public resetRowHeight = () => undefined;

  @action
  public reload = () => undefined;
}
export default new RPIncomeLocationStore();
