import '@ant-design/compatible/assets/index.css';

import { Button, Checkbox, Input, Modal, Tabs } from 'antd';
import i18next from 'i18next';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React from 'react';
import useForm from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { Form } from '@ant-design/compatible';

import UIField from '~components/UI/UIField';

import RoleList from './RoleList';
import store from './store';

export interface FormValues {
  display: string;
  description?: string;
}

const validationSchema = yup.object().shape<FormValues>({
  display: yup.string().required(i18next.t('ROLE_IS_REQUIRED'))
});

const ProfileForm = observer(() => {
  const { t } = useTranslation();
  const formProps = useForm({ validationSchema });
  const {
    isShowForm,
    setIsShowForm,
    getRoleList,
    setCreatedRoleIds,
    setCreatedMenuIds,
    handleSubmitRecord,
    selectedItem,
    createdRoleIds,
    createdMenuIds,
    getMenuList
  } = store;
  const {
    handleSubmit,
    formState: { isSubmitting }
  } = formProps;

  const initValues = {
    display: get(selectedItem, 'display', ''),
    description: get(selectedItem, 'description', ''),
    roleIds: get(selectedItem, 'roleIds', [])
  };

  const footer = (
    <>
      <Button type="ghost" onClick={() => setIsShowForm(false)}>
        {t('CLOSE')}
      </Button>
      <Button htmlType="submit" form="profileForm" loading={isSubmitting} type="primary">
        {selectedItem ? t('EDIT') : t('CREATE')}
      </Button>
    </>
  );
  
  const onSubmit = (record) => {
    handleSubmitRecord(record);
  };
  
  return (
    <Modal title={t('PROFILE')} visible={isShowForm} footer={footer} width="80%" closable={false}>
      <Form onSubmit={handleSubmit(onSubmit)} id="profileForm" layout="vertical">
        <Tabs>
          <Tabs.TabPane tab={t('INFORMATION')} key="info">
            <UIField
              {...formProps}
              name="display"
              label={t('ROLE_NAME')}
              fieldProps={{
                placeholder: t('ROLE_NAME')
              }}
              fieldComponent={Input}
              defaultValue={initValues.display}
            />

            <UIField
              {...formProps}
              name="description"
              label={t('DESCRIPTION')}
              fieldProps={{
                placeholder: t('DESCRIPTION')
              }}
              fieldComponent={Input}
              defaultValue={initValues.description}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab={t('ROLE')} key="role">
            <Checkbox.Group
              style={{
                maxHeight: 500,
                overflowX: 'auto'
              }}
              onChange={(e) => setCreatedRoleIds(e as string[])}
              defaultValue={createdRoleIds}
            >
              {getRoleList &&
                Object.keys(getRoleList).map((e, i) => {
                  return <RoleList title={e} data={getRoleList[e]} key={String(i)} />;
                })}
            </Checkbox.Group>
          </Tabs.TabPane>

          <Tabs.TabPane tab={t('MENU')} key="menu">
            <Checkbox.Group
              style={{
                maxHeight: 500,
                overflowX: 'auto'
              }}
              onChange={(e) => setCreatedMenuIds(e as string[])}
              value={createdMenuIds}
            >
              {getMenuList &&
                Object.keys(getMenuList).map((e, i) => {
                  return <RoleList title={e} data={getMenuList[e]} key={String(`${i}-${e}`)} />;
                })}
            </Checkbox.Group>
          </Tabs.TabPane>
        </Tabs>
      </Form>
    </Modal>
  );
});

export default ProfileForm;
