import { Card, Col, Row, Spin } from 'antd';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import HighChart from '~components/UI/HighChart/HighChart.lazy';
import { useTranslation } from 'react-i18next';

import store from '../store';
import { createTypeDevice, createTypeOs } from './ChartOSDev';

const OsDevice: FC = observer(() => {
  const { loading, getOsData, getDeviceData } = store;
  const {t} = useTranslation();

  return (
    <>
      <Row gutter={8}>
        <Col span={12}>
          <Spin spinning={loading}>
            <Card title={t('DEVICE')}>
              <HighChart options={createTypeDevice(getDeviceData)} />
            </Card>
          </Spin>
        </Col>
        <Col span={12}>
          <Spin spinning={loading}>
            <Card title={t('OS')}>
              <HighChart options={createTypeOs(getOsData)} />
            </Card>
          </Spin>
        </Col>
      </Row>
    </>
  );
});
export default OsDevice;
