import { Button, Empty, Spin } from 'antd';
import * as CSS from 'csstype';
import { isEmpty, isEqual } from 'lodash';
import React, { ReactNode, Suspense } from 'react';
import { Link } from 'react-router-dom';
import i18next from 'i18next';

import {
  GridOptions,
  GridReadyEvent,
  IServerSideDatasource as ServerSideDatasource,
  IServerSideGetRowsParams as ServerSideGetRowsParams,
  IServerSideGetRowsRequest as ServerSideGetRowsRequest
} from '@ag-grid-community/core';
import { AgGridReactProps } from '@ag-grid-community/react';
import { mapReduce, SortDirection, WhereArgs } from '@source/common';

import GetFilterWhere from '~components/GetFilterWhere/GetFilterWhere';
import { createMultiLevelObject } from '~utils/createMultiLevelObject';

import { emptySVG } from '../../../assets/images/index';
import Loading from '../Loading';
import AgGridReact from './AgGridReact.lazy';
import CountStatusBar from './CountStatusBar';
import CustomNoRowsOverlay from './UINoData';

export interface SortModel {
  colId: string;
  sort: SortDirection;
}

const processSortForNestedField = (sort: { [field: string]: SortDirection }): any => {
  const field = Object.keys(sort)[0];
  if (field.split('.').length === 1) {
    return sort;
  }
  return createMultiLevelObject(field.split('.'), sort[field]);
};

interface AgGridState {
  gridApi?: GridReadyEvent;
  empty?: boolean;
}

interface AgGridProps {
  screen?: string;
  emptyHandler?: Function | string;
  children?: ReactNode[];
  options: AgGridReactProps;
  customStyle?: CSS.Properties<string | number>;
  onGridReady?: Function;
  onQuery: Function;
  onCountQuery?: Function;
  queryKey: string;
  countQueryKey?: string;
  where?: WhereArgs;
  showWhere?: boolean;
  height?: number;
  loading?: boolean;
}

export default class AgGridServer extends React.Component<AgGridProps, AgGridState> {
  public gridOptions: GridOptions = {
    getRowNodeId: (row) => row._id || row.id,
    rowModelType: 'serverSide',
    cacheBlockSize: 50,
    maxBlocksInCache: 50,
    defaultColDef: {
      menuTabs: [],
      sortable: true,
      resizable: true
    },
    // pagination: true,
    // paginationPageSize: 50,
    animateRows: true,
    rowHeight: 40,
    headerHeight: 40,
    sideBar: {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          minWidth: 225,
          maxWidth: 225,
          width: 225
        }
      ],
      defaultToolPanel: 'columns',
      hiddenByDefault: true
    },
    loadingOverlayComponentFramework: Spin,
    noRowsOverlayComponentParams: {
      noRowsMessageFunc: () => {
        const { emptyHandler } = this.props;
        const { empty } = this.state;
        const returnBtn = () => {
          if (typeof emptyHandler === 'function') {
            return (
              <Button
                type="primary"
                style={{ pointerEvents: 'auto', marginTop: 10 }}
                onClick={() => emptyHandler()}
              >
                {i18next.t('CREATE')}
              </Button>
            );
          }
          if (typeof emptyHandler === 'string') {
            return (
              <Link to={emptyHandler}>
                <Button
                  style={{ pointerEvents: 'auto', marginTop: 10 }}
                  key="create"
                  type="primary"
                >
                  {i18next.t('CREATE')}
                </Button>
              </Link>
            );
          }
        };
        return (
          <Empty
            style={{ marginTop: '12%' }}
            image={emptySVG}
            imageStyle={{
              height: 60
            }}
            description={
              <span>
                {i18next.t('NO_DATA')}
                <br />
                {empty && returnBtn()}
              </span>
            }
          />
        );
      }
    },
    noRowsOverlayComponentFramework: CustomNoRowsOverlay,
    suppressMovableColumns: true,
    frameworkComponents: {
      CountStatusBarComponent: CountStatusBar
    },
    statusBar: {
      statusPanels: [
        {
          statusPanelFramework: CountStatusBar,
          align: 'left',
          key: 'CountStatusBar'
        }
      ]
    }
  };

  public async UNSAFE_componentWillMount() {
    const { onCountQuery, countQueryKey, where } = this.props;
    const count = await onCountQuery({ where });
    this.setState({ empty: count[countQueryKey] <= 0 });
  }

  public UNSAFE_componentWillReceiveProps(nextProps: AgGridProps) {
    const where = this.props?.where;
    if (!isEqual(where, nextProps.where)) {
      this.reload();
    }
  }

  private getHeight(): number {
    const { showWhere, height } = this.props;
    if (height) {
      return showWhere ? height - 33 : height;
    }
    return 500;
  }

  public reload = () => undefined;

  public getDataSource = async (request: ServerSideGetRowsRequest) => {
    const { startRow, endRow, sortModel, groupKeys, rowGroupCols, valueCols } = request;
    const { onQuery, queryKey, where = {} } = this.props;
    const { empty } = this.state;
    const limit = endRow - startRow;
    let sort = mapReduce<SortModel, 'colId', SortDirection>(
      sortModel,
      'colId',
      ({ sort: s }) => s.toUpperCase() as any
    );

    if (!isEmpty(sort)) {
      sort = processSortForNestedField(sort);
    }
    const data = await onQuery({
      limit,
      where,
      sort,
      skip: startRow,
      groupKeys,
      rowGroupCols,
      valueCols
    });
    const result = data[queryKey];
    if (empty) {
      this.setState({ empty: isEmpty(result) });
    }
    return result;
  };

  public onGridReady = async (params: GridReadyEvent) => {
    const { onGridReady } = this.props;
    this.setState({ gridApi: params });
    this.reload = () => {
      params.api.onFilterChanged();
    };

    if (typeof onGridReady === 'function') {
      onGridReady(params);
    }
    const dataSource = this.serverSideDataSource();
    params.api.setServerSideDatasource(dataSource);
    params.api.setSideBarVisible(true);
  };

  private resize = (params: GridReadyEvent) => {
    params.api.sizeColumnsToFit();
  };

  public serverSideDataSource(): ServerSideDatasource {
    return {
      getRows: async ({ request, successCallback }: ServerSideGetRowsParams) => {
        const { onCountQuery, countQueryKey, where } = this.props;
        const { gridApi } = this.state;
        const { groupKeys, rowGroupCols, valueCols } = request;
        const data = await this.getDataSource(request);
        const count = await onCountQuery({ where, groupKeys, rowGroupCols, valueCols });
        if (count[countQueryKey] <= 0) {
          gridApi.api.showNoRowsOverlay();
        } else {
          gridApi.api.hideOverlay();
        }
        setTimeout(() => {
          successCallback(data, count[countQueryKey]);
        }, 500);
        setTimeout(() => {
          const statusBar: any = document.getElementsByClassName('ag-status-bar');
          for (let i = 0; i < statusBar.length; i++) {
            statusBar[i].style['line-height'] = '50px';
            statusBar[i].style.height = '50px';
          }
        }, 1500);
      }
    };
  }

  render() {
    const { customStyle, options, where, showWhere } = this.props;
    const gridHeight = this.getHeight();

    return (
      <Suspense fallback={<Loading title="Loading grid..." />}>
        {!showWhere || (
          <GetFilterWhere
            where={where}
            style={{ width: '100%', backgroundColor: 'white', border: '1px solid #efe8e8' }}
          />
        )}
        <div
          style={{
            height: gridHeight,
            width: '100%',
            ...customStyle
          }}
          className="ag-theme-material"
        >
          <AgGridReact
            {...this.gridOptions}
            {...options}
            onGridSizeChanged={this.resize}
            onColumnEverythingChanged={this.resize}
            onFirstDataRendered={this.resize}
            onGridReady={this.onGridReady}
          />
        </div>
      </Suspense>
    );
  }
}
