import { Button, Descriptions, Tag } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { format, PENDING, REJECTED, VERIFIED } from '@source/common';

import { formatNumber } from '~utils/formatNumber';

import store from './store';

const InfoModal: FC = observer(() => {
  const { isPaymentInfoVisible, setPaymentInfoVisible, selectedItem } = store;

  if (!selectedItem) return null;

  const getStatus = (statusPayment) => {
    let color = 'default';
    switch (statusPayment) {
      case VERIFIED:
        color = 'success';
        break;
      case REJECTED:
        color = 'error';
        break;
      default:
        break;
    }
    return (
      <>
        <Tag color={color}>{statusPayment.toUpperCase()}</Tag>
      </>
    );
  };

  return (
    <Modal
      title="Payment Infomation"
      visible={isPaymentInfoVisible}
      onCancel={() => setPaymentInfoVisible(false)}
      width={700}
      footer={[
        <Button key="info-cancle" onClick={() => setPaymentInfoVisible(false)}>
          Cancle
        </Button>
      ]}
    >
      <Descriptions title="Payment Infomation" bordered column={2}>
        <Descriptions.Item span={1} label="Amount">
          {formatNumber(selectedItem?.amount)}
        </Descriptions.Item>
        <Descriptions.Item span={1} label="Date">
          {format(selectedItem?.createdAt)}
        </Descriptions.Item>
        <Descriptions.Item span={1} label="By">
          {selectedItem?.userPayment.name}
        </Descriptions.Item>
        <Descriptions.Item span={1} label="Email">
          {selectedItem?.userPayment.email}
        </Descriptions.Item>
        <Descriptions.Item span={1} label="Phone">
          {selectedItem?.userPayment.phone}
        </Descriptions.Item>
        <Descriptions.Item span={1} label="Status">
          {getStatus(selectedItem?.status)}
        </Descriptions.Item>
        <Descriptions.Item span={2} label="Note">
          {selectedItem?.note}
        </Descriptions.Item>
      </Descriptions>

      {selectedItem.status !== PENDING && (
        <Descriptions
          title={`${selectedItem.status === VERIFIED ? 'Verified' : 'Rejected'} Infomation`}
          bordered
          column={2}
          style={{ marginTop: 20 }}
        >
          {selectedItem.status === VERIFIED && (
            <>
              <Descriptions.Item span={1} label="Verified By">
                {selectedItem?.verifiedByUser?.name}
              </Descriptions.Item>

              <Descriptions.Item span={1} label="Verified At">
                {format(selectedItem?.verifiedAt)}
              </Descriptions.Item>

              <Descriptions.Item span={1} label="Email">
                {selectedItem?.verifiedByUser?.email}
              </Descriptions.Item>

              <Descriptions.Item span={1} label="Phone">
                {selectedItem?.verifiedByUser?.phone}
              </Descriptions.Item>
            </>
          )}

          {selectedItem.status === REJECTED && (
            <>
              <Descriptions.Item span={1} label="Rejected By">
                {selectedItem?.verifiedByUser?.name}
              </Descriptions.Item>

              <Descriptions.Item span={1} label="Rejected At">
                {format(selectedItem?.rejectedAt)}
              </Descriptions.Item>

              <Descriptions.Item span={1} label="Email">
                {selectedItem?.verifiedByUser?.email}
              </Descriptions.Item>

              <Descriptions.Item span={1} label="Phone">
                {selectedItem?.verifiedByUser?.phone}
              </Descriptions.Item>

              <Descriptions.Item span={2} label="Rejected Reason">
                {selectedItem.rejectReason}
              </Descriptions.Item>
            </>
          )}
        </Descriptions>
      )}
    </Modal>
  );
});

export default InfoModal;
