import { Button, Input, Modal } from 'antd';
import { observer } from 'mobx-react';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ErrorNotify, SuccessNotify } from '~components/UI/Notification';

import HTMLTextArea from './HTMLTextArea';
import store from './store';

const GenerateForm: FunctionComponent = observer(() => {
  const { t } = useTranslation();
  const [macDevice, setMacDevice] = useState('');

  const {
    generatorFormVisible,
    setGeneratorVisible,
    selectedItem,
    selectedHTML,
    getRenderCode,
    renderCode
  } = store;

  const handleGenCode = () => {
    if (!selectedItem) return;
    getRenderCode({
      locationId: selectedItem._id,
      isExternal: '',
      macFieldName: macDevice.trim()
    });
  };

  const onCancel = () => {
    setGeneratorVisible();
  };

  const copyHTML = () => {
    const ele = document.createElement('textarea');
    ele.textContent = selectedHTML;
    document.body.appendChild(ele);
    ele.select();
    try {
      if (document.execCommand('copy')) {
        SuccessNotify('Copy HTML');
      } else {
        ErrorNotify('Copy HTML');
      }
    } catch (err) {
      ErrorNotify('Copy HTML');
    } finally {
      ele.parentNode.removeChild(ele);
    }
    setGeneratorVisible();
  };

  const footer = (
    <>
      <Button type="primary" onClick={() => handleGenCode()}>
        {t('GENERATE_HTML')}
      </Button>
      <Button type="primary" onClick={() => copyHTML()} disabled={!renderCode}>
        {t('COPY')}
      </Button>
      <Button type="ghost" onClick={() => onCancel()}>
        {t('CLOSE')}
      </Button>
    </>
  );

  return (
    <Modal
      title={t('GENERATE_HTML')}
      closable={false}
      visible={generatorFormVisible}
      footer={footer}
      width={800}
      style={{ top: 20 }}
    >
      <div style={{ marginBottom: 20 }}>
        <Input
          value={macDevice}
          style={{ width: 250 }}
          onChange={e => setMacDevice(e.target.value)}
          placeholder="Input field get MAC Address"
        />
      </div>
      <HTMLTextArea />
    </Modal>
  );
});

export default GenerateForm;
