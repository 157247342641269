import i18next from 'i18next';
import { debounce, isEmpty } from 'lodash';
import { action, observable, runInAction } from 'mobx';
import { actionAsync, task } from 'mobx-utils';
import { useTranslation } from 'react-i18next';

import { GridReadyEvent } from '@ag-grid-community/core';
import { WhereArgs, ADS_TYPE } from '@source/common';

import { ErrorNotify, SuccessNotify } from '~components/UI/Notification';
import { getConfig } from '~config/index';
import { Campaign, CampaignLocation, SetScheduleArgs } from '~graphql/_sdk';
import { getSdk } from '~graphql/sdk';

export class CampaignsStore {
  @observable public loading: boolean;
  @observable public where: WhereArgs;
  @observable public visible: boolean;
  @observable public modalType?: string;
  @observable public selectedItem?: Campaign;
  @observable public selectedAvailableBooking?: any;
  @observable public locationBookingType?: any;
  @observable public ownerType: string;
  @observable public loadingForm: boolean;
  @observable public renderFilter: boolean;
  @observable public filterDate: SetScheduleArgs;
  @observable public status: any;
  @observable public whereFilter;
  @observable public currentTime: string;

  constructor() {
    runInAction(() => {
      this.reset();
    });
  }

  @action
  public reset = () => {
    this.whereFilter = {
      adsType: ADS_TYPE.BASIC
    };
    this.status = undefined;
    this.loading = false;
    this.visible = false;
    this.modalType = undefined;
    this.selectedItem = undefined;
    this.filterDate = {};
    this.where = {};
    this.currentTime = '';
  };

  @action
  public setCurrentTime = (value) => {
    this.currentTime = value;
  };

  @action
  public setFilterDate = (value) => {
    this.filterDate = value;
  };

  @action
  public setOwnerType = (ownerType) => {
    Object.assign(this.whereFilter, { ownerType });
    this.ownerType = ownerType;
    this.reload();
  };

  public setAdsType = () => {
    Object.assign(this.whereFilter, { adsType: ADS_TYPE.ADVANCE });
    this.reload();
  };

  @actionAsync
  handleApproveCampaign = async (id: string, allowed, locations: CampaignLocation[]) => {
    this.setLoading(true);
    const sdk = await task(getSdk());
    const { approveCampaign } = await task(sdk.approveCampaign({ _id: id, allowed }));
    this.setLoading(false);
    return approveCampaign;
  };

  @action
  setLoading = (value: boolean) => {
    this.loading = value;
  };

  @action
  setSearchText = debounce(
    (text) => {
      runInAction(() => {
        Object.assign(this.whereFilter, { _search: text });
      });
      this.reload();
    },
    500,
    { leading: false, trailing: true }
  );

  @action
  setModalVisible = (item = undefined, type = undefined) => {
    this.selectedItem = item;
    this.visible = !this.visible;
    this.modalType = type;
  };

  @action
  resetAvailableBooking = () => {
    this.selectedAvailableBooking = null;
  };

  @actionAsync
  getAvailableBooking = async () => {
    this.loadingForm = false;
    if (!this.selectedItem.scheduleTo) {
      return;
    }

    this.loadingForm = true;

    const locationIds = this.selectedItem.locations.map((e) => e.locationId);
    const date = { from: this.selectedItem.scheduleFrom, to: this.selectedItem.scheduleTo };
    const sdk = await task(getSdk());

    const {
      getAvailableBooking: { locations }
    } = await task(sdk.getAvailableBooking({ locationIds, date }));

    this.selectedAvailableBooking = locations.reduce((all, item) => {
      all[item._id.toString()] = item.availableTotalKpi;
      return all;
    }, {});

    this.locationBookingType = locations.reduce((all, item) => {
      all[item._id.toString()] = item.bookingType;
      return all;
    }, {});

    this.loadingForm = false;
  };

  @actionAsync
  updateAdvance = async (_id: string, record: any) => {
    const sdk = await task(getSdk());
    const {
      updateAdvanceCampaign: { error }
    } = await sdk.updateAdvanceCampaign({ _id, record });

    if (error) {
      ErrorNotify(error.code);
    } else {
      SuccessNotify(i18next.t('UPDATE'));

      this.reload();
    }
    this.setModalVisible();
  };

  @actionAsync
  remove = async (_id: string) => {
    const sdk = await task(getSdk());
    const {
      deleteCampaignById: { error }
    } = await sdk.deleteCampaignById({ _id });

    if (error) {
      ErrorNotify(error.code);
    } else {
      SuccessNotify(i18next.t('DELETE'));

      this.reload();
    }
    this.setModalVisible();
  };

  @actionAsync
  enable = async (_id: string) => {
    const sdk = await task(getSdk());
    const {
      enableCampaign: { error }
    } = await sdk.enableCampaign({ _id });

    if (error) {
      ErrorNotify(error.code);
    } else {
      SuccessNotify('ENABLE');
      this.reload();
    }
    this.setModalVisible();
  };

  @actionAsync
  disable = async (_id: string, duration: number) => {
    const sdk = await task(getSdk());
    const {
      disableCampaign: { error }
    } = await sdk.disableCampaign({ _id, duration });

    if (error) {
      ErrorNotify(error.code);
    } else {
      SuccessNotify('DISABLE');
      this.reload();
    }
    this.setModalVisible();
  };

  @actionAsync
  setSchedule = async (_id: string, where: SetScheduleArgs) => {
    const sdk = await task(getSdk());
    const {
      setSchedule: { error }
    } = await sdk.setSchedule({ _id, where });
    if (error) {
      ErrorNotify(error.code);
    } else {
      SuccessNotify('Set Schedule');
      this.reload();
    }
    this.setModalVisible();
  };

  @actionAsync
  setTimes = async (_id: string) => {
    const sdk = await task(getSdk());

    const {
      setTimes: { error }
    } = await sdk.setTimes({ _id, times: this.currentTime });
    if (error) {
      ErrorNotify(error.code);
    } else {
      SuccessNotify('Set Times');
      this.reload();
    }
    this.setModalVisible();
  };

  @action
  public setFilter = (where: WhereArgs) => {
    if (!isEmpty(where)) {
      if (!where._operators?.status) {
        delete this.whereFilter?._operators?.status;
      }
      if (!where._operators?.type) {
        delete this.whereFilter?._operators?.type;
      }
      this.whereFilter = { ...this.whereFilter, ...where };
    } else {
      this.whereFilter = where;
    }
    this.reload();
  };

  @action
  public handleGridReady = ({ api }: GridReadyEvent) => {
    this.reload = () => {
      api.onFilterChanged();
    };
  };

  @action
  public async preview(id: string, redirect = 'https://google.com') {
    const { restApiEndpoint } = await getConfig();
    const params = [`bannerId=${id}`, redirect && `redirect=${encodeURIComponent(redirect)}`]
      .filter(Boolean)
      .join('&');
    window.open(`${restApiEndpoint}/renderer/preview?${params}`, '_blank');
  }

  @action
  public removeRow(locationId: string) {
    this.selectedItem.locations = this.selectedItem.locations.filter(
      (i) => i.locationId.toString() !== locationId
    );
  }

  @action
  public reload = () => undefined;
}

export default new CampaignsStore();
