const translations = {
  PLATFORM_INFO: 'ADVERTISER MANAGERMENT PLATFORM',
  LOGIN_FORM: 'LOGIN FORM',
  REMEMBER_ME: 'Remember me',
  REGISTER: 'Register MegaPlus',
  LANGUAGE: 'Language',
  INFO: 'Information',
  DASHBOARD: 'Dashboard',
  chooseLng: 'Select language',
  CREATED: 'Created',
  UPDATED: 'Updated',
  REMOVE: 'Removed',
  SUCCESSFULL: 'Successfully',
  FAILED: 'failed',
  EN: 'English',
  VN: 'Vietnamese',
  REQF: 'This field is required !!',
  CREATE: 'Create',
  UPDATE: 'Update',
  DELETE_THIS: 'Delete this?',
  DELETE: 'Delete',
  TYPE_TO_SEARCH: 'Type to search...',
  SEARCH: 'Search...',
  CLOSE: 'Close',
  CANCEL: 'Cancle',
  NO: 'No.',
  NAME: 'Name',
  INPUT_NAME: 'Input Name',
  TYPE: 'Type',
  CHOOSE_TYPE: 'Select Type',
  CHOOSE_MODEL: 'Select Model',
  LOCATION: 'Location',
  CHOOSE_LOCATION: 'Select Location....',
  CHOOSE_TAGS: 'Select Tags...',
  SAVE: 'Save',
  ARE_YOU_SURE_DELETE: 'Are you sure delete',
  KO: 'No',
  NOTI_INCORRECT_JSON: 'JSON FORMAT INCORECT',
  NOTI_VALUE_EXISTED: 'VALUE EXISTED',
  COMMON_DATA_MODAL_TITLE: 'COMMON MANAGEMENT',
  COMMON_DATA_PLACEHOLDER_TEXT: 'Input Text',
  COMMON_DATA_PLACEHOLDER_VALUE: 'Input value',
  COMMON_DATA_PLACEHOLDER_TYPE: 'Input Type',
  COMMON_DATA_PLACEHOLDER_ICON: 'Input icon: fa-user',
  COMMON_DATA_PLACEHOLDER_META: 'Input meta \nExample: {\'key\': \'value\'}',
  COMMON_DATA_TABLE_HEADER_TEXT: 'Text',
  COMMON_DATA_TABLE_HEADER_TYPE: 'Type',
  COMMON_DATA_VALIDATE_TEXT_REQUIRE: 'Text is required!',
  COMMON_DATA_VALIDATE_VALUE_REQUIRE: 'Value is required!',
  COMMON_DATA_VALIDATE_TYPE_REQUIRE: 'Type is required!',
  COMMON_DATA_VALIDATE_GROUP_REQUIRE: 'Group is required!',
  COMMON_DATA_VALIDATE_META_REQUIRE: 'Icon is required!',
  NOTI_FORMAT_DATA_JSON: 'Invalid JSON format: {\'key\': \'value\'} \n Please try again! ',
  LOCATION_STATISTIC: 'Location Statistic',
  LOCATION_ACTIVE: 'Location Active',
  LOCATION_ADS: 'Location Ads',
  MONTHLY_CLICK: 'Monthly Click',
  CTR: 'CTR',
  UNIQUE_USER: 'Unique User',
  TOTAL: 'Total',
  MONTHLY_KPI: 'Monthly KPI',
  SITES: 'Sites',
  ACTIVE: 'Active',
  POINT: 'Point',
  ALL: 'All',
  CAMPAIGN_STATISTIC: 'Campaign Statistic',
  ADVERTISEMENT: 'Advertisement',
  DEFAULT: 'Default',
  OTHER: 'Other',
  BOOKING_KPI: 'Booking Kpi',
  CLICK: 'Click',
  NEW: 'New',
  ENDING: 'Ending',
  USER_LICENSE: 'User License',
  DEMO: 'Demo',
  REAL: 'Real',
  NEW_IN_MONTH: 'New in Month',
  LOCATION_TRAFFIC: 'Location Traffic',
  IMPRESSION: 'Impression',
  TRAFFIC: 'Traffic',
  'CTR_%': 'CTR (%)',
  GROUP: 'Group',
  PLEASE_WAIT_A_MINUTE: 'Please wait a minute',
  CLICK_COUNT: 'Clicks',
  DATE: 'Date',
  BANNER: 'Banners',
  DEVICE_MAC: 'Device MAC',
  DEVICE_NAME: 'Device Name',
  AP_MAC: 'AP MAC',
  SERIAL: 'Serial',
  SESSION_START: ' Session Start',
  SESSION: 'Session',
  SELECT_LOCATION: 'Select location...',
  TODAY_LOGIN: 'Today Login',
  ONLINE_SESSION: 'Online Session',
  AUDIENCE: 'Audience',
  OS_REPORT: 'OS Report',
  DEVICE_TYPES_REPORT: 'Device Types Report',
  '15_DAYS': '15 days',
  '30_DAYS': '30 Days',
  VISIT: 'Visit',
  SURVEY: 'Survey',
  MAC: 'MAC',
  FIRST_LOGIN: 'First Login',
  LAST_LOGIN: 'Last Login',
  EXPORT_EXCEL: 'Export excel',
  DETAIL: 'Detail',
  OS_DEVICE: 'OS/DEVICE',
  SCHEDULE: 'Schedule',
  DEVICE: 'Device',
  BOOKING_STATUS: 'Booking Status',
  TOP_50_LOCATION: 'Top 50 Location',
  THIS_MONTH: 'This Month',
  Next_03_MONTH: 'Next 03 Month',
  THIS_YEAR: 'This Year',
  AVAILABLE_KPI: 'Available KPI',
  LOCATION_NAME: 'Location Name',
  EVERY_DAY: 'Every Day',
  BOOKING_AVAILABLE: ' Booking / Available',
  PERCENT: 'Percent (%)',
  TOP_CATEGORY: 'Top Category',
  THE_BEST_LOCATION: 'The Best Location',
  TRAFFIC_TODAY: 'Traffic today',
  MGN_LICENSE: 'MegaNet / MegaPlus',
  BOOKING_STATUS_30_DAYS: 'Booking Status 30 days',
  CAMPAIGN_ADS: 'Campaigns Ads',
  RUNNING: 'running',
  PUBLIC: 'Public',
  TRANSPORT: 'TransPort',
  AIRPORT: 'Airport',
  UNIVERSITY: 'University',
  COFFEE: 'Coffee',
  DRINK: 'Drink',
  KIDZONE: 'KidZone',
  HOSPITAL: 'Hospital',
  MALL: 'Mall',
  RESTAURANT: 'Restaurant',
  'FOOD_&_BEVERAGE': 'Food & Beverage',
  RAILWAY: 'Railway',
  GENERATE_HTML: 'Generate HTML',
  DISABLE: 'Disable',
  ENABLE: 'Enable',
  YES: 'Yes',
  CHOOSE_NAME: 'Select name...',
  STATUS: 'Status',
  CHOOSE_STATUS: 'Select status...',
  ADDRESS: 'Address',
  CHOOSE_ADDRESS: 'TYPE address...',
  CATEGORY: 'Category',
  CHOOSE_CATEGORY: 'Select category...',
  CITY: 'City',
  CHOOSE_CITY: 'Select city...',
  DISTRICT: 'District',
  CHOOSE_DISTRICT: 'Select district...',
  ACTIVE_DAY: 'Active Day',
  CREATED_DATE: 'Created Date',
  LICENSE: 'MegaPlus',
  NO_AUTHENTICATION: 'No authentication',
  BY_VOUCHER: 'By Voucher',
  BY_USERNAME_AND_PASSWORD: 'By Username & Password',
  LOCATION_MANAGEMENT: 'Location Management',
  GENERAL_INFO: 'General Info',
  ADVANCE_INFO: 'Advanced Info',
  AUTHENTICATION_TYPE: 'Authentication type',
  CHOOSE_VAUTHENTICATION_TYPE: 'Select the authentication type',
  CLICK_PER_MONTH: 'Click / Month',
  SHARE: 'Share',
  USER_MANAGER: 'User Manager',
  UNIT_PRICE: 'Unit Price',
  BASIC_INFO: 'BASIC INFO',
  NO_ROUTER: 'No. Router',
  NO_ACCESS_POINT: 'No. Access Point',
  CONFIG_AUTH: 'Config Auth',
  STATISTICS: 'Statistic',
  AVARAGE_PER_MONTH: 'Avarage / Month',
  LAST_THREE_MONTHS: 'Last three months',
  MAC_ADDRESS: 'Mac address',
  SPECIES: 'Species',
  MODEL: 'Model',
  AMOUNT: 'Amount',
  APPLY: 'Apply',
  SUCCESSFULLY_PURCHASED_CLOUD_SERVER_ECS: 'Successfully Purchased Cloud Server ECS!',
  GO_CONSOLE: 'Go Console',
  BUY_AGAIN: 'Buy Again',
  NEXT: 'Next',
  NAME_IS_REQUIRED: 'Name is required!',
  CATEGORY_IS_REQUIRED: 'Category is required!',
  CLICK_IS_REQUIRED: 'Please enter the number of clicks!',
  CLICK_MUST_BE_POSITIVE: 'The number of clicks must be positive!',
  MUST_CHOOSE_ONE_AUTHENTICATION: 'Must Select 1 form of authentication!',
  DESCRIPTION_IS_REQUIRED: 'Description is required!',
  GROUP_DEVICE_IS_REQUIRED: 'Group device is required!',
  MAX_OF_DEVICE_IS_REQUIRED: 'Max of device is required!',
  PRIORITY: 'priority',
  SUM_KPI: 'Sum KPI',
  KPI_COMPLETE: 'Completed',
  PLEASE_SELECT_A_LOCATION: 'Please select a location',
  MAC_ADDRESS_IS_REQUIRED: 'Address MAC is required!',
  TYPE_IS_REQUIRED: 'Type is required!',
  MODEL_IS_REQUIRED: 'Model is required!',
  LOCATION_IS_REQUIRED: 'Location is required!',
  BRAND_IS_REQUIRED: 'Brand is required!',
  EQUIPMENT_MANAGEMENT: 'Equipment management',
  BRAND: 'Brand',
  CHOOSE_BRAND: 'Select Brand',
  CREATOR_NAME: 'Creator Name',
  HTML_CODE: 'HTML CODE',
  INPUT_HTML_CODE: 'Input HTML code',
  VIEW_BANNER: 'View Banner',
  OWNER: 'Owner',
  CREATED_AT: 'Created At',
  BASIC: 'Basic',
  BANNER_CATEGORY: 'Banner Category',
  CHOOSE_LOCATION_CATEGORY: 'Select location category',
  UNSELECT: 'Unselect',
  SLIDE: 'Slide',
  VIDEO: 'Video',
  FORM: 'Form',
  SIMPLE: 'Simple',
  HTML_CODE_IS_REQUIRED: 'HTML CODE is required!',
  DATA_MUST_BE_JSON_FORMAT: 'Data must be JSON format!',
  OPTIONS_ARE_REQUIRED: 'Options are required!',
  BANNER_TEMPLATE: 'Banner Template',
  OPTIONS: 'Options',
  INPUT_OPTIONS: 'Input options',
  VIEW: 'View',
  NOTIFICATION: 'Notification',
  'MARKED_AS_READ ': 'Marked as read',
  DELETE_ALL: 'Delele All',
  VIEW_MORE: 'View more',
  USERS_INFORMATION: 'USERS INFOMATION',
  EMAIL: 'Email',
  PROFILE: 'Profile',
  OWNER_INFOMATION: 'OWNER INFOMATION',
  COMPANY: 'Company',
  TAX: 'Tax',
  WEBSITE: 'Website',
  REFERENCES_INFOMATION: 'REFERENCES INFOMATION',
  NAME_USER: 'Name User',
  PHONE_USER: 'Phone User',
  USER: 'User',
  INPUT_EMAIL: 'Input Email. Example: meganet@gmail.com',
  PASSWORD: 'Password',
  INPUT_PASSWORD: 'Input password',
  GENERATE_PASS: 'Generate Pass',
  CHOOSE_PROFILE: 'Select Profile',
  PHONE: 'Phone',
  INPUT_PHONE: 'Input phone. Example: 0123456789...',
  NAME_COMPANY: 'Name Company',
  INPUT_NAME_COMPANY: 'Input name Company / Location/ ...',
  INPUT_TAX: 'Input Tax',
  INPUT_WEBSITE: 'Input Website. Example: https://meganet.com.vn',
  CONTACT_USER: 'Contact User',
  INPUT_NAME_CONTACT: 'Input name contact',
  CREATOR: 'Creator',
  TIME_EXPIRED: 'Time expired',
  VERIFIED: 'Verified',
  SCHEDULE_FROM: 'Schedule From',
  SCHEDULE_TO: 'Schedule To',
  CONTRACT_COSTS: 'Contract Costs',
  COMMISSION: 'Commission',
  MONEY: 'Money',
  PERCENTAGE: 'Percentage',
  INVOICE_FEE: 'Invoice Fee',
  ACHIEVED_KPI: 'Achieved Kpi',
  COST_PRICE: 'Cost Price',
  TOTAL_AMOUNT: 'Total Amount',
  TOTAL_COST_PRICE: 'Total Cost Price',
  CAMPAIGN: 'Campaign',
  KPI: 'Kpi',
  ACTUAL_UNIT_PRICE: 'Actual Unit Price',
  CONTRACT_REVENUE: 'Contract Revenue',
  ACTUTAL_REVENUE: 'Actutal Revenue',
  YESTERDAY: 'Yesterday',
  TODAY: 'Today',
  REMAINING: 'Remaining',
  KPI_MANAGEMENT: 'KPI Management',
  REPORT_LOCATION: 'Report Location',
  CHOOSE_REPORT_LOCATION: 'Select Report Location',
  CHECK_ALL: 'Check All',
  CURRENT_KPI: 'Current KPI',
  ADDITIONAL_KPI: 'Additional KPI',
  KPI_CHANGE_WILL_BE_APPLIED: 'KPI change will be applied in 2 mins',
  SCHEDULED: 'SCHEDULED',
  STOPPED: 'STOPPED',
  INPUT_KPI: 'Input KPI (Click)',
  SET_A_NEW_CAMPAIGN: 'Set a new campaign',
  CAMPAIGN_MANAGEMENT: 'Campaign Management',
  CAMPAIGN_NAME: 'Campaign Name',
  TYPE_CAMPAIGN: 'Type of Campaign',
  TYPE_BRAND_CAMPAIGN: 'Type of brands for campaign',
  TYPE_OF_BRAND: 'Type of brands',
  OPTIMIZATION_TYPE: 'Optimization Type',
  ENTER_DESCRIPTION: 'Enter Description',
  TOTAL_BOOKING: 'Total Booking',
  TOTAL_LOCATIONS: 'Total Locations',
  APP_ID_AND_APP_SECRET: 'App ID and Secret App can be found at:',
  FACEBOOK_DEVELOPER: 'Facebook developer',
  PAGE: 'Page',
  FACEBOOK: 'Facebook',
  APP_ID: 'App ID',
  INPUT_FB_APP_ID: 'Input Facebook App ID',
  INPUT_ACCOUNT_KIT_SECRET: 'Input Account Kit Secret',
  INPUT_FB_APP_SECRET: 'Input Facebook App Secret',
  APP_SECRET: 'App Secret',
  ACCOUNT_KIT_SERVER: 'Account Kit Server',
  LOGIN_TYPE: 'Login Type',
  LINK_TO_SHARE: 'Link to share',
  TITLE: 'Title',
  INPUT_TITLE: 'The title of the link',
  CAPTION: 'Caption',
  INPUT_CAPTION: 'The caption of the link (appears beneath the link name)',
  DESCRIPTION: 'Description',
  INPUT_DESCRIPTION: 'The description of the link (appears beneath the link caption)',
  THUMBNAIL_IMAGE_URL: 'Thumbnail image URL',
  INPUT_THUMBNAIL: 'The URL of a picture attached to this post. At least 200px by 200px',
  GOOGLE: 'Google',
  INPUT_GOOGLE_ID: 'Input Google Client ID',
  INPUT_GOOGLE_SECRET: 'Input Google Client Secret',
  OS: 'OS',
  THERE_IS_NO_BANNER: 'There is no banner',
  ADD_BANNER: 'Add Banner',
  CALL_STATUS: 'Call Status',
  AGE: 'Age',
  GENDER: 'Gender',
  INCOME: 'Income',
  MARRIAGE: 'Marriage',
  ALERT_CRMCONFIG:
    'Customize the report by selecting the required fields. User must provide information. The system does not collect automatically.',
  DEFAULT_FIELDS: 'Default Fields',
  AVAILABLE_LOCATIONS: 'Available locations',
  ADD: 'Add',
  LOCATION_GROUPS: 'Location Groups',
  ADD_LOCATION: 'Add Location',
  ADD_GROUP: 'Add Group',
  MAIN_LANDING_PAGE: 'Main Landing Page',
  LOCATIONS_LANDING_PAGE: 'Locations Landing Page',
  REDIRECT_PAGE: 'Redirect Page',
  REDIRECT_PAGE_IS_REQUIRED: 'Redirect Page is required',
  UPDATE_CONFIG: 'Update Config',
  CAMPAIGN_CONFIG: 'Campaign Config',
  TARGET_OBJECT: 'Target Object',
  ADVANCE: 'Advance',
  LOCATION_GROUP: 'Location Group',
  DISAPPROVE: 'Disapprove',
  APPROVE: 'Approve',
  APPROVE_CAMPAIGN: 'Approve Campaign',
  STOP_FOREVER: ' Stop forever',
  DISABLE_CAMPAIGN: 'Disable Campaign',
  COMPLETED: 'Completed',
  INPUT_TIME: 'Input time',
  PAUSE: 'Pause',
  CONFIG: 'Config',
  COPY: 'Copy',
  NAME_CAMPAIGN_IS_REQUIRED: 'Name Campaign is required!',
  TYPE_CAMPAIGN_IS_REQUIRED: 'Type Campaign is required!',
  CAMPAIGN_INFORMATION: 'Campaign Information',
  CHOOSE_OWNER: 'Select owner',
  LANDING_PAGE: 'Landing page',
  PLEASE: 'Please',
  CHOOSE_CAMPAIGN: 'Select Campaign',
  FIRST: 'First!',
  CAMPAIGN_INFO: 'Campaign Info',
  FLOW_STATISTICS: 'Flow statistics',
  INFORMATION: 'Information',
  STATISTIC_DEVICE: 'Statistic device',
  STATISTIC_OPERATOR_SYSTEM: 'Statis Operator System',
  SUM_VIEW: 'Sum (View)',
  SUM_CLICK: 'Sum (Click Count)',
  AVG_CTR: 'Avg (CTR)',
  DETAIL_STATISTIC: 'Detail Statistic',
  MAIN_STATISTIC: 'Main Statistic',
  ACTIVE_CAMPAIGNS: 'ACTIVE CAMPAIGNS',
  TIME: 'Time',
  GENERAL: 'General',
  CAMPAIGN_TYPE_DEMO: 'Testing',
  CAMPAIGN_TYPE_PARTNER: 'Partner',
  CAMPAIGN_TYPE_REAL: 'Advertisement',
  CAMPAIGN_TYPE_TRASH: 'Trash',
  CAMPAIGN_TYPE_UNLIMITED: 'Unlimited',
  CAMPAIGN_TYPE_DEFAULT: 'Default',
  CAMPAIGN_TYPE_LICENSE: 'License',
  IMAGE_TEMPLATE: 'Image Template',
  IMAGE: 'Image',
  ABORT: 'Abort',
  DEVICE_MANAGEMENT: 'Device Management',
  INFORMATION_DEVICE: 'Device Information',
  TEXT: 'Text',
  VALUE: 'Value',
  META_DEVICE: 'Meta Device',
  METHOD: 'Method',
  CHOOSE_METHOD: 'Select Method',
  USERNAME: 'Username',
  CLIENT_MAC: 'Client Mac',
  LOGIN_URL: 'Login Url',
  LOGIN_URL_FORMAT: 'Login Url Format',
  REDIRECT_URL: 'Redirect Url',
  COMMON: 'Common',
  CHOOSE_GROUP: 'Select Group',
  INSERT_TITLE: 'Insert Title....',
  CHOOSE_BRAND_AP: 'Select Brand AP',
  CREATE_A_BEAUTY_DOCUMENT: 'Create a Beauty Document !!',
  INSTRUCTION: 'Instruction',
  INPUT_VIDEO: 'Input link of a video',
  FILE: 'File',
  GROUP_CATEGORY: 'Group Category',
  PRICE: 'Price',
  INPUT_PRICE: 'Input Price ....',
  DEVICE_GROUP: 'Group Device',
  CHOOSE_DEVICE: 'Select Device',
  ADD_NEW_TYPE: 'Add new type',
  NEW_TYPE: 'New type',
  BRAND_CAMPAIGN: 'Brand Campaign',
  BRAND_AP: 'Brand AP',
  PLAN: 'Plan',
  CODE: 'Code',
  INPUT_CODE: 'Input code',
  CHOOSE_GROUP_DEVICE: 'Select group device',
  INPUT_AMOUNT: 'Input amount',
  ROLES_MANAGEMENT: 'Roles Management',
  RESOURCES: 'Resources',
  LOCATION_LIST: 'Locations',
  PRICING_LIST: 'Price',
  CAMPAIGN_LIST: 'Campaigns',
  EQUIPMENT_LIST: 'Equipments',
  CAMPAIGNS_PRIORITY: 'Campaigns Priority',
  LOCATIONS_PRIORITY: 'Locations Priority',
  ACTIVE_CAMPAIGN: 'Active Campaigns',
  BOOKING_ONLINE: 'Booking Online',
  BANNER_LIST: 'Banners',
  REPORT_CAMPAIGN: 'Report Campaign',
  REPORT_CAMPAIGN_PARTNER: 'Report Partner',
  REPORT_REVENUE: 'Report Revenue',
  COMMON_DATA: 'Common Data',
  BANNER_IMAGE: 'Banner Image',
  EQUIPMENT_MODEL: 'Equipment Model',
  EQUIPMENT_BRAND: 'Equipment Brand',
  EQUIPMENT_GROUP: 'Equipment Group',
  LOCATION_TAGS: 'Location Tags',
  LOCATION_CATEGORY_GROUP: 'Category Groups',
  LOCATION_CATEGORY: 'Location Category',
  CAMPAIGN_BRAND: 'Campaign Brand',
  USER_LIST: 'Accounts',
  USER_SETTING: 'My profile',
  PROFILE_LIST: 'Profiles',
  ASSIGN_CAMPAIGN: 'Assign Campaign',
  ASSIGN_LOCATION: 'Assign Location',
  ASSIGN_PROFILE: 'Assign Profile',
  ASSIGN_BANNER: 'Assign Banner',
  KPI_BOOKING: 'Kpi Book',
  TOTAL_CLICK: 'Total Click',
  VIEW_REPORT: 'View Report',
  COMMON_REGION: 'Region',
  FORGOT_PASSWORD: 'Forgot Password?',
  VIEW_ADS: 'Realtime Ads',
  MGN_REVENUE: 'MGN',
  CUSTOMER_REVENUE: 'Customer',
  REVENUE: 'Revenue',
  ACTUAL_REVENUE: 'Actual Revenue',
  VERIFY: 'Verify',
  REJECT: 'Reject',
  PENDING: 'Pending',
  PAYMENT_LIST: 'Payments',
  INCOME_BY_CAMPAIGN: 'By Campaign',
  INCOME_BY_LOCATION: 'By Location',
  INCOME_FOR_PARTNER: 'For Partner',
  CUSTOMER: 'Customer',
  CUSTOMER_TYPE: 'Customer Type',
  CAMPAIGN_BOOKED: 'Campaigns Booked',
  TRAFFIC_LOCATION: 'Locations Traffic',
  FORGOT_YOUR_PASSWORD: 'Forgot password',
};

export default translations;
