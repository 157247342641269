import i18next from 'i18next';
import { debounce, get } from 'lodash';
import { action, computed, observable, reaction, runInAction } from 'mobx';
import { actionAsync, task } from 'mobx-utils';

import { ColDef, GridReadyEvent } from '@ag-grid-community/core';
import { FilterSelect, LICENSE, MGN, WhereArgs } from '@source/common';

import { ErrorNotify, SuccessNotify } from '~components/UI/Notification';
import { ResetPasswordMutationVariables, UserInput, UserUpdateArg } from '~graphql/_sdk';

import { getSdk } from '~graphql/sdk';

class UserStore {
  @observable public currentUser;
  @observable public modalVisible;
  @observable public isShowBudgetForm: boolean;
  @observable public selectedItem;
  @observable public listProfile;
  @observable public where: WhereArgs;
  @observable public searchWhere;
  @observable public listPermission: string[];
  @observable public listCreator;
  @observable public loading: boolean;
  @observable public drawerVisible;
  @observable public initFilterConfig: FilterSelect[];
  @observable public ownerType: string;
  @observable public updatedBudgetData;
  @observable public check: boolean;
  private initialized: boolean;
  public colDefs: ColDef[];

  constructor() {
    runInAction(() => {
      this.reset();
      reaction(
        () => this.currentUser,
        (currentUser) => {
          if (!currentUser) return;
          this.getListProfiles();
        }
      );
    });
  }

  @action
  public reset = () => {
    this.colDefs = null;
    this.currentUser = null;
    this.selectedItem = null;
    this.modalVisible = false;
    this.listProfile = [];
    this.ownerType = MGN;
    this.searchWhere = { _search: '' };
    this.where = {};
    this.listPermission = [];
    this.listCreator = [];
    this.drawerVisible = false;
    this.initialized = false;
    this.isShowBudgetForm = false;
    this.check = true;

    this.initFilterConfig = [
      {
        fieldValue: 'value',
        fieldText: 'text',
        config: {
          field: 'Permission',
          label: 'Permission',
          placeholder: 'Choose Permission'
        }
      },
      {
        fieldValue: 'value',
        fieldText: 'text',
        config: {
          field: 'Creator',
          label: 'Creator',
          placeholder: 'Choose Creator'
        }
      }
    ];
  };

  @computed
  get whereFilter() {
    const where = {
      ownerType: this.ownerType,
      ...this.searchWhere,
      ...this.where
    };
    this.reload();
    return where;
  }

  @action
  onCheck = (check) => {
    this.check = check;
  };

  // @action
  // public init = (forceReinit = false) => {
  //   if (this.initialized && !forceReinit) return;
  //   this.loading = true;
  //   this.fetchData().finally(
  //     action(() => {
  //       this.initialized = true;
  //     })
  //   );
  // };

  @action
  public initColDef = (colDef) => {
    this.colDefs = colDef;
  };

  @action
  setCurrentUser = (currentUser) => {
    this.currentUser = currentUser;
  };

  @action
  public setFilter = (where: WhereArgs) => {
    this.where = where;
  };

  @action
  public searchFilter = debounce(
    (text: string) => {
      runInAction(() => {
        this.searchWhere = { _search: text };
      });
    },
    500,
    { leading: false, trailing: true }
  );

  @action
  setDrawerVisible = (selectedItem = null) => {
    this.selectedItem = selectedItem;
    this.drawerVisible = !this.drawerVisible;
    // if(this.drawerVisible){
    //   return;
    // }
  };

  @action
  setModalVisible = (selectedItem = null, type: string) => {
    this.onCheck(get(selectedItem, 'extra.isBudget', false));
    // this.selectedItem = selectedItem;
    this.modalVisible = !this.modalVisible;
    if (this.modalVisible && type === 'user' && selectedItem) {
      this.getDataUserForm(selectedItem._id);
    } else {
      this.selectedItem = null;
    }
  };

  @action
  setIsShowBudget = (value: boolean, selectedItem = null) => {
    this.updatedBudgetData = selectedItem;
    this.isShowBudgetForm = value;
    if (!value) {
      this.updatedBudgetData = null;
    }
  };

  @actionAsync
  public getDataUserForm = async (_id) => {
    const sdk = await task(getSdk());
    const { findUserById } = await task(sdk.fetchUserForm({ _id }));
    this.selectedItem = findUserById;
  };

  @actionAsync
  getListProfiles = async () => {
    const sdk = await task(getSdk());
    const { findManyProfile } = await task(sdk.findManyProfileMinimal());
    this.listProfile = findManyProfile;
  };

  @actionAsync
  createUser = async (record: UserInput) => {
    const sdk = await task(getSdk());
    const {
      createUser: { error }
    } = await task(sdk.createUser({ record }));

    if (error) {
      ErrorNotify(error.code);
    } else {
      SuccessNotify('create');
      this.reload();
    }
  };

  @actionAsync
  setActive = async (email: string, active: boolean) => {
    const sdk = await task(getSdk());
    const {
      setActive: { error }
    } = await task(sdk.setActive({ email, active }));

    if (error) {
      ErrorNotify(error.code);
    } else {
      SuccessNotify('change active');
      this.reload();
    }
  };

  @actionAsync
  setUpgrateLicense = async (_id: string) => {
    const sdk = await task(getSdk());
    const {
      upgradeLicense: { error }
    } = await task(sdk.upgradeLicense({ _id }));

    if (error) {
      ErrorNotify(error.code);
    } else {
      SuccessNotify('Upgrade License');
      this.reload();
    }
  };

  @actionAsync
  setValueVerifyEmail = async (email: string) => {
    const sdk = await task(getSdk());
    const {
      setValueVerifyEmail: { error }
    } = await task(sdk.setValueVerifyEmail({ email }));

    if (error) {
      ErrorNotify(error.code);
    } else {
      SuccessNotify('verify email');
      this.reload();
    }
  };

  @actionAsync
  updateUser = async (_id: string, record: UserUpdateArg) => {
    const sdk = await task(getSdk());
    const {
      updateUserInfoById: { error, data }
    } = await task(sdk.updateUserInfoById({ _id, record }));

    if (error) {
      ErrorNotify(error.code);
    } else {
      SuccessNotify(i18next.t('UPDATED'));
      this.reload();
    }
  };

  @actionAsync
  removeUser = async (_id: string) => {
    const sdk = await task(getSdk());
    const {
      removeUserById: { error }
    } = await task(
      sdk.removeUserById({
        _id
      })
    );

    if (error) {
      ErrorNotify(error.code);
    } else {
      SuccessNotify('remove');
      this.reload();
    }
  };

  @actionAsync
  resetPasswordUser = async (record: ResetPasswordMutationVariables) => {
    const sdk = await task(getSdk());
    const {
      resetPassword: { error }
    } = await task(sdk.resetPassword({ ...record }));

    if (error) {
      ErrorNotify(error.code);
    } else {
      SuccessNotify('remove');
      this.reload();
    }
  };

  @action
  setPermission = (value) => {
    this.listPermission.push(value);
  };

  @action
  setCreator = (value) => {
    this.listCreator.push(value);
  };

  @action
  public handleGridReady = ({ api, columnApi }: GridReadyEvent) => {
    this.reload = () => {
      api.onFilterChanged();
      api.onSortChanged();
    };
    this.refresh = () => {
      api.onSortChanged();
    };
    this.setColDefs = () => {
      if (this.ownerType === MGN) {
        columnApi.setColumnVisible('extra.licenseType', false);
        columnApi.setColumnVisible('extra.expiredDate', false);
        api.sizeColumnsToFit();
      }
      if (this.ownerType === LICENSE || !this.ownerType) {
        columnApi.setColumnVisible('extra.licenseType', true);
        columnApi.setColumnVisible('extra.expiredDate', true);
        api.sizeColumnsToFit();
      }
    };
  };

  @action
  setWhere = (where: WhereArgs) => {
    this.where = { ...this.where, ...where };
  };

  @action
  public setOwnerType = (ownerType: string) => {
    this.ownerType = ownerType;
    this.setColDefs();
    this.setWhere({ ownerType });
    this.reload();
  };

  // private fetchData = async () => {
  //   await this.getListProfiles();
  // };

  @actionAsync
  public sendEmailVerify = async (email: string) => {
    const sdk = await task(getSdk());
    const {
      sendEmailVerify: { error }
    } = await task(sdk.sendEmailVerify({ email }));
    if (error) {
      ErrorNotify(error.code);
    } else {
      SuccessNotify(i18next.t('SEND_EMAIL'));
    }
  };

  private setColDefs = () => undefined;
  public refresh = () => undefined;
  @action
  public reload = () => undefined;
}

export default new UserStore();
