const notify = {
  notify: {
    SUCCESS: 'Thành công',
    FAILED: 'Thất bại',
    INVALID_LOGIN_ERR: 'Đăng nhập không thành công',
    INVALID_LOGIN_USER_LOCKED: 'Tài khoản của bạn đã bị vô hiệu hóa'
  }
};

export default notify;
