import { throttle, uniq } from 'lodash';
import { action, computed, observable, reaction, runInAction, toJS } from 'mobx';
import { actionAsync, task } from 'mobx-utils';
import moment from 'moment';

import { GridApi, GridReadyEvent } from '@ag-grid-community/core';

import * as _sdk from '~graphql/_sdk';
import { getSdk } from '~graphql/sdk';
import { FilterLevelItem, filterMultiLevel } from '~utils/filterMultiLevel';

interface SelectOption {
  value: string;
  text: string;
}

type StoreOptionField =
  | 'categoryOptions'
  | 'cityOptions'
  | 'districtOptions'
  | 'locationOptions'
  | 'tagOptions'
  | 'areaOptions'
  | 'customerOptions';

// type StoreMappingField = 'cityMapping' | 'districtMapping' | 'areaMapping' | 'tagMapping';

type StoreCommonField = 'cities' | 'districts' | 'areas' | 'tags' | 'locations' | 'categories';

type FilterField =
  | 'categoryId'
  | '_id'
  | 'city'
  | 'district'
  | 'tag'
  | 'date'
  | 'area'
  | 'status'
  | 'ownerType';

export type StoreTab = 'chart' | 'grid' | 'share' | 'campType' | 'upDown' | 'userReturn';

class ReportLocationStore {
  @observable public loading: boolean;
  @observable public tab: StoreTab;
  @observable public chartData: _sdk.ReportLocationData[];
  @observable public statisticData: _sdk.Total;
  @observable public where: _sdk.RpLocationWhereFilterInput;
  @observable public locationFilter: _sdk.RpLocationWhereFilterInput;
  @observable public gridApi: GridApi;
  @observable public sharedTrafficChart: _sdk.ReportSharedTrafficResult;
  @observable public reportUserReturn: _sdk.ReportUserReturnData[];
  @observable public customerId: string;
  @observable public amongTime: number;
  @observable public campaignRatioData: _sdk.ReportCampaignRatioResult[];

  @observable public locations: _sdk.Location[];
  @observable public cities: _sdk.Region[];
  @observable public districts: _sdk.Region[];
  @observable public areas: _sdk.Region[];
  @observable public tags: _sdk.Common[];
  @observable public categories: _sdk.Common[];

  @observable.ref public categoryOptions: SelectOption[];
  @observable.ref public cityOptions: SelectOption[];
  @observable.ref public districtOptions: SelectOption[];
  @observable.ref public locationOptions: SelectOption[];
  @observable.ref public tagOptions: SelectOption[];
  @observable.ref public customerOptions: SelectOption[];
  @observable.ref public reportUpDown: _sdk.ReportLocationData[];

  constructor() {
    this.reset();

    reaction(
      () => this.tab,
      (tab) => {
        switch (tab) {
          case 'chart':
            this.getChartData();
            break;
          case 'grid':
            this.getReportData();
            break;
          case 'share':
            this.getSharedTrafficChartData(this.customerId);
            break;
          case 'userReturn':
            this.getReportUserReturn();
            break;
          case 'campType':
            this.getCampaignRatioData();
            break;
          case 'upDown':
            this.getReportUpDown(this.amongTime);
            break;
          default:
            return null;
        }
      }
    );

    reaction(
      () => this.where.city,
      (city) => {
        if (city) {
          const districts = this.districts.filter((item) => city.includes(item.city));
          this.initSelectData(districts, 'districtOptions', 'value', 'text');
        } else {
          this.where.district = undefined;
          this.locationFilter.district = undefined;
          this.districtOptions = [];
        }
      }
    );

    reaction(
      () => toJS(this.locationFilter),
      (filter) => {
        const filterItems: FilterLevelItem[] = Object.keys(filter).reduce((all, k) => {
          if (filter[k]) {
            all.push({ field: k, value: filter[k] });
          }
          return all;
        }, []);
        const locations = filterMultiLevel(this.locations, filterItems);
        this.initSelectData(locations, 'locationOptions', '_id', 'name');
      }
    );

    reaction(
      () => this.customerId,
      (id) => {
        this.getSharedTrafficChartData(id);
      }
    );
  }

  @action
  public reset = () => {
    this.locations = [];
    this.tags = [];
    this.categories = [];
    this.cities = [];
    this.districts = [];
    this.areas = [];
    this.tab = 'chart';
    this.loading = false;
    this.chartData = null;
    this.statisticData = null;
    this.where = {
      date: {
        gte: moment().subtract(29, 'day').startOf('day'),
        lte: moment().endOf('day')
      }
    };
    this.locationFilter = {};
    this.categoryOptions = [];
    this.cityOptions = [];
    this.districtOptions = [];
    this.locationOptions = [];
    this.tagOptions = [];
    this.customerId = null;
    this.amongTime = 7;
    this.customerOptions = [];
    this.sharedTrafficChart = null;
    this.reportUserReturn = [];
    this.campaignRatioData = [];
    this.reportUpDown = [];
  };

  @computed
  public get getFilter() {
    return this.where;
  }

  @action
  public initSelectData = (
    data: any[],
    storeField: StoreOptionField,
    valueField: string,
    textField: string
  ) => {
    this[storeField] = data.map((item) => ({ value: item[valueField], text: item[textField] }));
  };

  @action
  public initCommonData = (data: any[], storeField: StoreCommonField) => {
    this[storeField] = data;
    const categoryIds = uniq(this.locations.map((l) => l.categoryId));
    const categories = this.categories.filter((c) => categoryIds.includes(c.value));
    switch (storeField) {
      case 'cities':
        this.initSelectData(data, 'cityOptions', 'value', 'text');
        break;
      case 'categories':
        this.initSelectData(categories, 'categoryOptions', 'value', 'text');
        break;
      case 'tags':
        this.initSelectData(data, 'tagOptions', 'value', 'text');
        break;
      case 'areas':
        this.initSelectData(data, 'areaOptions', 'value', 'text');
        break;
      case 'locations':
        this.initSelectData(data, 'locationOptions', '_id', 'name');
        break;
      default:
        return null;
    }
  };

  @action
  public handleFilterChange = (filterField: FilterField, value: any[], tab: StoreTab) => {
    if (filterField !== '_id' && filterField !== 'date') {
      delete this.where._id;
    }

    if (filterField === 'date') {
      this.where = { ...this.where, [`${filterField}`]: { gte: value[0], lte: value[1] } };
    } else {
      this.where = { ...this.where, [`${filterField}`]: value.length ? value : undefined };
    }

    if (filterField !== '_id' && filterField !== 'date') {
      this.locationFilter = {
        ...this.locationFilter,
        [`${filterField}`]: value.length ? value : undefined
      };
    }

    switch (tab) {
      case 'chart':
        this.getChartData();
        break;
      case 'grid':
        this.getReportData();
        break;
      case 'share':
        this.getSharedTrafficChartData(this.customerId);
        break;
      case 'userReturn':
        this.getReportUserReturn();
        break;
      case 'campType':
        this.getCampaignRatioData();
        break;
      case 'upDown':
        this.getReportUpDown(this.amongTime);
        break;
      default:
        return null;
    }

    this.getStatisticData();
  };

  public onFilterChange = throttle(
    (filterField: FilterField, value: any[]) => {
      this.handleFilterChange(filterField, value, this.tab);
    },
    400,
    { leading: false, trailing: true }
  );

  @action
  public onTabChange = (tab: StoreTab) => {
    this.tab = tab;
  };

  @actionAsync
  public getReportUserReturn = async () => {
    const sdk = await task(getSdk());

    const { getReportUserReturn } = await task(
      sdk.getReportUserReturn({ where: this.where, dataType: _sdk.DataType.Category })
    );

    this.reportUserReturn = getReportUserReturn.data;
  };

  @actionAsync
  public getReportData = async () => {
    const sdk = await task(getSdk());

    const { getReportLocationData } = await task(
      sdk.getReportLocationData({ where: this.where, dataType: _sdk.DataType.Category })
    );

    this.gridApi.setRowData(getReportLocationData.data);
    this.gridApi.hideOverlay();
  };

  @actionAsync
  public getReportUpDown = async (amongTime?: number) => {
    const sdk = await task(getSdk());

    const { getReportLocationUpDown } = await task(
      sdk.getReportLocationUpDown({
        where: this.where,
        dataType: this.where.categoryId ? _sdk.DataType.Location : _sdk.DataType.Category,
        amongTime: amongTime || 7
      })
    );

    runInAction(() => {
      this.reportUpDown = getReportLocationUpDown.data;
    });
  };

  @actionAsync
  public onOpenCategory = async (categoryId: string) => {
    const sdk = await task(getSdk());
    const { getReportLocationData } = await task(
      sdk.getReportLocationData({
        where: { ...this.where, categoryId: [categoryId] },
        dataType: _sdk.DataType.Location
      })
    );

    return getReportLocationData;
  };

  @actionAsync
  public onOpenLocation = async (_id: string) => {
    const sdk = await task(getSdk());
    const { getReportLocationData } = await task(
      sdk.getReportLocationData({
        where: { ...this.where, _id: [_id] },
        dataType: _sdk.DataType.Detail
      })
    );

    return getReportLocationData;
  };

  @actionAsync
  public getChartData = async () => {
    const sdk = await task(getSdk());
    const { getReportLocationData } = await task(
      sdk.getReportLocationData({
        where: { ...this.where },
        dataType: _sdk.DataType.Detail
      })
    );
    this.chartData = getReportLocationData.data;
  };

  @actionAsync
  public getStatisticData = async () => {
    const sdk = await task(getSdk());
    const { getReportLocationData } = await task(
      sdk.getReportLocationData({
        where: { ...this.where },
        dataType: _sdk.DataType.Detail
      })
    );
    this.statisticData = getReportLocationData.total;
  };

  @actionAsync
  public getSharedTrafficChartData = async (customerId: string) => {
    if (!customerId) {
      this.sharedTrafficChart = null;
      return;
    }
    const sdk = await task(getSdk());
    const { getReportSharedTrafficDetails } = await task(
      sdk.getReportSharedTrafficDetails({ filter: { ...this.where, customerId } })
    );

    [this.sharedTrafficChart] = getReportSharedTrafficDetails;
  };

  @actionAsync
  public loadCustomer = async () => {
    const sdk = await task(getSdk());
    const { findManyCustomer } = await task(
      sdk.findManyCustomer({ where: { shareTraffic: { enabled: true } } })
    );
    this.initSelectData(findManyCustomer, 'customerOptions', '_id', 'name');
  };

  @actionAsync
  public setCustomerId = (id: string) => {
    this.customerId = id;
  };

  @actionAsync
  public getCampaignRatioData = async () => {
    const sdk = await task(getSdk());
    const { getReportCampaignRatio } = await task(
      sdk.getReportCampaignRatio({
        filter: this.where
      })
    );
    this.campaignRatioData = getReportCampaignRatio;
  };

  @action
  public onGridClientReady = ({ api }: GridReadyEvent) => {
    this.gridApi = api;
    this.getReportData();
  };

  @actionAsync
  public setUpDownDate = (among: number) => {
    this.amongTime = among;
    this.getReportUpDown(this.amongTime);
  };

  private reload = () => undefined;
}

export default new ReportLocationStore();
