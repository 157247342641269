import { Button, Col, Popconfirm, Row, Table, Tag } from 'antd';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { FC } from 'react';
import ReactResizeDetector from 'react-resize-detector';
import { useTranslation } from 'react-i18next';

import { EditOutlined, EnterOutlined, StopOutlined } from '@ant-design/icons';
import { FINISHED, STOPPED } from '@source/common';

import { formatNumber } from '~utils/formatNumber';

import store from './store';

const KPIManagementGrid: FC = observer(() => {
  const {
    searchCommonLocations,
    stopBooking,
    setModalVisible,
    campaignIdChosen,
    loading,
    totalResult
  } = store;

  const { t } = useTranslation();

  const columns = [
    {
      title: t('LOCATION'),
      dataIndex: 'locationName',
      key: 'locationName',
      fixed: 'left',
      width: 300,
      render: (record, data: any) => {
        if (data.key === 'totalResult') {
          return `Total ${data.locations} locations`;
        }

        let stop = false;
        if (data.status === FINISHED || data.status === STOPPED || data.kpi === -1) {
          stop = true;
        }

        const dataLocation =
          data.reportLocationId !== data.locationId ? (
            <>
              <span style={{ color: '#fe9929', marginLeft: '21px' }}>
                <EnterOutlined rotate={90} /> {record} {stop ? <Tag color="red">Stopped</Tag> : ''}
              </span>
            </>
          ) : (
            <>
              <span>
                {record} {stop ? <Tag color="red">Stopped</Tag> : ''}
              </span>
            </>
          );
        return dataLocation;
      }
    },
    {
      title: '',
      dataIndex: 'locationId',
      key: 'locationId',
      width: 70,
      align: 'center',
      fixed: 'left',
      render: (record, data) => {
        let stop = false;
        if (data.status === FINISHED || data.status === STOPPED || data.kpi === -1) {
          stop = true;
        }
        return (
          <>
            <Button.Group size="small" style={{ display: !record ? 'none' : 'inline-block' }}>
              <Button
                icon={<EditOutlined />}
                color="blue"
                onClick={() => setModalVisible(record, 'edit')}
              />
              <Popconfirm
                title="Stop?"
                onConfirm={() => stopBooking({ locationId: record, campaignId: campaignIdChosen })}
              >
                {!stop ? <Button icon={<StopOutlined />} danger type="default" /> : <></>}
              </Popconfirm>
            </Button.Group>
          </>
        );
      }
    },
    {
      title: t('TOTAL'),
      width: 200,
      render: (record, data) => {
        return (
          <div
            style={{ textAlign: 'right', color: data.statsKpiCompleted === 100 ? 'blue' : 'unset' }}
          >
            <span>
              {formatNumber(data.statsClick)} / {formatNumber(data.statsKpi)} /{' '}
              {Number(data.statsKpiCompleted).toFixed(0)} %
            </span>
          </div>
        );
      }
    },
    {
      title: t('YESTERDAY'),
      width: 200,
      render: (record, data) => {
        return (
          <div style={{ textAlign: 'right' }}>
            <span>
              {formatNumber(data.yesterdayClick)} / {formatNumber(data.yesterdayKpi)} /{' '}
              {Number(data.yesterdayKpiCompleted).toFixed(0)} %
            </span>
          </div>
        );
      }
    },
    {
      title: t('TODAY'),
      width: 200,
      render: (record, data) => {
        return (
          <div style={{ textAlign: 'right' }}>
            <span>
              {formatNumber(data.todayClick)} / {formatNumber(data.todayKpi)} /{' '}
              {Number(data.todayKpiCompleted).toFixed(0)} %
            </span>
          </div>
        );
      }
    },
    {
      title: `${t('REMAINING')} / ${t('TODAY')}`,
      dataIndex: 'statsKpiRemaining',
      key: 'statsKpiRemaining',
      align: 'right',
      fixed: 'right',
      width: 120,
      render: (record, data) => {
        if (data.scheduleTo && record) {
          const dueDays = moment(data.scheduleTo).diff(moment().startOf('days'), 'days');
          return <span>{`${formatNumber(record)} / ${dueDays}`}</span>;
        }
        return record ? formatNumber(record) : 0;
      }
    }
  ];

  const footer = (
    <Row gutter={16} justify="space-around">
      <Col>
        <strong>{totalResult?.locations} locations</strong>
      </Col>
      <Col>
        <strong>Total: </strong>
        <span>
          {formatNumber(totalResult?.statsClick)} / {formatNumber(totalResult?.statsKpi)}
        </span>
      </Col>
      <Col>
        <strong>Remaining: </strong>
        <span>{formatNumber(totalResult?.statsKpiRemaining)}</span>
      </Col>
      <Col>
        <strong>Yesterday: </strong>
        <span>
          {formatNumber(totalResult?.yesterdayClick)} / {formatNumber(totalResult?.yesterdayKpi)}
        </span>
      </Col>
      <Col>
        <strong>Today: </strong>
        <span>
          {formatNumber(totalResult?.todayClick)} / {formatNumber(totalResult?.todayKpi)}
        </span>
      </Col>
    </Row>
  );

  return (
    <ReactResizeDetector handleHeight>
      {({ height }) => {
        return (
          <Table
            columns={columns as any}
            dataSource={searchCommonLocations}
            bordered
            size="small"
            pagination={false}
            scroll={{ x: 1000, y: height - 200 }}
            loading={loading}
            expandable={{
              defaultExpandAllRows: true
            }}
            // footer={() => footer}
            rowClassName={(record) => {
              if (record.children) {
                return 'row-expandeble';
              }
              return '';
            }}
          />
        );
      }}
    </ReactResizeDetector>
  );
});

export default KPIManagementGrid;
