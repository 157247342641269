import '@ant-design/compatible/assets/index.css';

import { Col, Divider, Input, Modal, Row } from 'antd';
import Select, { SelectProps } from 'antd/lib/select';
import i18next from 'i18next';
import { get } from 'lodash';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import React, { FC, ReactText } from 'react';
import useForm from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { Form } from '@ant-design/compatible';

import { UISelect, UISelectProps } from '~components/UI/Select/UISelect';
import UIField from '~components/UI/UIField';
import { CommonInput, CommonUpdateArg } from '~graphql/_sdk';

import store from './store';

const { Option } = Select;
interface FormValues {
  value?: string;
  text?: string;
  brand?: string;
  apMac?: string;
  clientMac?: string;
  loginUrl?: string;
  loginUrlFormat?: string;
  redirectUrl?: string;
  username?: string;
  password?: string;
  method?: string;
  type?: string;
  metaType?: string;
}
const isValidJSON = string => {
  try {
    JSON.parse(string);
  } catch (e) {
    return false;
  }
  return true;
};

yup.addMethod(yup.string, 'JSON', function(message: string) {
  return this.test('validate-JSON', message, value => isValidJSON(value));
});

const validationSchema = yup.object().shape<FormValues>({
  text: yup.string().required(i18next.t('COMMON_DATA_VALIDATE_TEXT_REQUIRE')),
  value: yup.string().required(i18next.t('COMMON_DATA_VALIDATE_VALUE_REQUIRE')),
  brand: yup.string().required(i18next.t('BRAND_IS_REQUIRED')),
  metaType: yup.string().required(i18next.t('TYPE_IS_REQUIRED'))
});

const ModelDeviceForm: FC = observer(() => {
  const { t } = useTranslation();
  const { modalVisible, setModalVisible, create, update, selectedItem, typeBrand } = store;

  const initValues: FormValues = {
    text: get(selectedItem, 'text', ''),
    value: get(selectedItem, 'value', ''),
    brand: get(selectedItem, 'meta.brand'),
    type: get(selectedItem, 'type', 'model_device'),
    metaType: get(selectedItem, 'meta.type'),
    apMac: get(selectedItem, 'meta.fields.apMac', ''),
    clientMac: get(selectedItem, 'meta.fields.clientMac', ''),
    loginUrl: get(selectedItem, 'meta.fields.loginUrl', ''),
    loginUrlFormat: get(selectedItem, 'meta.fields.loginUrlFormat', ''),
    redirectUrl: get(selectedItem, 'meta.fields.redirectUrl', ''),
    username: get(selectedItem, 'meta.fields.username', ''),
    password: get(selectedItem, 'meta.fields.password', ''),
    method: get(selectedItem, 'meta.fields.method')
  };

  const formProps = useForm({ validationSchema });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting }
  } = formProps;

  const onCancel = () => {
    reset(initValues);
    setModalVisible();
  };

  const onSubmit = async (record: FormValues) => {
    const {
      text,
      value,
      brand,
      type,
      apMac,
      loginUrl,
      loginUrlFormat,
      redirectUrl,
      username,
      password,
      clientMac,
      method,
      metaType
    } = record;
    const records = {
      text,
      value,
      type,
      meta: {
        brand,
        type: metaType,
        fields: {
          clientMac,
          loginUrl,
          apMac,
          loginUrlFormat,
          redirectUrl,
          username,
          password,
          method
        }
      }
    };

    if (selectedItem) {
      await update(get(selectedItem, '_id'), records as CommonUpdateArg);
    } else {
      await create(records as CommonInput);
    }
    onCancel();
  };

  const metaTypeOptions = [
    { text: 'AP', value: 'ap' },
    { text: 'Router', value: 'router' }
  ];
  return (
    <Modal
      title={t('DEVICE_MANAGEMENT')}
      visible={modalVisible}
      cancelText="Close"
      cancelButtonProps={{ danger: true, ghost: true }}
      onCancel={() => onCancel()}
      okText={selectedItem ? t('UPDATE') : t('CREATE')}
      width={1200}
      okButtonProps={{
        htmlType: 'submit',
        form: 'ModelDeviceForm',
        type: 'primary',
        loading: isSubmitting
      }}
    >
      <Form id="ModelDeviceForm" onSubmit={handleSubmit(onSubmit)} layout="vertical">
        <Divider orientation="left" style={{ color: '#333', fontWeight: 'normal' }}>
          {t('INFORMATION_DEVICE')}
        </Divider>
        <Row gutter={8}>
          <Col span={6}>
            <UIField
              {...formProps}
              name="text"
              label={t('TEXT')}
              fieldProps={{
                placeholder: t('COMMON_DATA_PLACEHOLDER_TEXT')
              }}
              fieldComponent={Input}
              defaultValue={initValues.text}
            />
          </Col>
          <Col span={6}>
            <UIField
              {...formProps}
              name="value"
              label={t('VALUE')}
              fieldProps={{
                placeholder: t('COMMON_DATA_PLACEHOLDER_VALUE'),
                disabled: !!selectedItem
              }}
              fieldComponent={Input}
              defaultValue={initValues.value}
            />
          </Col>
          <Col span={6}>
            <UIField<SelectProps<ReactText>>
              {...formProps}
              name="brand"
              label={t('BRAND')}
              fieldProps={{
                placeholder: t('BRAND'),
                allowClear: true,

                children: typeBrand.map((item, index) => (
                  <Option key={String(index)} value={item.value}>
                    {item.text}
                  </Option>
                ))
              }}
              fieldComponent={Select}
              defaultValue={initValues.brand}
            />
          </Col>
          <Col span={6}>
            <UIField<UISelectProps>
              {...formProps}
              name="metaType"
              label={t('TYPE')}
              fieldProps={{
                placeholder: t('TYPE'),
                allowClear: true,
                dataOptions: {
                  data: metaTypeOptions,
                  valueField: 'value',
                  textField: 'text'
                }
                // children: metaTypeOptions.map((item, index) => (
                //   <Option key={String(index)} value={item.value}>
                //     {item.text}
                //   </Option>
                // ))
              }}
              fieldComponent={UISelect}
              defaultValue={initValues.metaType}
            />
          </Col>
        </Row>
        <Divider
          orientation="left"
          style={{ color: '#333', fontWeight: 'normal', border: 'black' }}
        >
          {t('META_DEVICE')}
        </Divider>

        <Row gutter={8}>
          <Col span={0} style={{ display: 'none' }}>
            <UIField
              {...formProps}
              name="type"
              label={t('TYPE')}
              fieldProps={{
                placeholder: 'model_device',
                readOnly: true
              }}
              fieldComponent={Input}
              defaultValue={initValues.type}
            />
          </Col>
          <Col span={8}>
            <UIField
              {...formProps}
              name="apMac"
              label={t('AP_MAC')}
              fieldProps={{
                placeholder: t('AP_MAC')
              }}
              fieldComponent={Input}
              defaultValue={initValues.apMac}
            />
          </Col>
          <Col span={8}>
            <UIField
              {...formProps}
              name="clientMac"
              label={t('CLIENT_MAC')}
              fieldProps={{
                placeholder: t('CLIENT_MAC')
              }}
              fieldComponent={Input}
              defaultValue={initValues.clientMac}
            />
          </Col>
        </Row>
        <Divider />
        <Row gutter={8}>
          <Col span={6}>
            <UIField
              {...formProps}
              name="loginUrl"
              label={t('LOGIN_URL')}
              fieldProps={{
                placeholder: t('LOGIN_URL')
              }}
              fieldComponent={Input}
              defaultValue={initValues.loginUrl}
            />
          </Col>
          <Col span={6}>
            <UIField
              {...formProps}
              name="loginUrlFormat"
              label={t('LOGIN_URL_FORMAT')}
              fieldProps={{
                placeholder: t('LOGIN_URL_FORMAT')
              }}
              fieldComponent={Input}
              defaultValue={initValues.loginUrlFormat}
            />
          </Col>
          <Col span={6}>
            <UIField
              {...formProps}
              name="redirectUrl"
              label={t('REDIRECT_URL')}
              fieldProps={{
                placeholder: t('REDIRECT_URL')
              }}
              fieldComponent={Input}
              defaultValue={initValues.redirectUrl}
            />
          </Col>
          <Col span={6}>
            <UIField
              {...formProps}
              name="method"
              label={t('METHOD')}
              fieldProps={{
                placeholder: t('CHOOSE_METHOD'),
                children: ['get', 'post'].map((item, index) => (
                  <Select.Option key={String(index)} value={item}>
                    {item.toUpperCase()}
                  </Select.Option>
                ))
              }}
              fieldComponent={Select}
              defaultValue={initValues.method}
            />
          </Col>
        </Row>
        <Divider />
        <Row gutter={8}>
          <Col span={8}>
            <UIField
              {...formProps}
              name="username"
              label={t('USERNAME')}
              fieldProps={{
                placeholder: t('USERNAME')
              }}
              fieldComponent={Input}
              defaultValue={initValues.username}
            />
          </Col>
          <Col span={8}>
            <UIField
              {...formProps}
              name="password"
              label={t('PASSWORD')}
              fieldProps={{
                placeholder: t('PASSWORD')
              }}
              fieldComponent={Input}
              defaultValue={initValues.password}
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
});

export default ModelDeviceForm;
