import '@ant-design/compatible/assets/index.css';

import { Button, Descriptions, Modal } from 'antd';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Form } from '@ant-design/compatible';
import { DATE_FORMAT } from '@source/common';

import { UIDatePicker } from '../../components/UI/UIDatePicker';
import store from './store';

interface Props {
  visible: boolean;
  setModalVisible: () => void;
}
interface FormValues {
  scheduleTo?: Date;
  scheduleFrom?: Date;
}

const ScheduleRender: FC<Props> = observer(({ visible, setModalVisible }) => {
  const { selectedItem, setSchedule } = store;
  const { t } = useTranslation();
  const [value, setValue] = useState(get(selectedItem, 'scheduleTo'));

  const disabledDate = current => {
    return current && current < moment().startOf('day');
  };

  const footer = [
    <Button key="close" onClick={() => setModalVisible()}>
      {t('CLOSE')}
    </Button>,
    <Button
      key="stop"
      type="primary"
      onClick={() =>
        setSchedule(selectedItem._id, {
          scheduleFrom: get(selectedItem, 'scheduleFrom'),
          scheduleTo: value
        })
      }
    >
      {t('APPLY')}
    </Button>
  ];

  return (
    <Modal title={t('SCHEDULE_CAMPAIGN')} footer={footer} visible={visible}>
      <Form layout="vertical">
        <Descriptions bordered size="small" column={3}>
          <Descriptions.Item label={t('NAME')} span={3}>
            {get(selectedItem, 'name', '')}
          </Descriptions.Item>
        </Descriptions>
        <Form.Item label={t('SCHEDULE_FROM')} labelAlign="left" style={{ marginBottom: 8 }}>
          <UIDatePicker
            style={{ width: '100%' }}
            placeholder={t('SCHEDULE_FROM')}
            allowClear={false}
            value={get(selectedItem, 'scheduleFrom')}
            disabled={!!selectedItem}
            format={DATE_FORMAT}
          />
        </Form.Item>
        <Form.Item label={t('SCHEDULE_TO')} labelAlign="left" style={{ marginBottom: 8 }}>
          <UIDatePicker
            style={{ width: '100%' }}
            placeholder={t('SCHEDULE_TO')}
            allowClear={false}
            value={value}
            onChange={setValue}
            disabledDate={disabledDate}
            format={DATE_FORMAT}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
});

export default ScheduleRender;
