import balance from './balance';
import common from './common';
import login from './login';
import message from './message';
import oldTranslation from './old-translation';
import translation from './translation';
import equipment from './equipment';
import notify from './notify';

const result = Object.assign(
  oldTranslation,
  translation,
  common,
  message,
  balance,
  equipment,
  notify
);

export default {
  login,
  translation: {
    ...result
  }
};
