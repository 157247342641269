import { Avatar, Col, Descriptions, Row, Select } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';

import { UserOutlined } from '@ant-design/icons';
import { AUTHORIZED_ROLES_ASSIGN_OWNER_LOCATION, ROLE_ASSIGN_LOCATION_OWNER } from '@source/common';

import AssignOwner from '../AssignOwner';
import { ModelName } from '../store';

const { Option } = Select;

const AssignLocationScreen = observer(() => {
  const renderOptions = (data) => {
    return data.map((item) => {
      const { user } = item;
      return (
        <Option key={user} value={item._id} label={user}>
          <div style={{ border: '1px solid #d9d9d9', borderRadius: 4, padding: 5 }}>
            <Row>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Col span={6} style={{ textAlign: 'center' }}>
                  <Avatar size={64} icon={<UserOutlined />} />
                </Col>
                <Col span={18} style={{ paddingTop: 8 }}>
                  <Descriptions column={2}>
                    <Descriptions.Item label="Name">{item.user}</Descriptions.Item>
                    <Descriptions.Item label="Company">{item.nameCompany}</Descriptions.Item>
                    <Descriptions.Item label="Email">{item.email}</Descriptions.Item>
                    <Descriptions.Item label="Phone">{item.phone}</Descriptions.Item>
                  </Descriptions>
                </Col>
              </div>
            </Row>
          </div>
        </Option>
      );
    });
  };

  return (
    <AssignOwner
      pageHeader="Assign Location"
      collectionName={ModelName.Location}
      renderOptions={renderOptions}
      assignRoles={[ROLE_ASSIGN_LOCATION_OWNER]}
    />
  );
});

export default AssignLocationScreen;
