import { observer } from 'mobx-react';
import React, { FC } from 'react';

import Body from './Body';
import Header from './Header';
import store from './store';

const LocationsPriorityScreen: FC = observer(() => {
  const { fetchCampaign, resetFields } = store;
  React.useEffect(() => {
    let fetch = true;
    if (fetch) {
      fetchCampaign();
    }
    return () => {
      fetch = false;
      resetFields();
    };
  }, []);
  return (
    <>
      <Header />
      <Body />
    </>
  );
});

export default LocationsPriorityScreen;
