/* eslint-disable react/prefer-stateless-function */
import { InputNumber } from 'antd';
import { InputNumberProps } from 'antd/lib/input-number';
import React, { Component, ReactText } from 'react';

const formatter = (value: ReactText) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const parser = (value: string) => {
  const v = value.replace(/[^\d]|(,*)/g, '');
  return v ? parseInt(v, 10) : undefined;
};

export class UIInputNumber extends Component<InputNumberProps & { inputRef?: any }> {
  render() {
    const { inputRef, style = {} } = this.props;

    return (
      <InputNumber
        {...this.props}
        ref={inputRef}
        formatter={formatter}
        // parser={parser}
        style={{ width: '100%', ...style }}
      />
    );
  }
}
