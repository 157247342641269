import './BannerConfig.less';

import { Button, Col, Result, Row } from 'antd';
import { get } from 'lodash';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { PlusOutlined } from '@ant-design/icons';

import SortableComponent from '~components/UI/SortableComponent';

import store, { BannerConfigInterface } from '../store';
import BannerConfigElement from './BannerConfigElement';

const BannerConfig = observer(() => {
  const { t } = useTranslation();
  const {
    addBanner,
    removeBanner,
    updateTitleBanner,
    updateQueryTreeBanner,
    updateQueryBanner,
    sortArray,
    listBanner,
    genFields,
    customFields
  } = store;

  const fields = genFields(toJS(customFields));

  const handleAddBanner = () => {
    const newBanner: BannerConfigInterface = {
      bannerId: '',
      bannerName: '',
      query: null,
      queryTree: null
    };
    addBanner(newBanner);
  };

  return (
    <div style={{ padding: '16px 24px 16px 24px' }}>
      <Row>
        <Col
          sm={24}
          md={20}
          style={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap' }}
        >
          {listBanner && listBanner.length > 0 ? (
            <SortableComponent
              itemElement={BannerConfigElement}
              items={listBanner}
              sortListFunction={sortArray}
              elementProps={{
                // eslint-disable-next-line object-shorthand
                fields: fields,
                // eslint-disable-next-line object-shorthand
                removeBanner: removeBanner,
                // eslint-disable-next-line object-shorthand
                updateTitleBanner: updateTitleBanner,
                updateQueryTree: updateQueryTreeBanner,
                updateQuery: updateQueryBanner
              }}
            />
          ) : (
            <Result
              title={t('THERE_IS_NO_BANNER')}
              extra={
                <Button onClick={() => handleAddBanner()} type="primary" icon={<PlusOutlined />}>
                  {t('ADD_BANNER')}
                </Button>
              }
            />
          )}
        </Col>
        <Col sm={24} md={4}>
          <Button
            onClick={() => handleAddBanner()}
            style={{ float: 'right', display: listBanner && listBanner.length > 0 ? '' : 'none' }}
            type="primary"
            icon={<PlusOutlined />}
          >
            {t('ADD_BANNER')}
          </Button>
        </Col>
      </Row>
    </div>
  );
});

export default BannerConfig;
