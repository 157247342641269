import { Button, Descriptions, Modal, Tag, Typography } from 'antd';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ReactResizeDetector from 'react-resize-detector';

import { ColDef } from '@ag-grid-community/core';
import {
  CheckCircleOutlined,
  CloseOutlined,
  ExclamationCircleOutlined,
  InfoOutlined
} from '@ant-design/icons';
import {
  BalanceType,
  format,
  PENDING,
  REJECTED,
  ROLE_DELETE_PAYMENT,
  VERIFIED,
  WhereArgs
} from '@source/common';

import Authorized from '~components/Authorized/Authorized';
import AgGridServer from '~components/UI/AgGrid/AgGridServer';
import { useGraphQLSDK } from '~graphql/GraphQLSDKProvider';
import { formatNumber } from '~utils/formatNumber';
import { upperCaseFirstLetter } from '~utils/upperCaseFirstLetter';

import store from './store';

const { Text } = Typography;

const { confirm } = Modal;

const PaymentGrid: FC = observer(() => {
  const sdk = useGraphQLSDK();

  const { t } = useTranslation();
  const {
    handleGridReady,
    where,
    verifyPayment,
    setIsRejectDialogVisible,
    setPaymentInfoVisible
  } = store;

  const getVerifyContent = data => {
    return (
      <>
        <Descriptions column={2}>
          <Descriptions.Item span={1} label="Amount">
            {formatNumber(data?.amount)}
          </Descriptions.Item>
          <Descriptions.Item span={1} label="By">
            {data?.userPayment.name}
          </Descriptions.Item>
          <Descriptions.Item span={2} label="Email">
            {data?.userPayment.email}
          </Descriptions.Item>
          <Descriptions.Item span={2} label="Phone">
            {data?.userPayment.phone}
          </Descriptions.Item>
        </Descriptions>
        <Text>When the payment is verified, it will be added to owner</Text>
      </>
    );
  };

  const getAccountType = type => {
    let content;

    switch (type) {
      case BalanceType.PRIMARY:
        content = <Tag color="geekblue">{upperCaseFirstLetter(type)}</Tag>;
        break;
      case BalanceType.SECONDARY:
        content = <Tag color="gold">{upperCaseFirstLetter(type)}</Tag>;
        break;
      default:
        content = null;
    }

    return content;
  };
  const showVerifyConfirm = data => {
    confirm({
      title: <div>{t('VEFIRY_PAYMENT')}</div>,
      icon: <ExclamationCircleOutlined />,
      content: getVerifyContent(data),
      okText: t('VERIFY'),
      cancelText: t('CANCEL'),
      onOk() {
        verifyPayment(data._id);
      }
    });
  };

  const columnDefs: ColDef[] = [
    {
      headerName: '',
      field: '_id',
      minWidth: 80,
      maxWidth: 80,
      sortable: false,
      cellClass: 'text-center',
      cellRendererFramework: ({ data }) => {
        return (
          <Button.Group
            size="small"
            style={{ marginTop: '10px', display: data.status === PENDING ? 'inline' : 'none' }}
          >
            <Authorized roles={[ROLE_DELETE_PAYMENT]}>
              <Button
                type="primary"
                onClick={() => showVerifyConfirm(data)}
                style={{ backgroundColor: '#52c41a' }}
                icon={<CheckCircleOutlined />}
              ></Button>
            </Authorized>
            <Authorized roles={[ROLE_DELETE_PAYMENT]}>
              <Button
                type="primary"
                danger
                onClick={() => setIsRejectDialogVisible(true, data)}
                icon={<CloseOutlined />}
              ></Button>
            </Authorized>
          </Button.Group>
        );
      }
    },
    {
      headerName: t('NO'),
      field: '_id',
      minWidth: 50,
      maxWidth: 50,
      cellClass: 'text-center',
      sortable: false,
      cellRenderer: ({ rowIndex }) => rowIndex + 1
    },
    {
      headerName: t('STATUS'),
      field: 'status',
      minWidth: 100,
      maxWidth: 100,
      cellRendererFramework: ({ value }) => {
        let color = 'default';
        switch (value) {
          case VERIFIED:
            color = 'success';
            break;
          case REJECTED:
            color = 'error';
            break;
          default:
            break;
        }
        return (
          <>
            <Tag color={color}>{value.toUpperCase()}</Tag>
          </>
        );
      }
    },
    {
      headerName: t('USER'),
      field: 'userPayment.name',
      minWidth: 250,
      sortable: false,
      cellRendererFramework: ({ value, data }) => {
        return <a onClick={() => setPaymentInfoVisible(true, data)}>{value}</a>;
      }
    },
    {
      headerName: `${t('AMOUNT')} (VND)`,
      field: 'amount',
      minWidth: 150,
      maxWidth: 150,
      cellClass: 'text-right',
      cellRenderer: ({ value }) => formatNumber(value || 0)
    },
    {
      headerName: `${t('TYPE')}`,
      field: 'type',
      minWidth: 100,
      maxWidth: 100,
      cellRendererFramework: ({ value }) => getAccountType(value)
    },
    {
      headerName: t('CREATED'),
      field: 'createdAt',
      minWidth: 200,
      sortable: true,
      sort: 'desc',
      cellRenderer: ({ value }) => format(value)
    }
  ];

  return (
    <ReactResizeDetector handleHeight>
      {({ height }) => {
        return (
          <AgGridServer
            options={{
              columnDefs,
              cacheBlockSize: 50,
              paginationPageSize: 50,
              getRowHeight: () => 50
            }}
            onQuery={sdk.findManyPayment}
            queryKey="findManyPayment"
            onCountQuery={sdk.countPayment}
            countQueryKey="countPayment"
            onGridReady={handleGridReady}
            where={where as WhereArgs}
            height={height}
          />
        );
      }}
    </ReactResizeDetector>
  );
});

export default PaymentGrid;
