import '@ant-design/compatible/assets/index.css';

import { Col, Input, Modal, Row, Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import i18next from 'i18next';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { FC, ReactText } from 'react';
import useForm from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { Form } from '@ant-design/compatible';

import { commonDataStore } from '~common-stores/store-index';
import UIField from '~components/UI/UIField';
import { CommonInput, CommonUpdateArg } from '~graphql/_sdk';

import store from './store';

interface FormValues {
  value?: string;
  text?: string;
  type?: string;
  icon?: string;
  group?: string;
}
const isValidJSON = (string) => {
  try {
    JSON.parse(string);
  } catch (e) {
    return false;
  }
  return true;
};

yup.addMethod(yup.string, 'JSON', function (message: string) {
  return this.test('validate-JSON', message, (value) => isValidJSON(value));
});

const validationSchema = yup.object().shape<FormValues>({
  text: yup.string().required(i18next.t('COMMON_DATA_VALIDATE_TEXT_REQUIRE')),
  value: yup.string().required(i18next.t('COMMON_DATA_VALIDATE_VALUE_REQUIRE')),
  type: yup.string().required(i18next.t('COMMON_DATA_VALIDATE_TYPE_REQUIRE')),
  icon: yup.string(),
  group: yup.string().required('COMMON_DATA_VALIDATE_GROUP_REQUIRE')
});

const LocationCategoryForm: FC = observer(() => {
  const { t } = useTranslation();
  const { modalVisible, setModalVisible, create, update, selectedItem } = store;

  const initValues: FormValues = {
    text: get(selectedItem, 'text', ''),
    value: get(selectedItem, 'value', ''),
    type: get(selectedItem, 'type', 'location_category'),
    icon: get(selectedItem, 'icon', ''),
    group: get(selectedItem, 'meta.group')
  };

  const formProps = useForm({ validationSchema });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting }
  } = formProps;

  const onCancel = () => {
    reset(initValues);
    setModalVisible();
  };

  const onSubmit = async (record: FormValues) => {
    const { text, value, type, icon, group } = record;
    const records = {
      text,
      value,
      icon,
      type,
      meta: {
        group
      }
    };

    if (selectedItem) {
      await update(get(selectedItem, '_id'), records as CommonUpdateArg);
    } else {
      await create(records as CommonInput);
    }
    onCancel();
  };

  return (
    <Modal
      title={t('Location Category')}
      visible={modalVisible}
      cancelText="Close"
      cancelButtonProps={{ danger: true, ghost: true }}
      onCancel={() => onCancel()}
      okText={selectedItem ? 'Update' : 'Create'}
      okButtonProps={{
        htmlType: 'submit',
        form: 'LocationCategoryForm',
        type: 'primary',
        loading: isSubmitting
      }}
    >
      <Form id="LocationCategoryForm" onSubmit={handleSubmit(onSubmit)} layout="vertical">
        <Row gutter={8}>
          <Col xs={24} md={12}>
            <UIField
              {...formProps}
              name="text"
              label={t('TEXT')}
              fieldProps={{
                placeholder: 'COMMON_DATA_PLACEHOLDER_TEXT'
              }}
              fieldComponent={Input}
              defaultValue={initValues.text}
            />
          </Col>
          <Col xs={24} md={12}>
            <UIField
              {...formProps}
              name="value"
              label={t('VALUE')}
              fieldProps={{
                placeholder: t('COMMON_DATA_PLACEHOLDER_VALUE'),
                disabled: !!selectedItem
              }}
              fieldComponent={Input}
              defaultValue={initValues.value}
            />
          </Col>
        </Row>
        <Row gutter={8}>
          <Col xs={24} md={12}>
            <UIField
              {...formProps}
              name="type"
              label={t('TYPE')}
              fieldProps={{
                placeholder: t('COMMON_DATA_PLACEHOLDER_TYPE'),
                readOnly: true
              }}
              fieldComponent={Input}
              defaultValue={initValues.type}
            />
          </Col>
          <Col xs={24} md={12}>
            <UIField
              {...formProps}
              name="icon"
              label="Icon"
              fieldProps={{
                placeholder: t('COMMON_DATA_PLACEHOLDER_ICON')
              }}
              fieldComponent={Input}
              defaultValue={initValues.icon}
            />
          </Col>
        </Row>
        <UIField<SelectProps<ReactText>>
          {...formProps}
          name="group"
          label={t('GROUP')}
          fieldProps={{
            placeholder: t('CHOOSE_GROUP'),
            children: commonDataStore.groupType.map((item, index) => (
              <Select.Option key={String(index)} value={item.value}>
                {item.text}
              </Select.Option>
            ))
          }}
          fieldComponent={Select}
          defaultValue={initValues.group}
        />
      </Form>
    </Modal>
  );
});

export default LocationCategoryForm;
