import '@ant-design/compatible/assets/index.css';

import { Badge, Button, Card, Checkbox, Col, Collapse, Input, InputNumber, Row, Tag } from 'antd';
import { get, isEmpty, sumBy, set } from 'lodash';
import { observer } from 'mobx-react';
import moment, { Moment } from 'moment';
import React, { useEffect, useRef } from 'react';

import { Form } from '@ant-design/compatible';
import { CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';
import { DATE_FORMAT, mapReduce } from '@source/common';

import { flexAlign, flexCenter } from '~assets/css-classes';
import Loading from '~components/UI/Loading';
import { useStore } from '~context/react-context';
import { formatNumber } from '~utils/formatNumber';

import { UIDatePicker } from '../../components/UI/UIDatePicker';
import store from './store';
import ReactResizeDetector from 'react-resize-detector';

const { Item } = Form;
const { Group: InputGroup } = Input;
const { Panel } = Collapse;

const KPIForm = observer(() => {
  const {
    autoKpi,
    setTotalKPI,
    totalBooking,
    setDateRange,
    setKPILocation,
    setCheckedList,
    locationCheckedList,
    getAvailableBooking,
    availableKpiLoading,
    groupCheckedLocation,
    bookingRangeDate: { from, to },
    statusBtn,
    visibleBtn
  } = store;
  const refInput = useRef([]);

  useEffect(() => {
    locationCheckedList.map((i) => i.equipments.map((ap) => set(ap, 'checked', true)));
    if (!isEmpty(to)) {
      const locationIds = locationCheckedList.map((l) => l._id);
      store.getAvailableBooking(locationIds, to);
    }
  }, []);

  const { commonDataStore } = useStore();

  const categoryMap = mapReduce(commonDataStore.categories, 'value', (data) => ({
    text: data.text,
    group: data.meta.group
  }));

  const groupMap = mapReduce(get(commonDataStore, 'groupType', []), 'value', (data) => ({
    text: data.text,
    price: get(data, 'meta.price', 0)
  }));

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const autoKpiCategory = async (e, name) => {
    autoKpi(name);
    stopPropagation(e);
  };

  const noEndAutoKpi = async (e) => {
    store.noEndAutoKpi();
    stopPropagation(e);
  };

  const handleKPILocation = async (e, _id, name) => {
    setKPILocation(e, _id, name);
    // store.setTotalKPI(e, name);
    store.setVisibleBtn();
  };

  const setKPI = async (value, cate) => {
    setTotalKPI(value, cate);
    // store.setTotalBooking(value)
  };
  const disabledDateFrom = (current: Moment) => {
    return current && current < moment().subtract(1, 'days').endOf('day');
  };

  const disabledDateTo = (current: Moment) => {
    return current && current < moment(from).subtract(1, 'days').endOf('day');
  };

  const handlePickDate = async (date: Moment, type: string) => {
    const locationIds = locationCheckedList.map((l) => l._id);
    setDateRange(date, type);
    if (date && type === 'to') {
      await getAvailableBooking(locationIds, date);
    }
  };

  const onRemoveLocation = (loc) => {
    setCheckedList(locationCheckedList.filter((i) => i.name !== loc));
  };

  const checkAccessPointByLocation = (e, ap, locationId) => {
    const index = locationCheckedList.findIndex((i) => i._id === locationId);
    if (index < 0) {
      return;
    }
    const indexAp = locationCheckedList[index].equipments.findIndex((i) => i._id === ap._id);
    if (indexAp < 0) {
      return;
    }
    if (e.target.checked) {
      set(locationCheckedList[index].equipments[indexAp], 'checked', true);
    }
    if (!e.target.checked) {
      set(locationCheckedList[index].equipments[indexAp], 'checked', false);
    }
  };

  const renderedLocations = () => {
    return Object.keys(groupCheckedLocation).map((name, idx) => {
      const category = categoryMap[name];
      const { group } = category;

      const groupText = get(groupMap[group], 'text') ? `${groupMap[group].text}` : '';
      const groupPrice = get(groupMap[group], 'price') ? ` - ${groupMap[group].price}` : '';
      const categoryText = groupText ? `${category.text}` : category.text;

      let result;
      const apOfLocation = (location) => {
        return location.equipments?.length ? (
          <Collapse defaultActiveKey={['ap']} style={{ marginTop: '15px' }}>
            <Panel header={`Select AP: ${location.equipments?.length}`} key="ap">
              <Row>
                {location.equipments.map((ap, indexAp) => {
                  return (
                    <Col span={12} key={indexAp.toString()}>
                      <Checkbox
                        defaultChecked
                        key={ap.mac}
                        onChange={(e) => checkAccessPointByLocation(e, ap, location._id)}
                      >
                        {ap.mac}
                      </Checkbox>
                    </Col>
                  );
                })}
              </Row>
            </Panel>
          </Collapse>
        ) : (
          <></>
        );
      };
      if (to) {
        result = (
          <Collapse
            expandIconPosition="right"
            bordered={false}
            defaultActiveKey={['1']}
            key={idx.toString()}
            style={{ backgroundColor: 'darkslategray' }}
          >
            <Panel
              showArrow={false}
              header={
                <div className={flexAlign}>
                  <h2 className={flexAlign} key={String(idx)} style={{ margin: '0 8px 0 16px' }}>
                    <Badge
                      count={groupCheckedLocation[name].length}
                      overflowCount={9999}
                      style={{ backgroundColor: '#52c41a', marginRight: 8, zIndex: 0 }}
                    />
                    <span style={{ color: 'honeydew', fontWeight: 'bold' }}>{categoryText}</span>
                    {groupText && (
                      <Tag color="blue" style={{ marginLeft: 8 }}>
                        {groupText}
                        {groupPrice}
                      </Tag>
                    )}
                  </h2>
                  <InputNumber
                    placeholder="Total KPI"
                    style={{ width: '15%', borderRadius: 0 }}
                    defaultValue={0}
                    max={sumBy(groupCheckedLocation[name], (item: any) =>
                      item.availableTotalKpi < 0 ? 0 : item.availableTotalKpi
                    )}
                    // value={sum(get(bookingCategory, name, 0))}
                    value={sumBy(
                      locationCheckedList.filter((i) => i.categoryId === name),
                      'kpi'
                    )}
                    onChange={(e) => setKPI(e, name)}
                    onClick={stopPropagation}
                    formatter={formatNumber}
                  />

                  <div
                    className="ant-input-group-addon"
                    style={{
                      paddingTop: '2px',
                      verticalAlign: 'middle',
                      display: 'inline-table',
                      lineHeight: '28px',
                      height: '32px'
                    }}
                    onClick={stopPropagation}
                  >
                    {!availableKpiLoading && (
                      <span>
                        Available:
                        {formatNumber(
                          sumBy(groupCheckedLocation[name], (item: any) =>
                            item.availableTotalKpi < 0 ? 0 : item.availableTotalKpi
                          )
                        )}
                      </span>
                    )}
                  </div>

                  <Button
                    type="primary"
                    icon={<CheckOutlined />}
                    style={{ margin: '0 8px' }}
                    onClick={(e) => autoKpiCategory(e, name)}
                  >
                    Auto KPI
                  </Button>
                </div>
              }
              key="1"
            >
              <Row style={{ padding: 16, backgroundColor: 'white' }} gutter={[16, 16]}>
                {groupCheckedLocation[name].map((location, index) => {
                  return (
                    <Col span={12} key={String(index)}>
                      <Item>
                        <Card
                          title={<span style={{ fontWeight: 600 }}>{location.name}</span>}
                          style={{
                            // maxHeight: 115,
                            border: '1px solid'
                            // border: showError ? '1px solid red' : '1px solid #e8e8e8'
                          }}
                          size="small"
                          extra={
                            <CloseOutlined
                              style={{ color: 'gray' }}
                              onClick={() => onRemoveLocation(get(location, 'name', ''))}
                            />
                          }
                        >
                          <Row
                            className={flexAlign}
                            style={{
                              justifyContent: 'space-between'
                            }}
                          >
                            <Col span={16} className={flexAlign}>
                              <p style={{ margin: 0 }}>
                                Available: {formatNumber(get(location, 'availableTotalKpi', 0))}
                              </p>
                            </Col>

                            <Col span={8}>
                              <InputGroup compact>
                                <InputNumber
                                  placeholder="KPI"
                                  style={{ width: '100%', borderColor: '#e8e8e8' }}
                                  defaultValue={0}
                                  min={0}
                                  max={get(location, 'availableTotalKpi', 0)}
                                  formatter={formatNumber}
                                  value={
                                    +get(location, 'kpi', 0) < 0 ? 0 : +get(location, 'kpi', 0)
                                  }
                                  onChange={(e) => handleKPILocation(e, location._id, name)}
                                />
                              </InputGroup>
                            </Col>
                            {apOfLocation(location)}
                          </Row>
                        </Card>
                      </Item>
                    </Col>
                  );
                })}
              </Row>
            </Panel>
          </Collapse>
        );
      } else {
        result = (
          <Collapse
            style={{ backgroundColor: 'darkslategray' }}
            // expandIconPosition="right"
            bordered={false}
            defaultActiveKey={['group1']}
            key={`group${idx.toString()}`}
          >
            <Panel
              key={groupText}
              showArrow={false}
              header={
                <div className={flexAlign}>
                  <h2
                    className={flexAlign}
                    key={`group${idx.toString()}`}
                    style={{ margin: '0 8px 0 16px' }}
                  >
                    <Badge
                      count={groupCheckedLocation[name].length}
                      overflowCount={9999}
                      style={{ backgroundColor: '#52c41a', marginRight: 8, zIndex: 0 }}
                    />
                    <span style={{ color: 'honeydew', fontWeight: 'bold' }}>{categoryText}</span>
                    {groupText && (
                      <Tag color="blue" style={{ marginLeft: 8 }}>
                        {groupText}
                      </Tag>
                    )}
                  </h2>
                </div>
              }
            >
              <Row style={{ padding: 16, backgroundColor: 'white' }} gutter={[16, 16]}>
                {groupCheckedLocation[name].map((location, index) => {
                  return (
                    <Col span={12} key={String(index)}>
                      <Item>
                        <Card
                          title={<span style={{ fontWeight: 600 }}>{location.name}</span>}
                          style={{
                            // maxHeight: 115,
                            border: '1px solid'
                          }}
                          size="small"
                          extra={
                            <CloseOutlined
                              style={{ color: 'gray' }}
                              onClick={() => {
                                onRemoveLocation(get(location, 'name', ''));
                              }}
                            />
                          }
                        >
                          <Row className={flexAlign} style={{ display: 'contents' }}>
                            <span style={{ marginLeft: 12 }}>KPI: </span>
                            <Tag style={{ marginLeft: 8 }} color="slategrey">
                              <InputNumber
                                className="inp"
                                // eslint-disable-next-line no-return-assign
                                ref={(e) => (refInput.current[location._id] = e)}
                                placeholder="KPI"
                                style={{
                                  border: 'none',
                                  borderRadius: 'unset',
                                  backgroundColor: 'transparent',
                                  color: 'white'
                                }}
                                defaultValue={0}
                                onChange={(e) => store.setValueKpi(e)}
                                onClick={(e) => {
                                  stopPropagation(e);
                                }}
                                disabled={
                                  totalBooking > 0 ? statusBtn !== location._id || visibleBtn : true
                                }
                                formatter={formatNumber}
                                value={+get(location, 'kpi', 0) < 0 ? 0 : +get(location, 'kpi', 0)}
                              />
                            </Tag>
                            {!visibleBtn && statusBtn === location._id && (
                              <>
                                <Button
                                  style={{ top: 1.4, float: 'right' }}
                                  icon={<CheckOutlined />}
                                  onClick={() => {
                                    handleKPILocation(store.value, location._id, name);
                                  }}
                                  type="primary"
                                />
                                <Button
                                  style={{ top: 1.4, float: 'right', marginRight: 8 }}
                                  icon={<CloseOutlined />}
                                  onClick={() => store.setVisibleBtn()}
                                  danger
                                />
                              </>
                            )}
                            {((totalBooking > 0 && visibleBtn) || statusBtn !== location._id) && (
                              <Button
                                disabled={store.disabled}
                                style={{ top: 1.4, float: 'right' }}
                                icon={<EditOutlined />}
                                onClick={() => {
                                  store.changeStatusBtn(
                                    location._id,
                                    location.kpi,
                                    refInput.current[location._id]
                                  );
                                }}
                                type="primary"
                              />
                            )}
                            {apOfLocation(location)}
                          </Row>
                        </Card>
                      </Item>
                    </Col>
                  );
                })}
              </Row>
            </Panel>
          </Collapse>
        );
      }
      return result;
    });
  };
  return (
    <>
      <Row style={{ padding: '16px 16px' }} gutter={[16, 16]}>
        <Col span={8}>
          <Form name="basic" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} autoComplete="off">
            <Item label="Start Date" labelAlign="left" style={{ marginBottom: 8 }}>
              <UIDatePicker
                style={{ width: '100%' }}
                placeholder="Start Date"
                onChange={(date) => {
                  handlePickDate(date, 'from');
                  setDateRange(undefined, 'to');
                }}
                allowClear={false}
                value={from}
                disabledDate={disabledDateFrom}
                format={DATE_FORMAT}
              />
            </Item>
            <Item label="Pick End Date" labelAlign="left" style={{ marginBottom: 8 }}>
              <UIDatePicker
                style={{ width: '100%' }}
                placeholder="End Date"
                onChange={(date) => {
                  store.resetField();
                  handlePickDate(date, 'to');
                }}
                value={to}
                allowClear
                disabledDate={disabledDateTo}
                format={DATE_FORMAT}
              />
            </Item>
            <Item label="Total Booking" labelAlign="left" style={{ marginBottom: 8 }}>
              <InputNumber
                disabled={!!to}
                style={{ width: '100%' }}
                value={totalBooking || 0}
                onChange={(e) => {
                  store.setTotalBooking(e);
                }}
                formatter={formatNumber}
              />
            </Item>
            {!to && (
              <Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button
                  disabled={totalBooking <= 0 || !totalBooking}
                  type="primary"
                  icon={<CheckOutlined />}
                  style={{ marginTop: 5 }}
                  onClick={(e) => {
                    store.setDisabled();
                    noEndAutoKpi(e);
                  }}
                >
                  Auto KPI
                </Button>
              </Item>
            )}
          </Form>
        </Col>
        <Col span={16}>
          <ReactResizeDetector handleHeight>
            {({ height }) => (
              <div style={{ maxHeight: height + 100, overflow:'auto' }}>
                {!availableKpiLoading ? (
                  renderedLocations()
                ) : (
                  <div className={flexCenter} style={{ height: 'calc(100% - 120px)' }}>
                    <Loading />
                  </div>
                )}
              </div>
            )}
          </ReactResizeDetector>
        </Col>
      </Row>
    </>
  );
});

export default KPIForm;
