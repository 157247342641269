import { Col, Row } from 'antd';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { ROLE_READ_ALL, ROLE_SUPERADMIN } from '@source/common';

import Authorized from '~components/Authorized/Authorized';
import { UISelect } from '~components/UI/Select/UISelect';
import { UIDateRangePicker } from '~components/UI/UIDateRangePicker';

import store from './store';

const Filter: FC = observer(() => {
  const {
    customers,
    loadingCustomer,
    handleFilterOnChange,
    dateFilter,
  } = store;

  return (
    <Row
      justify="end"
      gutter={8}
      style={{
        backgroundColor: '#fff',
        margin: 0,
        padding: '8px 5px 12px 5px'
      }}
    >
      <Authorized roles={[ROLE_READ_ALL, ROLE_SUPERADMIN]}>
        <Col>
          <UISelect
            size="middle"
            dropdownStyle={{ minWidth: 200 }}
            loading={loadingCustomer}
            disabled={loadingCustomer}
            labelInValue
            dataOptions={{
              data: customers,
              valueField: '_id',
              textField: 'name'
            }}
            onChange={e => handleFilterOnChange('customer', e.value)}
            placeholder="Choose Customer"
            key="chooseCustomer"
            style={{ width: '100%', fontSize: 13, minWidth: 200 }}
            showSearch
            showArrow
            // allowClear={true}
          />
        </Col>
      </Authorized>
      <Col style={{ minWidth: 120 }}>
        <UIDateRangePicker
          size="middle"
          allowClear
          style={{ width: '100%', fontSize: 13 }}
          onChange={e => handleFilterOnChange('date', e)}
          value={[dateFilter.gte, dateFilter.lte]}
        />
      </Col>
    </Row>
  );
});

export default Filter;
