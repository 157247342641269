const translations = {
  common: {
    LANGUAGE: 'Ngôn ngữ',
    SELECT_LANGUAGE: 'Chọn Ngôn ngữ',
    EN: 'English',
    VN: 'Tiếng Việt',
    CREATE: 'Tạo',
    EDIT: 'Chỉnh sửa',
    UPDATE: 'Cập nhật',
    DELETE: 'Xoá',
    SEARCH: 'Tìm kiếm...',
    SAVE: 'Lưu',
    CLOSE: 'Đóng',
    OK: 'Đồng ý',
    CANCEL: 'Huỷ',
    KO: 'Không',
    DONE: 'Xong',
    CREATED: 'Tạo mới',
    UPDATED: 'Cập nhật',
    REMOVE: 'Xoá',
    SUCCESSFULL: 'thành công',
    FAILED: 'thất bại',
    NO: 'No.',
    NAME: 'Tên',
    BACK: 'Quay lại',
    NEXT: 'Tiếp tục',
    CONGRATULATION: 'Chúc mừng!',
    PREVIEW: 'Xem trước',
    TOTAL: 'Tổng',
    NO_DATA: 'Không có dữ liệu',
    BRAND: 'Hãng',
    MODEL: 'Model',
    CHOOSE_MODEL: 'Chọn Model',

    // Name
    BANNER_NAME: 'Tên'
  }
};

export default translations;
