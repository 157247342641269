import { Button, PageHeader, Steps } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import store from './store';

const { Step } = Steps;

const CampaignBookingHeader = observer(({ steps }) => {
  const { t } = useTranslation();
  const { setCurrentSteps, step, filteredCheckedLocation, isAllLocationBooked } = store;

  const ExtraSteps = () => (
    <div
      key={1}
      className="steps-action"
      style={{
        display: 'flex'
      }}
    >
      {step < 3 && (
        <Button
          style={{ marginLeft: 8, display: step > 0 ? 'inline-block' : 'none' }}
          onClick={() => setCurrentSteps(step - 1)}
        >
          <LeftOutlined />
          {t('PREVIOUS')}
        </Button>
      )}

      {step === steps.length - 2 && (
        <Button htmlType="submit" style={{ marginLeft: 8 }} type="primary">
          {t('FINISH')}
        </Button>
      )}

      {step < steps.length - 2 && (
        <Button
          disabled={
            step === 2
              ? !isAllLocationBooked
              : filteredCheckedLocation.length === 0
          }
          style={{ marginLeft: 8 }}
          type="primary"
          onClick={() => setCurrentSteps(step + 1)}
        >
          {t('NEXT')}
          <RightOutlined />
        </Button>
      )}
    </div>
  );

  // eslint-disable-next-line no-shadow
  const Title = ({ steps }) => {
    return (
      <Steps current={step} onChange={current => setCurrentSteps(current)} style={{ width: 700 }}>
        {steps.map(({ title, disabled }) => (
          <Step key={title} title={title} disabled={disabled} />
        ))}
      </Steps>
    );
  };

  return (
    <PageHeader
      style={{ marginBottom: 16, background: '#fff' }}
      title={<Title steps={steps} />}
      extra={[<ExtraSteps key="extra" />]}
    />
  );
});

export default CampaignBookingHeader;
