import { Menu } from 'antd';
import React, { FC, ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { intersection } from 'lodash';

import {
  BarChartOutlined,
  CloudOutlined,
  DashboardOutlined,
  DollarCircleOutlined,
  PartitionOutlined,
  PushpinOutlined,
  SettingOutlined,
  WifiOutlined
} from '@ant-design/icons';
import { groupMenuId, ROLE_MENU_ALL } from '@source/common';

import { useStore } from '~context/react-context';
import { MenuItem, MenuItems, routes } from '~screens/routes';

export interface GroupMenu {
  name: string;
  groupId: string;
  icon: ReactElement;
}

const defaultGroup: GroupMenu[] = [
  {
    name: 'DASHBOARD',
    groupId: groupMenuId.dashboard,
    icon: <DashboardOutlined />
  },
  {
    name: 'INVENTORY',
    groupId: groupMenuId.inventory,
    icon: <CloudOutlined />
  },
  {
    name: 'HOTSPOT',
    groupId: groupMenuId.hotspot,
    icon: <WifiOutlined />
  },
  {
    name: 'CAMPAIGN_BASIC',
    groupId: groupMenuId.campaignBasic,
    icon: <PushpinOutlined />
  },
  {
    name: 'CAMPAIGN',
    groupId: groupMenuId.campaign,
    icon: <PushpinOutlined />
  },
  {
    name: 'REPORT',
    groupId: groupMenuId.report,
    icon: <BarChartOutlined />
  },
  {
    name: 'INCOME',
    groupId: groupMenuId.income,
    icon: <DollarCircleOutlined />
  },
  {
    name: 'PERMISSION',
    groupId: groupMenuId.permission,
    icon: <PartitionOutlined />
  },
  {
    name: 'SYSTEM',
    groupId: groupMenuId.system,
    icon: <SettingOutlined />
  }
];

const { Item, SubMenu } = Menu;

const hasMenu = (menuRoleIds: string[], route: MenuItem | MenuItems): boolean => {
  if (!menuRoleIds) return false;
  if (!route.roleId) return false;
  if (menuRoleIds.includes(ROLE_MENU_ALL)) return true;
  return menuRoleIds.includes(route.roleId);
};

const hasRole = (menuRoleIds: string[], roles: string[]): boolean => {
  if (!menuRoleIds) return false;
  if (!roles.length) return false;
  if (menuRoleIds.includes(ROLE_MENU_ALL)) return true;
  return intersection(menuRoleIds, roles).length > 0;
};

const SideMenu: FC = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const {
    userStore: { currentUser }
  } = useStore();

  const currentRoute = routes.find((r) => r.path === pathname);

  const [openKeys, setOpenKeys] = useState([currentRoute?.groupId]);
  const [selectedKey, setSelectedKey] = useState([currentRoute?.path]);

  const menuRolesId = currentUser?.profile?.menuRoleIds;

  const subMenu = defaultGroup
    .map((group) => {
      const menuItems = routes.filter(
        (r) => r.groupId === group.groupId && hasMenu(menuRolesId, r) && !r.hide && r.exact
      );
      return menuItems.length ? (
        <SubMenu
          key={group.groupId}
          title={t(group.name)}
          icon={group.icon}
          style={{ textTransform: 'uppercase' }}
        >
          {menuItems.map((m) => (
            <Item
              key={m.path}
              style={{ display: m.hide ? 'none' : 'inherit', textTransform: 'capitalize' }}
            >
              <Link to={m.path} style={{ textTransform: 'capitalize' }}>
                {t(m.name)}
              </Link>
            </Item>
          ))}
        </SubMenu>
      ) : null;
    })
    .filter(Boolean);

  const subMenuKeys = subMenu.map((i) => i.key);

  const onOpenChange = (keys: string[]) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (!subMenuKeys.includes(latestOpenKey)) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const menuJSX = (listRoutes) => {
    const resultMenuJSX = [];
    listRoutes.forEach((route) => {
      if (!route.children || route.children?.length === 0) {
        if (hasMenu(menuRolesId, route)) {
          resultMenuJSX.push(
            <Item
              key={route.path}
              icon={route.icon}
              style={{ display: route.hide ? 'none' : 'inherit', textTransform: 'uppercase' }}
            >
              <Link to={route.path} style={{ textTransform: 'uppercase' }}>
                {t(route.name)}
              </Link>
            </Item>
          );
        }
      } else {
        const childrenRole = route.children.map((i) => i.roleId);
        if (hasRole(menuRolesId, childrenRole)) {
          resultMenuJSX.push(
            <SubMenu
              key={route.groupId}
              title={t(route.name)}
              icon={route.icon}
              style={{ textTransform: 'uppercase' }}
            >
              {route.children?.length ? (
                route.children?.map((child) => {
                  if (hasMenu(menuRolesId, child)) {
                    return (
                      <Item
                        key={child.path}
                        style={{
                          display: child.hide ? 'none' : 'inherit',
                          textTransform: 'capitalize'
                        }}
                      >
                        <Link to={child.path} style={{ textTransform: 'capitalize' }}>
                          {t(child.name)}
                        </Link>
                      </Item>
                    );
                  }
                })
              ) : (
                <></>
              )}
            </SubMenu>
          );
        }
      }
    });
    return resultMenuJSX;
  };

  return (
    <Menu
      mode="inline"
      openKeys={openKeys}
      onOpenChange={onOpenChange}
      selectedKeys={selectedKey}
      onSelect={(e) => {
        setSelectedKey(e.selectedKeys);
      }}
    >
      <Menu.Divider />
      {menuJSX(routes.filter((i) => i.isBasic))}
      <li
        className="ant-menu-item ant-menu-item-only-child"
        role="menuitem"
        style={{ paddingLeft: '24px', backgroundColor: '#80888f21' }}
      >
        {t('ADVANCE_FEATURES')}
      </li>
      {menuJSX(routes.filter((i) => !i.isBasic))}
    </Menu>
  );
};

export default SideMenu;
