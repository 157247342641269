import { Button, Modal, Result } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import SignUpStore from './store';

const ModalSignUpSuccess: React.FC = observer(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const { isSuccessModalVisible, toggleSuccessModal } = SignUpStore;

  const footer = (
    <Button
      type="default"
      style={{ background: '#858796', color: 'white' }}
      onClick={() => {
        toggleSuccessModal();
        history.push('/login');
      }}
    >
      {t('CLOSE')}
    </Button>
  );

  return (
    <Modal footer={footer} visible={isSuccessModalVisible}>
      <Result
        status="success"
        title={t('REGISTER_SUCCESS')}
        subTitle={t('CHECK_EMAIL_TO_ACTIVE')}
      />
    </Modal>
  );
});

export default ModalSignUpSuccess;
