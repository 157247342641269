import { Avatar, Dropdown, Menu } from 'antd';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { InfoCircleOutlined, LogoutOutlined } from '@ant-design/icons';

import { flexCenter } from '~assets/css-classes';
import { dropdownMenu_header, horDivider, info_name } from '~components/Layout/css';
import { useStore } from '~context/react-context';
import { AccountSettingsLocation, BalanceLocation } from '~screens/routes';

const { Item } = Menu;

const InfoMenu: FC = observer(() => {
  const { t } = useTranslation();
  const {
    authStore: { logout },
    userStore: { currentUser }
  } = useStore();

  const onLogout = async () => {
    await logout();
  };

  const avatarLink = get(currentUser, 'avatarLink');

  const userMenu = (
    <Menu theme="light">
      <Item key="info" style={{ display: 'flex', alignItems: 'center' }}>
        <InfoCircleOutlined />
        {AccountSettingsLocation.toLink(t('user.INFORMATION'), null)}
      </Item>
      {/* <div className={horDivider} /> */}
      {/* <Item key="balance" style={{ display: 'flex', alignItems: 'center' }}>
        <InfoCircleOutlined />
        {BalanceLocation.toLink(t('balance.BALANCE'), null)}
      </Item> */}
      <div className={horDivider} />
      <Item
        key="logout"
        onClick={() => onLogout()}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <LogoutOutlined />
        <span>{t('user.LOGOUT')}</span>
      </Item>
    </Menu>
  );
  return (
    <Dropdown overlay={userMenu} className={dropdownMenu_header} trigger={['click']}>
      <span className={flexCenter}>
        {avatarLink ? (
          <Avatar size="small" src={avatarLink} />
        ) : (
          <Avatar size="small" style={{ background: '#1890ff' }}>
            {currentUser.name.substring(1, 0)}
          </Avatar>
        )}
        <span className={info_name}>{currentUser.name}</span>
      </span>
    </Dropdown>
  );
});

export default InfoMenu;
