import { AndroidOutlined, LoginOutlined } from '@ant-design/icons';
import { Tabs } from 'antd';
import React, { FC } from 'react';
import { observer } from 'mobx-react';

import { useTranslation } from 'react-i18next';
import LoginGrid from './LoginGrid';
import LogsGridCampaignConfig from './CampaignConfigGrid';

const { TabPane } = Tabs;

const LogsTabs: FC = observer(() => {
  const { t } = useTranslation();
  return (
    <Tabs
      style={{ height: 'calc(100vh + 160px)' }}
      defaultActiveKey="login"
      tabBarStyle={{ backgroundColor: '#F0F2F5' }}
      tabBarGutter={50}
    >
      <TabPane
        tab={
          <span>
            <LoginOutlined />
            {t('LOGIN')} / {t('user.LOGOUT')}
          </span>
        }
        key="login"
      >
        <LoginGrid />
      </TabPane>
      <TabPane
        tab={
          <span>
            <AndroidOutlined />
            {t('CAMPAIGN')}
          </span>
        }
        key="2"
      >
        <LogsGridCampaignConfig />
      </TabPane>
    </Tabs>
  );
});

export default LogsTabs;
