import { css } from 'emotion';

export const container = css({
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  overflow: 'auto',
  background: '#f0f2f5',
  '@media(min-width: 768px)': {
    backgroundImage: 'url("https://gw.alipayobjects.com/zos/rmsportal/TVYTbAXWheQpRcWDaDMu.svg")',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center 110px',
    backgroundSize: '100%'
  }
});

export const login_header = css({
  width: '100%',
  height: '40px',
  lineHeight: '44px',
  textAlign: 'right',
  paddingRight: '12px'
});

export const login_content = css({
  flex: '1 1',
  '@media(min-width: 768px)': {
    padding: '32px 0 24px'
  }
});

export const content_header = css({
  textAlign: 'center',
  marginTop: '64px'
});

export const content_desc = css({
  marginTop: '12px',
  marginBottom: '40px',
  fontSize: '14px',
  lineHeight: '22px',
  color: 'rgba(0,0,0,.45)'
});

export const content_form = css({
  width: '368px',
  margin: '0 auto'
});

export const login_footer = css({
  margin: '48px 0 24px',
  padding: '0 16px',
  textAlign: 'center',
  lineHeight: '22px'
});

export const form_login_fb = css({
  marginTop: '16px',
  lineHeight: '22px',
  textAlign: 'left'
});

export const icon_hover = css({
  ':hover': {
    color: '#1890ff',
    cursor: 'pointer'
  },
  fontSize: '24px',
  marginRight: '8px',
  color: 'rgba(0,0,0,.45)'
});

export const login_button = css({
  width: '100%',
  margin: '16px 2px 0'
});

export const login_logo = css({
  maxWidth: '29%',
  height: 'auto',
  '@media(max-width: 576px)': {
    maxWidth: '80%'
  }
});
