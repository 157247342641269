/* eslint-disable react/jsx-key */
import { Badge, Descriptions, Popover, Tag, Typography } from 'antd';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactResizeDetector from 'react-resize-detector';

import { ColDef } from '@ag-grid-community/core';
import { PictureOutlined } from '@ant-design/icons';
import {
  COMING_SOON,
  format,
  LICENSE,
  LocationStatus,
  ROLE_ACTIVE_CAMPAIGNS_LOCATION,
  ROLE_READ_ALL
} from '@source/common';

import AgGridServer from '~components/UI/AgGrid/AgGridServer';
import { useStore } from '~context/react-context';
import { useGraphQLSDK } from '~graphql/GraphQLSDKProvider';

import CellActionRender from './CellActionRender';
import NameRenderer from './NameRenderer';
import store from './store';

const LocationGrid: FC = observer(() => {
  const { t } = useTranslation();
  const sdk = useGraphQLSDK();
  const { commonDataStore } = useStore();

  const {
    userStore: { currentUser }
  } = useStore();

  const { handleGridReady, initColDef, whereFilter, previewBanner } = store;

  const bannerActive = (banners: [any], locationId) => {
    let result = [];
    if (!banners?.length) {
      return result;
    }

    for (let index = 0; index < banners.length; index += 1) {
      const banner = banners[index];
      const query = JSON.parse(banner.query);

      if (query?.currentLocationId && query?.currentLocationId['$in'].indexOf(locationId) > -1) {
        result = [banner];
        break;
      }

      if (query !== null && !query?.currentLocationId) {
        result.push(banner);
      }

      if (query === null) {
        result.push(banner);
        break;
      }
    }
    return result;
  };

  const columnDefs: ColDef[] = [
    {
      headerName: '',
      field: '_id',
      cellClass: 'text-center',
      minWidth: 100,
      maxWidth: 100,
      cellRendererFramework: CellActionRender
    },
    {
      headerName: t('NO'),
      cellClass: 'text-center',
      minWidth: 50,
      maxWidth: 50,
      cellRenderer: ({ rowIndex }) => {
        return rowIndex + 1;
      }
    },
    {
      headerName: t('STATUS'),
      field: 'status',
      minWidth: 100,
      maxWidth: 100,
      // sort: 'asc',
      // sortedAt: 1,
      cellRenderer: ({ value }) => {
        let label = '';
        switch (value) {
          case LocationStatus.ACTIVE:
            label = t('ACTIVE');
            break;
          case LocationStatus.DISABLED:
            label = t('DISABLED');
            break;
          case LocationStatus.CREATED:
            label = t('CREATED');
            break;
          case LocationStatus.COMING_SOON:
            label = t('COMING_SOON');
            break;

          default:
            break;
        }
        return label;
      }
    },
    {
      headerName: t('NAME'),
      field: 'name',
      minWidth: 250,
      cellRendererFramework: NameRenderer
    },
    {
      headerName: t('LICENSE_NAME'),
      field: 'licenseName',
      minWidth: 250,
      hide:
        !currentUser.profile.roleIds.includes(ROLE_READ_ALL) || currentUser.ownerType !== LICENSE
    },
    {
      headerName: t('ADDRESS'),
      field: 'address.text',
      minWidth: 300,
      hide: true
    },
    {
      headerName: t('CATEGORY'),
      field: 'categoryId',
      width: 120,
      hide: true
    },
    {
      headerName: t('CAMPAIGN'),
      field: 'campaignRunning',
      minWidth: 100,
      maxWidth: 100,
      hide:
        !currentUser.profile.roleIds.includes(ROLE_READ_ALL) &&
        !currentUser.profile.roleIds.includes(ROLE_ACTIVE_CAMPAIGNS_LOCATION),
      cellClass: 'text-right',
      cellRendererFramework: ({ value, data }) => {
        return !value?.length ? (
          <Typography.Link>0 Running</Typography.Link>
        ) : (
          <Popover
            title={t('RUNNING_CAMPAIGN')}
            placement="bottomLeft"
            trigger="click"
            content={
              <Descriptions bordered size="middle" column={1}>
                {value.map((campaign) => (
                  <Descriptions.Item
                    span={1}
                    label={
                      <>
                        <Badge status={campaign?.isActive ? 'success' : 'warning'} />
                        {campaign?.campaignName}
                      </>
                    }
                  >
                    {bannerActive(campaign?.banners || [], data._id.toString()).map((b) => (
                      <PictureOutlined
                        style={{ fontSize: '20px', marginRight: '8px', color: 'green' }}
                        onClick={() => {
                          previewBanner(b.bannerId, campaign?.landingPage);
                        }}
                      />
                    ))}
                  </Descriptions.Item>
                ))}
              </Descriptions>
            }
          >
            <Typography.Link>{value?.length} Running</Typography.Link>
          </Popover>
        );
      }
    },
    {
      headerName: t('CITY'),
      field: 'city',
      headerClass: 'text-center',
      minWidth: 100,
      maxWidth: 100,
      cellRenderer: ({ value }) => {
        const city = commonDataStore.commonCity.find((i) => i.value === value);
        return get(city, 'text', '-');
      }
    },
    {
      headerName: t('DEVICE'),
      field: '',
      minWidth: 100,
      maxWidth: 100,
      cellClass: 'text-right',
      cellRenderer: ({ data }) => {
        return `${data.noOfAccessPoint || 0} / ${data.noOfRouter || 0}`;
      }
    },
    {
      headerName: t('MONTHLY_CLICK'),
      headerClass: 'text-center',
      field: 'stats.monthlyClick',
      cellClass: 'text-right',
      maxWidth: 120,
      minWidth: 120,
      cellRenderer: ({ value }) => `${value ? value.toLocaleString() : 0}`
    },
    {
      headerName: t('UNIQUE_USER'),
      headerClass: 'text-center',
      field: 'stats.uniqueUser',
      cellClass: 'text-right',
      hide: true,
      maxWidth: 120,
      minWidth: 120,
      cellRenderer: ({ value }) => `${value ? value.toLocaleString() : 0}`
    },
    {
      headerName: t('CTR'),
      field: 'stats.ctr',
      cellClass: 'text-right',
      maxWidth: 80,
      minWidth: 70,
      cellRenderer: ({ value }) => `${value ? value.toLocaleString() : 0} %`
    },
    {
      headerName: t('DISTRICT'),
      field: 'district',
      maxWidth: 100,
      hide: true
    },
    {
      headerName: t('ACTIVE_DAY'),
      field: 'activeDay',
      maxWidth: 120,
      minWidth: 120,
      hide: false,
      cellStyle: { textAlign: 'center' },
      cellRendererFramework: ({ value, data }) => {
        if (data.status === COMING_SOON) {
          return '-';
        }
        return format(value, 'DD-MM-YYYY');
      }
    },
    {
      headerName: 'Creator',
      field: 'creator.name',
      maxWidth: 150,
      minWidth: 150,
      cellClass: 'text-left',
      hide: true,
      cellRenderer: ({ value }) => value || ''
    },
    {
      headerName: t('CREATED_AT'),
      field: 'createdAt',
      // sort: 'desc',
      // sortedAt: 2,
      minWidth: 120,
      maxWidth: 120,
      cellRenderer: ({ value }) => format(value, 'DD-MM-YYYY')
    },
    {
      headerName: t('LAST_ACTIVE'),
      field: 'lastActiveTracking',
      sort: 'desc',
      sortedAt: 1,
      minWidth: 120,
      maxWidth: 120,
      cellRenderer: ({ value }) => format(value, 'DD-MM-YYYY')
    },
  ];

  useEffect(() => {
    initColDef(columnDefs);
  }, []);

  return (
    <ReactResizeDetector handleHeight>
      {({ height }) => {
        return (
          <AgGridServer
            options={{
              columnDefs: store.colDefs
            }}
            emptyHandler={() => store.setModalVisible(null, 'basic')}
            screen="location"
            height={height}
            onQuery={sdk.fetchLocationsGrid}
            queryKey="findManyLocation"
            onCountQuery={sdk.countLocation}
            countQueryKey="countLocation"
            onGridReady={handleGridReady}
            where={whereFilter}
          />
        );
      }}
    </ReactResizeDetector>
  );
});

export default LocationGrid;
