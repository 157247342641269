import { get } from 'lodash';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';

import QueryBuilder from '~components/UI/QueryBuilder';
import { Audience } from '~graphql/_sdk';

import store from '../store';

const TargetObjectConfig = observer(() => {
  const { setAudienceQuery, setAudienceQueryTree, campaign, genFields, customFields } = store;
  const audience = get(campaign, 'config.audience.acceptRules', {}) as Audience;
  const fields = genFields(toJS(customFields));
  return (
    <div style={{ padding: '16px 24px 16px 5px' }}>
      <QueryBuilder
        initValue={get(audience, 'queryTree', null)}
        fields={fields}
        getQuery={setAudienceQuery}
        getTree={setAudienceQueryTree}
      />
    </div>
  );
});

export default TargetObjectConfig;
