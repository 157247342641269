import { observer } from 'mobx-react';
import React from 'react';

import { UIButtonExportExcel } from '~components/UI/UIButtonExportExcel';
import { useStore } from '~context/react-context';
import { isMobile } from '~utils/isMobile';

import store from '../store';

export const Export: React.FC = observer(() => {
  const { exportFilter, getCampaignSurveyFilter, selectedTab, campaignId } = store;
  const {
    authStore: { token }
  } = useStore();

  const modelPath = `report/campaign${selectedTab === 'mac' ? '/crm' : ''}`;

  let filter: any = { ...exportFilter };

  if (selectedTab === 'mac') {
    filter = { ...getCampaignSurveyFilter };
    filter.campaignId = campaignId;
  }

  return (
    <UIButtonExportExcel
      key="exportExcel"
      name="Export"
      model={modelPath}
      style={{ display: isMobile() ? 'none' : 'inline-block', marginBottom: 8 }}
      filter={filter}
      token={token}
    />
  );
});
