import { Tabs, Tooltip } from 'antd';
import React, { FC } from 'react';
import ReactResizeDetector from 'react-resize-detector';
import { useTranslation } from 'react-i18next';

import useQuery from '~utils/useQuery';

import CampaignRatio from './CampaignRatio';
import Chart from './Chart';
import Grid from './Grid';
import ShareTraffic from './ShareTraffic';
import UserReturn from './UserReturn';
import store, { StoreTab } from './store';
import UpDownTraffic from './UpDownTraffic';

const { TabPane } = Tabs;

export const TabRPLocation: FC = () => {
  const { tab, onTabChange, where } = store;
  const { t } = useTranslation();

  const locationId = useQuery().get('_id');
  let currentLocation = [];

  if (locationId) {
    try {
      currentLocation = JSON.parse(locationId);
      where._id = currentLocation;
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <ReactResizeDetector handleHeight handleWidth>
      {({ height, width }) => {
        return (
          <Tabs
            style={{ height: '100%' }}
            size="small"
            onChange={(cTab) => onTabChange(cTab as StoreTab)}
            tabBarStyle={{ backgroundColor: '#F0F2F5' }}
            tabBarGutter={50}
            defaultActiveKey={tab}
          >
            <TabPane
              tab={
                <span style={{ paddingLeft: 15, paddingRight: 15 }}>
                  <i className="fas fa-fw fa-chart-bar" />
                  {t('CHART')}
                </span>
              }
              key="chart"
            >
              <Chart widthChart={width} heightChart={height} />
            </TabPane>
            <TabPane
              tab={
                <span style={{ paddingLeft: 15, paddingRight: 15 }}>
                  <i className="fas fa-fw fa-th" />
                  {t('GRID')}
                </span>
              }
              key="grid"
            >
              <Grid height={height} width={width} />
            </TabPane>
            {/* <TabPane
              tab={
                <span style={{ paddingLeft: 15, paddingRight: 15 }}>
                  <i className="fas fa-fw fa-balance-scale" />
                  {t('SHARE_TRAFFIC')}
                </span>
              }
              key="share"
            >
              <ShareTraffic widthChart={width} heightChart={height} />
            </TabPane> */}
            <TabPane
              tab={
                <span style={{ paddingLeft: 15, paddingRight: 15 }}>
                  <i className="fas fa-fw fa-user" />
                  {t('RETURN_USER')}
                </span>
              }
              key="userReturn"
            >
              <UserReturn widthChart={width} heightChart={height} />
            </TabPane>
            <TabPane
              tab={
                <span style={{ paddingLeft: 15, paddingRight: 15 }}>
                  <i className="fas fa-fw fa-percent" />
                  {t('CAMPAIGN_TYPE')}
                </span>
              }
              key="campType"
            >
              <CampaignRatio widthChart={width} heightChart={height} />
            </TabPane>
            <TabPane
              tab={
                <span style={{ paddingLeft: 15, paddingRight: 15 }}>
                  <i className="fas fa-fw fa-retweet" />
                  {t('UP_DOWN_TRAFFIC')}
                </span>
              }
              key="upDown"
            >
              <UpDownTraffic width={width} height={height} />
            </TabPane>
          </Tabs>
        );
      }}
    </ReactResizeDetector>
  );
};
