import '@ant-design/compatible/assets/index.css';

import { Button, Checkbox, Divider, Input } from 'antd';
import i18next from 'i18next';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import useForm from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as yup from 'yup';

import { Form } from '@ant-design/compatible';
import { LockOutlined, UserOutlined } from '@ant-design/icons';

import UIField from '~components/UI/UIField';
import { useStore } from '~context/react-context';

import { form_login_fb, login_button } from './css';
import store from './store';
import TFAForm from './TFAForm';

const { Item } = Form;

class FormValues {
  email: string;
  password: string;
}

const SignInSchema = yup.object().shape<FormValues>({
  email: yup.string().required(i18next.t('REQF')),
  password: yup.string().required(i18next.t('REQF'))
});

const defaultValues: FormValues = {
  email: '',
  password: ''
};

const LoginForm: FC = observer(() => {
  const {
    authStore: { login }
  } = useStore();

  const { setModalVisible, setStoredPassword, setUserId, userId } = store;
  const formProps = useForm<FormValues>({ defaultValues, validationSchema: SignInSchema });

  const {
    handleSubmit,
    formState: { isSubmitting }
  } = formProps;

  const onSubmit = async ({ email, password }: FormValues) => {
    const { isTFA, data } = await login({ email, password });
    if (isTFA && data) {
      setStoredPassword(password);
      setUserId(data._id);
    }
  };

  const { t } = useTranslation();

  const setKeepLogin = (value: boolean) => {
    window.localStorage.setItem('keep', value.toString());
  };

  const keepLogin = JSON.parse(window.localStorage.getItem('keep'));

  return (
    <>
      {!userId ? (
        <Form layout="vertical" onSubmit={handleSubmit(onSubmit)}>
          <UIField
            {...formProps}
            label={t('EMAIL')}
            name="email"
            fieldProps={{
              name: 'email',
              placeholder: t('login:email'),
              type: 'email',
              prefix: <UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
            }}
            fieldComponent={Input}
          />
          <UIField
            {...formProps}
            label={t('PASSWORD')}
            name="password"
            fieldProps={{
              name: 'password',
              type: 'password',
              placeholder: t('login:password'),
              prefix: <LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
            }}
            fieldComponent={Input}
          />
          <Item>
            <Checkbox
              onChange={(e) => {
                setKeepLogin(e.target.checked);
              }}
              defaultChecked={keepLogin}
            >
              {t('REMEMBER_ME')}
            </Checkbox>
            <Link to="" onClick={setModalVisible} style={{ float: 'right' }}>
              {t('FORGOT_YOUR_PASSWORD')} ?
            </Link>
            <Button
              htmlType="submit"
              type="primary"
              loading={isSubmitting}
              className={login_button}
            >
              {t('login:login')}
            </Button>
            <Divider />
            <div className={form_login_fb}>
              <Button htmlType="submit" type="default" href="/signup" style={{ width: '100%' }}>
                {t('REGISTER')} {t('ACCOUNT')}
              </Button>
            </div>
          </Item>
        </Form>
      ) : (
        <TFAForm />
      )}
    </>
  );
});

export default LoginForm;
