import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import vn from '../../../public/locales/vn';
import en from '../../../public/locales/en';

const availableLanguages = ['vn', 'en'];
const fallbackLng = ['vn'];
const resources = {
  vn,
  en
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    whitelist: availableLanguages,
    fallbackLng,
    // debug: true,
    interpolation: {
      escapeValue: true
    },
    react: {
      useSuspense: true
    }
  });
export default i18n;
