/* eslint-disable dot-notation */
import { get, set, sumBy } from 'lodash';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactResizeDetector from 'react-resize-detector';

import { ColDef, ColGroupDef } from '@ag-grid-community/core';

import { flexCenter } from '~assets/css-classes';
import AgGridClient from '~components/UI/AgGrid/AgGridClient';
import Loading from '~components/UI/Loading';
import { DATE_FORMAT, format } from '~utils/formatDate';
import { formatNumber } from '~utils/formatNumber';

import NameRenderer from './CellRenderer';
import store from './store';

const InventoryScreen: FC = observer(() => {
  const { t } = useTranslation();
  const {
    listAvailableKpi,
    findAvailableKpi,
    dayRange,
    onTableReady,
    loading,
    mapCategory,
    doesExternalFilterPass
  } = store;

  useEffect(() => {
    findAvailableKpi();
  }, []);

  const getChildren = () => {
    const children = [];
    for (let i = 0, { length } = dayRange; i < length; i++) {
      children.push({
        headerName: dayRange[i],
        field: 'everyDay',
        minWidth: 100,
        maxWidth: 100,
        cellClass: 'text-right',
        cellRendererFramework: ({ value }) => {
          if (value) {
            const availableKpi = get(value[i], 'availableKpi', 0);
            return <span>{formatNumber(availableKpi)}</span>;
          }
          return '';
        }
      });
    }
    return children;
  };

  const children = getChildren();
  const columnDefs: (ColDef | ColGroupDef)[] = [
    {
      field: 'group',
      rowGroup: true,
      hide: true,
      sort: 'asc',
      cellRenderer: ({ value }) => {
        return mapCategory[value].text;
      }
    },
    {
      field: 'categoryId',
      rowGroup: true,
      hide: true,
      sort: 'asc',
      minWidth: 200,
      maxWidth: 250,
      cellRenderer: ({ value }) => {
        return mapCategory[value].text;
      }
    },
    {
      headerName: t('LOCATION_NAME'),
      field: 'name',
      minWidth: 250,
      resizable: true,
      sortable: true,
      checkboxSelection: (params) => {
        return !params.node.group;
      },
      cellRendererFramework: NameRenderer
    },
    {
      headerName: t('BOOKING_KPI'),
      field: 'bookingKpi',
      maxWidth: 120,
      minWidth: 120,
      sortable: true,
      aggFunc: 'sum',
      cellClass: 'text-right',
      cellRenderer: ({ value }) => {
        return formatNumber(value > 0 ? value : 0);
      }
    },
    {
      headerName: t('AVAILABLE_KPI'),
      field: 'availableTotalKpi',
      minWidth: 120,
      maxWidth: 120,
      sortable: true,
      aggFunc: 'sum',
      cellClass: 'text-right',
      cellRenderer: ({ value }) => {
        return formatNumber(value > 0 ? value : 0);
      }
    },
    { headerName: t('EVERY_DAY'), children, sortable: true }
  ];

  const onSelectionChanged = (event) => {
    const rowData = event.api.getSelectedRows().map((i) => ({
      ...i,
      availableTotalKpi: +i.availableTotalKpi,
      bookingKpi: +i.bookingKpi,
      checked: true
    }));
    let result = {};
    const obj = {};

    result = {
      name: `Selected: ${rowData.length}`,
      availableTotalKpi: sumBy(rowData, 'availableTotalKpi'),
      bookingKpi: sumBy(rowData, 'bookingKpi')
    };

    rowData.forEach((item) => {
      item.everyDay.forEach((i) => {
        const date = format(i.date, DATE_FORMAT);
        let tmpKpi = get(obj, date, 0);
        tmpKpi += i.availableKpi;
        set(obj, date.toString(), tmpKpi);
      });
    });

    const everyDay = Object.keys(obj).map((key) => {
      return {
        date: key,
        availableKpi: obj[key]
      };
    });
    result = { ...result, everyDay };
    event.api.setPinnedTopRowData([result]);
  };
  const strcmp = (a, b) => {
    return a < b ? -1 : a > b ? 1 : 0;
  };

  const autoGroupColumnDef = () => {
    return {
      headerName: ' Group ',
      minWidth: 350,
      sortable: true,
      sort: 'asc',
      cellRendererParams: {
        checkbox: true
      },
      comparator: (valueA, valueB) => {
        if (valueA == null || valueB == null) return valueA - valueB;
        if (!valueA.substring || !valueB.substring) return valueA - valueB;
        if (valueA.length < 1 || valueB.length < 1) return valueA - valueB;
        return strcmp(valueA.substring(1, valueA.length), valueB.substring(1, valueB.length));
      }
    };
  };

  const rowData = toJS(listAvailableKpi).map((item) => {
    const { name, everyDay, _id } = item;
    const availableTotalKpi = get(item, 'availableTotalKpi', 0);
    const bookingKpi = get(item, 'bookingKpi', 0);
    const group = item.group ? item.group : 'other';
    const categoryId = get(item, 'categoryId', '');
    return {
      _id,
      name,
      availableTotalKpi,
      bookingKpi,
      everyDay,
      group,
      categoryId
    };
  });

  return loading ? (
    <div className={flexCenter} style={{ height: 'calc(100% - 96px)', backgroundColor: '#fff' }}>
      <Loading />
    </div>
  ) : (
    <>
      <ReactResizeDetector handleHeight>
        {({ height }) => (
          <AgGridClient
            groupSelectsChildren
            suppressRowClickSelection
            groupSelectsFiltered
            suppressAggFuncInHeader
            autoGroupColumnDef={autoGroupColumnDef()}
            rowData={rowData}
            onGridReady={onTableReady}
            columnDefs={columnDefs}
            rowSelection="multiple"
            height={height - 96}
            isExternalFilterPresent={() => true}
            doesExternalFilterPass={doesExternalFilterPass}
            onSelectionChanged={onSelectionChanged}
          />
        )}
      </ReactResizeDetector>
    </>
  );
});

export default InventoryScreen;
