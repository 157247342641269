import '@ant-design/compatible/assets/index.css';

import { Button, Descriptions, Input, Modal } from 'antd';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Form } from '@ant-design/compatible';

import store from './store';

interface Props {
  visible: boolean;
  setModalVisible: () => void;
}

const TimesRender: FC<Props> = observer(({ visible, setModalVisible }) => {
  const { selectedItem, setTimes, setCurrentTime, currentTime } = store;
  const { t } = useTranslation();
  const [value] = useState(get(selectedItem, 'times'));

  const setValueTimes = value => {
    setCurrentTime(value);
  };

  const footer = [
    <Button key="close" onClick={() => setModalVisible()}>
      {t('CLOSE')}
    </Button>,
    <Button key="apply" type="primary" onClick={() => setTimes(selectedItem?._id)}>
      {t('APPLY')}
    </Button>
  ];

  return (
    <Modal title={t('TIMES_CAMPAIGN')} footer={footer} visible={visible}>
      <Form layout="vertical">
        <Descriptions bordered size="small" column={3}>
          <Descriptions.Item label={t('NAME')} span={3}>
            {get(selectedItem, 'name', '')}
          </Descriptions.Item>
        </Descriptions>
        <Form.Item label={t('TIMES')} labelAlign="left" style={{ marginBottom: 8 }}>
          <Input defaultValue={value} onChange={e => setValueTimes(e.target.value)} />
        </Form.Item>
      </Form>
    </Modal>
  );
});

export default TimesRender;
