import { Input, PageHeader } from 'antd';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { SearchOutlined } from '@ant-design/icons';

import store from './store';

const LogsHeader: FC = observer(() => {
  const { t } = useTranslation();
  const { setSearchText, setModalVisible } = store;

  return (
    <PageHeader
      style={{ background: '#fff', marginBottom: 0 }}
      title=""
      extra={[
        <Input
          key="search"
          suffix={<SearchOutlined />}
          placeholder={t('SEARCH')}
          allowClear
          onChange={(e) => setSearchText(e.target.value)}
          style={{ width: '200px', marginRight: '8px' }}
        />
      ]}
    />
  );
});

export default LogsHeader;
