import './css.less';
import '@ant-design/compatible/assets/index.css';

import i18next from 'i18next';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { FC, useEffect } from 'react';
import useForm from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { Form } from '@ant-design/compatible';
import { mapReduce } from '@source/common';

import { useStore } from '~context/react-context';

import CampaignFinishStep from './CampaignFinishStep';
import CampaignInfoForm from './CampaignInfoStep';
import Content from './Content';
import Header from './Header';
import KPIForm from './KPIStep';
import PickLocationStep from './PickLocationStep';
import store from './store';
import { Card } from 'antd';

interface FormValues {
  name?: string;
  budget?: string;
}

const CampaignBookingScreen: FC = observer(() => {
  const { t } = useTranslation();
  const {
    bookingRangeDate: { to, from },
    locationCheckedList,
    isAllLocationBooked,
    bookNewCampaign,
    budgetBooking,
    optimizeType,
    totalBooking,
    getBudgetBooking,
    init,
    step,
    locations
  } = store;

  const {
    commonDataStore,
    userStore: {
      currentUser: { extra }
    }
  } = useStore();

  useEffect(() => {
    let fetch = true;
    if (fetch) {
      commonDataStore.loadCommonCity();
      commonDataStore.loadLocationCategory();
      commonDataStore.loadCampaignTypeBrand();
      commonDataStore.loadCampaignType();
      commonDataStore.loadGroupType();
      init();
    }
    return () => {
      store.reset();
      fetch = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if ((extra as any) && extra.budgetBooking) {
      getBudgetBooking(extra.budgetBooking);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  yup.addMethod(yup.string, 'JSON', function (message: string) {
    return this.test('validate-String', message, (value) => {
      if (budgetBooking === -1) return true;
      return Number(value.replace(/,/g, '')) <= budgetBooking;
    });
  });

  const validationSchema = yup.object().shape<FormValues>({
    name: yup.string().required(i18next.t('NAME_CAMPAIGN_IS_REQUIRED')),
    budget: yup.string().JSON(i18next.t('YOUR_BUDGET_MUST_BE_MORE_THAN_BOOKING'))
  });

  const categoryMap = mapReduce(commonDataStore.categories, 'value', ({ text }) => text);

  const step3Validation: boolean = !isAllLocationBooked || !to || step === 0;

  const formProps = useForm<FormValues>({ validationSchema });

  const steps = [
    {
      title: t('PICK_LOCATION'),
      content: <PickLocationStep />,
      disabled: step >= 2
    },
    {
      title: t('SET_KPI'),
      content: <KPIForm />,
      disabled: locationCheckedList.length === 0
    },
    {
      title: t('INFO'),
      content: <CampaignInfoForm formProps={formProps} />,
      disabled: step3Validation
    },
    {
      title: t('FINISH'),
      content: <CampaignFinishStep />,
      disabled: true
    }
  ];

  const { handleSubmit } = formProps;

  const onSubmit = async () => {
    // const location = locationCheckedList.filter(l => l['kpi']);
    const locationList = locationCheckedList.map((l) => {
      console.log(l.equipments.filter((ap) => get(ap, 'checked')));
      return {
        locationName: l.name,
        locationId: l._id,
        categoryId: l.categoryId,
        categoryName: categoryMap[l.categoryId],
        equipments: l.equipments.filter((ap) => get(ap, 'checked')).map((i) => i._id),
        // eslint-disable-next-line dot-notation
        kpi: +l['kpi']
      };
    });

    bookNewCampaign({
      scheduleFrom: from,
      scheduleTo: to,
      optimizeType,
      locations: locationList,
      kpi: totalBooking
    });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
      <Header steps={steps} />
      {locations.length ? (
        <Content steps={steps} />
      ) : (
        <Card style={{ width: '100%' }}>
          <h1>No Locations. Please add Location and active</h1>
        </Card>
      )}
    </Form>
  );
});
export default CampaignBookingScreen;
