import { get, isEmpty } from 'lodash';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactResizeDetector from 'react-resize-detector';

import { ColDef } from '@ag-grid-community/core';

import AgGridClient from '~components/UI/AgGrid/AgGridClient';
import { useStore } from '~context/react-context';
import { formatNumber } from '~utils/formatNumber';

import store from './store';

const Grid: FC = observer(() => {
  const { handleGridReady, groupByLocation, hideGroupName } = store;
  const { t } = useTranslation();
  const { commonDataStore } = useStore();

  useEffect(() => {
    commonDataStore.loadCommonCity();
  });

  const autoGroupColumnDef = () => {
    return {
      headerName: t('GROUP_LOCATION'),
      minWidth: 350,
      sortable: true,
      sort: 'asc'
    };
  };

  const columnDefs: ColDef[] = [
    // {
    //   headerName: t('NO'),
    //   field: '',
    //   cellClass: 'text-center',
    //   minWidth: 50,
    //   maxWidth: 50,
    //   cellRendererFramework: ({ rowIndex }) => rowIndex + 1
    // },
    {
      headerName: t('REPORT_LOCATION'),
      field: 'groupName',
      hide: true,
      rowGroup: !!hideGroupName,
      sortable: true,
      minWidth: 250
    },
    {
      headerName: t('LOCATION_NAME'),
      field: 'location.name',
      sortable: true,
      minWidth: 250
    },
    {
      headerName: t('CITY'),
      field: 'location.city',
      headerClass: 'text-center',
      minWidth: 100,
      maxWidth: 180,
      cellRendererFramework: ({ value }) => {
        const city = commonDataStore.commonCity.find(i => i.value === value);
        return get(city, 'text', '-');
      }
    },
    {
      headerName: t('CLICK'),
      field: 'click',
      sortable: true,
      minWidth: 100,
      maxWidth: 150,
      aggFunc: 'sum',
      cellClass: 'text-right',
      cellRendererFramework: ({ value }) => formatNumber(value)
    },
    {
      headerName: `${t('INCOME')} (VND)`,
      field: 'income',
      sortable: true,
      minWidth: 120,
      maxWidth: 150,
      aggFunc: 'sum',
      cellClass: 'text-right',
      cellRendererFramework: ({ value }) => formatNumber(value)
    }
  ];
  return (
    <ReactResizeDetector handleHeight>
      {({ height }) => {
        const gridHeight = height ? height - 60 : 500;
        return (
          <AgGridClient
            suppressAggFuncInHeader
            autoGroupColumnDef={autoGroupColumnDef()}
            columnDefs={columnDefs}
            height={gridHeight}
            animateRows
            rowData={toJS(groupByLocation)}
            onGridReady={handleGridReady}
          />
        );
      }}
    </ReactResizeDetector>
  );
});

export default Grid;
