import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import Fuse, { FuseOptions } from 'fuse.js';
import { debounce, isEqual, omit } from 'lodash';
import { action, observable, runInAction, toJS } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import { DataOptions, Options } from '~components/Filter/filterType';

const { Option } = Select;

export interface UISelectSearchProps extends SelectProps<any> {
  dataOptions?: DataOptions;
  // onChange?: (value: any) => void;
}

const checkOnlyOneSpace = (str: string) => {
  return str === ' ';
};

@observer
export class UISelectSearch extends Component<UISelectSearchProps> {
  @observable public selectData: Options[];
  @observable.shallow public renderedItems: Options[];
  @observable public loading: boolean;
  public searchEngine: Fuse<Options, FuseOptions<Options>>;
  public handleSearch = debounce(
    (value) => {
      if (value && !checkOnlyOneSpace(value)) {
        runInAction(() => {
          this.renderedItems = this.searchEngine.search(value) as Options[];
        });
      } else {
        runInAction(() => {
          this.renderedItems = [];
        });
      }
    },
    500,
    { leading: false, trailing: true }
  );
  constructor(props: Readonly<UISelectSearchProps>) {
    super(props);
    runInAction(() => {
      this.selectData = [];
      this.renderedItems = [];
      this.loading = true;
    });
    this.searchEngine = null;
  }

  componentDidMount() {
    const { dataOptions } = this.props;
    if (dataOptions.data.length) {
      this.setDataOptions(dataOptions);
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps: UISelectSearchProps) {
    const dataOptionsNext = nextProps.dataOptions;
    const valueNext = nextProps.value;
    const { value, dataOptions } = this.props;
    if (value !== valueNext) {
      runInAction(() => {
        this.renderedItems = this.selectData.filter((item) => item.value === valueNext);
      });
    }
    if (!isEqual(dataOptions, dataOptionsNext)) {
      this.setDataOptions(dataOptionsNext);
    }
  }

  @action
  public setDataOptions = (dataOptions: DataOptions) => {
    this.loading = true;
    const valueField = dataOptions.valueField || 'value';
    const textField = dataOptions.textField || 'text';
    this.selectData = dataOptions.data.map((item) => ({
      value: String(item[valueField]),
      text: String(item[textField])
    }));

    this.searchEngine = new Fuse(toJS(this.selectData), {
      shouldSort: true,
      threshold: 0.3,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: ['text'],
      includeMatches: false,
      includeScore: false
    });
    this.loading = false;
  };

  public render() {
    const props = omit(this.props, ['dataOptions']);

    return (
      <Select
        style={{ width: '100%' }}
        filterOption={false}
        showSearch
        onSearch={this.handleSearch}
        loading={this.loading}
        showArrow={false}
        notFoundContent="Not found !!!"
        {...props}
      >
        {this.renderedItems.map((item, index) => (
          <Option key={String(index)} value={item.value}>
            {item.text}
          </Option>
        ))}
      </Select>
    );
  }
}
