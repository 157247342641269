import { Col, Input, Row } from 'antd';
import { observer } from 'mobx-react';
import React, { FC, useEffect } from 'react';

import { SearchOutlined } from '@ant-design/icons';
import {
  APPROVED,
  CREATED,
  DISAPPROVED,
  notRunnableStatuses,
  ROLE_READ_ALL,
  runnableStatuses
} from '@source/common';

import { commonDataStore } from '~common-stores/store-index';
import Authorized from '~components/Authorized/Authorized';
import { UISelect } from '~components/UI/Select/UISelect';
import { UIDateRangePicker } from '~components/UI/UIDateRangePicker';
import { useStore } from '~context/react-context';

import store from '../store';

const FilterCampaignList: FC = observer(() => {
  const { typeFilter, statusFilter, onCampaignFilterChange, onSearch, dateFilter } = store;

  const CampaignStatus = [...runnableStatuses, ...notRunnableStatuses].filter(
    item => ![APPROVED, CREATED, DISAPPROVED].includes(item)
  );

  const upperFirstChar = (e: string) => {
    return e.charAt(0).toLocaleUpperCase() + e.slice(1);
  };

  useEffect(() => {
    commonDataStore.loadCampaignType();
  }, []);

  return (
    <Row gutter={16}>
      <Col flex="auto">
        <UIDateRangePicker
          style={{ width: '100%' }}
          value={dateFilter as any}
          allowClear
          onChange={e => onCampaignFilterChange('date', e)}
        />
      </Col>
      <Col flex="auto">
        <UISelect
          placeholder="Select Status"
          dataOptions={{
            data: CampaignStatus.map(e => {
              return { value: e, text: upperFirstChar(e) };
            }),
            valueField: 'value',
            textField: 'text'
          }}
          style={{ width: '100%' }}
          onChange={e => onCampaignFilterChange('status', e)}
          dropdownStyle={{ minWidth: 200 }}
          allowClear
          value={statusFilter}
          showArrow
          mode="multiple"
        />
      </Col>
      <Authorized roles={[ROLE_READ_ALL]}>
        <Col flex="auto">
          <UISelect
            placeholder="Select Type"
            dataOptions={{
              data: commonDataStore.campaignType,
              valueField: 'value',
              textField: 'text'
            }}
            style={{ width: '100%' }}
            onChange={e => onCampaignFilterChange('type', e)}
            allowClear
            showArrow
            value={typeFilter}
            dropdownStyle={{ minWidth: 150 }}
            mode="multiple"
          />
        </Col>
      </Authorized>
      <Col flex="auto">
        <Input
          key="search"
          suffix={<SearchOutlined />}
          placeholder="Search campaign..."
          allowClear
          onChange={e => onSearch(e.target.value)}
        />
      </Col>
    </Row>
  );
});

export default FilterCampaignList;
