import { Button, Form, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import { UploadProps } from 'antd/lib/upload';
import { RcCustomRequestOptions, UploadFile } from 'antd/lib/upload/interface';
import axios from 'axios';
import { isEmpty } from 'lodash';
import React, { FC, useEffect, useState } from 'react';

import { CloudUploadOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';

import { useGraphQLSDK } from '~graphql/GraphQLSDKProvider';

import { ErrorNotify } from './Notification';

interface Props extends UploadProps {
  bannerId?: string;
  label?: string;
  description?: string;
  quantity?: number;
  handleChange: (fileList: UploadFile[]) => void;
  ratio?: boolean;
  width?: number;
  height?: number;
  showButton?: boolean;
  buttonText?: string;
  name?: string;
  crop?: boolean;
}

const UIUpload: FC<Props> = ({
  crop,
  name,
  label,
  description,
  quantity,
  handleChange,
  defaultFileList,
  width,
  height,
  bannerId,
  showButton,
  buttonText,
  ...rest
}) => {
  const [uploading, setUploading] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [uploadedFile, setUploadedFile] = useState<UploadFile>(null);

  const sdk = useGraphQLSDK();

  useEffect(() => {
    setFileList(defaultFileList || []);
  }, []);

  useEffect(() => {
    if (!uploadedFile) return;
    setFileList([...fileList, uploadedFile]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedFile]);

  useEffect(() => {
    handleChange(fileList);
  }, [fileList]);

  const uploadFile = async ({ onSuccess, onError, file }: RcCustomRequestOptions) => {
    const {
      createFile: { data, error }
    } = await sdk.createFile(
      !isEmpty(bannerId)
        ? { bannerId, fileName: !name ? name : file.name }
        : { fileName: file.name }
    );
    if (error) {
      return ErrorNotify(error.title);
    }

    const { path: uploadUrl, id } = data;
    const options = {
      headers: {
        'Content-Type': file.type
      }
    };
    await axios
      .put(uploadUrl, file, options)
      .then(async res => {
        const { findFileById } = await sdk.findFileById({ id });
        if (!isEmpty(findFileById) && findFileById.error) {
          return ErrorNotify(findFileById.error.title);
        }

        const { path: viewUrl } = findFileById.data;
        const fileData: UploadFile = {
          uid: id,
          name: file.name,
          url: viewUrl,
          type: file.type,
          size: file.size
        };
        setUploadedFile(fileData);
        onSuccess(res, file);
      })
      .catch(err => {
        onError(err);
      });
  };

  const removeFile = async (file: UploadFile<any>) => {
    const {
      removeFileById: { error }
    } = await sdk.removeFileById({ id: file.uid });

    if (error) {
      ErrorNotify(error.title);
    } else {
      const index = fileList.findIndex(f => f.uid === file.uid);

      setFileList([...fileList.slice(0, index), ...fileList.slice(index + 1, fileList.length)]);
    }
  };

  const uploadProps: UploadProps = {
    ...rest,
    fileList,
    customRequest: async option => {
      setUploading(true);
      await uploadFile(option);
      setUploading(false);
    },
    onRemove: file => {
      removeFile(file);
    }
  };

  const imgCropProps = {
    modalTitle: 'Chỉnh sửa ảnh',
    width,
    height
  };

  const uploadButton =
    rest.listType === 'picture-card' ? (
      <>
        {uploading ? <LoadingOutlined /> : <PlusOutlined />}
        <div className="ant-upload-text">Upload</div>
      </>
    ) : (
      <Button icon={<CloudUploadOutlined />} loading={uploading}>
        {buttonText || 'Upload'}
      </Button>
    );

  const uploadRender =
    rest.listType === 'text' || crop === false ? (
      <Upload {...uploadProps}>
        {fileList.length >= (quantity || 100) && !showButton ? null : uploadButton}
      </Upload>
    ) : (
      <ImgCrop {...imgCropProps}>
        <Upload {...uploadProps}>
          {fileList.length >= (quantity || 100) && !showButton ? null : uploadButton}
        </Upload>
      </ImgCrop>
    );

  return (
    <Form.Item label={label}>
      {uploadRender}
      <small>{description}</small>
    </Form.Item>
  );
};
export default UIUpload;
