import './style.less';

import { Card } from 'antd';
import React, { FC } from 'react';

import { Role } from '~graphql/_sdk';

import RoleCard from './RoleCard';

interface RoleListProps {
  title: string;
  data: Role[];
}

const RoleList: FC<RoleListProps> = ({ title, data }) => {
  return (
    <Card
      title={title.toLocaleUpperCase()}
      style={{ marginTop: 5, border: '2px solid #8db1de' }}
      bodyStyle={{ display: 'flex', flexWrap: 'wrap' }}
      headStyle={{ borderBottom: '2px solid #8db1de' }}
    >
      {data &&
        data.length > 0 &&
        data.map((d) => {
          return <RoleCard record={d} key={`${d.group}-${d._id}`} />;
        })}
    </Card>
  );
};

export default RoleList;
