import { observer } from 'mobx-react';
import React from 'react';

import { UIButtonExportExcel } from '~components/UI/UIButtonExportExcel';
import { useStore } from '~context/react-context';
import { isMobile } from '~utils/isMobile';

import store from './store';

export const Export: React.FC = observer(() => {
  const {
    authStore: { token }
  } = useStore();
  const { where } = store;

  return (
    <UIButtonExportExcel
      key="exportExcel"
      name="Export"
      model="users"
      style={{ display: isMobile() ? 'none' : 'inline-block' }}
      filter={where}
      token={token}
      color="green"
    />
  );
});
