import { UploadFile } from 'antd/lib/upload/interface';
import i18next from 'i18next';
import { debounce, get, isEmpty } from 'lodash';
import { action, observable, reaction, runInAction } from 'mobx';
import { actionAsync, task } from 'mobx-utils';

import { GridReadyEvent } from '@ag-grid-community/core';
import { ROLE_DELETE_INTRUCTION, ROLE_UPDATE_INSTRUCTION, WhereArgs } from '@source/common';

import { ErrorNotify, SuccessNotify } from '~components/UI/Notification';
import { getConfig } from '~config/index';
import { Common, CommonFilter, CommonInput, CommonUpdateArg } from '~graphql/_sdk';
import { getSdk } from '~graphql/sdk';
import { useAuthorized } from '~components/Authorized/Authorized';

export class InstructionStore {
  @observable public loading: boolean;
  @observable public modalVisible: boolean;
  @observable public selectedItem?: Common;
  @observable public where: WhereArgs;
  @observable public link: string;
  @observable public instructions: any;
  @observable public title: string;
  public fileList: UploadFile<any>[];

  constructor() {
    runInAction(() => {
      this.title = 'All Of Intructions';
      this.instructions = [];
      this.loading = false;
      this.modalVisible = false;
      this.selectedItem = undefined;
      this.where = { type: 'instruction' };
      this.fileList = [];
      this.link = '';
    });

    reaction(
      () => this.selectedItem,
      (selectedItem) => {
        this.link = get(selectedItem, 'meta.link', '');

        let fileListTmp = [];
        if (this.link) {
          const fileName = this.link.split('/').pop();
          fileListTmp = [
            {
              uid: fileName,
              size: 0,
              name: fileName,
              type: '',
              url: this.link
            }
          ];
        }

        this.setFileList(fileListTmp);
      }
    );
  }

  @action
  setSearchText = debounce((text) => {
    runInAction(() => {
      this.where = { ...this.where, _search: text };
      this.reload();
    });
  }, 500);

  @action
  setModalVisible = (item: Common = undefined) => {
    this.selectedItem = item;
    this.modalVisible = !this.modalVisible;
  };

  @actionAsync
  setFileList = async (fileList: UploadFile[]) => {
    this.fileList = fileList;

    const { minioEndpoint } = await task(getConfig());
    if (fileList[0]) {
      this.link = `${minioEndpoint}/${fileList[0].uid}`;
    } else {
      this.link = '';
    }
  };

  @actionAsync
  create = async (record: CommonInput) => {
    const sdk = await task(getSdk());
    const {
      createCommon: { error, data }
    } = await task(sdk.createCommon({ record }));

    if (error) {
      ErrorNotify(error.code);
    } else {
      SuccessNotify(i18next.t('CREATE'));
      this.selectedItem = data;
      this.reload();
    }
  };

  @actionAsync
  update = async (_id: string, record: CommonUpdateArg) => {
    const sdk = await task(getSdk());
    const {
      updateCommonById: { error, data }
    } = await task(sdk.updateCommonById({ _id, record }));

    if (error) {
      ErrorNotify(error.code);
    } else {
      SuccessNotify(i18next.t('UPDATE'));
      this.selectedItem = data;
      this.reload();
    }
  };

  @actionAsync
  remove = async (_id: string) => {
    const sdk = await task(getSdk());
    const {
      removeCommonById: { error }
    } = await task(sdk.removeCommonById({ _id }));

    if (error) {
      ErrorNotify(error.code);
    } else {
      SuccessNotify(i18next.t('DELETE'));
      this.reload();
    }
  };

  @actionAsync
  public loadCampaignInstruction = async (where) => {
    const sdk = await task(getSdk());
    this.loading = true;
    const { findManyCommon } = await task(
      sdk.findManyCommon({ where: this.where as CommonFilter, sort: where.sort })
    );
    if (isEmpty(this.instructions)) {
      this.instructions = findManyCommon;
    }
    this.loading = false;
    return { findManyCommon };
  };

  // @action
  // public selectTypeInst = (_id, opt) => {
  //   this.where = { ...this.where, meta: { type: _id } };
  //   if (isEmpty(opt)) {
  //     this.title = i18next.t('INSTRUCTIONS');
  //   } else {
  //     this.title = opt.props.children;
  //   }
  //   this.reload();
  // };

  @action
  public selectInstructionTypeInst = (_id, opt) => {
    this.where = { ...this.where, instructionType: _id };
    if (isEmpty(opt)) {
      this.title = i18next.t('INSTRUCTIONS');
    } else {
      this.title = opt.props.children;
    }
    this.reload();
  };

  @action
  public handleGridReady = ({ api }: GridReadyEvent) => {
    this.reload = () => {
      api.onFilterChanged();
    };
  };

  @action
  public reload = () => undefined;
}

export default new InstructionStore();
