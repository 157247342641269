import { throttle, sum } from 'lodash';
import { action, observable, reaction } from 'mobx';
import { actionAsync, task } from 'mobx-utils';
import moment from 'moment';

import { DateArgs, HotspotOnOff } from '~graphql/_sdk';
import { getSdk } from '~graphql/sdk';

export type StoreFilterDate = '7days' | '15days' | '30days';

export class TrafficSummaryStore {
  @observable public newEquipment: number;
  @observable public totalEquipment: number;
  @observable public loading: boolean;
  @observable public filterDate: StoreFilterDate;
  @observable public reportDevice;
  @observable public hotspotOnOff: HotspotOnOff;

  private filter: DateArgs;

  constructor() {
    this.reset();

    reaction(
      () => this.filterDate,
      (date) => {
        switch (date) {
          case '7days':
            this.filter.gte = moment().subtract(7, 'days').startOf('day');
            break;
          case '15days':
            this.filter.gte = moment().subtract(14, 'days').startOf('day');
            break;
          case '30days':
            this.filter.gte = moment().subtract(29, 'days').startOf('day');
            break;
          default:
            return null;
        }
        this.onFilterChange();
      }
    );
  }

  @action setFilterDate = (value: StoreFilterDate) => {
    this.filterDate = value;
  };

  @action
  public reset = () => {
    this.filterDate = '30days';
    this.newEquipment = 0;
    this.totalEquipment = 0;
    this.loading = false;
    this.filter = {
      gte: moment().subtract(29, 'days').startOf('day'),
      lte: moment().endOf('day')
    };
  };

  @actionAsync public getNewEquipment = async () => {
    this.loading = true;
    const sdk = await task(getSdk());
    const { countEquipment } = await task(
      sdk.countEquipment({
        where: {
          _operators: {
            createdAt: {
              gte: this.filter.gte,
              lte: this.filter.lte
            }
          }
        }
      })
    );

    this.newEquipment = countEquipment;

    const countTotalEquipment = await task(sdk.countEquipment());

    this.totalEquipment = countTotalEquipment.countEquipment;
    this.loading = false;
  };

  @actionAsync
  public getHotspotOnOff = async () => {
    this.loading = true;
    const sdk = await task(getSdk());
    const { getHotspotOnOff } = await task(sdk.getHotspotOnOff());
    this.hotspotOnOff = getHotspotOnOff;

    this.loading = false;
  };

  @actionAsync
  public getReportDevice = async (locationId: string = null) => {
    const sdk = await task(getSdk());
    const {
      getDataOSDevice: {
        deviceTypeCount: { Mobile, PC, Tablet },
        osCount: { Android, iOS, other }
      }
    } = await task(sdk.getDataOSDevice({ locationId, dateFilter: this.filter }));

    const totalDevice = sum([Mobile, PC, Tablet]);

    this.reportDevice = {
      totalDevice
    };
  };

  private onFilterChange = throttle(
    () => {
      this.getNewEquipment();
      this.getReportDevice();
    },
    300,
    { leading: false, trailing: true }
  );
}

export default new TrafficSummaryStore();
