import '@ant-design/compatible/assets/index.css';

import { Button, Col, Form, Input, Modal, Row } from 'antd';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LICENSE, MGN, ProfileDefault } from '@source/common';

import { UISelect } from '~components/UI/Select/UISelect';
import UIUpload from '~components/UI/UIUpload';
import { useStore } from '~context/react-context';
import { LocationInput } from '~graphql/_sdk';

import store from './store';

interface FormValues {
  name?: string;
  address?: string;
  categoryId?: string;
  city?: string;
  district?: string;
  licenseName?: string;
  tags?: string[];
  description?: string;
  status?: string;
  urlLogo?: string;
}

const validateMessages = {
  required: '${label} is required!'
};
const BasicForm: FC = observer(() => {
  const { t } = useTranslation();
  const {
    createBasic,
    updateBasic,
    selectedItem,
    modalBasicVisible,
    setModalVisible,
    handleUpload,
    uploadImage,
    uploadVisible,
    setFileLists,
    fileLists,
    urlLogo,
    loading,
    updateCampaignDefault
  } = store;

  const {
    commonDataStore,
    userStore: { currentUser }
  } = useStore();
  const [form] = Form.useForm();

  const nameField = currentUser.ownerType === MGN ? 'name' : 'licenseName';
  const [districts, setDistricts] = useState([]);

  const initialValues: FormValues = {
    name: get(selectedItem, nameField),
    address: get(selectedItem, 'address.text'),
    categoryId: get(selectedItem, 'categoryId'),
    city: get(selectedItem, 'city'),
    district: get(selectedItem, 'district'),
    tags: get(selectedItem, 'tags', undefined),
    description: get(selectedItem, 'description'),
    status: get(selectedItem, 'status')
  };

  const onCityChange = value => {
    form.setFieldsValue({ district: undefined });

    const city = commonDataStore.commonCity.find(item => item.value === value);
    const district = commonDataStore.commonDistrict.filter(item => item.city === value);

    if (city) {
      setDistricts(district);
    } else {
      setDistricts([]);
    }
  };

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [selectedItem]);

  const onSubmit = async (record: FormValues) => {
    if (currentUser.ownerType === LICENSE) {
      record.licenseName = record.name;
      delete record.name;
    }
    const doc: LocationInput = Object.assign(record, {
      address: { text: record.address },
      // status: checked ? COMING_SOON : ACTIVE,
      urlLogo
    });

    if (!selectedItem) {
      await createBasic(doc);
      if (currentUser.profileId === ProfileDefault.PROFILE_CLIENT) { 
        await updateCampaignDefault();
      }
      setModalVisible(null, 'basic');
    } else {
      await updateBasic(selectedItem._id.toString(), doc);
      setModalVisible(null, 'basic');
    }
  };

  const onCancel = () => {
    form.resetFields();
    setModalVisible(null, 'basic');
  };

  const footer = (
    <>
      <Button type="ghost" onClick={() => onCancel()}>
        {t('CLOSE')}
      </Button>
      <Button htmlType="submit" form="basicForm" loading={loading} type="primary">
        {selectedItem ? t('UPDATE') : t('CREATE')}
      </Button>
    </>
  );

  return (
    <Modal
      title={t('BASIC_INFO')}
      onCancel={() => onCancel()}
      closable
      visible={modalBasicVisible}
      footer={footer}
      width={800}
      style={{ top: 20 }}
      okText={selectedItem ? t('UPDATE') : t('CREATE')}
      forceRender
    >
      <Form
        id="basicForm"
        initialValues={initialValues}
        validateMessages={validateMessages}
        onFinish={onSubmit}
        layout="vertical"
        form={form}
      >
        <Row gutter={32}>
          <Col span={12}>
            <Form.Item name="name" label={t('NAME')} rules={[{ required: true }]}>
              <Input placeholder={t('CHOOSE_NAME')} allowClear />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="categoryId" label={t('CATEGORY')} rules={[{ required: true }]}>
              <UISelect
                placeholder={t('CHOOSE_CATEGORY')}
                dataOptions={{
                  data: commonDataStore.categories,
                  valueField: 'value',
                  textField: 'text'
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="city" label={t('CITY')}>
              <UISelect
                placeholder={t('CHOOSE_CITY')}
                dataOptions={{
                  data: commonDataStore.commonCity,
                  valueField: 'value',
                  textField: 'text'
                }}
                onChange={onCityChange}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="district" label={t('DISTRICT')}>
              <UISelect
                placeholder={t('CHOOSE_DISTRICT')}
                dataOptions={{
                  data: districts,
                  valueField: 'value',
                  textField: 'text'
                }}
                // defa={
                //   commonDataStore.commonDistrict.find(e => e.value === initialValues.district)?.text
                // }
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="address" label={t('ADDRESS')}>
              <Input placeholder={t('TYPE_ADDRESS')} allowClear />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="tags" label={t('TAGS')}>
              <UISelect
                placeholder={t('CHOOSE_TAGS')}
                mode="tags"
                allowClear
                dataOptions={{
                  data: commonDataStore.tagsType,
                  valueField: 'value',
                  textField: 'text'
                }}
              />
            </Form.Item>
          </Col>
          {/* <Col>
            <Authorized roles={[ROLE_READ_ALL]}>
              <Form.Item label={t('COMING_SOON')}>
                <Switch
                  checkedChildren={t('YES')}
                  unCheckedChildren={t('NO')}
                  checked={checked}
                  onChange={e => setChecked(e)}
                />
              </Form.Item>
            </Authorized>
          </Col> */}
          {selectedItem && (
            <>
              <Col span={4}>
                <UIUpload
                  width={100}
                  height={100}
                  listType="picture-card"
                  label="Logo Location"
                  onPreview={e => handleUpload(e)}
                  key={selectedItem ? 'logoLocation' : 'uploadLocation'}
                  bannerId={selectedItem ? selectedItem._id : 'locations'}
                  quantity={1}
                  handleChange={setFileLists}
                  defaultFileList={fileLists}
                  name={undefined}
                  accept="image/*"
                />
              </Col>
              <Modal visible={uploadVisible} footer={null} onCancel={handleUpload}>
                <img alt="img" style={{ width: '100%' }} src={uploadImage} />
              </Modal>
              {/* <Col style={{ paddingTop: 60 }} span={9}>
                <Alert message="Image should be 100px x 100px " type="warning" showIcon />
              </Col> */}
            </>
          )}
        </Row>
      </Form>
    </Modal>
  );
});

export default BasicForm;
