/* eslint-disable default-case */
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import AdvanceRender from './AdvanceRender';
import ApproveRender from './ApproveRender';
import DisableRender from './DisableRender';
import ScheduleRender from './ScheduleRender';
import store from './store';
import TimesRender from './TimesRender';
import ViewRender from './ViewRender';

const CampaignsForm: FC = observer(() => {
  const { modalType, visible, setModalVisible } = store;

  switch (modalType) {
    case 'disable':
      return <DisableRender key="disable" visible={visible} setModalVisible={setModalVisible} />;

    case 'reinfo':
      return <AdvanceRender key="reinfo" visible={visible} setModalVisible={setModalVisible} />;

    case 'view':
      return <ViewRender key="view" visible={visible} setModalVisible={setModalVisible} />;

    case 'approve':
      return <ApproveRender key="approve" visible={visible} setModalVisible={setModalVisible} />;

    case 'schedule':
      return <ScheduleRender key="schedule" visible={visible} setModalVisible={setModalVisible} />;

    case 'times':
      return <TimesRender key="times" visible={visible} setModalVisible={setModalVisible} />;
  }
});

export default CampaignsForm;
