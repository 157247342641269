import { Button, Col, Collapse, Row, Select } from 'antd';
import { get } from 'lodash';
import React, { useState } from 'react';
import { Fields } from 'react-awesome-query-builder';
import { SortableHandle } from 'react-sortable-hoc';

/* eslint-disable react/destructuring-assignment */
import { DeleteOutlined, PictureOutlined, UnorderedListOutlined } from '@ant-design/icons';

import QueryBuilder from '~components/UI/QueryBuilder';
import { useStore } from '~context/react-context';

import store from '../store';

const { Panel } = Collapse;
const { Option } = Select;
interface BannerConfigElementInterface {
  index: number;
  fields: Fields;
  removeBanner: Function;
  updateTitleBanner: Function;
  updateQuery: Function;
  updateQueryTree: Function;
  value?: any;
}
const DragHandle = SortableHandle(() => (
  <Col
    span={1}
    style={{
      color: 'rgb(255, 255, 255)',
      border: '1px solid #d9d9d9',
      borderRadius: '5px 0px 0px 5px',
      height: 84,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: '#1890ff'
    }}
  >
    <UnorderedListOutlined />
  </Col>
));

const BannerConfigElement = (props: BannerConfigElementInterface) => {
  const [isValid, setIsValid] = useState(!!get(props, 'value.bannerName', null));
  const [currentSelect, setCurrentSelect] = useState(get(props, 'value.bannerName'));
  const {
    commonDataStore: { banners }
  } = useStore();

  const { preview, campaign } = store;

  const onChange = e => {
    if (!e) {
      setIsValid(false);
    } else {
      setIsValid(true);
      setCurrentSelect(e);
      props.updateTitleBanner(props.index, e);
    }
  };

  const handleViewBanner = () => {
    let selectBanner;
    let value;
    try {
      selectBanner = JSON.parse(currentSelect);
      if (selectBanner._id) {
        value = selectBanner._id;
      }
    } catch (e) {
      value = props.value.bannerId;
    }
    preview(value, campaign.config?.landingPageUrl);
  };

  return (
    <Row style={{ width: '90%', marginBottom: 10 }} key={props.index}>
      <DragHandle />
      <Col span={23}>
        <div
          style={{
            border: '1px solid #d9d9d9',
            borderBottom: 'none'
          }}
        >
          <Button
            disabled={!isValid}
            style={{
              border: 'none',
              width: '10%',
              color: '#1890ff'
            }}
            onClick={() => handleViewBanner()}
          >
            <PictureOutlined />
          </Button>
          <Select
            value={currentSelect}
            className="custom-select"
            style={{ width: '80%' }}
            onChange={e => onChange(e)}
            showSearch
          >
            {banners.length &&
              banners.map(e => (
                <Option value={JSON.stringify(e)} key={e._id}>
                  {e.name}
                </Option>
              ))}
          </Select>
          <Button
            onClick={() => props.removeBanner(props.index)}
            style={{
              border: 'none',
              width: '10%',
              color: 'rgb(223, 91, 91)'
            }}
            icon={<DeleteOutlined />}
          />
        </div>
        <Collapse
          style={{
            borderRadius: '0px 0px 5px 0px'
          }}
        >
          <Panel disabled={!isValid} header="Conditions" key={props.index}>
            <QueryBuilder
              initValue={get(props, 'value.queryTree', null)}
              fields={props.fields}
              getQuery={(e: any) => props.updateQuery(props.index, e)}
              getTree={(e: any) => props.updateQueryTree(props.index, e)}
            />
          </Panel>
        </Collapse>
      </Col>
    </Row>
  );
};

export default BannerConfigElement;
