import { Alert, Card, Checkbox, Col, Input, Result, Row, Spin } from 'antd';
import { isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import store from './store';

const Body = observer(() => {
  const { t } = useTranslation();
  const {
    checkedList,
    filterCommonLocations,
    onCheckAll,
    firstCampaign,
    secondCampaign,
    loading,
    status
  } = store;

  return (
    <>
      {!firstCampaign || !secondCampaign ? (
        <Row style={{ backgroundColor: 'white', height: '100%' }}>
          <Alert
            message="Notice"
            description="Please Select Exactly 2 Locations"
            type="info"
            showIcon
            style={{ margin: 'auto', marginTop: 20 }}
          />
        </Row>
      ) : (
        <Spin spinning={loading} tip="Wait a second......">
          <Row style={{ width: '100%', backgroundColor: 'white' }}>
            <div
              style={{
                width: '100%',
                marginTop: 10,
                borderRadius: 4,
                padding: 20
              }}
            >
              <h3>
                {status === 'force' && (
                  <Alert
                    style={{ marginBottom: 6, width: '50%' }}
                    message="Force is ON: First Campaign always have higher priority than Second Campaign."
                    type="warning"
                  />
                )}
                <strong>{t('Common Locations')}</strong>
                {!isEmpty(filterCommonLocations) && (
                  <Checkbox
                    style={{ marginLeft: 13 }}
                    checked={store.checked}
                    onChange={(e) => onCheckAll(e.target.checked)}
                  >
                    <strong>Check all</strong>
                  </Checkbox>
                )}
                <Input
                  placeholder="Search here...."
                  onChange={(e) => store.handleSearch(e.target.value)}
                  style={{ width: 200 }}
                />
              </h3>
              {filterCommonLocations.length > 0 ? (
                <Checkbox.Group
                  onChange={(e) => store.onChange(e)}
                  value={!store.checked ? checkedList : filterCommonLocations}
                >
                  {filterCommonLocations.map((i) => {
                    return (
                      <Card.Grid key={i.locationId}>
                        <Checkbox style={{ fontSize: 17 }} value={i}>
                          {i.locationName}
                        </Checkbox>
                      </Card.Grid>
                    );
                  })}
                </Checkbox.Group>
              ) : (
                <Result title="Not Found Common Locations" />
              )}
            </div>
          </Row>
        </Spin>
      )}
    </>
  );
});
export default Body;
