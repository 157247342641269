import i18next from 'i18next';
import { action, observable, runInAction } from 'mobx';
import { actionAsync, task } from 'mobx-utils';

import { ErrorNotify, SuccessNotify } from '~components/UI/Notification';
import { FullUserFragment, MutationLoginArgs } from '~graphql/_sdk';
import { getSdk, setAuthToken } from '~graphql/sdk';
import userStore from './userStore';

class AuthStore {
  @observable public token: string;
  @observable public isAuthenticated: boolean;

  constructor() {
    runInAction(() => {
      this.token = window.localStorage.getItem('token') || window.sessionStorage.getItem('token');
      this.isAuthenticated = false;
    });
  }

  @action
  public setAuthenticated = (isAuthenticated: boolean) => {
    this.isAuthenticated = isAuthenticated;
  };

  @actionAsync
  public login = async ({
    email,
    password
  }: MutationLoginArgs): Promise<{ isTFA: boolean; data: FullUserFragment }> => {
    if (!email || !password) {
      ErrorNotify('notify.INVALID_LOGIN_ERR');
    }
    const sdk = await task(getSdk());
    const {
      login: { authToken, data, error }
    } = await task(sdk.login({ email, password }));

    if (!authToken && data) {
      if (data?.twoFactorAuthId) {
        return { isTFA: true, data };
      }
    }

    if (authToken) {
      await task(this.setToken(authToken));
      await task(userStore.getCurrentUser());

      this.setAuthenticated(true);

      SuccessNotify(i18next.t('login:login'));
    }

    if (error) {
      ErrorNotify(error.message ? error.message : i18next.t(`notify.${error.code}`));
    }

    return { isTFA: false, data: null };
  };

  @actionAsync
  public loginTwoFactorAuth = async (code: string, userId: string, password: string) => {
    const sdk = await task(getSdk());

    const {
      loginTwoFactorAuth: { authToken, error, data }
    } = await task(sdk.loginTwoFactorAuth({ code, userId, password }));

    if (authToken) {
      await task(this.setToken(authToken));
      await task(userStore.getCurrentUser());

      this.setAuthenticated(true);

      SuccessNotify(i18next.t('login:login'));

      return true;
    }
    if (error) {
      ErrorNotify(i18next.t(`notify.${error.code}`));
    } else if (authToken) {
      this.setAuthenticated(true);
      userStore.setCurrentUser(data);

      await task(this.setToken(authToken));

      SuccessNotify(i18next.t('login:login'));
    }
    return false;
  };

  @actionAsync
  public logout = async (): Promise<void> => {
    try {
      const sdk = await task(getSdk());
      const { logLogout } = await task(sdk.logLogout({}));
    } catch (error) {
      console.log(error);
    }

    this.setToken(null);
    window.location.reload();
  };

  @action
  public async setToken(token: string) {
    this.token = token;
    return this.handleTokenChange(token);
  }

  private async handleTokenChange(token: string) {
    if (token) {
      const keepLogin = window.localStorage.getItem('keep') === 'true';
      this.setTokenToStorage(token, keepLogin);
    } else {
      this.removeToken();
    }
    return setAuthToken(token);
  }

  private setTokenToStorage(token: string = null, setLocal: boolean) {
    if (setLocal) {
      window.localStorage.setItem('token', token);
      window.sessionStorage.removeItem('token');
    } else {
      window.sessionStorage.setItem('token', token);
      window.localStorage.removeItem('token');
    }
  }

  public removeToken() {
    // if (window.localStorage.getItem('token') || window.sessionStorage.getItem('token')) {
    //   this.setToken(null);
    //   setTimeout(() => {
    //     window.location.reload();
    //   }, 2000);
    // }
    window.localStorage.removeItem('token');
    window.sessionStorage.removeItem('token');
  }
}

export default new AuthStore();
