import { Button, Card, Col, Input, PageHeader, Radio, Row } from 'antd';
import { get, isEmpty } from 'lodash';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { ROLE_CREATE_EQUIPMENT } from '@source/common';

import Authorized from '~components/Authorized/Authorized';
import { FilterConfig } from '~components/Filter/filterType';
import UIFilterHorizontal from '~components/Filter/UIFilterHorizontal';
import { useStore } from '~context/react-context';
import { FullCommonFragment } from '~graphql/_sdk';
import { isMobile } from '~utils/isMobile';

import store from './store';

const EquipmentHeader: FC = observer(() => {
  const { t } = useTranslation();

  const { searchFilter, toggleModal, setFilter } = store;

  const { commonDataStore } = useStore();

  const [modelEquipment, setModelEquipment] = useState([]);
  const [brand, setBrand] = useState([]);
  const [type, setType] = useState([]);

  useEffect(() => {
    commonDataStore.loadAllLocations();
    commonDataStore.loadEquipmentType();
    commonDataStore.loadCommonBrandAp();
  }, []);

  const handleChange = (
    newBrand: string[] = [],
    newType: string[] = [],
    data: FullCommonFragment[]
  ) => {
    let result = toJS(data);

    if (newBrand.length > 0) {
      result = result.filter((item) => newBrand.indexOf(get(item, 'meta.brand')) > -1);
    }

    if (newType.length > 0) {
      result = result.filter((item) => newType.indexOf(get(item, 'meta.type')) > -1);
    }
    return result;
  };

  const changeType = (newType: string[]) => {
    setType(newType);
    const filteredModel = handleChange(brand, newType, commonDataStore.equipmentType);
    setModelEquipment(filteredModel);
  };

  const changeBrand = (newBrand: string[]) => {
    setBrand(newBrand);
    const filteredModel = handleChange(newBrand, type, commonDataStore.equipmentType);
    setModelEquipment(filteredModel);
  };

  const typeOption = [
    {
      key: 'router',
      value: 'router',
      children: 'Router'
    },
    {
      key: 'ap',
      value: 'ap',
      children: 'Access Point'
    }
  ];

  const initFilter: FilterConfig = {
    select: [
      {
        dataOptions: {
          data: commonDataStore.locations,
          valueField: '_id',
          textField: 'name'
        },
        width: 250,
        props: {
          placeholder: t('CHOOSE_LOCATION'),
          allowClear: true,
          mode: 'multiple'
        },
        onChange: (value) =>
          !isEmpty(value)
            ? setFilter({ _operators: { locationId: { in: value } } })
            : setFilter({ _operators: { locationId: null } }),
        filterField: 'locationId',
        label: t('LOCATION')
      },

      {
        dataOptions: {
          data: commonDataStore.brandAp,
          valueField: 'value',
          textField: 'text'
        },
        props: {
          placeholder: t('CHOOSE_BRAND'),
          allowClear: true,
          mode: 'multiple'
        },
        onChange: changeBrand,
        filterField: 'brand',
        label: t('BRAND')
      },
      {
        dataOptions: {
          data: typeOption,

          valueField: 'value',
          textField: 'children'
        },

        props: {
          placeholder: t('CHOOSE_TYPE'),
          allowClear: true,
          mode: 'multiple'
        },
        onChange: changeType,
        filterField: 'type',
        label: t('TYPE')
      },
      {
        dataOptions: {
          data: modelEquipment,
          valueField: 'value',
          textField: 'text'
        },
        props: {
          placeholder: t('CHOOSE_MODEL'),
          allowClear: true,
          mode: 'multiple'
        },
        onChange: (value) =>
          !isEmpty(value)
            ? setFilter({ _operators: { model: { in: value } } })
            : setFilter({ _operators: { model: null } }),
        filterField: 'model',
        label: t('MODEL')
      }
    ]
  };
  return (
    <Card size="small" className="card-filter">
      <Row gutter={8} justify="end">
        <Col>
          <UIFilterHorizontal key="filter" config={initFilter} onFilterChanged={setFilter} />
        </Col>
        <Col>
          <Input
            key="search"
            suffix={<SearchOutlined />}
            placeholder={t('SEARCH')}
            allowClear
            onChange={e => searchFilter(e.target.value)}
            style={{ width: '200px' }}
          />
        </Col>
        <Col>
          <Authorized roles={[ROLE_CREATE_EQUIPMENT]} key="create">
            <Button type="primary" icon={<PlusOutlined />} onClick={() => toggleModal()}>
              {isMobile() ? '' : t('CREATE')}
            </Button>
          </Authorized>
        </Col>
      </Row>
    </Card>
  );
});

export default EquipmentHeader;
