import { action, observable, runInAction } from 'mobx';

// configure({ enforceActions: 'always' });
class CommonStore {
  @observable public appLoaded: boolean;

  constructor() {
    runInAction(() => {
      this.appLoaded = false;
    });
  }

  @action
  public setAppLoaded() {
    this.appLoaded = true;
  }
}

export default new CommonStore();
