import React, { FC } from 'react';

import Form from './Form';
import Grid from './Grid';
import Header from './Header';

const ImageScreen: FC = () => {
  return (
    <>
      <Header />
      <Grid />
      <Form />
    </>
  );
};

export default ImageScreen;
