// import { Tag } from 'antd';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React from 'react';

import { ICellRendererParams } from '@ag-grid-community/core';

import store from '~common-stores/commonDataStore';

@observer
export default class NameRenderer extends React.Component<ICellRendererParams> {
  public render() {
    const { locationCategoryMap } = store;
    const { data, value } = this.props;

    const category = get(data, 'categoryId', '');
    const className = `fa fa-fw ${locationCategoryMap[category]}`;
    return (
      <span>
        {value ? (
          <>
            <i className={className} /> -{' '}
            {/* <Tag>
              {isString(locationCategoryGroup[category])
                ? locationCategoryGroup[category].split(' ')[1]
                : '?'}
            </Tag> */}
            {value}
          </>
        ) : (
          ''
        )}
      </span>
    );
  }
}
