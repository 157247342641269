import '@ant-design/compatible/assets/index.css';

import { Button } from 'antd';
import Input from 'antd/lib/input';
import i18next from 'i18next';
import { isEqual } from 'lodash';
import { observer } from 'mobx-react';
import React from 'react';
import useForm from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';

import { Form } from '@ant-design/compatible';
// import store from './store';
import { LockOutlined } from '@ant-design/icons';

import { ErrorNotify } from '~components/UI/Notification';
import UIField from '~components/UI/UIField';

import { form_login_fb, login_button } from './css';
import store from './store';

const { Item } = Form;
interface FormValues {
  newPassword: string;
  verifyPassword: string;
}

const ForgotPasswordScreen: React.FC = observer(() => {
  const { t } = useTranslation();
  const { restorePassword } = store;
  const history = useHistory();
  const { id } = useParams();
  const validationSchema = yup.object().shape<FormValues>({
    newPassword: yup.string().required(i18next.t('NEW_PASSWORD_IS_REQUIRED')),
    verifyPassword: yup.string().required(i18next.t('VERIFY_NEW_PASSWORD_IS_REQUIRED'))
  });

  const formProps = useForm<FormValues>({ validationSchema });

  const {
    handleSubmit,
    // reset,
    formState: { isSubmitting }
  } = formProps;

  const onSubmit = async ({ newPassword, verifyPassword }) => {
    if (!isEqual(newPassword, verifyPassword)) {
      ErrorNotify(t('NOTI_VERIFY_EMAIL'));
    } else {
      const result = await restorePassword(id, newPassword, verifyPassword);
      // eslint-disable-next-line no-unused-expressions
      result && history.push('/login');
    }
  };

  return (
    <div style={{ margin: 'auto', textAlign: 'center' }}>
      <h1>{t('RESTORE_PASSWORD')}</h1>
      <Form layout="vertical" onSubmit={handleSubmit(onSubmit)}>
        <UIField
          {...formProps}
          name="newPassword"
          label={t('NEW_PASSWORD')}
          fieldProps={{
            name: 'newPassword',
            placeholder: t('INPUT_NEW_PASSWORD'),
            type: 'password',
            prefix: <LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
          }}
          fieldComponent={Input}
        />
        <UIField
          {...formProps}
          name="verifyPassword"
          label={t('VERIFY_NEW_PASSWORD')}
          fieldProps={{
            name: 'verifyPassword',
            type: 'password',
            placeholder: t('INPUT_NEW_PASSWORDD'),
            prefix: <LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
          }}
          fieldComponent={Input}
        />
        <Item>
          <Button htmlType="submit" type="primary" loading={isSubmitting} className={login_button}>
            {t('CONFIRM')}
          </Button>
          <div className={form_login_fb}>
            {t('SUCCESS_RECOVERY')} ?<Link to="/login"> {t('LOGIN')}</Link>
          </div>
        </Item>
      </Form>
    </div>
  );
});

export default ForgotPasswordScreen;
