import { Button, Input, PageHeader } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { AUTHORIZED_ROLES_CREATE_PROFILE } from '@source/common';

import Authorized from '~components/Authorized/Authorized';

import store from './store';

const ProfileHeader = () => {
  const { t } = useTranslation();
  const { setSearch, setIsShowForm } = store;

  return (
    <PageHeader
      style={{ background: '#fff', marginBottom: 0, padding: '16px' }}
      title=""
      extra={[
        <Input
          key="search"
          suffix={<SearchOutlined />}
          placeholder={t('SEARCH')}
          allowClear
          onChange={e => setSearch(e.target.value)}
          style={{ width: '200px' }}
        />,
        <Authorized roles={AUTHORIZED_ROLES_CREATE_PROFILE} key="create">
          <Button type="primary" icon={<PlusOutlined />} onClick={() => setIsShowForm(true)}>
            {t('CREATE')}
          </Button>
        </Authorized>
      ]}
    />
  );
};

export default ProfileHeader;
