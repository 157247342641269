import axios from 'axios';

export type AppConfig = {
  graphQLEndpoint: string;
  restApiEndpoint: string;
  minioEndpoint: string;
  recapchaSiteKey: string;
  isTestEnv: boolean;
}

class Config {
  private config: Promise<AppConfig>;

  constructor() {
    this.config = this._get();
  }

  private async _get() : Promise<AppConfig> {
    return axios.get<AppConfig>(`${window.location.origin}/config.json`).then(({ data }) => {
      return data;
    });
  }

  get(): Promise<AppConfig> {
    return this.config;
  }
}

const config = new Config();
const getConfig = config.get.bind(config);

export { getConfig };
