import { Alert, Checkbox } from 'antd';
import i18next from 'i18next';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import store from '../store';
import RenderCustomFields from './RenderCustomFields';

const { updateCustomField, updateFields } = store;

const options = [
  { label: i18next.t('NAME'), value: 'name' },
  { label: i18next.t('PHONE'), value: 'phone' },
  { label: i18next.t('CALL_STATUS'), value: 'phoneStatus' },
  { label: i18next.t('EMAIL'), value: 'email' },
  { label: i18next.t('AGE'), value: 'age' },
  { label: i18next.t('GENDER'), value: 'gender' },
  { label: i18next.t('INCOME'), value: 'income' },
  { label: i18next.t('MARRIAGE'), value: 'marital' }
];

const CRMConfig = observer(() => {
  const { campaign } = store;
  const { t } = useTranslation();
  const onChange = e => {
    updateFields(e);
  };

  return (
    <div style={{ padding: '16px 24px 16px 0px' }}>
      <Alert message="User Data" description={t('ALERT_CRMCONFIG')} type="info" showIcon />
      <div
        style={{
          width: '100%',
          marginTop: 10,
          border: '1px solid #d9d9d9',
          borderRadius: 4,
          padding: 20
        }}
      >
        <h3>{t('DEFAULT_FIELDS')}</h3>
        <div>
          <Checkbox.Group
            defaultValue={
              get(campaign, 'config.crm', {}) && Object.values(get(campaign, 'config.crm', {}))
            }
            onChange={onChange}
            options={options}
          />
        </div>
      </div>
      <div
        style={{
          width: '100%',
          marginTop: 10,
          display: 'flex',
          justifyContent: 'space-between',
          flexFlow: 'wrap'
        }}
      >
        {store.customFields.map((e, index) => (
          <RenderCustomFields
            index={index}
            record={e}
            onChange={updateCustomField}
            key={String(index)}
          />
        ))}
      </div>
    </div>
  );
});

export default CRMConfig;
