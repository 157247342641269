import { action, observable, runInAction } from 'mobx';

import { initLayout, collapseLayout } from '~components/Layout/css';

class LayoutStore {
  @observable public layout: any;
  @observable public drawerVisible: boolean;
  @observable public collapsedSideMenu: boolean;

  constructor() {
    runInAction(() => {
      this.layout = initLayout;
      this.drawerVisible = false;
      this.collapsedSideMenu = false;
    });
  }

  @action
  public toggleDrawer = () => {
    this.drawerVisible = !this.drawerVisible;
  };

  @action
  public toggleCollapsedSideMenu = () => {
    this.collapsedSideMenu = !this.collapsedSideMenu;
    
    if (this.collapsedSideMenu) {
      this.layout = collapseLayout;
    } else {
      this.layout = initLayout;
    }
  };
}

export default new LayoutStore();
