import Header from './Header';
import Tab from './Tab';

import React, { FC } from 'react';
import LoginGrid from './LoginGrid';
import { Layout } from 'antd';

const { Content } = Layout;

const LogsScreen: FC = () => {
  return (
    <>
      <Header />
      {/* <Content style={{ height: 'calc(100% - 80px)', background: '#fff' }}>
      </Content> */}
      <Tab />

    </>
  );
};

export default LogsScreen;
