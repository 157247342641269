import { Tag, Typography } from 'antd';
import { observer } from 'mobx-react';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactResizeDetector from 'react-resize-detector';
import { useHistory } from 'react-router-dom';

import { ColDef } from '@ag-grid-community/core';
import { ADVERTISING, FINISHED, format, PARTNER, RUNNING, STOPPED } from '@source/common';

import AgGridServer from '~components/UI/AgGrid/AgGridServer';
import { useStore } from '~context/react-context';
import { useGraphQLSDK } from '~graphql/GraphQLSDKProvider';
import { stringifyQuery } from '~utils/query-string';

import { name } from '../css';
import store from '../store';

const { Text } = Typography;

const Grid: FC = observer(() => {
  const { handleGridReady, setCampaign, filter } = store;
  const sdk = useGraphQLSDK();
  const { commonDataStore } = useStore();
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    commonDataStore.loadCampaignType();
  }, []);

  const generateStatusTag = (status: string) => {
    let color: string;
    switch (status) {
      case RUNNING:
        color = 'green';
        break;
      case STOPPED:
        color = 'error';
        break;
      case FINISHED:
        color = 'blue';
        break;
      default:
        color = 'default';
    }

    return <Tag color={color}>{status.toUpperCase()}</Tag>;
  };

  const generateTypeTag = (type: string) => {
    let color: string;
    switch (type) {
      case ADVERTISING:
        color = '#87d068';
        break;
      case PARTNER:
        color = '#2db7f5';
        break;
      default:
        color = '#737373';
    }

    const result = commonDataStore.campaignType.find(item => item.value === type)?.text || '-';

    return <Tag color={color}>{result.toUpperCase()}</Tag>;
  };

  const columnDefs: ColDef[] = [
    {
      headerName: t('NO'),
      cellClass: 'text-center',
      minWidth: 50,
      maxWidth: 50,
      cellRenderer: ({ rowIndex }) => {
        return rowIndex + 1;
      }
    },
    {
      headerName: t('CAMPAIGN_NAME'),
      field: 'name',
      minWidth: 250,
      cellRendererFramework: ({ value }) => {
        return <span className={name}>{value}</span>;
      }
    },
    {
      headerName: t('TYPE'),
      field: 'type',
      minWidth: 140,
      maxWidth: 150,
      cellRendererFramework: ({ value }) => {
        return value ? generateTypeTag(value) : '';
      }
    },
    {
      headerName: t('STATUS'),
      field: 'status',
      minWidth: 130,
      maxWidth: 150,
      cellRendererFramework: ({ value }) => {
        return generateStatusTag(value);
      }
    },
    {
      headerName: t('SCHEDULE'),
      field: 'scheduleFrom',
      maxWidth: 250,
      minWidth: 250,
      cellRendererFramework: ({ data }) => {
        return (
          <>
            <Text keyboard>{format(data.scheduleFrom, 'DD-MM-YYYY')}</Text> ->{' '}
            <Text keyboard>
              {data.scheduleTo ? format(data.scheduleTo, 'DD-MM-YYYY') : 'Unlimited'}
            </Text>
          </>
        );
      }
    },
    {
      headerName: 'KPI',
      field: 'stats.kpi',
      minWidth: 100,
      maxWidth: 100,
      cellClass: 'text-right',
      cellRendererFramework: ({ value }) => (
        <Text code>{value?.toLocaleString() || 'Unlimited'}</Text>
      )
    },
    {
      headerName: t('CLICK'),
      field: 'stats.click',
      minWidth: 100,
      maxWidth: 100,
      cellClass: 'text-right',
      cellRendererFramework: ({ value }) => (
        <Text code>{value?.toLocaleString() || 'Unlimited'}</Text>
      )
    },
    {
      field: 'createdAt',
      sort: 'desc',
      sortable: false,
      hide: true
    },
    {
      headerName: t('COMPLETED'),
      field: 'stats.kpiCompleted',
      minWidth: 100,
      maxWidth: 100,
      cellClass: 'text-right',
      cellRendererFramework: ({ value }) => {
        let text: string;
        if (!value) {
          text = '0%';
        } else {
          text = value > 100 ? '100%' : `${value}%`;
        }
        return <Text code>{text}</Text>;
      }
    }
  ];

  const onRowClicked = e => {
    const query = stringifyQuery({ id: e.data._id });
    history.push({ search: query });
    setCampaign(e.data._id);
  };

  return (
    <ReactResizeDetector handleHeight>
      {({ height }) => {
        return (
          <div style={{ height: 'calc(100vh - 300px)' }}>
            <AgGridServer
              options={{
                columnDefs,
                onRowClicked,
                rowModelType: 'serverSide',
                rowSelection: 'single'
              }}
              onQuery={sdk.findManyReportCampaign}
              queryKey="findManyCampaign"
              onCountQuery={sdk.countCampaign}
              countQueryKey="countCampaign"
              onGridReady={handleGridReady}
              height={height}
              where={filter}
            />
          </div>
        );
      }}
    </ReactResizeDetector>
  );
});

export default Grid;
