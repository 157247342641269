import { get, merge } from 'lodash';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ReactResizeDetector from 'react-resize-detector';

import { RANGE_DAY_FORMAT } from '@source/common';

import HighChart from '~components/UI/HighChart/HighChart';
import { format } from '~utils/formatDate';

import store from '../store';

const Chart: FC = observer(() => {
  const { chartData } = store;

  const getItem = (key, isDate = false) => {
    const realData = (chartData || []).reduce((all, item) => {
      all = [...all, isDate ? format(get(item, key, []), RANGE_DAY_FORMAT) : get(item, key, [])];

      return all;
    }, []);
    return realData || null;
  };

  const { t } = useTranslation();

  const options: Highcharts.Options = {
    title: {
      text: ''
    },
    series: [
      {
        name: t('IMPRESSION'),
        color: '#0078D4',
        type: 'column',
        data: getItem('impression')
      },
      {
        name: t('CLICK'),
        color: '#FFA242',
        type: 'column',
        data: getItem('click')
      },
      {
        name: t('CTR'),
        color: '#F75A5A',
        zIndex: 1,
        type: 'spline',
        data: getItem('ctr'),
        yAxis: 1,
        tooltip: {
          valueSuffix: ' %'
        }
      }
    ],
    xAxis: {
      categories: getItem('date', true),
      title: {
        text: null
      },
      tickInterval: 2
    },
    yAxis: [
      {
        min: 0,
        title: {
          text: t('TRAFFIC'),
          align: 'middle'
        },
        labels: {
          overflow: 'justify'
        }
      },
      {
        title: {
          text: t('CTR_%'),
          align: 'middle'
        },
        opposite: true,
        labels: {
          overflow: 'justify'
        },
        min: 0,
        max: 100
      }
    ],
    tooltip: {
      shared: true
    },
    credits: {
      enabled: false
    }
  };
  return (
    <ReactResizeDetector handleHeight>
      {({ height }) => {
        merge(options, { chart: { height: 500 } });
        return <HighChart options={options} />;
      }}
    </ReactResizeDetector>
  );
});
export default Chart;
