import { Drawer, Layout } from 'antd';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { withRouter } from 'react-router-dom';

import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';

import SideMenu from '~components/Menu/Menu';
import { useStore } from '~context/react-context';
import { isMobile } from '~utils/isMobile';

import { logo_sider } from './css';

const { Sider } = Layout;
const Sidebar: FC = observer(() => {
  let logo = '';
  if (window.location.href.indexOf('meganet') > -1) {
    logo = require('~assets/images/ads-platform.png');
  } else {
    logo = require('~assets/images/logo-no-brand.png');
  }

  const { layoutStore } = useStore();
  const { collapsedSideMenu, toggleCollapsedSideMenu, drawerVisible, toggleDrawer } = layoutStore;
  const name = <img style={{ maxWidth: '80%', height: 'auto' }} src={logo} alt="" />;

  const logoMobile = (
    <img
      style={{ maxWidth: '60%', maxHeight: '60%' }}
      src={require('~assets/images/logo_dark.png')}
      alt=""
    />
  );

  const Trigger = (
    <div style={{ height: 47 }}>
      {collapsedSideMenu ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
    </div>
  );

  const hide = window.location.href.indexOf('meganet') > -1 ? 'flex' : 'none';

  return (
    <>
      {!isMobile() ? (
        <Sider
          trigger={Trigger}
          collapsible
          collapsed={collapsedSideMenu}
          onCollapse={toggleCollapsedSideMenu}
        >
          {collapsedSideMenu ? (
            <div className={logo_sider} style={{ display: hide }}>
              {logoMobile}
            </div>
          ) : (
            <div className={logo_sider}>{name}</div>
          )}

          <div style={{ height: 'calc(100% - 64px)', overflow: 'hidden auto' }}>
            <SideMenu />
          </div>
        </Sider>
      ) : (
        <></>
      )}
      <Drawer
        visible={drawerVisible}
        placement="left"
        onClose={toggleDrawer}
        closable={false}
        width={256}
        bodyStyle={{ padding: 0 }}
      >
        <div className={logo_sider}>{name}</div>
        <SideMenu />
      </Drawer>
    </>
  );
});

export default withRouter(Sidebar);
