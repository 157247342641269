import { Tag } from 'antd';
import * as CSS from 'csstype';
import { toJS } from 'mobx';
import React, { useEffect, useState } from 'react';

import { format } from '~utils/formatDate';

interface GetFilterWhereProps {
  where: object;
  style?: CSS.Properties<string | number>;
}
const colors = ['geekblue', 'purple', 'magenta', 'volcano', 'orange', 'gold', 'green'];

const GetFilterWhere = (props: GetFilterWhereProps) => {
  const { where, style } = props;
  const [whereValue, setWhereValue] = useState([]);

  const getWhereValue = (w: any, result: Array<any>) => {
    Object.keys(w).map((keys) => {
      const obj = {};
      if (!w[keys]) return;
      switch (true) {
        case Array.isArray(w[keys]) && keys === 'OR':
          w[keys].forEach((e) => {
            getWhereValue(e, result);
          });
          break;
        case Array.isArray(w[keys]):
          result.push({ arr: w[keys] });
          break;
        case typeof w[keys] === 'object' && !('gte' in w[keys]):
          getWhereValue(w[keys], result);
          break;
        case typeof w[keys] === 'object' && 'gte' in w[keys]:
          obj[keys] = [w[keys].gte, w[keys].lte];
          result.push(obj);
          break;
        case typeof w[keys] === 'string' && keys !== '_search':
          result.push({ text: w[keys] });
          break;
        default:
          break;
      }
      return result;
    });
  };

  useEffect(() => {
    const result = [];
    getWhereValue(toJS(where), result);
    setWhereValue(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [where]);

  const toUpperCaseFirstChar = (value: string): string => {
    // return value.charAt(0).toUpperCase() + value.slice(1);
  };

  const renderValue = (value, index) => {
    let values;
    if ('arr' in value) {
      if (value.arr.length <= 1) {
        values = toUpperCaseFirstChar(value.arr[0]);
      } else {
        let result = '';
        for (let i = 0; i < value.arr.length - 1; i += 1) {
          result += `${toUpperCaseFirstChar(value.arr[i])} | `;
        }
        result += `${toUpperCaseFirstChar(value.arr[value.arr.length - 1])}`;
        values = result;
      }
    } else if ('text' in value) {
      values = toUpperCaseFirstChar(value.text);
    } else if ('schedule' in value) {
      values = `${toUpperCaseFirstChar(Object.keys(value)[0])} | ${format(
        value.schedule[0],
        'DD/MM/YYYY'
      )} - ${format(value.schedule[1], 'DD/MM/YYYY')}`;
    } else {
      const keys = Object.keys(value)[0];
      values = `${toUpperCaseFirstChar(keys)} | ${format(value[keys][0], 'DD/MM/YYYY')} - ${format(
        value[keys][1],
        'DD/MM/YYYY'
      )}`;
    }
    return (
      <Tag color={colors[index]} key={index}>
        {values}
      </Tag>
    );
  };

  return whereValue.length > 0 ? (
    <div style={{ ...style, padding: 5 }}>
      {whereValue.map((value, index) => renderValue(value, index))}
    </div>
  ) : null;
};

export default GetFilterWhere;
