import { Button, Card, Checkbox, Col, Collapse, Divider, Input, List, Row, Switch } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PlusOutlined } from '@ant-design/icons';

import store, { CampaignLocations, LocationGroup } from '../store';
import LocationGroupElement from './LocationGoupElement';

const { Search } = Input;
const CheckboxGroup = Checkbox.Group;

const LocationGroupConfig = observer(() => {
  const { t } = useTranslation();
  const {
    addLocationGroup,
    locationGroups,
    setupAddLocationMode,
    addSelectedLocations,
    clearSelectedLocations,
    handleAddLocations,
    setCheckAll,
    isCheckAll,
    handleSearchOnchange,
    setIsShowGroup,
    isShowGroup,
    addLocationsMode,
    checkboxLocations,
    selectedLocations
  } = store;

  const handleAddLocationGroup = () => {
    const newLocationGroup: LocationGroup = {
      groupName: '',
      locations: []
    };
    addLocationGroup(newLocationGroup);
  };

  return (
    <Row gutter={16}>
      <Col span={10}>
        <Card size="small" title={t('AVAILABLE_LOCATIONS')}>
          <Search onChange={e => handleSearchOnchange(e.target.value)} />
          <div
            style={{
              width: '100%',
              justifyContent: 'flex-end',
              padding: 8,
              display: addLocationsMode ? 'flex' : 'none'
            }}
          >
            <h3 style={{ marginRight: 10 }}>{t('ALL')}</h3>
            <Checkbox
              checked={isCheckAll}
              onChange={e => {
                setCheckAll(e.target.checked);
              }}
            />
          </div>
          <CheckboxGroup
            style={{
              width: '100%'
            }}
            value={selectedLocations as CheckboxValueType[]}
            onChange={e => addSelectedLocations(e as CampaignLocations[])}
          >
            <List
              size="small"
              style={{
                maxHeight: 350,
                overflow: 'scroll'
              }}
              dataSource={checkboxLocations}
              renderItem={item => (
                <List.Item actions={addLocationsMode ? [<Checkbox value={item} />] : []}>
                  {item.locationName}
                </List.Item>
              )}
            />
          </CheckboxGroup>
          <div
            style={{
              marginTop: 10,
              width: '100%',
              display: addLocationsMode ? 'flex' : 'none',
              justifyContent: 'flex-end',
              padding: 8
            }}
          >
            <Button
              style={{ marginRight: 10 }}
              danger
              onClick={() => {
                setupAddLocationMode(false, -1);
                clearSelectedLocations();
              }}
            >
              {t('CANCEL')}
            </Button>
            <Button onClick={() => handleAddLocations()} type="primary">
              {t('ADD')}
            </Button>
          </div>
        </Card>
      </Col>
      <Col span={14}>
        <Card
          size="small"
          title={t('LOCATION_GROUPS')}
          extra={
            <Switch
              style={{ float: 'right', marginTop: 3 }}
              size="small"
              checked={isShowGroup}
              onChange={setIsShowGroup}
            />
          }
        >
          <div style={{ width: '100%' }}>
            {locationGroups.map((e, index) => (
              <LocationGroupElement index={index} element={e} key={String(index)} />
            ))}

            <Button
              type="dashed"
              onClick={() => handleAddLocationGroup()}
              style={{ width: '100%' }}
              disabled={addLocationsMode}
            >
              <PlusOutlined /> {t('ADD_GROUP')}
            </Button>
          </div>
        </Card>
      </Col>
    </Row>
  );
});

export default LocationGroupConfig;
