import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ReactResizeDetector from 'react-resize-detector';

import { ColDef, ColGroupDef } from '@ag-grid-community/core';
import {
  AndroidOutlined,
  AppleOutlined,
  LaptopOutlined,
  MobileOutlined,
  QqOutlined,
  QuestionOutlined,
  TabletOutlined,
  WindowsOutlined
} from '@ant-design/icons';
import { format } from '@source/common/build/utils/formatDate';

import AgGridServer from '~components/UI/AgGrid/AgGridServer';
import { upperCaseFirstLetter } from '~utils/upperCaseFirstLetter';

import store from '../store';

const Mac: FC = observer(() => {
  const {
    handleGridMacReady,
    getReportCampaignSurvey,
    getTotalReportCampaignSurvey,
    campaignInfo
  } = store;

  const { t } = useTranslation();

  const columnDefs: ColDef[] | ColGroupDef[] = [
    {
      headerName: t('NO'),
      cellClass: 'text-center',
      minWidth: 50,
      maxWidth: 50,
      sortable: false,
      pinned: 'left',
      cellRenderer: ({ rowIndex }) => {
        return rowIndex + 1;
      }
    },
    {
      headerName: t('MAC'),
      field: 'mac',
      maxWidth: 180,
      minWidth: 180,
      pinned: 'left',
      sortable: false
    },
    {
      headerName: '',
      field: 'ua.os',
      minWidth: 50,
      maxWidth: 50,
      sortable: false,
      cellClass: 'text-center',
      cellRendererFramework: ({ value }) => {
        switch (value) {
          case 'Android':
            return <AndroidOutlined style={{ color: 'green' }} />;
          case 'Windows':
            return <WindowsOutlined style={{ color: 'blue' }} />;
          case 'Mac OS X':
          case 'iOS':
            return <AppleOutlined style={{ color: '#9a9695' }} />;
          case 'Linux':
            return <QqOutlined />;

          default:
            return <QuestionOutlined />;
        }
      }
    },
    {
      headerName: t('VERSION'),
      field: 'ua.os_major',
      minWidth: 70,
      maxWidth: 70,
      cellClass: 'text-center',
      hide: true,
      sortable: false
    },
    {
      headerName: '',
      field: 'ua.device',
      minWidth: 50,
      maxWidth: 50,
      sortable: false,
      cellClass: 'text-center',
      cellRendererFramework: ({ value }) => {
        switch (value) {
          case 'PC':
            return <LaptopOutlined />;
          case 'Mobile':
            return <MobileOutlined />;
          case 'Tablet':
            return <TabletOutlined />;
          default:
            return <QuestionOutlined />;
        }
      }
    },
    {
      headerName: t('DEVICE_NAME'),
      field: 'ua.deviceName',
      minWidth: 150,
      maxWidth: 200,
      sortable: false,
      // cellRendererFramework: ({ value, data }) => {
      //   if (!value) {
      //     return (
      //       <Text type="warning">
      //         {data.ua?.device_name}
      //       </Text>
      //     );
      //   }
      //   return <Text>{value}</Text>;
      // },
      valueGetter: (params) => {
        if (!params.data?.ua?.deviceName) return `.${params.data?.ua?.device_name}`;
        return params.data?.ua?.deviceName;
      }
    },
    {
      headerName: t('LOCATION'),
      field: 'meta.locationName',
      minWidth: 200,
      // maxWidth: 300,
      sortable: false
    },
    {
      headerName: t('BANNER'),
      field: 'meta.bannerName',
      minWidth: 200,
      maxWidth: 300,
      sortable: false
    },
    {
      headerName: t('CLICK'),
      field: 'meta.count',
      minWidth: 70,
      maxWidth: 70,
      sortable: false,
      cellClass: 'text-center'
    },
    {
      headerName: t('FIRST_LOGIN'),
      field: 'createdAt',
      minWidth: 120,
      maxWidth: 130,
      sortable: false,
      cellClass: 'text-right',
      cellRendererFramework: ({ value }) => {
        return <>{format(value, 'DD-MM, HH:mm')}</>;
      }
    },
    {
      headerName: t('LAST_LOGIN'),
      field: 'updatedAt',
      minWidth: 120,
      maxWidth: 130,
      sortable: false,
      cellClass: 'text-right',
      cellRendererFramework: ({ value }) => {
        return <>{format(value, 'DD-MM, HH:mm')}</>;
      }
    }
  ];

  if (get(campaignInfo, 'config.crm')) {
    const keys = Object.keys(campaignInfo.config.crm);
    keys.forEach((k) => {
      const value = campaignInfo.config.crm[k];

      let fieldType;

      if (k.includes('field')) {
        fieldType = 'survey';
      } else {
        fieldType = 'audience';
      }

      fieldType = `${fieldType}.${k}`;
      const cellRendererFramework = ({ val }) => {
        if (val === 'female') {
          return <i className="fas fa-female" style={{ color: 'pink' }} />;
        }
        if (val === 'male') {
          return <i className="fas fa-male" style={{ color: 'blue' }} />;
        }
        return '-';
      };

      if (value === 'gender') {
        columnDefs.splice(5, 0, {
          headerName: upperCaseFirstLetter(value),
          field: fieldType,
          minWidth: 80,
          maxWidth: 80,
          cellClass: 'text-center',
          sortable: false,
          cellRendererFramework
        });
      } else {
        columnDefs.push({
          headerName: upperCaseFirstLetter(value),
          field: fieldType,
          minWidth: 200,
          maxWidth: 200,
          sortable: false
        });
      }
    });
  }

  return (
    <ReactResizeDetector handleHeight>
      {({ height }) => {
        return (
          <div style={{ height: 'calc(100vh - 270px)' }}>
            <AgGridServer
              height={height - 10}
              options={{ columnDefs }}
              onQuery={getReportCampaignSurvey}
              queryKey="getReportCampaignSurvey"
              onCountQuery={getTotalReportCampaignSurvey}
              countQueryKey="countReportCampaignSurvey"
              onGridReady={handleGridMacReady}
              where={{}}
              showWhere
            />
          </div>
        );
      }}
    </ReactResizeDetector>
  );
});
export default Mac;
