import { Button, Dropdown, Menu, Modal } from 'antd';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ReactResizeDetector from 'react-resize-detector';

import { ColDef } from '@ag-grid-community/core';
import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  FilePdfOutlined,
  YoutubeOutlined
} from '@ant-design/icons';
import {
  AUTHORIZED_ROLES_DELETE_INTRUCTION,
  AUTHORIZED_ROLES_UPDATE_INTRUCTION,
  ROLE_DELETE_INTRUCTION,
  ROLE_UPDATE_INSTRUCTION
} from '@source/common';

import Authorized from '~components/Authorized/Authorized';
import AgGridServer from '~components/UI/AgGrid/AgGridServer';
import { useGraphQLSDK } from '~graphql/GraphQLSDKProvider';

import { DATE_FORMAT, DATE_TIME_FORMAT, format } from '~utils/formatDate';
import store from './store';

const { confirm } = Modal;

const InstructionGrid: FC = observer(() => {
  const { t } = useTranslation();
  const sdk = useGraphQLSDK();

  const { handleGridReady, where, remove, loadCampaignInstruction } = store;

  const newWindow = (url) => setTimeout(() => window.open(`${url}?t=${Date.now()}`, '_blank'), 200);
  const showDeleteConfirm = (value, text) => {
    confirm({
      title: (
        <div>
          {t('ARE_YOU_SURE_DELETE')}: <b>{text}</b>
        </div>
      ),
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        remove(value);
      }
    });
  };
  const columnDefs: ColDef[] = [
    {
      headerName: t('NO'),
      field: '_id',
      sortable: false,
      minWidth: 50,
      maxWidth: 50,
      cellRenderer: ({ rowIndex }) => rowIndex + 1
    },
    {
      headerName: '',
      field: '_id',
      minWidth: 220,
      // maxWidth: 180,
      cellRendererFramework: ({ value, data }) => {
        const fileUrl = get(data, 'meta.link');
        const videoUrl = get(data, 'meta.video');

        return (
          <Button.Group size="small">
            <Authorized roles={[ROLE_UPDATE_INSTRUCTION]}>
              <Button
                icon={<EditOutlined />}
                type="primary"
                onClick={() => store.setModalVisible(data)}
              />
            </Authorized>
            {fileUrl && (
              <Button key="file" style={{ color: 'green' }} onClick={() => newWindow(fileUrl)}>
                <FilePdfOutlined />
                {t('FILE')}
              </Button>
            )}
            {videoUrl && (
              <Button key="video" style={{ color: 'blue' }} onClick={() => newWindow(videoUrl)}>
                <YoutubeOutlined />
                {t('VIDEO')}
              </Button>
            )}
            <Authorized roles={[ROLE_DELETE_INTRUCTION]}>
              <Button
                key="delete"
                style={{ color: 'red' }}
                onClick={() => showDeleteConfirm(value, data.text)}
                className="ant-dropdown-menu-item"
              >
                <DeleteOutlined style={{ color: 'red' }} />
                {t('DELETE')}
              </Button>
            </Authorized>
          </Button.Group>
        );
      }
    },
    {
      headerName: t('NAME'),
      field: 'text'
    },
    {
      headerName: t('UPDATED_AT'),
      field: 'updatedAt',
      cellRenderer: ({ value }) => format(value, DATE_FORMAT)
    }
  ];
  return (
    <ReactResizeDetector handleHeight>
      {({ height }) => {
        return (
          <AgGridServer
            options={{ columnDefs, cacheBlockSize: 50, paginationPageSize: 50 }}
            onQuery={loadCampaignInstruction}
            queryKey="findManyCommon"
            onCountQuery={sdk.countCommon}
            countQueryKey="countCommon"
            onGridReady={handleGridReady}
            where={where}
            height={height - 80}
          />
        );
      }}
    </ReactResizeDetector>
  );
});

export default InstructionGrid;
