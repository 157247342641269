import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import { isEqual, omit } from 'lodash';
import { action, observable, runInAction, toJS } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component, ReactElement } from 'react';
import shortid from 'shortid';

import { removeSign } from '@source/common';

import { DataOptions } from '~components/Filter/filterType';

const { Option } = Select;

interface SelectOptionsProps extends DataOptions {
  template?: (item: any) => ReactElement;
}

const handleSearch = (input: string, compareTarget: string) => {
  return (
    removeSign((compareTarget || '').toString().toLowerCase()).indexOf(
      removeSign(input.toLowerCase())
    ) >= 0
  );
};

export interface UISelectProps extends SelectProps<any> {
  dataOptions?: SelectOptionsProps;
}

@observer
export class UISelect extends Component<UISelectProps> {
  @observable public selectData: any[];
  @observable public loading: boolean;

  constructor(props: Readonly<UISelectProps>) {
    super(props);
    runInAction(() => {
      this.selectData = [];
      this.loading = true;
    });
  }

  componentDidMount() {
    const { dataOptions } = this.props;
    const length = dataOptions.data?.length;
    if (length) {
      this.setDataOptions(dataOptions);
    } else {
      this.setLoading(false);
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps: UISelectProps) {
    const { dataOptions } = this.props;
    if (!isEqual(dataOptions, nextProps.dataOptions.data)) {
      const dataOptionsNext = nextProps.dataOptions;

      this.setDataOptions(dataOptionsNext);
    }
  }

  @action
  private setLoading(value: boolean) {
    this.loading = value;
  }

  @action
  public setDataOptions = (dataOptions: SelectOptionsProps) => {
    this.selectData = dataOptions.data;
    this.setLoading(false);
  };

  public render() {
    const props = omit(this.props, ['dataOptions']);
    const { dataOptions } = this.props;
    const { valueField, textField, template } = dataOptions;
    return (
      <Select
        style={{ width: '100%' }}
        showSearch
        showArrow
        optionFilterProp="filter"
        filterOption={(input, option) => {
          return handleSearch(input, option.filter);
        }}
        loading={this.loading}
        // notFoundContent="Not found !!!"
        {...props}
      >
        {toJS(this.selectData).map((item) => (
          <Option key={shortid.generate()} value={item[valueField]} filter={item[textField]}>
            {template ? template(item) : item[textField]}
          </Option>
        ))}
      </Select>
    );
  }
}
