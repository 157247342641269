import TweenOne from 'rc-tween-one';
import Children from 'rc-tween-one/lib/plugin/ChildrenPlugin';
import React, { FC } from 'react';

interface CountingProps {
  value: number;
  duration?: number;
  suffix?: string;
}

TweenOne.plugins.push(Children);

const Counting: FC<CountingProps> = ({ value, duration, suffix }) => {
  return (
    <>
      <TweenOne
        animation={{
          Children: {
            value: value || 0,
            floatLength: 0,
            formatMoney: { thousand: ',', decimal: '.' }
          },
          duration: duration || 1000
        }}
      >
        0
      </TweenOne>
      {suffix || ''}
    </>
  );
};

export default Counting;
