export function createMultiLevelObject(arr: string[], value: any): object {
  let obj = {};
  for (let i = arr.length - 1; i >= 0; i -= 1) {
    if (i === arr.length - 1) {
      obj = { [`${arr[i]}`]: value };
    } else {
      obj = Object.assign({}, { [`${arr[i]}`]: obj });
    }
  }
  return obj;
}
