import { Card, Col, Row } from 'antd';
import { observer } from 'mobx-react';
import React, { FC, useEffect } from 'react';

import Counting from '~components/UI/Counting';

import store from './store';

const StatisticComponent: FC = observer(() => {
  const { statisticData: total, getStatisticData } = store;

  useEffect(() => {
    if (!total) {
      getStatisticData();
    }
  }, []);

  const customTitle = (icon, string, color) => {
    return (
      <>
        <i style={{ marginRight: 8, color, fontSize: 17 }} className={icon} />
        <b style={{ color }}>{string}</b>
      </>
    );
  };

  const colSpan = {
    md: 24,
    sm: 12,
    flex: 1
  };

  return (
    <Row gutter={16}>
      <Col {...colSpan}>
        <Card
          style={{
            borderLeft: '1px solid #0078D4',
            borderColor: '#0078D4',
            backgroundColor: 'rgba(0, 120, 212, 0.82)'
          }}
          title={customTitle('fas fa-eye', 'Impression', 'white')}
          extra={
            <span style={{ fontSize: 20, float: 'right', color: 'white', position: 'relative' }}>
              <Counting value={+total?.impression} />
            </span>
          }
          bodyStyle={{ padding: 0 }}
        ></Card>
      </Col>
      <Col {...colSpan}>
        <Card
          style={{
            borderLeft: '1px solid #FFA242',
            borderColor: '#FFA242',
            backgroundColor: 'rgba(255, 162, 66, 0.89)'
          }}
          title={customTitle('fas fa-location-arrow', 'Click', 'white')}
          extra={
            <span style={{ fontSize: 20, float: 'right', color: 'white', position: 'relative' }}>
              <Counting value={+total?.click} />
            </span>
          }
          bodyStyle={{ padding: 0 }}
        ></Card>
      </Col>
      <Col {...colSpan}>
        <Card
          style={{
            borderLeft: '1px solid #EC1E06',
            borderColor: '#EC1E06',
            backgroundColor: 'rgba(236, 30, 6, 0.74)'
          }}
          title={customTitle('fas fa-percentage', 'CTR', 'white')}
          extra={
            <>
              <span
                style={{
                  fontSize: 20,
                  float: 'right',
                  color: 'white',
                  position: 'relative',
                  display: 'flex'
                }}
              >
                <Counting value={+total?.ctr} suffix="%" />
              </span>
            </>
          }
          bodyStyle={{ padding: 0 }}
        ></Card>
      </Col>
      {/* <Col {...colSpan}>
        <Card
          style={{
            borderLeft: '1px solid #32C9BC',
            borderColor: '#32C9BC',
            backgroundColor: 'rgba(50, 201, 188, 0.77)'
          }}
          title={customTitle('fas fa-user', 'Users', 'white')}
          extra={
            <>
              <span
                style={{
                  fontSize: 20,
                  float: 'right',
                  color: 'white',
                  position: 'relative',
                  display: 'flex'
                }}
              >
                <Counting value={+total?.users} />
              </span>
            </>
          }
          bodyStyle={{ padding: 0 }}
        ></Card>
      </Col> */}
    </Row>
  );
});

export default StatisticComponent;
