/* eslint-disable prefer-destructuring */
import { get } from 'lodash';
import { action, observable, runInAction, toJS } from 'mobx';
import { actionAsync, task } from 'mobx-utils';
import QRCode from 'qrcode';

import userStore from '~common-stores/userStore';
import { ErrorNotify, SuccessNotify, WarningNoti } from '~components/UI/Notification';
import { TwoFactorAuth, User } from '~graphql/_sdk';
import { getSdk } from '~graphql/sdk';

export const enableStep = ['info', 'login', 'qrScan', 'verify', 'enable', 'done'];
export const disableStep = ['login', 'disable'];

class TFAStore {
  @observable public isShowTFAForm: boolean;
  @observable public password: string;
  @observable public verifiedCode: string;
  @observable public currentUser: User;
  @observable public step: string;
  @observable public steps: string[];
  @observable public tfa: TwoFactorAuth;
  @observable public otpauthUrl: string;
  @observable public isValidCode: boolean;
  @observable public isEnable: boolean;

  constructor() {
    runInAction(() => {
      this.isValidCode = true;
    });
  }

  @action nextStep = (next = 1) => {
    this.step = this.steps[this.steps.indexOf(this.step) + next];
  };
  @action prevousStep = (pre = 1) => {
    this.step = this.steps[this.steps.indexOf(this.step) - pre];
  };

  @action setCurrentUser = (user: User) => {
    this.currentUser = user;
    if (this.currentUser) {
      this.isEnable = !!this.currentUser?.twoFactorAuthId;
      if (this.isEnable) {
        this.steps = disableStep;
      } else {
        this.steps = enableStep;
      }
      this.step = this.steps[0];
    }
  };

  @action setIsShowTFAForm = (value: boolean) => {
    this.isShowTFAForm = value;
    if (!value) {
      this.password = null;
      this.step = this.steps[0];
      this.otpauthUrl = null;
      this.verifiedCode = null;
      this.isValidCode = true;
    }
  };

  @action resetStore = () => {
    this.password = null;
    this.currentUser = null;
    this.step = null;
    this.steps = null;
    this.tfa = null;
    this.otpauthUrl = null;
    this.verifiedCode = null;
    this.isValidCode = true;
  };

  @action setPassword = (value: string) => {
    this.password = value;
  };

  @action setVerifiedCode = (value: string) => {
    this.verifiedCode = value;
  };

  @actionAsync
  verifyTwoFactorAuthenticationCode = async () => {
    const sdk = await task(getSdk());

    const { verifyTwoFactorAuthenticationCode } = await task(
      sdk.verifyTwoFactorAuthenticationCode({ code: this.verifiedCode || '' })
    );
    if (verifyTwoFactorAuthenticationCode) {
      SuccessNotify('Verify');
      this.isValidCode = true;
      this.nextStep();
    } else {
      ErrorNotify('Verify failed');
      this.isValidCode = false;
    }
  };

  @actionAsync
  public confirmLogin = async (): Promise<void> => {
    const {
      currentUser: { email }
    } = userStore;

    if (!this.password) {
      WarningNoti('Please enter password');
    }
    const sdk = await task(getSdk());
    const {
      login: { authToken, data, error }
    } = await task(sdk.login({ email, password: this.password }));

    if (!error && data._id) {
      if (!this.isEnable) {
        await task(this.createTwoFactorAuthentication());
        await task(this.getOtpAuthUrl());
      }

      this.nextStep();
    } else {
      ErrorNotify('Password is incorrect');
    }
  };

  @actionAsync
  createTwoFactorAuthentication = async () => {
    const sdk = await task(getSdk());

    const {
      createTwoFactorAuthentication: { data, error }
    } = await task(sdk.createTwoFactorAuthentication());

    if (data) {
      this.tfa = data;
    } else {
      ErrorNotify(error.code);
    }
  };

  @actionAsync
  enableTwoFactorAuthentication = async () => {
    const sdk = await task(getSdk());

    const { enableTwoFactorAuthentication } = await task(sdk.enableTwoFactorAuthentication());

    if (enableTwoFactorAuthentication.data) {
      this.tfa = enableTwoFactorAuthentication.data;
      this.setIsShowTFAForm(false);
      window.location.reload();
    } else {
      ErrorNotify(enableTwoFactorAuthentication.error.code);
    }
  };

  @actionAsync
  disableTwoFactorAuthentication = async () => {
    const sdk = await task(getSdk());

    const { disableTwoFactorAuthentication } = await task(sdk.disableTwoFactorAuthentication());
    if (!disableTwoFactorAuthentication.error) {
      this.setIsShowTFAForm(false);
      window.location.reload();
    } else {
      ErrorNotify(disableTwoFactorAuthentication.error.code);
    }
  };

  @actionAsync
  getOtpAuthUrl = async () => {
    if (!this.tfa) return;
    const qr = await QRCode.toDataURL(this.tfa.otpauthUrl);
    runInAction(() => {
      this.otpauthUrl = qr;
    });
  };
}

export default new TFAStore();
