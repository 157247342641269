import i18next from 'i18next';
import { get, isEmpty, throttle } from 'lodash';
import { action, computed, observable, reaction, runInAction, toJS } from 'mobx';
import { actionAsync, task } from 'mobx-utils';

import { GridReadyEvent } from '@ag-grid-community/core';

import { ErrorNotify, SuccessNotify } from '~components/UI/Notification';
import {
  BasicCustomerFragment,
  Revenue,
  RevenueCustomer,
  RevenueCustomerInput,
  RevenueFilter,
  RevenueLocation,
  RevenueUpdateArg
} from '~graphql/_sdk';
import { FullCommonFragment } from '~graphql/_sdk.tmp';
import { getSdk } from '~graphql/sdk';
import { upperCaseFirstLetter } from '~utils/upperCaseFirstLetter';

interface Bill {
  contractCosts?: number;
  commissionType?: string;
  commission?: number;
  invoiceFeeType?: string;
  invoiceFee?: number;
  calculatedCommission?: number;
  calculatedInvoiceFee?: number;
}

interface LocationData extends RevenueLocation {
  key: string;
  total: number;
  name: string;
}

export interface MgnRevenueData {
  key: string;
  name: string;
  categoryId: string;
  categoryName: string;
  kpiBooked: number;
  kpiRunned: number;
  unitPrice: number;
  total: number;
  children: LocationData[];
}

export interface CustomerRevenueData {
  key: string;
  name: string;
  customerId: string;
  customerName: string;
  kpiBooked: number;
  kpiRunned: number;
  unitPricePartner: number;
  total: number;
  children: LocationData[];
}

interface ReportToLocationMapping {
  [field: string]: { total: number; list: RevenueLocation[] };
}

interface LocationGroup {
  [field: string]: RevenueLocation[];
}

export enum RevenueInputType {
  Percent = 'percent',
  Money = 'money'
}

export class RevenueStore {
  @observable public where: RevenueFilter;
  @observable public revenue: Revenue;
  @observable public campaignId: string;
  @observable public loading: boolean;
  @observable.deep public mappingCategoryLocation: LocationGroup;
  @observable.deep public mappingCustomerLocation: LocationGroup;
  @observable public mappingCustomerRevenue: { [field: string]: number };
  @observable public mappingSubLocation: ReportToLocationMapping;
  @observable public mappingCategoryName: { [field: string]: string };
  @observable public mappingCustomerName: { [field: string]: string };

  @observable public mgnRevenueTableData: MgnRevenueData[];
  @observable public customerRevenueTableData: CustomerRevenueData[];
  @observable public totalMgnRevenue: number;
  @observable public totalCustomerRevenue: number;
  @observable public contractCost: number;
  @observable public totalKpiBooked: number;
  @observable public commission: {
    type: RevenueInputType;
    value: number | string;
  };

  @observable public invoiceFee: {
    type: RevenueInputType;
    value: number | string;
  };

  constructor() {
    runInAction(() => {
      this.resetStore();

      reaction(
        () => this.revenue,
        revenue => {
          if (isEmpty(revenue)) return;
          this.initCustomerRevenue(revenue.customers);
          this.initLocationData(revenue.locations);
          this.contractCost = revenue.costContracted;

          this.commission = {
            type: RevenueInputType[upperCaseFirstLetter(revenue.typeTip)] ?? RevenueInputType.Money,
            value: revenue.tip
          };

          this.invoiceFee = {
            type:
              RevenueInputType[upperCaseFirstLetter(revenue.typeFeeBill)] ?? RevenueInputType.Money,
            value: revenue.feeBill
          };
          this.totalKpiBooked = revenue.kpiBooked;
        }
      );
    });
  }

  @actionAsync
  public setRevenueConfigData = async (id: string) => {
    this.loading = true;

    const sdk = await task(getSdk());
    const { findRevenueById } = await task(sdk.findRevenueById({ _id: id }));
    this.revenue = findRevenueById;

    this.loading = false;
  };

  @actionAsync
  public createRevenue = async () => {
    const sdk = await task(getSdk());
    const {
      createRevenue: { error }
    } = await task(sdk.createRevenue({ campaignId: this.campaignId }));

    if (error) {
      ErrorNotify(error.code);
    } else {
      SuccessNotify(i18next.t('CREATED'));
      this.refresh();
      this.reload();
    }
  };

  @actionAsync
  public verifyRevenue = async id => {
    const sdk = await task(getSdk());
    const {
      verifyRevenue: { error }
    } = await task(sdk.verifyRevenue({ _id: id }));

    if (error) {
      ErrorNotify(error.code);
    } else {
      SuccessNotify(i18next.t('VERIFY'));
      this.refresh();
      this.reload();
    }
  };

  @actionAsync
  public setCampaignId = async campaignId => {
    runInAction(() => {
      this.campaignId = campaignId;
    });
  };

  @actionAsync
  public updateRevenueById = async (_id: string, record: RevenueUpdateArg) => {
    const sdk = await task(getSdk());

    const {
      updateRevenueById: { error, data }
    } = await task(sdk.updateRevenueById({ _id, record }));

    if (error) {
      ErrorNotify('Update');
    }

    if (data) {
      SuccessNotify('Update');
    }
  };

  @action
  public getUnitPricePartner = () => {
    const mappingCustomerLocation = {};
    Object.keys(this.mappingCustomerLocation).forEach(customer => {
      this.mappingCustomerLocation[customer].forEach(location => {
        mappingCustomerLocation[customer] = location;
      });
    });

    return mappingCustomerLocation;
  };

  @actionAsync
  public handleUpdateRevenue = async () => {
    const { _id } = this.revenue;
    const revenue: RevenueUpdateArg = {};
    let locations: RevenueLocation[] = [];

    const mappingCustomerLocation = this.getUnitPricePartner();

    Object.keys(this.mappingCategoryLocation).forEach(cate => {
      locations.push(...this.mappingCategoryLocation[cate]);
    });

    Object.keys(this.mappingSubLocation).forEach(key => {
      locations.push(...this.mappingSubLocation[key].list);
    });

    locations = locations.map(loc => {
      return {
        ...loc,
        unitPricePartner: mappingCustomerLocation[loc.customerId]?.unitPricePartner
      };
    });

    const customers: RevenueCustomerInput[] = [];

    Object.keys(this.mappingCustomerLocation).forEach(customer => {
      let kpiBooked = 0;
      let kpiRunned = 0;

      this.mappingCustomerLocation[customer].forEach(loc => {
        kpiBooked += loc.kpiBooked;
        kpiRunned += loc.kpiRunned;
      });
      customers.push({
        kpiBooked,
        kpiRunned,
        revenue: this.mappingCustomerRevenue[customer],
        customerId: customer
      });
    });

    revenue.locations = locations;
    revenue.customers = customers;

    revenue.costContracted = this.contractCost;
    revenue.typeTip = this.commission.type;
    revenue.tip = this.parsePercent(this.commission.value as string);
    revenue.typeFeeBill = this.invoiceFee.type;
    revenue.feeBill = this.parsePercent(this.invoiceFee.value as string);

    revenue.revenue = this.totalMgnRevenue;
    revenue.revenuePartner = this.totalCustomerRevenue;
    revenue.kpiBooked = this.totalKpiBooked;

    this.updateRevenueById(_id, revenue);
  };

  @action
  public changeMappingCustomerLocation = (key: string, record, field, value) => {
    const customer = key.split('_&_')[1];
    const type = key.split('_&_')[0] === 'customer' ? 'customer' : 'location';

    if (field === 'revenuePartner') {
      const unitPrice = Math.round(value / record.kpiRunned);
      this.mappingCustomerRevenue[customer] = value;
      this.mappingCustomerLocation[customer] = this.mappingCustomerLocation[customer].map(loc => {
        return {
          ...loc,
          unitPricePartner: unitPrice
        };
      });
    }

    if (type === 'customer') {
      this.mappingCustomerLocation[customer] = this.mappingCustomerLocation[customer].map(loc => {
        return {
          ...loc,
          [field]: value
        };
      });
    }

    if (type === 'location') {
      const location = key.split('_&_')[0];
      this.mappingCustomerLocation[customer] = this.mappingCustomerLocation[customer].map(loc => {
        if (loc.locationId === location) {
          return {
            ...loc,
            [field]: value
          };
        }
        return loc;
      });
    }

    this.setCustomerRevenueTableData(type === 'customer');
  };

  @action
  public changeMappingCategoryLocation = (key: string, record, field, value) => {
    const type = key.split('_&_')[0] === 'category' ? 'category' : 'location';
    const category = key.split('_&_')[1];

    if (type === 'category') {
      this.mappingCategoryLocation[category] = this.mappingCategoryLocation[category].map(loc => {
        return {
          ...loc,
          [field]: value
        };
      });
    }

    if (type === 'location') {
      const location = key.split('_&_')[0];
      this.mappingCategoryLocation[category] = this.mappingCategoryLocation[category].map(loc => {
        if (loc.locationId === location) {
          return {
            ...loc,
            [field]: value
          };
        }
        return loc;
      });
    }

    if (field === 'kpiBooked') {
      this.changeMappingCustomerLocation(
        `${key.split('_&_')[0]}_&_${record.customerId}`,
        record,
        field,
        value
      );
    }

    this.setMgnRevenueTableData(type === 'category', 'unitPrice');
  };

  @action
  public initCustomerRevenue = (customers: RevenueCustomer[]) => {
    const mapping = customers.reduce((all, value) => {
      all[String(value.customerId)] = value.revenue as number;
      return all;
    }, {});

    this.mappingCustomerRevenue = mapping as any;
  };

  @action
  public initLocationData = (locations: RevenueLocation[]) => {
    const subLocation: ReportToLocationMapping = {};

    const mappinCategoryGroupLocation: LocationGroup = {};
    const mappinCustomerGroupLocation: LocationGroup = {};

    const setSubLoction = (location: RevenueLocation) => {
      if (subLocation[location.reportLocationId]) {
        subLocation[location.reportLocationId].total += location.kpiRunned;
        subLocation[location.reportLocationId].list.push(location);
      } else {
        subLocation[location.reportLocationId] = {
          total: location.kpiRunned ?? 0,
          list: [location]
        };
      }
    };

    const setLocationByCategory = (location: RevenueLocation) => {
      if (mappinCategoryGroupLocation[location.categoryId]) {
        mappinCategoryGroupLocation[location.categoryId].push(location);
      } else {
        mappinCategoryGroupLocation[location.categoryId] = [location];
      }
    };

    const setLocationByCustomer = (location: RevenueLocation) => {
      if (mappinCustomerGroupLocation[location.customerId]) {
        mappinCustomerGroupLocation[location.customerId].push(location);
      } else {
        mappinCustomerGroupLocation[location.customerId] = [location];
      }
    };

    locations.forEach(loc => {
      setLocationByCustomer(loc);
      if (loc.reportLocationId) {
        setSubLoction(loc);
      } else {
        setLocationByCategory(loc);
      }
    });

    this.mappingSubLocation = subLocation;
    this.mappingCategoryLocation = mappinCategoryGroupLocation;
    this.mappingCustomerLocation = mappinCustomerGroupLocation;

    this.setMgnRevenueTableData();
    this.setCustomerRevenueTableData();
  };

  @action setContractCost = (value: string) => {
    this.contractCost = parseFloat(value);
  };

  @action setCommission = commission => {
    this.commission = { ...this.commission, ...commission };
  };

  @action setInvoiceFee = fee => {
    this.invoiceFee = { ...this.invoiceFee, ...fee };
  };

  @action
  public handleGridReady = ({ api }: GridReadyEvent) => {
    this.reload = () => {
      api.onFilterChanged();
    };
  };

  @action
  public setSearch = throttle(
    (value: string) => {
      runInAction(() => {
        this.where = { ...this.where, _search: value };
      });
      this.reload();
    },
    300,
    { leading: false, trailing: true }
  );

  @action
  public resetData = () => {
    this.campaignId = undefined;
    this.revenue = undefined;
    this.mappingCategoryLocation = {};
    this.mappingCategoryName = {};
    this.mappingSubLocation = {};
  };

  @action
  public reload = () => undefined;

  private refresh = () => undefined;

  @action
  public setMgnRevenueTableData = (isCategory = false, field?: string) => {
    const mgnRevenueData: MgnRevenueData[] = [];
    let totalRevenue = 0;
    let totalKpiBooked = 0;

    Object.keys(this.mappingCategoryLocation).forEach(cateId => {
      let kpiBooked = 0;
      let kpiRunned = 0;
      let categoryTotal = 0;
      const locationData: LocationData[] = [];

      this.mappingCategoryLocation[cateId].forEach(loc => {
        const totalReportKpi =
          get(loc, 'kpiRunned', 0) + (this.mappingSubLocation[loc.locationId]?.total ?? 0);
        const total = get(loc, 'unitPrice', 0) * loc.kpiBooked;

        locationData.push({
          ...loc,
          key: `${loc.locationId}_&_${cateId}`,
          kpiRunned: totalReportKpi,
          total,
          name: loc.locationName
        });

        kpiBooked += loc.kpiBooked;
        kpiRunned += totalReportKpi;
        categoryTotal += total;
      });

      mgnRevenueData.push({
        name: this.mappingCategoryName[cateId],
        kpiRunned,
        categoryId: cateId,
        categoryName: this.mappingCategoryName[cateId],
        unitPrice: 0,
        key: `category_&_${cateId}`,
        total: categoryTotal,
        children: locationData,
        kpiBooked
      });

      totalRevenue += categoryTotal;
      totalKpiBooked += kpiBooked;
    });

    this.totalMgnRevenue = totalRevenue;
    this.totalKpiBooked = totalKpiBooked;
    this.mgnRevenueTableData = mgnRevenueData;
  };

  @action
  public setCustomerRevenueTableData = (isCustomer = false) => {
    const customerRevenueData: CustomerRevenueData[] = [];
    let totalRevenue = 0;

    Object.keys(this.mappingCustomerLocation).forEach(customerId => {
      let kpiBooked = 0;
      let kpiRunned = 0;
      let customerTotal = 0;

      const locationData: LocationData[] = [];

      this.mappingCustomerLocation[customerId].forEach(loc => {
        kpiBooked += loc.kpiBooked;
        kpiRunned += loc.kpiRunned;

        const total = Math.round(get(loc, 'unitPricePartner', 0) * get(loc, 'kpiBooked', 0));

        locationData.push({
          ...loc,
          key: `${loc.locationId}_&_${customerId}`,
          total,
          name: loc.locationName
        });

        customerTotal += get(loc, 'unitPricePartner', 0) * get(loc, 'kpiBooked', 0);
      });

      customerRevenueData.push({
        customerId,
        customerName: this.mappingCustomerName[customerId],
        kpiRunned,
        total: this.mappingCustomerRevenue[customerId] ?? 0,
        key: `customer_&_${customerId}`,
        name: this.mappingCustomerName[customerId],
        unitPricePartner: Math.round((this.mappingCustomerRevenue[customerId] ?? 0) / kpiRunned),
        children: null,
        ...(!isCustomer && { kpiBooked })
      });

      totalRevenue += this.mappingCustomerRevenue[customerId] ?? customerTotal;
    });

    this.totalCustomerRevenue = totalRevenue;
    this.customerRevenueTableData = customerRevenueData;
  };

  @action setMappingCategoryName = (categories: FullCommonFragment[]) => {
    if (!isEmpty(this.mappingCategoryName)) return;

    this.mappingCategoryName = categories.reduce((all, cate) => {
      all[cate.value] = cate.text;
      return all;
    }, {});
  };

  @action setMappingCustomerName = (customers: BasicCustomerFragment[]) => {
    if (!isEmpty(this.mappingCustomerName)) return;

    this.mappingCustomerName = customers.reduce((all, cus) => {
      all[cus._id] = cus.name;
      return all;
    }, {});
  };

  @action
  public resetStore = () => {
    this.campaignId = undefined;
    this.revenue = undefined;
    this.mappingCategoryLocation = {};
    this.mappingSubLocation = {};
    this.mappingCustomerRevenue = {};
    this.mappingCategoryName = {};
    this.mappingCustomerName = {};
    this.contractCost = 0;
    this.commission = {
      type: RevenueInputType.Money,
      value: 0
    };

    this.invoiceFee = {
      type: RevenueInputType.Money,
      value: 0
    };
  };

  @computed
  get myRevenue() {
    return this.totalMgnRevenue - this.totalCustomerRevenue ?? 0;
  }

  public parsePercent = (value: string) => {
    let parse = parseFloat(value);
    if (parse < 0 || Number.isNaN(parse)) {
      parse = 0;
    }
    return parse;
  };

  @computed
  get actualRevenue() {
    let actualRevenue: number = this.myRevenue - this.contractCost ?? 0;

    if (this.commission?.type === RevenueInputType.Money) {
      actualRevenue -= this.commission.value as number;
    } else {
      actualRevenue -= Math.round(
        (this.myRevenue * this.parsePercent(this.commission.value as string)) / 100
      );
    }

    if (this.invoiceFee?.type === RevenueInputType.Money) {
      actualRevenue += this.invoiceFee.value as number;
    } else {
      actualRevenue += Math.round(
        (this.myRevenue * this.parsePercent(this.invoiceFee.value as string)) / 100
      );
    }

    return actualRevenue ?? 0;
  }
}

export default new RevenueStore();
