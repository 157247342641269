import { observer } from 'mobx-react';
import React, { FC } from 'react';
import ReactResizeDetector from 'react-resize-detector';

import { ColDef } from '@ag-grid-community/core';

import AgGridServer from '~components/UI/AgGrid/AgGridServer';
import { DATE_FORMAT, format } from '~utils/formatDate';

import store from '../store';

const GridDetail: FC = observer(() => {
  const {
    handleGridDetailReady,
    getReportTrackingDetail,
    countReportTrackingDetail,
    reportFilter
  } = store;

  const columnDefs: ColDef[] = [
    {
      headerName: 'Category',
      field: 'categoryId',
      rowGroup: true,
      minWidth: 100,
      maxWidth: 400,
      hide: true,
      cellRenderer: ({ data }) => data.categoryName
    },
    {
      headerName: 'Location',
      field: 'locationId',
      rowGroup: true,
      minWidth: 100,
      maxWidth: 400,
      hide: true,
      cellRenderer: ({ data }) => data.locationName
    },
    {
      headerName: 'Banner',
      field: 'bannerId',
      rowGroup: true,
      minWidth: 100,
      maxWidth: 400,
      cellRenderer: ({ data }) => data.bannerName,
      hide: true
    },
    {
      headerName: 'Date',
      field: 'createdAt',
      minWidth: 100,
      maxWidth: 400,
      menuTabs: [],
      sortable: false,
      cellRenderer: ({ value }) => (value ? format(value, DATE_FORMAT) : null)
    },
    {
      headerName: 'Impression',
      field: 'impression',
      minWidth: 100,
      maxWidth: 100,
      menuTabs: [],
      sortable: false,
      cellClass: 'text-right',
      cellRenderer: ({ value }) => `${value ? value.toLocaleString() : 0}`
    },
    {
      headerName: 'Click',
      field: 'click',
      minWidth: 100,
      maxWidth: 100,
      menuTabs: [],
      sortable: false,
      cellClass: 'text-right',
      cellRenderer: ({ value }) => `${value ? value.toLocaleString() : 0}`
    },
    {
      headerName: 'CTR',
      field: 'ctr',
      minWidth: 100,
      maxWidth: 100,
      menuTabs: [],
      sortable: false,
      cellRenderer: ({ value }) => `${value ? value.toLocaleString() : 0} %`
    }
  ];

  const defaultColDef = {
    flex: 1,
    sortable: false,
    resizable: false
  };

  const autoGroupColumnDef = {
    menuTabs: [],
    minWidth: 200
  };

  return (
    <ReactResizeDetector handleHeight>
      {({ height }) => {
        return (
          <div style={{ height: 'calc(100vh - 320px)' }}>
            <AgGridServer
              options={{
                columnDefs,
                paginationPageSize: 50,
                cacheBlockSize: 50,
                defaultColDef,
                autoGroupColumnDef
              }}
              onQuery={(params) => getReportTrackingDetail(params)}
              queryKey="data"
              onCountQuery={(params) => countReportTrackingDetail(params)}
              countQueryKey="countReportTrackingDetail"
              onGridReady={handleGridDetailReady}
              height={height}
              where={reportFilter}
            />
          </div>
        );
      }}
    </ReactResizeDetector>
  );
});
export default GridDetail;
