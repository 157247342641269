import { Layout } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';

import store from './store';

 const { Content } = Layout;

const ContentStep = observer(({ steps }) => {
  const { step } = store;

  return (
    <Content style={{ height: 'calc(100% - 80px)', background: '#fff' }}>
      {steps[step].content}
    </Content>
  );
});

export default ContentStep;
