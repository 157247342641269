import { Input, Spin } from 'antd';
import { observer } from 'mobx-react';
import React, { FunctionComponent } from 'react';

import store from './store';

const HTMLTextArea: FunctionComponent = observer(() => {
  const { setSelectedHTML, renderCode, loadingGenHTML } = store;
  const html = `<script>${renderCode}</script>`;

  React.useEffect(() => {
    setSelectedHTML(html);
  }, [renderCode]);

  return (
    <Spin spinning={loadingGenHTML}>
      <Input.TextArea rows={16} cols={5} value={renderCode && html} disabled={!renderCode} />
    </Spin>
  );
});

export default HTMLTextArea;
