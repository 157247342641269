import { Col, Row, Tabs, Tooltip } from 'antd';
import React, { FC } from 'react';
import ReactResizeDetector from 'react-resize-detector';

import Chart from './Chart';
import GridCampaign from './GridCampaign';
import GridLocation from './GridLocation';
import StatisticComponent from './Statistic';

export const Report: FC = () => {
  const { TabPane } = Tabs;

  return (
    <Row style={{ height: '100%', flexFlow: 'column nowrap' }}>
      <Col flex="0 0 auto">
        <StatisticComponent />
      </Col>
      <Col flex="1 1 auto">
        <Tabs
          style={{
            height: '100%',
            backgroundColor: '#fff'
          }}
          size="small"
          tabPosition="left"
          defaultActiveKey="Date"
        >
          <TabPane
            forceRender
            tab={
              <Tooltip placement="right" title="Chart">
                <i className="fas fa-chart-bar" />
              </Tooltip>
            }
            key="Chart"
            style={{ height: '100%' }}
          >
            <ReactResizeDetector handleHeight handleWidth>
              {({ height, width }) => {
                return (<Chart widthChart={width} heightChart={height} />);
              }}
            </ReactResizeDetector>
          </TabPane>

          <TabPane
            forceRender
            style={{ height: '100%' }}
            tab={
              <Tooltip placement="right" title="Detail">
                <i className="fas fa-th" />
              </Tooltip>
            }
            key="Grid"
          >
            <Tabs
              style={{
                height: '100%',
                backgroundColor: '#fff'
              }}
              size="small"
              defaultActiveKey="Location"
            >
              <TabPane
                forceRender
                tab="By Location"
                key="Location"
              >
                <GridLocation />
              </TabPane>

              <TabPane
                forceRender
                tab="By Campaign"
                key="Campaign"
              >
                <GridCampaign />
              </TabPane>
            </Tabs>
          </TabPane>
        </Tabs>
      </Col>
    </Row>
  );
};
