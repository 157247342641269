import { Button, Checkbox, PageHeader } from 'antd';
import { isEqual } from 'lodash';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { DataOptions } from '~components/Filter/filterType';
import { UISelect } from '~components/UI/Select/UISelect';
import { UIDateRangePicker } from '~components/UI/UIDateRangePicker';
import { useStore } from '~context/react-context';
import { DateFilterInput } from '~graphql/_sdk';

import store from './store';

const InventoryHeader: FC = observer(() => {
  const { t } = useTranslation();
  const {
    setFilterDate,
    filterDate,
    setFilterCategoryId,
    setDate,
    checked,
    setChecked,
    setDetail,
    detail
  } = store;

  const {
    commonDataStore: { categories, loadLocationCategory }
  } = useStore();

  useEffect(() => {
    loadLocationCategory();
  }, []);

  const onChange = (date) => {
    const dateRange: DateFilterInput = {
      from: new Date(date[0]),
      to: new Date(date[1])
    };
    const filter = {
      from: filterDate.from.valueOf(),
      to: filterDate.to.valueOf()
    };

    if (isEqual(date, filter)) {
      return;
    }
    setDate(dateRange);
    setFilterDate(dateRange);
  };

  const disabledDate = (current) => {
    return current && current < moment().startOf('day');
  };

  const categoryIdFilter = (value) => {
    if (value) {
      setFilterCategoryId(value);
    }
  };

  const customRange = {
    'The next 30 days': [moment().startOf('day'), moment().add(30, 'days').startOf('day')]
  };

  const CheckedStatusButton = () => {
    return <Button onClick={setChecked}>{!checked ? 'Hide not selected' : 'Show all'}</Button>;
  };

  const dataOptions: DataOptions = {
    data: categories,
    textField: 'text',
    valueField: 'value'
  };

  return (
    <PageHeader
      style={{ background: '#fff', marginBottom: 0, padding: '16px' }}
      title={t('AVAILABLE_KPI')}
      extra={[
        <Checkbox key="4" onChange={(e) => setDetail(e.target.checked)} checked={detail}>
          Detail
        </Checkbox>,
        <CheckedStatusButton key="3" />,
        <UISelect
          mode="multiple"
          key="select-location"
          placeholder={t('SELECT_CATEGORY')}
          style={{ width: '250px' }}
          allowClear
          onChange={categoryIdFilter}
          dataOptions={dataOptions}
        />,
        <UIDateRangePicker
          key="2"
          customRange={customRange}
          disabledDate={disabledDate}
          style={{ width: '250px' }}
          allowClear={false}
          value={[filterDate.from.valueOf(), filterDate.to.valueOf()]}
          onChange={onChange}
        />
      ]}
    />
  );
});

export default InventoryHeader;
