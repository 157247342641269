import '@ant-design/compatible/assets/index.css';

import { Checkbox, Input, Modal } from 'antd';
import i18next from 'i18next';
import { get, isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import useForm from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { Form } from '@ant-design/compatible';

import SelectCommon from '~components/Select/SelectCommon';
import UIField from '~components/UI/UIField';
import UIUpload from '~components/UI/UIUpload';
import { CommonInput, CommonUpdateArg } from '~graphql/_sdk';

import store from './store';
import { CheckboxProps } from 'antd/lib/checkbox';

interface FormValues {
  value?: string;
  text?: string;
  type?: string;
  video?: string;
  active?: boolean;
}

const validationSchema = yup.object().shape<FormValues>({
  value: yup.string().required(i18next.t('COMMON_DATA_VALIDATE_VALUE_REQUIRE')),
  text: yup.string().required(i18next.t('COMMON_DATA_VALIDATE_TEXT_REQUIRE')),
  type: yup.string().required(i18next.t('COMMON_DATA_VALIDATE_TYPE_REQUIRE')),
  video: yup.string()
});

const InstructionForm: FC = observer(() => {
  const { t } = useTranslation();
  const {
    modalVisible,
    setModalVisible,
    create,
    update,
    selectedItem,
    setFileList,
    fileList,
    link
  } = store;

  const initValues: FormValues = {
    value: get(selectedItem, 'value', ''),
    text: get(selectedItem, 'text', ''),
    type: get(selectedItem, 'meta.type'),
    video: get(selectedItem, 'meta.video', ''),
    active: get(selectedItem, 'active', true)
  };

  const formProps = useForm({ validationSchema });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting }
  } = formProps;

  const onCancel = () => {
    reset();
    setModalVisible();
  };

  const onSubmit = async ({ video, type, ...record }: FormValues) => {
    const commonType = 'instruction';

    Object.assign(record, { type: commonType, meta: { type, video, link } });

    if (selectedItem) {
      await update(get(selectedItem, '_id'), record as CommonUpdateArg);
      setModalVisible();
    } else {
      await create(record as CommonInput);
      setModalVisible();
    }
  };
  return (
    <Modal
      style={{ top: '28px' }}
      className="customModal"
      title="Instruction"
      visible={modalVisible}
      cancelText="Close"
      cancelButtonProps={{ danger: true, ghost: true }}
      onCancel={() => onCancel()}
      okText={selectedItem ? 'Update' : 'Create'}
      okButtonProps={{
        htmlType: 'submit',
        form: 'bannerTemplateForm',
        type: 'primary',
        loading: isSubmitting
      }}
    >
      <Form id="bannerTemplateForm" onSubmit={handleSubmit(onSubmit)} layout="vertical">
        <UIField
          {...formProps}
          name="text"
          label={t('NAME')}
          fieldProps={{
            placeholder: t('INPUT_NAME')
          }}
          fieldComponent={Input}
          defaultValue={initValues.text}
        />
        <UIField
          {...formProps}
          name="value"
          label={t('VALUE')}
          fieldProps={{
            placeholder: t('COMMON_DATA_PLACEHOLDER_VALUE'),
            disabled: !!selectedItem
          }}
          fieldComponent={Input}
          defaultValue={initValues.value}
        />
        <UIField
          {...formProps}
          name="type"
          label={t('TYPE')}
          fieldProps={{
            placeholder: t('CHOOSE_TYPE'),
            where: { type: 'instruction_type' }
          }}
          fieldComponent={SelectCommon}
          defaultValue={selectedItem && selectedItem.meta.type}
        />
        <UIField
          {...formProps}
          name="video"
          label={t('VIDEO')}
          fieldProps={{
            placeholder: t('INPUT_VIDEO')
          }}
          fieldComponent={Input}
          defaultValue={initValues.video}
        />
        {!selectedItem ? (
          <UIUpload
            key="update"
            label={t('FILE')}
            listType="text"
            handleChange={setFileList}
            quantity={1}
            defaultFileList={fileList}
            accept="application"
          />
        ) : (
          <UIUpload
            label="File"
            key="create"
            quantity={1}
            handleChange={setFileList}
            defaultFileList={fileList}
            listType="text"
            accept="application"
          />
        )}
        <UIField<CheckboxProps>
          {...formProps}
          name="active"
          fieldProps={{
            name: 'active',
            children: [t('is Active')]
          }}
          fieldComponent={Checkbox}
          checkbox
          defaultValue={initValues.active}
        />
      </Form>
    </Modal>
  );
});

export default InstructionForm;
