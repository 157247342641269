import React, { useEffect } from 'react';

import Form from './Form';
import Grid from './Gird';
import Header from './Header';
import store from './store';

const ProfileScreen = () => {
  const { fetchRoles, reset } = store;
  useEffect(() => {
    fetchRoles();

    return () => reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Header />
      <Grid />
      <Form />
    </>
  );
};

export default ProfileScreen;
