import { Button, Layout } from 'antd';
import { observer } from 'mobx-react';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';

import { MenuUnfoldOutlined, PlusOutlined } from '@ant-design/icons';
import {
  ProfileDefault,
  ROLE_BOOK_CAMPAIGN,
  ROLE_BOOK_ONLINE_CAMPAIGN,
  ROLE_CREATE_CAMPAIGN
} from '@source/common';

import Authorized from '~components/Authorized/Authorized';
import BreadCrumbMenu from '~components/Menu/BreadCrumb';
import InfoMenu from '~components/Menu/InfoMenu';
import Instruction from '~components/Menu/Intruction';
import LangMenu from '~components/Menu/LangMenu';
import LocationList from '~components/Menu/LocationList';
import { useStore } from '~context/react-context';

import store from '../../screens/campaign-booking/store';
import { isMobile } from '../../utils/isMobile';
import { componentWrapper_header, fixed_header, trigger_button_sm } from './css';

const { Header } = Layout;

const Topbar: FC = observer(() => {
  const {
    layoutStore,
    userStore: { currentUser }
  } = useStore();
  const { toggleDrawer } = layoutStore;
  const { reset } = store;
  const { t } = useTranslation();

  let createCampaignRoute = '/campaigns/booking';
  let bookTitle = t('campaign.CREATE');

  if (
    currentUser.profile.roleIds.includes(ROLE_BOOK_ONLINE_CAMPAIGN) &&
    !currentUser.profile.roleIds.includes(ROLE_BOOK_CAMPAIGN)
  ) {
    createCampaignRoute = '/campaigns/booking-online';
    bookTitle = t('BOOKING_ONLINE');
  }

  if (currentUser.profileId.includes(ProfileDefault.PROFILE_CLIENT)) {
    createCampaignRoute = '/campaigns-basic?createButton=true';
    bookTitle = t('CAMPAIGN_BASIC');
  }

  return (
    <Header className={fixed_header}>
      <MenuUnfoldOutlined
        className={trigger_button_sm}
        onClick={(event) => {
          event.preventDefault();
          event.persist();
          toggleDrawer();
        }}
      />

      <BreadCrumbMenu />
      <div className={componentWrapper_header}>
        <Authorized roles={[ROLE_CREATE_CAMPAIGN, ROLE_BOOK_CAMPAIGN, ROLE_BOOK_ONLINE_CAMPAIGN]}>
          {!currentUser.profileId.includes(ProfileDefault.PROFILE_CLIENT) ? (
            <Link key="campaign" to={createCampaignRoute} onClick={() => reset()}>
              <Button key="create" type="primary" icon={<PlusOutlined />}>
                {bookTitle}
              </Button>
            </Link>
          ) : (
            <></>
          )}
        </Authorized>
        <InfoMenu />
        {!isMobile() ? <LangMenu /> : ''}
        {!isMobile() ? <Instruction /> : ''}
        {/* {!isMobile() ? <LocationList /> : ''} */}
      </div>
    </Header>
  );
});

export default withRouter(Topbar);
