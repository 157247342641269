import '@ant-design/compatible/assets/index.css';

import { Button, Checkbox, Drawer, Radio, Switch } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { DrawerProps } from 'antd/lib/drawer';
import { get, isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import shortId from 'shortid';

import { Form } from '@ant-design/compatible';
import { FilterOutlined } from '@ant-design/icons';
import { WhereArgs } from '@source/common';

import { UISelect } from '~components/UI/Select/UISelect';
import { UISelectSearch } from '~components/UI/Select/UISelectSearch';
import { UIDateRangePicker } from '~components/UI/UIDateRangePicker';
import { parseQuery, stringifyQuery } from '~utils/query-string';

import { FilterStore } from './filterStore';
import { FilterConfig } from './filterType';

const { Item } = Form;
const { Group } = Radio;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
};

const fixedItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 }
  }
};

interface FilterButton {
  buttonName?: string;
  props?: ButtonProps;
}

interface FilterProps {
  config?: FilterConfig;
  onFilterChanged?: (where: WhereArgs) => void;
  button?: FilterButton;
  title?: string;
  drawerProps?: DrawerProps;
  initialValues?: WhereArgs;
}

interface QueryWhereParams {
  where: object;
}

const Filter: FC<FilterProps> = observer((props) => {
  const { config, onFilterChanged, button, title, drawerProps } = props;
  const [store] = useState(() => new FilterStore());
  const {
    whereFilter,
    onDateRangeChanged,
    onMultiSelectChanged,
    onSingleSelectChanged,
    clearFilter,
    onDeselectChanged,
    onMultiUncheckChanged,
    init
  } = store;

  const [visible, setVisible] = useState(false);

  const location = useLocation();
  const history = useHistory();

  const initialFilter = parseQuery(location.search) as QueryWhereParams;

  const cachedInitialValues = useRef(undefined);

  useEffect(() => {
    const filter = initialFilter.where;
    if (filter) {
      init(filter);
      onFilterChanged(filter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (JSON.stringify(props.initialValues) !== cachedInitialValues.current) {
      init(props.initialValues);
      onFilterChanged(props.initialValues);
      cachedInitialValues.current = JSON.stringify(props.initialValues);
      const parsed = parseQuery(location.search) as QueryWhereParams;
      Object.assign(parsed, { where: props.initialValues });
      const query = stringifyQuery(parsed);
      history.push({ search: query });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.initialValues]);

  const dateRange =
    !isEmpty(config.dateRange) &&
    config.dateRange.map((rangeDate, index) => {
      let val0;
      let val1;
      const { length } = rangeDate.filterFields;
      if (length <= 1) {
        val0 = get(whereFilter._operators, `${rangeDate.filterFields[0]}.gte`, null);
        val1 = get(whereFilter._operators, `${rangeDate.filterFields[0]}.lte`, null);
      }

      if (length > 1) {
        val0 = whereFilter.OR
          ? get(
              whereFilter.OR.find(
                (item) => Object.keys(item._operators)[0] === rangeDate.filterFields[0]
              ),
              `_operators.${rangeDate.filterFields[0]}.gte`,
              null
            )
          : null;
        val1 = whereFilter.OR
          ? get(
              whereFilter.OR.find(
                (item) => Object.keys(item._operators)[0] === rangeDate.filterFields[1]
              ),
              `_operators.${rangeDate.filterFields[1]}.lte`,
              null
            )
          : null;
      }

      return (
        <Item key={String(index)} label={rangeDate.label} labelAlign="left" {...fixedItemLayout}>
          <UIDateRangePicker
            {...rangeDate.props}
            value={[val0, val1]}
            onChange={(value) => {
              if (rangeDate.onChange) return rangeDate.onChange(value);
              onDateRangeChanged(value, rangeDate.filterFields);
            }}
          />
        </Item>
      );
    });

  const select =
    !isEmpty(config.select) &&
    config.select.map((sl, index) => {
      const isMultiple = sl.props.mode === 'multiple';
      const singleValue = whereFilter[sl.filterField];
      const multiValue = get(whereFilter._operators, `${sl.filterField}.in`, []);
      return (
        <Item key={String(index)} label={sl.label} labelAlign="left" {...fixedItemLayout}>
          {sl.search ? (
            <UISelectSearch
              dataOptions={sl.dataOptions}
              value={isMultiple ? multiValue : singleValue}
              onDeselect={(value, option?) => {
                if (sl.props.onDeselect) return sl.props.onDeselect(value, option);
                onDeselectChanged(value, sl.filterField);
              }}
              onChange={(value) => {
                if (sl.onChange) return sl.onChange(value);
                if (isMultiple) {
                  onMultiSelectChanged(value, sl.filterField);
                } else {
                  onSingleSelectChanged(value, sl.filterField);
                }
              }}
              {...sl.props}
            />
          ) : (
            <UISelect
              dataOptions={sl.dataOptions}
              value={isMultiple ? multiValue : singleValue}
              onDeselect={(value, option?) => {
                if (sl.props.onDeselect) return sl.props.onDeselect(value, option);
                onDeselectChanged(value, sl.filterField);
              }}
              onChange={(value) => {
                if (sl.onChange) return sl.onChange(value);
                if (isMultiple) {
                  onMultiSelectChanged(value, sl.filterField);
                } else {
                  onSingleSelectChanged(value, sl.filterField);
                }
              }}
              {...sl.props}
            />
          )}
        </Item>
      );
    });

  const radio =
    !isEmpty(config.radio) &&
    config.radio.map((rad) => {
      const value = whereFilter[rad.filterField];
      return (
        <Item key={shortId.generate()} label={rad.label} labelAlign="left" {...fixedItemLayout}>
          <Group
            value={value}
            onChange={(val) => {
              if (rad.onChange) return rad.onChange(val);
              onSingleSelectChanged(val.target.value, rad.filterField);
            }}
            {...rad.groupProps}
          >
            {Object.keys(rad.optionsData).map((key) => {
              return (
                <Radio key={shortId.generate()} value={rad.optionsData[key]} {...rad.props}>
                  {key}
                </Radio>
              );
            })}
          </Group>
        </Item>
      );
    });

  const switchFilter =
    !isEmpty(config.switch) &&
    config.switch.map((filterSwitch, index) => {
      const value = whereFilter[filterSwitch.filterField] || false;
      return (
        <Item key={String(index)} label={filterSwitch.label} labelAlign="left" {...formItemLayout}>
          <Switch
            checked={value}
            onChange={(val) => {
              if (filterSwitch.onChange) return filterSwitch.onChange(val);
              onSingleSelectChanged(val, filterSwitch.filterField);
            }}
            {...filterSwitch.props}
          />
        </Item>
      );
    });

  const checkbox =
    !isEmpty(config.checkbox) &&
    config.checkbox.map((checkboxI, index) => {
      const multiValue = get(whereFilter._operators, `${checkboxI.filterField}.in`, []);
      return (
        <Item key={String(index)} label={checkboxI.label} labelAlign="left" {...fixedItemLayout}>
          <Checkbox.Group
            options={checkboxI.groupProps.options}
            value={multiValue as CheckboxValueType[]}
            onChange={(value) => {
              if (checkboxI.onChange) return checkboxI.onChange(value);
              onMultiUncheckChanged(value, checkboxI.filterField);
            }}
          />
        </Item>
      );
    });

  const apply = () => {
    const parsed = parseQuery(location.search) as QueryWhereParams;
    Object.assign(parsed, { where: whereFilter });
    const query = stringifyQuery(parsed);
    history.push({ search: query });
    onFilterChanged(whereFilter);
  };

  const clear = () => {
    const parsed = parseQuery(location.search) as QueryWhereParams;
    if (parsed.where) {
      delete parsed.where;
    }
    const query = stringifyQuery(parsed);
    history.push({ search: query });
    onFilterChanged(clearFilter());
  };

  const buttonName = button && button.buttonName;
  const buttonProps = button && button.props;

  return (
    <>
      <Drawer
        placement="right"
        visible={visible}
        title={title || 'Filter'}
        onClose={() => {
          setVisible(false);
        }}
        width={328}
        bodyStyle={{ height: 'calc(100% - 55px)', display: 'flex', flexFlow: 'column' }}
        {...drawerProps}
      >
        <Form layout="vertical" style={{ flex: 1, height: '90%', overflow: 'auto' }}>
          {dateRange}
          {select}
          {radio}
          {switchFilter}
          {checkbox}
        </Form>
        <div style={{ height: '10%', padding: 10 }}>
          <Button
            style={{ width: '100%', marginBottom: 10 }}
            type="primary"
            onClick={() => apply()}
          >
            Apply
          </Button>
          <Button danger ghost style={{ width: '100%' }} onClick={() => clear()}>
            Clear
          </Button>
        </div>
      </Drawer>
      <Button icon={<FilterOutlined />} onClick={() => setVisible(true)} {...buttonProps}>
        {buttonName}
      </Button>
    </>
  );
});

export default Filter;
