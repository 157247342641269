import { intersection } from 'lodash';
import { action, configure, observable, runInAction } from 'mobx';
import { actionAsync, task } from 'mobx-utils';

import { ROLE_SUPERADMIN } from '@source/common';

import { ErrorNotify } from '~components/UI/Notification';
import { getSdk } from '~graphql/sdk';

import * as _sdk from '../graphql/_sdk';
import authStore from './authStore';

configure({ enforceActions: 'always' });

class UserStore {
  @observable.ref public notifications: _sdk.FullNotificationFragment[];
  @observable public messages: _sdk.FullMessageFragment[];
  @observable public currentUser: _sdk.FullUserFragment;
  @observable public roleIds: string[];

  constructor() {
    runInAction(() => {
      this.currentUser = null;
      this.notifications = [];
      this.roleIds = [];
    });
  }

  // @actionAsync
  // public clearBannerTemp = async () => {
  //   const bannerId = window.localStorage.getItem('banner');
  //   if (bannerId) {
  //     const sdk = await task(getSdk());
  //     await task(sdk.removeBannerTempById({ _id: bannerId }));
  //     const {
  //       removeFileById: { data, error }
  //     } = await task(sdk.removeFileById({ id: bannerId }));
  //     if (error) {
  //       ErrorNotify(error.code);
  //     }
  //     if (data) {
  //       window.localStorage.removeItem('banner');
  //     }
  //   }
  // };

  @actionAsync
  public getCurrentUser = async () => {
    const sdk = await task(getSdk());
    const {
      me: { error, data }
    } = await task(sdk.me());

    if (error) {
      ErrorNotify(error.code);
      await authStore.logout();
    } else {
      this.setCurrentUser(data);
      authStore.setAuthenticated(true);
      // this.clearBannerTemp();
    }
  };

  @action
  public setCurrentUser = (user: _sdk.FullUserFragment) => {
    this.currentUser = user;
    this.roleIds = user.profile.roleIds || [];
    // if (this.currentUser) {
    //   this.fetchNotification(this.currentUser._id);
    //   this.fetchMessage(this.currentUser._id);
    // }
  };

  @action
  public isAuthorizedRoles = (roles: string[]) => {
    if (!this.currentUser) return false;
    const {
      profile: { roleIds }
    } = this.currentUser;
    if (roleIds.includes(ROLE_SUPERADMIN)) return true;

    const checkRoles = intersection(roleIds, roles);
    return checkRoles.length > 0;
  };

  // @actionAsync
  // public fetchNotification = async (_id) => {
  //   const sdk = await task(getSdk());
  //   const { findManyNotification } = await task(
  //     sdk.findManyNotification({ where: { userId: _id } })
  //   );
  //   this.notifications = findManyNotification;
  // };

  // @actionAsync
  // public fetchMessage = async (_id) => {
  //   const sdk = await task(getSdk());
  //   const { findManyMessage } = await task(sdk.findManyMessage({ where: { receiverId: _id } }));
  //   this.messages = findManyMessage;
  // };
}

export default new UserStore();
