/* eslint-disable array-callback-return */
import i18next from 'i18next';
import { debounce, get, groupBy } from 'lodash';
import { action, computed, observable, runInAction } from 'mobx';
import { actionAsync, task } from 'mobx-utils';

import { GridReadyEvent } from '@ag-grid-community/core';
import { WhereArgs } from '@source/common';

import { ErrorNotify, SuccessNotify } from '~components/UI/Notification';
import { Profile, ProfileInput, ProfileUpdateArg, Role } from '~graphql/_sdk';
import { getSdk } from '~graphql/sdk';

class ProfileStore {
  @observable public where: WhereArgs;
  @observable public isShowForm: boolean;
  @observable public roles: Role[];
  @observable public createdRoleIds: string[];
  @observable public createdMenuIds: string[];
  @observable public selectedItem: Profile;
  @observable public loading: boolean;

  constructor() {
    this.reset();
  }

  @action
  public reset = () => {
    this.roles = [];
    this.loading = false;
    this.where = {};
    this.isShowForm = false;
    this.createdMenuIds = [];
    this.createdRoleIds = [];
    this.selectedItem = null;
  };

  @action
  public handleSubmitRecord = (record) => {
    // const profileId = `${record.display.replace(' ', '_')}-${this.genId(4)}`;
    const submitRecord = {
      ...record,
      roleIds: this.createdRoleIds,
      menuRoleIds: this.createdMenuIds
    };

    if (this.selectedItem) {
      this.updateProfileById(this.selectedItem._id, submitRecord);
    } else {
      // Object.assign(submitRecord, { profileId });
      this.createProfile(submitRecord);
    }
    this.reload();
    this.createdRoleIds = [];
    this.createdMenuIds = [];
  };

  @action
  public setIsShowForm = (value: boolean) => {
    this.isShowForm = value;
    if (!value) {
      this.selectedItem = null;
      this.createdRoleIds = [];
      this.createdMenuIds = [];
    }
  };

  @action
  public handleInitEdit = (id: string) => {
    this.setIsShowForm(true);
    this.fetchProfileById(id);
  };

  @action
  public setCreatedRoleIds = (ids: string[]) => {
    this.createdRoleIds = ids;
  };

  @action
  public setCreatedMenuIds = (ids: string[]) => {
    this.createdMenuIds = ids;
  };

  @action
  public setSearch = debounce(
    (text: string) => {
      runInAction(() => {
        this.where = { ...this.where, _search: text };
        this.reload();
      });
    },
    500,
    { leading: false, trailing: true }
  );

  @action
  public handleGridReady = ({ api }: GridReadyEvent) => {
    this.reload = () => {
      api.onFilterChanged();
    };
  };

  @action
  reload = () => undefined;

  @actionAsync
  public removeProfileById = async (id: string) => {
    const sdk = await task(getSdk());
    const { removeProfileById } = await task(sdk.removeProfileById({ _id: id }));
    if (removeProfileById.data) {
      SuccessNotify(i18next.t('DELETE'));
    } else {
      ErrorNotify(i18next.t('DELETE'));
    }
    this.reload();
  };

  @actionAsync
  public fetchRoles = async () => {
    if (this.roles && this.roles.length > 0) return;
    const sdk = await task(getSdk());
    const { findManyRole } = await task(sdk.findManyRole());
    this.roles = findManyRole;
  };

  @actionAsync
  public createProfile = async (record: ProfileInput) => {
    const sdk = await task(getSdk());
    const { createProfile } = await task(sdk.createProfile({ record }));

    if (createProfile.data) {
      SuccessNotify(i18next.t('CREATE'));
    } else {
      ErrorNotify(i18next.t('CREATE'));
    }
    this.setIsShowForm(false);
  };

  @actionAsync
  public updateProfileById = async (id: string, record: ProfileUpdateArg) => {
    const sdk = await task(getSdk());
    const { updateProfileById } = await task(sdk.updateProfileById({ _id: id, record }));
    if (updateProfileById.data) {
      SuccessNotify(i18next.t('UPDATE'));
    } else {
      ErrorNotify(i18next.t('UPDATE'));
    }
    this.setIsShowForm(false);
  };

  @actionAsync
  public fetchProfileById = async (id: string) => {
    const sdk = await task(getSdk());
    const { findProfileById } = await task(sdk.findProfileById({ _id: id }));
    if (!findProfileById) return;
    this.selectedItem = findProfileById;
    this.createdRoleIds = findProfileById.roleIds;
    this.createdMenuIds = findProfileById.menuRoleIds;
  };

  @actionAsync
  public updateRoleDescriptionById = async (_id: string, description: string) => {
    const sdk = await task(getSdk());
    const { updateRoleDescriptionById } = await task(
      sdk.updateRoleDescriptionById({ _id, description })
    );
    if (updateRoleDescriptionById.data) {
      SuccessNotify('Edit description');
    } else {
      ErrorNotify('Edit description');
    }
  };

  @computed
  public get getMenuList() {
    const menuRoles = this.roles.filter((role) => role.name.includes('MENU_'));
    const menuList = groupBy(menuRoles, 'group');

    return menuList;
  }

  @computed
  public get getRoleList() {
    const roles = this.roles.filter((role) => !role.name.includes('MENU_'));

    const roleGroups = groupBy(roles, 'group');
    return roleGroups;
  }

  // public genId(length) {
  //   let result = '';
  //   const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  //   const charactersLength = characters.length;
  //   for (let i = 0; i < length; i += 1) {
  //     result += characters.charAt(Math.floor(Math.random() * charactersLength));
  //   }
  //   return result;
  // }
}

export default new ProfileStore();
