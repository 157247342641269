import { notification } from 'antd';
import i18next from 'i18next';

notification.config({
  placement: 'bottomLeft',
  duration: 3
});

type NotifyType = 'success' | 'info' | 'warning' | 'error';

const showNotify = (type: NotifyType, message: string, description = '') => {
  notification[type]({
    message,
    description
  });
};

export const SuccessNotify = (message: string, description?: string) =>
  showNotify('success', `${message} ${i18next.t('notify.SUCCESS')}`, description);

export const ErrorNotify = (message: string, description?: string) =>
  showNotify('error', `${message}`, description);

export const WarningNotify = (message: string, description?: string) =>
  showNotify('warning', message, description);

export const InfoNotify = (message: string, description?: string) =>
  showNotify('info', message, description);
