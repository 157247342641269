import { Button, Dropdown, Menu, Modal, Popconfirm, Typography } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ICellRendererParams } from '@ag-grid-community/core';
import {
  AreaChartOutlined,
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  Html5Outlined,
  IeOutlined,
  InfoOutlined,
  LikeOutlined,
  PictureOutlined,
  SettingOutlined,
  StopOutlined
} from '@ant-design/icons';
import {
  ACTIVE,
  COMING_SOON,
  DISABLED,
  ROLE_DELETE_LOCATION,
  ROLE_TRACKING_CHART_REPORT_LOCATION,
  ROLE_UPDATE_ADVANCE_LOCATION,
  ROLE_UPDATE_BASIC_LOCATION,
  ROLE_UPDATE_SALE_INFO_LOCATION,
  ROLE_UPDATE_STATUS_LOCATION
} from '@source/common';

import Authorized from '~components/Authorized/Authorized';
import { ReportLocation } from '~screens/routes';

import store from './store';

interface CellActionRenderProp extends ICellRendererParams, WithTranslation {}

@observer
class CellAction extends React.Component<CellActionRenderProp> {
  public render() {
    const { confirm } = Modal;
    const { Text } = Typography;
    const {
      setModalVisible,
      setGeneratorVisible,
      updateStatus,
      remove,
      setDrawerVisible,
      preview
    } = store;
    const { data, value, t, i18n } = this.props;

    const showDeleteConfirm = (value, text: string) => {
      confirm({
        width: 500,
        title: (
          <div>
            {i18n.t('ARE_YOU_SURE_DELETE')} <b>{text.toUpperCase()}</b> ?
            <br />
            <p>
              If this location is deleted, all reports about this location will be deleted and
              cannot be restored !
            </p>
          </div>
        ),
        okText: 'YES',
        okType: 'danger',
        cancelText: 'KO',
        onOk() {
          remove(value);
        }
      });
    };

    const showDisbaleConfirm = (data) => {
      confirm({
        title: (
          <div>
            {i18n.t('DISBALE_LOCATION')} <b>{data.name.toUpperCase()}</b> ?
          </div>
        ),
        icon: <StopOutlined />,
        content: () => {
          return <Text>When Disable, Location will not Render any Ads</Text>;
        },
        okText: t('OK'),
        okType: 'primary',
        cancelText: t('CANCEL'),
        async onOk() {
          try {
            return updateStatus(data._id, DISABLED);
          } catch (e) {
            return console.log('Oops errors!');
          }
        }
      });
    };

    const moreActions = (
      <Menu>
        <Authorized roles={[ROLE_TRACKING_CHART_REPORT_LOCATION]}>
          <Menu.Item key="viewADS" style={{ color: 'blue' }}>
            <Link to={`${ReportLocation.path}?_id=${encodeURIComponent(JSON.stringify([value]))}`}>
              <AreaChartOutlined /> {t('location.REPORT_TRAFFIC')}
            </Link>
          </Menu.Item>
        </Authorized>

        <Authorized roles={[ROLE_TRACKING_CHART_REPORT_LOCATION]}>
          <Menu.Divider />
        </Authorized>

        <Menu.Item
          key="viewADS"
          style={{ color: 'green' }}
          onClick={() => {
            preview(data);
          }}
        >
          <IeOutlined />
          {t('location.VIEW_ADS')}
        </Menu.Item>
        <Menu.Item
          key="2"
          style={{ color: 'green' }}
          onClick={() => {
            setGeneratorVisible(data);
          }}
        >
          <Html5Outlined />
          {t('GENERATE_HTML')}
        </Menu.Item>

        <Authorized roles={[ROLE_UPDATE_ADVANCE_LOCATION, ROLE_UPDATE_SALE_INFO_LOCATION]}>
          <Menu.Divider />
        </Authorized>

        <Authorized roles={[ROLE_UPDATE_ADVANCE_LOCATION]}>
          <Menu.Item
            key="advance"
            style={{ color: 'blue' }}
            onClick={() => {
              store.setModalVisible(data, 'advanced-info');
            }}
          >
            <SettingOutlined />
            {t('ADVANCE')}
          </Menu.Item>
        </Authorized>

        <Authorized roles={[ROLE_UPDATE_SALE_INFO_LOCATION]}>
          <Menu.Item
            key="saleEdit"
            onClick={() => setModalVisible(data, 'saleInfo')}
            style={{ color: 'blue' }}
          >
            <EditOutlined />
            Sale Info
          </Menu.Item>
        </Authorized>

        <Authorized roles={[ROLE_UPDATE_STATUS_LOCATION, ROLE_DELETE_LOCATION]}>
          <Menu.Divider />
        </Authorized>

        {data && data.status !== 'disabled' && (
          <Authorized roles={[ROLE_UPDATE_STATUS_LOCATION]}>
            <Menu.Item key="3" onClick={() => showDisbaleConfirm(data)} style={{ color: 'red' }}>
              <StopOutlined />
              {t('DISABLE')}
            </Menu.Item>
          </Authorized>
        )}
        {data && data.status !== 'active' && (
          <Authorized roles={[ROLE_UPDATE_STATUS_LOCATION]}>
            <Menu.Item key="5" onClick={() => updateStatus(value, ACTIVE)}>
              <LikeOutlined />
              {t('ENABLE')}
            </Menu.Item>
          </Authorized>
        )}

        {data && data.status === 'disabled' ? (
          <Authorized roles={[ROLE_DELETE_LOCATION]}>
            <Menu.Item
              key="1"
              onClick={() => showDeleteConfirm(value, data.name)}
              style={{ color: 'red' }}
            >
              <DeleteOutlined />
              {t('DELETE')}
            </Menu.Item>
          </Authorized>
        ) : null}
      </Menu>
    );

    let activeColor = '#a1a1a1';
    switch (data.status) {
      case ACTIVE:
        activeColor = '#52c41a';
        break;
      case COMING_SOON:
        activeColor = '#faad14';
        break;
      default:
        break;
    }

    return (
      <Button.Group size="small">
        <Authorized roles={[ROLE_UPDATE_BASIC_LOCATION]}>
          <Button icon={<EditOutlined />} onClick={() => setModalVisible(data, 'basic')} />
        </Authorized>
        <Button
          style={{ backgroundColor: activeColor, color: '#fff' }}
          onClick={() => setDrawerVisible(data)}
        >
          <i className="fas fa-info-circle fa-1x" />
        </Button>
        <Dropdown overlay={moreActions}>
          <Button icon={<EllipsisOutlined />} type="default" />
        </Dropdown>
      </Button.Group>
    );
  }
}

const CellActionRender = withTranslation()(CellAction);

export default CellActionRender;
