import '@ant-design/compatible/assets/index.css';

import { Checkbox, Col, Form, Radio, Row, Switch } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { DrawerProps } from 'antd/lib/drawer';
import { get, isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import shortId from 'shortid';

import { WhereArgs } from '@source/common';

import { UISelect } from '~components/UI/Select/UISelect';
import { UISelectSearch } from '~components/UI/Select/UISelectSearch';
import { UIDateRangePicker } from '~components/UI/UIDateRangePicker';
import { parseQuery, stringifyQuery } from '~utils/query-string';

import { FilterStore } from './filterStore';
import { FilterConfig } from './filterType';

const { Item } = Form;
const { Group } = Radio;

interface FilterButton {
  buttonName?: string;
  props?: ButtonProps;
}

interface FilterProps {
  config?: FilterConfig;
  onFilterChanged?: (where: WhereArgs) => void;
  button?: FilterButton;
  title?: string;
  drawerProps?: DrawerProps;
  initialValues?: WhereArgs;
  span?: number;
  flex?: string;
}

interface QueryWhereParams {
  where: object;
}

const fixedItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 }
  }
};
const UIFilterHorizontal: FC<FilterProps> = observer(props => {
  const { config, onFilterChanged, span, flex } = props;
  const [store] = useState(() => new FilterStore());
  const {
    whereFilter,
    onMultiSelectChanged,
    onSingleSelectChanged,
    onMultiUncheckChanged,
    init,
    onDeselectChanged
  } = store;

  const location = useLocation();
  const history = useHistory();

  const initialFilter = parseQuery(location.search) as QueryWhereParams;

  const cachedInitialValues = useRef(undefined);

  const apply = () => {
    const parsed = parseQuery(location.search) as QueryWhereParams;
    Object.assign(parsed, { where: store.selectFilter });
    const query = stringifyQuery(parsed);
    history.push({ search: query });
    onFilterChanged(whereFilter);
  };

  useEffect(() => {
    apply();
  }, [store.selectFilter]);

  useEffect(() => {
    const filter = initialFilter.where;
    if (filter) {
      init(filter);
      onFilterChanged(filter);
    }
  }, []);

  useEffect(() => {
    if (JSON.stringify(props.initialValues) !== cachedInitialValues.current) {
      init(props.initialValues);
      onFilterChanged(props.initialValues);

      cachedInitialValues.current = JSON.stringify(props.initialValues);

      const parsed = parseQuery(location.search) as QueryWhereParams;
      Object.assign(parsed, { where: props.initialValues });

      const query = stringifyQuery(parsed);
      history.push({ search: query });
    }
  }, [props.initialValues]);

  const select =
    !isEmpty(config.select) &&
    config.select.map((sl, index) => {
      const isMultiple = sl.props.mode === 'multiple';
      const singleValue = whereFilter[sl.filterField];
      const multiValue = get(whereFilter._operators, `${sl.filterField}.in`, []);
      return (
        <Col {...{ span, flex }} key={String(index)}>
          <Item
            // label={sl.label}
            labelAlign="left"
            {...fixedItemLayout}
            key={String(index)}
          >
            {sl.search ? (
              <UISelectSearch
                style={{ minWidth: sl.width || 130 }}
                dataOptions={sl.dataOptions}
                value={isMultiple ? multiValue : singleValue}
                onDeselect={(value, option?) => {
                  if (sl.props.onDeselect.length === 1) {
                    sl.props.onDeselect(value, option);
                  }
                  onDeselectChanged(value, sl.filterField);
                }}
                onChange={value => {
                  if (sl.onChange.length === 1) {
                    sl.onChange(value);
                  }
                  if (isMultiple) {
                    onMultiSelectChanged(value, sl.filterField);
                  } else {
                    onSingleSelectChanged(value, sl.filterField);
                  }
                }}
                {...sl.props}
              />
            ) : (
              <UISelect
                style={{ minWidth: sl.width || 130 }}
                dataOptions={sl.dataOptions}
                value={isMultiple ? multiValue : singleValue}
                onDeselect={(value, option?) => {
                  if (sl.props.onDeselect) {
                    sl.props.onDeselect(value, option);
                  }
                  onDeselectChanged(value, sl.filterField);
                }}
                onChange={value => {
                  if (sl.onChange) {
                    sl.onChange(value);
                  }
                  if (isMultiple) {
                    onMultiSelectChanged(value, sl.filterField);
                  } else {
                    onSingleSelectChanged(value, sl.filterField);
                  }
                }}
                {...sl.props}
              />
            )}
          </Item>
        </Col>
      );
    });

  const radio =
    !isEmpty(config.radio) &&
    config.radio.map((rad, index) => {
      const value = whereFilter[rad.filterField];
      return (
        <>
          <Col {...{ span, flex }} key={String(index)}>
            <Group
              value={value}
              onChange={val => {
                if (rad.onChange) {
                  rad.onChange(val);
                }
                onSingleSelectChanged(val.target.value, rad.filterField);
              }}
              {...rad.groupProps}
            >
              {Object.keys(rad.optionsData).map(key => {
                return (
                  <Radio key={shortId.generate()} value={rad.optionsData[key]} {...rad.props}>
                    {key}
                  </Radio>
                );
              })}
            </Group>
          </Col>
        </>
      );
    });

  const switchFilter =
    !isEmpty(config.switch) &&
    config.switch.map((filterSwitch, index) => {
      const value = whereFilter[filterSwitch.filterField] || false;
      return (
        <>
          <Col {...{ span, flex }} key={String(index)}>
            <Switch
              checked={value}
              onChange={val => {
                if (filterSwitch.onChange) {
                  filterSwitch.onChange(val);
                }
                onSingleSelectChanged(val, filterSwitch.filterField);
              }}
              {...filterSwitch.props}
            />
          </Col>
        </>
      );
    });

  const checkbox =
    !isEmpty(config.checkbox) &&
    config.checkbox.map((checkboxI, index) => {
      const multiValue = get(whereFilter._operators, `${checkboxI.filterField}.in`, []);
      return (
        <>
          <Col {...{ span, flex }} key={String(index)}>
            <Checkbox.Group
              options={checkboxI.groupProps.options}
              value={multiValue as CheckboxValueType[]}
              onChange={value => {
                if (checkboxI.onChange) {
                  checkboxI.onChange(value);
                }
                onMultiUncheckChanged(value, checkboxI.filterField);
              }}
            />
          </Col>
        </>
      );
    });

  return (
    <Row gutter={8} justify="end">
      {select}
      {radio}
      {switchFilter}
      {checkbox}
    </Row>
  );
});

export default UIFilterHorizontal;
