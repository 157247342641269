/* eslint-disable no-shadow */
import i18next from 'i18next';
import { cloneDeep, debounce, get, groupBy, isEmpty, merge, omit, set, sumBy } from 'lodash';
import { action, computed, observable, reaction, runInAction } from 'mobx';
import { actionAsync, task } from 'mobx-utils';
import moment, { Moment } from 'moment';

import { LocationStatus, mapReduce, MGN } from '@source/common';

import { ErrorNotify, SuccessNotify } from '~components/UI/Notification';
import { CampaignInput, CommonLocationFragment, FullLocationFragment } from '~graphql/_sdk';
import { getSdk } from '~graphql/sdk';

interface BookingRange {
  from: Moment;
  to: string;
}

interface CheckedLocations extends FullLocationFragment {
  availableTotalKpi?: number;
}

export class CampaignBookingStore {
  @observable public loading: boolean;
  @observable public isSelectCategory: boolean;
  @observable public isFormValid: boolean;
  @observable public optimizeType: string;
  @observable public categoryName: string;
  @observable public step: number;
  @observable public locationData;
  @observable public bookingRangeDate: BookingRange;
  @observable public filter: any;
  @observable public availableKpiLoading: boolean;
  @observable public bookingCategory: any;
  @observable public isAllLocationBooked: boolean;
  @observable public totalBooking: number;
  @observable public ownerType: string;
  @observable public campaignTemp: CampaignInput;
  @observable public statusBtn: boolean;
  @observable.ref public groupLocation: any;
  @observable.ref public groupCheckedLocation: any;
  @observable.ref public locationCheckedList: CheckedLocations[];
  @observable.ref public locations: CommonLocationFragment[];
  @observable public budgetBooking: number;
  @observable public value: number;
  @observable public disabled: boolean;
  @observable public visibleBtn: boolean;
  @observable public bookingKpi: number;

  private initialized: boolean;

  constructor() {
    runInAction(() => {
      this.loading = true;
      this.locations = [];
    });
    this.initObservable();

    reaction(
      () => this.filteredLocation,
      (filtered) => {
        this.groupLocation = groupBy(filtered, 'categoryId');
      }
    );

    reaction(
      () => this.locationCheckedList,
      (locationCheckedList) => {
        this.groupCheckedLocation = groupBy(locationCheckedList, 'categoryId');

        this.isAllLocationBooked = Object.keys(this.groupCheckedLocation).every((cate) =>
          this.groupCheckedLocation[cate].every((location) => {
            return get(location, 'availableTotalKpi') <= 0 || get(location, 'kpi');
          })
        );
        this.totalBooking = sumBy(this.locationCheckedList, 'kpi');
      }
    );

    reaction(
      () => this.bookingCategory,
      () => {
        this.isAllLocationBooked = Object.keys(this.groupCheckedLocation).every((cate) =>
          this.groupCheckedLocation[cate].every((location) => {
            return get(location, 'availableTotalKpi') <= 0 || get(location, 'kpi') >= 0;
          })
        );
        this.totalBooking = sumBy(this.locationCheckedList, 'kpi');
      }
    );
  }

  @action public setValueKpi = (e) => {
    this.value = e;
  };

  @action
  public resetField = () => {
    this.totalBooking = 0;
    this.locationCheckedList.map((location) => {
      set(location, 'kpi', 0);
      set(location, 'availableTotalKpi', undefined);
      return location;
    });
    this.bookingCategory = {};
  };

  @action public setDisabled = () => {
    if (this.totalBooking > 0) {
      this.disabled = false;
    }
  };

  @action
  public setTotalBooking = (e) => {
    this.totalBooking = e;
    if (this.totalBooking === 0) {
      this.disabled = true;
      this.noEndAutoKpi();
      this.statusBtn = true;
      this.resetField();
    }
  };

  @action
  public setBookingKPI = (e) => {
    this.bookingKpi = e;
    if (this.bookingKpi === 0) {
      this.resetField();
    }
  };

  @computed
  public get groupCheckedCategory() {
    const groupLocation = cloneDeep(get(this, 'groupLocation', {}));
    const grouplocChecked = groupBy(this.locationCheckedList, 'categoryId');

    Object.keys(groupLocation).forEach((group) => {
      if (groupLocation[group].length !== get(grouplocChecked[group], 'length', 0)) {
        set(groupLocation, group, 0);
      }
    });
    return groupLocation;
  }

  @computed
  public get filteredLocation() {
    if (!isEmpty(this.filter)) {
      const filter = cloneDeep(this.filter);
      const filterWithoutSearch = omit(filter, ['search', '_search']);
      const locations = Object.keys(filterWithoutSearch).reduce((all, item) => {
        if (!filterWithoutSearch[item] || !filterWithoutSearch[item].length) return all;
        all = all.filter((a) => filterWithoutSearch[item].includes(a[item]));
        return all;
      }, this.locations);

      if (!isEmpty(get(this.filter, '_search'))) {
        const re = new RegExp(`${this.filter._search}`, 'i');

        return locations.filter((l) => re.test(l.name));
      }
      return locations;
    }
    return this.locations;
  }

  @computed
  get filteredCheckedLocation() {
    if (!isEmpty(get(this.filter, 'search'))) {
      const re = new RegExp(`${this.filter.search}`, 'gi');
      return this.locationCheckedList.filter((l) => re.test(l.name));
    }
    return this.locationCheckedList;
  }

  @action
  public getBudgetBooking(value) {
    this.budgetBooking = value;
  }

  @action
  public init = (forceReinit = false) => {
    if (this.initialized && !forceReinit) return;
    this.loading = true;
    this.loadAllLocations().finally(
      action(() => {
        this.loading = false;
        this.initialized = true;
      })
    );
  };

  @action
  public initObservable = () => {
    this.disabled = true;
    this.visibleBtn = true;
    this.value = 0;
    this.statusBtn = true;
    this.budgetBooking = -1;
    this.isSelectCategory = false;
    this.locationData = null;
    this.filter = { ownerType: MGN };
    this.isFormValid = false;
    this.locationCheckedList = [];
    this.bookingRangeDate = {
      from: moment().startOf('days'),
      to: undefined
    };
    this.bookingCategory = {};
    this.optimizeType = 'CPC';
    this.step = 0;
    this.availableKpiLoading = false;
    this.isAllLocationBooked = false;
    this.totalBooking = 0;
    this.bookingKpi = 0;
    this.ownerType = MGN;
    this.campaignTemp = {};
  };

  @action reset = () => {
    this.initObservable();
  };

  @action public setVisibleBtn = () => {
    this.visibleBtn = !this.visibleBtn;
  };

  @action public changeStatusBtn = (_id, kpi, ref) => {
    this.value = kpi;
    this.statusBtn = _id;
    this.setVisibleBtn();
    setTimeout(() => {
      ref.focus();
    }, 50);
  };

  @action
  public setGroupCheckedLocation = () => {
    set(this.groupCheckedCategory, this.categoryName, 1);
  };

  @action public setCheckedList = (list) => {
    this.locationCheckedList = list;
  };

  @action public setOwnerType = (ownerType) => {
    this.ownerType = ownerType;
  };

  public setTotalKPI = debounce(
    (booking, cate) => {
      if (typeof booking !== 'number') {
        return;
      }
      runInAction(() => {
        this.bookingCategory = { ...this.bookingCategory, [cate]: booking < 0 ? 0 : booking };
      });
    },
    200,
    { leading: false, trailing: true }
  );

  @action
  public setKPILocation = debounce(
    (kpi, _id, cate) => {
      const idx = this.locationCheckedList.findIndex((i) => i._id === _id);
      const item = this.locationCheckedList[idx];

      const availableTotalKpi = get(item, 'availableTotalKpi');

      // plus to TotalKPI everytime a KPILocation changed
      if (availableTotalKpi > 0 && this.bookingCategory[cate]) {
        const kpiToPlus = kpi - get(item, 'kpi', 0);
        const bookingCate = this.bookingCategory[cate] + kpiToPlus;
        this.setTotalKPI(bookingCate, cate);
      }

      if (availableTotalKpi) {
        if (kpi > availableTotalKpi) {
          set(item, 'kpi', item.availableTotalKpi);
        }
        if (kpi <= 0) {
          set(item, 'kpi', 0);
        } else {
          set(item, 'kpi', kpi);
        }
      } else if (kpi <= 0) {
        set(item, 'kpi', 0);
      } else {
        set(item, 'kpi', kpi);
      }

      // switch (true) {
      //   case !availableTotalKpi && kpi <= 0:
      //     set(item, 'kpi', 0);
      //     break;
      //   case availableTotalKpi && kpi > 0:
      //     if (kpi > availableTotalKpi) {
      //       set(item, 'kpi', item['availableTotalKpi']);
      //       break;
      //     }
      //     set(item, 'kpi', kpi);
      //     break;
      //   default:
      //     console.log('Exception Found! ');
      //     break;
      // }

      runInAction(() => {
        this.locationCheckedList = [
          ...this.locationCheckedList.slice(0, idx),
          item,
          ...this.locationCheckedList.slice(idx + 1)
        ];
      });
    },
    200,
    { leading: false, trailing: true }
  );

  @action public setSelectCategory = (name, isChecked = false) => {
    if (isChecked) {
      this.isSelectCategory = !this.isSelectCategory;
    }
    this.categoryName = name;
  };

  @action public setOptimizeType = (type) => {
    this.optimizeType = type;
  };

  @action public setCurrentSteps = (step) => {
    this.step = step;
  };

  @action
  public setLocationDataGroup = (data) => {
    this.locationData = data;
  };

  @action
  public setDateRange = (date, options = 'from') => {
    if (options === 'from') {
      this.bookingRangeDate.from = date;
    } else {
      this.bookingRangeDate.to = date;
    }
  };

  @action
  public setFilter = (filter) => {
    runInAction(() => {
      this.filter = { ...this.filter, ...filter };
    });
  };

  @action
  public clearFilter = () => {
    this.filter = { ownerType: MGN };
  };

  @actionAsync
  public loadAllLocations = async () => {
    if (!this.locations.length) {
      const sdk = await task(getSdk());
      const { findManyLocation } = await task(
        sdk.findCommonLocation({ where: { status: LocationStatus.ACTIVE } })
      );
      this.locations = findManyLocation;
    }
  };

  @action
  public updateCampaignTemp = (campaign: CampaignInput) => {
    Object.assign(this.campaignTemp, campaign);
  };

  @actionAsync
  public bookNewCampaign = async (campaign: CampaignInput) => {
    const record = merge(this.campaignTemp, campaign);
    const sdk = await task(getSdk());

    if (record.scheduleTo) {
      record.scheduleTo = moment(record.scheduleTo).startOf('day').toISOString();
    }

    const {
      createCampaign: { data, error }
    } = await task(sdk.createCampaign({ record }));

    if (data) {
      this.setCurrentSteps(this.step + 1);
      SuccessNotify(i18next.t('CREATE_CAMPAIGN'));
      this.setCheckedList([]);
      this.setOptimizeType(null);

      this.bookingCategory = {};
      this.setDateRange(null, 'to');
    }
    if (error) ErrorNotify(error.code);
  };

  @actionAsync
  public getAvailableBooking = async (locationIds, date) => {
    this.availableKpiLoading = true;
    const sdk = await task(getSdk());
    const { getAvailableBooking } = await task(
      sdk.getAvailableBooking({
        locationIds,
        date: { from: this.bookingRangeDate.from, to: date }
      })
    );

    const locations = getAvailableBooking.locations || [];

    const dataMap = mapReduce(locations, '_id', ({ availableTotalKpi }) =>
      Math.max(availableTotalKpi, 0)
    );
    this.locationCheckedList.forEach((item) => {
      if (item._id in dataMap) {
        set(item, 'availableTotalKpi', dataMap[item._id]);
      }
    });
    this.availableKpiLoading = false;
  };

  getExpectedKpis(available, selected) {
    // eslint-disable-next-line dot-notation
    const detailKpi = sumBy(selected, (item) => ~~item['kpi'] || 1);
    let remain = available;
    const roundFn = available > 0 ? Math.ceil : Math.floor;
    return selected.map((item) => {
      const kpi = get(item, 'kpi');
      const availableKpi = get(item, 'availableTotalKpi');
      const expect = roundFn(((~~kpi || 1) / detailKpi) * available);
      let result = 0;
      if (!this.bookingRangeDate.to) {
        result = Math.min(expect, remain);
      } else if (available > 0 && availableKpi > 0) {
        result = Math.min(expect, availableKpi - ~~kpi, remain);
      }

      if (available < 0) {
        result = Math.max(expect, -~~kpi, remain);
      }

      remain -= result;
      return result;
    });
  }

  @actionAsync
  public autoKpi = async (category) => {
    const booking = this.bookingCategory[category];
    const selectedLocations = this.groupCheckedLocation[category].map((location) => {
      const availableTotalKpi = get(location, 'availableTotalKpi');
      const kpi = get(location, 'kpi');
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(kpi)) {
        set(location, 'kpi', 0);
      }
      if (availableTotalKpi < 0) {
        set(location, 'kpi', 0);
      } else if (kpi > availableTotalKpi) {
        set(location, 'kpi', availableTotalKpi);
      }
      return location;
    });
    const currentBooking = sumBy(selectedLocations, 'kpi');
    const totalAvailableKPI = sumBy(selectedLocations, (item) =>
      // eslint-disable-next-line dot-notation
      ~~item['availableTotalKpi'] < 0 ? 0 : ~~item['availableTotalKpi']
    );
    const maxKpi = Math.min(booking, totalAvailableKPI);
    const selected = [...selectedLocations];
    let available = maxKpi - currentBooking;
    while (available !== 0 && selected.length) {
      const expectedKpis = this.getExpectedKpis(available, selected);
      available -= sumBy(expectedKpis);
      for (let i = 0; i < selected.length; i += 1) {
        const item = selected[i];
        item.kpi += expectedKpis[i];
        if (item.kpi >= item.availableKpi || !expectedKpis[i]) {
          selected.splice(i, 1);
          expectedKpis.splice(i, 1);
          i -= 1;
        }
      }
    }

    this.setTotalKPI(
      // eslint-disable-next-line dot-notation
      sumBy(selectedLocations, (item) => ~~item['kpi']),
      category
    );
  };

  @actionAsync
  public noEndAutoKpi = async () => {
    const selectedLocations: any = this.locationCheckedList.map((location) => {
      set(location, 'kpi', 0);
      return location;
    });
    const currentBooking = sumBy(selectedLocations, 'kpi');
    const maxKpi = this.totalBooking;

    const selected = [...selectedLocations];

    let available = maxKpi - currentBooking;

    while (available !== 0 && selected.length) {
      const expectedKpis = this.getExpectedKpis(available, selected);
      available -= sumBy(expectedKpis);
      for (let i = 0; i < selected.length; i += 1) {
        const item = selected[i];
        item.kpi += expectedKpis[i];
        if (!expectedKpis[i]) {
          selected.splice(i, 1);
          expectedKpis.splice(i, 1);
          i -= 1;
        }
        this.setTotalKPI(
          // eslint-disable-next-line dot-notation
          sumBy(selectedLocations, (item) => ~~item['kpi']),
          selected[i].categoryId
        );
      }
    }
  };
}

export default new CampaignBookingStore();
