import { InputNumber } from 'antd';
import { throttle } from 'lodash';
import React, { FC, useEffect, useState } from 'react';

import { formatNumber } from '~utils/formatNumber';

interface EditableCellProps {
  handleSave: Function;
  record: any;
  value: number;
  editable: boolean;
  editField: string;
}

const EditableCell: FC<EditableCellProps> = props => {
  const { record, handleSave, value, editable, editField, ...restProps } = props;
  const [inputValue, setInputValue] = useState(value || 0);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const save = (editedValue: number) => {
    const { key } = record;
    handleSave(key, record, editField, editedValue);
  };

  const handleOnChange = throttle(e => {
    setInputValue(e);
    save(e);
  }, 300);

  const renderCell = () => {
    return (
      <InputNumber
        style={{ maxWidth: 150 }}
        onChange={e => handleOnChange(e)}
        value={inputValue}
        formatter={formatNumber}
        min={0}
      />
    );
  };
  return <>{editable ? renderCell() : inputValue}</>;
};

export default EditableCell;
