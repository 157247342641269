import { GraphQLClient } from 'graphql-request';
import { print } from 'graphql';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
};

export type ActiveCampaign = {
  _id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  isBookOnline?: Maybe<Scalars['Boolean']>;
  typeBrand?: Maybe<Array<Scalars['String']>>;
  categoryId?: Maybe<Scalars['String']>;
  scheduleFrom?: Maybe<Scalars['DateTime']>;
  scheduleTo?: Maybe<Scalars['DateTime']>;
  kpi?: Maybe<Scalars['Float']>;
  kpiType?: Maybe<Scalars['String']>;
  budget?: Maybe<Scalars['Float']>;
  balanceSource?: Maybe<BalanceType>;
  locations?: Maybe<Array<CampaignLocation>>;
  config?: Maybe<CampaignConfig>;
  displayType?: Maybe<Scalars['String']>;
  times?: Maybe<Scalars['String']>;
  timesOfClick?: Maybe<Scalars['Float']>;
  revenue?: Maybe<Scalars['JSON']>;
  revenueStatus?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
  forceStopped?: Maybe<Scalars['Boolean']>;
  stoppedUntil?: Maybe<Scalars['DateTime']>;
  stats?: Maybe<Statistic>;
  shareOwner?: Maybe<Scalars['String']>;
  ownerType?: Maybe<Scalars['String']>;
  customs?: Maybe<Scalars['JSON']>;
  mapCampaignIds?: Maybe<Array<Scalars['String']>>;
  hasOwner?: Maybe<Scalars['Boolean']>;
  creatorId?: Maybe<Scalars['ID']>;
  optimizeType?: Maybe<Scalars['String']>;
  adsType?: Maybe<Scalars['String']>;
  defaultForClient?: Maybe<Scalars['Boolean']>;
  creator?: Maybe<User>;
  customer?: Maybe<User>;
  id?: Maybe<Scalars['String']>;
  reportLocationId?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Float']>;
};

export type ActiveCampaignInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  isBookOnline?: Maybe<Scalars['Boolean']>;
  typeBrand?: Maybe<Array<Scalars['String']>>;
  categoryId?: Maybe<Scalars['String']>;
  scheduleFrom?: Maybe<Scalars['DateTime']>;
  scheduleTo?: Maybe<Scalars['DateTime']>;
  kpi?: Maybe<Scalars['Float']>;
  kpiType?: Maybe<Scalars['String']>;
  budget?: Maybe<Scalars['Float']>;
  balanceSource?: Maybe<BalanceType>;
  locations?: Maybe<Array<CampaignLocationInput>>;
  config?: Maybe<CampaignConfigInput>;
  displayType?: Maybe<Scalars['String']>;
  times?: Maybe<Scalars['String']>;
  timesOfClick?: Maybe<Scalars['Float']>;
  revenue?: Maybe<Scalars['JSON']>;
  revenueStatus?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
  forceStopped?: Maybe<Scalars['Boolean']>;
  stoppedUntil?: Maybe<Scalars['DateTime']>;
  stats?: Maybe<StatisticInput>;
  shareOwner?: Maybe<Scalars['String']>;
  ownerType?: Maybe<Scalars['String']>;
  customs?: Maybe<Scalars['JSON']>;
  mapCampaignIds?: Maybe<Array<Scalars['String']>>;
  hasOwner?: Maybe<Scalars['Boolean']>;
  creatorId?: Maybe<Scalars['ID']>;
  optimizeType?: Maybe<Scalars['String']>;
  adsType?: Maybe<Scalars['String']>;
  defaultForClient?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  reportLocationId?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Float']>;
};

export type AddKpiInput = {
  campaignMappingId: Scalars['String'];
  reportLocationId?: Maybe<Scalars['String']>;
  additionalKpi: Scalars['Float'];
};

export type AddLocationParams = {
  campaignId?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  kpi?: Maybe<Scalars['Float']>;
  reportLocationId?: Maybe<Scalars['String']>;
  scheduleFrom?: Maybe<Scalars['DateTime']>;
  scheduleTo?: Maybe<Scalars['DateTime']>;
};

export type AddressType = {
  text?: Maybe<Scalars['String']>;
  categoryName?: Maybe<Scalars['String']>;
  cityName?: Maybe<Scalars['String']>;
  districtName?: Maybe<Scalars['String']>;
};

export type AddressTypeInput = {
  text?: Maybe<Scalars['String']>;
  categoryName?: Maybe<Scalars['String']>;
  cityName?: Maybe<Scalars['String']>;
  districtName?: Maybe<Scalars['String']>;
};

export type AdvanceCampaignInfoArgs = {
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  shareOwner?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
};

export type AdvanceLocationUpdateArgs = {
  shareType?: Maybe<ShareTypeInput>;
  stats?: Maybe<LocationStatsInput>;
  activeDay?: Maybe<Scalars['DateTime']>;
  configAuth?: Maybe<Scalars['String']>;
  share?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
};

export type Age = {
  age?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Float']>;
};

export type AllLocation = {
  categoryId?: Maybe<Scalars['String']>;
  countAllLocation?: Maybe<Scalars['Float']>;
};

export type Audience = {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailVerified?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  phoneCarrier?: Maybe<Scalars['String']>;
  phoneStatus?: Maybe<Scalars['String']>;
  phoneVerified?: Maybe<Scalars['Boolean']>;
  gender?: Maybe<Scalars['String']>;
  genderVerified?: Maybe<Scalars['Boolean']>;
  age?: Maybe<Scalars['String']>;
  income?: Maybe<Scalars['String']>;
  marital?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
};

export type AudienceDataReturn = {
  totalGender?: Maybe<Scalars['Float']>;
  totalAge?: Maybe<Scalars['Float']>;
  totalIncome?: Maybe<Scalars['Float']>;
  genders?: Maybe<Array<Gender>>;
  ages?: Maybe<Array<Age>>;
  incomes?: Maybe<Array<Income>>;
};

export type AudienceInput = {
  acceptRules?: Maybe<QueryBuilderInput>;
};

export type AudienceQueryBuilder = {
  acceptRules?: Maybe<QueryBuilder>;
};

export type AvailableBooking = {
  locations: Array<AvailableBookingByLocations>;
  categories: Array<AvailableBookingByCategories>;
};

export type AvailableBookingByCategories = {
  categoryId?: Maybe<Scalars['String']>;
  bookingKpi: Scalars['Float'];
  availableKpi: Scalars['Float'];
  expectedKpi: Scalars['Float'];
  percent: Scalars['Float'];
};

export type AvailableBookingByLocations = {
  _id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  bookingType?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  bookingKpi?: Maybe<Scalars['Float']>;
  expectedKpi?: Maybe<Scalars['Float']>;
  availableTotalKpi?: Maybe<Scalars['Float']>;
  groupByDate?: Maybe<Array<GroupByDate>>;
  everyDay?: Maybe<Array<EveryDay>>;
  percent?: Maybe<Scalars['Float']>;
  group?: Maybe<Scalars['String']>;
};

export enum BalanceAction {
  Topup = 'TOPUP',
  Spend = 'SPEND',
  Refund = 'REFUND'
}

export type BalanceHistory = {
  _id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['ID']>;
  userId: Scalars['ID'];
  type: Scalars['String'];
  originalAmount: Scalars['Float'];
  amount: Scalars['Float'];
  campaignId?: Maybe<Scalars['ID']>;
  action: BalanceAction;
  campaign?: Maybe<BalanceHistoryCampaign>;
};

export type BalanceHistoryCampaign = {
  name: Scalars['String'];
  scheduleFrom?: Maybe<Scalars['DateTime']>;
  scheduleTo?: Maybe<Scalars['DateTime']>;
  kpi?: Maybe<Scalars['Float']>;
};

export type BalanceHistoryFilter = {
  _ids?: Maybe<Array<Scalars['ID']>>;
  AND?: Maybe<BalanceHistoryFilter>;
  OR?: Maybe<BalanceHistoryFilter>;
  _search?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  _operators?: Maybe<BalanceHistoryFilterOperator>;
  originalAmount?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  campaignId?: Maybe<Scalars['ID']>;
  action?: Maybe<BalanceAction>;
  _id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BalanceHistoryFilterOperator = {
  type?: Maybe<StringOperatorArgs>;
  originalAmount?: Maybe<NumberOperatorArgs>;
  amount?: Maybe<NumberOperatorArgs>;
  campaignId?: Maybe<IdOperatorArgs>;
  _id?: Maybe<IdOperatorArgs>;
  createdAt?: Maybe<DateOperatorArgs>;
  updatedAt?: Maybe<DateOperatorArgs>;
};

export type BalanceHistorySort = {
  userId?: Maybe<SortDirection>;
  type?: Maybe<SortDirection>;
  originalAmount?: Maybe<SortDirection>;
  amount?: Maybe<SortDirection>;
  campaignId?: Maybe<SortDirection>;
  action?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
  updatedAt?: Maybe<SortDirection>;
};

export enum BalanceType {
  Primary = 'PRIMARY',
  Secondary = 'SECONDARY'
}

export type Banner = {
  _id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  htmlCode?: Maybe<Scalars['String']>;
  fileCode?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  isSample: Scalars['Boolean'];
  configRender?: Maybe<Scalars['JSON']>;
  ownerType?: Maybe<Scalars['String']>;
  landingPageUrl?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  creatorId?: Maybe<Scalars['ID']>;
  category?: Maybe<Common>;
  creator?: Maybe<User>;
  customer?: Maybe<User>;
  currentCampaigns?: Maybe<Array<CampaignBanner>>;
};

export type BannerBasePayload = {
  data?: Maybe<Banner>;
  error?: Maybe<StandardMutationError>;
};

export type BannerFilter = {
  _ids?: Maybe<Array<Scalars['ID']>>;
  AND?: Maybe<BannerFilter>;
  OR?: Maybe<BannerFilter>;
  _search?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  _operators?: Maybe<BannerFilterOperator>;
  categoryId?: Maybe<Scalars['String']>;
  ownerType?: Maybe<Scalars['String']>;
  landingPageUrl?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  creatorId?: Maybe<Scalars['ID']>;
  _id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BannerFilterOperator = {
  name?: Maybe<StringOperatorArgs>;
  categoryId?: Maybe<StringOperatorArgs>;
  ownerType?: Maybe<StringOperatorArgs>;
  landingPageUrl?: Maybe<StringOperatorArgs>;
  creatorId?: Maybe<IdOperatorArgs>;
  _id?: Maybe<IdOperatorArgs>;
  createdAt?: Maybe<DateOperatorArgs>;
  updatedAt?: Maybe<DateOperatorArgs>;
};

export type BannerInput = {
  name: Scalars['String'];
  htmlCode?: Maybe<Scalars['String']>;
  fileCode?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  isSample: Scalars['Boolean'];
  configRender?: Maybe<Scalars['JSON']>;
  ownerType?: Maybe<Scalars['String']>;
  landingPageUrl?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  creatorId?: Maybe<Scalars['ID']>;
};

export type BannerPayload = {
  data?: Maybe<Banner>;
  error?: Maybe<StandardMutationError>;
};

export type BannerRandom = {
  enable?: Maybe<Scalars['Boolean']>;
  ratio?: Maybe<Scalars['JSONObject']>;
};

export type BannerRandomInput = {
  enable?: Maybe<Scalars['Boolean']>;
  ratio?: Maybe<Scalars['JSONObject']>;
};

export type BannerSort = {
  name?: Maybe<SortDirection>;
  categoryId?: Maybe<SortDirection>;
  ownerType?: Maybe<SortDirection>;
  landingPageUrl?: Maybe<SortDirection>;
  active?: Maybe<SortDirection>;
  creatorId?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
  updatedAt?: Maybe<SortDirection>;
};

export type BannerTemp = {
  _id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  htmlCode?: Maybe<Scalars['String']>;
  fileCode?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  isSample: Scalars['Boolean'];
  configRender?: Maybe<Scalars['JSON']>;
  ownerType?: Maybe<Scalars['String']>;
  creatorId?: Maybe<Scalars['ID']>;
  category?: Maybe<Common>;
  creator?: Maybe<User>;
  customer?: Maybe<User>;
};

export type BannerTempBasePayload = {
  data?: Maybe<BannerTemp>;
  error?: Maybe<StandardMutationError>;
};

export type BannerTempFilter = {
  _ids?: Maybe<Array<Scalars['ID']>>;
  AND?: Maybe<BannerTempFilter>;
  OR?: Maybe<BannerTempFilter>;
  _search?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  _operators?: Maybe<BannerTempFilterOperator>;
  categoryId?: Maybe<Scalars['String']>;
  ownerType?: Maybe<Scalars['String']>;
  creatorId?: Maybe<Scalars['ID']>;
  _id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BannerTempFilterOperator = {
  name?: Maybe<StringOperatorArgs>;
  categoryId?: Maybe<StringOperatorArgs>;
  ownerType?: Maybe<StringOperatorArgs>;
  creatorId?: Maybe<IdOperatorArgs>;
  _id?: Maybe<IdOperatorArgs>;
  createdAt?: Maybe<DateOperatorArgs>;
  updatedAt?: Maybe<DateOperatorArgs>;
};

export type BannerTempInput = {
  name: Scalars['String'];
  htmlCode?: Maybe<Scalars['String']>;
  fileCode?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  isSample: Scalars['Boolean'];
  configRender?: Maybe<Scalars['JSON']>;
  ownerType?: Maybe<Scalars['String']>;
  creatorId?: Maybe<Scalars['ID']>;
};

export type BannerTemplate = {
  _id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  htmlCode: Scalars['String'];
  type: Scalars['String'];
  options?: Maybe<Scalars['JSON']>;
  bannerTemplateType?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  typeText?: Maybe<Scalars['String']>;
};

export type BannerTemplateBasePayload = {
  data?: Maybe<BannerTemplate>;
  error?: Maybe<StandardMutationError>;
};

export type BannerTemplateFilter = {
  _ids?: Maybe<Array<Scalars['ID']>>;
  AND?: Maybe<BannerTemplateFilter>;
  OR?: Maybe<BannerTemplateFilter>;
  _search?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  _operators?: Maybe<BannerTemplateFilterOperator>;
  meta?: Maybe<Scalars['JSON']>;
  _id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BannerTemplateFilterOperator = {
  name?: Maybe<StringOperatorArgs>;
  _id?: Maybe<IdOperatorArgs>;
  createdAt?: Maybe<DateOperatorArgs>;
  updatedAt?: Maybe<DateOperatorArgs>;
};

export type BannerTemplateInput = {
  name: Scalars['String'];
  htmlCode: Scalars['String'];
  type: Scalars['String'];
  options?: Maybe<Scalars['JSON']>;
  bannerTemplateType?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
};

export type BannerTemplateSort = {
  name?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
  updatedAt?: Maybe<SortDirection>;
};

export type BannerTemplateUpdateArg = {
  name?: Maybe<Scalars['String']>;
  htmlCode?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  options?: Maybe<Scalars['JSON']>;
  bannerTemplateType?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
};

export type BannerTempSort = {
  name?: Maybe<SortDirection>;
  categoryId?: Maybe<SortDirection>;
  ownerType?: Maybe<SortDirection>;
  creatorId?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
  updatedAt?: Maybe<SortDirection>;
};

export type BannerTempUpdateArg = {
  name?: Maybe<Scalars['String']>;
  htmlCode?: Maybe<Scalars['String']>;
  fileCode?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  isSample?: Maybe<Scalars['Boolean']>;
  configRender?: Maybe<Scalars['JSON']>;
  ownerType?: Maybe<Scalars['String']>;
};

export type BannerUpdateArg = {
  name?: Maybe<Scalars['String']>;
  htmlCode?: Maybe<Scalars['String']>;
  fileCode?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  isSample?: Maybe<Scalars['Boolean']>;
  configRender?: Maybe<Scalars['JSON']>;
  ownerType?: Maybe<Scalars['String']>;
  landingPageUrl?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
};

export type Booking = {
  _id?: Maybe<Scalars['String']>;
  locationId: Scalars['String'];
  reportLocationId?: Maybe<Scalars['String']>;
  campaignId: Scalars['String'];
  kpi: Scalars['Float'];
  click?: Maybe<Scalars['Float']>;
  date?: Maybe<Scalars['DateTime']>;
};

export type BookingInfo = {
  bookingKpi: Scalars['Float'];
  availableKpi: Scalars['Float'];
  ratio: Scalars['Float'];
};

export type BookingInput = {
  _id?: Maybe<Scalars['String']>;
  locationId: Scalars['String'];
  reportLocationId?: Maybe<Scalars['String']>;
  campaignId: Scalars['String'];
  kpi?: Maybe<Scalars['Float']>;
  click?: Maybe<Scalars['Float']>;
  date?: Maybe<Scalars['DateTime']>;
};

export type BookingOnlineArgs = {
  name: Scalars['String'];
  scheduleFrom: Scalars['DateTime'];
  scheduleTo: Scalars['DateTime'];
  balanceSource: BalanceType;
  budget: Scalars['Float'];
  landingPageUrl: Scalars['String'];
  bannerId: Scalars['String'];
  isBookOnline?: Maybe<Scalars['Boolean']>;
  locations: Array<BookingOnlineKpiItem>;
};

export type BookingOnlineKpiItem = {
  _id: Scalars['ID'];
  budget: Scalars['Float'];
};

export type CalculateBookingOnlineKpiArgs = {
  locationIds: Array<Scalars['ID']>;
  scheduleFrom: Scalars['DateTime'];
  scheduleTo: Scalars['DateTime'];
  budget: Scalars['Float'];
};

export type CalculateBookingOnlineKpiPayload = {
  data?: Maybe<CalculateBookingOnlineKpiResult>;
  error?: Maybe<StandardMutationError>;
};

export type CalculateBookingOnlineKpiResult = {
  budget: Scalars['Float'];
  detail: Array<CalculatedBookingOnlineKpiItem>;
};

export type CalculatedBookingOnlineKpiItem = {
  _id: Scalars['ID'];
  price: Scalars['Float'];
  available: Scalars['Float'];
  budget: Scalars['Float'];
  kpi: Scalars['Float'];
};

export type Campaign = {
  _id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  isBookOnline?: Maybe<Scalars['Boolean']>;
  typeBrand?: Maybe<Array<Scalars['String']>>;
  categoryId?: Maybe<Scalars['String']>;
  scheduleFrom?: Maybe<Scalars['DateTime']>;
  scheduleTo?: Maybe<Scalars['DateTime']>;
  kpi?: Maybe<Scalars['Float']>;
  kpiType?: Maybe<Scalars['String']>;
  budget?: Maybe<Scalars['Float']>;
  balanceSource?: Maybe<BalanceType>;
  locations?: Maybe<Array<CampaignLocation>>;
  config?: Maybe<CampaignConfig>;
  displayType?: Maybe<Scalars['String']>;
  times?: Maybe<Scalars['String']>;
  timesOfClick?: Maybe<Scalars['Float']>;
  revenue?: Maybe<Scalars['JSON']>;
  revenueStatus?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
  forceStopped?: Maybe<Scalars['Boolean']>;
  stoppedUntil?: Maybe<Scalars['DateTime']>;
  stats?: Maybe<Statistic>;
  shareOwner?: Maybe<Scalars['String']>;
  ownerType?: Maybe<Scalars['String']>;
  customs?: Maybe<Scalars['JSON']>;
  mapCampaignIds?: Maybe<Array<Scalars['String']>>;
  hasOwner?: Maybe<Scalars['Boolean']>;
  creatorId?: Maybe<Scalars['ID']>;
  optimizeType?: Maybe<Scalars['String']>;
  adsType?: Maybe<Scalars['String']>;
  defaultForClient?: Maybe<Scalars['Boolean']>;
  creator?: Maybe<User>;
  customer?: Maybe<User>;
};

export type CampaignActive = {
  _id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  kpi?: Maybe<Scalars['Float']>;
  banners?: Maybe<Array<CampaignConfigBanner>>;
};

export type CampaignBanner = {
  name?: Maybe<Scalars['String']>;
  isBo?: Maybe<Scalars['Boolean']>;
  isRunning?: Maybe<Scalars['Boolean']>;
};

export type CampaignBannerInput = {
  name?: Maybe<Scalars['String']>;
  isBo?: Maybe<Scalars['Boolean']>;
  isRunning?: Maybe<Scalars['Boolean']>;
};

export type CampaignBasePayload = {
  data?: Maybe<Campaign>;
  error?: Maybe<StandardMutationError>;
};

export type CampaignConfig = {
  audience?: Maybe<AudienceQueryBuilder>;
  banners?: Maybe<Array<CampaignConfigBanner>>;
  bannerRandom?: Maybe<BannerRandom>;
  landingPageUrl?: Maybe<Scalars['String']>;
  times?: Maybe<Scalars['String']>;
  impressionUrl?: Maybe<Scalars['String']>;
  trackingLocation?: Maybe<Scalars['JSONObject']>;
  crm?: Maybe<Scalars['JSONObject']>;
  crmValue?: Maybe<Scalars['JSONObject']>;
  fb?: Maybe<Facebook>;
  gg?: Maybe<Google>;
  webhook?: Maybe<Webhook>;
};

export type CampaignConfigBanner = {
  query?: Maybe<Scalars['String']>;
  queryTree?: Maybe<Scalars['JSONObject']>;
  bannerId?: Maybe<Scalars['ID']>;
  bannerName?: Maybe<Scalars['String']>;
};

export type CampaignConfigBannerInput = {
  query?: Maybe<Scalars['String']>;
  queryTree?: Maybe<Scalars['JSONObject']>;
  bannerId?: Maybe<Scalars['ID']>;
  bannerName?: Maybe<Scalars['String']>;
};

export type CampaignConfigInput = {
  audience?: Maybe<AudienceInput>;
  banners?: Maybe<Array<CampaignConfigBannerInput>>;
  bannerRandom?: Maybe<BannerRandomInput>;
  landingPageUrl?: Maybe<Scalars['String']>;
  times?: Maybe<Scalars['String']>;
  impressionUrl?: Maybe<Scalars['String']>;
  trackingLocation?: Maybe<Scalars['JSONObject']>;
  crm?: Maybe<Scalars['JSONObject']>;
  crmValue?: Maybe<Scalars['JSONObject']>;
  fb?: Maybe<FacebookInput>;
  gg?: Maybe<GoogleInput>;
  webhook?: Maybe<WebhookInput>;
};

export type CampaignConfigUpdateArgs = {
  customs?: Maybe<Scalars['JSON']>;
  config?: Maybe<CampaignConfigInput>;
};

export type CampaignFilter = {
  _ids?: Maybe<Array<Scalars['ID']>>;
  AND?: Maybe<CampaignFilter>;
  OR?: Maybe<CampaignFilter>;
  _search?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  _operators?: Maybe<CampaignFilterOperator>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  isBookOnline?: Maybe<Scalars['Boolean']>;
  typeBrand?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  scheduleFrom?: Maybe<Scalars['DateTime']>;
  scheduleTo?: Maybe<Scalars['DateTime']>;
  kpi?: Maybe<Scalars['Float']>;
  kpiType?: Maybe<Scalars['String']>;
  budget?: Maybe<Scalars['Float']>;
  balanceSource?: Maybe<BalanceType>;
  displayType?: Maybe<Scalars['String']>;
  timesOfClick?: Maybe<Scalars['Float']>;
  revenue?: Maybe<Scalars['JSON']>;
  revenueStatus?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
  forceStopped?: Maybe<Scalars['Boolean']>;
  stoppedUntil?: Maybe<Scalars['DateTime']>;
  shareOwner?: Maybe<Scalars['String']>;
  ownerType?: Maybe<Scalars['String']>;
  customs?: Maybe<Scalars['JSON']>;
  mapCampaignIds?: Maybe<Scalars['String']>;
  hasOwner?: Maybe<Scalars['Boolean']>;
  creatorId?: Maybe<Scalars['ID']>;
  optimizeType?: Maybe<Scalars['String']>;
  adsType?: Maybe<Scalars['String']>;
  defaultForClient?: Maybe<Scalars['Boolean']>;
  _id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CampaignFilterOperator = {
  name?: Maybe<StringOperatorArgs>;
  description?: Maybe<StringOperatorArgs>;
  type?: Maybe<StringOperatorArgs>;
  typeBrand?: Maybe<StringOperatorArgs>;
  categoryId?: Maybe<StringOperatorArgs>;
  scheduleFrom?: Maybe<DateOperatorArgs>;
  scheduleTo?: Maybe<DateOperatorArgs>;
  kpi?: Maybe<NumberOperatorArgs>;
  kpiType?: Maybe<StringOperatorArgs>;
  budget?: Maybe<NumberOperatorArgs>;
  displayType?: Maybe<StringOperatorArgs>;
  timesOfClick?: Maybe<NumberOperatorArgs>;
  status?: Maybe<StringOperatorArgs>;
  stoppedUntil?: Maybe<DateOperatorArgs>;
  shareOwner?: Maybe<StringOperatorArgs>;
  ownerType?: Maybe<StringOperatorArgs>;
  mapCampaignIds?: Maybe<StringOperatorArgs>;
  creatorId?: Maybe<IdOperatorArgs>;
  optimizeType?: Maybe<StringOperatorArgs>;
  adsType?: Maybe<StringOperatorArgs>;
  _id?: Maybe<IdOperatorArgs>;
  createdAt?: Maybe<DateOperatorArgs>;
  updatedAt?: Maybe<DateOperatorArgs>;
};

export type CampaignIdByLocation = {
  name?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  ownerType?: Maybe<Scalars['String']>;
};

export type CampaignInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  isBookOnline?: Maybe<Scalars['Boolean']>;
  typeBrand?: Maybe<Array<Scalars['String']>>;
  categoryId?: Maybe<Scalars['String']>;
  scheduleFrom?: Maybe<Scalars['DateTime']>;
  scheduleTo?: Maybe<Scalars['DateTime']>;
  kpi?: Maybe<Scalars['Float']>;
  kpiType?: Maybe<Scalars['String']>;
  budget?: Maybe<Scalars['Float']>;
  balanceSource?: Maybe<BalanceType>;
  locations?: Maybe<Array<CampaignLocationInput>>;
  config?: Maybe<CampaignConfigInput>;
  displayType?: Maybe<Scalars['String']>;
  times?: Maybe<Scalars['String']>;
  timesOfClick?: Maybe<Scalars['Float']>;
  revenue?: Maybe<Scalars['JSON']>;
  revenueStatus?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
  forceStopped?: Maybe<Scalars['Boolean']>;
  stoppedUntil?: Maybe<Scalars['DateTime']>;
  stats?: Maybe<StatisticInput>;
  shareOwner?: Maybe<Scalars['String']>;
  ownerType?: Maybe<Scalars['String']>;
  customs?: Maybe<Scalars['JSON']>;
  mapCampaignIds?: Maybe<Array<Scalars['String']>>;
  hasOwner?: Maybe<Scalars['Boolean']>;
  creatorId?: Maybe<Scalars['ID']>;
  optimizeType?: Maybe<Scalars['String']>;
  adsType?: Maybe<Scalars['String']>;
  defaultForClient?: Maybe<Scalars['Boolean']>;
};

export type CampaignLocation = {
  locationId?: Maybe<Scalars['String']>;
  locationName?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  categoryName?: Maybe<Scalars['String']>;
  kpi?: Maybe<Scalars['Float']>;
  kpiPerDay?: Maybe<Scalars['Float']>;
  impressionUrl?: Maybe<Scalars['String']>;
  clickUrl?: Maybe<Scalars['String']>;
  tag?: Maybe<Array<Scalars['String']>>;
  locationGroup?: Maybe<Scalars['String']>;
  equipments?: Maybe<Array<Scalars['String']>>;
};

export type CampaignLocationInput = {
  locationId?: Maybe<Scalars['String']>;
  locationName?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  categoryName?: Maybe<Scalars['String']>;
  kpi?: Maybe<Scalars['Float']>;
  kpiPerDay?: Maybe<Scalars['Float']>;
  impressionUrl?: Maybe<Scalars['String']>;
  clickUrl?: Maybe<Scalars['String']>;
  tag?: Maybe<Array<Scalars['String']>>;
  locationGroup?: Maybe<Scalars['String']>;
  equipments?: Maybe<Array<Scalars['String']>>;
};

export type CampaignMapping = {
  _id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['ID']>;
  campaignId: Scalars['ID'];
  locationId: Scalars['ID'];
  reportLocationId?: Maybe<Scalars['ID']>;
  scheduleFrom?: Maybe<Scalars['DateTime']>;
  scheduleTo?: Maybe<Scalars['DateTime']>;
  times?: Maybe<Scalars['String']>;
  priority: Scalars['Float'];
  kpi: Scalars['Float'];
  status: Scalars['String'];
  campaignType?: Maybe<Scalars['String']>;
  multiplier?: Maybe<Scalars['Float']>;
  active: Scalars['Boolean'];
  stats?: Maybe<Statistic>;
  campaignName?: Maybe<Scalars['String']>;
  locationName?: Maybe<Scalars['String']>;
  reportLocationName?: Maybe<Scalars['String']>;
  licenseName?: Maybe<Scalars['String']>;
  todayStats?: Maybe<Statistic>;
  yesterdayStats?: Maybe<Statistic>;
  creatorId?: Maybe<Scalars['ID']>;
  equipmentIds?: Maybe<Scalars['ID']>;
  creator?: Maybe<User>;
  campaign?: Maybe<Campaign>;
  location?: Maybe<Location>;
  reportLocation?: Maybe<Location>;
};

export type CampaignMappingBasePayload = {
  data?: Maybe<CampaignMapping>;
  error?: Maybe<StandardMutationError>;
};

export type CampaignMappingFilter = {
  _ids?: Maybe<Array<Scalars['ID']>>;
  AND?: Maybe<CampaignMappingFilter>;
  OR?: Maybe<CampaignMappingFilter>;
  _search?: Maybe<Scalars['String']>;
  campaignId?: Maybe<Scalars['ID']>;
  _operators?: Maybe<CampaignMappingFilterOperator>;
  locationId?: Maybe<Scalars['ID']>;
  reportLocationId?: Maybe<Scalars['ID']>;
  scheduleFrom?: Maybe<Scalars['DateTime']>;
  scheduleTo?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['String']>;
  campaignType?: Maybe<Scalars['String']>;
  multiplier?: Maybe<Scalars['Float']>;
  creatorId?: Maybe<Scalars['ID']>;
  equipmentIds?: Maybe<Scalars['ID']>;
  _id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CampaignMappingFilterOperator = {
  campaignId?: Maybe<IdOperatorArgs>;
  locationId?: Maybe<IdOperatorArgs>;
  reportLocationId?: Maybe<IdOperatorArgs>;
  scheduleFrom?: Maybe<DateOperatorArgs>;
  scheduleTo?: Maybe<DateOperatorArgs>;
  status?: Maybe<StringOperatorArgs>;
  campaignType?: Maybe<StringOperatorArgs>;
  multiplier?: Maybe<NumberOperatorArgs>;
  creatorId?: Maybe<IdOperatorArgs>;
  equipmentIds?: Maybe<IdOperatorArgs>;
  _id?: Maybe<IdOperatorArgs>;
  createdAt?: Maybe<DateOperatorArgs>;
  updatedAt?: Maybe<DateOperatorArgs>;
};

export type CampaignMappingInput = {
  campaignId: Scalars['ID'];
  locationId: Scalars['ID'];
  reportLocationId?: Maybe<Scalars['ID']>;
  scheduleFrom?: Maybe<Scalars['DateTime']>;
  scheduleTo?: Maybe<Scalars['DateTime']>;
  times?: Maybe<Scalars['String']>;
  priority: Scalars['Float'];
  kpi: Scalars['Float'];
  status: Scalars['String'];
  campaignType?: Maybe<Scalars['String']>;
  multiplier?: Maybe<Scalars['Float']>;
  active: Scalars['Boolean'];
  stats?: Maybe<StatisticInput>;
  campaignName?: Maybe<Scalars['String']>;
  locationName?: Maybe<Scalars['String']>;
  reportLocationName?: Maybe<Scalars['String']>;
  licenseName?: Maybe<Scalars['String']>;
  todayStats?: Maybe<StatisticInput>;
  yesterdayStats?: Maybe<StatisticInput>;
  creatorId?: Maybe<Scalars['ID']>;
  equipmentIds?: Maybe<Scalars['ID']>;
};

export type CampaignMappingPayload = {
  data?: Maybe<CampaignMapping>;
  success?: Maybe<Scalars['Boolean']>;
  error?: Maybe<StandardMutationError>;
};

export type CampaignMappingSort = {
  campaignId?: Maybe<SortDirection>;
  locationId?: Maybe<SortDirection>;
  reportLocationId?: Maybe<SortDirection>;
  scheduleFrom?: Maybe<SortDirection>;
  scheduleTo?: Maybe<SortDirection>;
  priority?: Maybe<SortDirection>;
  kpi?: Maybe<SortDirection>;
  status?: Maybe<SortDirection>;
  campaignType?: Maybe<SortDirection>;
  multiplier?: Maybe<SortDirection>;
  creatorId?: Maybe<SortDirection>;
  equipmentIds?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
  updatedAt?: Maybe<SortDirection>;
};

export type CampaignMappingUpdateArg = {
  campaignId?: Maybe<Scalars['ID']>;
  locationId?: Maybe<Scalars['ID']>;
  reportLocationId?: Maybe<Scalars['ID']>;
  scheduleFrom?: Maybe<Scalars['DateTime']>;
  scheduleTo?: Maybe<Scalars['DateTime']>;
  times?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Float']>;
  kpi?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
  campaignType?: Maybe<Scalars['String']>;
  multiplier?: Maybe<Scalars['Float']>;
  active?: Maybe<Scalars['Boolean']>;
  stats?: Maybe<StatisticInput>;
  campaignName?: Maybe<Scalars['String']>;
  locationName?: Maybe<Scalars['String']>;
  reportLocationName?: Maybe<Scalars['String']>;
  licenseName?: Maybe<Scalars['String']>;
  todayStats?: Maybe<StatisticInput>;
  yesterdayStats?: Maybe<StatisticInput>;
  equipmentIds?: Maybe<Scalars['ID']>;
};

export type CampaignMeta = {
  bannerId?: Maybe<Scalars['String']>;
  campaignId?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  realLocationId?: Maybe<Scalars['String']>;
  bannerName?: Maybe<Scalars['String']>;
  locationName?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Float']>;
};

export type CampaignPayload = {
  data?: Maybe<Campaign>;
  error?: Maybe<StandardMutationError>;
};

export type CampaignRatioFilter = {
  categoryId?: Maybe<Array<Scalars['String']>>;
  _id?: Maybe<Array<Scalars['String']>>;
  city?: Maybe<Array<Scalars['String']>>;
  district?: Maybe<Array<Scalars['String']>>;
  tag?: Maybe<Array<Scalars['String']>>;
  status?: Maybe<Array<Scalars['String']>>;
  ownerType?: Maybe<Array<Scalars['String']>>;
  date?: Maybe<DateInput>;
  interval?: Maybe<SharedTrafficInterval>;
};

export type CampaignSort = {
  name?: Maybe<SortDirection>;
  description?: Maybe<SortDirection>;
  type?: Maybe<SortDirection>;
  typeBrand?: Maybe<SortDirection>;
  categoryId?: Maybe<SortDirection>;
  scheduleFrom?: Maybe<SortDirection>;
  scheduleTo?: Maybe<SortDirection>;
  kpi?: Maybe<SortDirection>;
  kpiType?: Maybe<SortDirection>;
  budget?: Maybe<SortDirection>;
  balanceSource?: Maybe<SortDirection>;
  displayType?: Maybe<SortDirection>;
  timesOfClick?: Maybe<SortDirection>;
  revenue?: Maybe<SortDirection>;
  revenueStatus?: Maybe<SortDirection>;
  active?: Maybe<SortDirection>;
  status?: Maybe<SortDirection>;
  forceStopped?: Maybe<SortDirection>;
  stoppedUntil?: Maybe<SortDirection>;
  shareOwner?: Maybe<SortDirection>;
  ownerType?: Maybe<SortDirection>;
  customs?: Maybe<SortDirection>;
  mapCampaignIds?: Maybe<SortDirection>;
  hasOwner?: Maybe<SortDirection>;
  creatorId?: Maybe<SortDirection>;
  optimizeType?: Maybe<SortDirection>;
  adsType?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
  updatedAt?: Maybe<SortDirection>;
};

export type CampaignSurveyFilter = {
  exists?: Maybe<Array<Scalars['String']>>;
  not_exists?: Maybe<Array<Scalars['String']>>;
  inq?: Maybe<Scalars['JSONObject']>;
  nin?: Maybe<Scalars['JSONObject']>;
  range?: Maybe<Scalars['JSONObject']>;
  survey?: Maybe<Scalars['JSONObject']>;
};

export type CampaignUpdateArg = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  isBookOnline?: Maybe<Scalars['Boolean']>;
  typeBrand?: Maybe<Array<Scalars['String']>>;
  categoryId?: Maybe<Scalars['String']>;
  scheduleFrom?: Maybe<Scalars['DateTime']>;
  scheduleTo?: Maybe<Scalars['DateTime']>;
  kpi?: Maybe<Scalars['Float']>;
  kpiType?: Maybe<Scalars['String']>;
  budget?: Maybe<Scalars['Float']>;
  balanceSource?: Maybe<BalanceType>;
  locations?: Maybe<Array<CampaignLocationInput>>;
  config?: Maybe<CampaignConfigInput>;
  displayType?: Maybe<Scalars['String']>;
  times?: Maybe<Scalars['String']>;
  timesOfClick?: Maybe<Scalars['Float']>;
  revenue?: Maybe<Scalars['JSON']>;
  revenueStatus?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
  forceStopped?: Maybe<Scalars['Boolean']>;
  stoppedUntil?: Maybe<Scalars['DateTime']>;
  stats?: Maybe<StatisticInput>;
  shareOwner?: Maybe<Scalars['String']>;
  ownerType?: Maybe<Scalars['String']>;
  customs?: Maybe<Scalars['JSON']>;
  mapCampaignIds?: Maybe<Array<Scalars['String']>>;
  hasOwner?: Maybe<Scalars['Boolean']>;
  optimizeType?: Maybe<Scalars['String']>;
  adsType?: Maybe<Scalars['String']>;
  defaultForClient?: Maybe<Scalars['Boolean']>;
};

export type CategoryPrice = {
  categoryId?: Maybe<Scalars['String']>;
  categoryName?: Maybe<Scalars['String']>;
  categoryIcon?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
};

export type ChartDetailType = {
  name: Scalars['String'];
  docCount: Scalars['Int'];
  more: Array<LeafDetailType>;
};

export type ClickInfo = {
  click: Scalars['Float'];
  impression: Scalars['Float'];
};

export type CmPositionInput = {
  id: Scalars['String'];
  priority: Scalars['Float'];
};

export type ColumnChartLocation = {
  allLocation?: Maybe<Array<AllLocation>>;
  locationActive?: Maybe<Array<LocationActive>>;
};

export type ColumnVo = {
  id?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  field?: Maybe<Scalars['String']>;
  aggFunc?: Maybe<Scalars['String']>;
};

export type Common = {
  _id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['ID']>;
  value: Scalars['String'];
  text: Scalars['String'];
  active: Scalars['Boolean'];
  type: Scalars['String'];
  instructionType?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  options?: Maybe<Scalars['JSON']>;
  creatorId?: Maybe<Scalars['ID']>;
};

export type CommonBasePayload = {
  data?: Maybe<Common>;
  error?: Maybe<StandardMutationError>;
};

export type CommonFilter = {
  _ids?: Maybe<Array<Scalars['ID']>>;
  AND?: Maybe<CommonFilter>;
  OR?: Maybe<CommonFilter>;
  _search?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  _operators?: Maybe<CommonFilterOperator>;
  active?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  instructionType?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  options?: Maybe<Scalars['JSON']>;
  creatorId?: Maybe<Scalars['ID']>;
  _id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CommonFilterOperator = {
  text?: Maybe<StringOperatorArgs>;
  type?: Maybe<StringOperatorArgs>;
  instructionType?: Maybe<StringOperatorArgs>;
  creatorId?: Maybe<IdOperatorArgs>;
  _id?: Maybe<IdOperatorArgs>;
  createdAt?: Maybe<DateOperatorArgs>;
  updatedAt?: Maybe<DateOperatorArgs>;
};

export type CommonInput = {
  value: Scalars['String'];
  text: Scalars['String'];
  active: Scalars['Boolean'];
  type: Scalars['String'];
  instructionType?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  options?: Maybe<Scalars['JSON']>;
  creatorId?: Maybe<Scalars['ID']>;
};

export type CommonPayload = {
  data?: Maybe<Common>;
  error?: Maybe<StandardMutationError>;
};

export type CommonSort = {
  text?: Maybe<SortDirection>;
  active?: Maybe<SortDirection>;
  type?: Maybe<SortDirection>;
  instructionType?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
  updatedAt?: Maybe<SortDirection>;
};

export type CommonUpdateArg = {
  value?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  instructionType?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  options?: Maybe<Scalars['JSON']>;
};

export type Customer = {
  _id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  taxCode?: Maybe<Scalars['String']>;
  typeCode?: Maybe<Scalars['String']>;
  urlLogo?: Maybe<Scalars['String']>;
  shareTraffic?: Maybe<ShareTrafficSettings>;
  shareRevenue?: Maybe<ShareRevenueSettings>;
  logos?: Maybe<File>;
  customerType?: Maybe<CustomerType>;
};

export type CustomerBasePayload = {
  data?: Maybe<Customer>;
  error?: Maybe<StandardMutationError>;
};

export type CustomerFilter = {
  _ids?: Maybe<Array<Scalars['ID']>>;
  AND?: Maybe<CustomerFilter>;
  OR?: Maybe<CustomerFilter>;
  _search?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  _operators?: Maybe<CustomerFilterOperator>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  shareTraffic?: Maybe<CustomershareTrafficFilter>;
  shareRevenue?: Maybe<CustomershareRevenueFilter>;
  _id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CustomerFilterOperator = {
  name?: Maybe<StringOperatorArgs>;
  email?: Maybe<StringOperatorArgs>;
  phone?: Maybe<StringOperatorArgs>;
  _id?: Maybe<IdOperatorArgs>;
  createdAt?: Maybe<DateOperatorArgs>;
  updatedAt?: Maybe<DateOperatorArgs>;
};

export type CustomerInput = {
  name: Scalars['String'];
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  taxCode?: Maybe<Scalars['String']>;
  typeCode?: Maybe<Scalars['String']>;
  urlLogo?: Maybe<Scalars['String']>;
  shareTraffic?: Maybe<ShareTrafficInput>;
  shareRevenue?: Maybe<ShareRevenueInput>;
  signature?: Maybe<Scalars['String']>;
  password: Scalars['String'];
};

export type CustomershareRevenueFilter = {
  AND?: Maybe<CustomershareRevenueFilter>;
  OR?: Maybe<CustomershareRevenueFilter>;
  _search?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  value?: Maybe<Scalars['Float']>;
  _operators?: Maybe<CustomershareRevenueFilterOperator>;
};

export type CustomershareRevenueFilterOperator = {
  value?: Maybe<NumberOperatorArgs>;
};

export type CustomershareTrafficFilter = {
  AND?: Maybe<CustomershareTrafficFilter>;
  OR?: Maybe<CustomershareTrafficFilter>;
  _search?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  ratio?: Maybe<Scalars['Float']>;
  _operators?: Maybe<CustomershareTrafficFilterOperator>;
};

export type CustomershareTrafficFilterOperator = {
  ratio?: Maybe<NumberOperatorArgs>;
};

export type CustomerSort = {
  name?: Maybe<SortDirection>;
  email?: Maybe<SortDirection>;
  phone?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
  updatedAt?: Maybe<SortDirection>;
};

export type CustomerType = {
  _id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  defaultProfileId: Scalars['String'];
  code?: Maybe<Scalars['String']>;
  profile?: Maybe<Profile>;
};

export type CustomerTypeBasePayload = {
  data?: Maybe<CustomerType>;
  error?: Maybe<StandardMutationError>;
};

export type CustomerTypeFilter = {
  _ids?: Maybe<Array<Scalars['ID']>>;
  AND?: Maybe<CustomerTypeFilter>;
  OR?: Maybe<CustomerTypeFilter>;
  _search?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  _operators?: Maybe<CustomerTypeFilterOperator>;
  _id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CustomerTypeFilterOperator = {
  name?: Maybe<StringOperatorArgs>;
  _id?: Maybe<IdOperatorArgs>;
  createdAt?: Maybe<DateOperatorArgs>;
  updatedAt?: Maybe<DateOperatorArgs>;
};

export type CustomerTypeInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  defaultProfileId: Scalars['String'];
  code?: Maybe<Scalars['String']>;
};

export type CustomerTypeSort = {
  name?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
  updatedAt?: Maybe<SortDirection>;
};

export type CustomerTypeUpdateArg = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  defaultProfileId?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
};

export type CustomerUpdateArg = {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  taxCode?: Maybe<Scalars['String']>;
  typeCode?: Maybe<Scalars['String']>;
  urlLogo?: Maybe<Scalars['String']>;
  shareTraffic?: Maybe<ShareTrafficInput>;
  shareRevenue?: Maybe<ShareRevenueInput>;
};

export type DashboardAdmin = {
  data?: Maybe<DataVal>;
};

export type Data = {
  dataYearAgo?: Maybe<Array<Array<Scalars['Float']>>>;
  dataYear?: Maybe<Array<Array<Scalars['Float']>>>;
};

export type DataInput = {
  nameCustomer: Scalars['String'];
  idCustomer: Scalars['String'];
  nameCampaign: Scalars['String'];
  info: Scalars['DateTime'];
  dateStart: Scalars['DateTime'];
  dateEnd: Scalars['DateTime'];
  kpi: Scalars['Float'];
  dateAnnoune: Scalars['DateTime'];
  locations: Scalars['Float'];
  totalPrice: Scalars['Float'];
};

export type DataReportLocation = {
  data?: Maybe<Array<ReportLocationData>>;
  total?: Maybe<Total>;
};

export type DataReportUserReturn = {
  data?: Maybe<Array<ReportUserReturnData>>;
  total?: Maybe<Total>;
};

export enum DataType {
  Category = 'CATEGORY',
  Location = 'LOCATION',
  Detail = 'DETAIL'
}

export type DataVal = {
  value?: Maybe<Scalars['String']>;
};

export type DateArgs = {
  gte?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
};

export type DateFilter = {
  gte?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
};

export type DateFilterInput = {
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
};

export type DateInput = {
  gte?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
};

export type DateOperatorArgs = {
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
};


export type DetailResult = {
  createdAt?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  locationName?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  categoryName?: Maybe<Scalars['String']>;
  campaignId?: Maybe<Scalars['String']>;
  campaignName?: Maybe<Scalars['String']>;
  bannerId?: Maybe<Scalars['String']>;
  bannerName?: Maybe<Scalars['String']>;
  impression?: Maybe<Scalars['Float']>;
  click?: Maybe<Scalars['Float']>;
  ctr?: Maybe<Scalars['Float']>;
};

export type DeviceDataPayload = {
  data?: Maybe<EsDeviceData>;
  error?: Maybe<StandardMutationError>;
};

export type DeviceFb = {
  fbId?: Maybe<Scalars['String']>;
  fbName?: Maybe<Scalars['String']>;
  fbGender?: Maybe<Scalars['String']>;
  fbAge?: Maybe<Scalars['String']>;
};

export type DeviceMeta = {
  campaignId?: Maybe<Scalars['String']>;
  campaignCategoryId?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  realLocationId?: Maybe<Scalars['String']>;
  cityId?: Maybe<Scalars['String']>;
  bannerId?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Float']>;
  campaignCount?: Maybe<Scalars['Float']>;
  locationCount?: Maybe<Scalars['Float']>;
  cityCount?: Maybe<Scalars['Float']>;
  locationCategoryId?: Maybe<Scalars['String']>;
};

export type DeviceOsTracking = {
  os?: Maybe<OsTracking>;
  device?: Maybe<DeviceTracking>;
};

export type DeviceOsTrackingInput = {
  os?: Maybe<OsTrackingInput>;
  device?: Maybe<DeviceTrackingInput>;
};

export type DeviceSurvey = {
  field?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type DeviceTracking = {
  Mobile?: Maybe<Scalars['Float']>;
  PC?: Maybe<Scalars['Float']>;
  Tablet?: Maybe<Scalars['Float']>;
};

export type DeviceTrackingInput = {
  Mobile?: Maybe<Scalars['Float']>;
  PC?: Maybe<Scalars['Float']>;
  Tablet?: Maybe<Scalars['Float']>;
};

export type DeviceTypeCount = {
  Mobile?: Maybe<Scalars['Float']>;
  PC?: Maybe<Scalars['Float']>;
  Tablet?: Maybe<Scalars['Float']>;
};

export type Equipment = {
  _id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  brand: Scalars['String'];
  model: Scalars['String'];
  mac?: Maybe<Scalars['String']>;
  serial?: Maybe<Scalars['String']>;
  gateway?: Maybe<Scalars['String']>;
  ownerType?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['ID']>;
  creatorId?: Maybe<Scalars['ID']>;
  creator?: Maybe<User>;
  location?: Maybe<Location>;
};

export type EquipmentBasePayload = {
  data?: Maybe<Equipment>;
  error?: Maybe<StandardMutationError>;
};

export type EquipmentFilter = {
  _ids?: Maybe<Array<Scalars['ID']>>;
  AND?: Maybe<EquipmentFilter>;
  OR?: Maybe<EquipmentFilter>;
  _search?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  _operators?: Maybe<EquipmentFilterOperator>;
  type?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  mac?: Maybe<Scalars['String']>;
  serial?: Maybe<Scalars['String']>;
  gateway?: Maybe<Scalars['String']>;
  ownerType?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['ID']>;
  creatorId?: Maybe<Scalars['ID']>;
  _id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EquipmentFilterOperator = {
  name?: Maybe<StringOperatorArgs>;
  type?: Maybe<StringOperatorArgs>;
  brand?: Maybe<StringOperatorArgs>;
  model?: Maybe<StringOperatorArgs>;
  mac?: Maybe<StringOperatorArgs>;
  serial?: Maybe<StringOperatorArgs>;
  gateway?: Maybe<StringOperatorArgs>;
  ownerType?: Maybe<StringOperatorArgs>;
  locationId?: Maybe<IdOperatorArgs>;
  creatorId?: Maybe<IdOperatorArgs>;
  _id?: Maybe<IdOperatorArgs>;
  createdAt?: Maybe<DateOperatorArgs>;
  updatedAt?: Maybe<DateOperatorArgs>;
};

export type EquipmentInput = {
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  brand: Scalars['String'];
  model: Scalars['String'];
  mac?: Maybe<Scalars['String']>;
  serial?: Maybe<Scalars['String']>;
  gateway?: Maybe<Scalars['String']>;
  ownerType?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['ID']>;
  creatorId?: Maybe<Scalars['ID']>;
};

export type EquipmentPayload = {
  data?: Maybe<Equipment>;
  error?: Maybe<StandardMutationError>;
};

export type EquipmentSort = {
  name?: Maybe<SortDirection>;
  type?: Maybe<SortDirection>;
  brand?: Maybe<SortDirection>;
  model?: Maybe<SortDirection>;
  mac?: Maybe<SortDirection>;
  serial?: Maybe<SortDirection>;
  gateway?: Maybe<SortDirection>;
  ownerType?: Maybe<SortDirection>;
  locationId?: Maybe<SortDirection>;
  creatorId?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
  updatedAt?: Maybe<SortDirection>;
};

export type EquipmentUpdateArgs = {
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  mac?: Maybe<Scalars['String']>;
  serial?: Maybe<Scalars['String']>;
  gateway?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
};

export type ErrorLog = {
  _id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['ID']>;
  userId: Scalars['String'];
  error: Scalars['String'];
  user?: Maybe<User>;
};

export type ErrorLogBasePayload = {
  data?: Maybe<ErrorLog>;
  error?: Maybe<StandardMutationError>;
};

export type ErrorLogInput = {
  userId: Scalars['String'];
  error: Scalars['String'];
};

export type EsDeviceData = {
  total?: Maybe<Scalars['Float']>;
  statistic?: Maybe<DeviceOsTracking>;
};

export type EsDeviceDataInput = {
  total?: Maybe<Scalars['Float']>;
  statistic?: Maybe<DeviceOsTrackingInput>;
};

export type EveryDay = {
  date?: Maybe<Scalars['DateTime']>;
  bookingKpi?: Maybe<Scalars['Float']>;
  availableKpi?: Maybe<Scalars['Float']>;
  availableShared?: Maybe<Scalars['Float']>;
};

export type Facebook = {
  appId?: Maybe<Scalars['String']>;
  appSecret?: Maybe<Scalars['String']>;
  accountKitSecret?: Maybe<Scalars['String']>;
  feed?: Maybe<Scalars['String']>;
  appDomain?: Maybe<Scalars['String']>;
  feedBody?: Maybe<FeedBody>;
};

export type FacebookFeedBody = {
  link?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
};

export type FacebookInput = {
  appId?: Maybe<Scalars['String']>;
  appSecret?: Maybe<Scalars['String']>;
  accountKitSecret?: Maybe<Scalars['String']>;
  feed?: Maybe<Scalars['String']>;
  appDomain?: Maybe<Scalars['String']>;
  feedBody?: Maybe<FacebookFeedBody>;
};

export type Fb = {
  fbId?: Maybe<Scalars['String']>;
  fbName?: Maybe<Scalars['String']>;
  fbGender?: Maybe<Scalars['String']>;
  fbAge?: Maybe<Scalars['String']>;
};

export type FeedBody = {
  link?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
};

export type File = {
  id?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
};

export type FileInputType = {
  id?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
};

export type FilePayload = {
  data?: Maybe<File>;
  error?: Maybe<StandardMutationError>;
};

export type Gender = {
  gender?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Float']>;
};

export type GeoType = {
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

export type GeoTypeInput = {
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

export type GetDetailLocationWhere = {
  campaignId?: Maybe<Scalars['String']>;
  locationId?: Maybe<Array<Scalars['String']>>;
  bannerId?: Maybe<Scalars['String']>;
  dateFrom: Scalars['DateTime'];
  dateTo?: Maybe<Scalars['DateTime']>;
  tab?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type Google = {
  clientId?: Maybe<Scalars['String']>;
  clientSecret?: Maybe<Scalars['String']>;
};

export type GoogleInput = {
  clientId?: Maybe<Scalars['String']>;
  clientSecret?: Maybe<Scalars['String']>;
};

export type GroupByDate = {
  key?: Maybe<Scalars['Float']>;
  key_as_string?: Maybe<Scalars['String']>;
  doc_count?: Maybe<Scalars['Float']>;
  sumByDate?: Maybe<SumByDate>;
  everyDay?: Maybe<Array<EveryDay>>;
};

export type GroupByDay = {
  date?: Maybe<Scalars['Float']>;
  click?: Maybe<Scalars['Float']>;
  impression?: Maybe<Scalars['Float']>;
  ctr?: Maybe<Scalars['Float']>;
};

export type GroupIncomeResult = {
  campaignName?: Maybe<Scalars['String']>;
  campaignId?: Maybe<Scalars['String']>;
  location?: Maybe<LocationResult>;
  locationId?: Maybe<Scalars['String']>;
  click?: Maybe<Scalars['Float']>;
  income?: Maybe<Scalars['Float']>;
  incomePartner?: Maybe<Scalars['Float']>;
};

export type HotspotOnOff = {
  on?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

export type IdFilterInput = {
  locationId?: Maybe<Scalars['String']>;
  campaignId?: Maybe<Scalars['String']>;
};

export type IdOperatorArgs = {
  in?: Maybe<Array<Scalars['ID']>>;
  nin?: Maybe<Array<Scalars['ID']>>;
};

export type Image = {
  _id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  type: Scalars['String'];
  category?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  imageId?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  typeText?: Maybe<Scalars['String']>;
  categoryText?: Maybe<Scalars['String']>;
  image?: Maybe<File>;
};

export type ImageBasePayload = {
  data?: Maybe<Image>;
  error?: Maybe<StandardMutationError>;
};

export type ImageFilter = {
  _ids?: Maybe<Array<Scalars['ID']>>;
  AND?: Maybe<ImageFilter>;
  OR?: Maybe<ImageFilter>;
  _search?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  _operators?: Maybe<ImageFilterOperator>;
  type?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  _id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ImageFilterOperator = {
  name?: Maybe<StringOperatorArgs>;
  type?: Maybe<StringOperatorArgs>;
  category?: Maybe<StringOperatorArgs>;
  _id?: Maybe<IdOperatorArgs>;
  createdAt?: Maybe<DateOperatorArgs>;
  updatedAt?: Maybe<DateOperatorArgs>;
};

export type ImageInput = {
  name: Scalars['String'];
  type: Scalars['String'];
  category?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  imageId?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
};

export type ImageSort = {
  name?: Maybe<SortDirection>;
  type?: Maybe<SortDirection>;
  category?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
  updatedAt?: Maybe<SortDirection>;
};

export type ImageUpdateArg = {
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  imageId?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
};

export type Income = {
  income?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Float']>;
};

export type IncomeFilter = {
  categoryId?: Maybe<Array<Scalars['String']>>;
  _id?: Maybe<Array<Scalars['String']>>;
  city?: Maybe<Array<Scalars['String']>>;
  district?: Maybe<Array<Scalars['String']>>;
  tag?: Maybe<Array<Scalars['String']>>;
  date?: Maybe<DateInput>;
  campaignId?: Maybe<Array<Scalars['String']>>;
  locationId?: Maybe<Array<Scalars['String']>>;
  customerId?: Maybe<Scalars['String']>;
  interval?: Maybe<IncomeInterval>;
};

export enum IncomeInterval {
  Day = 'Day',
  Month = 'Month',
  Year = 'Year'
}

export enum Interval {
  Day = 'Day',
  Month = 'Month'
}



export type LastNDay = {
  once?: Maybe<Scalars['Float']>;
  twoFive?: Maybe<Scalars['Float']>;
  sixTen?: Maybe<Scalars['Float']>;
  tenPlus?: Maybe<Scalars['Float']>;
};

export type LeafDetailType = {
  name: Scalars['String'];
  docCount: Scalars['Int'];
};

export type Location = {
  _id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  licenseName?: Maybe<Scalars['String']>;
  address?: Maybe<AddressType>;
  categoryId?: Maybe<Scalars['String']>;
  categoryName?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  cityName?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  districtName?: Maybe<Scalars['String']>;
  geo?: Maybe<GeoType>;
  status?: Maybe<Scalars['String']>;
  activeDay?: Maybe<Scalars['DateTime']>;
  stats?: Maybe<LocationStats>;
  noOfAccessPoint?: Maybe<Scalars['Float']>;
  noOfRouter?: Maybe<Scalars['Float']>;
  configAuth?: Maybe<Scalars['String']>;
  ownerType?: Maybe<Scalars['String']>;
  activeCampaigns?: Maybe<Array<ActiveCampaign>>;
  shareType?: Maybe<ShareType>;
  share?: Maybe<Scalars['String']>;
  creatorId?: Maybe<Scalars['ID']>;
  group?: Maybe<Scalars['String']>;
  extra?: Maybe<Scalars['JSON']>;
  available?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<Scalars['String']>>;
  description?: Maybe<Scalars['String']>;
  monthlyClick?: Maybe<Scalars['Float']>;
  pictureIds?: Maybe<Array<Scalars['String']>>;
  urlLogo?: Maybe<Scalars['String']>;
  bookingType?: Maybe<Scalars['String']>;
  lastActiveTracking?: Maybe<Scalars['DateTime']>;
  creator?: Maybe<User>;
  customer?: Maybe<User>;
  pictures?: Maybe<Array<File>>;
  price: Scalars['Int'];
  logos?: Maybe<File>;
  equipments?: Maybe<Array<Equipment>>;
  campaignRunning?: Maybe<Array<LocationCampaignRunning>>;
};

export type LocationActive = {
  categoryId?: Maybe<Scalars['String']>;
  countLocationActive?: Maybe<Scalars['Float']>;
};

export type LocationActiveCampaigns = {
  _id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  licenseName?: Maybe<Scalars['String']>;
  address?: Maybe<AddressType>;
  categoryId?: Maybe<Scalars['String']>;
  categoryName?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  cityName?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  districtName?: Maybe<Scalars['String']>;
  geo?: Maybe<GeoType>;
  status?: Maybe<Scalars['String']>;
  activeDay?: Maybe<Scalars['DateTime']>;
  stats?: Maybe<LocationStats>;
  noOfAccessPoint?: Maybe<Scalars['Float']>;
  noOfRouter?: Maybe<Scalars['Float']>;
  configAuth?: Maybe<Scalars['String']>;
  ownerType?: Maybe<Scalars['String']>;
  activeCampaigns?: Maybe<Array<ActiveCampaign>>;
  shareType?: Maybe<ShareType>;
  share?: Maybe<Scalars['String']>;
  creatorId?: Maybe<Scalars['ID']>;
  group?: Maybe<Scalars['String']>;
  extra?: Maybe<Scalars['JSON']>;
  available?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<Scalars['String']>>;
  description?: Maybe<Scalars['String']>;
  monthlyClick?: Maybe<Scalars['Float']>;
  pictureIds?: Maybe<Array<Scalars['String']>>;
  urlLogo?: Maybe<Scalars['String']>;
  bookingType?: Maybe<Scalars['String']>;
  lastActiveTracking?: Maybe<Scalars['DateTime']>;
  creator?: Maybe<User>;
  customer?: Maybe<User>;
  pictures?: Maybe<Array<File>>;
  price: Scalars['Int'];
  logos?: Maybe<File>;
  equipments?: Maybe<Array<Equipment>>;
  campaignRunning?: Maybe<Array<LocationCampaignRunning>>;
};

export type LocationBasePayload = {
  data?: Maybe<Location>;
  error?: Maybe<StandardMutationError>;
};

export type LocationBasicInput = {
  name?: Maybe<Scalars['String']>;
  licenseName?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  address?: Maybe<AddressTypeInput>;
  tags?: Maybe<Array<Scalars['String']>>;
  status?: Maybe<Scalars['String']>;
  urlLogo?: Maybe<Scalars['String']>;
};

export type LocationCampaignMapping = {
  locationId?: Maybe<Scalars['String']>;
  campaignId?: Maybe<Scalars['String']>;
  campaignName?: Maybe<Scalars['String']>;
  scheduleFrom?: Maybe<Scalars['DateTime']>;
  scheduleTo?: Maybe<Scalars['DateTime']>;
};

export type LocationCampaignRunning = {
  campaignName?: Maybe<Scalars['String']>;
  isBo?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  landingPage?: Maybe<Scalars['String']>;
  banners?: Maybe<Array<CampaignConfigBanner>>;
};

export type LocationCampaignRunningInput = {
  campaignName?: Maybe<Scalars['String']>;
  isBo?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  landingPage?: Maybe<Scalars['String']>;
  banners?: Maybe<Array<CampaignConfigBannerInput>>;
};

export type LocationCategory = {
  value: Scalars['String'];
  text: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
};

export type LocationCount = {
  categoryId?: Maybe<Scalars['String']>;
  categoryIcon?: Maybe<Scalars['String']>;
  categoryName?: Maybe<Scalars['String']>;
  countLocationActive?: Maybe<Scalars['Float']>;
  countLocationLicense?: Maybe<Scalars['Float']>;
  countLocationMGN?: Maybe<Scalars['Float']>;
  countTotalLocationCategory?: Maybe<Scalars['Float']>;
  sumMonthlyClick?: Maybe<Scalars['Float']>;
  ctrStats?: Maybe<Scalars['Float']>;
  uniqueUser?: Maybe<Scalars['Float']>;
};

export type LocationFilter = {
  _ids?: Maybe<Array<Scalars['ID']>>;
  AND?: Maybe<LocationFilter>;
  OR?: Maybe<LocationFilter>;
  _search?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  _operators?: Maybe<LocationFilterOperator>;
  licenseName?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  categoryName?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  cityName?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  districtName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  activeDay?: Maybe<Scalars['DateTime']>;
  stats?: Maybe<LocationstatsFilter>;
  noOfAccessPoint?: Maybe<Scalars['Float']>;
  noOfRouter?: Maybe<Scalars['Float']>;
  configAuth?: Maybe<Scalars['String']>;
  ownerType?: Maybe<Scalars['String']>;
  share?: Maybe<Scalars['String']>;
  creatorId?: Maybe<Scalars['ID']>;
  group?: Maybe<Scalars['String']>;
  extra?: Maybe<Scalars['JSON']>;
  tags?: Maybe<Scalars['String']>;
  lastActiveTracking?: Maybe<Scalars['DateTime']>;
  _id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LocationFilterOperator = {
  name?: Maybe<StringOperatorArgs>;
  licenseName?: Maybe<StringOperatorArgs>;
  categoryId?: Maybe<StringOperatorArgs>;
  categoryName?: Maybe<StringOperatorArgs>;
  city?: Maybe<StringOperatorArgs>;
  cityName?: Maybe<StringOperatorArgs>;
  district?: Maybe<StringOperatorArgs>;
  districtName?: Maybe<StringOperatorArgs>;
  status?: Maybe<StringOperatorArgs>;
  activeDay?: Maybe<DateOperatorArgs>;
  noOfAccessPoint?: Maybe<NumberOperatorArgs>;
  noOfRouter?: Maybe<NumberOperatorArgs>;
  configAuth?: Maybe<StringOperatorArgs>;
  ownerType?: Maybe<StringOperatorArgs>;
  share?: Maybe<StringOperatorArgs>;
  creatorId?: Maybe<IdOperatorArgs>;
  group?: Maybe<StringOperatorArgs>;
  tags?: Maybe<StringOperatorArgs>;
  lastActiveTracking?: Maybe<DateOperatorArgs>;
  _id?: Maybe<IdOperatorArgs>;
  createdAt?: Maybe<DateOperatorArgs>;
  updatedAt?: Maybe<DateOperatorArgs>;
};

export type LocationInput = {
  name?: Maybe<Scalars['String']>;
  licenseName?: Maybe<Scalars['String']>;
  address?: Maybe<AddressTypeInput>;
  categoryId?: Maybe<Scalars['String']>;
  categoryName?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  cityName?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  districtName?: Maybe<Scalars['String']>;
  geo?: Maybe<GeoTypeInput>;
  status?: Maybe<Scalars['String']>;
  activeDay?: Maybe<Scalars['DateTime']>;
  stats?: Maybe<LocationStatsInput>;
  noOfAccessPoint?: Maybe<Scalars['Float']>;
  noOfRouter?: Maybe<Scalars['Float']>;
  configAuth?: Maybe<Scalars['String']>;
  ownerType?: Maybe<Scalars['String']>;
  activeCampaigns?: Maybe<Array<ActiveCampaignInput>>;
  shareType?: Maybe<ShareTypeInput>;
  share?: Maybe<Scalars['String']>;
  creatorId?: Maybe<Scalars['ID']>;
  group?: Maybe<Scalars['String']>;
  extra?: Maybe<Scalars['JSON']>;
  available?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<Scalars['String']>>;
  description?: Maybe<Scalars['String']>;
  monthlyClick?: Maybe<Scalars['Float']>;
  pictureIds?: Maybe<Array<Scalars['String']>>;
  urlLogo?: Maybe<Scalars['String']>;
  bookingType?: Maybe<Scalars['String']>;
  lastActiveTracking?: Maybe<Scalars['DateTime']>;
};

export type LocationPayload = {
  data?: Maybe<Location>;
  error?: Maybe<StandardMutationError>;
};

export type LocationPrice = {
  _id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  licenseName?: Maybe<Scalars['String']>;
  address?: Maybe<AddressType>;
  categoryId?: Maybe<Scalars['String']>;
  categoryName?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  cityName?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  districtName?: Maybe<Scalars['String']>;
  geo?: Maybe<GeoType>;
  status?: Maybe<Scalars['String']>;
  activeDay?: Maybe<Scalars['DateTime']>;
  stats?: Maybe<LocationStats>;
  noOfAccessPoint?: Maybe<Scalars['Float']>;
  noOfRouter?: Maybe<Scalars['Float']>;
  configAuth?: Maybe<Scalars['String']>;
  ownerType?: Maybe<Scalars['String']>;
  activeCampaigns?: Maybe<Array<ActiveCampaign>>;
  shareType?: Maybe<ShareType>;
  share?: Maybe<Scalars['String']>;
  creatorId?: Maybe<Scalars['ID']>;
  group?: Maybe<Scalars['String']>;
  extra?: Maybe<Scalars['JSON']>;
  available?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<Scalars['String']>>;
  description?: Maybe<Scalars['String']>;
  monthlyClick?: Maybe<Scalars['Float']>;
  pictureIds?: Maybe<Array<Scalars['String']>>;
  urlLogo?: Maybe<Scalars['String']>;
  bookingType?: Maybe<Scalars['String']>;
  lastActiveTracking?: Maybe<Scalars['DateTime']>;
  creator?: Maybe<User>;
  customer?: Maybe<User>;
  pictures?: Maybe<Array<File>>;
  price?: Maybe<Scalars['Float']>;
  logos?: Maybe<File>;
  equipments?: Maybe<Array<Equipment>>;
  campaignRunning?: Maybe<Array<LocationCampaignRunning>>;
  categoryPrice?: Maybe<Scalars['Float']>;
};

export type LocationResult = {
  name?: Maybe<Scalars['String']>;
  licenseName?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
};

export type LocationSaleFilter = {
  _ids?: Maybe<Array<Scalars['ID']>>;
  AND?: Maybe<LocationSaleFilter>;
  OR?: Maybe<LocationSaleFilter>;
  _search?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  _operators?: Maybe<LocationSaleFilterOperator>;
  licenseName?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  categoryName?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  cityName?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  districtName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  activeDay?: Maybe<Scalars['DateTime']>;
  stats?: Maybe<LocationSalestatsFilter>;
  noOfAccessPoint?: Maybe<Scalars['Float']>;
  noOfRouter?: Maybe<Scalars['Float']>;
  configAuth?: Maybe<Scalars['String']>;
  ownerType?: Maybe<Scalars['String']>;
  share?: Maybe<Scalars['String']>;
  creatorId?: Maybe<Scalars['ID']>;
  group?: Maybe<Scalars['String']>;
  extra?: Maybe<Scalars['JSON']>;
  tags?: Maybe<Scalars['String']>;
  lastActiveTracking?: Maybe<Scalars['DateTime']>;
  _id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LocationSaleFilterOperator = {
  name?: Maybe<StringOperatorArgs>;
  licenseName?: Maybe<StringOperatorArgs>;
  categoryId?: Maybe<StringOperatorArgs>;
  categoryName?: Maybe<StringOperatorArgs>;
  city?: Maybe<StringOperatorArgs>;
  cityName?: Maybe<StringOperatorArgs>;
  district?: Maybe<StringOperatorArgs>;
  districtName?: Maybe<StringOperatorArgs>;
  status?: Maybe<StringOperatorArgs>;
  activeDay?: Maybe<DateOperatorArgs>;
  noOfAccessPoint?: Maybe<NumberOperatorArgs>;
  noOfRouter?: Maybe<NumberOperatorArgs>;
  configAuth?: Maybe<StringOperatorArgs>;
  ownerType?: Maybe<StringOperatorArgs>;
  share?: Maybe<StringOperatorArgs>;
  creatorId?: Maybe<IdOperatorArgs>;
  group?: Maybe<StringOperatorArgs>;
  tags?: Maybe<StringOperatorArgs>;
  lastActiveTracking?: Maybe<DateOperatorArgs>;
  _id?: Maybe<IdOperatorArgs>;
  createdAt?: Maybe<DateOperatorArgs>;
  updatedAt?: Maybe<DateOperatorArgs>;
};

export type LocationSaleSort = {
  name?: Maybe<SortDirection>;
  licenseName?: Maybe<SortDirection>;
  categoryId?: Maybe<SortDirection>;
  categoryName?: Maybe<SortDirection>;
  city?: Maybe<SortDirection>;
  cityName?: Maybe<SortDirection>;
  district?: Maybe<SortDirection>;
  districtName?: Maybe<SortDirection>;
  status?: Maybe<SortDirection>;
  activeDay?: Maybe<SortDirection>;
  stats?: Maybe<LocationSalestatsSort>;
  noOfAccessPoint?: Maybe<SortDirection>;
  noOfRouter?: Maybe<SortDirection>;
  configAuth?: Maybe<SortDirection>;
  ownerType?: Maybe<SortDirection>;
  creatorId?: Maybe<SortDirection>;
  lastActiveTracking?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
  updatedAt?: Maybe<SortDirection>;
};

export type LocationSalestatsFilter = {
  AND?: Maybe<LocationSalestatsFilter>;
  OR?: Maybe<LocationSalestatsFilter>;
  _search?: Maybe<Scalars['String']>;
  monthlyClick?: Maybe<Scalars['Float']>;
  _operators?: Maybe<LocationSalestatsFilterOperator>;
  monthlyImpression?: Maybe<Scalars['Float']>;
  dailyClick?: Maybe<Scalars['Float']>;
  dailyImpression?: Maybe<Scalars['Float']>;
  uniqueUser?: Maybe<Scalars['Float']>;
  ctr?: Maybe<Scalars['Float']>;
};

export type LocationSalestatsFilterOperator = {
  monthlyClick?: Maybe<NumberOperatorArgs>;
  monthlyImpression?: Maybe<NumberOperatorArgs>;
  dailyClick?: Maybe<NumberOperatorArgs>;
  dailyImpression?: Maybe<NumberOperatorArgs>;
  uniqueUser?: Maybe<NumberOperatorArgs>;
  ctr?: Maybe<NumberOperatorArgs>;
};

export type LocationSalestatsSort = {
  monthlyClick?: Maybe<SortDirection>;
  monthlyImpression?: Maybe<SortDirection>;
  dailyClick?: Maybe<SortDirection>;
  dailyImpression?: Maybe<SortDirection>;
  uniqueUser?: Maybe<SortDirection>;
  ctr?: Maybe<SortDirection>;
};

export type LocationsCommon = {
  value: Scalars['String'];
  text: Scalars['String'];
};

export type LocationSort = {
  name?: Maybe<SortDirection>;
  licenseName?: Maybe<SortDirection>;
  categoryId?: Maybe<SortDirection>;
  categoryName?: Maybe<SortDirection>;
  city?: Maybe<SortDirection>;
  cityName?: Maybe<SortDirection>;
  district?: Maybe<SortDirection>;
  districtName?: Maybe<SortDirection>;
  status?: Maybe<SortDirection>;
  activeDay?: Maybe<SortDirection>;
  stats?: Maybe<LocationstatsSort>;
  noOfAccessPoint?: Maybe<SortDirection>;
  noOfRouter?: Maybe<SortDirection>;
  configAuth?: Maybe<SortDirection>;
  ownerType?: Maybe<SortDirection>;
  creatorId?: Maybe<SortDirection>;
  lastActiveTracking?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
  updatedAt?: Maybe<SortDirection>;
};

export type LocationStats = {
  monthlyClick?: Maybe<Scalars['Float']>;
  monthlyImpression?: Maybe<Scalars['Float']>;
  dailyClick?: Maybe<Scalars['Float']>;
  dailyImpression?: Maybe<Scalars['Float']>;
  uniqueUser?: Maybe<Scalars['Float']>;
  ctr?: Maybe<Scalars['Float']>;
};

export type LocationstatsFilter = {
  AND?: Maybe<LocationstatsFilter>;
  OR?: Maybe<LocationstatsFilter>;
  _search?: Maybe<Scalars['String']>;
  monthlyClick?: Maybe<Scalars['Float']>;
  _operators?: Maybe<LocationstatsFilterOperator>;
  monthlyImpression?: Maybe<Scalars['Float']>;
  dailyClick?: Maybe<Scalars['Float']>;
  dailyImpression?: Maybe<Scalars['Float']>;
  uniqueUser?: Maybe<Scalars['Float']>;
  ctr?: Maybe<Scalars['Float']>;
};

export type LocationstatsFilterOperator = {
  monthlyClick?: Maybe<NumberOperatorArgs>;
  monthlyImpression?: Maybe<NumberOperatorArgs>;
  dailyClick?: Maybe<NumberOperatorArgs>;
  dailyImpression?: Maybe<NumberOperatorArgs>;
  uniqueUser?: Maybe<NumberOperatorArgs>;
  ctr?: Maybe<NumberOperatorArgs>;
};

export type LocationStatsInput = {
  monthlyClick?: Maybe<Scalars['Float']>;
  monthlyImpression?: Maybe<Scalars['Float']>;
  dailyClick?: Maybe<Scalars['Float']>;
  dailyImpression?: Maybe<Scalars['Float']>;
  uniqueUser?: Maybe<Scalars['Float']>;
  ctr?: Maybe<Scalars['Float']>;
};

export type LocationstatsSort = {
  monthlyClick?: Maybe<SortDirection>;
  monthlyImpression?: Maybe<SortDirection>;
  dailyClick?: Maybe<SortDirection>;
  dailyImpression?: Maybe<SortDirection>;
  uniqueUser?: Maybe<SortDirection>;
  ctr?: Maybe<SortDirection>;
};

export type LocationStatsType = {
  location?: Maybe<Scalars['Float']>;
  locationAds?: Maybe<Scalars['Float']>;
  campaignRunning?: Maybe<Scalars['Float']>;
  realCampaign?: Maybe<Scalars['Float']>;
};

export type LocationUpdateArg = {
  name?: Maybe<Scalars['String']>;
  licenseName?: Maybe<Scalars['String']>;
  address?: Maybe<AddressTypeInput>;
  categoryId?: Maybe<Scalars['String']>;
  categoryName?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  cityName?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  districtName?: Maybe<Scalars['String']>;
  geo?: Maybe<GeoTypeInput>;
  status?: Maybe<Scalars['String']>;
  activeDay?: Maybe<Scalars['DateTime']>;
  stats?: Maybe<LocationStatsInput>;
  noOfAccessPoint?: Maybe<Scalars['Float']>;
  noOfRouter?: Maybe<Scalars['Float']>;
  configAuth?: Maybe<Scalars['String']>;
  ownerType?: Maybe<Scalars['String']>;
  activeCampaigns?: Maybe<Array<ActiveCampaignInput>>;
  shareType?: Maybe<ShareTypeInput>;
  share?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['String']>;
  extra?: Maybe<Scalars['JSON']>;
  available?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<Scalars['String']>>;
  description?: Maybe<Scalars['String']>;
  monthlyClick?: Maybe<Scalars['Float']>;
  pictureIds?: Maybe<Array<Scalars['String']>>;
  urlLogo?: Maybe<Scalars['String']>;
  bookingType?: Maybe<Scalars['String']>;
  lastActiveTracking?: Maybe<Scalars['DateTime']>;
};

export type LoginData = {
  locationId?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['JSON']>;
  day?: Maybe<Scalars['JSON']>;
  hour?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoginPayload = {
  data?: Maybe<User>;
  error?: Maybe<StandardMutationError>;
  authToken?: Maybe<Scalars['String']>;
};

export type Logs = {
  _id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['ID']>;
  type?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
  model?: Maybe<Scalars['String']>;
  resourceId?: Maybe<Scalars['ID']>;
  old?: Maybe<Scalars['JSON']>;
  new?: Maybe<Scalars['JSON']>;
  user?: Maybe<User>;
  resourceCampaign?: Maybe<Campaign>;
};

export type LogsFilter = {
  _ids?: Maybe<Array<Scalars['ID']>>;
  AND?: Maybe<LogsFilter>;
  OR?: Maybe<LogsFilter>;
  _search?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  _operators?: Maybe<LogsFilterOperator>;
  userId?: Maybe<Scalars['ID']>;
  resourceId?: Maybe<Scalars['ID']>;
  _id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LogsFilterOperator = {
  type?: Maybe<StringOperatorArgs>;
  userId?: Maybe<IdOperatorArgs>;
  resourceId?: Maybe<IdOperatorArgs>;
  _id?: Maybe<IdOperatorArgs>;
  createdAt?: Maybe<DateOperatorArgs>;
  updatedAt?: Maybe<DateOperatorArgs>;
};

export type LogsInput = {
  type?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
  model?: Maybe<Scalars['String']>;
  resourceId?: Maybe<Scalars['ID']>;
  old?: Maybe<Scalars['JSON']>;
  new?: Maybe<Scalars['JSON']>;
};

export type LogsPayload = {
  data?: Maybe<Logs>;
  error?: Maybe<StandardMutationError>;
};

export type LogsSort = {
  type?: Maybe<SortDirection>;
  userId?: Maybe<SortDirection>;
  resourceId?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
  updatedAt?: Maybe<SortDirection>;
};

export type Message = {
  _id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['ID']>;
  receiverId: Scalars['ID'];
  title: Scalars['String'];
  content: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  seenAt?: Maybe<Scalars['DateTime']>;
  receiver: User;
};

export type MessageBasePayload = {
  data?: Maybe<Message>;
  error?: Maybe<StandardMutationError>;
};

export type MessageFilter = {
  _ids?: Maybe<Array<Scalars['ID']>>;
  AND?: Maybe<MessageFilter>;
  OR?: Maybe<MessageFilter>;
  _search?: Maybe<Scalars['String']>;
  receiverId?: Maybe<Scalars['ID']>;
  _operators?: Maybe<MessageFilterOperator>;
  title?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  seenAt?: Maybe<Scalars['DateTime']>;
  _id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MessageFilterOperator = {
  receiverId?: Maybe<IdOperatorArgs>;
  title?: Maybe<StringOperatorArgs>;
  content?: Maybe<StringOperatorArgs>;
  status?: Maybe<StringOperatorArgs>;
  seenAt?: Maybe<DateOperatorArgs>;
  _id?: Maybe<IdOperatorArgs>;
  createdAt?: Maybe<DateOperatorArgs>;
  updatedAt?: Maybe<DateOperatorArgs>;
};

export type MessageInput = {
  receiverId: Scalars['ID'];
  title: Scalars['String'];
  content: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  seenAt?: Maybe<Scalars['DateTime']>;
};

export type MessageSort = {
  receiverId?: Maybe<SortDirection>;
  title?: Maybe<SortDirection>;
  content?: Maybe<SortDirection>;
  status?: Maybe<SortDirection>;
  seenAt?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
  updatedAt?: Maybe<SortDirection>;
};

export type MessageUpdateArg = {
  receiverId?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  seenAt?: Maybe<Scalars['DateTime']>;
};

export type Meta = {
  campaignId?: Maybe<Scalars['String']>;
  campaignCategoryId?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  realLocationId?: Maybe<Scalars['String']>;
  locationCategoryId?: Maybe<Scalars['String']>;
  cityId?: Maybe<Scalars['String']>;
  bannerId?: Maybe<Scalars['String']>;
  equipmentId?: Maybe<Scalars['String']>;
};

export type MetaElastic = {
  locationId?: Maybe<Scalars['String']>;
  campaignId?: Maybe<Scalars['String']>;
  bannerId?: Maybe<Scalars['String']>;
  realLocationId?: Maybe<Scalars['String']>;
};

export enum Method {
  Post = 'post',
  Get = 'get'
}

export type Mutation = {
  createSetting: SettingBasePayload;
  updateSettingById: SettingBasePayload;
  removeSettingById: SettingBasePayload;
  createBanner: BannerBasePayload;
  updateBannerById: BannerBasePayload;
  removeBannerById: BannerBasePayload;
  updateBannerActive: BannerPayload;
  updateBannerTempById: BannerTempBasePayload;
  removeBannerTempById: BannerTempBasePayload;
  createBannerTemp: BannerTempBasePayload;
  createBannerTemplate: BannerTemplateBasePayload;
  updateBannerTemplateById: BannerTemplateBasePayload;
  removeBannerTemplateById: BannerTemplateBasePayload;
  createCampaignMapping: CampaignMappingBasePayload;
  updateCampaignMappingById: CampaignMappingBasePayload;
  removeCampaignMappingById: CampaignMappingBasePayload;
  updateCampaignPriority: CampaignMappingPayload;
  updateMultiLocations: UpdateMultiResult;
  updateBooking: UpdateBookingResults;
  stopBooking: CampaignMappingPayload;
  addKpiManagement: UpdateBookingResults;
  updateCalendarBooking: UpdateBookingResults;
  addLocation: UpdateBookingResults;
  createCampaign: CampaignBasePayload;
  updateCampaignById: CampaignBasePayload;
  removeCampaignById: CampaignBasePayload;
  approveCampaign: CampaignPayload;
  disableCampaign: CampaignPayload;
  enableCampaign: CampaignPayload;
  deleteCampaignById: CampaignPayload;
  updateConfig: CampaignPayload;
  setSchedule: CampaignPayload;
  setTimes: CampaignPayload;
  createCampaignOnline: CampaignPayload;
  updateAdvanceCampaign: CampaignPayload;
  updateCommonById: CommonBasePayload;
  removeCommonById: CommonBasePayload;
  createCommon: CommonPayload;
  updateCustomerById: CustomerBasePayload;
  createCustomer: CustomerBasePayload;
  createCustomerType: CustomerTypeBasePayload;
  updateCustomerTypeById: CustomerTypeBasePayload;
  removeCustomerTypeById: CustomerTypeBasePayload;
  setDefaultCustomerType: Scalars['Boolean'];
  removeEquipmentById: EquipmentBasePayload;
  createEquipment: EquipmentPayload;
  updateEquipmentById: EquipmentPayload;
  createErrorLog: ErrorLogBasePayload;
  createFile: FilePayload;
  removeFileById: FilePayload;
  createImage: ImageBasePayload;
  updateImageById: ImageBasePayload;
  removeImageById: ImageBasePayload;
  createLocation: LocationBasePayload;
  updateLocationById: LocationBasePayload;
  removeLocationById: LocationBasePayload;
  updateStatusLocation: LocationPayload;
  createBasicLocation: LocationPayload;
  updateSaleInfo: LocationPayload;
  updateSystemInfo: LocationPayload;
  updateBasicLocation: LocationPayload;
  updateAdvanceLocation: LocationPayload;
  createMessage: MessageBasePayload;
  updateMessageById: MessageBasePayload;
  removeMessageById: MessageBasePayload;
  createNotification: NotificationBasePayload;
  updateNotificationById: NotificationBasePayload;
  removeNotificationById: NotificationBasePayload;
  deleteAllNotification: Scalars['String'];
  createOrder: OrderBasePayload;
  updateOrderById: OrderBasePayload;
  removeOrderById: OrderBasePayload;
  updateOwnerById: OwnerBasePayload;
  removeOwnerById: OwnerBasePayload;
  upsertAssignment: OwnerPayload;
  createPack: PackBasePayload;
  updatePackById: PackBasePayload;
  removePackById: PackBasePayload;
  removePaymentById: PaymentBasePayload;
  createPayment: PaymentPayload;
  verifyPayment: PaymentPayload;
  rejectPayment: PaymentPayload;
  createPlan: PlanBasePayload;
  updatePlanById: PlanBasePayload;
  removePlanById: PlanBasePayload;
  addPricing: PricingPayload;
  removePricing: PricingPayload;
  removePricingHistoryById: PricingHistoryBasePayload;
  createProfile: ProfileBasePayload;
  updateProfileById: ProfileBasePayload;
  removeProfileById: ProfileBasePayload;
  createRegion: RegionBasePayload;
  updateRegionById: RegionBasePayload;
  removeRegionById: RegionBasePayload;
  updateRevenueById: RevenueBasePayload;
  removeRevenueById: RevenueBasePayload;
  createRevenue: RevenuePayload;
  verifyRevenue: RevenuePayload;
  updateRoleDescriptionById: RolePayload;
  removeTwoFactorAuthById: TwoFactorAuthBasePayload;
  createTwoFactorAuthentication: TwoFactorAuthPayload;
  enableTwoFactorAuthentication: TwoFactorAuthPayload;
  disableTwoFactorAuthentication: TwoFactorAuthPayload;
  updateUserById: UserBasePayload;
  removeUserById: UserBasePayload;
  login: LoginPayload;
  loginTwoFactorAuth: LoginPayload;
  changePassword: UserPayload;
  resetPassword: UserPayload;
  createUser: UserPayload;
  signUp: UserPayload;
  verifyEmail: Scalars['Boolean'];
  restorePassword: UserPayload;
  setActive: UserPayload;
  setValueVerifyEmail: UserPayload;
  sendMailForgotPassword: UserPayload;
  sendMailByLicense: UserPayload;
  verifyEmailLicense: UserPayload;
  upgradeLicense: UserPayload;
  sendEmailVerify: UserPayload;
  updateUserInfoById: UserPayload;
  createUserGuide: UserGuideBasePayload;
  updateUserGuideById: UserGuideBasePayload;
  removeUserGuideById: UserGuideBasePayload;
  logLogout: LogsPayload;
};


export type MutationCreateSettingArgs = {
  record: SettingInput;
};


export type MutationUpdateSettingByIdArgs = {
  _id: Scalars['String'];
  record: SettingUpdateArg;
};


export type MutationRemoveSettingByIdArgs = {
  _id: Scalars['String'];
};


export type MutationCreateBannerArgs = {
  record: BannerInput;
};


export type MutationUpdateBannerByIdArgs = {
  _id: Scalars['String'];
  record: BannerUpdateArg;
};


export type MutationRemoveBannerByIdArgs = {
  _id: Scalars['String'];
};


export type MutationUpdateBannerActiveArgs = {
  _id: Scalars['String'];
};


export type MutationUpdateBannerTempByIdArgs = {
  _id: Scalars['String'];
  record: BannerTempUpdateArg;
};


export type MutationRemoveBannerTempByIdArgs = {
  _id: Scalars['String'];
};


export type MutationCreateBannerTemplateArgs = {
  record: BannerTemplateInput;
};


export type MutationUpdateBannerTemplateByIdArgs = {
  _id: Scalars['String'];
  record: BannerTemplateUpdateArg;
};


export type MutationRemoveBannerTemplateByIdArgs = {
  _id: Scalars['String'];
};


export type MutationCreateCampaignMappingArgs = {
  record: CampaignMappingInput;
};


export type MutationUpdateCampaignMappingByIdArgs = {
  _id: Scalars['String'];
  record: CampaignMappingUpdateArg;
};


export type MutationRemoveCampaignMappingByIdArgs = {
  _id: Scalars['String'];
};


export type MutationUpdateCampaignPriorityArgs = {
  old?: Maybe<Array<CmPositionInput>>;
  current?: Maybe<Array<CmPositionInput>>;
  locationId?: Maybe<Scalars['String']>;
};


export type MutationUpdateMultiLocationsArgs = {
  status?: Maybe<Scalars['String']>;
  campaign: Array<Scalars['String']>;
  locations?: Maybe<Array<ResultInput>>;
};


export type MutationUpdateBookingArgs = {
  campaignMapping: CampaignMappingInput;
};


export type MutationStopBookingArgs = {
  idFilterArg: IdFilterInput;
};


export type MutationAddKpiManagementArgs = {
  campaignMapping: AddKpiInput;
};


export type MutationUpdateCalendarBookingArgs = {
  booking: Array<BookingInput>;
};


export type MutationAddLocationArgs = {
  addLocationParams: AddLocationParams;
};


export type MutationCreateCampaignArgs = {
  record: CampaignInput;
};


export type MutationUpdateCampaignByIdArgs = {
  _id: Scalars['String'];
  record: CampaignUpdateArg;
};


export type MutationRemoveCampaignByIdArgs = {
  _id: Scalars['String'];
};


export type MutationApproveCampaignArgs = {
  _id: Scalars['String'];
  allowed: Scalars['Boolean'];
  campaignType?: Maybe<Scalars['String']>;
  locations?: Maybe<Array<CampaignLocationInput>>;
};


export type MutationDisableCampaignArgs = {
  _id: Scalars['String'];
  duration?: Maybe<Scalars['Float']>;
};


export type MutationEnableCampaignArgs = {
  _id: Scalars['String'];
};


export type MutationDeleteCampaignByIdArgs = {
  _id: Scalars['String'];
};


export type MutationUpdateConfigArgs = {
  record: CampaignConfigUpdateArgs;
  id: Scalars['String'];
};


export type MutationSetScheduleArgs = {
  where: SetScheduleArgs;
  _id: Scalars['String'];
};


export type MutationSetTimesArgs = {
  _id: Scalars['String'];
  times: Scalars['String'];
};


export type MutationCreateCampaignOnlineArgs = {
  data: BookingOnlineArgs;
};


export type MutationUpdateAdvanceCampaignArgs = {
  _id: Scalars['String'];
  record: AdvanceCampaignInfoArgs;
};


export type MutationUpdateCommonByIdArgs = {
  _id: Scalars['String'];
  record: CommonUpdateArg;
};


export type MutationRemoveCommonByIdArgs = {
  _id: Scalars['String'];
};


export type MutationCreateCommonArgs = {
  record: CommonInput;
};


export type MutationUpdateCustomerByIdArgs = {
  _id: Scalars['String'];
  record: CustomerUpdateArg;
};


export type MutationCreateCustomerArgs = {
  record: CustomerInput;
};


export type MutationCreateCustomerTypeArgs = {
  record: CustomerTypeInput;
};


export type MutationUpdateCustomerTypeByIdArgs = {
  _id: Scalars['String'];
  record: CustomerTypeUpdateArg;
};


export type MutationRemoveCustomerTypeByIdArgs = {
  _id: Scalars['String'];
};


export type MutationSetDefaultCustomerTypeArgs = {
  _id: Scalars['String'];
};


export type MutationRemoveEquipmentByIdArgs = {
  _id: Scalars['String'];
};


export type MutationCreateEquipmentArgs = {
  record: EquipmentInput;
};


export type MutationUpdateEquipmentByIdArgs = {
  _id: Scalars['String'];
  record: EquipmentUpdateArgs;
};


export type MutationCreateErrorLogArgs = {
  record: ErrorLogInput;
};


export type MutationCreateFileArgs = {
  fileName?: Maybe<Scalars['String']>;
  bannerId?: Maybe<Scalars['String']>;
};


export type MutationRemoveFileByIdArgs = {
  id: Scalars['String'];
};


export type MutationCreateImageArgs = {
  record: ImageInput;
};


export type MutationUpdateImageByIdArgs = {
  _id: Scalars['String'];
  record: ImageUpdateArg;
};


export type MutationRemoveImageByIdArgs = {
  _id: Scalars['String'];
};


export type MutationCreateLocationArgs = {
  record: LocationInput;
};


export type MutationUpdateLocationByIdArgs = {
  _id: Scalars['String'];
  record: LocationUpdateArg;
};


export type MutationRemoveLocationByIdArgs = {
  _id: Scalars['String'];
};


export type MutationUpdateStatusLocationArgs = {
  status: Scalars['String'];
  _id: Scalars['String'];
};


export type MutationCreateBasicLocationArgs = {
  record: LocationBasicInput;
};


export type MutationUpdateSaleInfoArgs = {
  _id: Scalars['String'];
  record: SaleInfoInput;
};


export type MutationUpdateSystemInfoArgs = {
  _id: Scalars['String'];
  record: SystemInfoInput;
};


export type MutationUpdateBasicLocationArgs = {
  _id: Scalars['String'];
  record: LocationBasicInput;
};


export type MutationUpdateAdvanceLocationArgs = {
  _id: Scalars['String'];
  record: AdvanceLocationUpdateArgs;
};


export type MutationCreateMessageArgs = {
  record: MessageInput;
};


export type MutationUpdateMessageByIdArgs = {
  _id: Scalars['String'];
  record: MessageUpdateArg;
};


export type MutationRemoveMessageByIdArgs = {
  _id: Scalars['String'];
};


export type MutationCreateNotificationArgs = {
  record: NotificationInput;
};


export type MutationUpdateNotificationByIdArgs = {
  _id: Scalars['String'];
  record: NotificationUpdateArg;
};


export type MutationRemoveNotificationByIdArgs = {
  _id: Scalars['String'];
};


export type MutationCreateOrderArgs = {
  record: OrderInput;
};


export type MutationUpdateOrderByIdArgs = {
  _id: Scalars['String'];
  record: OrderUpdateArg;
};


export type MutationRemoveOrderByIdArgs = {
  _id: Scalars['String'];
};


export type MutationUpdateOwnerByIdArgs = {
  _id: Scalars['String'];
  record: OwnerUpdateArg;
};


export type MutationRemoveOwnerByIdArgs = {
  _id: Scalars['String'];
};


export type MutationUpsertAssignmentArgs = {
  model: Scalars['String'];
  userId: Scalars['String'];
  resourceIds: Array<Scalars['String']>;
};


export type MutationCreatePackArgs = {
  record: PackInput;
};


export type MutationUpdatePackByIdArgs = {
  _id: Scalars['String'];
  record: PackUpdateArg;
};


export type MutationRemovePackByIdArgs = {
  _id: Scalars['String'];
};


export type MutationRemovePaymentByIdArgs = {
  _id: Scalars['String'];
};


export type MutationCreatePaymentArgs = {
  record: PaymentInput;
};


export type MutationVerifyPaymentArgs = {
  id: Scalars['String'];
};


export type MutationRejectPaymentArgs = {
  _id: Scalars['String'];
  rejectReason?: Maybe<Scalars['String']>;
};


export type MutationCreatePlanArgs = {
  record: PlanInput;
};


export type MutationUpdatePlanByIdArgs = {
  _id: Scalars['String'];
  record: PlanUpdateArg;
};


export type MutationRemovePlanByIdArgs = {
  _id: Scalars['String'];
};


export type MutationAddPricingArgs = {
  record: PricingInput;
};


export type MutationRemovePricingArgs = {
  categoryId?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
};


export type MutationRemovePricingHistoryByIdArgs = {
  _id: Scalars['String'];
};


export type MutationCreateProfileArgs = {
  record: ProfileInput;
};


export type MutationUpdateProfileByIdArgs = {
  _id: Scalars['String'];
  record: ProfileUpdateArg;
};


export type MutationRemoveProfileByIdArgs = {
  _id: Scalars['String'];
};


export type MutationCreateRegionArgs = {
  record: RegionInput;
};


export type MutationUpdateRegionByIdArgs = {
  _id: Scalars['String'];
  record: RegionUpdateArg;
};


export type MutationRemoveRegionByIdArgs = {
  _id: Scalars['String'];
};


export type MutationUpdateRevenueByIdArgs = {
  _id: Scalars['String'];
  record: RevenueUpdateArg;
};


export type MutationRemoveRevenueByIdArgs = {
  _id: Scalars['String'];
};


export type MutationCreateRevenueArgs = {
  campaignId: Scalars['String'];
};


export type MutationVerifyRevenueArgs = {
  _id: Scalars['String'];
};


export type MutationUpdateRoleDescriptionByIdArgs = {
  description: Scalars['String'];
  _id: Scalars['String'];
};


export type MutationRemoveTwoFactorAuthByIdArgs = {
  _id: Scalars['String'];
};


export type MutationUpdateUserByIdArgs = {
  _id: Scalars['String'];
  record: UserUpdateArg;
};


export type MutationRemoveUserByIdArgs = {
  _id: Scalars['String'];
};


export type MutationLoginArgs = {
  password: Scalars['String'];
  email: Scalars['String'];
};


export type MutationLoginTwoFactorAuthArgs = {
  password: Scalars['String'];
  userId: Scalars['String'];
  code: Scalars['String'];
};


export type MutationChangePasswordArgs = {
  confirmPass: Scalars['String'];
  newPass: Scalars['String'];
  oldPass: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  newPass?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
};


export type MutationCreateUserArgs = {
  record: UserInput;
};


export type MutationSignUpArgs = {
  record: UserRegisterInput;
};


export type MutationVerifyEmailArgs = {
  key: Scalars['String'];
};


export type MutationRestorePasswordArgs = {
  verifyPass: Scalars['String'];
  newPass: Scalars['String'];
  _id: Scalars['String'];
};


export type MutationSetActiveArgs = {
  active: Scalars['Boolean'];
  email: Scalars['String'];
};


export type MutationSetValueVerifyEmailArgs = {
  email: Scalars['String'];
};


export type MutationSendMailForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationSendMailByLicenseArgs = {
  email: Scalars['String'];
  id: Scalars['String'];
};


export type MutationVerifyEmailLicenseArgs = {
  key: Scalars['String'];
  email: Scalars['String'];
  id: Scalars['String'];
};


export type MutationUpgradeLicenseArgs = {
  id: Scalars['String'];
};


export type MutationSendEmailVerifyArgs = {
  email: Scalars['String'];
};


export type MutationUpdateUserInfoByIdArgs = {
  _id: Scalars['String'];
  record: UserUpdateInfoArgs;
};


export type MutationCreateUserGuideArgs = {
  record: UserGuideInput;
};


export type MutationUpdateUserGuideByIdArgs = {
  _id: Scalars['String'];
  record: UserGuideUpdateArg;
};


export type MutationRemoveUserGuideByIdArgs = {
  _id: Scalars['String'];
};

export type Notification = {
  _id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['ID']>;
  userId?: Maybe<Scalars['ID']>;
  content: Scalars['String'];
  modelType?: Maybe<Scalars['String']>;
  notifi_type?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  prioryty?: Maybe<Scalars['String']>;
  resourceId?: Maybe<Scalars['ID']>;
  seenAt?: Maybe<Scalars['DateTime']>;
  user: User;
};

export type NotificationBasePayload = {
  data?: Maybe<Notification>;
  error?: Maybe<StandardMutationError>;
};

export type NotificationFilter = {
  _ids?: Maybe<Array<Scalars['ID']>>;
  AND?: Maybe<NotificationFilter>;
  OR?: Maybe<NotificationFilter>;
  _search?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
  _operators?: Maybe<NotificationFilterOperator>;
  content?: Maybe<Scalars['String']>;
  modelType?: Maybe<Scalars['String']>;
  notifi_type?: Maybe<Scalars['String']>;
  resourceId?: Maybe<Scalars['ID']>;
  seenAt?: Maybe<Scalars['DateTime']>;
  _id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type NotificationFilterOperator = {
  userId?: Maybe<IdOperatorArgs>;
  content?: Maybe<StringOperatorArgs>;
  modelType?: Maybe<StringOperatorArgs>;
  notifi_type?: Maybe<StringOperatorArgs>;
  resourceId?: Maybe<IdOperatorArgs>;
  seenAt?: Maybe<DateOperatorArgs>;
  _id?: Maybe<IdOperatorArgs>;
  createdAt?: Maybe<DateOperatorArgs>;
  updatedAt?: Maybe<DateOperatorArgs>;
};

export type NotificationInput = {
  userId?: Maybe<Scalars['ID']>;
  content: Scalars['String'];
  modelType?: Maybe<Scalars['String']>;
  notifi_type?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  prioryty?: Maybe<Scalars['String']>;
  resourceId?: Maybe<Scalars['ID']>;
  seenAt?: Maybe<Scalars['DateTime']>;
};

export type NotificationSort = {
  userId?: Maybe<SortDirection>;
  content?: Maybe<SortDirection>;
  modelType?: Maybe<SortDirection>;
  notifi_type?: Maybe<SortDirection>;
  resourceId?: Maybe<SortDirection>;
  seenAt?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
  updatedAt?: Maybe<SortDirection>;
};

export type NotificationUpdateArg = {
  userId?: Maybe<Scalars['ID']>;
  content?: Maybe<Scalars['String']>;
  modelType?: Maybe<Scalars['String']>;
  notifi_type?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  prioryty?: Maybe<Scalars['String']>;
  resourceId?: Maybe<Scalars['ID']>;
  seenAt?: Maybe<Scalars['DateTime']>;
};

export type NumberOperatorArgs = {
  in?: Maybe<Array<Scalars['Float']>>;
  nin?: Maybe<Array<Scalars['Float']>>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
};

export type OnlineSession = {
  detail?: Maybe<Array<TrackingDetail>>;
  total?: Maybe<Scalars['Float']>;
};

export type Order = {
  _id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['ID']>;
  plans: Array<OrderPlan>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  isRecharge: Scalars['Boolean'];
  createOrderStatus?: Maybe<Scalars['String']>;
  queryCount?: Maybe<Scalars['Float']>;
  paymentProvider?: Maybe<Scalars['String']>;
  paymentAmount?: Maybe<Scalars['Float']>;
  paymentRedirectUrl?: Maybe<Scalars['String']>;
  paymentMessage?: Maybe<Scalars['String']>;
  paymentBank?: Maybe<Scalars['String']>;
  paymentRefNo?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  waitedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  creatorId?: Maybe<Scalars['ID']>;
  customer?: Maybe<User>;
  creator?: Maybe<User>;
};

export type OrderBasePayload = {
  data?: Maybe<Order>;
  error?: Maybe<StandardMutationError>;
};

export type OrderFilter = {
  _ids?: Maybe<Array<Scalars['ID']>>;
  AND?: Maybe<OrderFilter>;
  OR?: Maybe<OrderFilter>;
  _search?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  _operators?: Maybe<OrderFilterOperator>;
  type?: Maybe<Scalars['String']>;
  creatorId?: Maybe<Scalars['ID']>;
  _id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderFilterOperator = {
  status?: Maybe<StringOperatorArgs>;
  type?: Maybe<StringOperatorArgs>;
  creatorId?: Maybe<IdOperatorArgs>;
  _id?: Maybe<IdOperatorArgs>;
  createdAt?: Maybe<DateOperatorArgs>;
  updatedAt?: Maybe<DateOperatorArgs>;
};

export type OrderInput = {
  plans: Array<OrderPlanInput>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  isRecharge: Scalars['Boolean'];
  createOrderStatus?: Maybe<Scalars['String']>;
  queryCount?: Maybe<Scalars['Float']>;
  paymentProvider?: Maybe<Scalars['String']>;
  paymentAmount?: Maybe<Scalars['Float']>;
  paymentRedirectUrl?: Maybe<Scalars['String']>;
  paymentMessage?: Maybe<Scalars['String']>;
  paymentBank?: Maybe<Scalars['String']>;
  paymentRefNo?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  waitedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  creatorId?: Maybe<Scalars['ID']>;
};

export type OrderPlan = {
  planCode: Scalars['String'];
  price: Scalars['Float'];
  locationIds?: Maybe<Array<Scalars['String']>>;
  amount: Scalars['Float'];
  calPrice: Scalars['Float'];
};

export type OrderPlanInput = {
  planCode: Scalars['String'];
  price: Scalars['Float'];
  locationIds?: Maybe<Array<Scalars['String']>>;
  amount: Scalars['Float'];
  calPrice: Scalars['Float'];
};

export type OrderSort = {
  status?: Maybe<SortDirection>;
  type?: Maybe<SortDirection>;
  creatorId?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
  updatedAt?: Maybe<SortDirection>;
};

export type OrderUpdateArg = {
  plans?: Maybe<Array<OrderPlanInput>>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  isRecharge?: Maybe<Scalars['Boolean']>;
  createOrderStatus?: Maybe<Scalars['String']>;
  queryCount?: Maybe<Scalars['Float']>;
  paymentProvider?: Maybe<Scalars['String']>;
  paymentAmount?: Maybe<Scalars['Float']>;
  paymentRedirectUrl?: Maybe<Scalars['String']>;
  paymentMessage?: Maybe<Scalars['String']>;
  paymentBank?: Maybe<Scalars['String']>;
  paymentRefNo?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  waitedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
};

export type OsCount = {
  iOS?: Maybe<Scalars['Float']>;
  Android?: Maybe<Scalars['Float']>;
  Windows?: Maybe<Scalars['Float']>;
  other?: Maybe<Scalars['Float']>;
};

export type OsTracking = {
  iOS?: Maybe<Scalars['Float']>;
  Android?: Maybe<Scalars['Float']>;
  Windows?: Maybe<Scalars['Float']>;
  other?: Maybe<Scalars['Float']>;
};

export type OsTrackingInput = {
  iOS?: Maybe<Scalars['Float']>;
  Android?: Maybe<Scalars['Float']>;
  Windows?: Maybe<Scalars['Float']>;
  other?: Maybe<Scalars['Float']>;
};

export type Owner = {
  _id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['ID']>;
  userId: Scalars['ID'];
  resourceId: Scalars['String'];
  model: Scalars['String'];
};

export type OwnerBasePayload = {
  data?: Maybe<Owner>;
  error?: Maybe<StandardMutationError>;
};

export type OwnerFilter = {
  _ids?: Maybe<Array<Scalars['ID']>>;
  AND?: Maybe<OwnerFilter>;
  OR?: Maybe<OwnerFilter>;
  _search?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
  _operators?: Maybe<OwnerFilterOperator>;
  resourceId?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OwnerFilterOperator = {
  userId?: Maybe<IdOperatorArgs>;
  resourceId?: Maybe<StringOperatorArgs>;
  model?: Maybe<StringOperatorArgs>;
  _id?: Maybe<IdOperatorArgs>;
  createdAt?: Maybe<DateOperatorArgs>;
  updatedAt?: Maybe<DateOperatorArgs>;
};

export type OwnerInput = {
  userId: Scalars['ID'];
  resourceId: Scalars['String'];
  model: Scalars['String'];
};

export type OwnerPayload = {
  data?: Maybe<Array<Owner>>;
  error?: Maybe<StandardMutationError>;
};

export type OwnerSort = {
  userId?: Maybe<SortDirection>;
  resourceId?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
  updatedAt?: Maybe<SortDirection>;
};

export type OwnerUpdateArg = {
  userId?: Maybe<Scalars['ID']>;
  resourceId?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
};

export type Pack = {
  _id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['ID']>;
  userId: Scalars['ID'];
  planCode: Scalars['String'];
  locationId?: Maybe<Scalars['ID']>;
  groupDevice?: Maybe<Scalars['String']>;
  maxOfDevice?: Maybe<Scalars['Float']>;
  calPrice?: Maybe<Scalars['Float']>;
  active: Scalars['Boolean'];
  expiredDate?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
  location?: Maybe<Location>;
};

export type PackBasePayload = {
  data?: Maybe<Pack>;
  error?: Maybe<StandardMutationError>;
};

export type PackFilter = {
  _ids?: Maybe<Array<Scalars['ID']>>;
  AND?: Maybe<PackFilter>;
  OR?: Maybe<PackFilter>;
  _search?: Maybe<Scalars['String']>;
  planCode?: Maybe<Scalars['String']>;
  _operators?: Maybe<PackFilterOperator>;
  locationId?: Maybe<Scalars['ID']>;
  groupDevice?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  _id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PackFilterOperator = {
  planCode?: Maybe<StringOperatorArgs>;
  locationId?: Maybe<IdOperatorArgs>;
  groupDevice?: Maybe<StringOperatorArgs>;
  _id?: Maybe<IdOperatorArgs>;
  createdAt?: Maybe<DateOperatorArgs>;
  updatedAt?: Maybe<DateOperatorArgs>;
};

export type PackInput = {
  userId: Scalars['ID'];
  planCode: Scalars['String'];
  locationId?: Maybe<Scalars['ID']>;
  groupDevice?: Maybe<Scalars['String']>;
  maxOfDevice?: Maybe<Scalars['Float']>;
  calPrice?: Maybe<Scalars['Float']>;
  active: Scalars['Boolean'];
  expiredDate?: Maybe<Scalars['DateTime']>;
};

export type PackSort = {
  planCode?: Maybe<SortDirection>;
  locationId?: Maybe<SortDirection>;
  groupDevice?: Maybe<SortDirection>;
  active?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
  updatedAt?: Maybe<SortDirection>;
};

export type PackUpdateArg = {
  userId?: Maybe<Scalars['ID']>;
  planCode?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['ID']>;
  groupDevice?: Maybe<Scalars['String']>;
  maxOfDevice?: Maybe<Scalars['Float']>;
  calPrice?: Maybe<Scalars['Float']>;
  active?: Maybe<Scalars['Boolean']>;
  expiredDate?: Maybe<Scalars['DateTime']>;
};

export type Payment = {
  _id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['ID']>;
  userId: Scalars['ID'];
  type?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  status: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  verifiedAt?: Maybe<Scalars['DateTime']>;
  verifiedBy?: Maybe<Scalars['ID']>;
  rejectedAt?: Maybe<Scalars['DateTime']>;
  rejectReason?: Maybe<Scalars['String']>;
  userPayment?: Maybe<User>;
  verifiedByUser?: Maybe<User>;
};

export type PaymentBasePayload = {
  data?: Maybe<Payment>;
  error?: Maybe<StandardMutationError>;
};

export type PaymentFilter = {
  _ids?: Maybe<Array<Scalars['ID']>>;
  AND?: Maybe<PaymentFilter>;
  OR?: Maybe<PaymentFilter>;
  _search?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
  _operators?: Maybe<PaymentFilterOperator>;
  type?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  verifiedAt?: Maybe<Scalars['DateTime']>;
  rejectedAt?: Maybe<Scalars['DateTime']>;
  rejectReason?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PaymentFilterOperator = {
  userId?: Maybe<IdOperatorArgs>;
  type?: Maybe<StringOperatorArgs>;
  amount?: Maybe<NumberOperatorArgs>;
  status?: Maybe<StringOperatorArgs>;
  note?: Maybe<StringOperatorArgs>;
  verifiedAt?: Maybe<DateOperatorArgs>;
  rejectedAt?: Maybe<DateOperatorArgs>;
  rejectReason?: Maybe<StringOperatorArgs>;
  _id?: Maybe<IdOperatorArgs>;
  createdAt?: Maybe<DateOperatorArgs>;
  updatedAt?: Maybe<DateOperatorArgs>;
};

export type PaymentInput = {
  userId: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  note?: Maybe<Scalars['String']>;
};

export type PaymentPayload = {
  data?: Maybe<Payment>;
  error?: Maybe<StandardMutationError>;
};

export type PaymentSort = {
  type?: Maybe<SortDirection>;
  amount?: Maybe<SortDirection>;
  status?: Maybe<SortDirection>;
  note?: Maybe<SortDirection>;
  verifiedAt?: Maybe<SortDirection>;
  rejectedAt?: Maybe<SortDirection>;
  rejectReason?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
  updatedAt?: Maybe<SortDirection>;
};

export type Plan = {
  _id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  planCode?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  price: Scalars['Float'];
  groupDeviceId: Scalars['String'];
  maxOfDevice: Scalars['Float'];
  status?: Maybe<Scalars['Boolean']>;
  groupDevice?: Maybe<Common>;
};

export type PlanBasePayload = {
  data?: Maybe<Plan>;
  error?: Maybe<StandardMutationError>;
};

export type PlanFilter = {
  _ids?: Maybe<Array<Scalars['ID']>>;
  AND?: Maybe<PlanFilter>;
  OR?: Maybe<PlanFilter>;
  _search?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  _operators?: Maybe<PlanFilterOperator>;
  planCode?: Maybe<Scalars['String']>;
  groupDeviceId?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PlanFilterOperator = {
  name?: Maybe<StringOperatorArgs>;
  planCode?: Maybe<StringOperatorArgs>;
  groupDeviceId?: Maybe<StringOperatorArgs>;
  _id?: Maybe<IdOperatorArgs>;
  createdAt?: Maybe<DateOperatorArgs>;
  updatedAt?: Maybe<DateOperatorArgs>;
};

export type PlanInput = {
  name: Scalars['String'];
  planCode?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  price: Scalars['Float'];
  groupDeviceId: Scalars['String'];
  maxOfDevice: Scalars['Float'];
  status?: Maybe<Scalars['Boolean']>;
};

export type PlanSort = {
  name?: Maybe<SortDirection>;
  planCode?: Maybe<SortDirection>;
  groupDeviceId?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
  updatedAt?: Maybe<SortDirection>;
};

export type PlanUpdateArg = {
  name?: Maybe<Scalars['String']>;
  planCode?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  groupDeviceId?: Maybe<Scalars['String']>;
  maxOfDevice?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Boolean']>;
};

export type Pricing = {
  _id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['ID']>;
  categoryId?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['ID']>;
  price: Scalars['Float'];
};

export type PricingFilter = {
  _ids?: Maybe<Array<Scalars['ID']>>;
  AND?: Maybe<PricingFilter>;
  OR?: Maybe<PricingFilter>;
  _search?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  _operators?: Maybe<PricingFilterOperator>;
  locationId?: Maybe<Scalars['ID']>;
  price?: Maybe<Scalars['Float']>;
  _id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PricingFilterOperator = {
  categoryId?: Maybe<StringOperatorArgs>;
  locationId?: Maybe<IdOperatorArgs>;
  price?: Maybe<NumberOperatorArgs>;
  _id?: Maybe<IdOperatorArgs>;
  createdAt?: Maybe<DateOperatorArgs>;
  updatedAt?: Maybe<DateOperatorArgs>;
};

export type PricingHistory = {
  _id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['ID']>;
  categoryId?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['ID']>;
  price: Scalars['Float'];
  creatorId?: Maybe<Scalars['ID']>;
  creator?: Maybe<User>;
};

export type PricingHistoryBasePayload = {
  data?: Maybe<PricingHistory>;
  error?: Maybe<StandardMutationError>;
};

export type PricingHistoryFilter = {
  _ids?: Maybe<Array<Scalars['ID']>>;
  AND?: Maybe<PricingHistoryFilter>;
  OR?: Maybe<PricingHistoryFilter>;
  _search?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  _operators?: Maybe<PricingHistoryFilterOperator>;
  locationId?: Maybe<Scalars['ID']>;
  price?: Maybe<Scalars['Float']>;
  _id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PricingHistoryFilterOperator = {
  categoryId?: Maybe<StringOperatorArgs>;
  locationId?: Maybe<IdOperatorArgs>;
  price?: Maybe<NumberOperatorArgs>;
  _id?: Maybe<IdOperatorArgs>;
  createdAt?: Maybe<DateOperatorArgs>;
  updatedAt?: Maybe<DateOperatorArgs>;
};

export type PricingHistoryInput = {
  categoryId?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['ID']>;
  price: Scalars['Float'];
  creatorId?: Maybe<Scalars['ID']>;
};

export type PricingHistorySort = {
  categoryId?: Maybe<SortDirection>;
  locationId?: Maybe<SortDirection>;
  price?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
  updatedAt?: Maybe<SortDirection>;
};

export type PricingInput = {
  categoryId?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['ID']>;
  price: Scalars['Float'];
};

export type PricingPayload = {
  data?: Maybe<Pricing>;
  error?: Maybe<StandardMutationError>;
};

export type PricingSort = {
  categoryId?: Maybe<SortDirection>;
  locationId?: Maybe<SortDirection>;
  price?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
  updatedAt?: Maybe<SortDirection>;
};

export type Profile = {
  _id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['ID']>;
  profileId?: Maybe<Scalars['String']>;
  display: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  roleIds?: Maybe<Array<Scalars['String']>>;
  menuRoleIds?: Maybe<Array<Scalars['String']>>;
  roleMapping?: Maybe<Scalars['JSONObject']>;
  roles: Array<Role>;
};

export type ProfileBasePayload = {
  data?: Maybe<Profile>;
  error?: Maybe<StandardMutationError>;
};

export type ProfileFilter = {
  _ids?: Maybe<Array<Scalars['ID']>>;
  AND?: Maybe<ProfileFilter>;
  OR?: Maybe<ProfileFilter>;
  _search?: Maybe<Scalars['String']>;
  display?: Maybe<Scalars['String']>;
  _operators?: Maybe<ProfileFilterOperator>;
  _id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ProfileFilterOperator = {
  display?: Maybe<StringOperatorArgs>;
  _id?: Maybe<IdOperatorArgs>;
  createdAt?: Maybe<DateOperatorArgs>;
  updatedAt?: Maybe<DateOperatorArgs>;
};

export type ProfileInput = {
  profileId?: Maybe<Scalars['String']>;
  display: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  roleIds?: Maybe<Array<Scalars['String']>>;
  menuRoleIds?: Maybe<Array<Scalars['String']>>;
  roleMapping?: Maybe<Scalars['JSONObject']>;
};

export type ProfileSort = {
  display?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
  updatedAt?: Maybe<SortDirection>;
};

export type ProfileUpdateArg = {
  profileId?: Maybe<Scalars['String']>;
  display?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  roleIds?: Maybe<Array<Scalars['String']>>;
  menuRoleIds?: Maybe<Array<Scalars['String']>>;
  roleMapping?: Maybe<Scalars['JSONObject']>;
};

export type Query = {
  findSettingById?: Maybe<Setting>;
  findOneSetting?: Maybe<Setting>;
  findManySetting: Array<Setting>;
  countSetting?: Maybe<Scalars['Int']>;
  CountTrafficData: DashboardAdmin;
  userBalanceStatus: Array<UserBalance>;
  findManyBalanceHistory: Array<BalanceHistory>;
  countBalanceHistory: Scalars['Int'];
  findBannerById?: Maybe<Banner>;
  findOneBanner?: Maybe<Banner>;
  findManyBanner: Array<Banner>;
  countBanner?: Maybe<Scalars['Int']>;
  findBannerTempById?: Maybe<BannerTemp>;
  findOneBannerTemp?: Maybe<BannerTemp>;
  findManyBannerTemp: Array<BannerTemp>;
  countBannerTemp?: Maybe<Scalars['Int']>;
  findBannerTemplateById?: Maybe<BannerTemplate>;
  findOneBannerTemplate?: Maybe<BannerTemplate>;
  findManyBannerTemplate: Array<BannerTemplate>;
  countBannerTemplate?: Maybe<Scalars['Int']>;
  getAvailableBooking: AvailableBooking;
  findCampaignMappingById?: Maybe<CampaignMapping>;
  findOneCampaignMapping?: Maybe<CampaignMapping>;
  findManyCampaignMapping: Array<CampaignMapping>;
  countCampaignMapping?: Maybe<Scalars['Int']>;
  getCampaignByLocationId: Array<CampaignIdByLocation>;
  findCommonLocations: Array<Result>;
  fetchCampaignMapping: Array<CampaignMapping>;
  fetchLocationMapping: Array<CampaignMapping>;
  fetchLocationCampaignMapping: Array<LocationCampaignMapping>;
  findCampaignById?: Maybe<Campaign>;
  findOneCampaign?: Maybe<Campaign>;
  findManyCampaign: Array<Campaign>;
  countCampaign?: Maybe<Scalars['Int']>;
  getTotalCampaign: TotalCampagin;
  calculateCampaignOnlineKpi: CalculateBookingOnlineKpiPayload;
  sendMailtoTheCustomer: Scalars['Boolean'];
  findCommonById?: Maybe<Common>;
  findOneCommon?: Maybe<Common>;
  findManyCommon: Array<Common>;
  countCommon?: Maybe<Scalars['Int']>;
  findCommonTypes: Array<Scalars['String']>;
  findCustomerById?: Maybe<Customer>;
  findOneCustomer?: Maybe<Customer>;
  findManyCustomer: Array<Customer>;
  countCustomer?: Maybe<Scalars['Int']>;
  findCustomerTypeById?: Maybe<CustomerType>;
  findOneCustomerType?: Maybe<CustomerType>;
  findManyCustomerType: Array<CustomerType>;
  countCustomerType?: Maybe<Scalars['Int']>;
  getDataAudience: AudienceDataReturn;
  getDataLogin: LoginData;
  getDataVisit: ReportVisit;
  getDataOSDevice: ReportDevice;
  getReportTodayLogin: Array<ReportTodayLogin>;
  getReportOnlineSession: Array<ReportOnlineSession>;
  countReportOnlineSession: Scalars['Float'];
  getDataTrafficOverview: Data;
  getTrafficSummary: TrafficSummaryData;
  getTotalUsers: Scalars['Int'];
  getReportLogin: ReportLogin;
  findDeviceDataByLocation: DeviceDataPayload;
  loadLocationStats: LocationStatsType;
  getTotalTraffic: TotalTraffic;
  getOnlineSession: OnlineSession;
  getReportDeviceOSAndDeviceType: ReportDevice;
  getTracking: ReportTracking;
  getDetail: Array<DetailResult>;
  countDetail: Scalars['Float'];
  getLocationClick3Month: ReportTrackingPayload;
  getTrackingData3Month: TrackingPayload;
  getTrackingByLocation: TrackingByLocation;
  getSurveyCampaign: SurveyCampaignPayload;
  getTrackingSurvey: SurveyCampaignPayload;
  getReportVisit: ReportVisit;
  findEquipmentById?: Maybe<Equipment>;
  findOneEquipment?: Maybe<Equipment>;
  findManyEquipment: Array<Equipment>;
  countEquipment?: Maybe<Scalars['Int']>;
  findFileById: FilePayload;
  findImageById?: Maybe<Image>;
  findOneImage?: Maybe<Image>;
  findManyImage: Array<Image>;
  countImage?: Maybe<Scalars['Int']>;
  findLocationById?: Maybe<Location>;
  findOneLocation?: Maybe<Location>;
  findManyLocation: Array<Location>;
  countLocation?: Maybe<Scalars['Int']>;
  getTotalLocationActive: Array<LocationCount>;
  getTotalStatusLocation: ColumnChartLocation;
  getLocationAds: Scalars['Int'];
  getCategories: Array<Common>;
  getRenderCode: Scalars['String'];
  getActiveCampaigns: Array<LocationActiveCampaigns>;
  findMessageById?: Maybe<Message>;
  findOneMessage?: Maybe<Message>;
  findManyMessage: Array<Message>;
  countMessage?: Maybe<Scalars['Int']>;
  findNotificationById?: Maybe<Notification>;
  findOneNotification?: Maybe<Notification>;
  findManyNotification: Array<Notification>;
  countNotification?: Maybe<Scalars['Int']>;
  findOrderById?: Maybe<Order>;
  findOneOrder?: Maybe<Order>;
  findManyOrder: Array<Order>;
  countOrder?: Maybe<Scalars['Int']>;
  findOwnerById?: Maybe<Owner>;
  findOneOwner?: Maybe<Owner>;
  findManyOwner: Array<Owner>;
  countOwner?: Maybe<Scalars['Int']>;
  findPackById?: Maybe<Pack>;
  findOnePack?: Maybe<Pack>;
  findManyPack: Array<Pack>;
  countPack?: Maybe<Scalars['Int']>;
  findPaymentById?: Maybe<Payment>;
  findOnePayment?: Maybe<Payment>;
  findManyPayment: Array<Payment>;
  countPayment?: Maybe<Scalars['Int']>;
  findManyPaymentForUser: Array<Payment>;
  countPaymentForUser: Scalars['Int'];
  findPlanById?: Maybe<Plan>;
  findOnePlan?: Maybe<Plan>;
  findManyPlan: Array<Plan>;
  countPlan?: Maybe<Scalars['Int']>;
  findPricingById?: Maybe<Pricing>;
  findOnePricing?: Maybe<Pricing>;
  findManyPricing: Array<Pricing>;
  countPricing?: Maybe<Scalars['Int']>;
  findLocationPrice: Array<LocationPrice>;
  findCategoryPrice: Array<CategoryPrice>;
  findPricingHistoryById?: Maybe<PricingHistory>;
  findOnePricingHistory?: Maybe<PricingHistory>;
  findManyPricingHistory: Array<PricingHistory>;
  countPricingHistory?: Maybe<Scalars['Int']>;
  findProfileById?: Maybe<Profile>;
  findOneProfile?: Maybe<Profile>;
  findManyProfile: Array<Profile>;
  countProfile?: Maybe<Scalars['Int']>;
  findRegionById?: Maybe<Region>;
  findOneRegion?: Maybe<Region>;
  findManyRegion: Array<Region>;
  countRegion?: Maybe<Scalars['Int']>;
  findDistrict: Array<Region>;
  findCity: Array<Region>;
  findArea: Array<Region>;
  getReportCampaignStatistic: ReportCampaignByDatePayload;
  getReportCampaignSurvey: ReportCampaignSurveyPayload;
  getReportCampaignOsDevice: ReportCampaignOsDevicePayload;
  getReportCampaignInfo: ReportCampaignInfoPayload;
  getReportTrackingDetail: ReportTrackingDetailPayload;
  getReportCampaignPartner: ReportCampaignByDatePayload;
  getReportIncomeByCampaign: ReportIncomeResult;
  getReportIncomeByLocation: ReportIncomeResult;
  getReportIncomePartner: ReportIncomeResult;
  getReportLocationData: DataReportLocation;
  getReportLocationUpDown: DataReportLocation;
  countReportLocationData: Scalars['Float'];
  alertLocation: DataReportLocation;
  getReportUserReturn: DataReportUserReturn;
  getReportSharedTrafficDetails: Array<ReportSharedTrafficResult>;
  getReportCampaignRatio: Array<ReportCampaignRatioResult>;
  findRevenueById?: Maybe<Revenue>;
  findOneRevenue?: Maybe<Revenue>;
  findManyRevenue: Array<Revenue>;
  countRevenue?: Maybe<Scalars['Int']>;
  findRoleById?: Maybe<Role>;
  findOneRole?: Maybe<Role>;
  findManyRole: Array<Role>;
  countRole?: Maybe<Scalars['Int']>;
  findTwoFactorAuthById?: Maybe<TwoFactorAuth>;
  findOneTwoFactorAuth?: Maybe<TwoFactorAuth>;
  findManyTwoFactorAuth: Array<TwoFactorAuth>;
  countTwoFactorAuth?: Maybe<Scalars['Int']>;
  getTwoFactorAuthCode: Scalars['String'];
  verifyTwoFactorAuthenticationCode: Scalars['Boolean'];
  findUserById?: Maybe<User>;
  findOneUser?: Maybe<User>;
  findManyUser: Array<User>;
  countUser?: Maybe<Scalars['Int']>;
  me: UserPayload;
  findManyUserWithOwner: Array<UserOwnerPayload>;
  countManyUserWithOwner: Scalars['Int'];
  checkRecaptcha: Scalars['Boolean'];
  getTotalUserLicenses?: Maybe<TotalUser>;
  findUserGuideById?: Maybe<UserGuide>;
  findOneUserGuide?: Maybe<UserGuide>;
  findManyUserGuide: Array<UserGuide>;
  countUserGuide?: Maybe<Scalars['Int']>;
  sale_findManyLocation: Array<Location>;
  sale_findManyCategory: Array<LocationCategory>;
  sale_findManyCity: Array<LocationsCommon>;
  sale_findManyDistrict: Array<LocationsCommon>;
  getHotspotOnOff: HotspotOnOff;
  findLogsById?: Maybe<Logs>;
  findOneLogs?: Maybe<Logs>;
  findManyLogs: Array<Logs>;
  countLogs?: Maybe<Scalars['Int']>;
  findFullLogs: Array<Logs>;
  countFullLogs: Scalars['Int'];
};


export type QueryFindSettingByIdArgs = {
  _id: Scalars['String'];
};


export type QueryFindOneSettingArgs = {
  where?: Maybe<SettingFilter>;
  sort?: Maybe<SettingSort>;
};


export type QueryFindManySettingArgs = {
  where?: Maybe<SettingFilter>;
  sort?: Maybe<SettingSort>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryCountSettingArgs = {
  where?: Maybe<SettingFilter>;
};


export type QueryCountTrafficDataArgs = {
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
};


export type QueryFindManyBalanceHistoryArgs = {
  where?: Maybe<BalanceHistoryFilter>;
  sort?: Maybe<BalanceHistorySort>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryCountBalanceHistoryArgs = {
  where?: Maybe<BalanceHistoryFilter>;
};


export type QueryFindBannerByIdArgs = {
  _id: Scalars['String'];
};


export type QueryFindOneBannerArgs = {
  where?: Maybe<BannerFilter>;
  sort?: Maybe<BannerSort>;
};


export type QueryFindManyBannerArgs = {
  where?: Maybe<BannerFilter>;
  sort?: Maybe<BannerSort>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryCountBannerArgs = {
  where?: Maybe<BannerFilter>;
};


export type QueryFindBannerTempByIdArgs = {
  _id: Scalars['String'];
};


export type QueryFindOneBannerTempArgs = {
  where?: Maybe<BannerTempFilter>;
  sort?: Maybe<BannerTempSort>;
};


export type QueryFindManyBannerTempArgs = {
  where?: Maybe<BannerTempFilter>;
  sort?: Maybe<BannerTempSort>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryCountBannerTempArgs = {
  where?: Maybe<BannerTempFilter>;
};


export type QueryFindBannerTemplateByIdArgs = {
  _id: Scalars['String'];
};


export type QueryFindOneBannerTemplateArgs = {
  where?: Maybe<BannerTemplateFilter>;
  sort?: Maybe<BannerTemplateSort>;
};


export type QueryFindManyBannerTemplateArgs = {
  where?: Maybe<BannerTemplateFilter>;
  sort?: Maybe<BannerTemplateSort>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryCountBannerTemplateArgs = {
  where?: Maybe<BannerTemplateFilter>;
};


export type QueryGetAvailableBookingArgs = {
  noToken?: Maybe<Scalars['Boolean']>;
  locationIds?: Maybe<Array<Scalars['String']>>;
  categoryIds?: Maybe<Array<Scalars['String']>>;
  date: DateFilterInput;
  interval?: Maybe<Scalars['String']>;
  detail?: Maybe<Scalars['Boolean']>;
};


export type QueryFindCampaignMappingByIdArgs = {
  _id: Scalars['String'];
};


export type QueryFindOneCampaignMappingArgs = {
  where?: Maybe<CampaignMappingFilter>;
  sort?: Maybe<CampaignMappingSort>;
};


export type QueryFindManyCampaignMappingArgs = {
  where?: Maybe<CampaignMappingFilter>;
  sort?: Maybe<CampaignMappingSort>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryCountCampaignMappingArgs = {
  where?: Maybe<CampaignMappingFilter>;
};


export type QueryGetCampaignByLocationIdArgs = {
  locationId: Scalars['String'];
};


export type QueryFindCommonLocationsArgs = {
  campaign: Array<Scalars['String']>;
  locations?: Maybe<Array<ResultInput>>;
};


export type QueryFetchCampaignMappingArgs = {
  campaignId: Scalars['String'];
};


export type QueryFetchLocationMappingArgs = {
  locationId: Scalars['String'];
};


export type QueryFetchLocationCampaignMappingArgs = {
  locationId: Array<Scalars['String']>;
};


export type QueryFindCampaignByIdArgs = {
  _id: Scalars['String'];
};


export type QueryFindOneCampaignArgs = {
  where?: Maybe<CampaignFilter>;
  sort?: Maybe<CampaignSort>;
};


export type QueryFindManyCampaignArgs = {
  where?: Maybe<CampaignFilter>;
  sort?: Maybe<CampaignSort>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryCountCampaignArgs = {
  where?: Maybe<CampaignFilter>;
};


export type QueryCalculateCampaignOnlineKpiArgs = {
  args: CalculateBookingOnlineKpiArgs;
};


export type QuerySendMailtoTheCustomerArgs = {
  data: DataInput;
  email: Scalars['String'];
};


export type QueryFindCommonByIdArgs = {
  _id: Scalars['String'];
};


export type QueryFindOneCommonArgs = {
  where?: Maybe<CommonFilter>;
  sort?: Maybe<CommonSort>;
};


export type QueryFindManyCommonArgs = {
  where?: Maybe<CommonFilter>;
  sort?: Maybe<CommonSort>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryCountCommonArgs = {
  where?: Maybe<CommonFilter>;
};


export type QueryFindCustomerByIdArgs = {
  _id: Scalars['String'];
};


export type QueryFindOneCustomerArgs = {
  where?: Maybe<CustomerFilter>;
  sort?: Maybe<CustomerSort>;
};


export type QueryFindManyCustomerArgs = {
  where?: Maybe<CustomerFilter>;
  sort?: Maybe<CustomerSort>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryCountCustomerArgs = {
  where?: Maybe<CustomerFilter>;
};


export type QueryFindCustomerTypeByIdArgs = {
  _id: Scalars['String'];
};


export type QueryFindOneCustomerTypeArgs = {
  where?: Maybe<CustomerTypeFilter>;
  sort?: Maybe<CustomerTypeSort>;
};


export type QueryFindManyCustomerTypeArgs = {
  where?: Maybe<CustomerTypeFilter>;
  sort?: Maybe<CustomerTypeSort>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryCountCustomerTypeArgs = {
  where?: Maybe<CustomerTypeFilter>;
};


export type QueryGetDataLoginArgs = {
  locationId?: Maybe<Scalars['String']>;
};


export type QueryGetDataVisitArgs = {
  locationId?: Maybe<Scalars['String']>;
};


export type QueryGetDataOsDeviceArgs = {
  dateFilter: DateArgs;
  locationId?: Maybe<Scalars['String']>;
};


export type QueryGetReportOnlineSessionArgs = {
  limit: Scalars['Float'];
  skip: Scalars['Float'];
};


export type QueryGetTrafficSummaryArgs = {
  gte?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
};


export type QueryGetTotalUsersArgs = {
  locationId?: Maybe<Scalars['String']>;
};


export type QueryGetReportLoginArgs = {
  locationId?: Maybe<Scalars['String']>;
};


export type QueryFindDeviceDataByLocationArgs = {
  categoryId?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
};


export type QueryGetTotalTrafficArgs = {
  locationId?: Maybe<Scalars['String']>;
};


export type QueryGetOnlineSessionArgs = {
  locationId?: Maybe<Scalars['String']>;
};


export type QueryGetReportDeviceOsAndDeviceTypeArgs = {
  dateFilter: DateFilter;
  locationId?: Maybe<Scalars['String']>;
};


export type QueryGetTrackingArgs = {
  view: View;
  campaignId?: Maybe<Scalars['String']>;
  locationId?: Maybe<Array<Scalars['String']>>;
  bannerId?: Maybe<Scalars['String']>;
  dateFrom: Scalars['DateTime'];
  dateTo?: Maybe<Scalars['DateTime']>;
  tab?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
};


export type QueryGetDetailArgs = {
  rowGroupCols?: Maybe<Array<ColumnVo>>;
  valueCols?: Maybe<Array<ColumnVo>>;
  groupKeys?: Maybe<Array<Scalars['String']>>;
  skip?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  where?: Maybe<GetDetailLocationWhere>;
};


export type QueryCountDetailArgs = {
  rowGroupCols?: Maybe<Array<ColumnVo>>;
  valueCols?: Maybe<Array<ColumnVo>>;
  groupKeys?: Maybe<Array<Scalars['String']>>;
  skip?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  where?: Maybe<GetDetailLocationWhere>;
};


export type QueryGetLocationClick3MonthArgs = {
  locationId: Scalars['String'];
};


export type QueryGetTrackingByLocationArgs = {
  id?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
};


export type QueryGetSurveyCampaignArgs = {
  params: SurveyFilterInput;
};


export type QueryGetTrackingSurveyArgs = {
  params: SurveyFilterInput;
};


export type QueryGetReportVisitArgs = {
  locationId?: Maybe<Scalars['String']>;
};


export type QueryFindEquipmentByIdArgs = {
  _id: Scalars['String'];
};


export type QueryFindOneEquipmentArgs = {
  where?: Maybe<EquipmentFilter>;
  sort?: Maybe<EquipmentSort>;
};


export type QueryFindManyEquipmentArgs = {
  where?: Maybe<EquipmentFilter>;
  sort?: Maybe<EquipmentSort>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryCountEquipmentArgs = {
  where?: Maybe<EquipmentFilter>;
};


export type QueryFindFileByIdArgs = {
  id: Scalars['String'];
};


export type QueryFindImageByIdArgs = {
  _id: Scalars['String'];
};


export type QueryFindOneImageArgs = {
  where?: Maybe<ImageFilter>;
  sort?: Maybe<ImageSort>;
};


export type QueryFindManyImageArgs = {
  where?: Maybe<ImageFilter>;
  sort?: Maybe<ImageSort>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryCountImageArgs = {
  where?: Maybe<ImageFilter>;
};


export type QueryFindLocationByIdArgs = {
  _id: Scalars['String'];
};


export type QueryFindOneLocationArgs = {
  where?: Maybe<LocationFilter>;
  sort?: Maybe<LocationSort>;
};


export type QueryFindManyLocationArgs = {
  where?: Maybe<LocationFilter>;
  sort?: Maybe<LocationSort>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryCountLocationArgs = {
  where?: Maybe<LocationFilter>;
};


export type QueryGetTotalLocationActiveArgs = {
  ownerType: Scalars['String'];
};


export type QueryGetTotalStatusLocationArgs = {
  ownerType: Scalars['String'];
};


export type QueryGetLocationAdsArgs = {
  ownerType: Scalars['String'];
};


export type QueryGetRenderCodeArgs = {
  locationId?: Maybe<Scalars['String']>;
  equipmentId?: Maybe<Scalars['String']>;
  bannerId?: Maybe<Scalars['String']>;
  isExternal?: Maybe<Scalars['String']>;
  macFieldName?: Maybe<Scalars['String']>;
};


export type QueryGetActiveCampaignsArgs = {
  where?: Maybe<LocationFilter>;
  sort?: Maybe<LocationSort>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryFindMessageByIdArgs = {
  _id: Scalars['String'];
};


export type QueryFindOneMessageArgs = {
  where?: Maybe<MessageFilter>;
  sort?: Maybe<MessageSort>;
};


export type QueryFindManyMessageArgs = {
  where?: Maybe<MessageFilter>;
  sort?: Maybe<MessageSort>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryCountMessageArgs = {
  where?: Maybe<MessageFilter>;
};


export type QueryFindNotificationByIdArgs = {
  _id: Scalars['String'];
};


export type QueryFindOneNotificationArgs = {
  where?: Maybe<NotificationFilter>;
  sort?: Maybe<NotificationSort>;
};


export type QueryFindManyNotificationArgs = {
  where?: Maybe<NotificationFilter>;
  sort?: Maybe<NotificationSort>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryCountNotificationArgs = {
  where?: Maybe<NotificationFilter>;
};


export type QueryFindOrderByIdArgs = {
  _id: Scalars['String'];
};


export type QueryFindOneOrderArgs = {
  where?: Maybe<OrderFilter>;
  sort?: Maybe<OrderSort>;
};


export type QueryFindManyOrderArgs = {
  where?: Maybe<OrderFilter>;
  sort?: Maybe<OrderSort>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryCountOrderArgs = {
  where?: Maybe<OrderFilter>;
};


export type QueryFindOwnerByIdArgs = {
  _id: Scalars['String'];
};


export type QueryFindOneOwnerArgs = {
  where?: Maybe<OwnerFilter>;
  sort?: Maybe<OwnerSort>;
};


export type QueryFindManyOwnerArgs = {
  where?: Maybe<OwnerFilter>;
  sort?: Maybe<OwnerSort>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryCountOwnerArgs = {
  where?: Maybe<OwnerFilter>;
};


export type QueryFindPackByIdArgs = {
  _id: Scalars['String'];
};


export type QueryFindOnePackArgs = {
  where?: Maybe<PackFilter>;
  sort?: Maybe<PackSort>;
};


export type QueryFindManyPackArgs = {
  where?: Maybe<PackFilter>;
  sort?: Maybe<PackSort>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryCountPackArgs = {
  where?: Maybe<PackFilter>;
};


export type QueryFindPaymentByIdArgs = {
  _id: Scalars['String'];
};


export type QueryFindOnePaymentArgs = {
  where?: Maybe<PaymentFilter>;
  sort?: Maybe<PaymentSort>;
};


export type QueryFindManyPaymentArgs = {
  where?: Maybe<PaymentFilter>;
  sort?: Maybe<PaymentSort>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryCountPaymentArgs = {
  where?: Maybe<PaymentFilter>;
};


export type QueryFindManyPaymentForUserArgs = {
  where?: Maybe<PaymentFilter>;
  sort?: Maybe<PaymentSort>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryCountPaymentForUserArgs = {
  where?: Maybe<PaymentFilter>;
  sort?: Maybe<PaymentSort>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryFindPlanByIdArgs = {
  _id: Scalars['String'];
};


export type QueryFindOnePlanArgs = {
  where?: Maybe<PlanFilter>;
  sort?: Maybe<PlanSort>;
};


export type QueryFindManyPlanArgs = {
  where?: Maybe<PlanFilter>;
  sort?: Maybe<PlanSort>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryCountPlanArgs = {
  where?: Maybe<PlanFilter>;
};


export type QueryFindPricingByIdArgs = {
  _id: Scalars['String'];
};


export type QueryFindOnePricingArgs = {
  where?: Maybe<PricingFilter>;
  sort?: Maybe<PricingSort>;
};


export type QueryFindManyPricingArgs = {
  where?: Maybe<PricingFilter>;
  sort?: Maybe<PricingSort>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryCountPricingArgs = {
  where?: Maybe<PricingFilter>;
};


export type QueryFindLocationPriceArgs = {
  where?: Maybe<LocationFilter>;
  sort?: Maybe<LocationSort>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryFindPricingHistoryByIdArgs = {
  _id: Scalars['String'];
};


export type QueryFindOnePricingHistoryArgs = {
  where?: Maybe<PricingHistoryFilter>;
  sort?: Maybe<PricingHistorySort>;
};


export type QueryFindManyPricingHistoryArgs = {
  where?: Maybe<PricingHistoryFilter>;
  sort?: Maybe<PricingHistorySort>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryCountPricingHistoryArgs = {
  where?: Maybe<PricingHistoryFilter>;
};


export type QueryFindProfileByIdArgs = {
  _id: Scalars['String'];
};


export type QueryFindOneProfileArgs = {
  where?: Maybe<ProfileFilter>;
  sort?: Maybe<ProfileSort>;
};


export type QueryFindManyProfileArgs = {
  where?: Maybe<ProfileFilter>;
  sort?: Maybe<ProfileSort>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryCountProfileArgs = {
  where?: Maybe<ProfileFilter>;
};


export type QueryFindRegionByIdArgs = {
  _id: Scalars['String'];
};


export type QueryFindOneRegionArgs = {
  where?: Maybe<RegionFilter>;
  sort?: Maybe<RegionSort>;
};


export type QueryFindManyRegionArgs = {
  where?: Maybe<RegionFilter>;
  sort?: Maybe<RegionSort>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryCountRegionArgs = {
  where?: Maybe<RegionFilter>;
};


export type QueryGetReportCampaignStatisticArgs = {
  campaignId: Scalars['String'];
  date: DateArgs;
  locationId?: Maybe<Array<Scalars['String']>>;
  bannerId?: Maybe<Array<Scalars['String']>>;
  categoryId?: Maybe<Array<Scalars['String']>>;
  interval?: Maybe<Interval>;
};


export type QueryGetReportCampaignSurveyArgs = {
  campaignId: Scalars['String'];
  filter?: Maybe<CampaignSurveyFilter>;
  skip?: Maybe<Scalars['Float']>;
};


export type QueryGetReportCampaignOsDeviceArgs = {
  campaignId: Scalars['String'];
  filter?: Maybe<CampaignSurveyFilter>;
  skip?: Maybe<Scalars['Float']>;
};


export type QueryGetReportCampaignInfoArgs = {
  campaignId: Scalars['String'];
};


export type QueryGetReportTrackingDetailArgs = {
  externalFilter?: Maybe<TrackingDetailExternalFilter>;
  internalFilter?: Maybe<TrackingDetailInternalFilter>;
  date: DateArgs;
};


export type QueryGetReportCampaignPartnerArgs = {
  campaignId: Array<Scalars['String']>;
  locationId: Array<Scalars['String']>;
  date: DateArgs;
  interval?: Maybe<Interval>;
};


export type QueryGetReportIncomeByCampaignArgs = {
  filter?: Maybe<IncomeFilter>;
};


export type QueryGetReportIncomeByLocationArgs = {
  filter?: Maybe<IncomeFilter>;
};


export type QueryGetReportIncomePartnerArgs = {
  filter?: Maybe<IncomeFilter>;
};


export type QueryGetReportLocationDataArgs = {
  where?: Maybe<RpLocationWhereFilterInput>;
  dataType?: Maybe<DataType>;
  skip?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  amongTime?: Maybe<Scalars['Float']>;
};


export type QueryGetReportLocationUpDownArgs = {
  where?: Maybe<RpLocationWhereFilterInput>;
  dataType?: Maybe<DataType>;
  skip?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  amongTime?: Maybe<Scalars['Float']>;
};


export type QueryCountReportLocationDataArgs = {
  where?: Maybe<RpLocationWhereFilterInput>;
  dataType?: Maybe<DataType>;
  skip?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  amongTime?: Maybe<Scalars['Float']>;
};


export type QueryAlertLocationArgs = {
  numberCheck?: Maybe<Scalars['Int']>;
  dateCheck?: Maybe<Scalars['Int']>;
};


export type QueryGetReportUserReturnArgs = {
  where?: Maybe<RpLocationWhereFilterInput>;
  dataType?: Maybe<DataType>;
  skip?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  amongTime?: Maybe<Scalars['Float']>;
};


export type QueryGetReportSharedTrafficDetailsArgs = {
  filter?: Maybe<SharedTrafficFilter>;
  skip?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
};


export type QueryGetReportCampaignRatioArgs = {
  filter?: Maybe<CampaignRatioFilter>;
  skip?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
};


export type QueryFindRevenueByIdArgs = {
  _id: Scalars['String'];
};


export type QueryFindOneRevenueArgs = {
  where?: Maybe<RevenueFilter>;
  sort?: Maybe<RevenueSort>;
};


export type QueryFindManyRevenueArgs = {
  where?: Maybe<RevenueFilter>;
  sort?: Maybe<RevenueSort>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryCountRevenueArgs = {
  where?: Maybe<RevenueFilter>;
};


export type QueryFindRoleByIdArgs = {
  _id: Scalars['String'];
};


export type QueryFindOneRoleArgs = {
  where?: Maybe<RoleFilter>;
  sort?: Maybe<RoleSort>;
};


export type QueryFindManyRoleArgs = {
  where?: Maybe<RoleFilter>;
  sort?: Maybe<RoleSort>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryCountRoleArgs = {
  where?: Maybe<RoleFilter>;
};


export type QueryFindTwoFactorAuthByIdArgs = {
  _id: Scalars['String'];
};


export type QueryFindOneTwoFactorAuthArgs = {
  where?: Maybe<TwoFactorAuthFilter>;
  sort?: Maybe<TwoFactorAuthSort>;
};


export type QueryFindManyTwoFactorAuthArgs = {
  where?: Maybe<TwoFactorAuthFilter>;
  sort?: Maybe<TwoFactorAuthSort>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryCountTwoFactorAuthArgs = {
  where?: Maybe<TwoFactorAuthFilter>;
};


export type QueryVerifyTwoFactorAuthenticationCodeArgs = {
  code: Scalars['String'];
};


export type QueryFindUserByIdArgs = {
  _id: Scalars['String'];
};


export type QueryFindOneUserArgs = {
  where?: Maybe<UserFilter>;
  sort?: Maybe<UserSort>;
};


export type QueryFindManyUserArgs = {
  where?: Maybe<UserFilter>;
  sort?: Maybe<UserSort>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryCountUserArgs = {
  where?: Maybe<UserFilter>;
};


export type QueryFindManyUserWithOwnerArgs = {
  skip?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  where?: Maybe<UserOwnerWhereArgs>;
};


export type QueryCountManyUserWithOwnerArgs = {
  skip?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  where?: Maybe<UserOwnerWhereArgs>;
};


export type QueryCheckRecaptchaArgs = {
  value: Scalars['String'];
};


export type QueryFindUserGuideByIdArgs = {
  _id: Scalars['String'];
};


export type QueryFindOneUserGuideArgs = {
  where?: Maybe<UserGuideFilter>;
  sort?: Maybe<UserGuideSort>;
};


export type QueryFindManyUserGuideArgs = {
  where?: Maybe<UserGuideFilter>;
  sort?: Maybe<UserGuideSort>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryCountUserGuideArgs = {
  where?: Maybe<UserGuideFilter>;
};


export type QuerySale_FindManyLocationArgs = {
  where?: Maybe<LocationSaleFilter>;
  sort?: Maybe<LocationSaleSort>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryFindLogsByIdArgs = {
  _id: Scalars['String'];
};


export type QueryFindOneLogsArgs = {
  where?: Maybe<LogsFilter>;
  sort?: Maybe<LogsSort>;
};


export type QueryFindManyLogsArgs = {
  where?: Maybe<LogsFilter>;
  sort?: Maybe<LogsSort>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryCountLogsArgs = {
  where?: Maybe<LogsFilter>;
};


export type QueryFindFullLogsArgs = {
  where?: Maybe<LogsFilter>;
  sort?: Maybe<LogsSort>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryCountFullLogsArgs = {
  where?: Maybe<LogsFilter>;
  sort?: Maybe<LogsSort>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryBuilder = {
  query?: Maybe<Scalars['String']>;
  queryTree?: Maybe<Scalars['JSONObject']>;
};

export type QueryBuilderInput = {
  query?: Maybe<Scalars['String']>;
  queryTree?: Maybe<Scalars['JSONObject']>;
};

export type Region = {
  _id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['ID']>;
  type: Scalars['String'];
  value: Scalars['String'];
  text: Scalars['String'];
  area?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
};

export type RegionBasePayload = {
  data?: Maybe<Region>;
  error?: Maybe<StandardMutationError>;
};

export type RegionFilter = {
  _ids?: Maybe<Array<Scalars['ID']>>;
  AND?: Maybe<RegionFilter>;
  OR?: Maybe<RegionFilter>;
  _search?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  _operators?: Maybe<RegionFilterOperator>;
  value?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  area?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RegionFilterOperator = {
  type?: Maybe<StringOperatorArgs>;
  value?: Maybe<StringOperatorArgs>;
  text?: Maybe<StringOperatorArgs>;
  area?: Maybe<StringOperatorArgs>;
  city?: Maybe<StringOperatorArgs>;
  _id?: Maybe<IdOperatorArgs>;
  createdAt?: Maybe<DateOperatorArgs>;
  updatedAt?: Maybe<DateOperatorArgs>;
};

export type RegionInput = {
  type: Scalars['String'];
  value: Scalars['String'];
  text: Scalars['String'];
  area?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
};

export type RegionSort = {
  type?: Maybe<SortDirection>;
  value?: Maybe<SortDirection>;
  text?: Maybe<SortDirection>;
  area?: Maybe<SortDirection>;
  city?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
  updatedAt?: Maybe<SortDirection>;
};

export type RegionUpdateArg = {
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  area?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
};

export type ReportCampaignByDate = {
  click?: Maybe<Scalars['Float']>;
  impression?: Maybe<Scalars['Float']>;
  ctr?: Maybe<Scalars['Float']>;
  date?: Maybe<Scalars['DateTime']>;
};

export type ReportCampaignByDatePayload = {
  total?: Maybe<ReportCampaignTotal>;
  byDate?: Maybe<Array<ReportCampaignByDate>>;
  error?: Maybe<StandardMutationError>;
};

export type ReportCampaignInfo = {
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  stats?: Maybe<Statistic>;
  scheduleTo?: Maybe<Scalars['DateTime']>;
  scheduleFrom?: Maybe<Scalars['DateTime']>;
  locations?: Maybe<Array<CampaignLocation>>;
  todayClick?: Maybe<Scalars['Float']>;
  config?: Maybe<CampaignConfig>;
  customs?: Maybe<Scalars['JSON']>;
};

export type ReportCampaignInfoPayload = {
  error?: Maybe<StandardMutationError>;
  data?: Maybe<ReportCampaignInfo>;
};

export type ReportCampaignOsDevicePayload = {
  os?: Maybe<OsCount>;
  device?: Maybe<DeviceTypeCount>;
  deviceName?: Maybe<Scalars['JSON']>;
  brandName?: Maybe<Scalars['JSON']>;
  error?: Maybe<StandardMutationError>;
};

export type ReportCampaignRatioDetail = {
  date: Scalars['String'];
  click: Scalars['Float'];
  ratio: Scalars['Float'];
};

export type ReportCampaignRatioResult = {
  type: Scalars['String'];
  click: Scalars['Float'];
  ratio: Scalars['Float'];
  detail: Array<ReportCampaignRatioDetail>;
};

export type ReportCampaignSurvey = {
  createdAt?: Maybe<Scalars['DateTime']>;
  meta?: Maybe<CampaignMeta>;
  ua?: Maybe<Ua>;
  uaString?: Maybe<Scalars['String']>;
  mac?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  survey?: Maybe<Scalars['JSON']>;
  audience?: Maybe<Scalars['JSON']>;
};

export type ReportCampaignSurveyPayload = {
  data?: Maybe<Array<ReportCampaignSurvey>>;
  total?: Maybe<Scalars['Float']>;
  error?: Maybe<StandardMutationError>;
};

export type ReportCampaignTotal = {
  click?: Maybe<Scalars['Float']>;
  impression?: Maybe<Scalars['Float']>;
  ctr?: Maybe<Scalars['Float']>;
};

export type ReportDevice = {
  locationId?: Maybe<Scalars['String']>;
  campaignId?: Maybe<Scalars['String']>;
  macCount?: Maybe<Scalars['Float']>;
  osCount?: Maybe<OsCount>;
  deviceTypeCount?: Maybe<DeviceTypeCount>;
};

export type ReportIncomeResult = {
  click?: Maybe<Scalars['Float']>;
  income?: Maybe<Scalars['Float']>;
  incomePartner?: Maybe<Scalars['Float']>;
  groupByCampaign?: Maybe<Array<GroupIncomeResult>>;
  groupByLocation?: Maybe<Array<GroupIncomeResult>>;
  time?: Maybe<Array<TimeIncomeResult>>;
};

export type ReportLocationData = {
  click?: Maybe<Scalars['Float']>;
  impression?: Maybe<Scalars['Float']>;
  clickBefore?: Maybe<Scalars['Float']>;
  impressionBefore?: Maybe<Scalars['Float']>;
  ctr?: Maybe<Scalars['Float']>;
  categoryName?: Maybe<Scalars['String']>;
  locationName?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
};

export type ReportLocationType = {
  click?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['DateTime']>;
};

export type ReportLogin = {
  locationId?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['JSON']>;
  day?: Maybe<Scalars['JSON']>;
  hour?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ReportOnlineSession = {
  os?: Maybe<Scalars['String']>;
  deviceType?: Maybe<Scalars['String']>;
  apMac?: Maybe<Scalars['String']>;
  deviceMac?: Maybe<Scalars['String']>;
  deviceName?: Maybe<Scalars['String']>;
  deviceCode?: Maybe<Scalars['String']>;
  device_name?: Maybe<Scalars['String']>;
  locationName?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
};

export type ReportSharedTrafficDetail = {
  date: Scalars['String'];
  total: Scalars['Float'];
  owned: Scalars['Float'];
  ratio: Scalars['Float'];
  totalImpression: Scalars['Float'];
  totalClick: Scalars['Float'];
  ownedImpression: Scalars['Float'];
  ownedClick: Scalars['Float'];
  ratioImpression: Scalars['Float'];
  ratioClick: Scalars['Float'];
};

export type ReportSharedTrafficResult = {
  _id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  total: Scalars['Float'];
  owned: Scalars['Float'];
  totalImpression: Scalars['Float'];
  totalClick: Scalars['Float'];
  ownedImpression: Scalars['Float'];
  ownedClick: Scalars['Float'];
  ratioImpression: Scalars['Float'];
  ratioClick: Scalars['Float'];
  sharedRatio?: Maybe<Scalars['Float']>;
  detail: Array<ReportSharedTrafficDetail>;
};

export type ReportSurvey = {
  createdAt?: Maybe<Scalars['DateTime']>;
  meta?: Maybe<SurveyMeta>;
  ua?: Maybe<Ua>;
  uaString?: Maybe<Scalars['String']>;
  mac?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  survey?: Maybe<Scalars['JSON']>;
  audience?: Maybe<Scalars['JSON']>;
};

export type ReportTodayLogin = {
  hour: Scalars['DateTime'];
  count: Scalars['Float'];
};

export type ReportTracking = {
  detail?: Maybe<Array<DetailResult>>;
  total?: Maybe<TotalValue>;
  groupByDay?: Maybe<Array<GroupByDay>>;
};

export type ReportTrackingDetailPayload = {
  data?: Maybe<Array<ReportTrakingDetail>>;
  error?: Maybe<StandardMutationError>;
};

export type ReportTrackingPayload = {
  data?: Maybe<Array<ReportLocationType>>;
  error?: Maybe<StandardMutationError>;
};

export type ReportTrakingDetail = {
  click?: Maybe<Scalars['Float']>;
  impression?: Maybe<Scalars['Float']>;
  ctr?: Maybe<Scalars['Float']>;
  categoryId?: Maybe<Scalars['String']>;
  categoryName?: Maybe<Scalars['String']>;
  bannerId?: Maybe<Scalars['String']>;
  bannerName?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  locationName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type ReportUserReturnData = {
  number_of_times?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  y?: Maybe<Scalars['Float']>;
};

export type ReportVisit = {
  locationId?: Maybe<Scalars['String']>;
  uniq7Days?: Maybe<Scalars['Float']>;
  uniq15Days?: Maybe<Scalars['Float']>;
  uniq30Days?: Maybe<Scalars['Float']>;
  click7Days?: Maybe<Scalars['Float']>;
  click15Days?: Maybe<Scalars['Float']>;
  click30Days?: Maybe<Scalars['Float']>;
  last7Days?: Maybe<LastNDay>;
  last15Days?: Maybe<LastNDay>;
  last30Days?: Maybe<LastNDay>;
};

export type Result = {
  _id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['ID']>;
  locationId?: Maybe<Scalars['String']>;
  campaignId?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['String']>;
  locationName?: Maybe<Scalars['String']>;
  campaignName?: Maybe<Scalars['String']>;
};

export type ResultInput = {
  locationId?: Maybe<Scalars['String']>;
  campaignId?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['String']>;
  locationName?: Maybe<Scalars['String']>;
  campaignName?: Maybe<Scalars['String']>;
};

export type Revenue = {
  _id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['ID']>;
  campaignId?: Maybe<Scalars['ID']>;
  campaignName?: Maybe<Scalars['String']>;
  scheduleFrom?: Maybe<Scalars['DateTime']>;
  scheduleTo?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['String']>;
  locations?: Maybe<Array<RevenueLocation>>;
  customers?: Maybe<Array<RevenueCustomer>>;
  unitPrice?: Maybe<Scalars['Float']>;
  unitPricePartner?: Maybe<Scalars['Float']>;
  revenueContracted?: Maybe<Scalars['Float']>;
  revenuePartnerContracted?: Maybe<Scalars['Float']>;
  costContracted?: Maybe<Scalars['Float']>;
  kpiBooked?: Maybe<Scalars['Float']>;
  kpiRunned?: Maybe<Scalars['Float']>;
  revenue?: Maybe<Scalars['Float']>;
  revenuePartner?: Maybe<Scalars['Float']>;
  typeTip?: Maybe<Scalars['String']>;
  tip?: Maybe<Scalars['Float']>;
  typeFeeBill?: Maybe<Scalars['String']>;
  feeBill?: Maybe<Scalars['Float']>;
  creatorId?: Maybe<Scalars['ID']>;
};

export type RevenueBasePayload = {
  data?: Maybe<Revenue>;
  error?: Maybe<StandardMutationError>;
};

export type RevenueCustomer = {
  kpiBooked?: Maybe<Scalars['Float']>;
  kpiRunned?: Maybe<Scalars['Float']>;
  revenue?: Maybe<Scalars['Float']>;
  customerId?: Maybe<Scalars['ID']>;
};

export type RevenueCustomerInput = {
  kpiBooked?: Maybe<Scalars['Float']>;
  kpiRunned?: Maybe<Scalars['Float']>;
  revenue?: Maybe<Scalars['Float']>;
  customerId?: Maybe<Scalars['ID']>;
};

export type RevenueFilter = {
  _ids?: Maybe<Array<Scalars['ID']>>;
  AND?: Maybe<RevenueFilter>;
  OR?: Maybe<RevenueFilter>;
  _search?: Maybe<Scalars['String']>;
  campaignId?: Maybe<Scalars['ID']>;
  _operators?: Maybe<RevenueFilterOperator>;
  scheduleFrom?: Maybe<Scalars['DateTime']>;
  scheduleTo?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RevenueFilterOperator = {
  campaignId?: Maybe<IdOperatorArgs>;
  scheduleFrom?: Maybe<DateOperatorArgs>;
  scheduleTo?: Maybe<DateOperatorArgs>;
  status?: Maybe<StringOperatorArgs>;
  _id?: Maybe<IdOperatorArgs>;
  createdAt?: Maybe<DateOperatorArgs>;
  updatedAt?: Maybe<DateOperatorArgs>;
};

export type RevenueInput = {
  campaignId?: Maybe<Scalars['ID']>;
  campaignName?: Maybe<Scalars['String']>;
  scheduleFrom?: Maybe<Scalars['DateTime']>;
  scheduleTo?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['String']>;
  locations?: Maybe<Array<RevenueLocationInput>>;
  customers?: Maybe<Array<RevenueCustomerInput>>;
  unitPrice?: Maybe<Scalars['Float']>;
  unitPricePartner?: Maybe<Scalars['Float']>;
  revenueContracted?: Maybe<Scalars['Float']>;
  revenuePartnerContracted?: Maybe<Scalars['Float']>;
  costContracted?: Maybe<Scalars['Float']>;
  kpiBooked?: Maybe<Scalars['Float']>;
  kpiRunned?: Maybe<Scalars['Float']>;
  revenue?: Maybe<Scalars['Float']>;
  revenuePartner?: Maybe<Scalars['Float']>;
  typeTip?: Maybe<Scalars['String']>;
  tip?: Maybe<Scalars['Float']>;
  typeFeeBill?: Maybe<Scalars['String']>;
  feeBill?: Maybe<Scalars['Float']>;
  creatorId?: Maybe<Scalars['ID']>;
};

export type RevenueLocation = {
  locationId?: Maybe<Scalars['ID']>;
  locationName?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  categoryName?: Maybe<Scalars['String']>;
  reportLocationId?: Maybe<Scalars['ID']>;
  kpiBooked?: Maybe<Scalars['Float']>;
  kpiRunned?: Maybe<Scalars['Float']>;
  unitPrice?: Maybe<Scalars['Float']>;
  unitPricePartner?: Maybe<Scalars['Float']>;
  revenue?: Maybe<Scalars['Float']>;
  revenuePartner?: Maybe<Scalars['Float']>;
  customerId?: Maybe<Scalars['ID']>;
};

export type RevenueLocationInput = {
  locationId?: Maybe<Scalars['ID']>;
  locationName?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  categoryName?: Maybe<Scalars['String']>;
  reportLocationId?: Maybe<Scalars['ID']>;
  kpiBooked?: Maybe<Scalars['Float']>;
  kpiRunned?: Maybe<Scalars['Float']>;
  unitPrice?: Maybe<Scalars['Float']>;
  unitPricePartner?: Maybe<Scalars['Float']>;
  revenue?: Maybe<Scalars['Float']>;
  revenuePartner?: Maybe<Scalars['Float']>;
  customerId?: Maybe<Scalars['ID']>;
};

export type RevenuePayload = {
  data?: Maybe<Revenue>;
  error?: Maybe<StandardMutationError>;
};

export type RevenueSort = {
  scheduleFrom?: Maybe<SortDirection>;
  scheduleTo?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
  updatedAt?: Maybe<SortDirection>;
};

export type RevenueUpdateArg = {
  campaignId?: Maybe<Scalars['ID']>;
  campaignName?: Maybe<Scalars['String']>;
  scheduleFrom?: Maybe<Scalars['DateTime']>;
  scheduleTo?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['String']>;
  locations?: Maybe<Array<RevenueLocationInput>>;
  customers?: Maybe<Array<RevenueCustomerInput>>;
  unitPrice?: Maybe<Scalars['Float']>;
  unitPricePartner?: Maybe<Scalars['Float']>;
  revenueContracted?: Maybe<Scalars['Float']>;
  revenuePartnerContracted?: Maybe<Scalars['Float']>;
  costContracted?: Maybe<Scalars['Float']>;
  kpiBooked?: Maybe<Scalars['Float']>;
  kpiRunned?: Maybe<Scalars['Float']>;
  revenue?: Maybe<Scalars['Float']>;
  revenuePartner?: Maybe<Scalars['Float']>;
  typeTip?: Maybe<Scalars['String']>;
  tip?: Maybe<Scalars['Float']>;
  typeFeeBill?: Maybe<Scalars['String']>;
  feeBill?: Maybe<Scalars['Float']>;
};

export type Role = {
  _id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['ID']>;
  roleId: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type RoleFilter = {
  _ids?: Maybe<Array<Scalars['ID']>>;
  AND?: Maybe<RoleFilter>;
  OR?: Maybe<RoleFilter>;
  _search?: Maybe<Scalars['String']>;
  roleId?: Maybe<Scalars['String']>;
  _operators?: Maybe<RoleFilterOperator>;
  group?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RoleFilterOperator = {
  roleId?: Maybe<StringOperatorArgs>;
  group?: Maybe<StringOperatorArgs>;
  name?: Maybe<StringOperatorArgs>;
  type?: Maybe<StringOperatorArgs>;
  _id?: Maybe<IdOperatorArgs>;
  createdAt?: Maybe<DateOperatorArgs>;
  updatedAt?: Maybe<DateOperatorArgs>;
};

export type RolePayload = {
  data?: Maybe<Role>;
  error?: Maybe<StandardMutationError>;
};

export type RoleSort = {
  roleId?: Maybe<SortDirection>;
  group?: Maybe<SortDirection>;
  name?: Maybe<SortDirection>;
  type?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
  updatedAt?: Maybe<SortDirection>;
};

export type RpLocationWhereFilterInput = {
  categoryId?: Maybe<Array<Scalars['String']>>;
  _id?: Maybe<Array<Scalars['String']>>;
  city?: Maybe<Array<Scalars['String']>>;
  district?: Maybe<Array<Scalars['String']>>;
  tag?: Maybe<Array<Scalars['String']>>;
  status?: Maybe<Array<Scalars['String']>>;
  ownerType?: Maybe<Array<Scalars['String']>>;
  date?: Maybe<DateInput>;
};

export type SaleInfoInput = {
  monthlyClick?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  bookingType?: Maybe<Scalars['String']>;
  pictureIds?: Maybe<Array<Scalars['String']>>;
};

export type SetScheduleArgs = {
  scheduleFrom?: Maybe<Scalars['DateTime']>;
  scheduleTo?: Maybe<Scalars['DateTime']>;
};

export type Setting = {
  _id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['ID']>;
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type SettingBasePayload = {
  data?: Maybe<Setting>;
  error?: Maybe<StandardMutationError>;
};

export type SettingFilter = {
  _ids?: Maybe<Array<Scalars['ID']>>;
  AND?: Maybe<SettingFilter>;
  OR?: Maybe<SettingFilter>;
  _search?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  _operators?: Maybe<SettingFilterOperator>;
  value?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SettingFilterOperator = {
  type?: Maybe<StringOperatorArgs>;
  value?: Maybe<StringOperatorArgs>;
  key?: Maybe<StringOperatorArgs>;
  description?: Maybe<StringOperatorArgs>;
  _id?: Maybe<IdOperatorArgs>;
  createdAt?: Maybe<DateOperatorArgs>;
  updatedAt?: Maybe<DateOperatorArgs>;
};

export type SettingInput = {
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type SettingSort = {
  type?: Maybe<SortDirection>;
  value?: Maybe<SortDirection>;
  key?: Maybe<SortDirection>;
  description?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
  updatedAt?: Maybe<SortDirection>;
};

export type SettingUpdateArg = {
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type SharedTrafficFilter = {
  categoryId?: Maybe<Array<Scalars['String']>>;
  _id?: Maybe<Array<Scalars['String']>>;
  city?: Maybe<Array<Scalars['String']>>;
  district?: Maybe<Array<Scalars['String']>>;
  tag?: Maybe<Array<Scalars['String']>>;
  status?: Maybe<Array<Scalars['String']>>;
  ownerType?: Maybe<Array<Scalars['String']>>;
  date?: Maybe<DateInput>;
  customerId: Scalars['String'];
  interval?: Maybe<SharedTrafficInterval>;
};

export enum SharedTrafficInterval {
  Day = 'Day',
  Month = 'Month',
  Year = 'Year'
}

export type ShareRevenueInput = {
  enabled?: Maybe<Scalars['Boolean']>;
  type?: Maybe<ShareRevenueType>;
  value?: Maybe<Scalars['Float']>;
};

export type ShareRevenueSettings = {
  enabled?: Maybe<Scalars['Boolean']>;
  type?: Maybe<ShareRevenueType>;
  value?: Maybe<Scalars['Float']>;
};

export enum ShareRevenueType {
  Fixed = 'Fixed',
  Percentage = 'Percentage'
}

export type ShareTrafficInput = {
  enabled?: Maybe<Scalars['Boolean']>;
  ratio?: Maybe<Scalars['Float']>;
};

export type ShareTrafficSettings = {
  enabled?: Maybe<Scalars['Boolean']>;
  ratio?: Maybe<Scalars['Float']>;
};

export type ShareType = {
  owner?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['Float']>;
};

export type ShareTypeInput = {
  owner?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['Float']>;
};

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type StandardMutationError = {
  title?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  message?: Maybe<Scalars['String']>;
};

export type Statistic = {
  kpi?: Maybe<Scalars['Float']>;
  click?: Maybe<Scalars['Float']>;
  impression?: Maybe<Scalars['Float']>;
  ctr?: Maybe<Scalars['Float']>;
  completed?: Maybe<Scalars['Float']>;
  kpiCompleted?: Maybe<Scalars['Float']>;
  kpiRemaining?: Maybe<Scalars['Float']>;
};

export type StatisticInput = {
  kpi?: Maybe<Scalars['Float']>;
  click?: Maybe<Scalars['Float']>;
  impression?: Maybe<Scalars['Float']>;
  ctr?: Maybe<Scalars['Float']>;
  completed?: Maybe<Scalars['Float']>;
  kpiCompleted?: Maybe<Scalars['Float']>;
  kpiRemaining?: Maybe<Scalars['Float']>;
};

export type StringOperatorArgs = {
  in?: Maybe<Array<Scalars['String']>>;
  nin?: Maybe<Array<Scalars['String']>>;
};

export type SumByDate = {
  value?: Maybe<Scalars['Float']>;
};

export type SurveyCampaignPayload = {
  data?: Maybe<Array<ReportSurvey>>;
  total?: Maybe<Scalars['Float']>;
  os?: Maybe<Scalars['JSON']>;
  device?: Maybe<DeviceTypeCount>;
  config?: Maybe<CampaignConfig>;
  error?: Maybe<StandardMutationError>;
  result?: Maybe<Array<SurveyTracking>>;
};

export type SurveyFilter = {
  exists?: Maybe<Array<Scalars['String']>>;
  not_exists?: Maybe<Array<Scalars['String']>>;
  inq?: Maybe<Scalars['JSONObject']>;
  nin?: Maybe<Scalars['JSONObject']>;
  range?: Maybe<Scalars['JSONObject']>;
  survey?: Maybe<Scalars['JSONObject']>;
};

export type SurveyFilterInput = {
  campaignId: Scalars['String'];
  filter?: Maybe<SurveyFilter>;
  skip?: Maybe<Scalars['Float']>;
};

export type SurveyMeta = {
  bannerId?: Maybe<Scalars['String']>;
  campaignId?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  realLocationId?: Maybe<Scalars['String']>;
  bannerName?: Maybe<Scalars['String']>;
  locationName?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Float']>;
};

export type SurveyMetaInput = {
  bannerId?: Maybe<Scalars['String']>;
  campaignId?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  realLocationId?: Maybe<Scalars['String']>;
  bannerName?: Maybe<Scalars['String']>;
  locationName?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Float']>;
};

export type SurveyProperties = {
  field?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type SurveyTracking = {
  name?: Maybe<Scalars['String']>;
  options?: Maybe<Scalars['JSON']>;
};

export type SystemInfoInput = {
  noOfAccessPoint?: Maybe<Scalars['Float']>;
  noOfRouter?: Maybe<Scalars['Float']>;
};

export type TimeIncomeResult = {
  date?: Maybe<Scalars['String']>;
  click?: Maybe<Scalars['Float']>;
  income?: Maybe<Scalars['Float']>;
  incomePartner?: Maybe<Scalars['Float']>;
};

export type TodayPayload = {
  total?: Maybe<Scalars['Float']>;
  detail?: Maybe<Array<ReportTodayLogin>>;
};

export type Total = {
  click?: Maybe<Scalars['Float']>;
  impression?: Maybe<Scalars['Float']>;
  clickBefore?: Maybe<Scalars['Float']>;
  impressionBefore?: Maybe<Scalars['Float']>;
  ctr?: Maybe<Scalars['Float']>;
  users?: Maybe<Scalars['Float']>;
};

export type TotalCampagin = {
  runningCampaigns?: Maybe<Scalars['Float']>;
  advertiserCampaigns?: Maybe<Scalars['Float']>;
  defaultCampaigns?: Maybe<Scalars['Float']>;
  otherCampaign?: Maybe<Scalars['Float']>;
  advertiserKpi?: Maybe<Scalars['Float']>;
  advertiserClick?: Maybe<Scalars['Float']>;
  newCampaigns?: Maybe<Scalars['Float']>;
  enddingCampaigns?: Maybe<Scalars['Float']>;
};

export type TotalTraffic = {
  today?: Maybe<TodayPayload>;
  thisMonth?: Maybe<Scalars['Float']>;
};

export type TotalUser = {
  ownerTypeUser?: Maybe<Scalars['Float']>;
  demoUser?: Maybe<Scalars['Float']>;
  realUser?: Maybe<Scalars['Float']>;
  newMonthUser?: Maybe<Scalars['Float']>;
};

export type TotalValue = {
  impression?: Maybe<Scalars['Float']>;
  click?: Maybe<Scalars['Float']>;
  ctr?: Maybe<Scalars['Float']>;
};

export type TrackingByLocation = {
  todayLogin: Array<ReportTodayLogin>;
  onlineSession: Array<ReportOnlineSession>;
};

export type TrackingDetail = {
  action?: Maybe<Scalars['String']>;
  mac?: Maybe<Scalars['String']>;
  uaString?: Maybe<Scalars['String']>;
  meta?: Maybe<MetaElastic>;
  ua?: Maybe<Ua>;
  ip?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TrackingDetailExternalFilter = {
  locationIds?: Maybe<Array<Scalars['String']>>;
  bannerIds?: Maybe<Array<Scalars['String']>>;
  categoryIds?: Maybe<Array<Scalars['String']>>;
  tags?: Maybe<Array<Scalars['String']>>;
  groups?: Maybe<Array<Scalars['String']>>;
};

export type TrackingDetailInternalFilter = {
  campaignId: Scalars['String'];
  categoryId?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  bannerId?: Maybe<Scalars['String']>;
};

export type TrackingLocation = {
  categoryId?: Maybe<Scalars['String']>;
  click?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['DateTime']>;
};

export type TrackingPayload = {
  data?: Maybe<Array<TrackingLocation>>;
  error?: Maybe<StandardMutationError>;
};

export type TrafficDetail = {
  click?: Maybe<Scalars['Float']>;
  impression?: Maybe<Scalars['Float']>;
  ctr?: Maybe<Scalars['Float']>;
  impressionAds?: Maybe<Scalars['Float']>;
  clickAds?: Maybe<Scalars['Float']>;
  ctrAds?: Maybe<Scalars['Float']>;
  date?: Maybe<Scalars['String']>;
};

export type TrafficSummaryData = {
  detail?: Maybe<Array<TrafficDetail>>;
  click?: Maybe<Scalars['Float']>;
  impression?: Maybe<Scalars['Float']>;
  ctr?: Maybe<Scalars['Float']>;
  impressionAds?: Maybe<Scalars['Float']>;
  clickAds?: Maybe<Scalars['Float']>;
  ctrAds?: Maybe<Scalars['Float']>;
};

export type TwoFactorAuth = {
  _id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['ID']>;
  userId: Scalars['ID'];
  secret?: Maybe<Scalars['String']>;
  otpauthUrl?: Maybe<Scalars['String']>;
};

export type TwoFactorAuthBasePayload = {
  data?: Maybe<TwoFactorAuth>;
  error?: Maybe<StandardMutationError>;
};

export type TwoFactorAuthFilter = {
  _ids?: Maybe<Array<Scalars['ID']>>;
  AND?: Maybe<TwoFactorAuthFilter>;
  OR?: Maybe<TwoFactorAuthFilter>;
  _search?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['ID']>;
  _operators?: Maybe<TwoFactorAuthFilterOperator>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TwoFactorAuthFilterOperator = {
  _id?: Maybe<IdOperatorArgs>;
  createdAt?: Maybe<DateOperatorArgs>;
  updatedAt?: Maybe<DateOperatorArgs>;
};

export type TwoFactorAuthInput = {
  userId: Scalars['ID'];
  secret?: Maybe<Scalars['String']>;
  otpauthUrl?: Maybe<Scalars['String']>;
};

export type TwoFactorAuthPayload = {
  data?: Maybe<TwoFactorAuth>;
  error?: Maybe<StandardMutationError>;
};

export type TwoFactorAuthSort = {
  createdAt?: Maybe<SortDirection>;
  updatedAt?: Maybe<SortDirection>;
};

export type Ua = {
  browser?: Maybe<Scalars['String']>;
  major?: Maybe<Scalars['Float']>;
  minor?: Maybe<Scalars['Float']>;
  patch?: Maybe<Scalars['Float']>;
  os?: Maybe<Scalars['String']>;
  os_name?: Maybe<Scalars['String']>;
  os_major?: Maybe<Scalars['Float']>;
  os_minor?: Maybe<Scalars['Float']>;
  device?: Maybe<Scalars['String']>;
  device_name?: Maybe<Scalars['String']>;
  brandName?: Maybe<Scalars['String']>;
  deviceName?: Maybe<Scalars['String']>;
  deviceCode?: Maybe<Scalars['String']>;
};

export type UaInput = {
  browser?: Maybe<Scalars['String']>;
  major?: Maybe<Scalars['Float']>;
  minor?: Maybe<Scalars['Float']>;
  patch?: Maybe<Scalars['Float']>;
  os?: Maybe<Scalars['String']>;
  os_name?: Maybe<Scalars['String']>;
  os_major?: Maybe<Scalars['Float']>;
  os_minor?: Maybe<Scalars['Float']>;
  device?: Maybe<Scalars['String']>;
  device_name?: Maybe<Scalars['String']>;
  brandName?: Maybe<Scalars['String']>;
  deviceName?: Maybe<Scalars['String']>;
  deviceCode?: Maybe<Scalars['String']>;
};

export type UpdateBookingResults = {
  result?: Maybe<Scalars['Boolean']>;
  error?: Maybe<StandardMutationError>;
};

export type UpdateMultiResult = {
  data?: Maybe<Array<Result>>;
  success?: Maybe<Scalars['Boolean']>;
  error?: Maybe<StandardMutationError>;
};

export type User = {
  _id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  signature?: Maybe<Scalars['String']>;
  avatarLink?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  profileId: Scalars['String'];
  creatorId?: Maybe<Scalars['ID']>;
  ownerType?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  emailVerified?: Maybe<Scalars['Boolean']>;
  twoFactorAuthId?: Maybe<Scalars['ID']>;
  expiredDate?: Maybe<Scalars['DateTime']>;
  activeLoginTimes?: Maybe<Scalars['Float']>;
  profile?: Maybe<Profile>;
  creator?: Maybe<User>;
  customer?: Maybe<Customer>;
};

export type UserBalance = {
  _id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['ID']>;
  userId: Scalars['ID'];
  type: Scalars['String'];
  amount: Scalars['Float'];
  expireDate?: Maybe<Scalars['DateTime']>;
};

export type UserBasePayload = {
  data?: Maybe<User>;
  error?: Maybe<StandardMutationError>;
};

export type UserFilter = {
  _ids?: Maybe<Array<Scalars['ID']>>;
  AND?: Maybe<UserFilter>;
  OR?: Maybe<UserFilter>;
  _search?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  _operators?: Maybe<UserFilterOperator>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  creatorId?: Maybe<Scalars['ID']>;
  ownerType?: Maybe<Scalars['String']>;
  expiredDate?: Maybe<Scalars['DateTime']>;
  activeLoginTimes?: Maybe<Scalars['Float']>;
  _id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserFilterOperator = {
  name?: Maybe<StringOperatorArgs>;
  email?: Maybe<StringOperatorArgs>;
  phone?: Maybe<StringOperatorArgs>;
  profileId?: Maybe<StringOperatorArgs>;
  creatorId?: Maybe<IdOperatorArgs>;
  ownerType?: Maybe<StringOperatorArgs>;
  expiredDate?: Maybe<DateOperatorArgs>;
  activeLoginTimes?: Maybe<NumberOperatorArgs>;
  _id?: Maybe<IdOperatorArgs>;
  createdAt?: Maybe<DateOperatorArgs>;
  updatedAt?: Maybe<DateOperatorArgs>;
};

export type UserGuide = {
  _id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['ID']>;
  title: Scalars['String'];
  brand?: Maybe<Scalars['String']>;
  content: Scalars['String'];
  linkVideo?: Maybe<Scalars['String']>;
  faq: Scalars['Boolean'];
  meta: Scalars['JSON'];
};

export type UserGuideBasePayload = {
  data?: Maybe<UserGuide>;
  error?: Maybe<StandardMutationError>;
};

export type UserGuideFilter = {
  _ids?: Maybe<Array<Scalars['ID']>>;
  AND?: Maybe<UserGuideFilter>;
  OR?: Maybe<UserGuideFilter>;
  _search?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['ID']>;
  _operators?: Maybe<UserGuideFilterOperator>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserGuideFilterOperator = {
  _id?: Maybe<IdOperatorArgs>;
  createdAt?: Maybe<DateOperatorArgs>;
  updatedAt?: Maybe<DateOperatorArgs>;
};

export type UserGuideInput = {
  title: Scalars['String'];
  brand?: Maybe<Scalars['String']>;
  content: Scalars['String'];
  linkVideo?: Maybe<Scalars['String']>;
  faq?: Maybe<Scalars['Boolean']>;
  meta: Scalars['JSON'];
};

export type UserGuideSort = {
  title?: Maybe<SortDirection>;
  brand?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
  updatedAt?: Maybe<SortDirection>;
};

export type UserGuideUpdateArg = {
  title?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  linkVideo?: Maybe<Scalars['String']>;
  faq?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<Scalars['JSON']>;
};

export type UserInput = {
  name: Scalars['String'];
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  signature?: Maybe<Scalars['String']>;
  avatarLink?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  profileId: Scalars['String'];
  creatorId?: Maybe<Scalars['ID']>;
  customerId?: Maybe<Scalars['ID']>;
  ownerType?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  emailVerified?: Maybe<Scalars['Boolean']>;
  twoFactorAuthId?: Maybe<Scalars['ID']>;
  expiredDate?: Maybe<Scalars['DateTime']>;
  activeLoginTimes?: Maybe<Scalars['Float']>;
};

export type UserOwnerPayload = Owner | User;

export type UserOwnerWhereArgs = {
  model?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  ownerType?: Maybe<Scalars['String']>;
};

export type UserPayload = {
  data?: Maybe<User>;
  error?: Maybe<StandardMutationError>;
};

export type UserRegisterInput = {
  name: Scalars['String'];
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  taxCode?: Maybe<Scalars['String']>;
  signature?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  recaptcha: Scalars['String'];
  referralId?: Maybe<Scalars['String']>;
};

export type UserSort = {
  name?: Maybe<SortDirection>;
  email?: Maybe<SortDirection>;
  phone?: Maybe<SortDirection>;
  profileId?: Maybe<SortDirection>;
  creatorId?: Maybe<SortDirection>;
  ownerType?: Maybe<SortDirection>;
  active?: Maybe<SortDirection>;
  emailVerified?: Maybe<SortDirection>;
  expiredDate?: Maybe<SortDirection>;
  activeLoginTimes?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
  updatedAt?: Maybe<SortDirection>;
};

export type UserUpdateArg = {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  signature?: Maybe<Scalars['String']>;
  avatarLink?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  ownerType?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  emailVerified?: Maybe<Scalars['Boolean']>;
  twoFactorAuthId?: Maybe<Scalars['ID']>;
  expiredDate?: Maybe<Scalars['DateTime']>;
  activeLoginTimes?: Maybe<Scalars['Float']>;
};

export type UserUpdateInfoArgs = {
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  avatarLink?: Maybe<Scalars['String']>;
};

export enum View {
  Location = 'location',
  Campaign = 'campaign'
}

export type Webhook = {
  api_url?: Maybe<Scalars['String']>;
  timeout?: Maybe<Scalars['Float']>;
  token?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  method?: Maybe<Method>;
  params?: Maybe<Array<WebhookParamsConfig>>;
};

export type WebhookInput = {
  api_url?: Maybe<Scalars['String']>;
  timeout?: Maybe<Scalars['Float']>;
  token?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  method?: Maybe<Method>;
  params?: Maybe<Array<WebhookParamsConfigInput>>;
};

export type WebhookParamsConfig = {
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type WebhookParamsConfigInput = {
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type Zalo = {
  zalo_oa_id?: Maybe<Scalars['String']>;
  zalo_userId_by_oaId?: Maybe<Scalars['String']>;
};

export type FullUserBalanceFragment = Pick<UserBalance, '_id' | 'userId' | 'amount' | 'type' | 'expireDate'>;

export type BasicBalanceHistoryFragment = Pick<BalanceHistory, '_id' | 'userId' | 'originalAmount' | 'amount' | 'campaignId' | 'type' | 'createdAt' | 'updatedAt' | 'action'>;

export type FullBalanceHistoryFragment = (
  { campaign?: Maybe<Pick<BalanceHistoryCampaign, 'name'>> }
  & BasicBalanceHistoryFragment
);

export type BasicBannerFragment = (
  Pick<Banner, '_id' | 'name' | 'categoryId' | 'htmlCode' | 'fileCode' | 'isSample' | 'configRender' | 'ownerType' | 'landingPageUrl' | 'active' | 'creatorId' | 'createdAt' | 'updatedAt'>
  & { category?: Maybe<Pick<Common, 'text'>> }
);

export type CommonBannerFragment = Pick<Banner, '_id' | 'name'>;

export type FullBannerFragment = (
  { creator?: Maybe<Pick<User, 'name' | 'email'>>, customer?: Maybe<Pick<User, 'name' | 'email'>>, currentCampaigns?: Maybe<Array<Pick<CampaignBanner, 'name' | 'isBo' | 'isRunning'>>> }
  & BasicBannerFragment
);

export type BasicBannerTempFragment = (
  Pick<BannerTemp, '_id' | 'name' | 'categoryId' | 'htmlCode' | 'fileCode' | 'isSample' | 'configRender' | 'ownerType' | 'creatorId' | 'createdAt' | 'updatedAt'>
  & { category?: Maybe<Pick<Common, 'text'>> }
);

export type CommonBannerTempFragment = Pick<BannerTemp, '_id' | 'name'>;

export type FullBannerTempFragment = (
  { creator?: Maybe<Pick<User, 'name' | 'email'>>, customer?: Maybe<Pick<User, 'name' | 'email'>> }
  & BasicBannerTempFragment
);

export type BannerTemplateFragment = Pick<BannerTemplate, '_id' | 'name' | 'type' | 'typeText' | 'options' | 'htmlCode' | 'createdAt' | 'updatedAt' | 'meta'>;

export type BasicCampaignFragment = (
  Pick<Campaign, '_id' | 'name' | 'description' | 'type' | 'isBookOnline' | 'categoryId' | 'scheduleFrom' | 'scheduleTo' | 'kpi' | 'budget' | 'times' | 'timesOfClick' | 'active' | 'status' | 'stoppedUntil' | 'defaultForClient'>
  & { locations?: Maybe<Array<Pick<CampaignLocation, 'locationId' | 'locationName' | 'categoryId' | 'categoryName' | 'kpi'>>>, config?: Maybe<(
    Pick<CampaignConfig, 'landingPageUrl'>
    & { banners?: Maybe<Array<Pick<CampaignConfigBanner, 'bannerName' | 'bannerId'>>> }
  )> }
);

export type CampaignStatsFragment = (
  Pick<Campaign, '_id' | 'name' | 'active' | 'status' | 'scheduleFrom' | 'scheduleTo' | 'type'>
  & { stats?: Maybe<Pick<Statistic, 'kpi' | 'click' | 'impression' | 'ctr' | 'completed' | 'kpiCompleted' | 'kpiRemaining'>> }
);

export type FullCampaignFragment = (
  Pick<Campaign, 'typeBrand' | 'shareOwner' | 'ownerType' | 'optimizeType' | 'customs' | 'creatorId' | 'type'>
  & { config?: Maybe<Pick<CampaignConfig, 'crm' | 'crmValue'>>, creator?: Maybe<Pick<User, 'name'>> }
  & BasicCampaignFragment
  & CampaignStatsFragment
);

export type CommonCampaignFragment = Pick<Campaign, '_id' | 'name' | 'status' | 'ownerType' | 'type'>;

export type ReportCampaignListFragment = (
  Pick<Campaign, '_id' | 'name' | 'isBookOnline' | 'type' | 'scheduleFrom' | 'scheduleTo' | 'status'>
  & { stats?: Maybe<Pick<Statistic, 'kpi' | 'completed' | 'click' | 'kpiCompleted'>> }
);

export type AdvanceCampaignFormFragment = Pick<Campaign, '_id' | 'name' | 'type' | 'shareOwner'>;

export type CampaignConfigFragment = (
  Pick<Campaign, '_id' | 'name' | 'scheduleFrom' | 'scheduleTo' | 'kpi' | 'customs'>
  & { locations?: Maybe<Array<Pick<CampaignLocation, 'categoryId' | 'locationId' | 'locationName'>>>, config?: Maybe<(
    Pick<CampaignConfig, 'landingPageUrl' | 'impressionUrl' | 'trackingLocation' | 'crm' | 'crmValue'>
    & { audience?: Maybe<{ acceptRules?: Maybe<Pick<QueryBuilder, 'query' | 'queryTree'>> }>, banners?: Maybe<Array<Pick<CampaignConfigBanner, 'bannerId' | 'bannerName' | 'query' | 'queryTree'>>>, bannerRandom?: Maybe<Pick<BannerRandom, 'enable' | 'ratio'>>, fb?: Maybe<(
      Pick<Facebook, 'appId' | 'appSecret' | 'accountKitSecret' | 'feed'>
      & { feedBody?: Maybe<Pick<FeedBody, 'link' | 'name' | 'caption' | 'description' | 'picture'>> }
    )>, gg?: Maybe<Pick<Google, 'clientId' | 'clientSecret'>>, webhook?: Maybe<(
      Pick<Webhook, 'enabled' | 'api_url' | 'timeout' | 'method' | 'token'>
      & { params?: Maybe<Array<Pick<WebhookParamsConfig, 'name' | 'value'>>> }
    )> }
  )> }
);

export type FullCampaignMappingFragment = (
  Pick<CampaignMapping, '_id' | 'campaignId' | 'locationId' | 'status' | 'reportLocationId' | 'scheduleFrom' | 'scheduleTo' | 'priority' | 'kpi' | 'active'>
  & { campaign?: Maybe<Pick<Campaign, '_id' | 'name'>>, location?: Maybe<Pick<Location, '_id' | 'name' | 'licenseName'>>, reportLocation?: Maybe<Pick<Location, '_id' | 'name' | 'licenseName'>>, stats?: Maybe<Pick<Statistic, 'kpi' | 'click' | 'kpiCompleted' | 'kpiRemaining'>>, todayStats?: Maybe<Pick<Statistic, 'kpi' | 'click' | 'kpiCompleted'>>, yesterdayStats?: Maybe<Pick<Statistic, 'kpi' | 'click' | 'kpiCompleted'>> }
);

export type FullCommonFragment = Pick<Common, '_id' | 'value' | 'text' | 'icon' | 'type' | 'instructionType' | 'meta' | 'options' | 'active' | 'creatorId' | 'createdAt' | 'updatedAt'>;

export type CommonLocationCategoryFragment = Pick<Common, 'text' | 'value' | 'icon' | 'meta' | 'active'>;

export type BasicCustomerFragment = (
  Pick<Customer, '_id' | 'name' | 'email' | 'phone' | 'address' | 'website' | 'taxCode' | 'typeCode' | 'urlLogo'>
  & { shareTraffic?: Maybe<Pick<ShareTrafficSettings, 'ratio' | 'enabled'>>, logos?: Maybe<Pick<File, 'id' | 'path'>>, shareRevenue?: Maybe<Pick<ShareRevenueSettings, 'enabled'>> }
);

export type MinimalCustomerTypeFragment = Pick<CustomerType, '_id' | 'name' | 'description'>;

export type BasicCustomerTypeFragment = (
  Pick<CustomerType, 'isDefault' | 'defaultProfileId'>
  & { profile?: Maybe<Pick<Profile, 'display'>> }
  & MinimalCustomerTypeFragment
);

export type BasicEquipmentFragment = Pick<Equipment, '_id' | 'name' | 'type' | 'brand' | 'model' | 'locationId' | 'mac' | 'serial' | 'gateway' | 'createdAt' | 'updatedAt'>;

export type FullEquipmentFragment = (
  Pick<Equipment, 'creatorId'>
  & { creator?: Maybe<MinimalUserFragment>, location?: Maybe<MinimalLocationFragment> }
  & BasicEquipmentFragment
);

export type CommonEquipmentFragment = Pick<Equipment, '_id' | 'mac'>;

export type CommonOwnerTypeEquipmentFragment = Pick<Equipment, '_id' | 'ownerType'>;

export type BasicErrorLogFragment = Pick<ErrorLog, '_id' | 'userId' | 'error' | 'createdAt' | 'updatedAt'>;

export type FullErrorLogFragment = (
  { user?: Maybe<FullUserFragment> }
  & BasicErrorLogFragment
);

export type ImageFragment = (
  Pick<Image, '_id' | 'name' | 'type' | 'typeText' | 'category' | 'categoryText' | 'link' | 'meta' | 'createdAt' | 'updatedAt'>
  & { image?: Maybe<Pick<File, 'id' | 'path'>> }
);

export type MinimalLocationFragment = Pick<Location, '_id' | 'name' | 'licenseName' | 'status'>;

export type StatsLocationFragment = (
  Pick<Location, 'activeDay' | 'noOfRouter' | 'noOfAccessPoint'>
  & { stats?: Maybe<Pick<LocationStats, 'dailyClick' | 'ctr' | 'monthlyClick' | 'monthlyImpression' | 'dailyImpression' | 'uniqueUser'>> }
  & MinimalLocationFragment
);

export type CommonLocationFragment = (
  Pick<Location, 'categoryId' | 'district' | 'city' | 'tags' | 'group' | 'ownerType'>
  & { address?: Maybe<Pick<AddressType, 'text'>>, equipments?: Maybe<Array<Pick<Equipment, '_id' | 'name' | 'mac'>>> }
  & StatsLocationFragment
);

export type FullLocationFragment = (
  { creator?: Maybe<MinimalUserFragment>, customer?: Maybe<BasicUserFragment> }
  & CommonLocationFragment
);

export type SaleInfoFormFragment = (
  Pick<Location, '_id' | 'name' | 'monthlyClick' | 'description' | 'pictureIds' | 'bookingType'>
  & { pictures?: Maybe<Array<Pick<File, 'path' | 'id'>>> }
);

export type SystemInfoFormFragment = Pick<Location, '_id' | 'noOfAccessPoint' | 'noOfRouter'>;

export type DetailLocationInfoFragment = (
  Pick<Location, 'noOfAccessPoint' | 'noOfRouter' | 'activeDay' | '_id' | 'name' | 'categoryId' | 'city' | 'district' | 'status' | 'configAuth'>
  & { address?: Maybe<Pick<AddressType, 'text'>>, stats?: Maybe<Pick<LocationStats, 'monthlyClick'>> }
);

export type AdvanceFormFragment = (
  Pick<Location, '_id' | 'status' | 'activeDay' | 'configAuth' | 'share'>
  & { shareType?: Maybe<Pick<ShareType, 'owner' | 'type' | 'unitPrice'>>, stats?: Maybe<Pick<LocationStats, 'monthlyClick'>> }
);

export type BasicFormFragment = (
  Pick<Location, '_id' | 'name' | 'licenseName' | 'categoryId' | 'urlLogo' | 'city' | 'district' | 'status' | 'tags' | 'description'>
  & { logos?: Maybe<Pick<File, 'id' | 'path'>>, address?: Maybe<Pick<AddressType, 'text'>> }
);

export type LocationsGridFragment = (
  Pick<Location, '_id' | 'name' | 'description' | 'licenseName' | 'categoryId' | 'district' | 'city' | 'status' | 'createdAt' | 'lastActiveTracking' | 'activeDay' | 'noOfRouter' | 'noOfAccessPoint' | 'creatorId'>
  & { address?: Maybe<Pick<AddressType, 'text'>>, stats?: Maybe<Pick<LocationStats, 'dailyClick' | 'ctr' | 'monthlyClick' | 'monthlyImpression' | 'dailyImpression' | 'uniqueUser'>>, creator?: Maybe<Pick<User, 'name'>>, campaignRunning?: Maybe<Array<(
    Pick<LocationCampaignRunning, 'campaignName' | 'isBo' | 'isActive' | 'landingPage'>
    & { banners?: Maybe<Array<Pick<CampaignConfigBanner, 'query' | 'bannerId' | 'bannerName'>>> }
  )>> }
);

export type LocationSaleFragment = (
  Pick<Location, '_id' | 'name' | 'description' | 'categoryId' | 'categoryName' | 'city' | 'cityName' | 'district' | 'districtName' | 'status' | 'pictureIds' | 'monthlyClick' | 'noOfAccessPoint'>
  & { address?: Maybe<Pick<AddressType, 'text'>>, stats?: Maybe<Pick<LocationStats, 'monthlyClick' | 'uniqueUser' | 'ctr'>>, pictures?: Maybe<Array<Pick<File, 'id' | 'path'>>> }
);

export type LocationPriceFragment = Pick<LocationPrice, '_id' | 'name' | 'description' | 'categoryId' | 'city' | 'bookingType' | 'district' | 'price' | 'categoryPrice'>;

export type LogsFragment = (
  Pick<Logs, '_id' | 'type' | 'description' | 'userId' | 'model' | 'resourceId' | 'old' | 'new' | 'createdAt' | 'updatedAt'>
  & { user?: Maybe<Pick<User, 'name' | 'email'>>, resourceCampaign?: Maybe<Pick<Campaign, 'name'>> }
);

export type FullMessageFragment = (
  Pick<Message, '_id' | 'receiverId' | 'title' | 'content' | 'status' | 'createdAt' | 'seenAt'>
  & { receiver: Pick<User, 'email' | 'name'> }
);

export type BasicNotificationFragment = Pick<Notification, '_id' | 'userId' | 'content' | 'status' | 'createdAt' | 'seenAt' | 'modelType' | 'notifi_type' | 'prioryty' | 'resourceId'>;

export type FullNotificationFragment = (
  { user: BasicUserFragment }
  & BasicNotificationFragment
);

export type FullOrderFragment = (
  Pick<Order, '_id' | 'status' | 'type' | 'isRecharge' | 'createOrderStatus' | 'queryCount' | 'paymentProvider' | 'paymentAmount' | 'paymentRedirectUrl' | 'paymentBank' | 'paymentMessage' | 'paymentRefNo' | 'paymentStatus' | 'createdAt' | 'updatedAt' | 'waitedAt' | 'paidAt' | 'creatorId'>
  & { plans: Array<Pick<OrderPlan, 'price' | 'locationIds' | 'amount' | 'planCode' | 'calPrice'>>, creator?: Maybe<Pick<User, 'name' | 'email'>>, customer?: Maybe<Pick<User, 'name' | 'email'>> }
);

export type BasicOrderFragment = Pick<Order, '_id' | 'status' | 'type' | 'isRecharge' | 'paymentProvider' | 'paymentAmount' | 'createdAt' | 'updatedAt' | 'waitedAt' | 'paidAt'>;

export type BasicOwnerFragment = Pick<Owner, 'model' | '_id' | 'userId' | 'resourceId'>;

export type PaymentFragment = (
  Pick<Payment, '_id' | 'userId' | 'type' | 'amount' | 'note' | 'status' | 'createdAt' | 'verifiedAt' | 'verifiedBy' | 'rejectedAt' | 'rejectReason'>
  & { userPayment?: Maybe<Pick<User, 'name' | 'email' | 'phone'>>, verifiedByUser?: Maybe<Pick<User, 'name' | 'email' | 'phone'>> }
);

export type PlanFragment = (
  Pick<Plan, '_id' | 'name' | 'planCode' | 'description' | 'price' | 'groupDeviceId' | 'maxOfDevice' | 'status' | 'createdAt' | 'updatedAt'>
  & { groupDevice?: Maybe<Pick<Common, 'text' | 'meta'>> }
);

export type MinimalProfileFragment = Pick<Profile, '_id' | 'profileId' | 'display'>;

export type BasicProfileFragment = (
  Pick<Profile, 'roleIds' | 'menuRoleIds' | 'createdAt' | 'updatedAt'>
  & MinimalProfileFragment
);

export type FullProfileFragment = (
  { roles: Array<FullRoleFragment> }
  & BasicProfileFragment
);

export type FullRegionFragment = Pick<Region, '_id' | 'type' | 'value' | 'text' | 'city' | 'area' | 'createdAt' | 'updatedAt'>;

export type CampaignRevenueFragment = Pick<Revenue, 'campaignId' | 'campaignName'>;

export type FullRevenueFragment = (
  Pick<Revenue, '_id' | 'campaignId' | 'campaignName' | 'unitPrice' | 'unitPricePartner' | 'revenueContracted' | 'revenuePartnerContracted' | 'costContracted' | 'kpiBooked' | 'kpiRunned' | 'revenue' | 'revenuePartner' | 'typeTip' | 'tip' | 'status' | 'typeFeeBill' | 'feeBill' | 'creatorId' | 'scheduleFrom' | 'scheduleTo' | 'createdAt' | 'updatedAt'>
  & { locations?: Maybe<Array<Pick<RevenueLocation, 'locationId' | 'locationName' | 'categoryId' | 'categoryName' | 'reportLocationId' | 'kpiBooked' | 'kpiRunned' | 'unitPrice' | 'unitPricePartner' | 'revenue' | 'revenuePartner' | 'customerId'>>>, customers?: Maybe<Array<Pick<RevenueCustomer, 'kpiBooked' | 'kpiRunned' | 'revenue' | 'customerId'>>> }
);

export type FullRoleFragment = Pick<Role, '_id' | 'roleId' | 'description' | 'createdAt' | 'updatedAt' | 'group' | 'name'>;

export type FullReportSurveyFragment = (
  Pick<ReportSurvey, 'uaString' | 'createdAt' | 'updatedAt' | 'mac' | 'survey' | 'audience'>
  & { ua?: Maybe<Pick<Ua, 'patch' | 'device_name' | 'os' | 'major' | 'minor' | 'os_major' | 'os_minor' | 'browser' | 'os_name' | 'device'>>, meta?: Maybe<Pick<SurveyMeta, 'bannerId' | 'campaignId' | 'locationId' | 'realLocationId' | 'count'>> }
);

export type FullTwoFactorAuthFragment = Pick<TwoFactorAuth, '_id' | 'userId' | 'secret' | 'otpauthUrl'>;

export type MinimalUserFragment = Pick<User, '_id' | 'avatarLink' | 'name' | 'email'>;

export type BasicUserFragment = (
  Pick<User, 'phone' | 'address' | 'password' | 'active' | 'emailVerified' | 'profileId' | 'ownerType' | 'twoFactorAuthId' | 'expiredDate'>
  & MinimalUserFragment
);

export type UserFormFragment = (
  { profile?: Maybe<Pick<Profile, 'display'>> }
  & BasicUserFragment
);

export type UserGridFragment = (
  Pick<User, 'creatorId' | 'createdAt'>
  & { creator?: Maybe<Pick<User, 'name'>> }
  & UserFormFragment
);

export type FullUserFragment = (
  Pick<User, 'customerId' | 'creatorId'>
  & { profile?: Maybe<FullProfileFragment>, customer?: Maybe<BasicCustomerFragment>, creator?: Maybe<Pick<User, '_id' | 'name'>> }
  & BasicUserFragment
);

export type AddKpiManagementMutationVariables = Exact<{
  campaignMapping: AddKpiInput;
}>;


export type AddKpiManagementMutation = { addKpiManagement: (
    Pick<UpdateBookingResults, 'result'>
    & { error?: Maybe<Pick<StandardMutationError, 'code' | 'title'>> }
  ) };

export type AddLocationMutationVariables = Exact<{
  addLocationParams: AddLocationParams;
}>;


export type AddLocationMutation = { addLocation: (
    Pick<UpdateBookingResults, 'result'>
    & { error?: Maybe<Pick<StandardMutationError, 'title' | 'code'>> }
  ) };

export type AddPricingMutationVariables = Exact<{
  record: PricingInput;
}>;


export type AddPricingMutation = { addPricing: { data?: Maybe<Pick<Pricing, '_id' | 'categoryId' | 'locationId' | 'price'>>, error?: Maybe<Pick<StandardMutationError, 'code'>> } };

export type ApproveCampaignMutationVariables = Exact<{
  _id: Scalars['String'];
  allowed: Scalars['Boolean'];
  locations?: Maybe<Array<CampaignLocationInput>>;
}>;


export type ApproveCampaignMutation = { approveCampaign: { data?: Maybe<FullCampaignFragment>, error?: Maybe<Pick<StandardMutationError, 'code'>> } };

export type ChangePasswordMutationVariables = Exact<{
  oldPass: Scalars['String'];
  newPass: Scalars['String'];
  confirmPass: Scalars['String'];
}>;


export type ChangePasswordMutation = { changePassword: { data?: Maybe<FullUserFragment>, error?: Maybe<Pick<StandardMutationError, 'code'>> } };

export type CreateBannerMutationVariables = Exact<{
  record: BannerInput;
}>;


export type CreateBannerMutation = { createBanner: { data?: Maybe<BasicBannerFragment>, error?: Maybe<Pick<StandardMutationError, 'title' | 'code'>> } };

export type CreateBannerTempMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateBannerTempMutation = { createBannerTemp: { data?: Maybe<BasicBannerTempFragment>, error?: Maybe<Pick<StandardMutationError, 'title' | 'code'>> } };

export type CreateBannerTemplateMutationVariables = Exact<{
  record: BannerTemplateInput;
}>;


export type CreateBannerTemplateMutation = { createBannerTemplate: { data?: Maybe<BannerTemplateFragment>, error?: Maybe<Pick<StandardMutationError, 'title' | 'code'>> } };

export type CreateBasicLocationMutationVariables = Exact<{
  record: LocationBasicInput;
}>;


export type CreateBasicLocationMutation = { createBasicLocation: { data?: Maybe<FullLocationFragment>, error?: Maybe<Pick<StandardMutationError, 'code'>> } };

export type CreateCampaignMutationVariables = Exact<{
  record: CampaignInput;
}>;


export type CreateCampaignMutation = { createCampaign: { data?: Maybe<FullCampaignFragment>, error?: Maybe<Pick<StandardMutationError, 'code'>> } };

export type CreateCampaignMappingMutationVariables = Exact<{
  record: CampaignMappingInput;
}>;


export type CreateCampaignMappingMutation = { createCampaignMapping: { data?: Maybe<FullCampaignMappingFragment>, error?: Maybe<Pick<StandardMutationError, 'code'>> } };

export type CreateCampaignOnlineMutationVariables = Exact<{
  data: BookingOnlineArgs;
}>;


export type CreateCampaignOnlineMutation = { createCampaignOnline: { data?: Maybe<BasicCampaignFragment>, error?: Maybe<Pick<StandardMutationError, 'code'>> } };

export type CreateCommonMutationVariables = Exact<{
  record: CommonInput;
}>;


export type CreateCommonMutation = { createCommon: { data?: Maybe<FullCommonFragment>, error?: Maybe<Pick<StandardMutationError, 'code'>> } };

export type CreateCustomerMutationVariables = Exact<{
  record: CustomerInput;
}>;


export type CreateCustomerMutation = { createCustomer: { data?: Maybe<BasicCustomerFragment>, error?: Maybe<Pick<StandardMutationError, 'code'>> } };

export type CreateCustomerTypeMutationVariables = Exact<{
  record: CustomerTypeInput;
}>;


export type CreateCustomerTypeMutation = { createCustomerType: { data?: Maybe<BasicCustomerTypeFragment>, error?: Maybe<Pick<StandardMutationError, 'code'>> } };

export type CreateEquipmentMutationVariables = Exact<{
  record: EquipmentInput;
}>;


export type CreateEquipmentMutation = { createEquipment: { data?: Maybe<FullEquipmentFragment>, error?: Maybe<Pick<StandardMutationError, 'code'>> } };

export type CreateErrorLogMutationVariables = Exact<{
  record: ErrorLogInput;
}>;


export type CreateErrorLogMutation = { createErrorLog: { data?: Maybe<FullErrorLogFragment>, error?: Maybe<Pick<StandardMutationError, 'code'>> } };

export type CreateFileMutationVariables = Exact<{
  bannerId?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
}>;


export type CreateFileMutation = { createFile: { data?: Maybe<Pick<File, 'id' | 'path'>>, error?: Maybe<Pick<StandardMutationError, 'title' | 'code'>> } };

export type CreateImageMutationVariables = Exact<{
  record: ImageInput;
}>;


export type CreateImageMutation = { createImage: { data?: Maybe<ImageFragment>, error?: Maybe<Pick<StandardMutationError, 'title' | 'code'>> } };

export type CreateNotificationMutationVariables = Exact<{
  record: NotificationInput;
}>;


export type CreateNotificationMutation = { createNotification: { data?: Maybe<FullNotificationFragment>, error?: Maybe<Pick<StandardMutationError, 'title' | 'code'>> } };

export type CreatePaymentMutationVariables = Exact<{
  record: PaymentInput;
}>;


export type CreatePaymentMutation = { createPayment: { data?: Maybe<PaymentFragment>, error?: Maybe<Pick<StandardMutationError, 'code'>> } };

export type CreatePlanMutationVariables = Exact<{
  record: PlanInput;
}>;


export type CreatePlanMutation = { createPlan: { data?: Maybe<PlanFragment>, error?: Maybe<Pick<StandardMutationError, 'title' | 'code'>> } };

export type CreateProfileMutationVariables = Exact<{
  record: ProfileInput;
}>;


export type CreateProfileMutation = { createProfile: { data?: Maybe<BasicProfileFragment>, error?: Maybe<Pick<StandardMutationError, 'code'>> } };

export type CreateRegionMutationVariables = Exact<{
  record: RegionInput;
}>;


export type CreateRegionMutation = { createRegion: { data?: Maybe<FullRegionFragment>, error?: Maybe<Pick<StandardMutationError, 'code'>> } };

export type CreateRevenueMutationVariables = Exact<{
  campaignId: Scalars['String'];
}>;


export type CreateRevenueMutation = { createRevenue: { data?: Maybe<FullRevenueFragment>, error?: Maybe<Pick<StandardMutationError, 'title' | 'code'>> } };

export type CreateTwoFactorAuthenticationMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateTwoFactorAuthenticationMutation = { createTwoFactorAuthentication: { data?: Maybe<FullTwoFactorAuthFragment>, error?: Maybe<Pick<StandardMutationError, 'code'>> } };

export type CreateUserMutationVariables = Exact<{
  record: UserInput;
}>;


export type CreateUserMutation = { createUser: { data?: Maybe<FullUserFragment>, error?: Maybe<Pick<StandardMutationError, 'code'>> } };

export type CreateUserGuideMutationVariables = Exact<{
  title: Scalars['String'];
  content: Scalars['String'];
  brand: Scalars['String'];
  meta: Scalars['JSON'];
}>;


export type CreateUserGuideMutation = { createUserGuide: { data?: Maybe<Pick<UserGuide, 'meta' | 'brand' | '_id' | 'content' | 'title'>>, error?: Maybe<Pick<StandardMutationError, 'code' | 'title'>> } };

export type DeleteAllNotificationMutationVariables = Exact<{ [key: string]: never; }>;


export type DeleteAllNotificationMutation = Pick<Mutation, 'deleteAllNotification'>;

export type DeleteCampaignByIdMutationVariables = Exact<{
  _id: Scalars['String'];
}>;


export type DeleteCampaignByIdMutation = { deleteCampaignById: { data?: Maybe<FullCampaignFragment>, error?: Maybe<Pick<StandardMutationError, 'title' | 'code'>> } };

export type DisableCampaignMutationVariables = Exact<{
  _id: Scalars['String'];
  duration?: Maybe<Scalars['Float']>;
}>;


export type DisableCampaignMutation = { disableCampaign: { data?: Maybe<FullCampaignFragment>, error?: Maybe<Pick<StandardMutationError, 'title' | 'code'>> } };

export type DisableTwoFactorAuthenticationMutationVariables = Exact<{ [key: string]: never; }>;


export type DisableTwoFactorAuthenticationMutation = { disableTwoFactorAuthentication: { data?: Maybe<FullTwoFactorAuthFragment>, error?: Maybe<Pick<StandardMutationError, 'code'>> } };

export type EnableCampaignMutationVariables = Exact<{
  _id: Scalars['String'];
}>;


export type EnableCampaignMutation = { enableCampaign: { data?: Maybe<FullCampaignFragment>, error?: Maybe<Pick<StandardMutationError, 'title' | 'code'>> } };

export type EnableTwoFactorAuthenticationMutationVariables = Exact<{ [key: string]: never; }>;


export type EnableTwoFactorAuthenticationMutation = { enableTwoFactorAuthentication: { data?: Maybe<FullTwoFactorAuthFragment>, error?: Maybe<Pick<StandardMutationError, 'code'>> } };

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = { login: (
    Pick<LoginPayload, 'authToken'>
    & { data?: Maybe<FullUserFragment>, error?: Maybe<Pick<StandardMutationError, 'code' | 'message'>> }
  ) };

export type LoginTwoFactorAuthMutationVariables = Exact<{
  userId: Scalars['String'];
  password: Scalars['String'];
  code: Scalars['String'];
}>;


export type LoginTwoFactorAuthMutation = { loginTwoFactorAuth: (
    Pick<LoginPayload, 'authToken'>
    & { data?: Maybe<FullUserFragment>, error?: Maybe<Pick<StandardMutationError, 'code'>> }
  ) };

export type LogLogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogLogoutMutation = { logLogout: { data?: Maybe<LogsFragment>, error?: Maybe<Pick<StandardMutationError, 'code' | 'message'>> } };

export type RejectPaymentMutationVariables = Exact<{
  _id: Scalars['String'];
  rejectReason?: Maybe<Scalars['String']>;
}>;


export type RejectPaymentMutation = { rejectPayment: { data?: Maybe<PaymentFragment>, error?: Maybe<Pick<StandardMutationError, 'title' | 'code'>> } };

export type RemoveBannerByIdMutationVariables = Exact<{
  _id: Scalars['String'];
}>;


export type RemoveBannerByIdMutation = { removeBannerById: { data?: Maybe<BasicBannerFragment>, error?: Maybe<Pick<StandardMutationError, 'title' | 'code'>> } };

export type RemoveBannerTempByIdMutationVariables = Exact<{
  _id: Scalars['String'];
}>;


export type RemoveBannerTempByIdMutation = { removeBannerTempById: { data?: Maybe<BasicBannerTempFragment>, error?: Maybe<Pick<StandardMutationError, 'title' | 'code'>> } };

export type RemoveBannerTemplateByIdMutationVariables = Exact<{
  _id: Scalars['String'];
}>;


export type RemoveBannerTemplateByIdMutation = { removeBannerTemplateById: { data?: Maybe<BannerTemplateFragment>, error?: Maybe<Pick<StandardMutationError, 'title' | 'code'>> } };

export type RemoveCommonByIdMutationVariables = Exact<{
  _id: Scalars['String'];
}>;


export type RemoveCommonByIdMutation = { removeCommonById: { data?: Maybe<FullCommonFragment>, error?: Maybe<Pick<StandardMutationError, 'code'>> } };

export type RemoveCustomerTypeByIdMutationVariables = Exact<{
  _id: Scalars['String'];
}>;


export type RemoveCustomerTypeByIdMutation = { removeCustomerTypeById: { data?: Maybe<BasicCustomerTypeFragment>, error?: Maybe<Pick<StandardMutationError, 'code'>> } };

export type RemoveEquipmentByIdMutationVariables = Exact<{
  _id: Scalars['String'];
}>;


export type RemoveEquipmentByIdMutation = { removeEquipmentById: { data?: Maybe<FullEquipmentFragment>, error?: Maybe<Pick<StandardMutationError, 'code'>> } };

export type RemoveFileByIdMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemoveFileByIdMutation = { removeFileById: { data?: Maybe<Pick<File, 'id'>>, error?: Maybe<Pick<StandardMutationError, 'title' | 'code'>> } };

export type RemoveImageByIdMutationVariables = Exact<{
  _id: Scalars['String'];
}>;


export type RemoveImageByIdMutation = { removeImageById: { data?: Maybe<ImageFragment>, error?: Maybe<Pick<StandardMutationError, 'title' | 'code'>> } };

export type RemoveLocationByIdMutationVariables = Exact<{
  _id: Scalars['String'];
}>;


export type RemoveLocationByIdMutation = { removeLocationById: { data?: Maybe<FullLocationFragment>, error?: Maybe<Pick<StandardMutationError, 'code'>> } };

export type RemoveMessageByIdMutationVariables = Exact<{
  _id: Scalars['String'];
}>;


export type RemoveMessageByIdMutation = { removeMessageById: { data?: Maybe<FullMessageFragment>, error?: Maybe<Pick<StandardMutationError, 'title' | 'code'>> } };

export type RemoveNotificationByIdMutationVariables = Exact<{
  _id: Scalars['String'];
}>;


export type RemoveNotificationByIdMutation = { removeNotificationById: { data?: Maybe<BasicNotificationFragment>, error?: Maybe<Pick<StandardMutationError, 'title' | 'code'>> } };

export type RemovePaymentByIdMutationVariables = Exact<{
  _id: Scalars['String'];
}>;


export type RemovePaymentByIdMutation = { removePaymentById: { data?: Maybe<PaymentFragment>, error?: Maybe<Pick<StandardMutationError, 'code'>> } };

export type RemovePlanByIdMutationVariables = Exact<{
  _id: Scalars['String'];
}>;


export type RemovePlanByIdMutation = { removePlanById: { data?: Maybe<PlanFragment>, error?: Maybe<Pick<StandardMutationError, 'title' | 'code'>> } };

export type RemovePricingMutationVariables = Exact<{
  categoryId?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
}>;


export type RemovePricingMutation = { removePricing: { data?: Maybe<Pick<Pricing, '_id' | 'categoryId' | 'locationId' | 'price'>>, error?: Maybe<Pick<StandardMutationError, 'code'>> } };

export type RemoveProfileByIdMutationVariables = Exact<{
  _id: Scalars['String'];
}>;


export type RemoveProfileByIdMutation = { removeProfileById: { data?: Maybe<BasicProfileFragment>, error?: Maybe<Pick<StandardMutationError, 'code' | 'title'>> } };

export type RemoveRegionByIdMutationVariables = Exact<{
  _id: Scalars['String'];
}>;


export type RemoveRegionByIdMutation = { removeRegionById: { data?: Maybe<FullRegionFragment>, error?: Maybe<Pick<StandardMutationError, 'code' | 'title'>> } };

export type RemoveUserByIdMutationVariables = Exact<{
  _id: Scalars['String'];
}>;


export type RemoveUserByIdMutation = { removeUserById: { data?: Maybe<FullUserFragment>, error?: Maybe<Pick<StandardMutationError, 'code' | 'title'>> } };

export type RemoveUserGuideByIdMutationVariables = Exact<{
  _id: Scalars['String'];
}>;


export type RemoveUserGuideByIdMutation = { removeUserGuideById: { data?: Maybe<Pick<UserGuide, '_id' | 'title' | 'content'>>, error?: Maybe<Pick<StandardMutationError, 'code' | 'title'>> } };

export type ResetPasswordMutationVariables = Exact<{
  userId: Scalars['String'];
  newPass?: Maybe<Scalars['String']>;
}>;


export type ResetPasswordMutation = { resetPassword: { data?: Maybe<FullUserFragment>, error?: Maybe<Pick<StandardMutationError, 'code' | 'title'>> } };

export type RestorePasswordMutationVariables = Exact<{
  _id: Scalars['String'];
  newPass: Scalars['String'];
  verifyPass: Scalars['String'];
}>;


export type RestorePasswordMutation = { restorePassword: { data?: Maybe<FullUserFragment>, error?: Maybe<Pick<StandardMutationError, 'code' | 'title'>> } };

export type SendEmailVerifyMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type SendEmailVerifyMutation = { sendEmailVerify: { data?: Maybe<Pick<User, 'name'>>, error?: Maybe<Pick<StandardMutationError, 'code'>> } };

export type SendMailByLicenseMutationVariables = Exact<{
  id: Scalars['String'];
  email: Scalars['String'];
}>;


export type SendMailByLicenseMutation = { sendMailByLicense: { data?: Maybe<Pick<User, 'email'>>, error?: Maybe<Pick<StandardMutationError, 'title' | 'code'>> } };

export type SetActiveMutationVariables = Exact<{
  email: Scalars['String'];
  active: Scalars['Boolean'];
}>;


export type SetActiveMutation = { setActive: { data?: Maybe<Pick<User, 'name' | 'email'>>, error?: Maybe<Pick<StandardMutationError, 'title' | 'code'>> } };

export type SetScheduleMutationVariables = Exact<{
  _id: Scalars['String'];
  where: SetScheduleArgs;
}>;


export type SetScheduleMutation = { setSchedule: { data?: Maybe<Pick<Campaign, 'scheduleFrom' | 'scheduleTo'>>, error?: Maybe<Pick<StandardMutationError, 'title' | 'code'>> } };

export type SetTimesMutationVariables = Exact<{
  _id: Scalars['String'];
  times: Scalars['String'];
}>;


export type SetTimesMutation = { setTimes: { data?: Maybe<Pick<Campaign, 'times'>>, error?: Maybe<Pick<StandardMutationError, 'title' | 'code'>> } };

export type SetValueVerifyEmailMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type SetValueVerifyEmailMutation = { setValueVerifyEmail: { data?: Maybe<Pick<User, 'name' | 'email'>>, error?: Maybe<Pick<StandardMutationError, 'title' | 'code'>> } };

export type SignUpMutationVariables = Exact<{
  record: UserRegisterInput;
}>;


export type SignUpMutation = { signUp: { data?: Maybe<FullUserFragment>, error?: Maybe<Pick<StandardMutationError, 'code'>> } };

export type StopBookingMutationVariables = Exact<{
  idFilterArg: IdFilterInput;
}>;


export type StopBookingMutation = { stopBooking: { error?: Maybe<Pick<StandardMutationError, 'code'>>, data?: Maybe<FullCampaignMappingFragment> } };

export type UpdateAdvanceCampaignMutationVariables = Exact<{
  _id: Scalars['String'];
  record: AdvanceCampaignInfoArgs;
}>;


export type UpdateAdvanceCampaignMutation = { updateAdvanceCampaign: { data?: Maybe<AdvanceCampaignFormFragment>, error?: Maybe<Pick<StandardMutationError, 'code'>> } };

export type UpdateAdvanceLocationMutationVariables = Exact<{
  _id: Scalars['String'];
  record: AdvanceLocationUpdateArgs;
}>;


export type UpdateAdvanceLocationMutation = { updateAdvanceLocation: { data?: Maybe<AdvanceFormFragment>, error?: Maybe<Pick<StandardMutationError, 'code'>> } };

export type UpdateBannerActiveMutationVariables = Exact<{
  _id: Scalars['String'];
}>;


export type UpdateBannerActiveMutation = { updateBannerActive: { data?: Maybe<BasicBannerFragment>, error?: Maybe<Pick<StandardMutationError, 'title' | 'code'>> } };

export type UpdateBannerByIdMutationVariables = Exact<{
  _id: Scalars['String'];
  record: BannerUpdateArg;
}>;


export type UpdateBannerByIdMutation = { updateBannerById: { data?: Maybe<BasicBannerFragment>, error?: Maybe<Pick<StandardMutationError, 'title' | 'code'>> } };

export type UpdateBannerTempByIdMutationVariables = Exact<{
  _id: Scalars['String'];
  record: BannerTempUpdateArg;
}>;


export type UpdateBannerTempByIdMutation = { updateBannerTempById: { data?: Maybe<BasicBannerTempFragment>, error?: Maybe<Pick<StandardMutationError, 'title' | 'code'>> } };

export type UpdateBannerTemplateByIdMutationVariables = Exact<{
  _id: Scalars['String'];
  record: BannerTemplateUpdateArg;
}>;


export type UpdateBannerTemplateByIdMutation = { updateBannerTemplateById: { data?: Maybe<BannerTemplateFragment>, error?: Maybe<Pick<StandardMutationError, 'title' | 'code'>> } };

export type UpdateBasicLocationMutationVariables = Exact<{
  _id: Scalars['String'];
  record: LocationBasicInput;
}>;


export type UpdateBasicLocationMutation = { updateBasicLocation: { data?: Maybe<BasicFormFragment>, error?: Maybe<Pick<StandardMutationError, 'code'>> } };

export type UpdateBookingMutationVariables = Exact<{
  campaignMapping: CampaignMappingInput;
}>;


export type UpdateBookingMutation = { updateBooking: (
    Pick<UpdateBookingResults, 'result'>
    & { error?: Maybe<Pick<StandardMutationError, 'code' | 'title'>> }
  ) };

export type UpdateCalendarBookingMutationVariables = Exact<{
  booking: Array<BookingInput>;
}>;


export type UpdateCalendarBookingMutation = { updateCalendarBooking: (
    Pick<UpdateBookingResults, 'result'>
    & { error?: Maybe<Pick<StandardMutationError, 'code' | 'title'>> }
  ) };

export type UpdateCampaignByIdMutationVariables = Exact<{
  _id: Scalars['String'];
  record: CampaignUpdateArg;
}>;


export type UpdateCampaignByIdMutation = { updateCampaignById: { data?: Maybe<FullCampaignFragment>, error?: Maybe<Pick<StandardMutationError, 'code'>> } };

export type UpdateCampaignConfigByIdMutationVariables = Exact<{
  id: Scalars['String'];
  record: CampaignConfigUpdateArgs;
}>;


export type UpdateCampaignConfigByIdMutation = { updateConfig: { data?: Maybe<CampaignConfigFragment>, error?: Maybe<Pick<StandardMutationError, 'title' | 'code'>> } };

export type UpdateCampaignMappingByIdMutationVariables = Exact<{
  _id: Scalars['String'];
  record: CampaignMappingUpdateArg;
}>;


export type UpdateCampaignMappingByIdMutation = { updateCampaignMappingById: { data?: Maybe<FullCampaignMappingFragment>, error?: Maybe<Pick<StandardMutationError, 'code'>> } };

export type UpdateCampaignPriorityMutationVariables = Exact<{
  old: Array<CmPositionInput>;
  current: Array<CmPositionInput>;
  locationId: Scalars['String'];
}>;


export type UpdateCampaignPriorityMutation = { updateCampaignPriority: (
    Pick<CampaignMappingPayload, 'success'>
    & { error?: Maybe<Pick<StandardMutationError, 'code'>> }
  ) };

export type UpdateCommonByIdMutationVariables = Exact<{
  _id: Scalars['String'];
  record: CommonUpdateArg;
}>;


export type UpdateCommonByIdMutation = { updateCommonById: { data?: Maybe<FullCommonFragment>, error?: Maybe<Pick<StandardMutationError, 'code'>> } };

export type UpdateCustomerByIdMutationVariables = Exact<{
  _id: Scalars['String'];
  record: CustomerUpdateArg;
}>;


export type UpdateCustomerByIdMutation = { updateCustomerById: { data?: Maybe<BasicCustomerFragment>, error?: Maybe<Pick<StandardMutationError, 'code'>> } };

export type UpdateCustomerTypeByIdMutationVariables = Exact<{
  _id: Scalars['String'];
  record: CustomerTypeUpdateArg;
}>;


export type UpdateCustomerTypeByIdMutation = { updateCustomerTypeById: { data?: Maybe<BasicCustomerTypeFragment>, error?: Maybe<Pick<StandardMutationError, 'code' | 'title'>> } };

export type UpdateEquipmentByIdMutationVariables = Exact<{
  _id: Scalars['String'];
  record: EquipmentUpdateArgs;
}>;


export type UpdateEquipmentByIdMutation = { updateEquipmentById: { data?: Maybe<FullEquipmentFragment>, error?: Maybe<Pick<StandardMutationError, 'code'>> } };

export type SendMailForgotPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type SendMailForgotPasswordMutation = { sendMailForgotPassword: { data?: Maybe<FullUserFragment>, error?: Maybe<Pick<StandardMutationError, 'code' | 'title'>> } };

export type UpdateImageByIdMutationVariables = Exact<{
  _id: Scalars['String'];
  record: ImageUpdateArg;
}>;


export type UpdateImageByIdMutation = { updateImageById: { data?: Maybe<ImageFragment>, error?: Maybe<Pick<StandardMutationError, 'title' | 'code'>> } };

export type UpdateLocationByIdMutationVariables = Exact<{
  _id: Scalars['String'];
  record: LocationUpdateArg;
}>;


export type UpdateLocationByIdMutation = { updateLocationById: { data?: Maybe<FullLocationFragment>, error?: Maybe<Pick<StandardMutationError, 'code'>> } };

export type UpdateMultiLocationsMutationVariables = Exact<{
  campaign: Array<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  locations?: Maybe<Array<ResultInput>>;
}>;


export type UpdateMultiLocationsMutation = { updateMultiLocations: Pick<UpdateMultiResult, 'success'> };

export type UpdateNotificationByIdMutationVariables = Exact<{
  _id: Scalars['String'];
  record: NotificationUpdateArg;
}>;


export type UpdateNotificationByIdMutation = { updateNotificationById: { data?: Maybe<BasicNotificationFragment>, error?: Maybe<Pick<StandardMutationError, 'code'>> } };

export type UpdatePlanByIdMutationVariables = Exact<{
  _id: Scalars['String'];
  record: PlanUpdateArg;
}>;


export type UpdatePlanByIdMutation = { updatePlanById: { data?: Maybe<PlanFragment>, error?: Maybe<Pick<StandardMutationError, 'title' | 'code'>> } };

export type UpdateProfileByIdMutationVariables = Exact<{
  _id: Scalars['String'];
  record: ProfileUpdateArg;
}>;


export type UpdateProfileByIdMutation = { updateProfileById: { data?: Maybe<BasicProfileFragment>, error?: Maybe<Pick<StandardMutationError, 'code'>> } };

export type UpdateRegionByIdMutationVariables = Exact<{
  _id: Scalars['String'];
  record: RegionUpdateArg;
}>;


export type UpdateRegionByIdMutation = { updateRegionById: { data?: Maybe<FullRegionFragment>, error?: Maybe<Pick<StandardMutationError, 'code'>> } };

export type UpdateRevenueByIdMutationVariables = Exact<{
  _id: Scalars['String'];
  record: RevenueUpdateArg;
}>;


export type UpdateRevenueByIdMutation = { updateRevenueById: { data?: Maybe<FullRevenueFragment>, error?: Maybe<Pick<StandardMutationError, 'title' | 'code'>> } };

export type UpdateRoleDescriptionByIdMutationVariables = Exact<{
  _id: Scalars['String'];
  description: Scalars['String'];
}>;


export type UpdateRoleDescriptionByIdMutation = { updateRoleDescriptionById: { data?: Maybe<FullRoleFragment>, error?: Maybe<Pick<StandardMutationError, 'code'>> } };

export type UpdateSaleInfoMutationVariables = Exact<{
  _id: Scalars['String'];
  record: SaleInfoInput;
}>;


export type UpdateSaleInfoMutation = { updateSaleInfo: { data?: Maybe<SaleInfoFormFragment>, error?: Maybe<Pick<StandardMutationError, 'code'>> } };

export type UpdateSystemInfoMutationVariables = Exact<{
  _id: Scalars['String'];
  record: SystemInfoInput;
}>;


export type UpdateSystemInfoMutation = { updateSystemInfo: { data?: Maybe<SystemInfoFormFragment>, error?: Maybe<Pick<StandardMutationError, 'code'>> } };

export type UpdateUserByIdMutationVariables = Exact<{
  _id: Scalars['String'];
  record: UserUpdateArg;
}>;


export type UpdateUserByIdMutation = { updateUserById: { data?: Maybe<Pick<User, 'name' | 'email' | 'phone' | 'address' | 'active' | 'emailVerified' | 'profileId' | 'ownerType' | 'twoFactorAuthId'>>, error?: Maybe<Pick<StandardMutationError, 'code' | 'title'>> } };

export type UpdateUserGuideByIdMutationVariables = Exact<{
  _id: Scalars['String'];
  title: Scalars['String'];
  content: Scalars['String'];
  brand: Scalars['String'];
}>;


export type UpdateUserGuideByIdMutation = { updateUserGuideById: { data?: Maybe<Pick<UserGuide, 'meta' | 'brand' | '_id' | 'title' | 'content'>>, error?: Maybe<Pick<StandardMutationError, 'code' | 'title'>> } };

export type UpdateUserInfoByIdMutationVariables = Exact<{
  _id: Scalars['String'];
  record: UserUpdateInfoArgs;
}>;


export type UpdateUserInfoByIdMutation = { updateUserInfoById: { data?: Maybe<BasicUserFragment>, error?: Maybe<Pick<StandardMutationError, 'code'>> } };

export type UpgradeLicenseMutationVariables = Exact<{
  _id: Scalars['String'];
}>;


export type UpgradeLicenseMutation = { upgradeLicense: { data?: Maybe<FullUserFragment>, error?: Maybe<Pick<StandardMutationError, 'code' | 'title'>> } };

export type UpsertAssignmentMutationVariables = Exact<{
  model: Scalars['String'];
  userId: Scalars['String'];
  resourceIds: Array<Scalars['String']>;
}>;


export type UpsertAssignmentMutation = { upsertAssignment: { data?: Maybe<Array<BasicOwnerFragment>>, error?: Maybe<Pick<StandardMutationError, 'code' | 'title'>> } };

export type VerifyEmailMutationVariables = Exact<{
  key: Scalars['String'];
}>;


export type VerifyEmailMutation = Pick<Mutation, 'verifyEmail'>;

export type VerifyEmailLicenseMutationVariables = Exact<{
  currentUserId: Scalars['String'];
  email: Scalars['String'];
  key: Scalars['String'];
}>;


export type VerifyEmailLicenseMutation = { verifyEmailLicense: { data?: Maybe<Pick<User, 'email' | 'name'>>, error?: Maybe<Pick<StandardMutationError, 'title' | 'code'>> } };

export type VerifyPaymentMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type VerifyPaymentMutation = { verifyPayment: { data?: Maybe<PaymentFragment>, error?: Maybe<Pick<StandardMutationError, 'title' | 'code'>> } };

export type VerifyRevenueMutationVariables = Exact<{
  _id: Scalars['String'];
}>;


export type VerifyRevenueMutation = { verifyRevenue: { data?: Maybe<FullRevenueFragment>, error?: Maybe<Pick<StandardMutationError, 'title' | 'code'>> } };

export type CountTrafficDataQueryVariables = Exact<{
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
}>;


export type CountTrafficDataQuery = { CountTrafficData: { data?: Maybe<Pick<DataVal, 'value'>> } };

export type CalculateCampaignOnlineKpiQueryVariables = Exact<{
  args: CalculateBookingOnlineKpiArgs;
}>;


export type CalculateCampaignOnlineKpiQuery = { calculateCampaignOnlineKpi: { data?: Maybe<(
      Pick<CalculateBookingOnlineKpiResult, 'budget'>
      & { detail: Array<Pick<CalculatedBookingOnlineKpiItem, '_id' | 'price' | 'available' | 'budget' | 'kpi'>> }
    )>, error?: Maybe<Pick<StandardMutationError, 'code'>> } };

export type CheckRecaptchaQueryVariables = Exact<{
  value: Scalars['String'];
}>;


export type CheckRecaptchaQuery = Pick<Query, 'checkRecaptcha'>;

export type CountBalanceHistoryQueryVariables = Exact<{
  where?: Maybe<BalanceHistoryFilter>;
}>;


export type CountBalanceHistoryQuery = Pick<Query, 'countBalanceHistory'>;

export type CountBannerQueryVariables = Exact<{
  where?: Maybe<BannerFilter>;
}>;


export type CountBannerQuery = Pick<Query, 'countBanner'>;

export type CountBannerTemplateQueryVariables = Exact<{
  where?: Maybe<BannerTemplateFilter>;
}>;


export type CountBannerTemplateQuery = Pick<Query, 'countBannerTemplate'>;

export type CountCampaignQueryVariables = Exact<{
  where?: Maybe<CampaignFilter>;
}>;


export type CountCampaignQuery = Pick<Query, 'countCampaign'>;

export type CountCampaignMappingQueryVariables = Exact<{
  where?: Maybe<CampaignMappingFilter>;
}>;


export type CountCampaignMappingQuery = Pick<Query, 'countCampaignMapping'>;

export type CountCommonQueryVariables = Exact<{
  where?: Maybe<CommonFilter>;
}>;


export type CountCommonQuery = Pick<Query, 'countCommon'>;

export type CountCustomerQueryVariables = Exact<{
  where?: Maybe<CustomerFilter>;
}>;


export type CountCustomerQuery = Pick<Query, 'countCustomer'>;

export type CountEquipmentQueryVariables = Exact<{
  where?: Maybe<EquipmentFilter>;
}>;


export type CountEquipmentQuery = Pick<Query, 'countEquipment'>;

export type CountFullLogsQueryVariables = Exact<{
  where?: Maybe<LogsFilter>;
}>;


export type CountFullLogsQuery = Pick<Query, 'countFullLogs'>;

export type CountImageQueryVariables = Exact<{
  where?: Maybe<ImageFilter>;
}>;


export type CountImageQuery = Pick<Query, 'countImage'>;

export type CountLocationQueryVariables = Exact<{
  where?: Maybe<LocationFilter>;
}>;


export type CountLocationQuery = Pick<Query, 'countLocation'>;

export type CountLogsQueryVariables = Exact<{
  where?: Maybe<LogsFilter>;
}>;


export type CountLogsQuery = Pick<Query, 'countLogs'>;

export type CountMessageQueryVariables = Exact<{
  where?: Maybe<MessageFilter>;
}>;


export type CountMessageQuery = Pick<Query, 'countMessage'>;

export type CountNotificationQueryVariables = Exact<{
  where?: Maybe<NotificationFilter>;
}>;


export type CountNotificationQuery = Pick<Query, 'countNotification'>;

export type CountOrderQueryVariables = Exact<{
  where?: Maybe<OrderFilter>;
}>;


export type CountOrderQuery = Pick<Query, 'countOrder'>;

export type CountPaymentQueryVariables = Exact<{
  where?: Maybe<PaymentFilter>;
}>;


export type CountPaymentQuery = Pick<Query, 'countPayment'>;

export type CountPaymentForUserQueryVariables = Exact<{
  where?: Maybe<PaymentFilter>;
}>;


export type CountPaymentForUserQuery = Pick<Query, 'countPaymentForUser'>;

export type CountPlanQueryVariables = Exact<{
  where?: Maybe<PlanFilter>;
}>;


export type CountPlanQuery = Pick<Query, 'countPlan'>;

export type CountProfileQueryVariables = Exact<{
  where?: Maybe<ProfileFilter>;
}>;


export type CountProfileQuery = Pick<Query, 'countProfile'>;

export type CountRegionQueryVariables = Exact<{
  where?: Maybe<RegionFilter>;
}>;


export type CountRegionQuery = Pick<Query, 'countRegion'>;

export type CountReportLocationDataQueryVariables = Exact<{
  where?: Maybe<RpLocationWhereFilterInput>;
  dataType?: Maybe<DataType>;
  skip?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
}>;


export type CountReportLocationDataQuery = Pick<Query, 'countReportLocationData'>;

export type CountReportOnlineSessionQueryVariables = Exact<{ [key: string]: never; }>;


export type CountReportOnlineSessionQuery = Pick<Query, 'countReportOnlineSession'>;

export type CountRevenueQueryVariables = Exact<{
  where?: Maybe<RevenueFilter>;
}>;


export type CountRevenueQuery = Pick<Query, 'countRevenue'>;

export type CountUserQueryVariables = Exact<{
  where?: Maybe<UserFilter>;
}>;


export type CountUserQuery = Pick<Query, 'countUser'>;

export type CountUserGuideQueryVariables = Exact<{
  where?: Maybe<UserGuideFilter>;
}>;


export type CountUserGuideQuery = Pick<Query, 'countUserGuide'>;

export type CountManyUserWithOwnerQueryVariables = Exact<{
  limit?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  where?: Maybe<UserOwnerWhereArgs>;
}>;


export type CountManyUserWithOwnerQuery = Pick<Query, 'countManyUserWithOwner'>;

export type FetchCampaignMappingQueryVariables = Exact<{
  campaignId: Scalars['String'];
}>;


export type FetchCampaignMappingQuery = { fetchCampaignMapping: Array<(
    Pick<CampaignMapping, '_id' | 'campaignId' | 'locationId' | 'status' | 'reportLocationId' | 'scheduleFrom' | 'scheduleTo' | 'priority' | 'kpi' | 'times' | 'active'>
    & { location?: Maybe<Pick<Location, 'name' | 'categoryId'>>, reportLocation?: Maybe<Pick<Location, '_id' | 'name'>>, stats?: Maybe<Pick<Statistic, 'kpi' | 'click' | 'kpiCompleted' | 'kpiRemaining'>>, todayStats?: Maybe<Pick<Statistic, 'kpi' | 'click' | 'kpiCompleted'>>, yesterdayStats?: Maybe<Pick<Statistic, 'kpi' | 'click' | 'kpiCompleted'>> }
  )> };

export type FetchUserFormQueryVariables = Exact<{
  _id: Scalars['String'];
}>;


export type FetchUserFormQuery = { findUserById?: Maybe<UserFormFragment> };

export type FetchDetailInfoQueryVariables = Exact<{
  _id: Scalars['String'];
}>;


export type FetchDetailInfoQuery = { findLocationById?: Maybe<DetailLocationInfoFragment> };

export type FetchAdvanceFormDataQueryVariables = Exact<{
  _id: Scalars['String'];
}>;


export type FetchAdvanceFormDataQuery = { findLocationById?: Maybe<AdvanceFormFragment> };

export type FetchBasicFormDataQueryVariables = Exact<{
  _id: Scalars['String'];
}>;


export type FetchBasicFormDataQuery = { findLocationById?: Maybe<BasicFormFragment> };

export type FetchLocationCampaignMappingQueryVariables = Exact<{
  locationId: Array<Scalars['String']>;
}>;


export type FetchLocationCampaignMappingQuery = { fetchLocationCampaignMapping: Array<Pick<LocationCampaignMapping, 'locationId' | 'campaignName' | 'campaignId' | 'scheduleFrom' | 'scheduleTo'>> };

export type FetchLocationMappingQueryVariables = Exact<{
  locationId: Scalars['String'];
}>;


export type FetchLocationMappingQuery = { fetchLocationMapping: Array<(
    Pick<CampaignMapping, '_id' | 'campaignId' | 'locationId' | 'status' | 'reportLocationId' | 'scheduleFrom' | 'scheduleTo' | 'priority' | 'kpi' | 'times' | 'active'>
    & { campaign?: Maybe<Pick<Campaign, '_id' | 'name'>>, stats?: Maybe<Pick<Statistic, 'kpi' | 'click' | 'kpiCompleted'>>, todayStats?: Maybe<Pick<Statistic, 'kpi' | 'click' | 'kpiCompleted'>>, yesterdayStats?: Maybe<Pick<Statistic, 'kpi' | 'click' | 'kpiCompleted'>> }
  )> };

export type FetchLocationsGridQueryVariables = Exact<{
  where?: Maybe<LocationFilter>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<LocationSort>;
}>;


export type FetchLocationsGridQuery = { findManyLocation: Array<LocationsGridFragment> };

export type FetchSaleInfoQueryVariables = Exact<{
  _id: Scalars['String'];
}>;


export type FetchSaleInfoQuery = { findLocationById?: Maybe<SaleInfoFormFragment> };

export type FetchSystemInfoQueryVariables = Exact<{
  _id: Scalars['String'];
}>;


export type FetchSystemInfoQuery = { findLocationById?: Maybe<SystemInfoFormFragment> };

export type FetchUserGridQueryVariables = Exact<{
  where?: Maybe<UserFilter>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<UserSort>;
}>;


export type FetchUserGridQuery = { findManyUser: Array<UserGridFragment> };

export type FindAreaQueryVariables = Exact<{ [key: string]: never; }>;


export type FindAreaQuery = { findArea: Array<FullRegionFragment> };

export type FindBannerTempByIdQueryVariables = Exact<{
  _id: Scalars['String'];
}>;


export type FindBannerTempByIdQuery = { findBannerTempById?: Maybe<BasicBannerTempFragment> };

export type FindCampaignConfigByIdQueryVariables = Exact<{
  _id: Scalars['String'];
}>;


export type FindCampaignConfigByIdQuery = { findCampaignById?: Maybe<CampaignConfigFragment> };

export type FindCampaignMappingByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type FindCampaignMappingByIdQuery = { findCampaignMappingById?: Maybe<FullCampaignMappingFragment> };

export type FindCategoryPriceQueryVariables = Exact<{ [key: string]: never; }>;


export type FindCategoryPriceQuery = { findCategoryPrice: Array<Pick<CategoryPrice, 'categoryId' | 'categoryName' | 'categoryIcon' | 'price'>> };

export type FindCityQueryVariables = Exact<{ [key: string]: never; }>;


export type FindCityQuery = { findCity: Array<FullRegionFragment> };

export type FindManyCommonQueryVariables = Exact<{
  where?: Maybe<CommonFilter>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<CommonSort>;
}>;


export type FindManyCommonQuery = { findManyCommon: Array<FullCommonFragment> };

export type FindCommonBannerQueryVariables = Exact<{
  where?: Maybe<BannerFilter>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<BannerSort>;
}>;


export type FindCommonBannerQuery = { findManyBanner: Array<CommonBannerFragment> };

export type FindCommonCampaignQueryVariables = Exact<{
  where?: Maybe<CampaignFilter>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<CampaignSort>;
}>;


export type FindCommonCampaignQuery = { findManyCampaign: Array<CommonCampaignFragment> };

export type FindCommonLocationQueryVariables = Exact<{
  where?: Maybe<LocationFilter>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<LocationSort>;
}>;


export type FindCommonLocationQuery = { findManyLocation: Array<CommonLocationFragment> };

export type FindCommonLocationsQueryVariables = Exact<{
  campaign: Array<Scalars['String']>;
}>;


export type FindCommonLocationsQuery = { findCommonLocations: Array<Pick<Result, 'locationId' | 'locationName'>> };

export type FindCommonTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type FindCommonTypesQuery = Pick<Query, 'findCommonTypes'>;

export type FindCustomerByIdQueryVariables = Exact<{
  _id: Scalars['String'];
}>;


export type FindCustomerByIdQuery = { findCustomerById?: Maybe<BasicCustomerFragment> };

export type FindManyCustomerTypeQueryVariables = Exact<{
  where?: Maybe<CustomerTypeFilter>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<CustomerTypeSort>;
}>;


export type FindManyCustomerTypeQuery = { findManyCustomerType: Array<BasicCustomerTypeFragment> };

export type CountCustomerTypeQueryVariables = Exact<{
  where?: Maybe<CustomerTypeFilter>;
}>;


export type CountCustomerTypeQuery = Pick<Query, 'countCustomerType'>;

export type FindDeviceDataByLocationQueryVariables = Exact<{
  categoryId?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
}>;


export type FindDeviceDataByLocationQuery = { findDeviceDataByLocation: { data?: Maybe<(
      Pick<EsDeviceData, 'total'>
      & { statistic?: Maybe<{ os?: Maybe<Pick<OsTracking, 'iOS' | 'Android' | 'Windows' | 'other'>>, device?: Maybe<Pick<DeviceTracking, 'Mobile' | 'PC' | 'Tablet'>> }> }
    )>, error?: Maybe<Pick<StandardMutationError, 'code'>> } };

export type FindDistrictQueryVariables = Exact<{ [key: string]: never; }>;


export type FindDistrictQuery = { findDistrict: Array<FullRegionFragment> };

export type FindFileByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type FindFileByIdQuery = { findFileById: { data?: Maybe<Pick<File, 'id' | 'path'>>, error?: Maybe<Pick<StandardMutationError, 'title' | 'code'>> } };

export type FindFullLogsQueryVariables = Exact<{
  where?: Maybe<LogsFilter>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<LogsSort>;
}>;


export type FindFullLogsQuery = { findFullLogs: Array<LogsFragment> };

export type FindLocationByIdQueryVariables = Exact<{
  _id: Scalars['String'];
}>;


export type FindLocationByIdQuery = { findLocationById?: Maybe<FullLocationFragment> };

export type FindLocationPriceQueryVariables = Exact<{
  where?: Maybe<LocationFilter>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<LocationSort>;
}>;


export type FindLocationPriceQuery = { findLocationPrice: Array<LocationPriceFragment> };

export type FindBasicLocationPriceQueryVariables = Exact<{
  where?: Maybe<LocationFilter>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<LocationSort>;
}>;


export type FindBasicLocationPriceQuery = { findLocationPrice: Array<(
    Pick<LocationPrice, 'status' | 'monthlyClick' | 'noOfAccessPoint'>
    & { stats?: Maybe<Pick<LocationStats, 'monthlyClick'>> }
    & LocationPriceFragment
  )> };

export type FindMacEquipmentQueryVariables = Exact<{
  where?: Maybe<EquipmentFilter>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<EquipmentSort>;
}>;


export type FindMacEquipmentQuery = { findManyEquipment: Array<CommonEquipmentFragment> };

export type FindManyBalanceHistoryQueryVariables = Exact<{
  where?: Maybe<BalanceHistoryFilter>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<BalanceHistorySort>;
}>;


export type FindManyBalanceHistoryQuery = { findManyBalanceHistory: Array<FullBalanceHistoryFragment> };

export type FindManyBannerQueryVariables = Exact<{
  where?: Maybe<BannerFilter>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<BannerSort>;
}>;


export type FindManyBannerQuery = { findManyBanner: Array<FullBannerFragment> };

export type FindManyBannerTemplateQueryVariables = Exact<{
  where?: Maybe<BannerTemplateFilter>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<BannerTemplateSort>;
}>;


export type FindManyBannerTemplateQuery = { findManyBannerTemplate: Array<BannerTemplateFragment> };

export type FindManyCampaignQueryVariables = Exact<{
  where?: Maybe<CampaignFilter>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<CampaignSort>;
}>;


export type FindManyCampaignQuery = { findManyCampaign: Array<FullCampaignFragment> };

export type FindManyReportCampaignQueryVariables = Exact<{
  where?: Maybe<CampaignFilter>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<CampaignSort>;
}>;


export type FindManyReportCampaignQuery = { findManyCampaign: Array<ReportCampaignListFragment> };

export type FindManyCampaignIdAndNameQueryVariables = Exact<{
  where?: Maybe<CampaignFilter>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<CampaignSort>;
}>;


export type FindManyCampaignIdAndNameQuery = { findManyCampaign: Array<Pick<Campaign, '_id' | 'name'>> };

export type FindManyCampaignMappingQueryVariables = Exact<{
  where?: Maybe<CampaignMappingFilter>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<CampaignMappingSort>;
}>;


export type FindManyCampaignMappingQuery = { findManyCampaignMapping: Array<FullCampaignMappingFragment> };

export type FindManyCustomerQueryVariables = Exact<{
  where?: Maybe<CustomerFilter>;
  sort?: Maybe<CustomerSort>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type FindManyCustomerQuery = { findManyCustomer: Array<BasicCustomerFragment> };

export type FindManyEquipmentQueryVariables = Exact<{
  where?: Maybe<EquipmentFilter>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<EquipmentSort>;
}>;


export type FindManyEquipmentQuery = { findManyEquipment: Array<FullEquipmentFragment> };

export type FindManyImageQueryVariables = Exact<{
  where?: Maybe<ImageFilter>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<ImageSort>;
}>;


export type FindManyImageQuery = { findManyImage: Array<ImageFragment> };

export type FindManyLocationQueryVariables = Exact<{
  where?: Maybe<LocationFilter>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<LocationSort>;
}>;


export type FindManyLocationQuery = { findManyLocation: Array<FullLocationFragment> };

export type FindManyLocationCategoryQueryVariables = Exact<{
  where?: Maybe<CommonFilter>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<CommonSort>;
}>;


export type FindManyLocationCategoryQuery = { findManyCommon: Array<CommonLocationCategoryFragment> };

export type FindManyLocationIdAndNameQueryVariables = Exact<{
  where?: Maybe<LocationFilter>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<LocationSort>;
}>;


export type FindManyLocationIdAndNameQuery = { findManyLocation: Array<Pick<Location, '_id' | 'name'>> };

export type FindManyLogsQueryVariables = Exact<{
  where?: Maybe<LogsFilter>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<LogsSort>;
}>;


export type FindManyLogsQuery = { findManyLogs: Array<LogsFragment> };

export type FindManyMessageQueryVariables = Exact<{
  where?: Maybe<MessageFilter>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<MessageSort>;
}>;


export type FindManyMessageQuery = { findManyMessage: Array<FullMessageFragment> };

export type FindManyNotificationQueryVariables = Exact<{
  where?: Maybe<NotificationFilter>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<NotificationSort>;
}>;


export type FindManyNotificationQuery = { findManyNotification: Array<FullNotificationFragment> };

export type FindManyOrderQueryVariables = Exact<{
  where?: Maybe<OrderFilter>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<OrderSort>;
}>;


export type FindManyOrderQuery = { findManyOrder: Array<BasicOrderFragment> };

export type FindManyOwnerByUserIdQueryVariables = Exact<{
  where?: Maybe<OwnerFilter>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<OwnerSort>;
}>;


export type FindManyOwnerByUserIdQuery = { findManyOwner: Array<BasicOwnerFragment> };

export type FindManyPaymentQueryVariables = Exact<{
  where?: Maybe<PaymentFilter>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<PaymentSort>;
}>;


export type FindManyPaymentQuery = { findManyPayment: Array<PaymentFragment> };

export type FindManyPaymentForUserQueryVariables = Exact<{
  where?: Maybe<PaymentFilter>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<PaymentSort>;
}>;


export type FindManyPaymentForUserQuery = { findManyPaymentForUser: Array<PaymentFragment> };

export type FindManyPlanQueryVariables = Exact<{
  where?: Maybe<PlanFilter>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<PlanSort>;
}>;


export type FindManyPlanQuery = { findManyPlan: Array<PlanFragment> };

export type FindManyPricingHistoryQueryVariables = Exact<{
  where?: Maybe<PricingHistoryFilter>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<PricingHistorySort>;
}>;


export type FindManyPricingHistoryQuery = { findManyPricingHistory: Array<(
    Pick<PricingHistory, '_id' | 'categoryId' | 'locationId' | 'price' | 'createdAt' | 'creatorId'>
    & { creator?: Maybe<Pick<User, 'name'>> }
  )> };

export type FindManyProfileMinimalQueryVariables = Exact<{
  where?: Maybe<ProfileFilter>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<ProfileSort>;
}>;


export type FindManyProfileMinimalQuery = { findManyProfile: Array<MinimalProfileFragment> };

export type FindManyProfileBasicQueryVariables = Exact<{
  where?: Maybe<ProfileFilter>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<ProfileSort>;
}>;


export type FindManyProfileBasicQuery = { findManyProfile: Array<BasicProfileFragment> };

export type FindManyProfileQueryVariables = Exact<{
  where?: Maybe<ProfileFilter>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<ProfileSort>;
}>;


export type FindManyProfileQuery = { findManyProfile: Array<FullProfileFragment> };

export type FindManyProfileIdAndDisplayQueryVariables = Exact<{
  where?: Maybe<ProfileFilter>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<ProfileSort>;
}>;


export type FindManyProfileIdAndDisplayQuery = { findManyProfile: Array<Pick<Profile, '_id' | 'display' | 'description'>> };

export type FindManyRegionQueryVariables = Exact<{
  where?: Maybe<RegionFilter>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<RegionSort>;
}>;


export type FindManyRegionQuery = { findManyRegion: Array<FullRegionFragment> };

export type FindManyRevenueQueryVariables = Exact<{
  where?: Maybe<RevenueFilter>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<RevenueSort>;
}>;


export type FindManyRevenueQuery = { findManyRevenue: Array<FullRevenueFragment> };

export type FindManyRoleQueryVariables = Exact<{
  where?: Maybe<RoleFilter>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<RoleSort>;
}>;


export type FindManyRoleQuery = { findManyRole: Array<FullRoleFragment> };

export type FindManyUserQueryVariables = Exact<{
  where?: Maybe<UserFilter>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<UserSort>;
}>;


export type FindManyUserQuery = { findManyUser: Array<FullUserFragment> };

export type FindManyUserGuideQueryVariables = Exact<{
  where?: Maybe<UserGuideFilter>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<UserGuideSort>;
}>;


export type FindManyUserGuideQuery = { findManyUserGuide: Array<Pick<UserGuide, '_id' | 'title' | 'content' | 'meta' | 'linkVideo' | 'faq' | 'brand'>> };

export type FindManyUserIdQueryVariables = Exact<{
  where?: Maybe<UserFilter>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<UserSort>;
}>;


export type FindManyUserIdQuery = { findManyUser: Array<(
    Pick<User, '_id' | 'name' | 'email'>
    & { profile?: Maybe<Pick<Profile, 'display'>> }
  )> };

export type FindManyUserWithOwnerQueryVariables = Exact<{
  limit?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  where?: Maybe<UserOwnerWhereArgs>;
}>;


export type FindManyUserWithOwnerQuery = { findManyUserWithOwner: Array<Pick<Owner, '_id' | 'userId' | 'resourceId' | 'model'> | (
    Pick<User, '_id' | 'name' | 'email'>
    & { profile?: Maybe<Pick<Profile, 'display'>> }
  )> };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { me: { data?: Maybe<FullUserFragment>, error?: Maybe<Pick<StandardMutationError, 'code'>> } };

export type FindMessageByIdQueryVariables = Exact<{
  _id: Scalars['String'];
}>;


export type FindMessageByIdQuery = { findMessageById?: Maybe<FullMessageFragment> };

export type FindNotificationByIdQueryVariables = Exact<{
  _id: Scalars['String'];
}>;


export type FindNotificationByIdQuery = { findNotificationById?: Maybe<FullNotificationFragment> };

export type FindOneCampaignQueryVariables = Exact<{
  _id: Scalars['ID'];
}>;


export type FindOneCampaignQuery = { findOneCampaign?: Maybe<FullCampaignFragment> };

export type FindOneLocationQueryVariables = Exact<{
  where?: Maybe<LocationFilter>;
  sort?: Maybe<LocationSort>;
}>;


export type FindOneLocationQuery = { findOneLocation?: Maybe<FullLocationFragment> };

export type FindOneRevenueQueryVariables = Exact<{
  where?: Maybe<RevenueFilter>;
  sort?: Maybe<RevenueSort>;
}>;


export type FindOneRevenueQuery = { findOneRevenue?: Maybe<FullRevenueFragment> };

export type FindOrderByIdQueryVariables = Exact<{
  _id: Scalars['String'];
}>;


export type FindOrderByIdQuery = { findOrderById?: Maybe<FullOrderFragment> };

export type FindManyOwnerEquipmentQueryVariables = Exact<{
  where?: Maybe<EquipmentFilter>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<EquipmentSort>;
}>;


export type FindManyOwnerEquipmentQuery = { findManyEquipment: Array<CommonOwnerTypeEquipmentFragment> };

export type FindProfileByIdQueryVariables = Exact<{
  _id: Scalars['String'];
}>;


export type FindProfileByIdQuery = { findProfileById?: Maybe<FullProfileFragment> };

export type FindRevenueByIdQueryVariables = Exact<{
  _id: Scalars['String'];
}>;


export type FindRevenueByIdQuery = { findRevenueById?: Maybe<FullRevenueFragment> };

export type GetActiveCampaignsQueryVariables = Exact<{
  where?: Maybe<LocationFilter>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<LocationSort>;
}>;


export type GetActiveCampaignsQuery = { getActiveCampaigns: Array<(
    Pick<LocationActiveCampaigns, '_id' | 'name' | 'licenseName' | 'categoryId' | 'ownerType' | 'group'>
    & { activeCampaigns?: Maybe<Array<(
      Pick<ActiveCampaign, '_id' | 'name' | 'kpi'>
      & { config?: Maybe<{ banners?: Maybe<Array<Pick<CampaignConfigBanner, 'bannerId' | 'bannerName'>>> }> }
    )>> }
  )> };

export type AlertLocationQueryVariables = Exact<{
  dateCheck?: Maybe<Scalars['Int']>;
  numberCheck?: Maybe<Scalars['Int']>;
}>;


export type AlertLocationQuery = { alertLocation: { data?: Maybe<Array<Pick<ReportLocationData, 'locationId' | 'locationName' | 'city' | 'click' | 'categoryId'>>> } };

export type GetAvailableBookingQueryVariables = Exact<{
  locationIds?: Maybe<Array<Scalars['String']>>;
  categoryIds?: Maybe<Array<Scalars['String']>>;
  date: DateFilterInput;
  interval?: Maybe<Scalars['String']>;
  detail?: Maybe<Scalars['Boolean']>;
}>;


export type GetAvailableBookingQuery = { getAvailableBooking: { locations: Array<(
      Pick<AvailableBookingByLocations, '_id' | 'name' | 'bookingType' | 'availableTotalKpi' | 'bookingKpi' | 'categoryId' | 'expectedKpi' | 'group' | 'percent'>
      & { everyDay?: Maybe<Array<Pick<EveryDay, 'date' | 'availableKpi'>>> }
    )>, categories: Array<Pick<AvailableBookingByCategories, 'categoryId' | 'bookingKpi' | 'availableKpi' | 'expectedKpi' | 'percent'>> } };

export type GetCampaignByLocationIdQueryVariables = Exact<{
  locationId: Scalars['String'];
}>;


export type GetCampaignByLocationIdQuery = { getCampaignByLocationId: Array<Pick<CampaignIdByLocation, '_id' | 'name' | 'status' | 'ownerType'>> };

export type GetCampaignRevenueQueryVariables = Exact<{
  where?: Maybe<RevenueFilter>;
}>;


export type GetCampaignRevenueQuery = { findManyRevenue: Array<CampaignRevenueFragment> };

export type GetCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCategoriesQuery = { getCategories: Array<CommonLocationCategoryFragment> };

export type GetDataAudienceQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDataAudienceQuery = { getDataAudience: (
    Pick<AudienceDataReturn, 'totalAge' | 'totalGender' | 'totalIncome'>
    & { genders?: Maybe<Array<Pick<Gender, 'gender' | 'count'>>>, ages?: Maybe<Array<Pick<Age, 'age' | 'count'>>>, incomes?: Maybe<Array<Pick<Income, 'income' | 'count'>>> }
  ) };

export type GetDataTrafficOverviewQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDataTrafficOverviewQuery = { getDataTrafficOverview: Pick<Data, 'dataYearAgo' | 'dataYear'> };

export type GetTotalUsersQueryVariables = Exact<{
  locationId?: Maybe<Scalars['String']>;
}>;


export type GetTotalUsersQuery = Pick<Query, 'getTotalUsers'>;

export type GetHotspotOnOffQueryVariables = Exact<{ [key: string]: never; }>;


export type GetHotspotOnOffQuery = { getHotspotOnOff: Pick<HotspotOnOff, 'on' | 'total'> };

export type GetLocationAdsQueryVariables = Exact<{
  ownerType: Scalars['String'];
}>;


export type GetLocationAdsQuery = Pick<Query, 'getLocationAds'>;

export type GetLocationClick3MonthQueryVariables = Exact<{
  locationId: Scalars['String'];
}>;


export type GetLocationClick3MonthQuery = { getLocationClick3Month: { data?: Maybe<Array<Pick<ReportLocationType, 'click' | 'month'>>>, error?: Maybe<Pick<StandardMutationError, 'title' | 'code'>> } };

export type GetDetailQueryVariables = Exact<{
  where?: Maybe<GetDetailLocationWhere>;
  rowGroupCols?: Maybe<Array<ColumnVo>>;
  valueCols?: Maybe<Array<ColumnVo>>;
  groupKeys?: Maybe<Array<Scalars['String']>>;
}>;


export type GetDetailQuery = { getDetail: Array<Pick<DetailResult, 'categoryId' | 'categoryName' | 'locationId' | 'locationName' | 'ctr' | 'click' | 'impression' | 'createdAt' | 'bannerId' | 'bannerName'>> };

export type GetOnlineSessionQueryVariables = Exact<{
  locationId?: Maybe<Scalars['String']>;
}>;


export type GetOnlineSessionQuery = { getOnlineSession: (
    Pick<OnlineSession, 'total'>
    & { detail?: Maybe<Array<(
      Pick<TrackingDetail, 'action' | 'mac' | 'uaString' | 'ip' | 'createdAt' | 'updatedAt'>
      & { ua?: Maybe<Pick<Ua, 'browser' | 'major' | 'minor' | 'patch' | 'os' | 'os_name' | 'os_major' | 'os_minor' | 'device' | 'device_name'>>, meta?: Maybe<Pick<MetaElastic, 'locationId' | 'campaignId' | 'bannerId' | 'realLocationId'>> }
    )>> }
  ) };

export type GetRenderCodeQueryVariables = Exact<{
  locationId?: Maybe<Scalars['String']>;
  equipmentId?: Maybe<Scalars['String']>;
  isExternal?: Maybe<Scalars['String']>;
  macFieldName?: Maybe<Scalars['String']>;
}>;


export type GetRenderCodeQuery = Pick<Query, 'getRenderCode'>;

export type GetReportCampaignInfoQueryVariables = Exact<{
  campaignId: Scalars['String'];
}>;


export type GetReportCampaignInfoQuery = { getReportCampaignInfo: { data?: Maybe<(
      Pick<ReportCampaignInfo, 'name' | 'type' | 'status' | 'scheduleTo' | 'scheduleFrom' | 'todayClick' | 'customs'>
      & { config?: Maybe<(
        Pick<CampaignConfig, 'crm' | 'crmValue' | 'landingPageUrl'>
        & { banners?: Maybe<Array<Pick<CampaignConfigBanner, 'bannerName' | 'bannerId'>>> }
      )>, locations?: Maybe<Array<Pick<CampaignLocation, 'locationId' | 'locationName' | 'categoryId' | 'categoryName' | 'tag' | 'locationGroup'>>>, stats?: Maybe<Pick<Statistic, 'kpi' | 'kpiCompleted' | 'completed' | 'click' | 'kpiRemaining'>> }
    )>, error?: Maybe<Pick<StandardMutationError, 'title' | 'code'>> } };

export type GetReportCampaignOsDeviceQueryVariables = Exact<{
  campaignId: Scalars['String'];
  filter?: Maybe<CampaignSurveyFilter>;
}>;


export type GetReportCampaignOsDeviceQuery = { getReportCampaignOsDevice: (
    Pick<ReportCampaignOsDevicePayload, 'brandName' | 'deviceName'>
    & { os?: Maybe<Pick<OsCount, 'iOS' | 'Android' | 'Windows' | 'other'>>, device?: Maybe<Pick<DeviceTypeCount, 'Mobile' | 'PC' | 'Tablet'>>, error?: Maybe<Pick<StandardMutationError, 'code'>> }
  ) };

export type GetReportCampaignPartnerQueryVariables = Exact<{
  campaignId: Array<Scalars['String']>;
  locationId: Array<Scalars['String']>;
  date: DateArgs;
  interval?: Maybe<Interval>;
}>;


export type GetReportCampaignPartnerQuery = { getReportCampaignPartner: { total?: Maybe<Pick<ReportCampaignTotal, 'click' | 'impression' | 'ctr'>>, byDate?: Maybe<Array<Pick<ReportCampaignByDate, 'click' | 'impression' | 'ctr' | 'date'>>>, error?: Maybe<Pick<StandardMutationError, 'code'>> } };

export type GetReportCampaignRatioQueryVariables = Exact<{
  filter?: Maybe<CampaignRatioFilter>;
  skip?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
}>;


export type GetReportCampaignRatioQuery = { getReportCampaignRatio: Array<(
    Pick<ReportCampaignRatioResult, 'click' | 'ratio' | 'type'>
    & { detail: Array<Pick<ReportCampaignRatioDetail, 'click' | 'ratio' | 'date'>> }
  )> };

export type GetReportCampaignStatisticQueryVariables = Exact<{
  campaignId: Scalars['String'];
  date: DateArgs;
  locationId?: Maybe<Array<Scalars['String']>>;
  categoryId?: Maybe<Array<Scalars['String']>>;
  bannerId?: Maybe<Array<Scalars['String']>>;
  interval?: Maybe<Interval>;
}>;


export type GetReportCampaignStatisticQuery = { getReportCampaignStatistic: { total?: Maybe<Pick<ReportCampaignTotal, 'click' | 'impression' | 'ctr'>>, byDate?: Maybe<Array<Pick<ReportCampaignByDate, 'click' | 'impression' | 'ctr' | 'date'>>>, error?: Maybe<Pick<StandardMutationError, 'title' | 'code'>> } };

export type GetReportCampaignSurveyQueryVariables = Exact<{
  campaignId: Scalars['String'];
  filter?: Maybe<CampaignSurveyFilter>;
  skip?: Maybe<Scalars['Float']>;
}>;


export type GetReportCampaignSurveyQuery = { getReportCampaignSurvey: (
    Pick<ReportCampaignSurveyPayload, 'total'>
    & { data?: Maybe<Array<(
      Pick<ReportCampaignSurvey, 'createdAt' | 'uaString' | 'mac' | 'updatedAt' | 'survey' | 'audience'>
      & { meta?: Maybe<Pick<CampaignMeta, 'bannerId' | 'campaignId' | 'locationId' | 'realLocationId' | 'bannerName' | 'locationName' | 'count'>>, ua?: Maybe<Pick<Ua, 'browser' | 'major' | 'minor' | 'patch' | 'os' | 'os_name' | 'os_major' | 'os_minor' | 'device' | 'device_name' | 'deviceName' | 'deviceCode' | 'brandName'>> }
    )>>, error?: Maybe<Pick<StandardMutationError, 'code'>> }
  ) };

export type GetDataOsDeviceQueryVariables = Exact<{
  locationId?: Maybe<Scalars['String']>;
  dateFilter: DateArgs;
}>;


export type GetDataOsDeviceQuery = { getDataOSDevice: { osCount?: Maybe<Pick<OsCount, 'iOS' | 'Android' | 'Windows' | 'other'>>, deviceTypeCount?: Maybe<Pick<DeviceTypeCount, 'Mobile' | 'PC' | 'Tablet'>> } };

export type GetReportIncomeByCampaignQueryVariables = Exact<{
  filter?: Maybe<IncomeFilter>;
}>;


export type GetReportIncomeByCampaignQuery = { getReportIncomeByCampaign: (
    Pick<ReportIncomeResult, 'income' | 'incomePartner' | 'click'>
    & { groupByLocation?: Maybe<Array<(
      Pick<GroupIncomeResult, 'locationId' | 'income' | 'incomePartner' | 'click'>
      & { location?: Maybe<Pick<LocationResult, 'name' | 'licenseName' | 'city' | 'district'>> }
    )>>, time?: Maybe<Array<Pick<TimeIncomeResult, 'date' | 'income' | 'incomePartner' | 'click'>>> }
  ) };

export type GetReportIncomeByLocationQueryVariables = Exact<{
  filter?: Maybe<IncomeFilter>;
}>;


export type GetReportIncomeByLocationQuery = { getReportIncomeByLocation: (
    Pick<ReportIncomeResult, 'income' | 'incomePartner' | 'click'>
    & { groupByCampaign?: Maybe<Array<Pick<GroupIncomeResult, 'campaignName' | 'campaignId' | 'income' | 'incomePartner' | 'click'>>>, groupByLocation?: Maybe<Array<(
      Pick<GroupIncomeResult, 'locationId' | 'income' | 'incomePartner' | 'click'>
      & { location?: Maybe<Pick<LocationResult, 'name' | 'licenseName' | 'city' | 'district'>> }
    )>>, time?: Maybe<Array<Pick<TimeIncomeResult, 'date' | 'income' | 'incomePartner' | 'click'>>> }
  ) };

export type GetReportIncomePartnerQueryVariables = Exact<{
  filter?: Maybe<IncomeFilter>;
}>;


export type GetReportIncomePartnerQuery = { getReportIncomePartner: (
    Pick<ReportIncomeResult, 'income' | 'incomePartner' | 'click'>
    & { groupByCampaign?: Maybe<Array<Pick<GroupIncomeResult, 'campaignName' | 'campaignId' | 'click' | 'incomePartner'>>>, groupByLocation?: Maybe<Array<(
      Pick<GroupIncomeResult, 'locationId' | 'incomePartner' | 'click'>
      & { location?: Maybe<Pick<LocationResult, 'name' | 'licenseName' | 'city' | 'district'>> }
    )>>, time?: Maybe<Array<Pick<TimeIncomeResult, 'date' | 'click' | 'incomePartner'>>> }
  ) };

export type GetReportLocationDataQueryVariables = Exact<{
  where?: Maybe<RpLocationWhereFilterInput>;
  dataType?: Maybe<DataType>;
  skip?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
}>;


export type GetReportLocationDataQuery = { getReportLocationData: { data?: Maybe<Array<Pick<ReportLocationData, 'click' | 'impression' | 'ctr' | 'date' | 'city' | 'district' | 'locationName' | 'categoryName' | 'locationId' | 'categoryId'>>>, total?: Maybe<Pick<Total, 'click' | 'impression' | 'ctr' | 'users'>> } };

export type GetReportLocationUpDownQueryVariables = Exact<{
  where?: Maybe<RpLocationWhereFilterInput>;
  dataType?: Maybe<DataType>;
  skip?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  amongTime?: Maybe<Scalars['Float']>;
}>;


export type GetReportLocationUpDownQuery = { getReportLocationUpDown: { data?: Maybe<Array<Pick<ReportLocationData, 'click' | 'clickBefore' | 'impression' | 'impressionBefore' | 'ctr' | 'date' | 'city' | 'district' | 'locationName' | 'categoryName' | 'locationId' | 'categoryId'>>>, total?: Maybe<Pick<Total, 'click' | 'clickBefore' | 'impression' | 'impressionBefore' | 'ctr' | 'users'>> } };

export type GetDataLoginQueryVariables = Exact<{
  locationId?: Maybe<Scalars['String']>;
}>;


export type GetDataLoginQuery = { getDataLogin: Pick<LoginData, 'locationId' | 'time' | 'day' | 'hour'> };

export type GetReportOnlineSessionQueryVariables = Exact<{
  skip: Scalars['Float'];
  limit: Scalars['Float'];
}>;


export type GetReportOnlineSessionQuery = { getReportOnlineSession: Array<Pick<ReportOnlineSession, 'os' | 'deviceType' | 'apMac' | 'deviceMac' | 'deviceName' | 'deviceCode' | 'device_name' | 'locationName' | 'startedAt'>> };

export type GetReportSharedTrafficDetailsQueryVariables = Exact<{
  filter?: Maybe<SharedTrafficFilter>;
  skip?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
}>;


export type GetReportSharedTrafficDetailsQuery = { getReportSharedTrafficDetails: Array<(
    Pick<ReportSharedTrafficResult, '_id' | 'name' | 'total' | 'owned' | 'totalImpression' | 'totalClick' | 'ratioImpression' | 'ratioClick' | 'ownedImpression' | 'ownedClick' | 'sharedRatio'>
    & { detail: Array<Pick<ReportSharedTrafficDetail, 'date' | 'totalImpression' | 'totalClick' | 'ratioImpression' | 'ratioClick' | 'ownedImpression' | 'ownedClick'>> }
  )> };

export type GetReportTodayLoginQueryVariables = Exact<{ [key: string]: never; }>;


export type GetReportTodayLoginQuery = { getReportTodayLogin: Array<Pick<ReportTodayLogin, 'hour' | 'count'>> };

export type GetTrackingQueryVariables = Exact<{
  locationId?: Maybe<Array<Scalars['String']>>;
  campaignId?: Maybe<Scalars['String']>;
  dateFrom: Scalars['DateTime'];
  dateTo: Scalars['DateTime'];
  bannerId?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  view: View;
  tab?: Maybe<Scalars['String']>;
}>;


export type GetTrackingQuery = { getTracking: { groupByDay?: Maybe<Array<Pick<GroupByDay, 'click' | 'impression' | 'ctr' | 'date'>>>, total?: Maybe<Pick<TotalValue, 'click' | 'impression' | 'ctr'>>, detail?: Maybe<Array<Pick<DetailResult, 'categoryId' | 'campaignId' | 'createdAt' | 'locationId' | 'categoryName' | 'locationName' | 'bannerId' | 'bannerName' | 'impression' | 'click' | 'ctr'>>> } };

export type GetReportTrackingDetailQueryVariables = Exact<{
  externalFilter?: Maybe<TrackingDetailExternalFilter>;
  internalFilter?: Maybe<TrackingDetailInternalFilter>;
  date: DateArgs;
}>;


export type GetReportTrackingDetailQuery = { getReportTrackingDetail: { data?: Maybe<Array<Pick<ReportTrakingDetail, 'click' | 'impression' | 'ctr' | 'categoryId' | 'categoryName' | 'bannerId' | 'bannerName' | 'locationId' | 'locationName' | 'createdAt'>>>, error?: Maybe<Pick<StandardMutationError, 'title' | 'code'>> } };

export type GetReportUserReturnQueryVariables = Exact<{
  where?: Maybe<RpLocationWhereFilterInput>;
  dataType?: Maybe<DataType>;
  skip?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
}>;


export type GetReportUserReturnQuery = { getReportUserReturn: { data?: Maybe<Array<Pick<ReportUserReturnData, 'name' | 'y'>>>, total?: Maybe<Pick<Total, 'click' | 'impression' | 'ctr' | 'users'>> } };

export type GetDataVisitQueryVariables = Exact<{
  locationId?: Maybe<Scalars['String']>;
}>;


export type GetDataVisitQuery = { getDataVisit: { last7Days?: Maybe<Pick<LastNDay, 'once' | 'twoFive' | 'sixTen' | 'tenPlus'>>, last15Days?: Maybe<Pick<LastNDay, 'once' | 'twoFive' | 'sixTen' | 'tenPlus'>>, last30Days?: Maybe<Pick<LastNDay, 'once' | 'twoFive' | 'sixTen' | 'tenPlus'>> } };

export type GetSurveyCampaignQueryVariables = Exact<{
  params: SurveyFilterInput;
}>;


export type GetSurveyCampaignQuery = { getSurveyCampaign: (
    Pick<SurveyCampaignPayload, 'total' | 'os'>
    & { data?: Maybe<Array<(
      Pick<ReportSurvey, 'createdAt' | 'updatedAt' | 'uaString' | 'mac' | 'survey' | 'audience'>
      & { meta?: Maybe<Pick<SurveyMeta, 'bannerId' | 'bannerName' | 'campaignId' | 'locationId' | 'locationName' | 'realLocationId' | 'count'>>, ua?: Maybe<Pick<Ua, 'patch' | 'device_name' | 'os' | 'major' | 'minor' | 'browser' | 'os_name' | 'device'>> }
    )>>, device?: Maybe<Pick<DeviceTypeCount, 'Mobile' | 'PC' | 'Tablet'>>, config?: Maybe<(
      Pick<CampaignConfig, 'landingPageUrl' | 'impressionUrl' | 'crm' | 'crmValue'>
      & { audience?: Maybe<{ acceptRules?: Maybe<Pick<QueryBuilder, 'query' | 'queryTree'>> }> }
    )>, error?: Maybe<Pick<StandardMutationError, 'code'>> }
  ) };

export type GetTotalCampaignQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTotalCampaignQuery = { getTotalCampaign: Pick<TotalCampagin, 'runningCampaigns' | 'advertiserCampaigns' | 'defaultCampaigns' | 'otherCampaign' | 'advertiserKpi' | 'advertiserClick' | 'newCampaigns' | 'enddingCampaigns'> };

export type GetTotalLocationActiveQueryVariables = Exact<{
  ownerType: Scalars['String'];
}>;


export type GetTotalLocationActiveQuery = { getTotalLocationActive: Array<Pick<LocationCount, 'categoryId' | 'categoryIcon' | 'categoryName' | 'countLocationActive' | 'countLocationLicense' | 'countLocationMGN' | 'countTotalLocationCategory' | 'sumMonthlyClick' | 'ctrStats' | 'uniqueUser'>> };

export type GetTotalStatusLocationQueryVariables = Exact<{
  ownerType: Scalars['String'];
}>;


export type GetTotalStatusLocationQuery = { getTotalStatusLocation: { allLocation?: Maybe<Array<Pick<AllLocation, 'categoryId' | 'countAllLocation'>>>, locationActive?: Maybe<Array<Pick<LocationActive, 'categoryId' | 'countLocationActive'>>> } };

export type GetTotalTrafficQueryVariables = Exact<{
  locationId?: Maybe<Scalars['String']>;
}>;


export type GetTotalTrafficQuery = { getTotalTraffic: (
    Pick<TotalTraffic, 'thisMonth'>
    & { today?: Maybe<(
      Pick<TodayPayload, 'total'>
      & { detail?: Maybe<Array<Pick<ReportTodayLogin, 'count' | 'hour'>>> }
    )> }
  ) };

export type GetTotalUserLicensesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTotalUserLicensesQuery = { getTotalUserLicenses?: Maybe<Pick<TotalUser, 'ownerTypeUser' | 'demoUser' | 'realUser' | 'newMonthUser'>> };

export type GetTrackingByLocationQueryVariables = Exact<{
  id?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
}>;


export type GetTrackingByLocationQuery = { getTrackingByLocation: { todayLogin: Array<Pick<ReportTodayLogin, 'hour' | 'count'>>, onlineSession: Array<Pick<ReportOnlineSession, 'os' | 'deviceType' | 'apMac' | 'deviceMac' | 'locationName' | 'startedAt'>> } };

export type GetTrackingData3MonthQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTrackingData3MonthQuery = { getTrackingData3Month: { data?: Maybe<Array<Pick<TrackingLocation, 'click' | 'month' | 'categoryId'>>>, error?: Maybe<Pick<StandardMutationError, 'title' | 'code'>> } };

export type GetTrackingSurveyQueryVariables = Exact<{
  campaignId: Scalars['String'];
}>;


export type GetTrackingSurveyQuery = { getTrackingSurvey: { result?: Maybe<Array<Pick<SurveyTracking, 'name' | 'options'>>> } };

export type GetTrafficSummaryQueryVariables = Exact<{
  gte: Scalars['DateTime'];
  lte: Scalars['DateTime'];
}>;


export type GetTrafficSummaryQuery = { getTrafficSummary: (
    Pick<TrafficSummaryData, 'impression' | 'click' | 'ctr' | 'impressionAds' | 'clickAds' | 'ctrAds'>
    & { detail?: Maybe<Array<Pick<TrafficDetail, 'date' | 'ctr' | 'impression' | 'click' | 'impressionAds' | 'clickAds' | 'ctrAds'>>> }
  ) };

export type Sale_FindManyCategoryQueryVariables = Exact<{ [key: string]: never; }>;


export type Sale_FindManyCategoryQuery = { sale_findManyCategory: Array<Pick<LocationCategory, 'value' | 'text' | 'icon'>> };

export type Sale_FindManyCityQueryVariables = Exact<{ [key: string]: never; }>;


export type Sale_FindManyCityQuery = { sale_findManyCity: Array<Pick<LocationsCommon, 'value' | 'text'>> };

export type Sale_FindManyDistrictQueryVariables = Exact<{ [key: string]: never; }>;


export type Sale_FindManyDistrictQuery = { sale_findManyDistrict: Array<Pick<LocationsCommon, 'value' | 'text'>> };

export type Sale_FindManyBasicLocationQueryVariables = Exact<{
  where?: Maybe<LocationSaleFilter>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<LocationSaleSort>;
}>;


export type Sale_FindManyBasicLocationQuery = { sale_findManyLocation: Array<Pick<Location, '_id' | 'name' | 'categoryId' | 'city' | 'bookingType' | 'district' | 'status' | 'monthlyClick' | 'noOfAccessPoint' | 'price'>> };

export type Sale_FindManyLocationQueryVariables = Exact<{
  where?: Maybe<LocationSaleFilter>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<LocationSaleSort>;
}>;


export type Sale_FindManyLocationQuery = { sale_findManyLocation: Array<LocationSaleFragment> };

export type SendMailtoTheCustomerQueryVariables = Exact<{
  email: Scalars['String'];
  data: DataInput;
}>;


export type SendMailtoTheCustomerQuery = Pick<Query, 'sendMailtoTheCustomer'>;

export type UserBalanceStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type UserBalanceStatusQuery = { userBalanceStatus: Array<FullUserBalanceFragment> };

export type VerifyTwoFactorAuthenticationCodeQueryVariables = Exact<{
  code: Scalars['String'];
}>;


export type VerifyTwoFactorAuthenticationCodeQuery = Pick<Query, 'verifyTwoFactorAuthenticationCode'>;

export const FullUserBalanceFragmentDoc = gql`
    fragment FullUserBalance on UserBalance {
  _id
  userId
  amount
  type
  expireDate
}
    `;
export const BasicBalanceHistoryFragmentDoc = gql`
    fragment BasicBalanceHistory on BalanceHistory {
  _id
  userId
  originalAmount
  amount
  campaignId
  type
  createdAt
  updatedAt
  action
}
    `;
export const FullBalanceHistoryFragmentDoc = gql`
    fragment FullBalanceHistory on BalanceHistory {
  ...BasicBalanceHistory
  campaign {
    name
  }
}
    ${BasicBalanceHistoryFragmentDoc}`;
export const CommonBannerFragmentDoc = gql`
    fragment CommonBanner on Banner {
  _id
  name
}
    `;
export const BasicBannerFragmentDoc = gql`
    fragment BasicBanner on Banner {
  _id
  name
  categoryId
  category {
    text
  }
  htmlCode
  fileCode
  isSample
  configRender
  ownerType
  landingPageUrl
  active
  creatorId
  createdAt
  updatedAt
}
    `;
export const FullBannerFragmentDoc = gql`
    fragment FullBanner on Banner {
  ...BasicBanner
  creator {
    name
    email
  }
  customer {
    name
    email
  }
  currentCampaigns {
    name
    isBo
    isRunning
  }
}
    ${BasicBannerFragmentDoc}`;
export const CommonBannerTempFragmentDoc = gql`
    fragment CommonBannerTemp on BannerTemp {
  _id
  name
}
    `;
export const BasicBannerTempFragmentDoc = gql`
    fragment BasicBannerTemp on BannerTemp {
  _id
  name
  categoryId
  category {
    text
  }
  htmlCode
  fileCode
  isSample
  configRender
  ownerType
  creatorId
  createdAt
  updatedAt
}
    `;
export const FullBannerTempFragmentDoc = gql`
    fragment FullBannerTemp on BannerTemp {
  ...BasicBannerTemp
  creator {
    name
    email
  }
  customer {
    name
    email
  }
}
    ${BasicBannerTempFragmentDoc}`;
export const BannerTemplateFragmentDoc = gql`
    fragment BannerTemplate on BannerTemplate {
  _id
  name
  type
  typeText
  options
  htmlCode
  createdAt
  updatedAt
  meta
}
    `;
export const BasicCampaignFragmentDoc = gql`
    fragment BasicCampaign on Campaign {
  _id
  name
  description
  type
  isBookOnline
  categoryId
  scheduleFrom
  scheduleTo
  kpi
  budget
  times
  timesOfClick
  locations {
    locationId
    locationName
    categoryId
    categoryName
    kpi
  }
  config {
    banners {
      bannerName
      bannerId
    }
    landingPageUrl
  }
  active
  status
  stoppedUntil
  defaultForClient
}
    `;
export const CampaignStatsFragmentDoc = gql`
    fragment CampaignStats on Campaign {
  _id
  name
  active
  status
  scheduleFrom
  scheduleTo
  type
  stats {
    kpi
    click
    impression
    ctr
    completed
    kpiCompleted
    kpiRemaining
  }
}
    `;
export const FullCampaignFragmentDoc = gql`
    fragment FullCampaign on Campaign {
  ...BasicCampaign
  ...CampaignStats
  typeBrand
  shareOwner
  ownerType
  optimizeType
  config {
    crm
    crmValue
  }
  customs
  creatorId
  creator {
    name
  }
  type
}
    ${BasicCampaignFragmentDoc}
${CampaignStatsFragmentDoc}`;
export const CommonCampaignFragmentDoc = gql`
    fragment CommonCampaign on Campaign {
  _id
  name
  status
  ownerType
  type
}
    `;
export const ReportCampaignListFragmentDoc = gql`
    fragment ReportCampaignList on Campaign {
  _id
  name
  isBookOnline
  type
  scheduleFrom
  scheduleTo
  status
  stats {
    kpi
    completed
    click
    kpiCompleted
  }
}
    `;
export const AdvanceCampaignFormFragmentDoc = gql`
    fragment AdvanceCampaignForm on Campaign {
  _id
  name
  type
  shareOwner
}
    `;
export const CampaignConfigFragmentDoc = gql`
    fragment CampaignConfig on Campaign {
  _id
  name
  scheduleFrom
  scheduleTo
  kpi
  customs
  locations {
    categoryId
    locationId
    locationName
  }
  config {
    audience {
      acceptRules {
        query
        queryTree
      }
    }
    banners {
      bannerId
      bannerName
      query
      queryTree
    }
    bannerRandom {
      enable
      ratio
    }
    landingPageUrl
    impressionUrl
    trackingLocation
    crm
    crmValue
    fb {
      appId
      appSecret
      accountKitSecret
      feed
      feedBody {
        link
        name
        caption
        description
        picture
      }
    }
    gg {
      clientId
      clientSecret
    }
    webhook {
      enabled
      api_url
      timeout
      method
      token
      params {
        name
        value
      }
    }
  }
}
    `;
export const FullCampaignMappingFragmentDoc = gql`
    fragment FullCampaignMapping on CampaignMapping {
  _id
  campaignId
  campaign {
    _id
    name
  }
  locationId
  location {
    _id
    name
    licenseName
  }
  status
  reportLocationId
  reportLocation {
    _id
    name
    licenseName
  }
  scheduleFrom
  scheduleTo
  priority
  kpi
  active
  stats {
    kpi
    click
    kpiCompleted
    kpiRemaining
  }
  todayStats {
    kpi
    click
    kpiCompleted
  }
  yesterdayStats {
    kpi
    click
    kpiCompleted
  }
}
    `;
export const FullCommonFragmentDoc = gql`
    fragment FullCommon on Common {
  _id
  value
  text
  icon
  type
  instructionType
  meta
  options
  active
  creatorId
  createdAt
  updatedAt
}
    `;
export const CommonLocationCategoryFragmentDoc = gql`
    fragment CommonLocationCategory on Common {
  text
  value
  icon
  meta
  active
}
    `;
export const MinimalCustomerTypeFragmentDoc = gql`
    fragment MinimalCustomerType on CustomerType {
  _id
  name
  description
}
    `;
export const BasicCustomerTypeFragmentDoc = gql`
    fragment BasicCustomerType on CustomerType {
  ...MinimalCustomerType
  isDefault
  defaultProfileId
  profile {
    display
  }
}
    ${MinimalCustomerTypeFragmentDoc}`;
export const BasicEquipmentFragmentDoc = gql`
    fragment basicEquipment on Equipment {
  _id
  name
  type
  brand
  model
  locationId
  mac
  serial
  gateway
  createdAt
  updatedAt
}
    `;
export const MinimalUserFragmentDoc = gql`
    fragment MinimalUser on User {
  _id
  avatarLink
  name
  email
}
    `;
export const MinimalLocationFragmentDoc = gql`
    fragment MinimalLocation on Location {
  _id
  name
  licenseName
  status
}
    `;
export const FullEquipmentFragmentDoc = gql`
    fragment fullEquipment on Equipment {
  ...basicEquipment
  creatorId
  creator {
    ...MinimalUser
  }
  location {
    ...MinimalLocation
  }
}
    ${BasicEquipmentFragmentDoc}
${MinimalUserFragmentDoc}
${MinimalLocationFragmentDoc}`;
export const CommonEquipmentFragmentDoc = gql`
    fragment commonEquipment on Equipment {
  _id
  mac
}
    `;
export const CommonOwnerTypeEquipmentFragmentDoc = gql`
    fragment commonOwnerTypeEquipment on Equipment {
  _id
  ownerType
}
    `;
export const BasicErrorLogFragmentDoc = gql`
    fragment BasicErrorLog on ErrorLog {
  _id
  userId
  error
  createdAt
  updatedAt
}
    `;
export const BasicUserFragmentDoc = gql`
    fragment BasicUser on User {
  ...MinimalUser
  phone
  address
  password
  active
  emailVerified
  profileId
  ownerType
  twoFactorAuthId
  expiredDate
}
    ${MinimalUserFragmentDoc}`;
export const MinimalProfileFragmentDoc = gql`
    fragment MinimalProfile on Profile {
  _id
  profileId
  display
}
    `;
export const BasicProfileFragmentDoc = gql`
    fragment BasicProfile on Profile {
  ...MinimalProfile
  roleIds
  menuRoleIds
  createdAt
  updatedAt
}
    ${MinimalProfileFragmentDoc}`;
export const FullRoleFragmentDoc = gql`
    fragment FullRole on Role {
  _id
  roleId
  description
  createdAt
  updatedAt
  group
  name
}
    `;
export const FullProfileFragmentDoc = gql`
    fragment FullProfile on Profile {
  ...BasicProfile
  roles {
    ...FullRole
  }
}
    ${BasicProfileFragmentDoc}
${FullRoleFragmentDoc}`;
export const BasicCustomerFragmentDoc = gql`
    fragment BasicCustomer on Customer {
  _id
  name
  email
  phone
  address
  website
  taxCode
  typeCode
  shareTraffic {
    ratio
    enabled
  }
  urlLogo
  logos {
    id
    path
  }
  shareRevenue {
    enabled
  }
}
    `;
export const FullUserFragmentDoc = gql`
    fragment FullUser on User {
  ...BasicUser
  profile {
    ...FullProfile
  }
  customerId
  customer {
    ...BasicCustomer
  }
  creatorId
  creator {
    _id
    name
  }
}
    ${BasicUserFragmentDoc}
${FullProfileFragmentDoc}
${BasicCustomerFragmentDoc}`;
export const FullErrorLogFragmentDoc = gql`
    fragment FullErrorLog on ErrorLog {
  ...BasicErrorLog
  user {
    ...FullUser
  }
}
    ${BasicErrorLogFragmentDoc}
${FullUserFragmentDoc}`;
export const ImageFragmentDoc = gql`
    fragment Image on Image {
  _id
  name
  type
  typeText
  category
  categoryText
  link
  meta
  image {
    id
    path
  }
  createdAt
  updatedAt
}
    `;
export const StatsLocationFragmentDoc = gql`
    fragment StatsLocation on Location {
  ...MinimalLocation
  activeDay
  noOfRouter
  noOfAccessPoint
  stats {
    dailyClick
    ctr
    monthlyClick
    monthlyImpression
    dailyImpression
    uniqueUser
  }
}
    ${MinimalLocationFragmentDoc}`;
export const CommonLocationFragmentDoc = gql`
    fragment CommonLocation on Location {
  ...StatsLocation
  categoryId
  district
  city
  tags
  group
  address {
    text
  }
  ownerType
  equipments {
    _id
    name
    mac
  }
}
    ${StatsLocationFragmentDoc}`;
export const FullLocationFragmentDoc = gql`
    fragment FullLocation on Location {
  ...CommonLocation
  creator {
    ...MinimalUser
  }
  customer {
    ...BasicUser
  }
}
    ${CommonLocationFragmentDoc}
${MinimalUserFragmentDoc}
${BasicUserFragmentDoc}`;
export const SaleInfoFormFragmentDoc = gql`
    fragment saleInfoForm on Location {
  _id
  name
  monthlyClick
  description
  pictures {
    path
    id
  }
  pictureIds
  bookingType
}
    `;
export const SystemInfoFormFragmentDoc = gql`
    fragment systemInfoForm on Location {
  _id
  noOfAccessPoint
  noOfRouter
}
    `;
export const DetailLocationInfoFragmentDoc = gql`
    fragment detailLocationInfo on Location {
  noOfAccessPoint
  noOfRouter
  activeDay
  _id
  name
  categoryId
  city
  district
  address {
    text
  }
  stats {
    monthlyClick
  }
  status
  configAuth
}
    `;
export const AdvanceFormFragmentDoc = gql`
    fragment AdvanceForm on Location {
  _id
  shareType {
    owner
    type
    unitPrice
  }
  stats {
    monthlyClick
  }
  status
  activeDay
  configAuth
  share
}
    `;
export const BasicFormFragmentDoc = gql`
    fragment BasicForm on Location {
  _id
  name
  licenseName
  categoryId
  urlLogo
  logos {
    id
    path
  }
  city
  district
  address {
    text
  }
  status
  tags
  description
}
    `;
export const LocationsGridFragmentDoc = gql`
    fragment locationsGrid on Location {
  _id
  name
  description
  licenseName
  categoryId
  address {
    text
  }
  district
  city
  status
  createdAt
  lastActiveTracking
  activeDay
  noOfRouter
  noOfAccessPoint
  stats {
    dailyClick
    ctr
    monthlyClick
    monthlyImpression
    dailyImpression
    uniqueUser
  }
  creatorId
  creator {
    name
  }
  campaignRunning {
    campaignName
    isBo
    isActive
    landingPage
    banners {
      query
      bannerId
      bannerName
    }
  }
}
    `;
export const LocationSaleFragmentDoc = gql`
    fragment LocationSale on Location {
  _id
  name
  description
  categoryId
  categoryName
  city
  cityName
  district
  districtName
  address {
    text
  }
  stats {
    monthlyClick
    uniqueUser
    ctr
  }
  status
  pictureIds
  pictures {
    id
    path
  }
  monthlyClick
  noOfAccessPoint
}
    `;
export const LocationPriceFragmentDoc = gql`
    fragment LocationPrice on LocationPrice {
  _id
  name
  description
  categoryId
  city
  bookingType
  district
  price
  categoryPrice
}
    `;
export const LogsFragmentDoc = gql`
    fragment Logs on Logs {
  _id
  type
  description
  userId
  user {
    name
    email
  }
  model
  resourceId
  resourceCampaign {
    name
  }
  old
  new
  createdAt
  updatedAt
}
    `;
export const FullMessageFragmentDoc = gql`
    fragment FullMessage on Message {
  _id
  receiverId
  title
  content
  status
  createdAt
  seenAt
  receiver {
    email
    name
  }
}
    `;
export const BasicNotificationFragmentDoc = gql`
    fragment BasicNotification on Notification {
  _id
  userId
  content
  status
  createdAt
  seenAt
  modelType
  notifi_type
  prioryty
  resourceId
}
    `;
export const FullNotificationFragmentDoc = gql`
    fragment FullNotification on Notification {
  ...BasicNotification
  user {
    ...BasicUser
  }
}
    ${BasicNotificationFragmentDoc}
${BasicUserFragmentDoc}`;
export const FullOrderFragmentDoc = gql`
    fragment FullOrder on Order {
  _id
  plans {
    price
    locationIds
    amount
    planCode
    calPrice
  }
  status
  type
  isRecharge
  createOrderStatus
  queryCount
  paymentProvider
  paymentAmount
  paymentRedirectUrl
  paymentBank
  paymentMessage
  paymentRefNo
  paymentStatus
  createdAt
  updatedAt
  waitedAt
  paidAt
  creatorId
  creator {
    name
    email
  }
  customer {
    name
    email
  }
}
    `;
export const BasicOrderFragmentDoc = gql`
    fragment BasicOrder on Order {
  _id
  status
  type
  isRecharge
  paymentProvider
  paymentAmount
  createdAt
  updatedAt
  waitedAt
  paidAt
}
    `;
export const BasicOwnerFragmentDoc = gql`
    fragment BasicOwner on Owner {
  model
  _id
  userId
  resourceId
}
    `;
export const PaymentFragmentDoc = gql`
    fragment Payment on Payment {
  _id
  userId
  userPayment {
    name
    email
    phone
  }
  type
  amount
  note
  status
  createdAt
  verifiedAt
  verifiedBy
  verifiedByUser {
    name
    email
    phone
  }
  rejectedAt
  rejectReason
}
    `;
export const PlanFragmentDoc = gql`
    fragment Plan on Plan {
  _id
  name
  planCode
  description
  price
  groupDeviceId
  groupDevice {
    text
    meta
  }
  maxOfDevice
  status
  createdAt
  updatedAt
}
    `;
export const FullRegionFragmentDoc = gql`
    fragment FullRegion on Region {
  _id
  type
  value
  text
  city
  area
  createdAt
  updatedAt
}
    `;
export const CampaignRevenueFragmentDoc = gql`
    fragment CampaignRevenue on Revenue {
  campaignId
  campaignName
}
    `;
export const FullRevenueFragmentDoc = gql`
    fragment FullRevenue on Revenue {
  _id
  campaignId
  campaignName
  locations {
    locationId
    locationName
    categoryId
    categoryName
    reportLocationId
    kpiBooked
    kpiRunned
    unitPrice
    unitPricePartner
    revenue
    revenuePartner
    customerId
  }
  customers {
    kpiBooked
    kpiRunned
    revenue
    customerId
  }
  unitPrice
  unitPricePartner
  revenueContracted
  revenuePartnerContracted
  costContracted
  kpiBooked
  kpiRunned
  revenue
  revenuePartner
  typeTip
  tip
  status
  typeFeeBill
  feeBill
  creatorId
  scheduleFrom
  scheduleTo
  createdAt
  updatedAt
}
    `;
export const FullReportSurveyFragmentDoc = gql`
    fragment FullReportSurvey on ReportSurvey {
  ua {
    patch
    device_name
    os
    major
    minor
    os_major
    os_minor
    browser
    os_name
    device
  }
  meta {
    bannerId
    campaignId
    locationId
    realLocationId
    count
  }
  uaString
  createdAt
  updatedAt
  mac
  survey
  audience
}
    `;
export const FullTwoFactorAuthFragmentDoc = gql`
    fragment FullTwoFactorAuth on TwoFactorAuth {
  _id
  userId
  secret
  otpauthUrl
}
    `;
export const UserFormFragmentDoc = gql`
    fragment UserForm on User {
  ...BasicUser
  profile {
    display
  }
}
    ${BasicUserFragmentDoc}`;
export const UserGridFragmentDoc = gql`
    fragment UserGrid on User {
  ...UserForm
  creatorId
  creator {
    name
  }
  createdAt
}
    ${UserFormFragmentDoc}`;
export const AddKpiManagementDocument = gql`
    mutation addKpiManagement($campaignMapping: AddKpiInput!) {
  addKpiManagement(campaignMapping: $campaignMapping) {
    result
    error {
      code
      title
    }
  }
}
    `;
export const AddLocationDocument = gql`
    mutation addLocation($addLocationParams: AddLocationParams!) {
  addLocation(addLocationParams: $addLocationParams) {
    result
    error {
      title
      code
    }
  }
}
    `;
export const AddPricingDocument = gql`
    mutation addPricing($record: PricingInput!) {
  addPricing(record: $record) {
    data {
      _id
      categoryId
      locationId
      price
    }
    error {
      code
    }
  }
}
    `;
export const ApproveCampaignDocument = gql`
    mutation approveCampaign($_id: String!, $allowed: Boolean!, $locations: [CampaignLocationInput!]) {
  approveCampaign(_id: $_id, allowed: $allowed, locations: $locations) {
    data {
      ...FullCampaign
    }
    error {
      code
    }
  }
}
    ${FullCampaignFragmentDoc}`;
export const ChangePasswordDocument = gql`
    mutation changePassword($oldPass: String!, $newPass: String!, $confirmPass: String!) {
  changePassword(oldPass: $oldPass, newPass: $newPass, confirmPass: $confirmPass) {
    data {
      ...FullUser
    }
    error {
      code
    }
  }
}
    ${FullUserFragmentDoc}`;
export const CreateBannerDocument = gql`
    mutation createBanner($record: BannerInput!) {
  createBanner(record: $record) {
    data {
      ...BasicBanner
    }
    error {
      title
      code
    }
  }
}
    ${BasicBannerFragmentDoc}`;
export const CreateBannerTempDocument = gql`
    mutation createBannerTemp {
  createBannerTemp {
    data {
      ...BasicBannerTemp
    }
    error {
      title
      code
    }
  }
}
    ${BasicBannerTempFragmentDoc}`;
export const CreateBannerTemplateDocument = gql`
    mutation createBannerTemplate($record: BannerTemplateInput!) {
  createBannerTemplate(record: $record) {
    data {
      ...BannerTemplate
    }
    error {
      title
      code
    }
  }
}
    ${BannerTemplateFragmentDoc}`;
export const CreateBasicLocationDocument = gql`
    mutation createBasicLocation($record: LocationBasicInput!) {
  createBasicLocation(record: $record) {
    data {
      ...FullLocation
    }
    error {
      code
    }
  }
}
    ${FullLocationFragmentDoc}`;
export const CreateCampaignDocument = gql`
    mutation createCampaign($record: CampaignInput!) {
  createCampaign(record: $record) {
    data {
      ...FullCampaign
    }
    error {
      code
    }
  }
}
    ${FullCampaignFragmentDoc}`;
export const CreateCampaignMappingDocument = gql`
    mutation createCampaignMapping($record: CampaignMappingInput!) {
  createCampaignMapping(record: $record) {
    data {
      ...FullCampaignMapping
    }
    error {
      code
    }
  }
}
    ${FullCampaignMappingFragmentDoc}`;
export const CreateCampaignOnlineDocument = gql`
    mutation createCampaignOnline($data: BookingOnlineArgs!) {
  createCampaignOnline(data: $data) {
    data {
      ...BasicCampaign
    }
    error {
      code
    }
  }
}
    ${BasicCampaignFragmentDoc}`;
export const CreateCommonDocument = gql`
    mutation createCommon($record: CommonInput!) {
  createCommon(record: $record) {
    data {
      ...FullCommon
    }
    error {
      code
    }
  }
}
    ${FullCommonFragmentDoc}`;
export const CreateCustomerDocument = gql`
    mutation createCustomer($record: CustomerInput!) {
  createCustomer(record: $record) {
    data {
      ...BasicCustomer
    }
    error {
      code
    }
  }
}
    ${BasicCustomerFragmentDoc}`;
export const CreateCustomerTypeDocument = gql`
    mutation createCustomerType($record: CustomerTypeInput!) {
  createCustomerType(record: $record) {
    data {
      ...BasicCustomerType
    }
    error {
      code
    }
  }
}
    ${BasicCustomerTypeFragmentDoc}`;
export const CreateEquipmentDocument = gql`
    mutation createEquipment($record: EquipmentInput!) {
  createEquipment(record: $record) {
    data {
      ...fullEquipment
    }
    error {
      code
    }
  }
}
    ${FullEquipmentFragmentDoc}`;
export const CreateErrorLogDocument = gql`
    mutation createErrorLog($record: ErrorLogInput!) {
  createErrorLog(record: $record) {
    data {
      ...FullErrorLog
    }
    error {
      code
    }
  }
}
    ${FullErrorLogFragmentDoc}`;
export const CreateFileDocument = gql`
    mutation createFile($bannerId: String, $fileName: String) {
  createFile(bannerId: $bannerId, fileName: $fileName) {
    data {
      id
      path
    }
    error {
      title
      code
    }
  }
}
    `;
export const CreateImageDocument = gql`
    mutation createImage($record: ImageInput!) {
  createImage(record: $record) {
    data {
      ...Image
    }
    error {
      title
      code
    }
  }
}
    ${ImageFragmentDoc}`;
export const CreateNotificationDocument = gql`
    mutation createNotification($record: NotificationInput!) {
  createNotification(record: $record) {
    data {
      ...FullNotification
    }
    error {
      title
      code
    }
  }
}
    ${FullNotificationFragmentDoc}`;
export const CreatePaymentDocument = gql`
    mutation createPayment($record: PaymentInput!) {
  createPayment(record: $record) {
    data {
      ...Payment
    }
    error {
      code
    }
  }
}
    ${PaymentFragmentDoc}`;
export const CreatePlanDocument = gql`
    mutation createPlan($record: PlanInput!) {
  createPlan(record: $record) {
    data {
      ...Plan
    }
    error {
      title
      code
    }
  }
}
    ${PlanFragmentDoc}`;
export const CreateProfileDocument = gql`
    mutation createProfile($record: ProfileInput!) {
  createProfile(record: $record) {
    data {
      ...BasicProfile
    }
    error {
      code
    }
  }
}
    ${BasicProfileFragmentDoc}`;
export const CreateRegionDocument = gql`
    mutation createRegion($record: RegionInput!) {
  createRegion(record: $record) {
    data {
      ...FullRegion
    }
    error {
      code
    }
  }
}
    ${FullRegionFragmentDoc}`;
export const CreateRevenueDocument = gql`
    mutation createRevenue($campaignId: String!) {
  createRevenue(campaignId: $campaignId) {
    data {
      ...FullRevenue
    }
    error {
      title
      code
    }
  }
}
    ${FullRevenueFragmentDoc}`;
export const CreateTwoFactorAuthenticationDocument = gql`
    mutation createTwoFactorAuthentication {
  createTwoFactorAuthentication {
    data {
      ...FullTwoFactorAuth
    }
    error {
      code
    }
  }
}
    ${FullTwoFactorAuthFragmentDoc}`;
export const CreateUserDocument = gql`
    mutation createUser($record: UserInput!) {
  createUser(record: $record) {
    data {
      ...FullUser
    }
    error {
      code
    }
  }
}
    ${FullUserFragmentDoc}`;
export const CreateUserGuideDocument = gql`
    mutation createUserGuide($title: String!, $content: String!, $brand: String!, $meta: JSON!) {
  createUserGuide(record: {title: $title, content: $content, brand: $brand, meta: $meta}) {
    data {
      meta
      brand
      _id
      content
      title
    }
    error {
      code
      title
    }
  }
}
    `;
export const DeleteAllNotificationDocument = gql`
    mutation deleteAllNotification {
  deleteAllNotification
}
    `;
export const DeleteCampaignByIdDocument = gql`
    mutation deleteCampaignById($_id: String!) {
  deleteCampaignById(_id: $_id) {
    data {
      ...FullCampaign
    }
    error {
      title
      code
    }
  }
}
    ${FullCampaignFragmentDoc}`;
export const DisableCampaignDocument = gql`
    mutation disableCampaign($_id: String!, $duration: Float) {
  disableCampaign(_id: $_id, duration: $duration) {
    data {
      ...FullCampaign
    }
    error {
      title
      code
    }
  }
}
    ${FullCampaignFragmentDoc}`;
export const DisableTwoFactorAuthenticationDocument = gql`
    mutation disableTwoFactorAuthentication {
  disableTwoFactorAuthentication {
    data {
      ...FullTwoFactorAuth
    }
    error {
      code
    }
  }
}
    ${FullTwoFactorAuthFragmentDoc}`;
export const EnableCampaignDocument = gql`
    mutation enableCampaign($_id: String!) {
  enableCampaign(_id: $_id) {
    data {
      ...FullCampaign
    }
    error {
      title
      code
    }
  }
}
    ${FullCampaignFragmentDoc}`;
export const EnableTwoFactorAuthenticationDocument = gql`
    mutation enableTwoFactorAuthentication {
  enableTwoFactorAuthentication {
    data {
      ...FullTwoFactorAuth
    }
    error {
      code
    }
  }
}
    ${FullTwoFactorAuthFragmentDoc}`;
export const LoginDocument = gql`
    mutation login($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    authToken
    data {
      ...FullUser
    }
    error {
      code
      message
    }
  }
}
    ${FullUserFragmentDoc}`;
export const LoginTwoFactorAuthDocument = gql`
    mutation loginTwoFactorAuth($userId: String!, $password: String!, $code: String!) {
  loginTwoFactorAuth(userId: $userId, password: $password, code: $code) {
    authToken
    data {
      ...FullUser
    }
    error {
      code
    }
  }
}
    ${FullUserFragmentDoc}`;
export const LogLogoutDocument = gql`
    mutation logLogout {
  logLogout {
    data {
      ...Logs
    }
    error {
      code
      message
    }
  }
}
    ${LogsFragmentDoc}`;
export const RejectPaymentDocument = gql`
    mutation rejectPayment($_id: String!, $rejectReason: String) {
  rejectPayment(_id: $_id, rejectReason: $rejectReason) {
    data {
      ...Payment
    }
    error {
      title
      code
    }
  }
}
    ${PaymentFragmentDoc}`;
export const RemoveBannerByIdDocument = gql`
    mutation removeBannerById($_id: String!) {
  removeBannerById(_id: $_id) {
    data {
      ...BasicBanner
    }
    error {
      title
      code
    }
  }
}
    ${BasicBannerFragmentDoc}`;
export const RemoveBannerTempByIdDocument = gql`
    mutation removeBannerTempById($_id: String!) {
  removeBannerTempById(_id: $_id) {
    data {
      ...BasicBannerTemp
    }
    error {
      title
      code
    }
  }
}
    ${BasicBannerTempFragmentDoc}`;
export const RemoveBannerTemplateByIdDocument = gql`
    mutation removeBannerTemplateById($_id: String!) {
  removeBannerTemplateById(_id: $_id) {
    data {
      ...BannerTemplate
    }
    error {
      title
      code
    }
  }
}
    ${BannerTemplateFragmentDoc}`;
export const RemoveCommonByIdDocument = gql`
    mutation removeCommonById($_id: String!) {
  removeCommonById(_id: $_id) {
    data {
      ...FullCommon
    }
    error {
      code
    }
  }
}
    ${FullCommonFragmentDoc}`;
export const RemoveCustomerTypeByIdDocument = gql`
    mutation removeCustomerTypeById($_id: String!) {
  removeCustomerTypeById(_id: $_id) {
    data {
      ...BasicCustomerType
    }
    error {
      code
    }
  }
}
    ${BasicCustomerTypeFragmentDoc}`;
export const RemoveEquipmentByIdDocument = gql`
    mutation removeEquipmentById($_id: String!) {
  removeEquipmentById(_id: $_id) {
    data {
      ...fullEquipment
    }
    error {
      code
    }
  }
}
    ${FullEquipmentFragmentDoc}`;
export const RemoveFileByIdDocument = gql`
    mutation removeFileById($id: String!) {
  removeFileById(id: $id) {
    data {
      id
    }
    error {
      title
      code
    }
  }
}
    `;
export const RemoveImageByIdDocument = gql`
    mutation removeImageById($_id: String!) {
  removeImageById(_id: $_id) {
    data {
      ...Image
    }
    error {
      title
      code
    }
  }
}
    ${ImageFragmentDoc}`;
export const RemoveLocationByIdDocument = gql`
    mutation removeLocationById($_id: String!) {
  removeLocationById(_id: $_id) {
    data {
      ...FullLocation
    }
    error {
      code
    }
  }
}
    ${FullLocationFragmentDoc}`;
export const RemoveMessageByIdDocument = gql`
    mutation removeMessageById($_id: String!) {
  removeMessageById(_id: $_id) {
    data {
      ...FullMessage
    }
    error {
      title
      code
    }
  }
}
    ${FullMessageFragmentDoc}`;
export const RemoveNotificationByIdDocument = gql`
    mutation removeNotificationById($_id: String!) {
  removeNotificationById(_id: $_id) {
    data {
      ...BasicNotification
    }
    error {
      title
      code
    }
  }
}
    ${BasicNotificationFragmentDoc}`;
export const RemovePaymentByIdDocument = gql`
    mutation removePaymentById($_id: String!) {
  removePaymentById(_id: $_id) {
    data {
      ...Payment
    }
    error {
      code
    }
  }
}
    ${PaymentFragmentDoc}`;
export const RemovePlanByIdDocument = gql`
    mutation removePlanById($_id: String!) {
  removePlanById(_id: $_id) {
    data {
      ...Plan
    }
    error {
      title
      code
    }
  }
}
    ${PlanFragmentDoc}`;
export const RemovePricingDocument = gql`
    mutation removePricing($categoryId: String, $locationId: String) {
  removePricing(categoryId: $categoryId, locationId: $locationId) {
    data {
      _id
      categoryId
      locationId
      price
    }
    error {
      code
    }
  }
}
    `;
export const RemoveProfileByIdDocument = gql`
    mutation removeProfileById($_id: String!) {
  removeProfileById(_id: $_id) {
    data {
      ...BasicProfile
    }
    error {
      code
      title
    }
  }
}
    ${BasicProfileFragmentDoc}`;
export const RemoveRegionByIdDocument = gql`
    mutation removeRegionById($_id: String!) {
  removeRegionById(_id: $_id) {
    data {
      ...FullRegion
    }
    error {
      code
      title
    }
  }
}
    ${FullRegionFragmentDoc}`;
export const RemoveUserByIdDocument = gql`
    mutation removeUserById($_id: String!) {
  removeUserById(_id: $_id) {
    data {
      ...FullUser
    }
    error {
      code
      title
    }
  }
}
    ${FullUserFragmentDoc}`;
export const RemoveUserGuideByIdDocument = gql`
    mutation removeUserGuideById($_id: String!) {
  removeUserGuideById(_id: $_id) {
    data {
      _id
      title
      content
    }
    error {
      code
      title
    }
  }
}
    `;
export const ResetPasswordDocument = gql`
    mutation resetPassword($userId: String!, $newPass: String) {
  resetPassword(userId: $userId, newPass: $newPass) {
    data {
      ...FullUser
    }
    error {
      code
      title
    }
  }
}
    ${FullUserFragmentDoc}`;
export const RestorePasswordDocument = gql`
    mutation restorePassword($_id: String!, $newPass: String!, $verifyPass: String!) {
  restorePassword(_id: $_id, newPass: $newPass, verifyPass: $verifyPass) {
    data {
      ...FullUser
    }
    error {
      code
      title
    }
  }
}
    ${FullUserFragmentDoc}`;
export const SendEmailVerifyDocument = gql`
    mutation sendEmailVerify($email: String!) {
  sendEmailVerify(email: $email) {
    data {
      name
    }
    error {
      code
    }
  }
}
    `;
export const SendMailByLicenseDocument = gql`
    mutation sendMailByLicense($id: String!, $email: String!) {
  sendMailByLicense(id: $id, email: $email) {
    data {
      email
    }
    error {
      title
      code
    }
  }
}
    `;
export const SetActiveDocument = gql`
    mutation setActive($email: String!, $active: Boolean!) {
  setActive(email: $email, active: $active) {
    data {
      name
      email
    }
    error {
      title
      code
    }
  }
}
    `;
export const SetScheduleDocument = gql`
    mutation setSchedule($_id: String!, $where: SetScheduleArgs!) {
  setSchedule(_id: $_id, where: $where) {
    data {
      scheduleFrom
      scheduleTo
    }
    error {
      title
      code
    }
  }
}
    `;
export const SetTimesDocument = gql`
    mutation setTimes($_id: String!, $times: String!) {
  setTimes(_id: $_id, times: $times) {
    data {
      times
    }
    error {
      title
      code
    }
  }
}
    `;
export const SetValueVerifyEmailDocument = gql`
    mutation setValueVerifyEmail($email: String!) {
  setValueVerifyEmail(email: $email) {
    data {
      name
      email
    }
    error {
      title
      code
    }
  }
}
    `;
export const SignUpDocument = gql`
    mutation signUp($record: UserRegisterInput!) {
  signUp(record: $record) {
    data {
      ...FullUser
    }
    error {
      code
    }
  }
}
    ${FullUserFragmentDoc}`;
export const StopBookingDocument = gql`
    mutation stopBooking($idFilterArg: IdFilterInput!) {
  stopBooking(idFilterArg: $idFilterArg) {
    error {
      code
    }
    data {
      ...FullCampaignMapping
    }
  }
}
    ${FullCampaignMappingFragmentDoc}`;
export const UpdateAdvanceCampaignDocument = gql`
    mutation updateAdvanceCampaign($_id: String!, $record: AdvanceCampaignInfoArgs!) {
  updateAdvanceCampaign(_id: $_id, record: $record) {
    data {
      ...AdvanceCampaignForm
    }
    error {
      code
    }
  }
}
    ${AdvanceCampaignFormFragmentDoc}`;
export const UpdateAdvanceLocationDocument = gql`
    mutation updateAdvanceLocation($_id: String!, $record: AdvanceLocationUpdateArgs!) {
  updateAdvanceLocation(_id: $_id, record: $record) {
    data {
      ...AdvanceForm
    }
    error {
      code
    }
  }
}
    ${AdvanceFormFragmentDoc}`;
export const UpdateBannerActiveDocument = gql`
    mutation updateBannerActive($_id: String!) {
  updateBannerActive(_id: $_id) {
    data {
      ...BasicBanner
    }
    error {
      title
      code
    }
  }
}
    ${BasicBannerFragmentDoc}`;
export const UpdateBannerByIdDocument = gql`
    mutation updateBannerById($_id: String!, $record: BannerUpdateArg!) {
  updateBannerById(_id: $_id, record: $record) {
    data {
      ...BasicBanner
    }
    error {
      title
      code
    }
  }
}
    ${BasicBannerFragmentDoc}`;
export const UpdateBannerTempByIdDocument = gql`
    mutation updateBannerTempById($_id: String!, $record: BannerTempUpdateArg!) {
  updateBannerTempById(_id: $_id, record: $record) {
    data {
      ...BasicBannerTemp
    }
    error {
      title
      code
    }
  }
}
    ${BasicBannerTempFragmentDoc}`;
export const UpdateBannerTemplateByIdDocument = gql`
    mutation updateBannerTemplateById($_id: String!, $record: BannerTemplateUpdateArg!) {
  updateBannerTemplateById(_id: $_id, record: $record) {
    data {
      ...BannerTemplate
    }
    error {
      title
      code
    }
  }
}
    ${BannerTemplateFragmentDoc}`;
export const UpdateBasicLocationDocument = gql`
    mutation updateBasicLocation($_id: String!, $record: LocationBasicInput!) {
  updateBasicLocation(_id: $_id, record: $record) {
    data {
      ...BasicForm
    }
    error {
      code
    }
  }
}
    ${BasicFormFragmentDoc}`;
export const UpdateBookingDocument = gql`
    mutation updateBooking($campaignMapping: CampaignMappingInput!) {
  updateBooking(campaignMapping: $campaignMapping) {
    result
    error {
      code
      title
    }
  }
}
    `;
export const UpdateCalendarBookingDocument = gql`
    mutation updateCalendarBooking($booking: [BookingInput!]!) {
  updateCalendarBooking(booking: $booking) {
    result
    error {
      code
      title
    }
  }
}
    `;
export const UpdateCampaignByIdDocument = gql`
    mutation updateCampaignById($_id: String!, $record: CampaignUpdateArg!) {
  updateCampaignById(_id: $_id, record: $record) {
    data {
      ...FullCampaign
    }
    error {
      code
    }
  }
}
    ${FullCampaignFragmentDoc}`;
export const UpdateCampaignConfigByIdDocument = gql`
    mutation updateCampaignConfigById($id: String!, $record: CampaignConfigUpdateArgs!) {
  updateConfig(id: $id, record: $record) {
    data {
      ...CampaignConfig
    }
    error {
      title
      code
    }
  }
}
    ${CampaignConfigFragmentDoc}`;
export const UpdateCampaignMappingByIdDocument = gql`
    mutation updateCampaignMappingById($_id: String!, $record: CampaignMappingUpdateArg!) {
  updateCampaignMappingById(_id: $_id, record: $record) {
    data {
      ...FullCampaignMapping
    }
    error {
      code
    }
  }
}
    ${FullCampaignMappingFragmentDoc}`;
export const UpdateCampaignPriorityDocument = gql`
    mutation updateCampaignPriority($old: [CMPositionInput!]!, $current: [CMPositionInput!]!, $locationId: String!) {
  updateCampaignPriority(old: $old, current: $current, locationId: $locationId) {
    success
    error {
      code
    }
  }
}
    `;
export const UpdateCommonByIdDocument = gql`
    mutation updateCommonById($_id: String!, $record: CommonUpdateArg!) {
  updateCommonById(_id: $_id, record: $record) {
    data {
      ...FullCommon
    }
    error {
      code
    }
  }
}
    ${FullCommonFragmentDoc}`;
export const UpdateCustomerByIdDocument = gql`
    mutation updateCustomerById($_id: String!, $record: CustomerUpdateArg!) {
  updateCustomerById(_id: $_id, record: $record) {
    data {
      ...BasicCustomer
    }
    error {
      code
    }
  }
}
    ${BasicCustomerFragmentDoc}`;
export const UpdateCustomerTypeByIdDocument = gql`
    mutation updateCustomerTypeById($_id: String!, $record: CustomerTypeUpdateArg!) {
  updateCustomerTypeById(_id: $_id, record: $record) {
    data {
      ...BasicCustomerType
    }
    error {
      code
      title
    }
  }
}
    ${BasicCustomerTypeFragmentDoc}`;
export const UpdateEquipmentByIdDocument = gql`
    mutation updateEquipmentById($_id: String!, $record: EquipmentUpdateArgs!) {
  updateEquipmentById(_id: $_id, record: $record) {
    data {
      ...fullEquipment
    }
    error {
      code
    }
  }
}
    ${FullEquipmentFragmentDoc}`;
export const SendMailForgotPasswordDocument = gql`
    mutation sendMailForgotPassword($email: String!) {
  sendMailForgotPassword(email: $email) {
    data {
      ...FullUser
    }
    error {
      code
      title
    }
  }
}
    ${FullUserFragmentDoc}`;
export const UpdateImageByIdDocument = gql`
    mutation updateImageById($_id: String!, $record: ImageUpdateArg!) {
  updateImageById(_id: $_id, record: $record) {
    data {
      ...Image
    }
    error {
      title
      code
    }
  }
}
    ${ImageFragmentDoc}`;
export const UpdateLocationByIdDocument = gql`
    mutation updateLocationById($_id: String!, $record: LocationUpdateArg!) {
  updateLocationById(_id: $_id, record: $record) {
    data {
      ...FullLocation
    }
    error {
      code
    }
  }
}
    ${FullLocationFragmentDoc}`;
export const UpdateMultiLocationsDocument = gql`
    mutation updateMultiLocations($campaign: [String!]!, $status: String, $locations: [ResultInput!]) {
  updateMultiLocations(campaign: $campaign, status: $status, locations: $locations) {
    success
  }
}
    `;
export const UpdateNotificationByIdDocument = gql`
    mutation updateNotificationById($_id: String!, $record: NotificationUpdateArg!) {
  updateNotificationById(_id: $_id, record: $record) {
    data {
      ...BasicNotification
    }
    error {
      code
    }
  }
}
    ${BasicNotificationFragmentDoc}`;
export const UpdatePlanByIdDocument = gql`
    mutation updatePlanById($_id: String!, $record: PlanUpdateArg!) {
  updatePlanById(_id: $_id, record: $record) {
    data {
      ...Plan
    }
    error {
      title
      code
    }
  }
}
    ${PlanFragmentDoc}`;
export const UpdateProfileByIdDocument = gql`
    mutation updateProfileById($_id: String!, $record: ProfileUpdateArg!) {
  updateProfileById(_id: $_id, record: $record) {
    data {
      ...BasicProfile
    }
    error {
      code
    }
  }
}
    ${BasicProfileFragmentDoc}`;
export const UpdateRegionByIdDocument = gql`
    mutation updateRegionById($_id: String!, $record: RegionUpdateArg!) {
  updateRegionById(_id: $_id, record: $record) {
    data {
      ...FullRegion
    }
    error {
      code
    }
  }
}
    ${FullRegionFragmentDoc}`;
export const UpdateRevenueByIdDocument = gql`
    mutation updateRevenueById($_id: String!, $record: RevenueUpdateArg!) {
  updateRevenueById(_id: $_id, record: $record) {
    data {
      ...FullRevenue
    }
    error {
      title
      code
    }
  }
}
    ${FullRevenueFragmentDoc}`;
export const UpdateRoleDescriptionByIdDocument = gql`
    mutation updateRoleDescriptionById($_id: String!, $description: String!) {
  updateRoleDescriptionById(_id: $_id, description: $description) {
    data {
      ...FullRole
    }
    error {
      code
    }
  }
}
    ${FullRoleFragmentDoc}`;
export const UpdateSaleInfoDocument = gql`
    mutation updateSaleInfo($_id: String!, $record: SaleInfoInput!) {
  updateSaleInfo(_id: $_id, record: $record) {
    data {
      ...saleInfoForm
    }
    error {
      code
    }
  }
}
    ${SaleInfoFormFragmentDoc}`;
export const UpdateSystemInfoDocument = gql`
    mutation updateSystemInfo($_id: String!, $record: SystemInfoInput!) {
  updateSystemInfo(_id: $_id, record: $record) {
    data {
      ...systemInfoForm
    }
    error {
      code
    }
  }
}
    ${SystemInfoFormFragmentDoc}`;
export const UpdateUserByIdDocument = gql`
    mutation updateUserById($_id: String!, $record: UserUpdateArg!) {
  updateUserById(_id: $_id, record: $record) {
    data {
      name
      email
      phone
      address
      active
      emailVerified
      profileId
      ownerType
      twoFactorAuthId
    }
    error {
      code
      title
    }
  }
}
    `;
export const UpdateUserGuideByIdDocument = gql`
    mutation updateUserGuideById($_id: String!, $title: String!, $content: String!, $brand: String!) {
  updateUserGuideById(_id: $_id, record: {title: $title, content: $content, brand: $brand}) {
    data {
      meta
      brand
      _id
      title
      content
    }
    error {
      code
      title
    }
  }
}
    `;
export const UpdateUserInfoByIdDocument = gql`
    mutation updateUserInfoById($_id: String!, $record: UserUpdateInfoArgs!) {
  updateUserInfoById(_id: $_id, record: $record) {
    data {
      ...BasicUser
    }
    error {
      code
    }
  }
}
    ${BasicUserFragmentDoc}`;
export const UpgradeLicenseDocument = gql`
    mutation upgradeLicense($_id: String!) {
  upgradeLicense(id: $_id) {
    data {
      ...FullUser
    }
    error {
      code
      title
    }
  }
}
    ${FullUserFragmentDoc}`;
export const UpsertAssignmentDocument = gql`
    mutation upsertAssignment($model: String!, $userId: String!, $resourceIds: [String!]!) {
  upsertAssignment(model: $model, userId: $userId, resourceIds: $resourceIds) {
    data {
      ...BasicOwner
    }
    error {
      code
      title
    }
  }
}
    ${BasicOwnerFragmentDoc}`;
export const VerifyEmailDocument = gql`
    mutation verifyEmail($key: String!) {
  verifyEmail(key: $key)
}
    `;
export const VerifyEmailLicenseDocument = gql`
    mutation verifyEmailLicense($currentUserId: String!, $email: String!, $key: String!) {
  verifyEmailLicense(id: $currentUserId, email: $email, key: $key) {
    data {
      email
      name
    }
    error {
      title
      code
    }
  }
}
    `;
export const VerifyPaymentDocument = gql`
    mutation verifyPayment($id: String!) {
  verifyPayment(id: $id) {
    data {
      ...Payment
    }
    error {
      title
      code
    }
  }
}
    ${PaymentFragmentDoc}`;
export const VerifyRevenueDocument = gql`
    mutation verifyRevenue($_id: String!) {
  verifyRevenue(_id: $_id) {
    data {
      ...FullRevenue
    }
    error {
      title
      code
    }
  }
}
    ${FullRevenueFragmentDoc}`;
export const CountTrafficDataDocument = gql`
    query CountTrafficData($from: DateTime!, $to: DateTime!) {
  CountTrafficData(from: $from, to: $to) {
    data {
      value
    }
  }
}
    `;
export const CalculateCampaignOnlineKpiDocument = gql`
    query calculateCampaignOnlineKpi($args: CalculateBookingOnlineKpiArgs!) {
  calculateCampaignOnlineKpi(args: $args) {
    data {
      budget
      detail {
        _id
        price
        available
        budget
        kpi
      }
    }
    error {
      code
    }
  }
}
    `;
export const CheckRecaptchaDocument = gql`
    query checkRecaptcha($value: String!) {
  checkRecaptcha(value: $value)
}
    `;
export const CountBalanceHistoryDocument = gql`
    query countBalanceHistory($where: BalanceHistoryFilter) {
  countBalanceHistory(where: $where)
}
    `;
export const CountBannerDocument = gql`
    query countBanner($where: BannerFilter) {
  countBanner(where: $where)
}
    `;
export const CountBannerTemplateDocument = gql`
    query countBannerTemplate($where: BannerTemplateFilter) {
  countBannerTemplate(where: $where)
}
    `;
export const CountCampaignDocument = gql`
    query countCampaign($where: CampaignFilter) {
  countCampaign(where: $where)
}
    `;
export const CountCampaignMappingDocument = gql`
    query countCampaignMapping($where: CampaignMappingFilter) {
  countCampaignMapping(where: $where)
}
    `;
export const CountCommonDocument = gql`
    query countCommon($where: CommonFilter) {
  countCommon(where: $where)
}
    `;
export const CountCustomerDocument = gql`
    query countCustomer($where: CustomerFilter) {
  countCustomer(where: $where)
}
    `;
export const CountEquipmentDocument = gql`
    query countEquipment($where: EquipmentFilter) {
  countEquipment(where: $where)
}
    `;
export const CountFullLogsDocument = gql`
    query countFullLogs($where: LogsFilter) {
  countFullLogs(where: $where)
}
    `;
export const CountImageDocument = gql`
    query countImage($where: ImageFilter) {
  countImage(where: $where)
}
    `;
export const CountLocationDocument = gql`
    query countLocation($where: LocationFilter) {
  countLocation(where: $where)
}
    `;
export const CountLogsDocument = gql`
    query countLogs($where: LogsFilter) {
  countLogs(where: $where)
}
    `;
export const CountMessageDocument = gql`
    query countMessage($where: MessageFilter) {
  countMessage(where: $where)
}
    `;
export const CountNotificationDocument = gql`
    query countNotification($where: NotificationFilter) {
  countNotification(where: $where)
}
    `;
export const CountOrderDocument = gql`
    query countOrder($where: OrderFilter) {
  countOrder(where: $where)
}
    `;
export const CountPaymentDocument = gql`
    query countPayment($where: PaymentFilter) {
  countPayment(where: $where)
}
    `;
export const CountPaymentForUserDocument = gql`
    query countPaymentForUser($where: PaymentFilter) {
  countPaymentForUser(where: $where)
}
    `;
export const CountPlanDocument = gql`
    query countPlan($where: PlanFilter) {
  countPlan(where: $where)
}
    `;
export const CountProfileDocument = gql`
    query countProfile($where: ProfileFilter) {
  countProfile(where: $where)
}
    `;
export const CountRegionDocument = gql`
    query countRegion($where: RegionFilter) {
  countRegion(where: $where)
}
    `;
export const CountReportLocationDataDocument = gql`
    query countReportLocationData($where: RpLocationWhereFilterInput, $dataType: DataType, $skip: Float, $limit: Float) {
  countReportLocationData(where: $where, dataType: $dataType, skip: $skip, limit: $limit)
}
    `;
export const CountReportOnlineSessionDocument = gql`
    query countReportOnlineSession {
  countReportOnlineSession
}
    `;
export const CountRevenueDocument = gql`
    query countRevenue($where: RevenueFilter) {
  countRevenue(where: $where)
}
    `;
export const CountUserDocument = gql`
    query countUser($where: UserFilter) {
  countUser(where: $where)
}
    `;
export const CountUserGuideDocument = gql`
    query countUserGuide($where: UserGuideFilter) {
  countUserGuide(where: $where)
}
    `;
export const CountManyUserWithOwnerDocument = gql`
    query countManyUserWithOwner($limit: Float, $skip: Float, $where: UserOwnerWhereArgs) {
  countManyUserWithOwner(limit: $limit, skip: $skip, where: $where)
}
    `;
export const FetchCampaignMappingDocument = gql`
    query fetchCampaignMapping($campaignId: String!) {
  fetchCampaignMapping(campaignId: $campaignId) {
    _id
    campaignId
    locationId
    location {
      name
      categoryId
    }
    status
    reportLocationId
    reportLocation {
      _id
      name
    }
    scheduleFrom
    scheduleTo
    priority
    kpi
    times
    active
    stats {
      kpi
      click
      kpiCompleted
      kpiRemaining
    }
    todayStats {
      kpi
      click
      kpiCompleted
    }
    yesterdayStats {
      kpi
      click
      kpiCompleted
    }
  }
}
    `;
export const FetchUserFormDocument = gql`
    query fetchUserForm($_id: String!) {
  findUserById(_id: $_id) {
    ...UserForm
  }
}
    ${UserFormFragmentDoc}`;
export const FetchDetailInfoDocument = gql`
    query fetchDetailInfo($_id: String!) {
  findLocationById(_id: $_id) {
    ...detailLocationInfo
  }
}
    ${DetailLocationInfoFragmentDoc}`;
export const FetchAdvanceFormDataDocument = gql`
    query fetchAdvanceFormData($_id: String!) {
  findLocationById(_id: $_id) {
    ...AdvanceForm
  }
}
    ${AdvanceFormFragmentDoc}`;
export const FetchBasicFormDataDocument = gql`
    query fetchBasicFormData($_id: String!) {
  findLocationById(_id: $_id) {
    ...BasicForm
  }
}
    ${BasicFormFragmentDoc}`;
export const FetchLocationCampaignMappingDocument = gql`
    query fetchLocationCampaignMapping($locationId: [String!]!) {
  fetchLocationCampaignMapping(locationId: $locationId) {
    locationId
    campaignName
    campaignId
    scheduleFrom
    scheduleTo
  }
}
    `;
export const FetchLocationMappingDocument = gql`
    query fetchLocationMapping($locationId: String!) {
  fetchLocationMapping(locationId: $locationId) {
    _id
    campaignId
    campaign {
      _id
      name
    }
    locationId
    status
    reportLocationId
    scheduleFrom
    scheduleTo
    priority
    kpi
    times
    active
    stats {
      kpi
      click
      kpiCompleted
    }
    todayStats {
      kpi
      click
      kpiCompleted
    }
    yesterdayStats {
      kpi
      click
      kpiCompleted
    }
  }
}
    `;
export const FetchLocationsGridDocument = gql`
    query fetchLocationsGrid($where: LocationFilter, $limit: Int, $skip: Int, $sort: LocationSort) {
  findManyLocation(where: $where, limit: $limit, skip: $skip, sort: $sort) {
    ...locationsGrid
  }
}
    ${LocationsGridFragmentDoc}`;
export const FetchSaleInfoDocument = gql`
    query fetchSaleInfo($_id: String!) {
  findLocationById(_id: $_id) {
    ...saleInfoForm
  }
}
    ${SaleInfoFormFragmentDoc}`;
export const FetchSystemInfoDocument = gql`
    query fetchSystemInfo($_id: String!) {
  findLocationById(_id: $_id) {
    ...systemInfoForm
  }
}
    ${SystemInfoFormFragmentDoc}`;
export const FetchUserGridDocument = gql`
    query fetchUserGrid($where: UserFilter, $limit: Int, $skip: Int, $sort: UserSort) {
  findManyUser(where: $where, limit: $limit, skip: $skip, sort: $sort) {
    ...UserGrid
  }
}
    ${UserGridFragmentDoc}`;
export const FindAreaDocument = gql`
    query findArea {
  findArea {
    ...FullRegion
  }
}
    ${FullRegionFragmentDoc}`;
export const FindBannerTempByIdDocument = gql`
    query findBannerTempById($_id: String!) {
  findBannerTempById(_id: $_id) {
    ...BasicBannerTemp
  }
}
    ${BasicBannerTempFragmentDoc}`;
export const FindCampaignConfigByIdDocument = gql`
    query findCampaignConfigById($_id: String!) {
  findCampaignById(_id: $_id) {
    ...CampaignConfig
  }
}
    ${CampaignConfigFragmentDoc}`;
export const FindCampaignMappingByIdDocument = gql`
    query findCampaignMappingById($id: String!) {
  findCampaignMappingById(_id: $id) {
    ...FullCampaignMapping
  }
}
    ${FullCampaignMappingFragmentDoc}`;
export const FindCategoryPriceDocument = gql`
    query findCategoryPrice {
  findCategoryPrice {
    categoryId
    categoryName
    categoryIcon
    price
  }
}
    `;
export const FindCityDocument = gql`
    query findCity {
  findCity {
    ...FullRegion
  }
}
    ${FullRegionFragmentDoc}`;
export const FindManyCommonDocument = gql`
    query findManyCommon($where: CommonFilter, $limit: Int, $skip: Int, $sort: CommonSort) {
  findManyCommon(where: $where, limit: $limit, skip: $skip, sort: $sort) {
    ...FullCommon
  }
}
    ${FullCommonFragmentDoc}`;
export const FindCommonBannerDocument = gql`
    query findCommonBanner($where: BannerFilter, $limit: Int, $skip: Int, $sort: BannerSort) {
  findManyBanner(where: $where, limit: $limit, skip: $skip, sort: $sort) {
    ...CommonBanner
  }
}
    ${CommonBannerFragmentDoc}`;
export const FindCommonCampaignDocument = gql`
    query findCommonCampaign($where: CampaignFilter, $limit: Int, $skip: Int, $sort: CampaignSort) {
  findManyCampaign(where: $where, limit: $limit, skip: $skip, sort: $sort) {
    ...CommonCampaign
  }
}
    ${CommonCampaignFragmentDoc}`;
export const FindCommonLocationDocument = gql`
    query findCommonLocation($where: LocationFilter, $limit: Int, $skip: Int, $sort: LocationSort) {
  findManyLocation(where: $where, limit: $limit, skip: $skip, sort: $sort) {
    ...CommonLocation
  }
}
    ${CommonLocationFragmentDoc}`;
export const FindCommonLocationsDocument = gql`
    query findCommonLocations($campaign: [String!]!) {
  findCommonLocations(campaign: $campaign) {
    locationId
    locationName
  }
}
    `;
export const FindCommonTypesDocument = gql`
    query findCommonTypes {
  findCommonTypes
}
    `;
export const FindCustomerByIdDocument = gql`
    query findCustomerById($_id: String!) {
  findCustomerById(_id: $_id) {
    ...BasicCustomer
  }
}
    ${BasicCustomerFragmentDoc}`;
export const FindManyCustomerTypeDocument = gql`
    query findManyCustomerType($where: CustomerTypeFilter, $limit: Int, $skip: Int, $sort: CustomerTypeSort) {
  findManyCustomerType(where: $where, limit: $limit, skip: $skip, sort: $sort) {
    ...BasicCustomerType
  }
}
    ${BasicCustomerTypeFragmentDoc}`;
export const CountCustomerTypeDocument = gql`
    query countCustomerType($where: CustomerTypeFilter) {
  countCustomerType(where: $where)
}
    `;
export const FindDeviceDataByLocationDocument = gql`
    query findDeviceDataByLocation($categoryId: String, $locationId: String) {
  findDeviceDataByLocation(categoryId: $categoryId, locationId: $locationId) {
    data {
      total
      statistic {
        os {
          iOS
          Android
          Windows
          other
        }
        device {
          Mobile
          PC
          Tablet
        }
      }
    }
    error {
      code
    }
  }
}
    `;
export const FindDistrictDocument = gql`
    query findDistrict {
  findDistrict {
    ...FullRegion
  }
}
    ${FullRegionFragmentDoc}`;
export const FindFileByIdDocument = gql`
    query findFileById($id: String!) {
  findFileById(id: $id) {
    data {
      id
      path
    }
    error {
      title
      code
    }
  }
}
    `;
export const FindFullLogsDocument = gql`
    query findFullLogs($where: LogsFilter, $limit: Int, $skip: Int, $sort: LogsSort) {
  findFullLogs(where: $where, limit: $limit, skip: $skip, sort: $sort) {
    ...Logs
  }
}
    ${LogsFragmentDoc}`;
export const FindLocationByIdDocument = gql`
    query findLocationById($_id: String!) {
  findLocationById(_id: $_id) {
    ...FullLocation
  }
}
    ${FullLocationFragmentDoc}`;
export const FindLocationPriceDocument = gql`
    query findLocationPrice($where: LocationFilter, $limit: Int, $skip: Int, $sort: LocationSort) {
  findLocationPrice(where: $where, limit: $limit, skip: $skip, sort: $sort) {
    ...LocationPrice
  }
}
    ${LocationPriceFragmentDoc}`;
export const FindBasicLocationPriceDocument = gql`
    query findBasicLocationPrice($where: LocationFilter, $limit: Int, $skip: Int, $sort: LocationSort) {
  findLocationPrice(where: $where, limit: $limit, skip: $skip, sort: $sort) {
    ...LocationPrice
    stats {
      monthlyClick
    }
    status
    monthlyClick
    noOfAccessPoint
  }
}
    ${LocationPriceFragmentDoc}`;
export const FindMacEquipmentDocument = gql`
    query findMacEquipment($where: EquipmentFilter, $limit: Int, $skip: Int, $sort: EquipmentSort) {
  findManyEquipment(where: $where, limit: $limit, skip: $skip, sort: $sort) {
    ...commonEquipment
  }
}
    ${CommonEquipmentFragmentDoc}`;
export const FindManyBalanceHistoryDocument = gql`
    query findManyBalanceHistory($where: BalanceHistoryFilter, $limit: Int, $skip: Int, $sort: BalanceHistorySort) {
  findManyBalanceHistory(where: $where, limit: $limit, skip: $skip, sort: $sort) {
    ...FullBalanceHistory
  }
}
    ${FullBalanceHistoryFragmentDoc}`;
export const FindManyBannerDocument = gql`
    query findManyBanner($where: BannerFilter, $limit: Int, $skip: Int, $sort: BannerSort) {
  findManyBanner(where: $where, limit: $limit, skip: $skip, sort: $sort) {
    ...FullBanner
  }
}
    ${FullBannerFragmentDoc}`;
export const FindManyBannerTemplateDocument = gql`
    query findManyBannerTemplate($where: BannerTemplateFilter, $limit: Int, $skip: Int, $sort: BannerTemplateSort) {
  findManyBannerTemplate(where: $where, limit: $limit, skip: $skip, sort: $sort) {
    ...BannerTemplate
  }
}
    ${BannerTemplateFragmentDoc}`;
export const FindManyCampaignDocument = gql`
    query findManyCampaign($where: CampaignFilter, $limit: Int, $skip: Int, $sort: CampaignSort) {
  findManyCampaign(where: $where, limit: $limit, skip: $skip, sort: $sort) {
    ...FullCampaign
  }
}
    ${FullCampaignFragmentDoc}`;
export const FindManyReportCampaignDocument = gql`
    query findManyReportCampaign($where: CampaignFilter, $limit: Int, $skip: Int, $sort: CampaignSort) {
  findManyCampaign(where: $where, limit: $limit, skip: $skip, sort: $sort) {
    ...ReportCampaignList
  }
}
    ${ReportCampaignListFragmentDoc}`;
export const FindManyCampaignIdAndNameDocument = gql`
    query findManyCampaignIdAndName($where: CampaignFilter, $limit: Int, $skip: Int, $sort: CampaignSort) {
  findManyCampaign(where: $where, limit: $limit, skip: $skip, sort: $sort) {
    _id
    name
  }
}
    `;
export const FindManyCampaignMappingDocument = gql`
    query findManyCampaignMapping($where: CampaignMappingFilter, $limit: Int, $skip: Int, $sort: CampaignMappingSort) {
  findManyCampaignMapping(where: $where, limit: $limit, skip: $skip, sort: $sort) {
    ...FullCampaignMapping
  }
}
    ${FullCampaignMappingFragmentDoc}`;
export const FindManyCustomerDocument = gql`
    query findManyCustomer($where: CustomerFilter, $sort: CustomerSort, $skip: Int, $limit: Int) {
  findManyCustomer(where: $where, sort: $sort, skip: $skip, limit: $limit) {
    ...BasicCustomer
  }
}
    ${BasicCustomerFragmentDoc}`;
export const FindManyEquipmentDocument = gql`
    query findManyEquipment($where: EquipmentFilter, $limit: Int, $skip: Int, $sort: EquipmentSort) {
  findManyEquipment(where: $where, limit: $limit, skip: $skip, sort: $sort) {
    ...fullEquipment
  }
}
    ${FullEquipmentFragmentDoc}`;
export const FindManyImageDocument = gql`
    query findManyImage($where: ImageFilter, $limit: Int, $skip: Int, $sort: ImageSort) {
  findManyImage(where: $where, limit: $limit, skip: $skip, sort: $sort) {
    ...Image
  }
}
    ${ImageFragmentDoc}`;
export const FindManyLocationDocument = gql`
    query findManyLocation($where: LocationFilter, $limit: Int, $skip: Int, $sort: LocationSort) {
  findManyLocation(where: $where, limit: $limit, skip: $skip, sort: $sort) {
    ...FullLocation
  }
}
    ${FullLocationFragmentDoc}`;
export const FindManyLocationCategoryDocument = gql`
    query findManyLocationCategory($where: CommonFilter, $limit: Int, $skip: Int, $sort: CommonSort) {
  findManyCommon(where: $where, limit: $limit, skip: $skip, sort: $sort) {
    ...CommonLocationCategory
  }
}
    ${CommonLocationCategoryFragmentDoc}`;
export const FindManyLocationIdAndNameDocument = gql`
    query findManyLocationIdAndName($where: LocationFilter, $limit: Int, $skip: Int, $sort: LocationSort) {
  findManyLocation(where: $where, limit: $limit, skip: $skip, sort: $sort) {
    _id
    name
  }
}
    `;
export const FindManyLogsDocument = gql`
    query findManyLogs($where: LogsFilter, $limit: Int, $skip: Int, $sort: LogsSort) {
  findManyLogs(where: $where, limit: $limit, skip: $skip, sort: $sort) {
    ...Logs
  }
}
    ${LogsFragmentDoc}`;
export const FindManyMessageDocument = gql`
    query findManyMessage($where: MessageFilter, $limit: Int, $skip: Int, $sort: MessageSort) {
  findManyMessage(where: $where, limit: $limit, skip: $skip, sort: $sort) {
    ...FullMessage
  }
}
    ${FullMessageFragmentDoc}`;
export const FindManyNotificationDocument = gql`
    query findManyNotification($where: NotificationFilter, $limit: Int, $skip: Int, $sort: NotificationSort) {
  findManyNotification(where: $where, limit: $limit, skip: $skip, sort: $sort) {
    ...FullNotification
  }
}
    ${FullNotificationFragmentDoc}`;
export const FindManyOrderDocument = gql`
    query findManyOrder($where: OrderFilter, $limit: Int, $skip: Int, $sort: OrderSort) {
  findManyOrder(where: $where, limit: $limit, skip: $skip, sort: $sort) {
    ...BasicOrder
  }
}
    ${BasicOrderFragmentDoc}`;
export const FindManyOwnerByUserIdDocument = gql`
    query findManyOwnerByUserId($where: OwnerFilter, $limit: Int, $skip: Int, $sort: OwnerSort) {
  findManyOwner(where: $where, limit: $limit, skip: $skip, sort: $sort) {
    ...BasicOwner
  }
}
    ${BasicOwnerFragmentDoc}`;
export const FindManyPaymentDocument = gql`
    query findManyPayment($where: PaymentFilter, $limit: Int, $skip: Int, $sort: PaymentSort) {
  findManyPayment(where: $where, limit: $limit, skip: $skip, sort: $sort) {
    ...Payment
  }
}
    ${PaymentFragmentDoc}`;
export const FindManyPaymentForUserDocument = gql`
    query findManyPaymentForUser($where: PaymentFilter, $limit: Int, $skip: Int, $sort: PaymentSort) {
  findManyPaymentForUser(where: $where, limit: $limit, skip: $skip, sort: $sort) {
    ...Payment
  }
}
    ${PaymentFragmentDoc}`;
export const FindManyPlanDocument = gql`
    query findManyPlan($where: PlanFilter, $limit: Int, $skip: Int, $sort: PlanSort) {
  findManyPlan(where: $where, limit: $limit, skip: $skip, sort: $sort) {
    ...Plan
  }
}
    ${PlanFragmentDoc}`;
export const FindManyPricingHistoryDocument = gql`
    query findManyPricingHistory($where: PricingHistoryFilter, $limit: Int, $skip: Int, $sort: PricingHistorySort) {
  findManyPricingHistory(where: $where, limit: $limit, skip: $skip, sort: $sort) {
    _id
    categoryId
    locationId
    price
    createdAt
    creatorId
    creator {
      name
    }
  }
}
    `;
export const FindManyProfileMinimalDocument = gql`
    query findManyProfileMinimal($where: ProfileFilter, $limit: Int, $skip: Int, $sort: ProfileSort) {
  findManyProfile(where: $where, limit: $limit, skip: $skip, sort: $sort) {
    ...MinimalProfile
  }
}
    ${MinimalProfileFragmentDoc}`;
export const FindManyProfileBasicDocument = gql`
    query findManyProfileBasic($where: ProfileFilter, $limit: Int, $skip: Int, $sort: ProfileSort) {
  findManyProfile(where: $where, limit: $limit, skip: $skip, sort: $sort) {
    ...BasicProfile
  }
}
    ${BasicProfileFragmentDoc}`;
export const FindManyProfileDocument = gql`
    query findManyProfile($where: ProfileFilter, $limit: Int, $skip: Int, $sort: ProfileSort) {
  findManyProfile(where: $where, limit: $limit, skip: $skip, sort: $sort) {
    ...FullProfile
  }
}
    ${FullProfileFragmentDoc}`;
export const FindManyProfileIdAndDisplayDocument = gql`
    query findManyProfileIdAndDisplay($where: ProfileFilter, $limit: Int, $skip: Int, $sort: ProfileSort) {
  findManyProfile(where: $where, limit: $limit, skip: $skip, sort: $sort) {
    _id
    display
    description
  }
}
    `;
export const FindManyRegionDocument = gql`
    query findManyRegion($where: RegionFilter, $limit: Int, $skip: Int, $sort: RegionSort) {
  findManyRegion(where: $where, limit: $limit, skip: $skip, sort: $sort) {
    ...FullRegion
  }
}
    ${FullRegionFragmentDoc}`;
export const FindManyRevenueDocument = gql`
    query findManyRevenue($where: RevenueFilter, $limit: Int, $skip: Int, $sort: RevenueSort) {
  findManyRevenue(where: $where, limit: $limit, skip: $skip, sort: $sort) {
    ...FullRevenue
  }
}
    ${FullRevenueFragmentDoc}`;
export const FindManyRoleDocument = gql`
    query findManyRole($where: RoleFilter, $limit: Int, $skip: Int, $sort: RoleSort) {
  findManyRole(where: $where, limit: $limit, skip: $skip, sort: $sort) {
    ...FullRole
  }
}
    ${FullRoleFragmentDoc}`;
export const FindManyUserDocument = gql`
    query findManyUser($where: UserFilter, $limit: Int, $skip: Int, $sort: UserSort) {
  findManyUser(where: $where, limit: $limit, skip: $skip, sort: $sort) {
    ...FullUser
  }
}
    ${FullUserFragmentDoc}`;
export const FindManyUserGuideDocument = gql`
    query findManyUserGuide($where: UserGuideFilter, $limit: Int, $skip: Int, $sort: UserGuideSort) {
  findManyUserGuide(where: $where, limit: $limit, skip: $skip, sort: $sort) {
    _id
    title
    content
    meta
    linkVideo
    faq
    brand
  }
}
    `;
export const FindManyUserIdDocument = gql`
    query findManyUserId($where: UserFilter, $limit: Int, $skip: Int, $sort: UserSort) {
  findManyUser(where: $where, limit: $limit, skip: $skip, sort: $sort) {
    _id
    name
    email
    profile {
      display
    }
  }
}
    `;
export const FindManyUserWithOwnerDocument = gql`
    query findManyUserWithOwner($limit: Float, $skip: Float, $where: UserOwnerWhereArgs) {
  findManyUserWithOwner(limit: $limit, skip: $skip, where: $where) {
    ... on User {
      _id
      name
      email
      profile {
        display
      }
    }
    ... on Owner {
      _id
      userId
      resourceId
      model
    }
  }
}
    `;
export const MeDocument = gql`
    query me {
  me {
    data {
      ...FullUser
    }
    error {
      code
    }
  }
}
    ${FullUserFragmentDoc}`;
export const FindMessageByIdDocument = gql`
    query findMessageById($_id: String!) {
  findMessageById(_id: $_id) {
    ...FullMessage
  }
}
    ${FullMessageFragmentDoc}`;
export const FindNotificationByIdDocument = gql`
    query findNotificationById($_id: String!) {
  findNotificationById(_id: $_id) {
    ...FullNotification
  }
}
    ${FullNotificationFragmentDoc}`;
export const FindOneCampaignDocument = gql`
    query findOneCampaign($_id: ID!) {
  findOneCampaign(where: {_id: $_id}) {
    ...FullCampaign
  }
}
    ${FullCampaignFragmentDoc}`;
export const FindOneLocationDocument = gql`
    query findOneLocation($where: LocationFilter, $sort: LocationSort) {
  findOneLocation(where: $where, sort: $sort) {
    ...FullLocation
  }
}
    ${FullLocationFragmentDoc}`;
export const FindOneRevenueDocument = gql`
    query findOneRevenue($where: RevenueFilter, $sort: RevenueSort) {
  findOneRevenue(where: $where, sort: $sort) {
    ...FullRevenue
  }
}
    ${FullRevenueFragmentDoc}`;
export const FindOrderByIdDocument = gql`
    query findOrderById($_id: String!) {
  findOrderById(_id: $_id) {
    ...FullOrder
  }
}
    ${FullOrderFragmentDoc}`;
export const FindManyOwnerEquipmentDocument = gql`
    query findManyOwnerEquipment($where: EquipmentFilter, $limit: Int, $skip: Int, $sort: EquipmentSort) {
  findManyEquipment(where: $where, limit: $limit, skip: $skip, sort: $sort) {
    ...commonOwnerTypeEquipment
  }
}
    ${CommonOwnerTypeEquipmentFragmentDoc}`;
export const FindProfileByIdDocument = gql`
    query findProfileById($_id: String!) {
  findProfileById(_id: $_id) {
    ...FullProfile
  }
}
    ${FullProfileFragmentDoc}`;
export const FindRevenueByIdDocument = gql`
    query findRevenueById($_id: String!) {
  findRevenueById(_id: $_id) {
    ...FullRevenue
  }
}
    ${FullRevenueFragmentDoc}`;
export const GetActiveCampaignsDocument = gql`
    query getActiveCampaigns($where: LocationFilter, $limit: Int, $skip: Int, $sort: LocationSort) {
  getActiveCampaigns(where: $where, limit: $limit, skip: $skip, sort: $sort) {
    _id
    name
    licenseName
    categoryId
    ownerType
    group
    activeCampaigns {
      _id
      name
      kpi
      config {
        banners {
          bannerId
          bannerName
        }
      }
    }
  }
}
    `;
export const AlertLocationDocument = gql`
    query alertLocation($dateCheck: Int, $numberCheck: Int) {
  alertLocation(dateCheck: $dateCheck, numberCheck: $numberCheck) {
    data {
      locationId
      locationName
      city
      click
      categoryId
    }
  }
}
    `;
export const GetAvailableBookingDocument = gql`
    query getAvailableBooking($locationIds: [String!], $categoryIds: [String!], $date: DateFilterInput!, $interval: String, $detail: Boolean) {
  getAvailableBooking(locationIds: $locationIds, categoryIds: $categoryIds, date: $date, interval: $interval, detail: $detail) {
    locations {
      _id
      name
      bookingType
      availableTotalKpi
      bookingKpi
      categoryId
      expectedKpi
      group
      everyDay {
        date
        availableKpi
      }
      percent
    }
    categories {
      categoryId
      bookingKpi
      availableKpi
      expectedKpi
      percent
    }
  }
}
    `;
export const GetCampaignByLocationIdDocument = gql`
    query getCampaignByLocationId($locationId: String!) {
  getCampaignByLocationId(locationId: $locationId) {
    _id
    name
    status
    ownerType
  }
}
    `;
export const GetCampaignRevenueDocument = gql`
    query getCampaignRevenue($where: RevenueFilter) {
  findManyRevenue(where: $where) {
    ...CampaignRevenue
  }
}
    ${CampaignRevenueFragmentDoc}`;
export const GetCategoriesDocument = gql`
    query getCategories {
  getCategories {
    ...CommonLocationCategory
  }
}
    ${CommonLocationCategoryFragmentDoc}`;
export const GetDataAudienceDocument = gql`
    query getDataAudience {
  getDataAudience {
    totalAge
    totalGender
    totalIncome
    genders {
      gender
      count
    }
    ages {
      age
      count
    }
    incomes {
      income
      count
    }
  }
}
    `;
export const GetDataTrafficOverviewDocument = gql`
    query getDataTrafficOverview {
  getDataTrafficOverview {
    dataYearAgo
    dataYear
  }
}
    `;
export const GetTotalUsersDocument = gql`
    query getTotalUsers($locationId: String) {
  getTotalUsers(locationId: $locationId)
}
    `;
export const GetHotspotOnOffDocument = gql`
    query getHotspotOnOff {
  getHotspotOnOff {
    on
    total
  }
}
    `;
export const GetLocationAdsDocument = gql`
    query getLocationAds($ownerType: String!) {
  getLocationAds(ownerType: $ownerType)
}
    `;
export const GetLocationClick3MonthDocument = gql`
    query getLocationClick3Month($locationId: String!) {
  getLocationClick3Month(locationId: $locationId) {
    data {
      click
      month
    }
    error {
      title
      code
    }
  }
}
    `;
export const GetDetailDocument = gql`
    query getDetail($where: GetDetailLocationWhere, $rowGroupCols: [ColumnVO!], $valueCols: [ColumnVO!], $groupKeys: [String!]) {
  getDetail(where: $where, rowGroupCols: $rowGroupCols, valueCols: $valueCols, groupKeys: $groupKeys) {
    categoryId
    categoryName
    locationId
    locationName
    ctr
    click
    impression
    createdAt
    bannerId
    bannerName
  }
}
    `;
export const GetOnlineSessionDocument = gql`
    query getOnlineSession($locationId: String) {
  getOnlineSession(locationId: $locationId) {
    detail {
      action
      mac
      ua {
        browser
        major
        minor
        patch
        os
        os_name
        os_major
        os_minor
        device
        device_name
      }
      uaString
      ip
      createdAt
      updatedAt
      meta {
        locationId
        campaignId
        bannerId
        realLocationId
      }
    }
    total
  }
}
    `;
export const GetRenderCodeDocument = gql`
    query getRenderCode($locationId: String, $equipmentId: String, $isExternal: String, $macFieldName: String) {
  getRenderCode(locationId: $locationId, equipmentId: $equipmentId, isExternal: $isExternal, macFieldName: $macFieldName)
}
    `;
export const GetReportCampaignInfoDocument = gql`
    query getReportCampaignInfo($campaignId: String!) {
  getReportCampaignInfo(campaignId: $campaignId) {
    data {
      name
      type
      status
      scheduleTo
      scheduleFrom
      todayClick
      config {
        crm
        crmValue
        landingPageUrl
        banners {
          bannerName
          bannerId
        }
      }
      locations {
        locationId
        locationName
        categoryId
        categoryName
        tag
        locationGroup
      }
      stats {
        kpi
        kpiCompleted
        completed
        click
        kpiRemaining
      }
      customs
    }
    error {
      title
      code
    }
  }
}
    `;
export const GetReportCampaignOsDeviceDocument = gql`
    query getReportCampaignOsDevice($campaignId: String!, $filter: CampaignSurveyFilter) {
  getReportCampaignOsDevice(campaignId: $campaignId, filter: $filter) {
    os {
      iOS
      Android
      Windows
      other
    }
    device {
      Mobile
      PC
      Tablet
    }
    brandName
    deviceName
    error {
      code
    }
  }
}
    `;
export const GetReportCampaignPartnerDocument = gql`
    query getReportCampaignPartner($campaignId: [String!]!, $locationId: [String!]!, $date: DateArgs!, $interval: Interval) {
  getReportCampaignPartner(campaignId: $campaignId, locationId: $locationId, date: $date, interval: $interval) {
    total {
      click
      impression
      ctr
    }
    byDate {
      click
      impression
      ctr
      date
    }
    error {
      code
    }
  }
}
    `;
export const GetReportCampaignRatioDocument = gql`
    query getReportCampaignRatio($filter: CampaignRatioFilter, $skip: Float, $limit: Float) {
  getReportCampaignRatio(filter: $filter, skip: $skip, limit: $limit) {
    click
    ratio
    type
    detail {
      click
      ratio
      date
    }
  }
}
    `;
export const GetReportCampaignStatisticDocument = gql`
    query getReportCampaignStatistic($campaignId: String!, $date: DateArgs!, $locationId: [String!], $categoryId: [String!], $bannerId: [String!], $interval: Interval) {
  getReportCampaignStatistic(campaignId: $campaignId, date: $date, locationId: $locationId, categoryId: $categoryId, bannerId: $bannerId, interval: $interval) {
    total {
      click
      impression
      ctr
    }
    byDate {
      click
      impression
      ctr
      date
    }
    error {
      title
      code
    }
  }
}
    `;
export const GetReportCampaignSurveyDocument = gql`
    query getReportCampaignSurvey($campaignId: String!, $filter: CampaignSurveyFilter, $skip: Float) {
  getReportCampaignSurvey(campaignId: $campaignId, filter: $filter, skip: $skip) {
    data {
      createdAt
      meta {
        bannerId
        campaignId
        locationId
        realLocationId
        bannerName
        locationName
        count
      }
      ua {
        browser
        major
        minor
        patch
        os
        os_name
        os_major
        os_minor
        device
        device_name
        deviceName
        deviceCode
        brandName
      }
      uaString
      mac
      updatedAt
      survey
      audience
    }
    total
    error {
      code
    }
  }
}
    `;
export const GetDataOsDeviceDocument = gql`
    query getDataOSDevice($locationId: String, $dateFilter: DateArgs!) {
  getDataOSDevice(locationId: $locationId, dateFilter: $dateFilter) {
    osCount {
      iOS
      Android
      Windows
      other
    }
    deviceTypeCount {
      Mobile
      PC
      Tablet
    }
  }
}
    `;
export const GetReportIncomeByCampaignDocument = gql`
    query getReportIncomeByCampaign($filter: IncomeFilter) {
  getReportIncomeByCampaign(filter: $filter) {
    income
    incomePartner
    click
    groupByLocation {
      locationId
      location {
        name
        licenseName
        city
        district
      }
      income
      incomePartner
      click
    }
    time {
      date
      income
      incomePartner
      click
    }
  }
}
    `;
export const GetReportIncomeByLocationDocument = gql`
    query getReportIncomeByLocation($filter: IncomeFilter) {
  getReportIncomeByLocation(filter: $filter) {
    income
    incomePartner
    click
    groupByCampaign {
      campaignName
      campaignId
      income
      incomePartner
      click
    }
    groupByLocation {
      locationId
      location {
        name
        licenseName
        city
        district
      }
      income
      incomePartner
      click
    }
    time {
      date
      income
      incomePartner
      click
    }
  }
}
    `;
export const GetReportIncomePartnerDocument = gql`
    query getReportIncomePartner($filter: IncomeFilter) {
  getReportIncomePartner(filter: $filter) {
    income
    incomePartner
    click
    groupByCampaign {
      campaignName
      campaignId
      click
      incomePartner
    }
    groupByLocation {
      locationId
      location {
        name
        licenseName
        city
        district
      }
      incomePartner
      click
    }
    time {
      date
      click
      incomePartner
    }
  }
}
    `;
export const GetReportLocationDataDocument = gql`
    query getReportLocationData($where: RpLocationWhereFilterInput, $dataType: DataType, $skip: Float, $limit: Float) {
  getReportLocationData(where: $where, dataType: $dataType, skip: $skip, limit: $limit) {
    data {
      click
      impression
      ctr
      date
      city
      district
      locationName
      categoryName
      locationId
      categoryId
    }
    total {
      click
      impression
      ctr
      users
    }
  }
}
    `;
export const GetReportLocationUpDownDocument = gql`
    query getReportLocationUpDown($where: RpLocationWhereFilterInput, $dataType: DataType, $skip: Float, $limit: Float, $amongTime: Float) {
  getReportLocationUpDown(where: $where, dataType: $dataType, skip: $skip, limit: $limit, amongTime: $amongTime) {
    data {
      click
      clickBefore
      impression
      impressionBefore
      ctr
      date
      city
      district
      locationName
      categoryName
      locationId
      categoryId
    }
    total {
      click
      clickBefore
      impression
      impressionBefore
      ctr
      users
    }
  }
}
    `;
export const GetDataLoginDocument = gql`
    query getDataLogin($locationId: String) {
  getDataLogin(locationId: $locationId) {
    locationId
    time
    day
    hour
  }
}
    `;
export const GetReportOnlineSessionDocument = gql`
    query getReportOnlineSession($skip: Float!, $limit: Float!) {
  getReportOnlineSession(skip: $skip, limit: $limit) {
    os
    deviceType
    apMac
    deviceMac
    deviceName
    deviceCode
    device_name
    locationName
    startedAt
  }
}
    `;
export const GetReportSharedTrafficDetailsDocument = gql`
    query getReportSharedTrafficDetails($filter: SharedTrafficFilter, $skip: Float, $limit: Float) {
  getReportSharedTrafficDetails(filter: $filter, skip: $skip, limit: $limit) {
    _id
    name
    total
    owned
    totalImpression
    totalClick
    ratioImpression
    ratioClick
    ownedImpression
    ownedClick
    sharedRatio
    detail {
      date
      totalImpression
      totalClick
      ratioImpression
      ratioClick
      ownedImpression
      ownedClick
    }
  }
}
    `;
export const GetReportTodayLoginDocument = gql`
    query getReportTodayLogin {
  getReportTodayLogin {
    hour
    count
  }
}
    `;
export const GetTrackingDocument = gql`
    query getTracking($locationId: [String!], $campaignId: String, $dateFrom: DateTime!, $dateTo: DateTime!, $bannerId: String, $categoryId: String, $view: View!, $tab: String) {
  getTracking(locationId: $locationId, campaignId: $campaignId, dateFrom: $dateFrom, dateTo: $dateTo, bannerId: $bannerId, view: $view, tab: $tab, categoryId: $categoryId) {
    groupByDay {
      click
      impression
      ctr
      date
    }
    total {
      click
      impression
      ctr
    }
    detail {
      categoryId
      campaignId
      createdAt
      locationId
      categoryName
      locationName
      bannerId
      bannerName
      impression
      click
      ctr
    }
  }
}
    `;
export const GetReportTrackingDetailDocument = gql`
    query getReportTrackingDetail($externalFilter: TrackingDetailExternalFilter, $internalFilter: TrackingDetailInternalFilter, $date: DateArgs!) {
  getReportTrackingDetail(externalFilter: $externalFilter, internalFilter: $internalFilter, date: $date) {
    data {
      click
      impression
      ctr
      categoryId
      categoryName
      bannerId
      bannerName
      locationId
      locationName
      createdAt
    }
    error {
      title
      code
    }
  }
}
    `;
export const GetReportUserReturnDocument = gql`
    query getReportUserReturn($where: RpLocationWhereFilterInput, $dataType: DataType, $skip: Float, $limit: Float) {
  getReportUserReturn(where: $where, dataType: $dataType, skip: $skip, limit: $limit) {
    data {
      name
      y
    }
    total {
      click
      impression
      ctr
      users
    }
  }
}
    `;
export const GetDataVisitDocument = gql`
    query getDataVisit($locationId: String) {
  getDataVisit(locationId: $locationId) {
    last7Days {
      once
      twoFive
      sixTen
      tenPlus
    }
    last15Days {
      once
      twoFive
      sixTen
      tenPlus
    }
    last30Days {
      once
      twoFive
      sixTen
      tenPlus
    }
  }
}
    `;
export const GetSurveyCampaignDocument = gql`
    query getSurveyCampaign($params: SurveyFilterInput!) {
  getSurveyCampaign(params: $params) {
    data {
      createdAt
      updatedAt
      meta {
        bannerId
        bannerName
        campaignId
        locationId
        locationName
        realLocationId
        count
      }
      ua {
        patch
        device_name
        os
        major
        minor
        browser
        os_name
        device
      }
      uaString
      mac
      survey
      audience
    }
    total
    os
    device {
      Mobile
      PC
      Tablet
    }
    config {
      audience {
        acceptRules {
          query
          queryTree
        }
      }
      landingPageUrl
      impressionUrl
      crm
      crmValue
    }
    error {
      code
    }
  }
}
    `;
export const GetTotalCampaignDocument = gql`
    query getTotalCampaign {
  getTotalCampaign {
    runningCampaigns
    advertiserCampaigns
    defaultCampaigns
    otherCampaign
    advertiserKpi
    advertiserClick
    newCampaigns
    enddingCampaigns
  }
}
    `;
export const GetTotalLocationActiveDocument = gql`
    query getTotalLocationActive($ownerType: String!) {
  getTotalLocationActive(ownerType: $ownerType) {
    categoryId
    categoryIcon
    categoryName
    countLocationActive
    countLocationLicense
    countLocationMGN
    countTotalLocationCategory
    sumMonthlyClick
    ctrStats
    uniqueUser
  }
}
    `;
export const GetTotalStatusLocationDocument = gql`
    query getTotalStatusLocation($ownerType: String!) {
  getTotalStatusLocation(ownerType: $ownerType) {
    allLocation {
      categoryId
      countAllLocation
    }
    locationActive {
      categoryId
      countLocationActive
    }
  }
}
    `;
export const GetTotalTrafficDocument = gql`
    query getTotalTraffic($locationId: String) {
  getTotalTraffic(locationId: $locationId) {
    today {
      total
      detail {
        count
        hour
      }
    }
    thisMonth
  }
}
    `;
export const GetTotalUserLicensesDocument = gql`
    query getTotalUserLicenses {
  getTotalUserLicenses {
    ownerTypeUser
    demoUser
    realUser
    newMonthUser
  }
}
    `;
export const GetTrackingByLocationDocument = gql`
    query getTrackingByLocation($id: String, $date: DateTime) {
  getTrackingByLocation(id: $id, date: $date) {
    todayLogin {
      hour
      count
    }
    onlineSession {
      os
      deviceType
      apMac
      deviceMac
      locationName
      startedAt
    }
  }
}
    `;
export const GetTrackingData3MonthDocument = gql`
    query getTrackingData3Month {
  getTrackingData3Month {
    data {
      click
      month
      categoryId
    }
    error {
      title
      code
    }
  }
}
    `;
export const GetTrackingSurveyDocument = gql`
    query getTrackingSurvey($campaignId: String!) {
  getTrackingSurvey(params: {campaignId: $campaignId}) {
    result {
      name
      options
    }
  }
}
    `;
export const GetTrafficSummaryDocument = gql`
    query getTrafficSummary($gte: DateTime!, $lte: DateTime!) {
  getTrafficSummary(gte: $gte, lte: $lte) {
    impression
    click
    ctr
    impressionAds
    clickAds
    ctrAds
    detail {
      date
      ctr
      impression
      click
      impressionAds
      clickAds
      ctrAds
    }
  }
}
    `;
export const Sale_FindManyCategoryDocument = gql`
    query sale_findManyCategory {
  sale_findManyCategory {
    value
    text
    icon
  }
}
    `;
export const Sale_FindManyCityDocument = gql`
    query sale_findManyCity {
  sale_findManyCity {
    value
    text
  }
}
    `;
export const Sale_FindManyDistrictDocument = gql`
    query sale_findManyDistrict {
  sale_findManyDistrict {
    value
    text
  }
}
    `;
export const Sale_FindManyBasicLocationDocument = gql`
    query sale_findManyBasicLocation($where: LocationSaleFilter, $limit: Int, $skip: Int, $sort: LocationSaleSort) {
  sale_findManyLocation(where: $where, limit: $limit, skip: $skip, sort: $sort) {
    _id
    name
    categoryId
    city
    bookingType
    district
    status
    monthlyClick
    noOfAccessPoint
    price
  }
}
    `;
export const Sale_FindManyLocationDocument = gql`
    query sale_findManyLocation($where: LocationSaleFilter, $limit: Int, $skip: Int, $sort: LocationSaleSort) {
  sale_findManyLocation(where: $where, limit: $limit, skip: $skip, sort: $sort) {
    ...LocationSale
  }
}
    ${LocationSaleFragmentDoc}`;
export const SendMailtoTheCustomerDocument = gql`
    query sendMailtoTheCustomer($email: String!, $data: DataInput!) {
  sendMailtoTheCustomer(email: $email, data: $data)
}
    `;
export const UserBalanceStatusDocument = gql`
    query userBalanceStatus {
  userBalanceStatus {
    ...FullUserBalance
  }
}
    ${FullUserBalanceFragmentDoc}`;
export const VerifyTwoFactorAuthenticationCodeDocument = gql`
    query verifyTwoFactorAuthenticationCode($code: String!) {
  verifyTwoFactorAuthenticationCode(code: $code)
}
    `;

export type SdkFunctionWrapper = <T>(action: () => Promise<T>) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = sdkFunction => sdkFunction();
export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    addKpiManagement(variables: AddKpiManagementMutationVariables): Promise<AddKpiManagementMutation> {
      return withWrapper(() => client.request<AddKpiManagementMutation>(print(AddKpiManagementDocument), variables));
    },
    addLocation(variables: AddLocationMutationVariables): Promise<AddLocationMutation> {
      return withWrapper(() => client.request<AddLocationMutation>(print(AddLocationDocument), variables));
    },
    addPricing(variables: AddPricingMutationVariables): Promise<AddPricingMutation> {
      return withWrapper(() => client.request<AddPricingMutation>(print(AddPricingDocument), variables));
    },
    approveCampaign(variables: ApproveCampaignMutationVariables): Promise<ApproveCampaignMutation> {
      return withWrapper(() => client.request<ApproveCampaignMutation>(print(ApproveCampaignDocument), variables));
    },
    changePassword(variables: ChangePasswordMutationVariables): Promise<ChangePasswordMutation> {
      return withWrapper(() => client.request<ChangePasswordMutation>(print(ChangePasswordDocument), variables));
    },
    createBanner(variables: CreateBannerMutationVariables): Promise<CreateBannerMutation> {
      return withWrapper(() => client.request<CreateBannerMutation>(print(CreateBannerDocument), variables));
    },
    createBannerTemp(variables?: CreateBannerTempMutationVariables): Promise<CreateBannerTempMutation> {
      return withWrapper(() => client.request<CreateBannerTempMutation>(print(CreateBannerTempDocument), variables));
    },
    createBannerTemplate(variables: CreateBannerTemplateMutationVariables): Promise<CreateBannerTemplateMutation> {
      return withWrapper(() => client.request<CreateBannerTemplateMutation>(print(CreateBannerTemplateDocument), variables));
    },
    createBasicLocation(variables: CreateBasicLocationMutationVariables): Promise<CreateBasicLocationMutation> {
      return withWrapper(() => client.request<CreateBasicLocationMutation>(print(CreateBasicLocationDocument), variables));
    },
    createCampaign(variables: CreateCampaignMutationVariables): Promise<CreateCampaignMutation> {
      return withWrapper(() => client.request<CreateCampaignMutation>(print(CreateCampaignDocument), variables));
    },
    createCampaignMapping(variables: CreateCampaignMappingMutationVariables): Promise<CreateCampaignMappingMutation> {
      return withWrapper(() => client.request<CreateCampaignMappingMutation>(print(CreateCampaignMappingDocument), variables));
    },
    createCampaignOnline(variables: CreateCampaignOnlineMutationVariables): Promise<CreateCampaignOnlineMutation> {
      return withWrapper(() => client.request<CreateCampaignOnlineMutation>(print(CreateCampaignOnlineDocument), variables));
    },
    createCommon(variables: CreateCommonMutationVariables): Promise<CreateCommonMutation> {
      return withWrapper(() => client.request<CreateCommonMutation>(print(CreateCommonDocument), variables));
    },
    createCustomer(variables: CreateCustomerMutationVariables): Promise<CreateCustomerMutation> {
      return withWrapper(() => client.request<CreateCustomerMutation>(print(CreateCustomerDocument), variables));
    },
    createCustomerType(variables: CreateCustomerTypeMutationVariables): Promise<CreateCustomerTypeMutation> {
      return withWrapper(() => client.request<CreateCustomerTypeMutation>(print(CreateCustomerTypeDocument), variables));
    },
    createEquipment(variables: CreateEquipmentMutationVariables): Promise<CreateEquipmentMutation> {
      return withWrapper(() => client.request<CreateEquipmentMutation>(print(CreateEquipmentDocument), variables));
    },
    createErrorLog(variables: CreateErrorLogMutationVariables): Promise<CreateErrorLogMutation> {
      return withWrapper(() => client.request<CreateErrorLogMutation>(print(CreateErrorLogDocument), variables));
    },
    createFile(variables?: CreateFileMutationVariables): Promise<CreateFileMutation> {
      return withWrapper(() => client.request<CreateFileMutation>(print(CreateFileDocument), variables));
    },
    createImage(variables: CreateImageMutationVariables): Promise<CreateImageMutation> {
      return withWrapper(() => client.request<CreateImageMutation>(print(CreateImageDocument), variables));
    },
    createNotification(variables: CreateNotificationMutationVariables): Promise<CreateNotificationMutation> {
      return withWrapper(() => client.request<CreateNotificationMutation>(print(CreateNotificationDocument), variables));
    },
    createPayment(variables: CreatePaymentMutationVariables): Promise<CreatePaymentMutation> {
      return withWrapper(() => client.request<CreatePaymentMutation>(print(CreatePaymentDocument), variables));
    },
    createPlan(variables: CreatePlanMutationVariables): Promise<CreatePlanMutation> {
      return withWrapper(() => client.request<CreatePlanMutation>(print(CreatePlanDocument), variables));
    },
    createProfile(variables: CreateProfileMutationVariables): Promise<CreateProfileMutation> {
      return withWrapper(() => client.request<CreateProfileMutation>(print(CreateProfileDocument), variables));
    },
    createRegion(variables: CreateRegionMutationVariables): Promise<CreateRegionMutation> {
      return withWrapper(() => client.request<CreateRegionMutation>(print(CreateRegionDocument), variables));
    },
    createRevenue(variables: CreateRevenueMutationVariables): Promise<CreateRevenueMutation> {
      return withWrapper(() => client.request<CreateRevenueMutation>(print(CreateRevenueDocument), variables));
    },
    createTwoFactorAuthentication(variables?: CreateTwoFactorAuthenticationMutationVariables): Promise<CreateTwoFactorAuthenticationMutation> {
      return withWrapper(() => client.request<CreateTwoFactorAuthenticationMutation>(print(CreateTwoFactorAuthenticationDocument), variables));
    },
    createUser(variables: CreateUserMutationVariables): Promise<CreateUserMutation> {
      return withWrapper(() => client.request<CreateUserMutation>(print(CreateUserDocument), variables));
    },
    createUserGuide(variables: CreateUserGuideMutationVariables): Promise<CreateUserGuideMutation> {
      return withWrapper(() => client.request<CreateUserGuideMutation>(print(CreateUserGuideDocument), variables));
    },
    deleteAllNotification(variables?: DeleteAllNotificationMutationVariables): Promise<DeleteAllNotificationMutation> {
      return withWrapper(() => client.request<DeleteAllNotificationMutation>(print(DeleteAllNotificationDocument), variables));
    },
    deleteCampaignById(variables: DeleteCampaignByIdMutationVariables): Promise<DeleteCampaignByIdMutation> {
      return withWrapper(() => client.request<DeleteCampaignByIdMutation>(print(DeleteCampaignByIdDocument), variables));
    },
    disableCampaign(variables: DisableCampaignMutationVariables): Promise<DisableCampaignMutation> {
      return withWrapper(() => client.request<DisableCampaignMutation>(print(DisableCampaignDocument), variables));
    },
    disableTwoFactorAuthentication(variables?: DisableTwoFactorAuthenticationMutationVariables): Promise<DisableTwoFactorAuthenticationMutation> {
      return withWrapper(() => client.request<DisableTwoFactorAuthenticationMutation>(print(DisableTwoFactorAuthenticationDocument), variables));
    },
    enableCampaign(variables: EnableCampaignMutationVariables): Promise<EnableCampaignMutation> {
      return withWrapper(() => client.request<EnableCampaignMutation>(print(EnableCampaignDocument), variables));
    },
    enableTwoFactorAuthentication(variables?: EnableTwoFactorAuthenticationMutationVariables): Promise<EnableTwoFactorAuthenticationMutation> {
      return withWrapper(() => client.request<EnableTwoFactorAuthenticationMutation>(print(EnableTwoFactorAuthenticationDocument), variables));
    },
    login(variables: LoginMutationVariables): Promise<LoginMutation> {
      return withWrapper(() => client.request<LoginMutation>(print(LoginDocument), variables));
    },
    loginTwoFactorAuth(variables: LoginTwoFactorAuthMutationVariables): Promise<LoginTwoFactorAuthMutation> {
      return withWrapper(() => client.request<LoginTwoFactorAuthMutation>(print(LoginTwoFactorAuthDocument), variables));
    },
    logLogout(variables?: LogLogoutMutationVariables): Promise<LogLogoutMutation> {
      return withWrapper(() => client.request<LogLogoutMutation>(print(LogLogoutDocument), variables));
    },
    rejectPayment(variables: RejectPaymentMutationVariables): Promise<RejectPaymentMutation> {
      return withWrapper(() => client.request<RejectPaymentMutation>(print(RejectPaymentDocument), variables));
    },
    removeBannerById(variables: RemoveBannerByIdMutationVariables): Promise<RemoveBannerByIdMutation> {
      return withWrapper(() => client.request<RemoveBannerByIdMutation>(print(RemoveBannerByIdDocument), variables));
    },
    removeBannerTempById(variables: RemoveBannerTempByIdMutationVariables): Promise<RemoveBannerTempByIdMutation> {
      return withWrapper(() => client.request<RemoveBannerTempByIdMutation>(print(RemoveBannerTempByIdDocument), variables));
    },
    removeBannerTemplateById(variables: RemoveBannerTemplateByIdMutationVariables): Promise<RemoveBannerTemplateByIdMutation> {
      return withWrapper(() => client.request<RemoveBannerTemplateByIdMutation>(print(RemoveBannerTemplateByIdDocument), variables));
    },
    removeCommonById(variables: RemoveCommonByIdMutationVariables): Promise<RemoveCommonByIdMutation> {
      return withWrapper(() => client.request<RemoveCommonByIdMutation>(print(RemoveCommonByIdDocument), variables));
    },
    removeCustomerTypeById(variables: RemoveCustomerTypeByIdMutationVariables): Promise<RemoveCustomerTypeByIdMutation> {
      return withWrapper(() => client.request<RemoveCustomerTypeByIdMutation>(print(RemoveCustomerTypeByIdDocument), variables));
    },
    removeEquipmentById(variables: RemoveEquipmentByIdMutationVariables): Promise<RemoveEquipmentByIdMutation> {
      return withWrapper(() => client.request<RemoveEquipmentByIdMutation>(print(RemoveEquipmentByIdDocument), variables));
    },
    removeFileById(variables: RemoveFileByIdMutationVariables): Promise<RemoveFileByIdMutation> {
      return withWrapper(() => client.request<RemoveFileByIdMutation>(print(RemoveFileByIdDocument), variables));
    },
    removeImageById(variables: RemoveImageByIdMutationVariables): Promise<RemoveImageByIdMutation> {
      return withWrapper(() => client.request<RemoveImageByIdMutation>(print(RemoveImageByIdDocument), variables));
    },
    removeLocationById(variables: RemoveLocationByIdMutationVariables): Promise<RemoveLocationByIdMutation> {
      return withWrapper(() => client.request<RemoveLocationByIdMutation>(print(RemoveLocationByIdDocument), variables));
    },
    removeMessageById(variables: RemoveMessageByIdMutationVariables): Promise<RemoveMessageByIdMutation> {
      return withWrapper(() => client.request<RemoveMessageByIdMutation>(print(RemoveMessageByIdDocument), variables));
    },
    removeNotificationById(variables: RemoveNotificationByIdMutationVariables): Promise<RemoveNotificationByIdMutation> {
      return withWrapper(() => client.request<RemoveNotificationByIdMutation>(print(RemoveNotificationByIdDocument), variables));
    },
    removePaymentById(variables: RemovePaymentByIdMutationVariables): Promise<RemovePaymentByIdMutation> {
      return withWrapper(() => client.request<RemovePaymentByIdMutation>(print(RemovePaymentByIdDocument), variables));
    },
    removePlanById(variables: RemovePlanByIdMutationVariables): Promise<RemovePlanByIdMutation> {
      return withWrapper(() => client.request<RemovePlanByIdMutation>(print(RemovePlanByIdDocument), variables));
    },
    removePricing(variables?: RemovePricingMutationVariables): Promise<RemovePricingMutation> {
      return withWrapper(() => client.request<RemovePricingMutation>(print(RemovePricingDocument), variables));
    },
    removeProfileById(variables: RemoveProfileByIdMutationVariables): Promise<RemoveProfileByIdMutation> {
      return withWrapper(() => client.request<RemoveProfileByIdMutation>(print(RemoveProfileByIdDocument), variables));
    },
    removeRegionById(variables: RemoveRegionByIdMutationVariables): Promise<RemoveRegionByIdMutation> {
      return withWrapper(() => client.request<RemoveRegionByIdMutation>(print(RemoveRegionByIdDocument), variables));
    },
    removeUserById(variables: RemoveUserByIdMutationVariables): Promise<RemoveUserByIdMutation> {
      return withWrapper(() => client.request<RemoveUserByIdMutation>(print(RemoveUserByIdDocument), variables));
    },
    removeUserGuideById(variables: RemoveUserGuideByIdMutationVariables): Promise<RemoveUserGuideByIdMutation> {
      return withWrapper(() => client.request<RemoveUserGuideByIdMutation>(print(RemoveUserGuideByIdDocument), variables));
    },
    resetPassword(variables: ResetPasswordMutationVariables): Promise<ResetPasswordMutation> {
      return withWrapper(() => client.request<ResetPasswordMutation>(print(ResetPasswordDocument), variables));
    },
    restorePassword(variables: RestorePasswordMutationVariables): Promise<RestorePasswordMutation> {
      return withWrapper(() => client.request<RestorePasswordMutation>(print(RestorePasswordDocument), variables));
    },
    sendEmailVerify(variables: SendEmailVerifyMutationVariables): Promise<SendEmailVerifyMutation> {
      return withWrapper(() => client.request<SendEmailVerifyMutation>(print(SendEmailVerifyDocument), variables));
    },
    sendMailByLicense(variables: SendMailByLicenseMutationVariables): Promise<SendMailByLicenseMutation> {
      return withWrapper(() => client.request<SendMailByLicenseMutation>(print(SendMailByLicenseDocument), variables));
    },
    setActive(variables: SetActiveMutationVariables): Promise<SetActiveMutation> {
      return withWrapper(() => client.request<SetActiveMutation>(print(SetActiveDocument), variables));
    },
    setSchedule(variables: SetScheduleMutationVariables): Promise<SetScheduleMutation> {
      return withWrapper(() => client.request<SetScheduleMutation>(print(SetScheduleDocument), variables));
    },
    setTimes(variables: SetTimesMutationVariables): Promise<SetTimesMutation> {
      return withWrapper(() => client.request<SetTimesMutation>(print(SetTimesDocument), variables));
    },
    setValueVerifyEmail(variables: SetValueVerifyEmailMutationVariables): Promise<SetValueVerifyEmailMutation> {
      return withWrapper(() => client.request<SetValueVerifyEmailMutation>(print(SetValueVerifyEmailDocument), variables));
    },
    signUp(variables: SignUpMutationVariables): Promise<SignUpMutation> {
      return withWrapper(() => client.request<SignUpMutation>(print(SignUpDocument), variables));
    },
    stopBooking(variables: StopBookingMutationVariables): Promise<StopBookingMutation> {
      return withWrapper(() => client.request<StopBookingMutation>(print(StopBookingDocument), variables));
    },
    updateAdvanceCampaign(variables: UpdateAdvanceCampaignMutationVariables): Promise<UpdateAdvanceCampaignMutation> {
      return withWrapper(() => client.request<UpdateAdvanceCampaignMutation>(print(UpdateAdvanceCampaignDocument), variables));
    },
    updateAdvanceLocation(variables: UpdateAdvanceLocationMutationVariables): Promise<UpdateAdvanceLocationMutation> {
      return withWrapper(() => client.request<UpdateAdvanceLocationMutation>(print(UpdateAdvanceLocationDocument), variables));
    },
    updateBannerActive(variables: UpdateBannerActiveMutationVariables): Promise<UpdateBannerActiveMutation> {
      return withWrapper(() => client.request<UpdateBannerActiveMutation>(print(UpdateBannerActiveDocument), variables));
    },
    updateBannerById(variables: UpdateBannerByIdMutationVariables): Promise<UpdateBannerByIdMutation> {
      return withWrapper(() => client.request<UpdateBannerByIdMutation>(print(UpdateBannerByIdDocument), variables));
    },
    updateBannerTempById(variables: UpdateBannerTempByIdMutationVariables): Promise<UpdateBannerTempByIdMutation> {
      return withWrapper(() => client.request<UpdateBannerTempByIdMutation>(print(UpdateBannerTempByIdDocument), variables));
    },
    updateBannerTemplateById(variables: UpdateBannerTemplateByIdMutationVariables): Promise<UpdateBannerTemplateByIdMutation> {
      return withWrapper(() => client.request<UpdateBannerTemplateByIdMutation>(print(UpdateBannerTemplateByIdDocument), variables));
    },
    updateBasicLocation(variables: UpdateBasicLocationMutationVariables): Promise<UpdateBasicLocationMutation> {
      return withWrapper(() => client.request<UpdateBasicLocationMutation>(print(UpdateBasicLocationDocument), variables));
    },
    updateBooking(variables: UpdateBookingMutationVariables): Promise<UpdateBookingMutation> {
      return withWrapper(() => client.request<UpdateBookingMutation>(print(UpdateBookingDocument), variables));
    },
    updateCalendarBooking(variables: UpdateCalendarBookingMutationVariables): Promise<UpdateCalendarBookingMutation> {
      return withWrapper(() => client.request<UpdateCalendarBookingMutation>(print(UpdateCalendarBookingDocument), variables));
    },
    updateCampaignById(variables: UpdateCampaignByIdMutationVariables): Promise<UpdateCampaignByIdMutation> {
      return withWrapper(() => client.request<UpdateCampaignByIdMutation>(print(UpdateCampaignByIdDocument), variables));
    },
    updateCampaignConfigById(variables: UpdateCampaignConfigByIdMutationVariables): Promise<UpdateCampaignConfigByIdMutation> {
      return withWrapper(() => client.request<UpdateCampaignConfigByIdMutation>(print(UpdateCampaignConfigByIdDocument), variables));
    },
    updateCampaignMappingById(variables: UpdateCampaignMappingByIdMutationVariables): Promise<UpdateCampaignMappingByIdMutation> {
      return withWrapper(() => client.request<UpdateCampaignMappingByIdMutation>(print(UpdateCampaignMappingByIdDocument), variables));
    },
    updateCampaignPriority(variables: UpdateCampaignPriorityMutationVariables): Promise<UpdateCampaignPriorityMutation> {
      return withWrapper(() => client.request<UpdateCampaignPriorityMutation>(print(UpdateCampaignPriorityDocument), variables));
    },
    updateCommonById(variables: UpdateCommonByIdMutationVariables): Promise<UpdateCommonByIdMutation> {
      return withWrapper(() => client.request<UpdateCommonByIdMutation>(print(UpdateCommonByIdDocument), variables));
    },
    updateCustomerById(variables: UpdateCustomerByIdMutationVariables): Promise<UpdateCustomerByIdMutation> {
      return withWrapper(() => client.request<UpdateCustomerByIdMutation>(print(UpdateCustomerByIdDocument), variables));
    },
    updateCustomerTypeById(variables: UpdateCustomerTypeByIdMutationVariables): Promise<UpdateCustomerTypeByIdMutation> {
      return withWrapper(() => client.request<UpdateCustomerTypeByIdMutation>(print(UpdateCustomerTypeByIdDocument), variables));
    },
    updateEquipmentById(variables: UpdateEquipmentByIdMutationVariables): Promise<UpdateEquipmentByIdMutation> {
      return withWrapper(() => client.request<UpdateEquipmentByIdMutation>(print(UpdateEquipmentByIdDocument), variables));
    },
    sendMailForgotPassword(variables: SendMailForgotPasswordMutationVariables): Promise<SendMailForgotPasswordMutation> {
      return withWrapper(() => client.request<SendMailForgotPasswordMutation>(print(SendMailForgotPasswordDocument), variables));
    },
    updateImageById(variables: UpdateImageByIdMutationVariables): Promise<UpdateImageByIdMutation> {
      return withWrapper(() => client.request<UpdateImageByIdMutation>(print(UpdateImageByIdDocument), variables));
    },
    updateLocationById(variables: UpdateLocationByIdMutationVariables): Promise<UpdateLocationByIdMutation> {
      return withWrapper(() => client.request<UpdateLocationByIdMutation>(print(UpdateLocationByIdDocument), variables));
    },
    updateMultiLocations(variables: UpdateMultiLocationsMutationVariables): Promise<UpdateMultiLocationsMutation> {
      return withWrapper(() => client.request<UpdateMultiLocationsMutation>(print(UpdateMultiLocationsDocument), variables));
    },
    updateNotificationById(variables: UpdateNotificationByIdMutationVariables): Promise<UpdateNotificationByIdMutation> {
      return withWrapper(() => client.request<UpdateNotificationByIdMutation>(print(UpdateNotificationByIdDocument), variables));
    },
    updatePlanById(variables: UpdatePlanByIdMutationVariables): Promise<UpdatePlanByIdMutation> {
      return withWrapper(() => client.request<UpdatePlanByIdMutation>(print(UpdatePlanByIdDocument), variables));
    },
    updateProfileById(variables: UpdateProfileByIdMutationVariables): Promise<UpdateProfileByIdMutation> {
      return withWrapper(() => client.request<UpdateProfileByIdMutation>(print(UpdateProfileByIdDocument), variables));
    },
    updateRegionById(variables: UpdateRegionByIdMutationVariables): Promise<UpdateRegionByIdMutation> {
      return withWrapper(() => client.request<UpdateRegionByIdMutation>(print(UpdateRegionByIdDocument), variables));
    },
    updateRevenueById(variables: UpdateRevenueByIdMutationVariables): Promise<UpdateRevenueByIdMutation> {
      return withWrapper(() => client.request<UpdateRevenueByIdMutation>(print(UpdateRevenueByIdDocument), variables));
    },
    updateRoleDescriptionById(variables: UpdateRoleDescriptionByIdMutationVariables): Promise<UpdateRoleDescriptionByIdMutation> {
      return withWrapper(() => client.request<UpdateRoleDescriptionByIdMutation>(print(UpdateRoleDescriptionByIdDocument), variables));
    },
    updateSaleInfo(variables: UpdateSaleInfoMutationVariables): Promise<UpdateSaleInfoMutation> {
      return withWrapper(() => client.request<UpdateSaleInfoMutation>(print(UpdateSaleInfoDocument), variables));
    },
    updateSystemInfo(variables: UpdateSystemInfoMutationVariables): Promise<UpdateSystemInfoMutation> {
      return withWrapper(() => client.request<UpdateSystemInfoMutation>(print(UpdateSystemInfoDocument), variables));
    },
    updateUserById(variables: UpdateUserByIdMutationVariables): Promise<UpdateUserByIdMutation> {
      return withWrapper(() => client.request<UpdateUserByIdMutation>(print(UpdateUserByIdDocument), variables));
    },
    updateUserGuideById(variables: UpdateUserGuideByIdMutationVariables): Promise<UpdateUserGuideByIdMutation> {
      return withWrapper(() => client.request<UpdateUserGuideByIdMutation>(print(UpdateUserGuideByIdDocument), variables));
    },
    updateUserInfoById(variables: UpdateUserInfoByIdMutationVariables): Promise<UpdateUserInfoByIdMutation> {
      return withWrapper(() => client.request<UpdateUserInfoByIdMutation>(print(UpdateUserInfoByIdDocument), variables));
    },
    upgradeLicense(variables: UpgradeLicenseMutationVariables): Promise<UpgradeLicenseMutation> {
      return withWrapper(() => client.request<UpgradeLicenseMutation>(print(UpgradeLicenseDocument), variables));
    },
    upsertAssignment(variables: UpsertAssignmentMutationVariables): Promise<UpsertAssignmentMutation> {
      return withWrapper(() => client.request<UpsertAssignmentMutation>(print(UpsertAssignmentDocument), variables));
    },
    verifyEmail(variables: VerifyEmailMutationVariables): Promise<VerifyEmailMutation> {
      return withWrapper(() => client.request<VerifyEmailMutation>(print(VerifyEmailDocument), variables));
    },
    verifyEmailLicense(variables: VerifyEmailLicenseMutationVariables): Promise<VerifyEmailLicenseMutation> {
      return withWrapper(() => client.request<VerifyEmailLicenseMutation>(print(VerifyEmailLicenseDocument), variables));
    },
    verifyPayment(variables: VerifyPaymentMutationVariables): Promise<VerifyPaymentMutation> {
      return withWrapper(() => client.request<VerifyPaymentMutation>(print(VerifyPaymentDocument), variables));
    },
    verifyRevenue(variables: VerifyRevenueMutationVariables): Promise<VerifyRevenueMutation> {
      return withWrapper(() => client.request<VerifyRevenueMutation>(print(VerifyRevenueDocument), variables));
    },
    CountTrafficData(variables: CountTrafficDataQueryVariables): Promise<CountTrafficDataQuery> {
      return withWrapper(() => client.request<CountTrafficDataQuery>(print(CountTrafficDataDocument), variables));
    },
    calculateCampaignOnlineKpi(variables: CalculateCampaignOnlineKpiQueryVariables): Promise<CalculateCampaignOnlineKpiQuery> {
      return withWrapper(() => client.request<CalculateCampaignOnlineKpiQuery>(print(CalculateCampaignOnlineKpiDocument), variables));
    },
    checkRecaptcha(variables: CheckRecaptchaQueryVariables): Promise<CheckRecaptchaQuery> {
      return withWrapper(() => client.request<CheckRecaptchaQuery>(print(CheckRecaptchaDocument), variables));
    },
    countBalanceHistory(variables?: CountBalanceHistoryQueryVariables): Promise<CountBalanceHistoryQuery> {
      return withWrapper(() => client.request<CountBalanceHistoryQuery>(print(CountBalanceHistoryDocument), variables));
    },
    countBanner(variables?: CountBannerQueryVariables): Promise<CountBannerQuery> {
      return withWrapper(() => client.request<CountBannerQuery>(print(CountBannerDocument), variables));
    },
    countBannerTemplate(variables?: CountBannerTemplateQueryVariables): Promise<CountBannerTemplateQuery> {
      return withWrapper(() => client.request<CountBannerTemplateQuery>(print(CountBannerTemplateDocument), variables));
    },
    countCampaign(variables?: CountCampaignQueryVariables): Promise<CountCampaignQuery> {
      return withWrapper(() => client.request<CountCampaignQuery>(print(CountCampaignDocument), variables));
    },
    countCampaignMapping(variables?: CountCampaignMappingQueryVariables): Promise<CountCampaignMappingQuery> {
      return withWrapper(() => client.request<CountCampaignMappingQuery>(print(CountCampaignMappingDocument), variables));
    },
    countCommon(variables?: CountCommonQueryVariables): Promise<CountCommonQuery> {
      return withWrapper(() => client.request<CountCommonQuery>(print(CountCommonDocument), variables));
    },
    countCustomer(variables?: CountCustomerQueryVariables): Promise<CountCustomerQuery> {
      return withWrapper(() => client.request<CountCustomerQuery>(print(CountCustomerDocument), variables));
    },
    countEquipment(variables?: CountEquipmentQueryVariables): Promise<CountEquipmentQuery> {
      return withWrapper(() => client.request<CountEquipmentQuery>(print(CountEquipmentDocument), variables));
    },
    countFullLogs(variables?: CountFullLogsQueryVariables): Promise<CountFullLogsQuery> {
      return withWrapper(() => client.request<CountFullLogsQuery>(print(CountFullLogsDocument), variables));
    },
    countImage(variables?: CountImageQueryVariables): Promise<CountImageQuery> {
      return withWrapper(() => client.request<CountImageQuery>(print(CountImageDocument), variables));
    },
    countLocation(variables?: CountLocationQueryVariables): Promise<CountLocationQuery> {
      return withWrapper(() => client.request<CountLocationQuery>(print(CountLocationDocument), variables));
    },
    countLogs(variables?: CountLogsQueryVariables): Promise<CountLogsQuery> {
      return withWrapper(() => client.request<CountLogsQuery>(print(CountLogsDocument), variables));
    },
    countMessage(variables?: CountMessageQueryVariables): Promise<CountMessageQuery> {
      return withWrapper(() => client.request<CountMessageQuery>(print(CountMessageDocument), variables));
    },
    countNotification(variables?: CountNotificationQueryVariables): Promise<CountNotificationQuery> {
      return withWrapper(() => client.request<CountNotificationQuery>(print(CountNotificationDocument), variables));
    },
    countOrder(variables?: CountOrderQueryVariables): Promise<CountOrderQuery> {
      return withWrapper(() => client.request<CountOrderQuery>(print(CountOrderDocument), variables));
    },
    countPayment(variables?: CountPaymentQueryVariables): Promise<CountPaymentQuery> {
      return withWrapper(() => client.request<CountPaymentQuery>(print(CountPaymentDocument), variables));
    },
    countPaymentForUser(variables?: CountPaymentForUserQueryVariables): Promise<CountPaymentForUserQuery> {
      return withWrapper(() => client.request<CountPaymentForUserQuery>(print(CountPaymentForUserDocument), variables));
    },
    countPlan(variables?: CountPlanQueryVariables): Promise<CountPlanQuery> {
      return withWrapper(() => client.request<CountPlanQuery>(print(CountPlanDocument), variables));
    },
    countProfile(variables?: CountProfileQueryVariables): Promise<CountProfileQuery> {
      return withWrapper(() => client.request<CountProfileQuery>(print(CountProfileDocument), variables));
    },
    countRegion(variables?: CountRegionQueryVariables): Promise<CountRegionQuery> {
      return withWrapper(() => client.request<CountRegionQuery>(print(CountRegionDocument), variables));
    },
    countReportLocationData(variables?: CountReportLocationDataQueryVariables): Promise<CountReportLocationDataQuery> {
      return withWrapper(() => client.request<CountReportLocationDataQuery>(print(CountReportLocationDataDocument), variables));
    },
    countReportOnlineSession(variables?: CountReportOnlineSessionQueryVariables): Promise<CountReportOnlineSessionQuery> {
      return withWrapper(() => client.request<CountReportOnlineSessionQuery>(print(CountReportOnlineSessionDocument), variables));
    },
    countRevenue(variables?: CountRevenueQueryVariables): Promise<CountRevenueQuery> {
      return withWrapper(() => client.request<CountRevenueQuery>(print(CountRevenueDocument), variables));
    },
    countUser(variables?: CountUserQueryVariables): Promise<CountUserQuery> {
      return withWrapper(() => client.request<CountUserQuery>(print(CountUserDocument), variables));
    },
    countUserGuide(variables?: CountUserGuideQueryVariables): Promise<CountUserGuideQuery> {
      return withWrapper(() => client.request<CountUserGuideQuery>(print(CountUserGuideDocument), variables));
    },
    countManyUserWithOwner(variables?: CountManyUserWithOwnerQueryVariables): Promise<CountManyUserWithOwnerQuery> {
      return withWrapper(() => client.request<CountManyUserWithOwnerQuery>(print(CountManyUserWithOwnerDocument), variables));
    },
    fetchCampaignMapping(variables: FetchCampaignMappingQueryVariables): Promise<FetchCampaignMappingQuery> {
      return withWrapper(() => client.request<FetchCampaignMappingQuery>(print(FetchCampaignMappingDocument), variables));
    },
    fetchUserForm(variables: FetchUserFormQueryVariables): Promise<FetchUserFormQuery> {
      return withWrapper(() => client.request<FetchUserFormQuery>(print(FetchUserFormDocument), variables));
    },
    fetchDetailInfo(variables: FetchDetailInfoQueryVariables): Promise<FetchDetailInfoQuery> {
      return withWrapper(() => client.request<FetchDetailInfoQuery>(print(FetchDetailInfoDocument), variables));
    },
    fetchAdvanceFormData(variables: FetchAdvanceFormDataQueryVariables): Promise<FetchAdvanceFormDataQuery> {
      return withWrapper(() => client.request<FetchAdvanceFormDataQuery>(print(FetchAdvanceFormDataDocument), variables));
    },
    fetchBasicFormData(variables: FetchBasicFormDataQueryVariables): Promise<FetchBasicFormDataQuery> {
      return withWrapper(() => client.request<FetchBasicFormDataQuery>(print(FetchBasicFormDataDocument), variables));
    },
    fetchLocationCampaignMapping(variables: FetchLocationCampaignMappingQueryVariables): Promise<FetchLocationCampaignMappingQuery> {
      return withWrapper(() => client.request<FetchLocationCampaignMappingQuery>(print(FetchLocationCampaignMappingDocument), variables));
    },
    fetchLocationMapping(variables: FetchLocationMappingQueryVariables): Promise<FetchLocationMappingQuery> {
      return withWrapper(() => client.request<FetchLocationMappingQuery>(print(FetchLocationMappingDocument), variables));
    },
    fetchLocationsGrid(variables?: FetchLocationsGridQueryVariables): Promise<FetchLocationsGridQuery> {
      return withWrapper(() => client.request<FetchLocationsGridQuery>(print(FetchLocationsGridDocument), variables));
    },
    fetchSaleInfo(variables: FetchSaleInfoQueryVariables): Promise<FetchSaleInfoQuery> {
      return withWrapper(() => client.request<FetchSaleInfoQuery>(print(FetchSaleInfoDocument), variables));
    },
    fetchSystemInfo(variables: FetchSystemInfoQueryVariables): Promise<FetchSystemInfoQuery> {
      return withWrapper(() => client.request<FetchSystemInfoQuery>(print(FetchSystemInfoDocument), variables));
    },
    fetchUserGrid(variables?: FetchUserGridQueryVariables): Promise<FetchUserGridQuery> {
      return withWrapper(() => client.request<FetchUserGridQuery>(print(FetchUserGridDocument), variables));
    },
    findArea(variables?: FindAreaQueryVariables): Promise<FindAreaQuery> {
      return withWrapper(() => client.request<FindAreaQuery>(print(FindAreaDocument), variables));
    },
    findBannerTempById(variables: FindBannerTempByIdQueryVariables): Promise<FindBannerTempByIdQuery> {
      return withWrapper(() => client.request<FindBannerTempByIdQuery>(print(FindBannerTempByIdDocument), variables));
    },
    findCampaignConfigById(variables: FindCampaignConfigByIdQueryVariables): Promise<FindCampaignConfigByIdQuery> {
      return withWrapper(() => client.request<FindCampaignConfigByIdQuery>(print(FindCampaignConfigByIdDocument), variables));
    },
    findCampaignMappingById(variables: FindCampaignMappingByIdQueryVariables): Promise<FindCampaignMappingByIdQuery> {
      return withWrapper(() => client.request<FindCampaignMappingByIdQuery>(print(FindCampaignMappingByIdDocument), variables));
    },
    findCategoryPrice(variables?: FindCategoryPriceQueryVariables): Promise<FindCategoryPriceQuery> {
      return withWrapper(() => client.request<FindCategoryPriceQuery>(print(FindCategoryPriceDocument), variables));
    },
    findCity(variables?: FindCityQueryVariables): Promise<FindCityQuery> {
      return withWrapper(() => client.request<FindCityQuery>(print(FindCityDocument), variables));
    },
    findManyCommon(variables?: FindManyCommonQueryVariables): Promise<FindManyCommonQuery> {
      return withWrapper(() => client.request<FindManyCommonQuery>(print(FindManyCommonDocument), variables));
    },
    findCommonBanner(variables?: FindCommonBannerQueryVariables): Promise<FindCommonBannerQuery> {
      return withWrapper(() => client.request<FindCommonBannerQuery>(print(FindCommonBannerDocument), variables));
    },
    findCommonCampaign(variables?: FindCommonCampaignQueryVariables): Promise<FindCommonCampaignQuery> {
      return withWrapper(() => client.request<FindCommonCampaignQuery>(print(FindCommonCampaignDocument), variables));
    },
    findCommonLocation(variables?: FindCommonLocationQueryVariables): Promise<FindCommonLocationQuery> {
      return withWrapper(() => client.request<FindCommonLocationQuery>(print(FindCommonLocationDocument), variables));
    },
    findCommonLocations(variables: FindCommonLocationsQueryVariables): Promise<FindCommonLocationsQuery> {
      return withWrapper(() => client.request<FindCommonLocationsQuery>(print(FindCommonLocationsDocument), variables));
    },
    findCommonTypes(variables?: FindCommonTypesQueryVariables): Promise<FindCommonTypesQuery> {
      return withWrapper(() => client.request<FindCommonTypesQuery>(print(FindCommonTypesDocument), variables));
    },
    findCustomerById(variables: FindCustomerByIdQueryVariables): Promise<FindCustomerByIdQuery> {
      return withWrapper(() => client.request<FindCustomerByIdQuery>(print(FindCustomerByIdDocument), variables));
    },
    findManyCustomerType(variables?: FindManyCustomerTypeQueryVariables): Promise<FindManyCustomerTypeQuery> {
      return withWrapper(() => client.request<FindManyCustomerTypeQuery>(print(FindManyCustomerTypeDocument), variables));
    },
    countCustomerType(variables?: CountCustomerTypeQueryVariables): Promise<CountCustomerTypeQuery> {
      return withWrapper(() => client.request<CountCustomerTypeQuery>(print(CountCustomerTypeDocument), variables));
    },
    findDeviceDataByLocation(variables?: FindDeviceDataByLocationQueryVariables): Promise<FindDeviceDataByLocationQuery> {
      return withWrapper(() => client.request<FindDeviceDataByLocationQuery>(print(FindDeviceDataByLocationDocument), variables));
    },
    findDistrict(variables?: FindDistrictQueryVariables): Promise<FindDistrictQuery> {
      return withWrapper(() => client.request<FindDistrictQuery>(print(FindDistrictDocument), variables));
    },
    findFileById(variables: FindFileByIdQueryVariables): Promise<FindFileByIdQuery> {
      return withWrapper(() => client.request<FindFileByIdQuery>(print(FindFileByIdDocument), variables));
    },
    findFullLogs(variables?: FindFullLogsQueryVariables): Promise<FindFullLogsQuery> {
      return withWrapper(() => client.request<FindFullLogsQuery>(print(FindFullLogsDocument), variables));
    },
    findLocationById(variables: FindLocationByIdQueryVariables): Promise<FindLocationByIdQuery> {
      return withWrapper(() => client.request<FindLocationByIdQuery>(print(FindLocationByIdDocument), variables));
    },
    findLocationPrice(variables?: FindLocationPriceQueryVariables): Promise<FindLocationPriceQuery> {
      return withWrapper(() => client.request<FindLocationPriceQuery>(print(FindLocationPriceDocument), variables));
    },
    findBasicLocationPrice(variables?: FindBasicLocationPriceQueryVariables): Promise<FindBasicLocationPriceQuery> {
      return withWrapper(() => client.request<FindBasicLocationPriceQuery>(print(FindBasicLocationPriceDocument), variables));
    },
    findMacEquipment(variables?: FindMacEquipmentQueryVariables): Promise<FindMacEquipmentQuery> {
      return withWrapper(() => client.request<FindMacEquipmentQuery>(print(FindMacEquipmentDocument), variables));
    },
    findManyBalanceHistory(variables?: FindManyBalanceHistoryQueryVariables): Promise<FindManyBalanceHistoryQuery> {
      return withWrapper(() => client.request<FindManyBalanceHistoryQuery>(print(FindManyBalanceHistoryDocument), variables));
    },
    findManyBanner(variables?: FindManyBannerQueryVariables): Promise<FindManyBannerQuery> {
      return withWrapper(() => client.request<FindManyBannerQuery>(print(FindManyBannerDocument), variables));
    },
    findManyBannerTemplate(variables?: FindManyBannerTemplateQueryVariables): Promise<FindManyBannerTemplateQuery> {
      return withWrapper(() => client.request<FindManyBannerTemplateQuery>(print(FindManyBannerTemplateDocument), variables));
    },
    findManyCampaign(variables?: FindManyCampaignQueryVariables): Promise<FindManyCampaignQuery> {
      return withWrapper(() => client.request<FindManyCampaignQuery>(print(FindManyCampaignDocument), variables));
    },
    findManyReportCampaign(variables?: FindManyReportCampaignQueryVariables): Promise<FindManyReportCampaignQuery> {
      return withWrapper(() => client.request<FindManyReportCampaignQuery>(print(FindManyReportCampaignDocument), variables));
    },
    findManyCampaignIdAndName(variables?: FindManyCampaignIdAndNameQueryVariables): Promise<FindManyCampaignIdAndNameQuery> {
      return withWrapper(() => client.request<FindManyCampaignIdAndNameQuery>(print(FindManyCampaignIdAndNameDocument), variables));
    },
    findManyCampaignMapping(variables?: FindManyCampaignMappingQueryVariables): Promise<FindManyCampaignMappingQuery> {
      return withWrapper(() => client.request<FindManyCampaignMappingQuery>(print(FindManyCampaignMappingDocument), variables));
    },
    findManyCustomer(variables?: FindManyCustomerQueryVariables): Promise<FindManyCustomerQuery> {
      return withWrapper(() => client.request<FindManyCustomerQuery>(print(FindManyCustomerDocument), variables));
    },
    findManyEquipment(variables?: FindManyEquipmentQueryVariables): Promise<FindManyEquipmentQuery> {
      return withWrapper(() => client.request<FindManyEquipmentQuery>(print(FindManyEquipmentDocument), variables));
    },
    findManyImage(variables?: FindManyImageQueryVariables): Promise<FindManyImageQuery> {
      return withWrapper(() => client.request<FindManyImageQuery>(print(FindManyImageDocument), variables));
    },
    findManyLocation(variables?: FindManyLocationQueryVariables): Promise<FindManyLocationQuery> {
      return withWrapper(() => client.request<FindManyLocationQuery>(print(FindManyLocationDocument), variables));
    },
    findManyLocationCategory(variables?: FindManyLocationCategoryQueryVariables): Promise<FindManyLocationCategoryQuery> {
      return withWrapper(() => client.request<FindManyLocationCategoryQuery>(print(FindManyLocationCategoryDocument), variables));
    },
    findManyLocationIdAndName(variables?: FindManyLocationIdAndNameQueryVariables): Promise<FindManyLocationIdAndNameQuery> {
      return withWrapper(() => client.request<FindManyLocationIdAndNameQuery>(print(FindManyLocationIdAndNameDocument), variables));
    },
    findManyLogs(variables?: FindManyLogsQueryVariables): Promise<FindManyLogsQuery> {
      return withWrapper(() => client.request<FindManyLogsQuery>(print(FindManyLogsDocument), variables));
    },
    findManyMessage(variables?: FindManyMessageQueryVariables): Promise<FindManyMessageQuery> {
      return withWrapper(() => client.request<FindManyMessageQuery>(print(FindManyMessageDocument), variables));
    },
    findManyNotification(variables?: FindManyNotificationQueryVariables): Promise<FindManyNotificationQuery> {
      return withWrapper(() => client.request<FindManyNotificationQuery>(print(FindManyNotificationDocument), variables));
    },
    findManyOrder(variables?: FindManyOrderQueryVariables): Promise<FindManyOrderQuery> {
      return withWrapper(() => client.request<FindManyOrderQuery>(print(FindManyOrderDocument), variables));
    },
    findManyOwnerByUserId(variables?: FindManyOwnerByUserIdQueryVariables): Promise<FindManyOwnerByUserIdQuery> {
      return withWrapper(() => client.request<FindManyOwnerByUserIdQuery>(print(FindManyOwnerByUserIdDocument), variables));
    },
    findManyPayment(variables?: FindManyPaymentQueryVariables): Promise<FindManyPaymentQuery> {
      return withWrapper(() => client.request<FindManyPaymentQuery>(print(FindManyPaymentDocument), variables));
    },
    findManyPaymentForUser(variables?: FindManyPaymentForUserQueryVariables): Promise<FindManyPaymentForUserQuery> {
      return withWrapper(() => client.request<FindManyPaymentForUserQuery>(print(FindManyPaymentForUserDocument), variables));
    },
    findManyPlan(variables?: FindManyPlanQueryVariables): Promise<FindManyPlanQuery> {
      return withWrapper(() => client.request<FindManyPlanQuery>(print(FindManyPlanDocument), variables));
    },
    findManyPricingHistory(variables?: FindManyPricingHistoryQueryVariables): Promise<FindManyPricingHistoryQuery> {
      return withWrapper(() => client.request<FindManyPricingHistoryQuery>(print(FindManyPricingHistoryDocument), variables));
    },
    findManyProfileMinimal(variables?: FindManyProfileMinimalQueryVariables): Promise<FindManyProfileMinimalQuery> {
      return withWrapper(() => client.request<FindManyProfileMinimalQuery>(print(FindManyProfileMinimalDocument), variables));
    },
    findManyProfileBasic(variables?: FindManyProfileBasicQueryVariables): Promise<FindManyProfileBasicQuery> {
      return withWrapper(() => client.request<FindManyProfileBasicQuery>(print(FindManyProfileBasicDocument), variables));
    },
    findManyProfile(variables?: FindManyProfileQueryVariables): Promise<FindManyProfileQuery> {
      return withWrapper(() => client.request<FindManyProfileQuery>(print(FindManyProfileDocument), variables));
    },
    findManyProfileIdAndDisplay(variables?: FindManyProfileIdAndDisplayQueryVariables): Promise<FindManyProfileIdAndDisplayQuery> {
      return withWrapper(() => client.request<FindManyProfileIdAndDisplayQuery>(print(FindManyProfileIdAndDisplayDocument), variables));
    },
    findManyRegion(variables?: FindManyRegionQueryVariables): Promise<FindManyRegionQuery> {
      return withWrapper(() => client.request<FindManyRegionQuery>(print(FindManyRegionDocument), variables));
    },
    findManyRevenue(variables?: FindManyRevenueQueryVariables): Promise<FindManyRevenueQuery> {
      return withWrapper(() => client.request<FindManyRevenueQuery>(print(FindManyRevenueDocument), variables));
    },
    findManyRole(variables?: FindManyRoleQueryVariables): Promise<FindManyRoleQuery> {
      return withWrapper(() => client.request<FindManyRoleQuery>(print(FindManyRoleDocument), variables));
    },
    findManyUser(variables?: FindManyUserQueryVariables): Promise<FindManyUserQuery> {
      return withWrapper(() => client.request<FindManyUserQuery>(print(FindManyUserDocument), variables));
    },
    findManyUserGuide(variables?: FindManyUserGuideQueryVariables): Promise<FindManyUserGuideQuery> {
      return withWrapper(() => client.request<FindManyUserGuideQuery>(print(FindManyUserGuideDocument), variables));
    },
    findManyUserId(variables?: FindManyUserIdQueryVariables): Promise<FindManyUserIdQuery> {
      return withWrapper(() => client.request<FindManyUserIdQuery>(print(FindManyUserIdDocument), variables));
    },
    findManyUserWithOwner(variables?: FindManyUserWithOwnerQueryVariables): Promise<FindManyUserWithOwnerQuery> {
      return withWrapper(() => client.request<FindManyUserWithOwnerQuery>(print(FindManyUserWithOwnerDocument), variables));
    },
    me(variables?: MeQueryVariables): Promise<MeQuery> {
      return withWrapper(() => client.request<MeQuery>(print(MeDocument), variables));
    },
    findMessageById(variables: FindMessageByIdQueryVariables): Promise<FindMessageByIdQuery> {
      return withWrapper(() => client.request<FindMessageByIdQuery>(print(FindMessageByIdDocument), variables));
    },
    findNotificationById(variables: FindNotificationByIdQueryVariables): Promise<FindNotificationByIdQuery> {
      return withWrapper(() => client.request<FindNotificationByIdQuery>(print(FindNotificationByIdDocument), variables));
    },
    findOneCampaign(variables: FindOneCampaignQueryVariables): Promise<FindOneCampaignQuery> {
      return withWrapper(() => client.request<FindOneCampaignQuery>(print(FindOneCampaignDocument), variables));
    },
    findOneLocation(variables?: FindOneLocationQueryVariables): Promise<FindOneLocationQuery> {
      return withWrapper(() => client.request<FindOneLocationQuery>(print(FindOneLocationDocument), variables));
    },
    findOneRevenue(variables?: FindOneRevenueQueryVariables): Promise<FindOneRevenueQuery> {
      return withWrapper(() => client.request<FindOneRevenueQuery>(print(FindOneRevenueDocument), variables));
    },
    findOrderById(variables: FindOrderByIdQueryVariables): Promise<FindOrderByIdQuery> {
      return withWrapper(() => client.request<FindOrderByIdQuery>(print(FindOrderByIdDocument), variables));
    },
    findManyOwnerEquipment(variables?: FindManyOwnerEquipmentQueryVariables): Promise<FindManyOwnerEquipmentQuery> {
      return withWrapper(() => client.request<FindManyOwnerEquipmentQuery>(print(FindManyOwnerEquipmentDocument), variables));
    },
    findProfileById(variables: FindProfileByIdQueryVariables): Promise<FindProfileByIdQuery> {
      return withWrapper(() => client.request<FindProfileByIdQuery>(print(FindProfileByIdDocument), variables));
    },
    findRevenueById(variables: FindRevenueByIdQueryVariables): Promise<FindRevenueByIdQuery> {
      return withWrapper(() => client.request<FindRevenueByIdQuery>(print(FindRevenueByIdDocument), variables));
    },
    getActiveCampaigns(variables?: GetActiveCampaignsQueryVariables): Promise<GetActiveCampaignsQuery> {
      return withWrapper(() => client.request<GetActiveCampaignsQuery>(print(GetActiveCampaignsDocument), variables));
    },
    alertLocation(variables?: AlertLocationQueryVariables): Promise<AlertLocationQuery> {
      return withWrapper(() => client.request<AlertLocationQuery>(print(AlertLocationDocument), variables));
    },
    getAvailableBooking(variables: GetAvailableBookingQueryVariables): Promise<GetAvailableBookingQuery> {
      return withWrapper(() => client.request<GetAvailableBookingQuery>(print(GetAvailableBookingDocument), variables));
    },
    getCampaignByLocationId(variables: GetCampaignByLocationIdQueryVariables): Promise<GetCampaignByLocationIdQuery> {
      return withWrapper(() => client.request<GetCampaignByLocationIdQuery>(print(GetCampaignByLocationIdDocument), variables));
    },
    getCampaignRevenue(variables?: GetCampaignRevenueQueryVariables): Promise<GetCampaignRevenueQuery> {
      return withWrapper(() => client.request<GetCampaignRevenueQuery>(print(GetCampaignRevenueDocument), variables));
    },
    getCategories(variables?: GetCategoriesQueryVariables): Promise<GetCategoriesQuery> {
      return withWrapper(() => client.request<GetCategoriesQuery>(print(GetCategoriesDocument), variables));
    },
    getDataAudience(variables?: GetDataAudienceQueryVariables): Promise<GetDataAudienceQuery> {
      return withWrapper(() => client.request<GetDataAudienceQuery>(print(GetDataAudienceDocument), variables));
    },
    getDataTrafficOverview(variables?: GetDataTrafficOverviewQueryVariables): Promise<GetDataTrafficOverviewQuery> {
      return withWrapper(() => client.request<GetDataTrafficOverviewQuery>(print(GetDataTrafficOverviewDocument), variables));
    },
    getTotalUsers(variables?: GetTotalUsersQueryVariables): Promise<GetTotalUsersQuery> {
      return withWrapper(() => client.request<GetTotalUsersQuery>(print(GetTotalUsersDocument), variables));
    },
    getHotspotOnOff(variables?: GetHotspotOnOffQueryVariables): Promise<GetHotspotOnOffQuery> {
      return withWrapper(() => client.request<GetHotspotOnOffQuery>(print(GetHotspotOnOffDocument), variables));
    },
    getLocationAds(variables: GetLocationAdsQueryVariables): Promise<GetLocationAdsQuery> {
      return withWrapper(() => client.request<GetLocationAdsQuery>(print(GetLocationAdsDocument), variables));
    },
    getLocationClick3Month(variables: GetLocationClick3MonthQueryVariables): Promise<GetLocationClick3MonthQuery> {
      return withWrapper(() => client.request<GetLocationClick3MonthQuery>(print(GetLocationClick3MonthDocument), variables));
    },
    getDetail(variables?: GetDetailQueryVariables): Promise<GetDetailQuery> {
      return withWrapper(() => client.request<GetDetailQuery>(print(GetDetailDocument), variables));
    },
    getOnlineSession(variables?: GetOnlineSessionQueryVariables): Promise<GetOnlineSessionQuery> {
      return withWrapper(() => client.request<GetOnlineSessionQuery>(print(GetOnlineSessionDocument), variables));
    },
    getRenderCode(variables?: GetRenderCodeQueryVariables): Promise<GetRenderCodeQuery> {
      return withWrapper(() => client.request<GetRenderCodeQuery>(print(GetRenderCodeDocument), variables));
    },
    getReportCampaignInfo(variables: GetReportCampaignInfoQueryVariables): Promise<GetReportCampaignInfoQuery> {
      return withWrapper(() => client.request<GetReportCampaignInfoQuery>(print(GetReportCampaignInfoDocument), variables));
    },
    getReportCampaignOsDevice(variables: GetReportCampaignOsDeviceQueryVariables): Promise<GetReportCampaignOsDeviceQuery> {
      return withWrapper(() => client.request<GetReportCampaignOsDeviceQuery>(print(GetReportCampaignOsDeviceDocument), variables));
    },
    getReportCampaignPartner(variables: GetReportCampaignPartnerQueryVariables): Promise<GetReportCampaignPartnerQuery> {
      return withWrapper(() => client.request<GetReportCampaignPartnerQuery>(print(GetReportCampaignPartnerDocument), variables));
    },
    getReportCampaignRatio(variables?: GetReportCampaignRatioQueryVariables): Promise<GetReportCampaignRatioQuery> {
      return withWrapper(() => client.request<GetReportCampaignRatioQuery>(print(GetReportCampaignRatioDocument), variables));
    },
    getReportCampaignStatistic(variables: GetReportCampaignStatisticQueryVariables): Promise<GetReportCampaignStatisticQuery> {
      return withWrapper(() => client.request<GetReportCampaignStatisticQuery>(print(GetReportCampaignStatisticDocument), variables));
    },
    getReportCampaignSurvey(variables: GetReportCampaignSurveyQueryVariables): Promise<GetReportCampaignSurveyQuery> {
      return withWrapper(() => client.request<GetReportCampaignSurveyQuery>(print(GetReportCampaignSurveyDocument), variables));
    },
    getDataOSDevice(variables: GetDataOsDeviceQueryVariables): Promise<GetDataOsDeviceQuery> {
      return withWrapper(() => client.request<GetDataOsDeviceQuery>(print(GetDataOsDeviceDocument), variables));
    },
    getReportIncomeByCampaign(variables?: GetReportIncomeByCampaignQueryVariables): Promise<GetReportIncomeByCampaignQuery> {
      return withWrapper(() => client.request<GetReportIncomeByCampaignQuery>(print(GetReportIncomeByCampaignDocument), variables));
    },
    getReportIncomeByLocation(variables?: GetReportIncomeByLocationQueryVariables): Promise<GetReportIncomeByLocationQuery> {
      return withWrapper(() => client.request<GetReportIncomeByLocationQuery>(print(GetReportIncomeByLocationDocument), variables));
    },
    getReportIncomePartner(variables?: GetReportIncomePartnerQueryVariables): Promise<GetReportIncomePartnerQuery> {
      return withWrapper(() => client.request<GetReportIncomePartnerQuery>(print(GetReportIncomePartnerDocument), variables));
    },
    getReportLocationData(variables?: GetReportLocationDataQueryVariables): Promise<GetReportLocationDataQuery> {
      return withWrapper(() => client.request<GetReportLocationDataQuery>(print(GetReportLocationDataDocument), variables));
    },
    getReportLocationUpDown(variables?: GetReportLocationUpDownQueryVariables): Promise<GetReportLocationUpDownQuery> {
      return withWrapper(() => client.request<GetReportLocationUpDownQuery>(print(GetReportLocationUpDownDocument), variables));
    },
    getDataLogin(variables?: GetDataLoginQueryVariables): Promise<GetDataLoginQuery> {
      return withWrapper(() => client.request<GetDataLoginQuery>(print(GetDataLoginDocument), variables));
    },
    getReportOnlineSession(variables: GetReportOnlineSessionQueryVariables): Promise<GetReportOnlineSessionQuery> {
      return withWrapper(() => client.request<GetReportOnlineSessionQuery>(print(GetReportOnlineSessionDocument), variables));
    },
    getReportSharedTrafficDetails(variables?: GetReportSharedTrafficDetailsQueryVariables): Promise<GetReportSharedTrafficDetailsQuery> {
      return withWrapper(() => client.request<GetReportSharedTrafficDetailsQuery>(print(GetReportSharedTrafficDetailsDocument), variables));
    },
    getReportTodayLogin(variables?: GetReportTodayLoginQueryVariables): Promise<GetReportTodayLoginQuery> {
      return withWrapper(() => client.request<GetReportTodayLoginQuery>(print(GetReportTodayLoginDocument), variables));
    },
    getTracking(variables: GetTrackingQueryVariables): Promise<GetTrackingQuery> {
      return withWrapper(() => client.request<GetTrackingQuery>(print(GetTrackingDocument), variables));
    },
    getReportTrackingDetail(variables: GetReportTrackingDetailQueryVariables): Promise<GetReportTrackingDetailQuery> {
      return withWrapper(() => client.request<GetReportTrackingDetailQuery>(print(GetReportTrackingDetailDocument), variables));
    },
    getReportUserReturn(variables?: GetReportUserReturnQueryVariables): Promise<GetReportUserReturnQuery> {
      return withWrapper(() => client.request<GetReportUserReturnQuery>(print(GetReportUserReturnDocument), variables));
    },
    getDataVisit(variables?: GetDataVisitQueryVariables): Promise<GetDataVisitQuery> {
      return withWrapper(() => client.request<GetDataVisitQuery>(print(GetDataVisitDocument), variables));
    },
    getSurveyCampaign(variables: GetSurveyCampaignQueryVariables): Promise<GetSurveyCampaignQuery> {
      return withWrapper(() => client.request<GetSurveyCampaignQuery>(print(GetSurveyCampaignDocument), variables));
    },
    getTotalCampaign(variables?: GetTotalCampaignQueryVariables): Promise<GetTotalCampaignQuery> {
      return withWrapper(() => client.request<GetTotalCampaignQuery>(print(GetTotalCampaignDocument), variables));
    },
    getTotalLocationActive(variables: GetTotalLocationActiveQueryVariables): Promise<GetTotalLocationActiveQuery> {
      return withWrapper(() => client.request<GetTotalLocationActiveQuery>(print(GetTotalLocationActiveDocument), variables));
    },
    getTotalStatusLocation(variables: GetTotalStatusLocationQueryVariables): Promise<GetTotalStatusLocationQuery> {
      return withWrapper(() => client.request<GetTotalStatusLocationQuery>(print(GetTotalStatusLocationDocument), variables));
    },
    getTotalTraffic(variables?: GetTotalTrafficQueryVariables): Promise<GetTotalTrafficQuery> {
      return withWrapper(() => client.request<GetTotalTrafficQuery>(print(GetTotalTrafficDocument), variables));
    },
    getTotalUserLicenses(variables?: GetTotalUserLicensesQueryVariables): Promise<GetTotalUserLicensesQuery> {
      return withWrapper(() => client.request<GetTotalUserLicensesQuery>(print(GetTotalUserLicensesDocument), variables));
    },
    getTrackingByLocation(variables?: GetTrackingByLocationQueryVariables): Promise<GetTrackingByLocationQuery> {
      return withWrapper(() => client.request<GetTrackingByLocationQuery>(print(GetTrackingByLocationDocument), variables));
    },
    getTrackingData3Month(variables?: GetTrackingData3MonthQueryVariables): Promise<GetTrackingData3MonthQuery> {
      return withWrapper(() => client.request<GetTrackingData3MonthQuery>(print(GetTrackingData3MonthDocument), variables));
    },
    getTrackingSurvey(variables: GetTrackingSurveyQueryVariables): Promise<GetTrackingSurveyQuery> {
      return withWrapper(() => client.request<GetTrackingSurveyQuery>(print(GetTrackingSurveyDocument), variables));
    },
    getTrafficSummary(variables: GetTrafficSummaryQueryVariables): Promise<GetTrafficSummaryQuery> {
      return withWrapper(() => client.request<GetTrafficSummaryQuery>(print(GetTrafficSummaryDocument), variables));
    },
    sale_findManyCategory(variables?: Sale_FindManyCategoryQueryVariables): Promise<Sale_FindManyCategoryQuery> {
      return withWrapper(() => client.request<Sale_FindManyCategoryQuery>(print(Sale_FindManyCategoryDocument), variables));
    },
    sale_findManyCity(variables?: Sale_FindManyCityQueryVariables): Promise<Sale_FindManyCityQuery> {
      return withWrapper(() => client.request<Sale_FindManyCityQuery>(print(Sale_FindManyCityDocument), variables));
    },
    sale_findManyDistrict(variables?: Sale_FindManyDistrictQueryVariables): Promise<Sale_FindManyDistrictQuery> {
      return withWrapper(() => client.request<Sale_FindManyDistrictQuery>(print(Sale_FindManyDistrictDocument), variables));
    },
    sale_findManyBasicLocation(variables?: Sale_FindManyBasicLocationQueryVariables): Promise<Sale_FindManyBasicLocationQuery> {
      return withWrapper(() => client.request<Sale_FindManyBasicLocationQuery>(print(Sale_FindManyBasicLocationDocument), variables));
    },
    sale_findManyLocation(variables?: Sale_FindManyLocationQueryVariables): Promise<Sale_FindManyLocationQuery> {
      return withWrapper(() => client.request<Sale_FindManyLocationQuery>(print(Sale_FindManyLocationDocument), variables));
    },
    sendMailtoTheCustomer(variables: SendMailtoTheCustomerQueryVariables): Promise<SendMailtoTheCustomerQuery> {
      return withWrapper(() => client.request<SendMailtoTheCustomerQuery>(print(SendMailtoTheCustomerDocument), variables));
    },
    userBalanceStatus(variables?: UserBalanceStatusQueryVariables): Promise<UserBalanceStatusQuery> {
      return withWrapper(() => client.request<UserBalanceStatusQuery>(print(UserBalanceStatusDocument), variables));
    },
    verifyTwoFactorAuthenticationCode(variables: VerifyTwoFactorAuthenticationCodeQueryVariables): Promise<VerifyTwoFactorAuthenticationCodeQuery> {
      return withWrapper(() => client.request<VerifyTwoFactorAuthenticationCodeQuery>(print(VerifyTwoFactorAuthenticationCodeDocument), variables));
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;