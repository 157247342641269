import '@ant-design/compatible/assets/index.css';

import { Button, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import { UploadProps } from 'antd/lib/upload';
import { RcCustomRequestOptions, UploadFile } from 'antd/lib/upload/interface';
import axios from 'axios';
import { isEmpty } from 'lodash';
import React from 'react';

import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import { LoadingOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';

import { useGraphQLSDK } from '~graphql/GraphQLSDKProvider';

import { ErrorNotify } from './Notification';

interface Props extends UploadProps {
  bannerId?: string;
  label?: string;
  description?: string;
  quantity?: number;
  handleChange: (fileList: UploadFile[]) => void;
  ratio?: boolean;
  width?: number;
  height?: number;
  showButton?: boolean;
  buttonText?: string;
  name?: string;
  crop?: boolean;
}
interface State {
  fileList?: UploadFile[];
  uploading: boolean;
}
export default class UIUpload extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    const { defaultFileList } = this.props;

    this.state = { fileList: defaultFileList ?? [], uploading: false };
  }

  componentDidUpdate(preProps, preState) {
    const { fileList } = this.state;
    const { handleChange } = this.props;
    if (preState.fileList?.length !== fileList?.length) {
      handleChange(fileList);
    }
  }

  uploadFile = async ({ onSuccess, onError, file }: RcCustomRequestOptions) => {
    const sdk = useGraphQLSDK();

    const { name, bannerId } = this.props;
    const {
      createFile: { data, error }
    } = await sdk.createFile(
      !isEmpty(bannerId)
        ? { bannerId, fileName: !name ? name : file.name }
        : { fileName: file.name }
    );
    if (error) {
      return ErrorNotify(error.title);
    }

    const { path: uploadUrl, id } = data!;
    const options = {
      headers: {
        'Content-Type': file.type
      }
    };
    await axios
      .put(uploadUrl, file, options)
      .then(async (res) => {
        const { findFileById } = await sdk.findFileById({ id });
        if (!isEmpty(findFileById) && findFileById.error) {
          return ErrorNotify(findFileById.error.title);
        }

        const { path: viewUrl } = findFileById.data;
        const fileData: UploadFile = {
          uid: id,
          name: file.name,
          url: viewUrl,
          type: file.type,
          size: file.size
        };

        this.setState((preState) => ({
          ...preState,
          fileList: [...preState.fileList, fileData]
        }));
        onSuccess(res, file);
      })
      .catch((err) => {
        onError(err);
      });
  };

  removeFile = async (file: UploadFile<any>) => {
    const sdk = useGraphQLSDK();

    const { fileList } = this.state;

    const {
      removeFileById: { error }
    } = await sdk.removeFileById({ id: file.uid });

    if (error) {
      ErrorNotify(error.title);
    } else {
      const index = fileList.findIndex((f) => f.uid === file.uid);

      this.setState((preState) => ({
        ...preState,
        fileList: [...fileList.slice(0, index), ...fileList.slice(index + 1, fileList.length)]
      }));
    }
  };

  render() {
    const {
      crop,
      name,
      label,
      description,
      quantity,
      handleChange,
      defaultFileList,
      width,
      height,
      bannerId,
      showButton,
      buttonText,
      ...rest
    } = this.props;

    const { uploading, fileList } = this.state;
    const uploadButton =
      rest.listType === 'picture-card' ? (
        <>
          {uploading ? <LoadingOutlined /> : <PlusOutlined />}
          <div className="ant-upload-text">Upload</div>
        </>
      ) : (
        <Button icon={<UploadOutlined />} loading={uploading}>
          {buttonText || 'Upload'}
        </Button>
      );

    const uploadProps: UploadProps = {
      ...rest,
      fileList,
      customRequest: async (option) => {
        this.setState((preState) => ({
          ...preState,
          uploading: true
        }));
        await this.uploadFile(option);
        this.setState((preState) => ({
          ...preState,
          uploading: false
        }));
      },
      onRemove: (file) => {
        this.removeFile(file);
      }
    };

    const imgCropProps = {
      modalTitle: 'Chỉnh sửa ảnh',
      width,
      height
    };

    const uploadRender =
      rest.listType === 'text' || crop === false ? (
        <Upload {...uploadProps}>
          {fileList.length >= (quantity || 8) && !showButton ? null : uploadButton}
        </Upload>
      ) : (
        <ImgCrop {...imgCropProps}>
          <Upload {...uploadProps}>
            {fileList.length >= (quantity || 8) && !showButton ? null : uploadButton}
          </Upload>
        </ImgCrop>
      );
    return (
      <Form.Item label={label}>
        {uploadRender}
        <small>{description}</small>
      </Form.Item>
    );
  }
}
