import { Input, PageHeader } from 'antd';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { SearchOutlined } from '@ant-design/icons';

import OwnerType from '~components/UI/OwnerTypeRadio';

import AssignOwnerPopup from './AssignOwnerPopup';
import Grid from './Grid';
import store from './store';

interface AssignOwnerProps {
  pageHeader: string;
  collectionName: string;
  renderOptions: Function;
  assignRoles?: string[];
}

const AssignOwner = observer((props: AssignOwnerProps) => {
  const { t } = useTranslation();
  const { collectionName, renderOptions, assignRoles } = props;

  const { getCurrenUserAssignments, setSearch, setOwnerType } = store;

  useEffect(() => {
    getCurrenUserAssignments(collectionName);
    return () => {
      setSearch('');
    };
  }, []);

  const handleSearch = (event) => {
    const text = event.target.value;
    setSearch(text);
  };

  return (
    <>
      <AssignOwnerPopup
        title={t('ROLES_MANAGEMENT')}
        renderOptions={renderOptions}
        collectionName={collectionName}
      />
      <PageHeader
        style={{ marginBottom: 0 }}
        backIcon
        ghost={false}
        title={<OwnerType setOwnerFunc={setOwnerType} />}
        extra={[
          <Input
            key="search"
            suffix={<SearchOutlined />}
            placeholder={t('SEARCH')}
            allowClear
            onChange={handleSearch}
            style={{ width: '200px', marginRight: '8px' }}
          />
        ]}
      />
      <Grid assignRoles={assignRoles} />
    </>
  );
});

export default AssignOwner;
