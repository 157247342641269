import './style.less';

import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ColDef } from '@ag-grid-community/core';
import { DATE_FORMAT, format } from '@source/common';

import AgGridClient from '~components/UI/AgGrid/AgGridClient';
import { formatNumber } from '~utils/formatNumber';

import store from './store';

interface GridProps {
  height: number;
  width?: number;
}

const Grid: FC<GridProps> = observer(({ height }) => {
  const { t } = useTranslation();
  const { onGridClientReady, onOpenCategory, onOpenLocation } = store;

  const columnDefs: ColDef[] = [
    {
      field: 'categoryName',
      cellClass: 'text-center text-bold',
      cellRenderer: 'agGroupCellRenderer',
      sortable: false,
      minWidth: 300
    },
    {
      headerName: t('IMPRESSION'),
      field: 'impression',
      enableValue: true,
      minWidth: 150,
      maxWidth: 150,
      sortable: true,
      cellClass: 'text-right text-bold',
      cellRendererFramework: ({ value }) => {
        if (typeof value !== 'number') {
          return 0;
        }
        return formatNumber(value);
      }
    },
    {
      headerName: t('CLICK'),
      field: 'click',
      enableValue: true,
      sortable: true,
      minWidth: 150,
      maxWidth: 150,
      cellClass: 'text-right text-bold',
      cellRendererFramework: ({ value }) => {
        if (typeof value !== 'number') {
          return 0;
        }
        return formatNumber(value);
      }
    },
    {
      headerName: t('CTR'),
      field: 'ctr',
      sortable: true,
      enableValue: true,
      minWidth: 100,
      maxWidth: 100,
      cellClass: 'text-right text-bold',
      valueFormatter: ({ value }) => `${Number(value).toFixed(2)} %`
    }
  ];

  const detailLocation = () => {
    return {
      detailGridOptions: {
        embedFullWidthRows: true,
        // pagination: true,
        // paginationAutoPageSize: true,
        headerHeight: 0,
        columnDefs: [
          {
            field: 'date',
            cellClass: 'text-right',
            minWidth: 300,
            cellRenderer: ({ value }) => {
              return format(value, DATE_FORMAT);
            }
          },
          {
            field: 'impression',
            minWidth: 150,
            maxWidth: 150,
            cellClass: 'text-right',
            valueFormatter: ({ value }) => formatNumber(value)
          },
          {
            field: 'click',
            minWidth: 150,
            maxWidth: 150,
            cellClass: 'text-right',
            valueFormatter: ({ value }) => formatNumber(value)
          },
          {
            field: 'ctr',
            minWidth: 100,
            maxWidth: 100,
            cellClass: 'text-right',
            valueFormatter: ({ value }) => `${Number(value).toFixed(2)} %`
          }
        ],
        defaultColDef: {
          menuTabs: [],
          sortable: true,
          flex: 1
        }
      },
      getDetailRowData: async params => {
        const data = await onOpenLocation(params.data.locationId);
        params.successCallback(data.data);
      }
    };
  };

  const detailCategory = () => {
    return {
      detailGridOptions: {
        embedFullWidthRows: true,
        // pagination: true,
        // paginationAutoPageSize: true,
        masterDetail: true,
        detailRowHeight: 350,
        headerHeight: 0,
        detailCellRendererParams: detailLocation(),
        columnDefs: [
          {
            headerName: 'Locations',
            field: 'locationName',
            sortable: false,
            cellRenderer: 'agGroupCellRenderer',
            cellClass: 'paddingLeft-40',
            minWidth: 300,
          },
          {
            headerName: t('IMPRESSION'),
            field: 'impression',
            minWidth: 150,
            maxWidth: 150,
            sortable: true,
            cellClass: 'text-right',
            cellRendererFramework: ({ value }) => {
              return value ? formatNumber(value) : 0;
            }
          },
          {
            headerName: t('CLICK'),
            field: 'click',
            minWidth: 150,
            maxWidth: 150,
            sortable: true,
            cellClass: 'text-right',
            cellRendererFramework: ({ value }) => {
              if (typeof value !== 'number') {
                return 0;
              }
              return formatNumber(value);
            }
          },
          {
            headerName: t('CTR'),
            field: 'ctr',
            minWidth: 100,
            maxWidth: 100,
            sortable: true,
            cellClass: 'text-right',
            valueFormatter: ({ value }) => `${Number(value).toFixed(2)} %`
          }
        ],
        defaultColDef: {
          menuTabs: [],
          sortable: true,
          flex: 1
        }
      },
      getDetailRowData: async params => {
        const data = await onOpenCategory(params.data.categoryId);
        params.successCallback(data.data);
      }
    };
  };

  return (
    <AgGridClient
      onGridReady={onGridClientReady}
      masterDetail
      columnDefs={columnDefs}
      detailCellRendererParams={detailCategory()}
      detailRowHeight={height - 110}
      height={height - 55}
    />
  );
});
export default Grid;
