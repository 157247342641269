import { Alert, Checkbox, Col, Divider, Form, Input, InputNumber, Modal, Row } from 'antd';
import { Store } from 'antd/lib/form/interface';
import { observer } from 'mobx-react';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MODAL_UPDATE } from '@source/common';

import UIUpload from '~components/UI/UIUpload';
import { CustomerInput, CustomerUpdateArg } from '~graphql/_sdk';

import store from './store';

const CustomerForm: FC = observer(() => {
  const { Item, useForm } = Form;
  const {
    modalVisible,
    selectedItem,
    setModalVisible,
    create,
    update,
    setFileList,
    fileList,
    modalType,
    urlLogo,
    handlePreview,
    previewVisible,
    previewImage
  } = store;

  const [form] = useForm();
  const initialValues = {
    name: selectedItem?.name,
    email: selectedItem?.email,
    password: selectedItem?.password,
    signature: selectedItem?.signature,
    phone: selectedItem?.phone,
    website: selectedItem?.website,
    address: selectedItem?.address,
    taxCode: selectedItem?.taxCode,
    typeCode: selectedItem?.typeCode,
    urlLogo: selectedItem?.urlLogo,
    ratio: selectedItem?.shareTraffic?.ratio || 50,
    isShareTraffic: selectedItem?.shareTraffic?.enabled || false,
    isShareRevenue: selectedItem?.shareRevenue?.enabled || false
  };
  const [checked, setChecked] = useState(initialValues.isShareTraffic);
  const [isRevenue, setIsRevenue] = useState(initialValues.isShareRevenue);

  useEffect(() => {
    form.setFieldsValue(initialValues);
    setChecked(initialValues.isShareTraffic);
    setIsRevenue(initialValues.isShareRevenue);
    // return () => reset();
  }, [selectedItem]);

  const onCancel = () => {
    form.resetFields();
    setChecked(false);
    setIsRevenue(false);
    setModalVisible();
  };

  const submit = async (record: Store) => {
    const r: Store = {
      ...record,
      urlLogo,
      shareTraffic: { enabled: checked, ratio: record.ratio },
      shareRevenue: { enabled: isRevenue }
    };
    delete r?.ratio;
    delete r?.isShareTraffic;
    delete r?.isShareRevenue;
    delete r?.logos;

    if (!selectedItem) {
      await create(r as CustomerInput);
    } else {
      await update(r as CustomerUpdateArg);
    }
  };

  const { t } = useTranslation();
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 }
  };
  return (
    <Modal
      title={'Customer'}
      style={{ top: 20, width: 1000 }}
      visible={modalVisible}
      onCancel={onCancel}
      okText={modalType === MODAL_UPDATE ? t('UPDATE') : t('CREATE')}
      okButtonProps={{
        type: 'primary',
        form: 'customerForm',
        htmlType: 'submit'
      }}
      onOk={() => {
        form
          .validateFields()
          .then(async record => {
            await submit(record);
            setModalVisible();
            form.resetFields();
          })
          .catch(info => {
          });
      }}
      maskClosable={false}
      forceRender
      cancelText={t('CLOSE')}
      bodyStyle={{ maxHeight: 600, overflow: 'scroll' }}
    >
      <Form
        autoComplete="off"
        title={t('CUSTOMER')}
        {...layout}
        form={form}
        name="customerForm"
        layout="vertical"
      >
        <Row gutter={8}>
          <Col span={12}>
            <Item
              style={{ width: 330 }}
              label={t('NAME')}
              name="name"
              rules={[{ required: true, message: 'Name is required' }]}
            >
              <Input placeholder="Name of the customer" />
            </Item>
          </Col>
          <Col span={12}>
            <Item
              style={{ width: 330 }}
              label={t('Email')}
              name="email"
              rules={[{ required: true, message: 'Email is required' }]}
            >
              <Input autoComplete="off" key="inpEmail" placeholder="Email of the customer" />
            </Item>
          </Col>
        </Row>
        {modalType !== MODAL_UPDATE && (
          <Row gutter={8}>
            <Col span={12}>
              <Item
                style={{ width: 330 }}
                label={t('Password')}
                name="password"
                rules={[{ required: true, message: 'Password is required' }]}
              >
                <Input.Password autoComplete="off" placeholder="Password of the customer" />
              </Item>
            </Col>
            <Col span={12}>
              <Item style={{ width: 330 }} label={t('Signature')} name="signature">
                <Input placeholder="Signature of the customer" />
              </Item>
            </Col>
          </Row>
        )}
        <Row gutter={8}>
          <Col span={12}>
            <Item style={{ width: 330 }} label={t('Phone')} name="phone">
              <Input placeholder="Phone of the customer" />
            </Item>
          </Col>
          <Col span={12}>
            <Item style={{ width: 330 }} label={t('Website')} name="website">
              <Input placeholder="Phone of the customer" />
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Item label={t('Address')} name="address">
              <Input style={{ width: 460 }} placeholder="Address of the customer" />
            </Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <Item style={{ width: 330 }} label={t('Tax Code')} name="taxCode">
              <Input placeholder="TaxCode of the customer" />
            </Item>
          </Col>
          <Col span={12}>
            <Item
              style={{ width: 330 }}
              label={t('Type Code')}
              name="typeCode"
              rules={[{ required: true, message: 'Type Code is required' }]}
            >
              <Input placeholder="TypeCode of the customer" />
            </Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={24}>
            <Divider orientation="left">Share</Divider>
          </Col>
          <Col style={{ paddingTop: 10, paddingBottom: 10 }} span={8}>
            <Checkbox checked={checked} onChange={e => setChecked(e.target.checked)}>
              Share Traffic
            </Checkbox>
          </Col>
          {checked && (
            <Col offset={4} span={10}>
              <Item style={{ width: 330 }} name="ratio">
                <InputNumber
                  step={5}
                  min={0}
                  max={100}
                  formatter={value => `${value}%`}
                  parser={value => value.replace('%', '')}
                />
              </Item>
            </Col>
          )}
        </Row>
        <Row gutter={8}>
          <Col style={{ paddingTop: 10, paddingBottom: 10 }} span={8}>
            <Item style={{ width: 330 }} name="isShareRevenue">
              <Checkbox checked={isRevenue} onChange={e => setIsRevenue(e.target.checked)}>
                Share Revenue
              </Checkbox>
            </Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={24}>
            <Divider orientation="left">Logo</Divider>
            <UIUpload
              width={100}
              height={100}
              listType="picture-card"
              // label="Logo"
              onPreview={e => handlePreview(e)}
              key={selectedItem ? 'logo' : 'upload'}
              bannerId={selectedItem ? selectedItem._id : 'customer_mkt'}
              quantity={1}
              handleChange={setFileList}
              defaultFileList={fileList}
              name={undefined}
              accept="image/*"
            />
            <Modal visible={previewVisible} footer={null} onCancel={handlePreview}>
              <img alt="img" style={{ width: '100%' }} src={previewImage} />
            </Modal>
          </Col>
          {/* <Col span={24}>
            <Alert message="Logo should be 100px x 100px " type="warning" showIcon />
          </Col> */}
        </Row>
      </Form>
    </Modal>
  );
});

export default CustomerForm;
