import { sum } from 'lodash';
import { action, observable, reaction, runInAction, toJS } from 'mobx';
import { actionAsync, task } from 'mobx-utils';
import moment from 'moment';

import { Age, DateArgs, DateFilter, Gender, Income, ReportLogin } from '~graphql/_sdk';
import { getSdk } from '~graphql/sdk';

import { categorieLoginChart } from './chartOptions';

class Store {
  @observable public totalAge: number;
  @observable public totalIncome: number;
  @observable public totalGender: number;
  @observable public genders: Gender[];
  @observable public ages: Age[];
  @observable public incomes: Income[];
  @observable public reportLogin: ReportLogin;
  @observable public selectDay: number;
  @observable public selectDayText: string;
  @observable public loginLoading: boolean;
  @observable public enableLogin: boolean;
  @observable public reportVisit;
  @observable public reportDevice;
  @observable public titleDay: number;
  @observable public dateFilter: DateArgs;
  @observable public loadingAudiencePie: boolean;
  @observable public loadingDevice: boolean;
  @observable public loadingVisit: boolean;
  private init: boolean;

  constructor() {
    runInAction(() => {
      this.genders = [];
      this.ages = [];
      this.incomes = [];
      this.reportVisit = [];
      this.reportDevice = [];
      this.init = true;
      this.titleDay = 7;
      this.dateFilter = {
        gte: moment()
          .subtract(7, 'days')
          .startOf('days')
          .valueOf(),
        lte: moment()
          .startOf('days')
          .valueOf()
      };
      reaction(
        () => this.titleDay,
        titleDay => {
          this.getReportVisit(null, titleDay);
        }
      );

      reaction(
        () => this.dateFilter,
        date => {
          if (!date) return;
          this.getReportDevice(null);
        }
      );
    });
  }

  @action
  public setSelectDay = (date: string) => {
    this.selectDayText = date;
    const index = categorieLoginChart.findIndex(e => e === date);
    this.selectDay = index + 1;
  };

  @action
  public setEnableLogin = (value: boolean) => {
    this.enableLogin = value;
  };

  @action
  public refreshSelectDay = () => {
    this.selectDay = -1;
    this.selectDayText = 'Weekday';
  };

  @actionAsync
  public findManyAudience = async () => {
    const sdk = await task(getSdk());
    const {
      getDataAudience: { totalIncome, totalAge, totalGender, genders, ages, incomes }
    } = await task(sdk.getDataAudience());
    this.totalAge = totalAge;
    this.totalIncome = totalIncome;
    this.totalGender = totalGender;
    this.genders = genders;
    this.ages = ages;
    this.incomes = incomes;
  };

  @action
  public setTitleDay = (titleDay: number) => {
    this.titleDay = titleDay;
  };

  @actionAsync
  public getReportLogin = async (locationId: string = null) => {
    this.loginLoading = true;
    const sdk = await task(getSdk());
    const { getDataLogin } = await task(sdk.getDataLogin({ locationId }));
    this.reportLogin = getDataLogin;
    this.loginLoading = false;
  };

  @actionAsync
  public getReportVisit = async (locationId: string = null, titleDay: number = 7) => {
    this.loadingVisit = true;
    const sdk = await task(getSdk());
    const {
      getDataVisit: { last7Days, last15Days, last30Days }
    } = await task(sdk.getDataVisit({ locationId }));

    let objectDay = last7Days;

    switch (titleDay) {
      case 15:
        objectDay = last15Days;
        break;
      case 30:
        objectDay = last30Days;
        break;
      default:
        break;
    }

    const totalVisit = sum(Object.values(objectDay));

    this.reportVisit = {
      percentOnce: ((objectDay.once / totalVisit) * 100).toFixed(1),
      percentTwoFive: ((objectDay.twoFive / totalVisit) * 100).toFixed(1),
      percentSixTen: ((objectDay.sixTen / totalVisit) * 100).toFixed(1),
      percentTenPlus: ((objectDay.tenPlus / totalVisit) * 100).toFixed(1),
      totalVisit
    };
    this.loadingVisit = false;
  };

  @actionAsync
  public getReportDevice = async (locationId: string = null) => {
    this.loadingDevice = true;
    const sdk = await task(getSdk());
    const {
      getDataOSDevice: {
        deviceTypeCount: { Mobile, PC, Tablet },
        osCount: { Android, iOS, other }
      }
    } = await task(sdk.getDataOSDevice({ locationId, dateFilter: this.dateFilter }));

    const totalDevice = sum([Mobile, PC, Tablet]);
    const totalOS = sum([Android, iOS, other]);

    this.reportDevice = {
      percentMobile: +((Mobile / totalDevice) * 100).toFixed(1),
      percentPC: +((PC / totalDevice) * 100).toFixed(1),
      percentTablet: +((Tablet / totalDevice) * 100).toFixed(1),
      percentAndroid: +((Android / totalOS) * 100).toFixed(1),
      percentIOS: +((iOS / totalOS) * 100).toFixed(1),
      percentOther: +((other / totalOS) * 100).toFixed(1),
      totalDevice
    };
    this.loadingDevice = false;
  };

  @action
  setDate = (numberDate: number) => {
    this.dateFilter = {
      gte: moment()
        .subtract(numberDate, 'days')
        .startOf('days')
        .valueOf(),
      lte: moment()
        .startOf('days')
        .valueOf()
    };
  };

  @actionAsync
  public fetchData = async () => {
    if (this.init) {
      await this.getReportLogin();
      await this.getReportVisit();
      await this.getReportDevice();
    }
    this.init = false;
  };
}

export default new Store();
