import { Drawer, Row } from 'antd';
import { get, isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DeviceType, OsType } from '@source/common';

import store from '../store';
import FilterElement from './FilterElement';

const deviceOptions = DeviceType.map((e) => {
  return {
    text: e,
    value: e
  };
});

const osOptions = OsType.map((e) => {
  return {
    text: e,
    value: e
  };
});

const CRMFilter: FC = observer(() => {
  const { getCrmSelectOptions, campaignInfo, drawerVisible, setDrawerVisible } = store;
  const [audience, customs] = getCrmSelectOptions;

  const [crmOptionMapping, setCrmOptionMapping] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    const crmValue = get(campaignInfo, 'config.crmValue');
    const mapping = {};

    if (isEmpty(crmValue)) return;

    Object.keys(crmValue).forEach((key) => {
      const options = crmValue[key]?.map((e) => {
        return {
          label: e,
          value: e
        };
      });
      mapping[key] = options;
    });

    setCrmOptionMapping(mapping);
  }, [campaignInfo]);

  return (
    <Drawer
      title={t('FILTER')}
      placement="right"
      closable={false}
      width={500}
      onClose={() => setDrawerVisible()}
      visible={drawerVisible}
      getContainer={false}
      style={{ position: 'absolute' }}
    >
      <Row gutter={16} style={{ marginBottom: 8 }}>
        <FilterElement
          item={{ text: 'Device', key: 'device' }}
          target="ua"
          option={deviceOptions}
          key="device"
          enableOption={{
            empty: false,
            notEmpty: false
          }}
        />

        <FilterElement
          item={{ text: 'Os', key: 'os' }}
          target="ua"
          option={osOptions}
          key="os"
          enableOption={{
            empty: false,
            notEmpty: false
          }}
        />

        {audience.map((au) => (
          <FilterElement item={au} target="audience" key={`audience-${au.key}`} />
        ))}

        {customs.map((cus) => (
          <FilterElement
            item={cus}
            target="survey"
            key={`survey-${cus.key}`}
            option={crmOptionMapping[cus.key]}
          />
        ))}
      </Row>
    </Drawer>
  );
});

export default CRMFilter;
