import { Button, Card, Col, Divider, Progress, Row, Typography } from 'antd';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { CaretLeftOutlined } from '@ant-design/icons';

import OwnerType from '~components/UI/OwnerTypeRadio';
import { selectCampaignTemplate } from '~components/UI/Select/SelectTemplates';
import { UISelect } from '~components/UI/Select/UISelect';
import { useStore } from '~context/react-context';
import { stringifyQuery } from '~utils/query-string';
import { useTranslation } from 'react-i18next';

import store from '../store';

const HeaderInfo: FC = observer(() => {
  const { campaignId, onSelectCampaignChange, onBack, setOwnerType } = store;
  const history = useHistory();
  const { t } = useTranslation();

  const { commonDataStore } = useStore();

  const onClick = () => {
    onBack();
    history.push({ search: '' });
  };

  const onCampaignChange = (cid: string) => {
    const query = stringifyQuery({ id: cid });
    history.push({ search: query });
    onSelectCampaignChange(cid);
  };

  return (
    <Row gutter={8} justify="space-around" align="middle">
      <Col flex="0 0 auto">
        <Button style={{ width: '100%' }} icon={<CaretLeftOutlined />} onClick={onClick}>
        {t('BACK')}
        </Button>
      </Col>
      <Col flex="1 1 auto">
        <Divider style={{ margin: 0, color: '#737373' }} orientation="left">
          {t('REPORT_DETAIL')}
        </Divider>
      </Col>
      {/* <Col flex="1 1">
        <OwnerType setOwnerFunc={setOwnerType} />
      </Col> */}
      <Col flex="1 1 auto" style={{ maxWidth: 350 }}>
        <Card size="small" style={{ borderColor: '#FFA242', height: '100%', borderRadius: 4 }}>
          <UISelect
            dataOptions={{
              data: commonDataStore.campaigns,
              valueField: '_id',
              textField: 'name',
              template: selectCampaignTemplate
            }}
            size="small"
            bordered={false}
            value={campaignId}
            onChange={(e) => onCampaignChange(e)}
          />
        </Card>
      </Col>
    </Row>
  );
});
export default HeaderInfo;
