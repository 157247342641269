import { throttle } from 'lodash';
import { action, observable, reaction } from 'mobx';
import { actionAsync, task } from 'mobx-utils';
import moment from 'moment';

import { DateArgs, TrafficSummaryData } from '~graphql/_sdk';
import { getSdk } from '~graphql/sdk';

export type StoreFilterDate = '7days' | '15days' | '30days';

export class TrafficSummaryStore {
  @observable public data: TrafficSummaryData;
  @observable public loading: boolean;
  @observable public filterDate: StoreFilterDate;

  private filter: DateArgs;

  constructor() {
    this.reset();

    reaction(
      () => this.filterDate,
      date => {
        switch (date) {
          case '7days':
            this.filter.gte = moment()
              .subtract(7, 'days')
              .startOf('day');
            break;
          case '15days':
            this.filter.gte = moment()
              .subtract(14, 'days')
              .startOf('day');
            break;
          case '30days':
            this.filter.gte = moment()
              .subtract(29, 'days')
              .startOf('day');
            break;
          default:
            return null;
        }
        this.onFilterChange();
      }
    );
  }

  @action setFilterDate = (value: StoreFilterDate) => {
    this.filterDate = value;
  };

  @action
  public reset = () => {
    this.filterDate = '30days';
    this.data = null;
    this.loading = false;
    this.filter = {
      gte: moment()
        .subtract(29, 'days')
        .startOf('day'),
      lte: moment().endOf('day')
    };
  };

  @actionAsync public getDataTrafficSummary = async () => {
    this.loading = true;
    const sdk = await task(getSdk());
    const { getTrafficSummary } = await task(
      sdk.getTrafficSummary({ gte: this.filter.gte, lte: this.filter.lte })
    );

    this.data = getTrafficSummary;
    this.loading = false;
  };

  private onFilterChange = throttle(
    () => {
      this.getDataTrafficSummary();
    },
    300,
    { leading: false, trailing: true }
  );
}

export default new TrafficSummaryStore();
