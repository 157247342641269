import { Button, Dropdown, Menu, Modal } from 'antd';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ReactResizeDetector from 'react-resize-detector';

import { ColDef } from '@ag-grid-community/core';
import { DeleteOutlined, EditOutlined, EllipsisOutlined, PictureOutlined } from '@ant-design/icons';
import {
  ROLE_DELETE_BANNER_TEMPLATE,
  ROLE_UPDATE_BANNER_TEMPLATE
} from '@source/common';

import Authorized from '~components/Authorized/Authorized';
import AgGridServer from '~components/UI/AgGrid/AgGridServer';
import { WhereArgs } from '~graphql/_sdk.tmp';
import { useGraphQLSDK } from '~graphql/GraphQLSDKProvider';

import store from './store';

const { confirm } = Modal;

const BannerTemplateGrid: FC = observer(() => {
  const { t } = useTranslation();
  const sdk = useGraphQLSDK();

  const { handleGridReady, where, remove } = store;

  // const newWindow = url => {
  //   setTimeout(() => window.open(`${url}?t=${Date.now()}`, '_blank'), 200);
  // };

  const showDeleteConfirm = (value, text) => {
    confirm({
      title: (
        <div>
          {t('ARE_YOU_SURE_DELETE')} <b>{text}</b>
        </div>
      ),
      okText: t('YES'),
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        remove(value);
      }
    });
  };
  const columnDefs: ColDef[] = [
    {
      headerName: '',
      field: '_id',
      minWidth: 70,
      maxWidth: 70,
      cellRendererFramework: ({ value, data }) => {
        const moreActions = (
          <Menu>
            <Menu.Item
              key="view"
              style={{ color: 'green' }}
              // onClick={() => newWindow(get(data, 'link', ''))}
            >
              <PictureOutlined />
              {t('VIEW')}
            </Menu.Item>
            <Menu.Divider />

            <Authorized roles={[ROLE_DELETE_BANNER_TEMPLATE]} key="delete">
              <Menu.Item
                onClick={() => showDeleteConfirm(value, data.name)}
                style={{ color: 'red' }}
              >
                <DeleteOutlined style={{ color: 'red' }} />
                {t('DELETE')}
              </Menu.Item>
            </Authorized>
          </Menu>
        );

        return (
          <Button.Group size="small">
            <Authorized roles={[ROLE_UPDATE_BANNER_TEMPLATE]}>
              <Button icon={<EditOutlined />} type="primary" onClick={() => store.setModalVisible(data)} />
            </Authorized>
            <Dropdown overlay={moreActions}>
              <Button icon={<EllipsisOutlined />} type="default" />
            </Dropdown>
          </Button.Group>
        );
      }
    },
    {
      headerName: t('NO'),
      field: '_id',
      minWidth: 50,
      maxWidth: 50,
      cellRenderer: ({ rowIndex }) => rowIndex + 1
    },
    {
      headerName: t('NAME'),
      field: 'name',
      sort: 'asc'
    },
    {
      headerName: t('TYPE'),
      field: 'typeText',
      minWidth: 150,
      maxWidth: 150
    }
  ];
  return (
    <ReactResizeDetector handleHeight>
      {({ height }) => (
        <AgGridServer
          options={{ columnDefs, cacheBlockSize: 50, paginationPageSize: 50 }}
          onQuery={sdk.findManyBannerTemplate}
          queryKey="findManyBannerTemplate"
          onCountQuery={sdk.countBannerTemplate}
          countQueryKey="countBannerTemplate"
          onGridReady={handleGridReady}
          where={where as WhereArgs}
          height={height - 80}
        />
      )}
    </ReactResizeDetector>
  );
});

export default BannerTemplateGrid;
