import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ReactResizeDetector from 'react-resize-detector';

import { ColDef } from '@ag-grid-community/core';

import AgGridClient from '~components/UI/AgGrid/AgGridClient';
import { formatNumber } from '~utils/formatNumber';

import store from './store';

const GridCampaign: FC = observer(() => {
  const { income, handleGridReady } = store;
  const { t } = useTranslation();

  const columnDefs: ColDef[] = [
    {
      headerName: t('NO'),
      field: '',
      cellClass: 'text-center',
      minWidth: 50,
      maxWidth: 50,
      cellRendererFramework: ({ rowIndex }) => rowIndex + 1
    },
    {
      headerName: t('CAMPAIGN_NAME'),
      field: 'campaignName',
      minWidth: 250,
    },
    {
      headerName: t('INCOME'),
      field: 'click',
      sortable: true,
      minWidth: 100,
      maxWidth: 150,
      cellClass: 'text-right',
      cellRendererFramework: ({ value }) => formatNumber(value)
    },
    {
      headerName: t('INCOME'),
      field: 'income',
      sortable: true,
      minWidth: 100,
      cellClass: 'text-right',
      cellRendererFramework: ({ value }) => formatNumber(value)
    }
  ];

  return (
    <ReactResizeDetector handleHeight>
      {({ height }) => {
        return (
          <AgGridClient
            columnDefs={columnDefs}
            height={height}
            rowDragManaged
            suppressRowDrag={false}
            animateRows
            rowData={income.groupByCampaign}
            onGridReady={handleGridReady}
          />
        );
      }}
    </ReactResizeDetector>
  );
});

export default GridCampaign;
