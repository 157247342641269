import '@ant-design/compatible/assets/index.css';

import { Button, Col, Divider, Form, Modal, Row, Select } from 'antd';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { UISelect } from '~components/UI/Select/UISelect';
import { UIDatePicker } from '~components/UI/UIDatePicker';
import { UIInputNumber } from '~components/UI/UIInputNumber';
import { useStore } from '~context/react-context';
import { AdvanceLocationUpdateArgs } from '~graphql/_sdk';

import store from './store';

interface FormValues {
  status?: string;
  monthlyClick?: number;
  activeDay?: Date;
  configAuth?: string;
  owner?: string;
  share?: string;
  unitPrice?: number;
}

const { Option } = Select;

const validateMessages = {
  required: '${label} is required!'
};

const AdvanceForm: FC = observer(() => {
  const { t } = useTranslation();
  const {
    modalAdvanceVisible,
    setModalVisible,
    updateAdvance,
    selectedItem,
    onFieldChange,
    cachedFields,
    loading
  } = store;
  const { commonDataStore } = useStore();
  const [form] = Form.useForm();

  const initialValues: FormValues = {
    unitPrice: get(cachedFields, 'unitPrice'),
    owner: get(cachedFields, 'owner'),
    share: get(cachedFields, 'shareType'),
    monthlyClick: get(cachedFields, 'monthlyClick'),
    activeDay: get(cachedFields, 'activeDay', new Date()),
    configAuth: get(cachedFields, 'configAuth', 'no_authen')
  };

  const authOptions = [
    {
      key: 'no_authen',
      value: 'no_authen',
      children: t('NO_AUTHENTICATION')
    },
    {
      key: 'voucher',
      value: 'voucher',
      children: t('BY_VOUCHER')
    },
    {
      key: 'user_pass',
      value: 'user_pass',
      children: t('BY_USERNAME_AND_PASSWORD')
    }
  ];
  const shareTypes = [
    {
      key: 'revenue',
      value: 'revenue',
      children: 'Revenue'
    },
    {
      key: 'traffic',
      value: 'traffic',
      children: 'Traffic'
    }
  ];

  const onCancel = () => {
    form.resetFields();
    setModalVisible(null, 'advanced-info');
  };

  const onSubmit = async (record: FormValues) => {
    const { activeDay, configAuth, monthlyClick, owner, share, unitPrice } = record;

    let type = '';
    if (share === 'traffic') {
      type = 'percentTraffic';
    }

    const records = {
      activeDay,
      configAuth,
      share,
      stats: {
        monthlyClick
      },
      shareType: {
        type,
        owner,
        unitPrice
      },
      customerId: owner
    };
    await updateAdvance(selectedItem._id.toString(), records as AdvanceLocationUpdateArgs);
    setModalVisible(null, 'advanced-info');
  };

  const footer = (
    <>
      <Button type="ghost" onClick={() => onCancel()}>
        {t('CLOSE')}
      </Button>
      <Button htmlType="submit" form="advanceForm" loading={loading} type="primary">
        {t('UPDATE')}
      </Button>
    </>
  );

  const onChange = (field: string, value: any) => {
    onFieldChange({ [field]: value });
  };

  useEffect(() => {
    commonDataStore.loadCustomers({ shareTraffic: { enabled: true } });
    form.setFieldsValue(initialValues);
  }, [cachedFields]);

  return (
    <Modal
      title="Advance Info"
      closable={false}
      visible={modalAdvanceVisible}
      footer={footer}
      width={850}
      style={{ top: 20 }}
    >
      <Form
        id="advanceForm"
        initialValues={initialValues}
        validateMessages={validateMessages}
        onFinish={onSubmit}
        layout="vertical"
        form={form}
      >
        <Divider orientation="left">Advance</Divider>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="monthlyClick"
              label={t('CLICK_PER_MONTH')}
              rules={[{ required: true }]}
            >
              <UIInputNumber
                style={{ width: '100%' }}
                onChange={v => onChange('monthlyClick', v)}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="activeDay" label={t('ACTIVE_DAY')}>
              <UIDatePicker onChange={v => onChange('activeDay', v)} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="configAuth"
              label={t('AUTHENTICATION_TYPE')}
              rules={[{ required: true }]}
            >
              <UISelect
                onChange={v => onChange('configAuth', v)}
                placeholder="Chọn loại xác thực:"
                dataOptions={{
                  data: authOptions,
                  valueField: 'value',
                  textField: 'children'
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Divider orientation="left">Share Setting</Divider>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="share" label={t('SHARE')}>
              <UISelect
                onChange={v => onChange('share', v)}
                placeholder="Select Share Type"
                dataOptions={{
                  data: shareTypes,
                  valueField: 'value',
                  textField: 'children'
                }}
                allowClear
              >
                {shareTypes.map(({ key, ...prop }) => (
                  <Option key={key} {...prop} />
                ))}
              </UISelect>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="owner" label={t('CUSTOMER')}>
              <UISelect
                onChange={v => onChange('owner', v)}
                placeholder={t('SELECT_CUSTOMER')}
                dataOptions={{
                  data: commonDataStore.customers,
                  valueField: '_id',
                  textField: 'name'
                }}
                allowClear
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="unitPrice" label={t('UNIT_PRICE')}>
              <UIInputNumber
                style={{ width: '100%' }}
                onChange={v => onChange('unitPrice', v)}
                placeholder="% Share"
                min={10}
                max={100}
                step={10}
                formatter={value => `${value} %`}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
});
export default AdvanceForm;
