import i18next from 'i18next';
import { action, observable, runInAction } from 'mobx';
import { actionAsync, task } from 'mobx-utils';

import { ErrorNotify, SuccessNotify } from '~components/UI/Notification';
import { getSdk } from '~graphql/sdk';

class Store {
  @observable modalVisible: boolean;
  @observable storedPassword: string;
  @observable userId: string;

  constructor() {
    runInAction(() => {
      this.modalVisible = false;
    });
  }

  @action
  public setStoredPassword = (pass: string) => {
    this.storedPassword = pass;
  };

  @action
  public setUserId = (id: string) => {
    this.userId = id;
  };

  @action
  public setModalVisible = () => {
    this.modalVisible = !this.modalVisible;
  };

  @actionAsync
  sendMail = async (email: string) => {
    const sdk = await task(getSdk());
    const {
      sendMailForgotPassword: { error }
    } = await task(sdk.sendMailForgotPassword({ email }));
    if (error) {
      ErrorNotify(error.code);
    } else {
      SuccessNotify(i18next.t('NOTI_CHECK_MAIL'));
    }
  };
}

export default new Store();
