import { observable, runInAction, toJS } from 'mobx';
import { actionAsync, task } from 'mobx-utils';

import { ErrorNotify, SuccessNotify } from '~components/UI/Notification';
import { getSdk } from '~graphql/sdk';

class Store {
  @observable public isVerifiedEmail;

  constructor() {
    runInAction(() => {
      this.isVerifiedEmail = {};
    });
  }
  @actionAsync
  verifyEmail = async (currentUserId: string, email: string, key: string) => {
    const sdk = await task(getSdk());
    const { verifyEmailLicense } = await task(
      sdk.verifyEmailLicense({ currentUserId, email, key })
    );
    this.isVerifiedEmail = verifyEmailLicense.data;
  };
}

export default new Store();
