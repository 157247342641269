import '@ant-design/compatible/assets/index.css';

import { Col, Divider, Input, Modal, Row, Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import i18next from 'i18next';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { FC, ReactText, useEffect, useState } from 'react';
import useForm from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { Form } from '@ant-design/compatible';
import { PlusOutlined } from '@ant-design/icons';
import { stringToJson } from '@source/common/src/utils/stringToJson';

import UIField from '~components/UI/UIField';
import { CommonInput, CommonUpdateArg } from '~graphql/_sdk';
import { isJSON } from '~utils/query-string';

import store from './store';

interface FormValues {
  value?: string;
  text?: string;
  type?: string;
  icon?: string;
  meta?: string;
}

yup.addMethod(yup.string, 'JSON', function (message: string) {
  return this.test('validate-JSON', message, (value) => {
    return isJSON(value);
  });
});

const validationSchema = yup.object().shape<FormValues>({
  text: yup.string().required(i18next.t('COMMON_DATA_VALIDATE_TEXT_REQUIRE')),
  value: yup.string().required(i18next.t('COMMON_DATA_VALIDATE_VALUE_REQUIRE')),
  type: yup.string().required(i18next.t('COMMON_DATA_VALIDATE_TEXT_REQUIRE')),
  // icon: yup.string(),
  meta: yup.string().JSON(i18next.t('NOTI_FORMAT_DATA_JSON'))
});

const DeviceGroupForm: FC = observer(() => {
  const { t } = useTranslation();
  const { modalVisible, setModalVisible, create, update, selectedItem } = store;
  const [inputModal, setInputModal] = useState(false);
  const [types, setType] = useState([]);
  const [text, setText] = useState('');

  useEffect(() => {
    setType(store.types);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.types]);
  const initValues: FormValues = {
    text: get(selectedItem, 'text', ''),
    value: get(selectedItem, 'value', ''),
    type: get(selectedItem, 'type'),
    icon: get(selectedItem, 'icon', ''),
    meta: JSON.stringify(get(selectedItem, 'meta', JSON.parse('{}')))
  };

  const formProps = useForm({ validationSchema });

  const {
    reset,
    setValue,
    handleSubmit,
    formState: { isSubmitting }
  } = formProps;

  const onCancel = () => {
    reset();
    setModalVisible();
  };

  const onSubmit = async (record: FormValues) => {
    if (selectedItem) {
      await update(get(selectedItem, '_id'), record as CommonUpdateArg);
    } else {
      await create(record as CommonInput);
    }
    onCancel();
  };

  const toggleInputModal = () => {
    setInputModal(!inputModal);
  };

  const addType = () => {
    setType([...types, text]);
    setText('');
    setValue('type', text);
    toggleInputModal();
  };
  const layout = {
    labelCol: { offset: 0 },
    wrapperCol: { offset: 0, span: 24 }
  };

  return (
    <Modal
      title={t('COMMON')}
      visible={modalVisible}
      cancelText={t('CLOSE')}
      cancelButtonProps={{ danger: true, ghost: true }}
      onCancel={() => onCancel()}
      okText={selectedItem ? 'Update' : 'Create'}
      okButtonProps={{
        htmlType: 'submit',
        form: 'commonForm',
        type: 'primary',
        loading: isSubmitting
      }}
    >
      <Form
        id="commonForm"
        onSubmit={handleSubmit(onSubmit)}
        layout='vertical'
      >
        <Row gutter={8}>
          <Col xs={24} md={12}>
            <UIField
              {...formProps}
              name="text"
              label={t('TEXT')}
              fieldProps={{
                placeholder: i18next.t('COMMON_DATA_PLACEHOLDER_TEXT')
              }}
              fieldComponent={Input}
              defaultValue={initValues.text}
            />
          </Col>
          <Col xs={24} md={12}>
            <UIField
              {...formProps}
              name="value"
              label={t('VALUE')}
              fieldProps={{
                placeholder: i18next.t('COMMON_DATA_PLACEHOLDER_VALUE'),
                disabled: !!selectedItem
              }}
              fieldComponent={Input}
              defaultValue={initValues.value}
            />
          </Col>
        </Row>

        <Row gutter={8}>
          <Col xs={24} md={12}>
            <UIField<SelectProps<ReactText>>
              {...formProps}
              name="type"
              label={t('TYPE')}
              fieldProps={{
                placeholder: i18next.t('COMMON_DATA_PLACEHOLDER_TYPE'),
                allowClear: true,
                dropdownRender: (menu) => (
                  <div>
                    {menu}
                    <Divider style={{ margin: '4px 0' }} />
                    <div
                      style={{ padding: '4px 8px', cursor: 'pointer' }}
                      onMouseDown={(e) => e.preventDefault()}
                      onClick={toggleInputModal}
                    >
                      <PlusOutlined /> {t('ADD_NEW_TYPE')}
                    </div>
                  </div>
                ),
                children: types.map((item, index) => (
                  <Select.Option key={String(index)} value={item}>
                    {item}
                  </Select.Option>
                ))
              }}
              fieldComponent={Select}
              defaultValue={initValues.type}
            />
          </Col>
          <Col xs={24} md={12}>
            <UIField
              {...formProps}
              name="icon"
              label="Icon"
              fieldProps={{
                placeholder: i18next.t('COMMON_DATA_PLACEHOLDER_ICON')
              }}
              fieldComponent={Input}
              defaultValue={initValues.icon}
            />
          </Col>
        </Row>
        <Form.Item label="Meta" {...layout}>
          <UIField
            {...formProps}
            name="meta"
            fieldProps={{
              placeholder: i18next.t('COMMON_DATA_PLACEHOLDER_META'),
              rows: 6
            }}
            fieldComponent={Input.TextArea}
            defaultValue={initValues.meta}
          />
        </Form.Item>
      </Form>
      <Modal
        title={t('NEW_TYPE')}
        width={400}
        centered
        visible={inputModal}
        closable={false}
        onCancel={toggleInputModal}
        cancelText={t('CLOSE')}
        cancelButtonProps={{ danger: true, ghost: true }}
        onOk={addType}
        okText="Add"
      >
        <Input
          value={text}
          placeholder={t('INPUT_TYPE')}
          onChange={(e) => setText(e.target.value)}
        />
      </Modal>
    </Modal>
  );
});

export default DeviceGroupForm;
