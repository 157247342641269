import { Button } from 'antd';
import { observer } from 'mobx-react';
import React, { FC, useEffect } from 'react';

import { useStore } from '~context/react-context';

import store from './store';
import Form from './TwoFactorAuthForm';

const TwoFactorAuth: FC = observer(() => {
  const {
    userStore: { currentUser }
  } = useStore();
  const { setIsShowTFAForm, setCurrentUser, isEnable } = store;

  useEffect(() => {
    if (!currentUser) return;
    setCurrentUser(currentUser);
  }, [currentUser]);

  if (!currentUser) return null;

  return (
    <div>
      <Form />
      <h1>Two-Factor Authentication</h1>
      <p>
        Two-Factor Authentication adds an extra layer of protection to your account. Whenever you
        log in, you'll need to enter both your password and also a security code from an app on your
        mobile phone.
      </p>
      <br />
      <Button
        type="primary"
        style={{
          marginTop: 15,
          backgroundColor: isEnable && '#8a8c8e',
          borderColor: isEnable && 'white'
        }}
        onClick={() => setIsShowTFAForm(true)}
      >
        {`${isEnable ? 'Disable' : 'Enable'} Two-Factor Authentication`}
      </Button>
    </div>
  );
});

export default TwoFactorAuth;
