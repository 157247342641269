import './reportCampaign.less';

import { Descriptions, Tag, Typography } from 'antd';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { FC } from 'react';

import { DATE_FORMAT, RUNNING, STOPPED } from '@source/common';

import { format } from '~utils/formatDate';
import { formatNumber } from '~utils/formatNumber';
import { useTranslation } from 'react-i18next';

import store from '../store';
import BannerInfo from './BannerInfo';

const { Link } = Typography;

const Info: FC = observer(() => {
  const { campaignInfo } = store;
  const { t } = useTranslation();

  const remainingDay = moment(campaignInfo.scheduleTo).diff(moment().startOf('day'), 'days');

  const generateStatusTag = (status: string) => {
    let color: string;
    switch (status) {
      case RUNNING:
        color = 'green';
        break;
      case STOPPED:
        color = 'error';
        break;
      default:
        color = 'default';
    }

    return <Tag color={color}>{status.toUpperCase()}</Tag>;
  };

  return (
    <>
      <Descriptions bordered column={2}>
        <Descriptions.Item span={1} label={t('CAMPAIGN_NAME')}>
          {campaignInfo?.name}
        </Descriptions.Item>
        <Descriptions.Item span={1} label={t('SCHEDULED')}>
          {format(campaignInfo?.scheduleFrom, DATE_FORMAT)} -{'>'}{' '}
          {campaignInfo.scheduleTo ? format(campaignInfo?.scheduleTo, DATE_FORMAT) : 'Unlimited'}
        </Descriptions.Item>
        <Descriptions.Item span={1} label={t('STATUS')}>
          {generateStatusTag(campaignInfo?.status)}
        </Descriptions.Item>
        {/* <Descriptions.Item span={1} label="Today (Clicks)">
          {formatNumber(campaignInfo?.todayClick)}
        </Descriptions.Item> */}
        <Descriptions.Item span={1} label={t('COMPLETED')}>
          {formatNumber(campaignInfo?.stats?.kpiCompleted)} %
        </Descriptions.Item>
        <Descriptions.Item span={1} label="KPI">
          {formatNumber(campaignInfo?.stats?.kpi)}
        </Descriptions.Item>
        <Descriptions.Item span={2} label={t('REMAINING_DAY')}>
          {remainingDay > 0 ? remainingDay : 0} days
        </Descriptions.Item>
        <Descriptions.Item span={1} label={t('KPI_COMPLETED')}>
          {formatNumber(campaignInfo?.stats?.completed)}
          {/* <span style={{ float: 'right' }}>{formatNumber(campaignInfo?.stats?.kpiCompleted)}</span> */}
        </Descriptions.Item>
        <Descriptions.Item span={1} label={t('LOCATION')}>
          {campaignInfo?.locations.length}
        </Descriptions.Item>
        <Descriptions.Item span={2} label={t('LANDING_PAGE')}>
          <Link href={campaignInfo?.config?.landingPageUrl} target="_blank">
            {campaignInfo?.config?.landingPageUrl}
          </Link>
        </Descriptions.Item>
        <Descriptions.Item span={2} label={t('BANNER_NAME')}>
          <BannerInfo />
        </Descriptions.Item>
      </Descriptions>
    </>
  );
});
export default Info;
