import '@ant-design/compatible/assets/index.css';

import { Button, Col, Form, Input, Modal, Row, Switch } from 'antd';
import { get, isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { LICENSE, MGN } from '@source/common';

import { UISelect } from '~components/UI/Select/UISelect';
import { UIInputNumber } from '~components/UI/UIInputNumber';
import UIUpload from '~components/UI/UIUploadClass';
import { useStore } from '~context/react-context';
import { SaleInfoInput } from '~graphql/_sdk';

import store from './store';

interface FormValues {
  name?: string;
  licenseName?: string;
  description?: string;
  monthlyClick?: number;
  bookingType?: string;
  pictureIds?: string[];
}

const { TextArea } = Input;

const SaleInfoForm: FC = observer(() => {
  const { t } = useTranslation();
  const {
    fileList,
    selectedItem,
    updateSaleInfo,
    modalSaleInfoVisible,
    setFileList,
    setModalVisible,
    pictureIds,
    previewVisible,
    handlePreview,
    previewImage,
    loading,
    onFieldChange,
  } = store;

  const {
    userStore: { currentUser }
  } = useStore();
  const [form] = Form.useForm();

  const nameField = currentUser.ownerType === MGN ? 'name' : 'licenseName';

  const initialValues: FormValues = {
    name: get(selectedItem, nameField),
    description: get(selectedItem, 'description'),
    monthlyClick: get(selectedItem, 'monthlyClick'),
    pictureIds: get(selectedItem, 'pictureIds', []),
    bookingType: get(selectedItem, 'bookingType', 'normal')
  };

  const onCancel = () => {
    setModalVisible(null, 'saleInfo');
    form.resetFields();
  };

  const footer = (
    <>
      <Button type="ghost" onClick={() => onCancel()}>
        {t('CLOSE')}
      </Button>
      <Button htmlType="submit" form="saleInfoForm" loading={loading} type="primary">
        {t('UPDATE')}
      </Button>
    </>
  );

  const onSubmit = async (record: FormValues) => {
    if (currentUser.ownerType === LICENSE) {
      record.licenseName = record.name;
      delete record.name;
    }
    const data = Object.assign(record, { pictureIds });
    if (selectedItem) {
      await updateSaleInfo(get(selectedItem, '_id'), data as SaleInfoInput);
      onCancel();
    }
  };

  const bookingType = [
    {
      value: 'normal',
      text: 'Normal'
    },
    {
      value: 'type1',
      text: 'Type 1'
    }
  ];

  const onChange = (field: string, value: any) => {
    onFieldChange({ [field]: value });
  };

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [selectedItem]);

  return (
    <Modal
      title={<strong>{initialValues.name || `Sale's Information`}</strong>}
      onCancel={() => onCancel()}
      closable={false}
      visible={modalSaleInfoVisible}
      footer={footer}
      width={800}
      style={{ top: 20 }}
      okText={t('Update')}
    >
      <Form
        id="saleInfoForm"
        initialValues={initialValues}
        layout="vertical"
        onFinish={onSubmit}
        form={form}
      >
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item name="monthlyClick" label={t('MONTHLY_CLICK')}>
              <UIInputNumber style={{ width: 200 }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="bookingType" label={t('location.BOOKING_TYPE')}>
              <UISelect
                onChange={v => onChange('bookingType', v)}
                placeholder={t('location.BOOKING_TYPE')}
                dataOptions={{
                  data: bookingType,
                  valueField: 'value',
                  textField: 'text'
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="description" label={t('DESCRIPTION')}>
              <TextArea rows={4} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="pictureIds"
              label={t('picture.NAME')}
              style={{ margin: 0, padding: 0 }}
            >
              <UIUpload
                crop={false}
                listType="picture-card"
                onPreview={e => handlePreview(e)}
                key={!isEmpty(initialValues.pictureIds) ? 'upload' : 'noData'}
                bannerId={selectedItem && selectedItem._id}
                multiple
                handleChange={setFileList}
                defaultFileList={fileList}
                name={undefined}
                accept="image/*"
              />
            </Form.Item>
            <Modal visible={previewVisible} footer={null} onCancel={handlePreview}>
              <img alt="img" style={{ width: '100%' }} src={previewImage} />
            </Modal>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
});

export default SaleInfoForm;
