import React from 'react';
import * as yup from 'yup';
import useForm from 'react-hook-form';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { QrcodeOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button } from 'antd';
import UIField from '~components/UI/UIField';
import { login_button } from './css';

import store from './store';
import { useStore } from '~context/react-context';

class FormValues {
  code: string;
}

const TfaSchema = yup.object().shape<FormValues>({
  code: yup.string().required(i18next.t('REQF'))
});

const defaultValues: FormValues = {
  code: ''
};

const TFAForm = () => {
  const formProps = useForm<FormValues>({ defaultValues, validationSchema: TfaSchema });
  const { t } = useTranslation();
  const {
    authStore: { loginTwoFactorAuth }
  } = useStore();
  const { storedPassword, userId, setUserId, setStoredPassword } = store;

  const {
    handleSubmit,
    formState: { isSubmitting }
  } = formProps;

  const onSubmit = async ({ code }: FormValues) => {
    const check = await loginTwoFactorAuth(code, userId, storedPassword);
    if (check) {
      setUserId(null);
      setStoredPassword('');
    }
  };

  return (
    <Form layout="vertical" onSubmit={handleSubmit(onSubmit)}>
      <UIField
        {...formProps}
        name="code"
        fieldProps={{
          name: 'code',
          placeholder: t('login:code'),
          prefix: <QrcodeOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
        }}
        fieldComponent={Input}
      />
      <Button htmlType="submit" type="primary" loading={isSubmitting} className={login_button}>
        Submit
      </Button>
      <span style={{ marginTop: 5 }}>
        Note: Enter the code received from your app (ex: Google Authenticator,... ){' '}
      </span>
    </Form>
  );
};

export default TFAForm;
