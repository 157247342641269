import React from 'react';
import { SortableContainer, SortableElement, SortEndHandler } from 'react-sortable-hoc';
import shortId from 'shortid';

interface SortableComponentProps {
  itemElement: React.ReactNode;
  items: any;
  elementProps: any;
  sortListFunction: SortEndHandler;
}
const SortableItem = SortableElement(({ value, Item, elementProps, elementIndex }) => {
  return <Item value={value} index={elementIndex} {...elementProps} />;
});

const SortableComponent = (props: SortableComponentProps) => {
  const { sortListFunction, items, itemElement, elementProps } = props;
  const SortableList = SortableContainer(({ i }) => {
    return (
      <div style={{ width: '100%' }}>
        {i.map((value, index) => {
          return (
            <SortableItem
              key={shortId.generate()}
              index={index}
              elementIndex={index}
              value={value}
              Item={itemElement}
              elementProps={elementProps}
            />
          );
        })}
      </div>
    );
  });

  return <SortableList i={items} onSortEnd={sortListFunction} useDragHandle />;
};

export default SortableComponent;
