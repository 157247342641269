import '@ant-design/compatible/assets/index.css';

import { Button, Col, Form, Input, InputNumber, Modal, Row, Select } from 'antd';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DATE_FORMAT, LICENSE, MGN } from '@source/common';

import { useStore } from '~context/react-context';

import store from './store';
import { UISelect } from '~components/UI/Select/UISelect';
import commonDataStore from '~common-stores/commonDataStore';
import { UIDatePicker } from '~components/UI/UIDatePicker';
import moment, { Moment } from 'moment';

interface FormValues {
  name?: string;
  licenseName?: string;
  type: string;
  bannerId: string;
  landingPageUrl?: string;
  scheduleFrom: string;
  scheduleTo?: string;
  kpi?: number;
  landingPage?: string;
  description?: string;
}

const validateMessages = {
  required: '${label} is required!'
};
const CampaignBasicForm: FC = observer(() => {
  const { t } = useTranslation();
  const [selectedBanner, setBanner] = useState([]);
  const {
    setModalCreateVisible,
    visibleCreate,
    newCampaign,
    loading,
    preview,
    createBasic,
    bookingRangeDate: { from, to },
    setDateRange
  } = store;

  const {
    userStore: { currentUser }
  } = useStore();
  const [form] = Form.useForm();

  const nameField = currentUser.ownerType === MGN ? 'name' : 'licenseName';

  const initialValues: FormValues = {
    name: get(newCampaign, nameField),
    type: get(newCampaign, 'type'),
    bannerId: get(newCampaign, 'bannerId'),
    scheduleFrom: get(newCampaign, 'scheduleFrom'),
    scheduleTo: get(newCampaign, 'scheduleTo'),
    kpi: get(newCampaign, 'kpi', 0),
    landingPage: get(newCampaign, 'landingPage'),
    description: get(newCampaign, 'description')
  };

  useEffect(() => {
    commonDataStore.loadAllBanners();
    commonDataStore.loadFullDataAllLocations();
    form.setFieldsValue(initialValues);
  }, [newCampaign]);

  const onSubmit = async (record: FormValues) => {
    await createBasic(record, commonDataStore.fullLocations);
    setModalCreateVisible();
  };

  const onCancel = () => {
    form.resetFields();
    setModalCreateVisible();
  };

  const handleViewBanner = () => {
    preview(selectedBanner);
  };

  const disabledDateFrom = (current: Moment) => {
    return current && current < moment().subtract(1, 'days').endOf('day');
  };

  const disabledDateTo = (current: Moment) => {
    return current && current < moment(from).subtract(1, 'days').endOf('day');
  };

  const handlePickDate = async (date: Moment, type: string) => {
    setDateRange(date, type);
  };

  const footer = (
    <>
      <Button type="ghost" onClick={() => onCancel()}>
        {t('CLOSE')}
      </Button>
      <Button htmlType="submit" form="basicForm" loading={loading} type="primary">
        {t('CREATE')}
      </Button>
    </>
  );

  return (
    <Modal
      title={t('CAMPAIGN_BASIC')}
      onCancel={() => onCancel()}
      closable
      visible={visibleCreate}
      footer={footer}
      width={800}
      style={{ top: 20 }}
      okText={t('CREATE')}
      forceRender
    >
      <p>- Quảng cáo cơ bản sẽ cấu hình cho tất cả điểm phát mà bạn sỡ hữu</p>
      <p>- Quảng cáo tạo trước sẽ được ưu tiên chạy trước</p>
      <Form
        id="basicForm"
        initialValues={initialValues}
        validateMessages={validateMessages}
        onFinish={onSubmit}
        layout="vertical"
        form={form}
      >
        <Row gutter={32}>
          <Col span={12}>
            <Form.Item name="name" label={t('NAME')} rules={[{ required: true }]}>
              <Input placeholder={t('CHOOSE_NAME')} allowClear />
            </Form.Item>
          </Col>
          {/* <Col span={12}>
            <Form.Item name="type" label={t('TYPE_CAMPAIGN')} rules={[{ required: true }]}>
              <UISelect
                placeholder={t('COMMON_DATA_PLACEHOLDER_TYPE')}
                dataOptions={{
                  data: commonDataStore.campaignType,
                  valueField: 'value',
                  textField: 'text'
                }}
                // onChange={(value) => onChange(value, ['type'])}
                // value={initialValues.type}
              />
            </Form.Item>
          </Col> */}
          <Col span={12}>
            <Form.Item
              name="bannerId"
              label={t('BANNER')}
              rules={[{ required: true }]}
              style={{ marginBottom: 0 }}
            >
              <UISelect
                placeholder={t('BANNER')}
                dataOptions={{
                  data: commonDataStore.banners,
                  valueField: '_id',
                  textField: 'name'
                }}
                onChange={(e) => setBanner(e)}
              />
            </Form.Item>
            <a onClick={() => handleViewBanner()}>
              {t('VIEW_BANNER')}
            </a>
          </Col>
          <Col span={12}>
            <Form.Item name="landingPageUrl" label={t('LANDING_PAGE')} rules={[{ required: true }]}>
              <Input placeholder={t('LANDING_PAGE')} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="kpi" label={t('KPI')} style={{ marginBottom: 0 }}>
              <InputNumber placeholder={t('KPI')} min={0} />
            </Form.Item>
            <p>- 0: không giới hạn KPI</p>
          </Col>
          <Col span={12}>
            <Form.Item name="scheduleFrom" label={t('START_DATE')} rules={[{ required: true }]}>
              <UIDatePicker
                style={{ width: '100%' }}
                placeholder={t('START_DATE')}
                onChange={(date) => {
                  handlePickDate(date, 'from');
                  setDateRange(undefined, 'to');
                }}
                allowClear={false}
                // value={from}
                disabledDate={disabledDateFrom}
                format={DATE_FORMAT}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="scheduleTo" label={t('END_DATE')}>
              <UIDatePicker
                style={{ width: '100%' }}
                placeholder={t('END_DATE')}
                onChange={(date) => {
                  handlePickDate(date, 'from');
                  setDateRange(undefined, 'to');
                }}
                allowClear={false}
                // value={from}
                disabledDate={disabledDateTo}
                format={DATE_FORMAT}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
});

export default CampaignBasicForm;
