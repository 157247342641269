import { UploadFile } from 'antd/lib/upload/interface';
import i18next from 'i18next';
import { debounce, get, groupBy, isEmpty, merge, throttle } from 'lodash';
import { action, computed, observable, reaction, runInAction } from 'mobx';
import { actionAsync, task } from 'mobx-utils';

import { ColDef, GridReadyEvent } from '@ag-grid-community/core';
import { COMING_SOON, DISABLED, FilterSelect, LICENSE, LocationStatus, MGN, WhereArgs } from '@source/common';

import { ErrorNotify, SuccessNotify } from '~components/UI/Notification';
import { getConfig } from '~config/index';
import {
  AdvanceLocationUpdateArgs,
  Common,
  FullLocationFragment,
  FullRegionFragment,
  GetRenderCodeQueryVariables,
  Location,
  LocationBasicInput,
  SaleInfoInput,
  SystemInfoInput
} from '~graphql/_sdk';
import { useGraphQLSDK } from '~graphql/GraphQLSDKProvider';
import { getSdk } from '~graphql/sdk';
import { removeSign } from '~utils/removeSign';

class GridStore {
  @observable public loading: boolean;
  @observable public modalAdvanceVisible: boolean;
  @observable public modalBasicVisible: boolean;
  @observable public modalSaleInfoVisible: boolean;
  @observable public modalSystemInfoVisible: boolean;
  @observable public drawerVisible: boolean;
  @observable public selectedItem: any;
  @observable public where: WhereArgs;
  @observable public initFilterConfig: FilterSelect[];
  @observable public generatorFormVisible: boolean;
  @observable public gmapVisible: boolean;
  @observable public selectedHTML: string;
  @observable public locationData: Location[];
  @observable public categoryData: Common[];
  @observable public cityData: Common[];
  @observable public ownerType: string;
  @observable public cityLoaded: boolean | undefined;
  @observable public districtData: { text: string; value: string }[];
  @observable public selectedItemStatistic: any[];
  @observable public exportDataLocation: any;
  @observable public cachedFields: { [fields: string]: any };
  @observable public dataloading: boolean;
  @observable public dataOneCampaign: any;
  @observable public loadingGenHTML: boolean;
  @observable public renderCode: string;
  @observable public available: boolean;
  @observable public pictureIds: string[];
  @observable public pictures: File[];
  @observable public previewVisible: boolean;
  @observable public previewImage: string;
  @observable public cityDataMapping: { [field: string]: FullRegionFragment };
  @observable public districtDataMapping: { [field: string]: FullRegionFragment };
  @observable public mappingCityDistrict: { [field: string]: FullRegionFragment[] };
  @observable public filterCategoryId: WhereArgs;
  @observable public filterCity: string;
  @observable public filterDistrict: string;
  @observable public searchWhere: any;
  @observable public filterStatus: string;
  @observable public filterCreatedAt: any;
  @observable public filterActiveDay: any;
  @observable public uploadImage: string;
  @observable public uploadVisible: boolean;
  @observable checked: boolean;
  @observable public urlLogo: string;
  public fileLists: UploadFile<any>[];
  @observable temp: any;
  public fileList: UploadFile<any>[];
  public colDefs: ColDef[];
  private isLicense: boolean;

  constructor() {
    this.reset();
    reaction(
      () => this.selectedItem,
      (selectedItem) => {
        let fileListTmp = [];
        if (selectedItem && !isEmpty(selectedItem.pictures)) {
          for (let i = 0; i < selectedItem?.pictureIds?.length; i += 1) {
            const obj = {
              uid: selectedItem.pictures[i].id,
              name: selectedItem.pictures[i].id.split('/')[1],
              type: '',
              url: selectedItem.pictures[i].path
            };
            fileListTmp = [...fileListTmp, obj];
          }
          this.setFileList(fileListTmp);
          return;
        }
        this.setFileList([]);
      }
    );
    reaction(
      () => this.selectedItem,
      (selectedItem) => {
        let fileListTmp = [];
        if (selectedItem?.urlLogo) {
          const obj = {
            uid: selectedItem?.logos?.id,
            name: selectedItem?.logos?.id.split('/')[1],
            type: '',
            url: selectedItem?.logos?.path
          };
          fileListTmp = [...fileListTmp, obj];
        }
        this.setFileLists(fileListTmp);
      }
    );
  }

  @action public reset = () => {
    runInAction(() => {
      this.previewVisible = false;
      this.categoryData = [];
      this.cityData = [];
      this.districtData = [];
      this.locationData = [];
      this.selectedItem = null;
      this.loading = false;
      this.drawerVisible = false;
      this.generatorFormVisible = false;
      this.gmapVisible = false;
      this.selectedHTML = '';
      this.ownerType = null;
      this.initFilterConfig = [];
      this.cityLoaded = false;
      this.selectedItemStatistic = [];
      this.exportDataLocation = {};
      this.cachedFields = null;
      this.dataOneCampaign = {};
      this.dataloading = true;
      this.colDefs = null;
      this.isLicense = false;
      this.loadingGenHTML = false;
      this.cityDataMapping = {};
      this.districtDataMapping = {};
      this.filterCategoryId = {};
      this.filterCity = null;
      this.filterDistrict = null;
      this.searchWhere = {};
      this.filterStatus = LocationStatus.ACTIVE;
      this.filterCreatedAt = {};
      this.filterActiveDay = {};
      this.temp = {};
    });
  };

  @action
  setFileLists = (fileList) => {
    this.fileLists = fileList;
    this.urlLogo = fileList[0]?.uid || '';
  };

  @action
  public handleUpload = (e) => {
    this.uploadImage = e.url;
    this.uploadVisible = !this.uploadVisible;
  };

  @computed
  get whereFilter() {
    const where: WhereArgs = {
      _operators: {
        ...this.filterCategoryId,
        ...this.filterCreatedAt,
        ...this.filterActiveDay
      },
      ...this.searchWhere,
      city: this.filterCity,
      district: this.filterDistrict,
      ownerType: this.ownerType,
      status: this.filterStatus
    };
    this.reload();
    return where;
  }

  @action
  public setFilter = throttle(
    (where: WhereArgs) => {
      if (this.where?._search) {
        runInAction(() => {
          this.where = { ...where, _search: this.where._search };
        });
      } else {
        runInAction(() => {
          this.where = where;
        });
      }
      this.reload();
    },
    300,
    { leading: false, trailing: true }
  );
  @action setFilterCreatedAt = (value: WhereArgs) => {
    this.filterCreatedAt = value;
  };

  @action setFilterActiveDay = (value) => {
    this.filterActiveDay = value;
  };

  @action
  setFilterCategoryId = (where: WhereArgs) => {
    this.filterCategoryId = where;
  };
  @action
  setFilterCity = (value) => {
    this.filterCity = value;
  };
  @action
  setFilterDistrict = (value) => {
    this.filterDistrict = value;
  };
  @action
  setFilterStatus = (value) => {
    this.filterStatus = value;
  };

  @action
  public handlePreview = (e) => {
    this.previewImage = e.url;
    this.previewVisible = !this.previewVisible;
  };

  @action
  public onFieldChange = (obj) => {
    this.setCheck(get(obj, 'available', false));
    merge(this.cachedFields, obj);
  };
  @action
  public updateFieldBasic = (input) => {
    Object.assign(this.temp, input);
  };

  @actionAsync
  setFileList = async (fileList) => {
    this.fileList = fileList;
    let fileListTmp = [];
    for (let i = 0; i < this.fileList?.length; i += 1) {
      const obj = {
        id: fileList[i].uid,
        path: fileList[i].url
      };

      fileListTmp = [...fileListTmp, obj];
    }
    this.pictureIds = fileListTmp.map((i) => i.id);
  };

  @actionAsync
  onRemoveFile = async (fileList) => {
    const sdk = useGraphQLSDK();
    const {
      removeFileById: { error }
    } = await sdk.removeFileById({ id: fileList.uid });

    if (error) {
      ErrorNotify(error.title);
    } else {
      const index = this.fileLists.findIndex((f) => f.uid === fileList.uid);
      this.setFileList([
        ...this.fileLists.slice(0, index),
        ...this.fileLists.slice(index + 1, this.fileLists.length)
      ]);
    }
  };

  @action
  public setIsLicense = (value: boolean) => {
    this.isLicense = value;
  };

  @action
  public initColDef = (colDef) => {
    this.colDefs = colDef;
  };

  @action
  public setCheck = (check: boolean) => {
    this.available = check;
  };

  @action public loadDistrictData = (value: any) => {
    this.districtData = this.mappingCityDistrict[value]?.map((item) => {
      return {
        value: item.value,
        text: item.text
      };
    });
    this.cityLoaded = true;
  };

  @action
  public setChecked = (e) => {
    this.checked = e;
  };

  @action
  setModalVisible = (item: FullLocationFragment = null, modalType: string) => {
    this.setCheck(get(item, 'available', false));

    switch (modalType) {
      case 'basic':
        this.modalBasicVisible = !this.modalBasicVisible;
        if (this.modalBasicVisible) {
          if (item) {
            this.setChecked(item.status === COMING_SOON);
            this.getBasicFormData(item._id);
          }
        } else {
          this.selectedItem = item;
          this.setChecked(false);
        }
        break;
      case 'advanced-info':
        this.modalAdvanceVisible = !this.modalAdvanceVisible;
        if (this.modalAdvanceVisible) {
          if (item) {
            this.getAdvanceFormData(item._id);
          }
        } else {
          this.selectedItem = item;
        }
        break;
      case 'saleInfo':
        this.modalSaleInfoVisible = !this.modalSaleInfoVisible;
        if (this.modalSaleInfoVisible) {
          if (item) {
            this.getSaleInfoData(item._id);
          }
        } else {
          this.selectedItem = item;
        }
        break;
      case 'systemInfo':
        this.modalSystemInfoVisible = !this.modalSystemInfoVisible;
        if (this.modalSystemInfoVisible) {
          if (item) {
            this.getSystemInfoData(item._id);
          }
        } else {
          this.selectedItem = item;
        }
        break;

      default:
        break;
    }
  };

  @action
  public async previewBanner(bannerId: string, redirect = 'https://google.com') {
    const { restApiEndpoint } = await getConfig();
    const params = [`bannerId=${bannerId}`, redirect && `redirect=${encodeURIComponent(redirect)}`]
      .filter(Boolean)
      .join('&');
    window.open(`${restApiEndpoint}/renderer/preview?${params}`, '_blank');
  }

  @action
  public async preview(data: { _id: string; name?: string; campaignRunning?: [any] }) {
    const { restApiEndpoint } = await getConfig();
    const redirect = data.campaignRunning.filter((i) => i.isActive)[0]?.landingPage;
    const params = [
      data.name && `name=${removeSign(data.name)}`,
      `locationId=${data._id}`,
      `redirect=${encodeURIComponent(redirect)}`
    ]
      .filter(Boolean)
      .join('&');
    window.open(`${restApiEndpoint}/renderer/preview?${params}`, '_blank');
  }

  @actionAsync
  public getAdvanceFormData = async (_id) => {
    const sdk = await task(getSdk());
    const { findLocationById } = await task(sdk.fetchAdvanceFormData({ _id }));
    this.selectedItem = findLocationById;
    this.cachedFields = {
      unitPrice: get(this.selectedItem, 'shareType.unitPrice'),
      owner: get(this.selectedItem, 'shareType.owner'),
      shareType: get(this.selectedItem, 'share'),
      status: get(this.selectedItem, 'status', 'disabled'),
      monthlyClick: get(this.selectedItem, 'stats.monthlyClick', 3000),
      activeDay: get(this.selectedItem, 'activeDay', new Date()),
      configAuth: get(this.selectedItem, 'configAuth'),
      queue: get(this.selectedItem, 'extra.booking', 0),
      available: get(this.selectedItem, 'available', false)
    };
  };

  @actionAsync
  public getBasicFormData = async (_id) => {
    const sdk = await task(getSdk());
    const { findLocationById } = await task(sdk.fetchBasicFormData({ _id }));
    this.selectedItem = findLocationById;
  };

  @actionAsync
  public getSaleInfoData = async (_id) => {
    const sdk = await task(getSdk());
    const { findLocationById } = await task(sdk.fetchSaleInfo({ _id }));
    this.selectedItem = findLocationById;
  };

  @actionAsync
  public getSystemInfoData = async (_id) => {
    const sdk = await task(getSdk());
    const { findLocationById } = await task(sdk.fetchSystemInfo({ _id }));
    this.selectedItem = findLocationById;
  };

  @actionAsync
  public getDetailInfoData = async (_id) => {
    const sdk = await task(getSdk());
    const { findLocationById } = await task(sdk.fetchDetailInfo({ _id }));
    this.selectedItem = findLocationById;
  };

  @actionAsync
  setDrawerVisible = async (item: FullLocationFragment = null) => {
    this.selectedItem = item;
    this.drawerVisible = !this.drawerVisible;
    // if (this.drawerVisible) {
    //   this.getDetailInfoData(item._id);
    // }
  };

  @action
  setGeneratorVisible = (item: FullLocationFragment = null) => {
    this.selectedItem = item;
    this.generatorFormVisible = !this.generatorFormVisible;
    if (!this.generatorFormVisible) {
      this.renderCode = null;
    }
  };

  @action
  setGmapVisible = (item: FullLocationFragment = null) => {
    this.selectedItem = item;
    this.gmapVisible = !this.gmapVisible;
  };

  @action
  setSelectedHTML = (html: string) => {
    this.selectedHTML = html;
  };

  @action
  setCityData = (data: FullRegionFragment[]) => {
    this.cityDataMapping = data.reduce((all, item) => {
      all[item.value] = item;
      return all;
    }, {});
  };

  @action
  setDistrictData = (data: FullRegionFragment[]) => {
    this.districtDataMapping = data.reduce((all, item) => {
      all[item.value] = item;
      return all;
    }, {});

    this.mappingCityDistrict = groupBy(data, 'city');
  };

  @action
  public searchFilter = debounce(
    (text: string) => {
      runInAction(() => {
        this.searchWhere = { _search: text };
      });
    },
    500,
    { leading: false, trailing: true }
  );

  @action
  public resetFilter = () => {
    this.where = {};
    this.setIsLicense(false);
  };

  @actionAsync
  public createBasic = async (record: LocationBasicInput) => {
    this.loading = true;
    const sdk = await task(getSdk());
    const {
      createBasicLocation: { error }
    } = await task(sdk.createBasicLocation({ record }));
    this.loading = false;
    if (error) {
      ErrorNotify(error.code);
    } else {
      SuccessNotify(i18next.t('CREATED'));
      this.refresh();
    }
  };

  @actionAsync
  updateSaleInfo = async (_id: string, record: SaleInfoInput) => {
    this.loading = true;
    const sdk = await task(getSdk());
    const {
      updateSaleInfo: { error }
    } = await task(sdk.updateSaleInfo({ _id, record }));
    this.loading = false;
    if (error) {
      ErrorNotify(error.code);
    } else {
      SuccessNotify(i18next.t('UPDATED'));
      this.refresh();
    }
  };

  @actionAsync
  updateSystemInfo = async (_id: string, record: SystemInfoInput) => {
    const sdk = await task(getSdk());
    const {
      updateSystemInfo: { error }
    } = await task(sdk.updateSystemInfo({ _id, record }));
    if (error) {
      ErrorNotify(error.code);
    } else {
      SuccessNotify(i18next.t('UPDATED'));
      this.refresh();
    }
  };

  @actionAsync
  updateBasic = async (_id: string, record: LocationBasicInput) => {
    this.loading = true;
    const sdk = await task(getSdk());
    const {
      updateBasicLocation: { error }
    } = await task(sdk.updateBasicLocation({ _id, record }));
    this.loading = false;
    if (error) {
      ErrorNotify(error.code);
    } else {
      SuccessNotify(i18next.t('UPDATED'));
      this.refresh();
    }
  };

  @actionAsync
  updateAdvance = async (_id: string, record: AdvanceLocationUpdateArgs) => {
    this.loading = true;
    const sdk = await task(getSdk());
    const {
      updateAdvanceLocation: { error }
    } = await task(sdk.updateAdvanceLocation({ _id, record }));
    this.loading = false;
    if (error) {
      ErrorNotify(error.code);
    } else {
      SuccessNotify(i18next.t('UPDATED'));
      this.refresh();
    }
  };

  @actionAsync
  remove = async (_id: string) => {
    const sdk = await task(getSdk());
    const {
      removeLocationById: { error }
    } = await task(
      sdk.removeLocationById({
        _id
      })
    );

    if (error) {
      ErrorNotify(error.code);
    } else {
      SuccessNotify(i18next.t('Remove'));
      this.refresh();
    }
  };

  @actionAsync
  updateStatus = async (_id: string, status: string) => {
    const sdk = await task(getSdk());
    const {
      updateLocationById: { error }
    } = await task(
      sdk.updateLocationById({
        _id,
        record: { status }
      })
    );

    if (error) {
      ErrorNotify(error.code);
    } else {
      // eslint-disable-next-line no-unused-expressions
      status === DISABLED
        ? SuccessNotify(i18next.t('DISABLED'))
        : SuccessNotify(i18next.t('ACTIVE'));
      this.refresh();
    }
  };

  @action
  public handleGridReady = ({ api, columnApi }: GridReadyEvent) => {
    this.reload = () => {
      api.onFilterChanged();
    };
    this.refresh = () => {
      api.onSortChanged();
    };
    this.setColDefs = () => {
      if (this.ownerType === MGN) {
        columnApi.setColumnVisible('licenseName', false);
        api.sizeColumnsToFit();
      }
      if (this.ownerType === LICENSE) {
        columnApi.setColumnVisible('licenseName', true);
        if (this.isLicense) {
          columnApi.setColumnVisible('name', false);
        }
        api.sizeColumnsToFit();
      }
    };
  };

  @action
  setWhere = (where: WhereArgs) => {
    this.where = { ...this.where, ...where };
  };

  @action
  public setOwnerType = (ownerType: string) => {
    this.ownerType = ownerType;
    this.setColDefs();
    this.setWhere({ ownerType });
    this.reload();
  };

  @actionAsync
  getRenderCode = async (params: GetRenderCodeQueryVariables) => {
    this.loadingGenHTML = true;
    const sdk = await task(getSdk());
    const { getRenderCode } = await task(sdk.getRenderCode(params));
    this.renderCode = JSON.parse(getRenderCode);
    this.loadingGenHTML = false;
  };

  @actionAsync
  updateCampaignDefault = async () => {
    const sdk = await task(getSdk());
    const { findManyCampaign } = await task(
      sdk.findManyCampaign({ where: { defaultForClient: true } })
    );
    if (findManyCampaign.length) {
      await task(sdk.approveCampaign({ _id: findManyCampaign[0]._id, allowed: true }));
    }
  };

  private setColDefs = () => undefined;

  private refresh = () => undefined;
  @action
  public reload = () => undefined;
}

export default new GridStore();
