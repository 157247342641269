/* eslint-disable react-hooks/exhaustive-deps */
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

import { useStore } from '~context/react-context';

import Drawer from './Drawer';
import Form from './Form';
import Grid from './Grid';
import Header from './Header';
import store from './store';

const User = observer(() => {
  const { setCurrentUser, reset } = store;
  const {
    userStore: { currentUser }
  } = useStore();
  useEffect(() => {
    return () => reset();
  }, []);
  setCurrentUser(currentUser);
  return (
    <>
      <Header />
      <Grid />
      <Form />
      <Drawer />
    </>
  );
});

export default User;
