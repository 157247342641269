import '@ant-design/compatible/assets/index.css';

import { Button, Col, Descriptions, Input, Modal, Row } from 'antd';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Form } from '@ant-design/compatible';

import { DATE_FORMAT, format } from '~utils/formatDate';

import store from './store';

const { Item } = Form;

interface Props {
  visible: boolean;
  setModalVisible: () => void;
}

const DisableRender: FC<Props> = observer(({ visible, setModalVisible }) => {
  const { selectedItem, disable } = store;
  const { t } = useTranslation();
  const [value, setValue] = useState();

  const handleChange = e => {
    setValue(e.target.value);
  };

  const handlePauseOrStopForever = async () => {
    await disable(selectedItem._id, Number(value));
  };

  const footer = [
    <Button key="close" onClick={() => setModalVisible()}>
      {t('CLOSE')}
    </Button>,
    <Button key="stop" danger onClick={handlePauseOrStopForever}>
      {t('STOP_FOREVER')}
    </Button>
  ];

  let schedule = '';
  const scheduleFrom = `From ${format(get(selectedItem, 'scheduleFrom'), DATE_FORMAT)}`;
  const scheduleTo = ` to ${format(get(selectedItem, 'scheduleTo'), DATE_FORMAT)}`;
  if (scheduleTo) {
    schedule = scheduleFrom + scheduleTo;
  } else {
    schedule = scheduleFrom;
  }

  let kpi = '';
  if (!get(selectedItem, 'kpi')) {
    kpi = 'Unlimited';
  } else {
    kpi = String(get(selectedItem, 'kpi'));
  }

  return (
    <Modal title={t('DISABLE_CAMPAIGN')} closable footer={footer} visible={visible}>
      <Form>
        <Descriptions bordered size="small" column={3}>
          <Descriptions.Item label={t('NAME')} span={3}>
            {get(selectedItem, 'name', '')}
          </Descriptions.Item>

          <Descriptions.Item label={t('SCHEDULE')} span={3}>
            {schedule}
          </Descriptions.Item>

          <Descriptions.Item label={t('KPI')} span={2}>
            {kpi}
          </Descriptions.Item>

          <Descriptions.Item label={t('COMPLETED')} span={1}>
            {`${get(selectedItem, 'stats.kpiCompleted', 0)}%`}
          </Descriptions.Item>

          <Descriptions.Item label={t('IMPRESSION')} span={1}>
            {get(selectedItem, 'stats.impression', 0)}
          </Descriptions.Item>

          <Descriptions.Item label={t('CLICK')} span={1}>
            {get(selectedItem, 'stats.click', 0)}
          </Descriptions.Item>

          <Descriptions.Item label={t('CTR')} span={1}>
            {get(selectedItem, 'stats.ctr', 0)}%
          </Descriptions.Item>
        </Descriptions>

        <Item label={t('INPUT_TIME')}>
          <Row gutter={8}>
            <Col xs={24} md={18}>
              <Input onChange={handleChange} placeholder={`${t('INPUT_HOUR')} | 1 2 3 4 5 ...`} />
            </Col>

            <Col xs={24} md={6}>
              <Button onClick={handlePauseOrStopForever} style={{ width: '100%' }}>
                {t('PAUSE')}
              </Button>
            </Col>
          </Row>
        </Item>
      </Form>
    </Modal>
  );
});

export default DisableRender;
