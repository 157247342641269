import { Col, Row } from 'antd';
import { observer } from 'mobx-react';
import React, { FC, useEffect } from 'react';

import { commonDataStore } from '~common-stores/store-index';

import AdvanceForm from './AdvanceForm';
import BasicForm from './BasicForm';
import Drawers from './Drawer';
import Grid from './Grid';
import Header from './Header';
import GenerateForm from './HTMLGenerateForm';
import SaleInfoForm from './SaleInfoForm';
import store from './store';
import SystemInfoForm from './SystemInfoForm';

const LocationView: FC = observer(() => {
  const { commonCity, commonDistrict } = commonDataStore;

  const { setDrawerVisible, resetFilter, setIsLicense, setCityData, setDistrictData } = store;

  useEffect(() => {
    if (commonCity && commonDistrict) {
      setCityData(commonCity);
      setDistrictData(commonDistrict);
    }
  }, [commonCity, commonDistrict]);

  useEffect(() => {
    commonDataStore.loadCommonCity();
    commonDataStore.loadLocationCategory();
    commonDataStore.loadOwnedLocationCategory();
    commonDataStore.loadCommonDistrict();
    commonDataStore.loadCommonPartnerOwner();
    commonDataStore.loadCommonBrandAp();
    commonDataStore.loadGroupType();
    commonDataStore.loadCommonTags();

    return () => {
      store.reset();
      resetFilter();
    };
  }, []);
  return (
    <Row style={{ height: '100%', flexFlow: 'column nowrap' }}>
      <Col flex="0 0 auto">
        <Header />
      </Col>
      <Col flex="1 1 auto">
        <Grid />
      </Col>
      <BasicForm />
      <AdvanceForm />
      <Drawers onClose={() => setDrawerVisible()} />
      <GenerateForm />
      <SaleInfoForm />
      <SystemInfoForm />
    </Row>
  );
});

export default LocationView;
