import { css } from 'emotion';
import React, { FC } from 'react';

const header = css`
  font-size: 16px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
  line-height: 24px;
  display: block;
  margin-bottom: 16px;
`;

interface Props {
  title: string;
}

const Header: FC<Props> = ({ title }) => <p className={header}>{title}</p>;

export default Header;
