import { Button, Result } from 'antd';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

/* eslint-disable react/jsx-key */
import { SmileTwoTone } from '@ant-design/icons';

import useQuery from '~utils/useQuery';

import store from './store';

const Email: React.FC = observer(() => {
  const { verifyEmail, isVerifiedEmail } = store;
  const query = useQuery();

  const key = query.get('key');

  useEffect(() => {
    verifyEmail(key);
  }, [key, verifyEmail]);

  return (
    <div style={{ margin: 'auto', textAlign: 'center' }}>
      {isVerifiedEmail ? (
        <Result
          status="success"
          title="Bạn đã kích hoạt thành công!"
          extra={[<Link to="/login">Quay trở về trang chủ</Link>]}
        />
      ) : (
        <Result
          icon={<SmileTwoTone />}
          title="Kích hoạt không thành công, vui lòng thử lại!"
          extra={[<Link to="/login">Quay trở về trang chủ</Link>]}
        />
      )}
    </div>
  );
});

export default Email;
