import { findLocation } from '~models/plugins/findLocation';
import { action, computed, observable } from 'mobx';
import { actionAsync, task } from 'mobx-utils';

import { mapReduce } from '@source/common';

import {
  BasicCustomerFragment,
  Common,
  CommonBannerFragment,
  CommonCampaignFragment,
  CommonEquipmentFragment,
  CommonLocationFragment,
  CustomerFilter,
  FullBalanceHistoryFragment,
  FullCampaignFragment,
  FullLocationFragment,
  FullRegionFragment,
  FullUserFragment,
  Role,
  SortDirection
} from '~graphql/_sdk';
import { getSdk } from '~graphql/sdk';

class CommonDataStore {
  @observable public loading: boolean;
  @observable.ref public users: FullUserFragment[];
  @observable.ref public banners: CommonBannerFragment[];
  @observable.ref public equipments: CommonEquipmentFragment[];
  @observable.ref public ownerType: Common[];
  @observable.ref public locations: CommonLocationFragment[];
  @observable.ref public fullLocations: FullLocationFragment[];
  @observable.ref public campaigns: CommonCampaignFragment[];
  @observable.ref public campaignRunning: CommonCampaignFragment[];
  @observable.ref public bannerType: Common[];
  @observable.ref public bannerTemplateType: Common[];
  @observable.ref public equipmentType: Common[];
  @observable.ref public locationCampaigns: FullCampaignFragment[];
  @observable.ref public campaignTypeBrand: Common[];
  @observable.ref public campaignType: Common[];
  @observable.ref public balanceHistory: FullBalanceHistoryFragment[];
  @observable.ref public brandAp: Common[];
  @observable.ref public groupType: Common[];
  @observable.ref public categories: Common[];
  @observable.ref public locationCategories: Common[];
  @observable.ref public commonCity: FullRegionFragment[];
  @observable.ref public commonDistrict: FullRegionFragment[];
  @observable.ref public commonArea: FullRegionFragment[];
  @observable.ref public tagsType: Common[];
  @observable.ref public roles: Role[];
  @observable.ref public customers: BasicCustomerFragment[];

  constructor() {
    this.init();
  }

  @action
  public init() {
    this.roles = [];
    this.loading = true;
    this.bannerType = [];
    this.bannerTemplateType = [];
    this.equipmentType = [];
    this.categories = [];
    this.locationCategories = [];
    this.customers = [];
    this.locations = [];
    this.fullLocations = [];
    this.banners = [];
    this.equipments = [];
    this.commonCity = [];
    this.commonDistrict = [];
    this.ownerType = [];
    this.brandAp = [];
    this.users = [];
    this.campaigns = [];
    this.locationCampaigns = [];
    this.campaignTypeBrand = [];
    this.campaignType = [];
    this.balanceHistory = [];
    this.groupType = [];
    this.tagsType = [];
    this.campaignRunning = [];
    this.commonArea = [];
  }

  @computed
  public get locationCategoryMap() {
    return mapReduce(this.locationCategories, 'value', item => item.icon);
  }
  @computed
  public get locationCategoryGroup() {
    const groupMap = mapReduce(this.groupType, 'value', item => item.text);
    return mapReduce(this.locationCategories, 'value', item => groupMap[item.meta.group]);
  }

  @actionAsync
  public fetchAllRoles = async () => {
    if (!this.roles.length) {
      const sdk = await task(getSdk());
      const { findManyRole } = await task(sdk.findManyRole());
      this.roles = findManyRole;
    }
    this.setLoading();
  };

  @actionAsync
  public loadBalanceHistory = async () => {
    if (!this.balanceHistory.length) {
      const sdk = await task(getSdk());
      const data = await task(sdk.findManyBalanceHistory({ where: {} }));
      this.balanceHistory = data.findManyBalanceHistory;
    }
    this.setLoading();
  };

  @actionAsync
  public loadCommonPartnerOwner = async () => {
    if (!this.ownerType.length) {
      const sdk = await task(getSdk());
      const data = await task(sdk.findManyCommon({ where: { type: 'partner_owner' } }));
      this.ownerType = data.findManyCommon;
    }
    this.setLoading();
  };

  @actionAsync
  public loadCommonBrandAp = async () => {
    if (!this.brandAp.length) {
      const sdk = await task(getSdk());
      const data = await task(sdk.findManyCommon({ where: { type: 'brand_ap' } }));
      this.brandAp = data.findManyCommon;
    }
  };
  // load Common tags
  @actionAsync
  public loadCommonTags = async () => {
    if (!this.tagsType.length) {
      const sdk = await task(getSdk());
      const data = await task(sdk.findManyCommon({ where: { type: 'tags' } }));
      this.tagsType = data.findManyCommon;
    }
  };

  @actionAsync
  public loadCommonCity = async () => {
    if (!this.commonCity.length) {
      const sdk = await task(getSdk());
      const data = await task(sdk.findCity());
      this.commonCity = data.findCity;
    }
    this.setLoading();
  };

  @actionAsync
  public loadCommonDistrict = async () => {
    if (!this.commonDistrict.length) {
      const sdk = await task(getSdk());
      const data = await task(sdk.findDistrict());
      this.commonDistrict = data.findDistrict;
    }
    this.setLoading();
  };

  @actionAsync
  public loadCommonArea = async () => {
    if (!this.commonArea.length) {
      const sdk = await task(getSdk());
      const data = await task(sdk.findArea());
      this.commonArea = data.findArea;
    }
    this.setLoading();
  };

  @actionAsync
  public loadBannerType = async () => {
    if (!this.bannerType.length) {
      const sdk = await task(getSdk());
      const data = await task(sdk.findManyCommon({ where: { type: 'banner_type' } }));
      this.bannerType = data.findManyCommon;
    }
    this.setLoading();
  };

  @actionAsync
  public loadBannerTemplateType = async () => {
    if (!this.bannerTemplateType.length) {
      const sdk = await task(getSdk());
      const data = await task(
        sdk.findManyCommon({ where: { type: 'banner_type', meta: { isLicense: true } } })
      );
      this.bannerTemplateType = data.findManyCommon;
    }
    this.setLoading();
  };

  @actionAsync
  public loadGroupType = async () => {
    const sdk = await task(getSdk());
    const data = await task(sdk.findManyCommon({ where: { type: 'group_category' } }));
    this.groupType = data.findManyCommon;
    this.setLoading();
  };

  @actionAsync
  public loadEquipmentType = async (where = { type: 'model_device' }) => {
    if (!this.equipmentType.length) {
      const sdk = await task(getSdk());
      const data = await task(sdk.findManyCommon({ where }));
      this.equipmentType = data.findManyCommon;
    }
    this.setLoading();
  };

  @actionAsync
  public loadLocationCategory = async () => {
    const sdk = await task(getSdk());
    const data = await task(sdk.findManyCommon({ where: { type: 'location_category' } }));
    this.categories = data.findManyCommon;
    this.setLoading();
  };

  @actionAsync
  public loadOwnedLocationCategory = async () => {
    const sdk = await task(getSdk());
    const data = await task(sdk.getCategories());
    this.locationCategories = data.getCategories as any;
    this.setLoading();
  };

  @actionAsync
  public loadAllLocations = async (where = {}) => {
    if (!this.locations.length) {
      const sdk = await task(getSdk());
      const data = await task(sdk.findCommonLocation({ where }));
      this.locations = data.findManyLocation;
    }
    this.setLoading();
  };

  @actionAsync
  public loadFullDataAllLocations = async (where = {}) => {
    if (!this.fullLocations.length) {
      const sdk = await task(getSdk());
      const data = await task(sdk.findManyLocation({ where }));
      this.fullLocations = data.findManyLocation;
    }
    this.setLoading();
  };

  @actionAsync
  public loadAllBanners = async () => {
    const sdk = await task(getSdk());
    const data = await task(
      sdk.findCommonBanner({ where: {}, sort: { createdAt: SortDirection.Desc } })
    );
    this.banners = data.findManyBanner;
  };

  @actionAsync
  public loadAllUser = async () => {
    if (!this.users.length) {
      const sdk = await task(getSdk());
      const data = await task(sdk.findManyUser({ where: {} }));
      this.users = data.findManyUser;
    }
    this.setLoading();
  };

  @actionAsync
  public loadCampaignRunning = async () => {
    const sdk = await task(getSdk());
    const data = await task(sdk.findCommonCampaign({ where: { status: 'running' } }));
    this.campaignRunning = data.findManyCampaign;
    this.setLoading();
  };

  // load _id, name trong campaign
  @actionAsync
  public loadCampaigns = async (where = {}) => {
    const sdk = await task(getSdk());
    const data = await task(
      sdk.findCommonCampaign({ where, sort: { createdAt: SortDirection.Desc } })
    );
    this.campaigns = data.findManyCampaign;
    this.setLoading();
  };
  // loaction in PKI
  @actionAsync
  public loadLocationCampaigns = async (where = {}) => {
    if (!this.locationCampaigns.length) {
      const sdk = await task(getSdk());
      const data = await task(sdk.findManyCampaign({ where }));
      this.locationCampaigns = data.findManyCampaign;
    }
  };
  // load data _id, mac của equipment
  @actionAsync
  public loadEquipment = async () => {
    if (!this.equipments.length) {
      const sdk = await task(getSdk());
      const data = await task(sdk.findMacEquipment({ where: {} }));
      this.equipments = data.findManyEquipment;
    }
    this.setLoading();
  };

  @actionAsync
  public loadCampaignTypeBrand = async () => {
    if (!this.campaignTypeBrand.length) {
      const sdk = await task(getSdk());
      const data = await task(sdk.findManyCommon({ where: { type: 'type_brand' } }));
      this.campaignTypeBrand = data.findManyCommon;
    }
    this.setLoading();
  };

  @actionAsync
  public loadCampaignType = async () => {
    if (!this.campaignType.length) {
      const sdk = await task(getSdk());
      const data = await task(sdk.findManyCommon({ where: { type: 'campaign_type' } }));
      this.campaignType = data.findManyCommon;
    }
    this.setLoading();
  };

  @actionAsync
  public loadCustomers = async (where?: CustomerFilter) => {
    if (!this.customers.length) {
      const sdk = await task(getSdk());
      const data = await task(sdk.findManyCustomer({ where }));
      this.customers = data.findManyCustomer;
    }
    this.setLoading();
  };

  @action
  public setLoading = () => {
    this.loading = false;
  };
}

export default new CommonDataStore();
