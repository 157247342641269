import { Col, Row, Tabs } from 'antd';
import { observer } from 'mobx-react';
import React, { FC, useEffect, useState } from 'react';
import ReactResizeDetector from 'react-resize-detector';
import { useHistory, useLocation } from 'react-router-dom';

import { isMobile } from '~utils/isMobile';
import { parseQuery, stringifyQuery } from '~utils/query-string';

import PasswordForm from './ChangePassword';
import InviteLicense from './InviteLicense';
import Message from './MessageScreen';
import NotificationTable from './NotificationScreen';
import store from './store';
import TwoFactorAuth from './twoFactorAuth/TwoFactorAuth';
import InfoForm from './UserInfo';
import { useTranslation } from 'react-i18next';

interface URLParams {
  tab: string;
}

const { TabPane } = Tabs;

const UserInfo: FC = observer(() => {
  const { clearFilter } = store;
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();

  const parsed = parseQuery(location.search) as URLParams;
  const [activeTab, setActiveTab] = useState();
  const onTabChange = (tab) => {
    clearFilter();
    const query = stringifyQuery({ tab });
    history.push({ search: query });
    setActiveTab(tab);
  };

  useEffect(() => {
    onTabChange(parsed.tab || 'basic');
  }, [parsed.tab]);

  return (
    <>
      <ReactResizeDetector handleHeight>
        {({ height }) => {
          return (
            <Tabs
              tabPosition={isMobile() ? 'top' : 'left'}
              activeKey={String(activeTab)}
              onChange={onTabChange}
              defaultActiveKey={activeTab}
              style={{ background: '#ffff', height: `${height}px`, padding: '8px' }}
            >
              <TabPane tab={t('BASIC_SETTING')} key="basic">
                <InfoForm />
              </TabPane>
              <TabPane tab={t('SECRET_SETTING')} key="secret">
                <Row justify="space-around" align="top">
                  <Col md={24} lg={12}>
                    <PasswordForm />
                  </Col>
                  <Col md={24} lg={12}>
                    <TwoFactorAuth />
                  </Col>
                </Row>
              </TabPane>
              {/* <TabPane tab="Notification" key="notification">
                <div style={{ height }}>
                  <NotificationTable />
                </div>
              </TabPane>
              <TabPane tab="Message" key="message">
                <Message />
              </TabPane> */}
              <TabPane tab={t('INVITE_LICENSE')} key="invite">
                <InviteLicense />
              </TabPane>
            </Tabs>
          );
        }}
      </ReactResizeDetector>
    </>
  );
});

export default UserInfo;
