import { TransferItem } from 'antd/lib/transfer';
import i18next from 'i18next';
import { debounce, get } from 'lodash';
import { action, observable, runInAction } from 'mobx';
import { actionAsync, task } from 'mobx-utils';

import { GridReadyEvent } from '@ag-grid-community/core';
import { MGN } from '@source/common';

import { ErrorNotify, SuccessNotify } from '~components/UI/Notification';
import { UpsertAssignmentMutationVariables } from '~graphql/_sdk';
import { getSdk } from '~graphql/sdk';

export enum ModelName {
  Campaign = 'Campaign',
  Location = 'Location',
  Profile = 'Profile'
}

export class AssignOwnerStore {
  @observable public currentUserAssignments: any;
  @observable public dataSource: TransferItem[];
  @observable public loading: boolean;
  @observable public upsertLoading: boolean;
  @observable public currentSelectUser: any;
  @observable public isShowModal: boolean;
  @observable public currentModel: string;
  @observable public where;

  constructor() {
    runInAction(() => {
      this.dataSource = [];
      this.where = { ownerType: MGN };
    });
  }

  @action
  public setIsShowModal = (value: boolean) => {
    this.isShowModal = value;
  };

  @action
  public setOwnerType = (type: string) => {
    if (type) {
      this.where = Object.assign(this.where, { ownerType: type });
    } else {
      this.where = Object.assign(this.where, { ownerType: null });
    }
    this.reload();
  };

  @action
  public setSearch = debounce((value: string) => {
    runInAction(() => {
      this.where = { ...this.where, search: value };
      this.reload();
    });
  }, 300);

  @action
  public setCurrentSelect = (user: any) => {
    this.currentSelectUser = user;
  };

  @actionAsync
  public upsertAssignment = async (data: UpsertAssignmentMutationVariables) => {
    runInAction(() => {
      this.upsertLoading = true;
    });

    const sdk = await task(getSdk());
    const { upsertAssignment } = await sdk.upsertAssignment(data);
    if (get(upsertAssignment, 'data')) {
      const response = [];
      upsertAssignment.data.forEach((value, index) => {
        const name = this.currentUserAssignments[value.resourceId];
        response.push({ ...value, name, key: value._id, index });
      });
      runInAction(() => {
        this.setIsShowModal(false);
      });
      SuccessNotify(i18next.t('ASSIGN'));
      this.reload();
    } else {
      ErrorNotify(i18next.t('ASSIGN'));
    }

    runInAction(() => {
      this.upsertLoading = false;
    });
  };

  @actionAsync
  public getAssignments = async (userId: string) => {
    const sdk = await task(getSdk());
    const { findManyOwner } = await task(
      sdk.findManyOwnerByUserId({ where: { model: this.currentModel, userId } })
    );
    return findManyOwner;
  };

  @actionAsync
  public getUserOwner = async (params: any) => {
    const { skip, limit, groupKeys, where: whereAgGird } = params;
    const userId = groupKeys ? groupKeys[0] : null;
    const where = { ...whereAgGird, model: this.currentModel, userId };
    this.loading = true;
    const sdk = await task(getSdk());
    const { findManyUserWithOwner } = await task(sdk.findManyUserWithOwner({ where, skip, limit }));

    if (findManyUserWithOwner.length > 0 && 'name' in findManyUserWithOwner[0]) {
      return { findManyUserWithOwner };
    }

    const response = [];
    findManyUserWithOwner.forEach((value: any) => {
      const resourceName = this.currentUserAssignments[value.resourceId];
      response.push({ ...value, resourceName });
    });

    runInAction(() => {
      this.loading = false;
    });

    return { findManyUserWithOwner: response };
  };

  @actionAsync
  public countUserOwner = async (params: any) => {
    const { skip, limit, groupKeys, where: whereAgGird } = params;
    const userId = groupKeys ? groupKeys[0] : null;
    const where = { ...whereAgGird, model: this.currentModel, userId };
    const sdk = await task(getSdk());
    const { countManyUserWithOwner } = await task(
      sdk.countManyUserWithOwner({ where, skip, limit })
    );
    return { countManyUserWithOwner };
  };

  @actionAsync
  public getCurrenUserAssignments = async (model: string) => {
    runInAction(() => {
      this.loading = true;
      this.currentModel = model;
    });
    const sdk = await task(getSdk());
    let response;
    switch (model) {
      case ModelName.Campaign:
        const { findManyCampaign } = await task(sdk.findManyCampaignIdAndName());
        response = findManyCampaign;
        break;
      case ModelName.Location:
        const { findManyLocation } = await task(sdk.findManyLocationIdAndName());
        response = findManyLocation;
        break;
      case ModelName.Profile:
        const { findManyProfile } = await task(sdk.findManyProfileIdAndDisplay());
        response = findManyProfile;
        break;
    }

    runInAction(() => {
      this.dataSource = [];
      this.currentUserAssignments = response.reduce((acc, value) => {
        this.dataSource.push({
          key: value._id,
          title: get(value, 'name') || get(value, 'display')
        });
        acc[value._id] = get(value, 'name') || get(value, 'display');
        return acc;
      }, {});

      this.loading = false;
    });
  };

  @action
  public handleGridReady = ({ api }: GridReadyEvent) => {
    this.reload = () => {
      api.onFilterChanged();
    };
  };

  @action
  public reload = () => undefined;
}

export default new AssignOwnerStore();
