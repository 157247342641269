import '@ant-design/compatible/assets/index.css';

import { Button, Form, Input, Modal } from 'antd';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { commonDataStore } from '~common-stores/store-index';
import { UISelect } from '~components/UI/Select/UISelect';
import { AdvanceCampaignInfoArgs } from '~graphql/_sdk';

import store from './store';

const validateMessages = {
  required: '${label} is required!'
};

interface FormValues {
  name?: string;
  type?: string;
  shareOwner?: string;
}

interface Props {
  visible: boolean;
  setModalVisible: () => void;
}

const AdvanceRender: FC<Props> = observer(({ visible, setModalVisible }) => {
  const { t } = useTranslation();
  const { selectedItem, updateAdvance, loading } = store;
  const [form] = Form.useForm();

  const initialValues: FormValues = {
    name: get(selectedItem, 'name', ''),
    type: get(selectedItem, 'type'),
    shareOwner: get(selectedItem, 'shareOwner') || ''
  };

  const onSubmit = async (record: FormValues) => {
    const { name, type, shareOwner } = record;

    const records = {
      name,
      type,
      shareOwner,
      customerId: shareOwner
    };

    await updateAdvance(selectedItem._id.toString(), records as AdvanceCampaignInfoArgs);
    setModalVisible();
  };

  const onCancel = () => {
    form.resetFields();
    setModalVisible();
  };

  const footer = (
    <>
      <Button type="ghost" onClick={() => onCancel()}>
        {t('CLOSE')}
      </Button>
      <Button htmlType="submit" form="advanceForm" loading={loading} type="primary">
        {t('UPDATE')}
      </Button>
    </>
  );

  useEffect(() => {
    commonDataStore.loadCustomers({ shareTraffic: { enabled: true } });
    form.setFieldsValue(initialValues);
  }, [selectedItem]);

  return (
    <Modal
      title={t('CAMPAIGN_INFORMATION')}
      footer={footer}
      visible={visible}
      onCancel={() => setModalVisible()}
    >
      <Form
        id="advanceForm"
        initialValues={initialValues}
        validateMessages={validateMessages}
        layout="vertical"
        onFinish={onSubmit}
        form={form}
      >
        <Form.Item name="name" label={t('CAMPAIGN_NAME')} rules={[{ required: true }]}>
          <Input />
        </Form.Item>

        <Form.Item name="type" label={t('CAMPAIGN_TYPE')}>
          <UISelect
            placeholder={t('SELECT_CAMPAIGN_TYPE')}
            dataOptions={{
              data: commonDataStore.campaignType,
              valueField: 'value',
              textField: 'text'
            }}
          />
        </Form.Item>

        <Form.Item name="shareOwner" label={t('CUSTOMER')}>
          <UISelect
            placeholder={t('SELECT_CUSTOMER')}
            dataOptions={{
              data: commonDataStore.customers,
              valueField: '_id',
              textField: 'name'
            }}
            allowClear
          />
        </Form.Item>
      </Form>
    </Modal>
  );
});

export default AdvanceRender;
