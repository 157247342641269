import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import HighChart from '~components/UI/HighChart/HighChart.lazy';
import { format, RANGE_DAY_FORMAT } from '~utils/formatDate';

import store from './store';

interface Chart {
  heightChart: number;
  widthChart?: number;
}

const Chart: FC<Chart> = observer(props => {
  const { heightChart, widthChart } = props;
  const { chartData, getChartData } = store;
  const { t } = useTranslation();

  useEffect(() => {
    if (!chartData) {
      getChartData();
    }
  }, []);

  const getItem = (key, isDate = false) => {
    const realData = chartData?.reduce((all, item) => {
      all = [...all, isDate ? format(get(item, key, []), RANGE_DAY_FORMAT) : get(item, key, [])];
      return all;
    }, []);
    return realData || null;
  };

  const options: Highcharts.Options = {
    title: {
      text: t('TRAFFIC')
    },
    plotOptions: {
      series: {
        label: {
          enabled: false
        }
      }
    },
    series: [
      {
        name: t('IMPRESSION'),
        color: '#0078D4',
        type: 'column',
        data: getItem('impression'),
        boostThreshold: 1
      },
      {
        name: t('CLICK'),
        color: '#FFA242',
        type: 'column',
        data: getItem('click'),
        boostThreshold: 1
      },
      {
        name: t('CTR'),
        color: '#EC1E06',
        zIndex: 1,
        type: 'spline',
        data: getItem('ctr'),
        yAxis: 1,
        tooltip: {
          valueSuffix: ' %'
        },
        boostThreshold: 1
      }
    ],
    xAxis: {
      categories: getItem('date', true),

      title: {
        text: null
      },
      tickInterval: 5
    },
    yAxis: [
      {
        min: 0,
        title: {
          text: t('TRAFFIC'),
          align: 'middle'
        },
        labels: {
          overflow: 'justify'
        }
      },
      {
        min: 0,
        max: 100,
        title: {
          text: t('CTR_%'),
          align: 'middle'
        },
        opposite: true,
        labels: {
          overflow: 'justify'
        }
      }
    ],
    chart: {
      events: {
        redraw: e => {
          e.preventDefault();
        }
      },
      height: heightChart - 60,
      width: widthChart
    },
    boost: { enabled: true },
    tooltip: {
      shared: true
    },
    credits: {
      enabled: false
    }
  };
  return <HighChart options={options} />;
});

export default Chart;
