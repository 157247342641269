import { observer } from 'mobx-react';
import moment from 'moment';
import React, { FC } from 'react';

import { ColDef } from '@ag-grid-community/core';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';

import AgGridClient from '~components/UI/AgGrid/AgGridClient';
import { UISelect } from '~components/UI/Select/UISelect';
import { formatNumber } from '~utils/formatNumber';

import store from './store';

interface GridProps {
  height: number;
  width?: number;
}

const UpDownTraffic: FC<GridProps> = observer(props => {
  const { height, width } = props;
  const { reportUpDown, setUpDownDate, amongTime, loading } = store;

  const dateNow = {
    gte: moment()
      .subtract(amongTime, 'days')
      .startOf('days')
      .format('DD-MM-YYYY'),
    lte: moment()
      .subtract(1, 'days')
      .endOf('days')
      .format('DD-MM-YYYY')
  };
  const dateBefore = {
    gte: moment()
      .subtract(amongTime * 2, 'days')
      .startOf('days')
      .format('DD-MM-YYYY'),
    lte: moment()
      .subtract(amongTime + 1, 'days')
      .endOf('days')
      .format('DD-MM-YYYY')
  };

  const columnDefs: ColDef[] = [
    {
      field: '',
      cellClass: 'text-bold',
      sortable: false,
      minWidth: 300,
      cellRendererFramework: ({ data }) => {
        return data.categoryName || data.locationName;
      }
    },
    {
      headerName: `${dateNow.gte} - ${dateNow.lte}`,
      field: 'click',
      enableValue: true,
      sortable: true,
      minWidth: 250,
      maxWidth: 250,
      cellClass: 'text-right text-bold',
      cellRendererFramework: ({ value }) => {
        if (typeof value !== 'number') {
          return 0;
        }
        return formatNumber(value);
      }
    },
    {
      headerName: `${dateBefore.gte} - ${dateBefore.lte}`,
      field: 'clickBefore',
      enableValue: true,
      sortable: true,
      minWidth: 250,
      maxWidth: 250,
      cellClass: 'text-right text-bold',
      cellRendererFramework: ({ value }) => {
        if (typeof value !== 'number') {
          return 0;
        }
        return formatNumber(value);
      }
    },
    {
      headerName: `UpDown`,
      field: '',
      enableValue: true,
      sortable: true,
      minWidth: 150,
      maxWidth: 150,
      cellClass: 'text-right text-bold',
      cellRendererFramework: ({ data }) => {
        let icon = <ArrowDownOutlined />;
        let color = 'red';
        if (data.click >= data.clickBefore) {
          icon = <ArrowUpOutlined />;
          color = 'green';
        }
        const percent = Math.abs(100 - (data.click / data.clickBefore || 1) * 100);
        return (
          <span style={{ color }}>
            {icon} {Number(percent).toFixed(2)}%
          </span>
        );
      }
    }
  ];

  return (
    <>
      <div style={{ textAlign: 'right', marginBottom: '10px' }}>
        <UISelect
          dataOptions={{
            data: [
              { value: 7, text: '7 days' },
              { value: 14, text: '14 days' },
              { value: 30, text: '30days' }
            ],
            valueField: 'value',
            textField: 'text'
          }}
          placeholder="Select Customer..."
          defaultValue={amongTime}
          onChange={v => setUpDownDate(v)}
          showArrow
          dropdownStyle={{ minWidth: 150 }}
          style={{ width: 150, textAlign: 'left' }}
        />
      </div>
      <AgGridClient
        columnDefs={columnDefs}
        rowData={reportUpDown}
        loading={loading}
        height={height - 100}
      />
    </>
  );
});

export default UpDownTraffic;
