const translations = {
  PLATFORM_INFO: 'ADVERTISER MANAGERMENT PLATFORM',
  INPUT_REFERRAL: 'INPUT REFERRAL',
  YOU_MUST_AGREE_WITH_CONDITION: 'YOU MUST AGREE WITH CONDITION',
  MAXIMUM: 'MAXIMUM',
  REGISTER_SUCCESS: 'REGISTER SUCCESS',
  CHECK_EMAIL_TO_ACTIVE: 'CHECK EMAIL TO ACTIVE',
  EMAIL_EXISTED_ERR: 'EMAIL EXISTED ERROR',
  ACCOUNT: 'Account',

  // Menu
  DASHBOARD: 'DASHBOARD',
  INVENTORY: 'KPI',
  AVAILABLE_KPI: 'available kpi',
  HOTSPOT: 'HOTSPOT',
  LOCATION_LIST: 'Location list',
  CAMPAIGN_LIST: 'campaign list',
  EQUIPMENT_LIST: 'Access Point',
  CAMPAIGNS_PRIORITY: 'campaign priority',
  LOCATIONS_PRIORITY: 'location priority',
  ACTIVE_CAMPAIGN: 'active campaign',
  CREATIVE_ADS: 'creative ads',

  BOOKING_ONLINE: 'booking online',

  REPORT: 'report',
  CAMPAIGN_BOOKED: 'campaign booked',
  TRAFFIC_LOCATION: 'traffic location',

  PERMISSION: 'permission',
  USER_LIST: 'user list',
  PROFILE_LIST: 'profile list',

  ASSIGN_CAMPAIGN: 'assign campaign',
  ASSIGN_LOCATION: 'assign Location',
  ASSIGN_PROFILE: 'assign profile',

  region: {
    NAME: 'Region',
    AREA: 'Area',
    CITY: 'City',
    DISTRICT: 'District'
  },

  category: {
    name: 'category',
    NAME: 'Category',
    names: 'categories',
    NAMES: 'Categories'
  },

  location: {
    name: 'location',
    NAME: 'Location',
    names: 'locations',
    NAMES: 'Locations',
    MONTHLY_CLICK: 'Monthly Click',
    COMING_SOON: 'Coming Soon'
  },

  banner: {
    NAME: 'Banner',
    MODE: 'Mode',
    TEMPLATE: 'Template',
    SIMPLE: 'Simple',
    VIDEO: 'Video',
    FORM: 'Form',
    SLIDE: 'Slide',
    logoImage: 'Logo',
    contentImage: 'Background',
    loginButton: 'Button',
    footerImage: 'Footer',
    UPLOAD: 'Upload {{item}}',
    OPEN: 'Open {{item}}',
    logoPosition: 'Position',
    contentImg: 'background',
    contentGallery: 'Galerry',
    contentImgPosition: 'Position',
    imgLoginBtn: 'button',
    loginBtnGallery: 'Gallery',
    bannerlogoPosition: 'Position',
    bannercontentImgPosition: 'Position',
    footerGallery: 'Gallery',
    form: 'Form',
    fields: 'Fields',
    video: 'Video',
    thumbnailImg: 'thumbnail',
    timeout: 'Time out',
    contentImage1: 'Background 1',
    contentImage2: 'Background 2',
    contentImg1: 'background 1',
    contentImg2: 'background 1',
    contentGallery1: 'gallery',
    contentGallery2: 'gallery'
  },

  campaign: {
    NAME: 'Campaign',
    LANDING_PAGE: 'Landing Page',
    PLACE_BOOKING: 'Book Campaign',
    CREATE: 'Create Campaign'
  },

  pricing: {
    NAME: 'Pricing',
    PRICE: 'Price'
  },

  booking: {
    START_DATE: 'Start Date',
    END_DATE: 'End Date',
    SCHEDULE: 'Schedule',
    BALANCE_SOURCE: 'Balance Source',
    BUDGET: 'Budget',
    TOTAL_BUDGET: 'Total Budget',
    KPI: 'KPI',
    KPI_AVAILABLE: 'Available',
    STEP_LOCATION: 'Location',
    STEP_LOCATION_DESC: 'Choose your target locations',
    STEP_SCHEDULE_KPI: 'Schedule & Budget',
    STEP_SCHEDULE_KPI_DESC: 'Set schedule and budget',
    STEP_CONTENT: 'Content',
    STEP_CONTENT_DESC: 'Set your banner and landing page',
    STEP_REVIEW: 'Review',
    STEP_REVIEW_DESC: 'Review your booking',
    ERR_BOOKING_NO_LOCATION: 'Please choose your target location(s) first!',
    ERR_BOOKING_MISSING_INFO: 'Some information is missing',
    ERR_BUGDET_IS_HIGHER_THAN_BALANCE: 'Your balance is not enough',
    ERR_BALANCE_SOURCE_IS_EMPTY: 'Balance source is empty',
    ERR_KPI_IS_HIGHER_THAN_AVAILABLE: 'KPI must lower than available',
    CAMPAIGN_NAME: 'Campaign Name',
    SELECTED_LOCATION: 'Selected Location',
    BOOKING_SUCCESS: 'Your booking is placed successfully!'
  },

  picture: {
    NAME: 'Picture'
  },

  notify: {
    CANCLE: 'Cancle',
    SUCCESS: 'Success',
    FAIL: 'Fail'
  }
};

export default translations;
