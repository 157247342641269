import { Descriptions, Input } from 'antd';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { DATE_FORMAT, format } from '~utils/formatDate';
import { formatKpi } from '~utils/formatKpi';
import { formatNumber } from '~utils/formatNumber';

import store from './store';

const CampaignInfo: FC = observer(() => {
  const { campaign, handleSearch, totalResult } = store;
  const { t } = useTranslation();

  return (
    <Descriptions
      title={t('CAMPAIGN_INFO')}
      bordered
      style={{ marginBottom: 20 }}
      extra={[
        <Input
          key="3"
          placeholder={`${t('SEARCH')} ${t('LOCATION')}`}
          onChange={(e) => handleSearch(e.target.value)}
          style={{ width: 200 }}
        />
      ]}
    >
      <Descriptions.Item label={t('NAME')}>{campaign.name}</Descriptions.Item>
      <Descriptions.Item label="KPI">{formatKpi(campaign.kpi)}</Descriptions.Item>
      <Descriptions.Item label={t('SCHEDULE')}>
        {format(get(campaign, 'scheduleFrom'), DATE_FORMAT)} {'->'} {' '}
        {get(campaign, 'scheduleTo')
          ? format(get(campaign, 'scheduleTo'), DATE_FORMAT)
          : 'Unlimited'}
      </Descriptions.Item>
      <Descriptions.Item label={t('REMAINING_DAY')}>
        {formatNumber(totalResult?.statsKpiRemaining)}
      </Descriptions.Item>
      <Descriptions.Item label={t('YESTERDAY')}>
        {formatNumber(totalResult?.yesterdayClick)} / {formatNumber(totalResult?.yesterdayKpi)}
      </Descriptions.Item>
      <Descriptions.Item label={t('TODAY')}>
        {formatNumber(totalResult?.todayClick)} / {formatNumber(totalResult?.todayKpi)}
      </Descriptions.Item>
    </Descriptions>
  );
});

export default CampaignInfo;
