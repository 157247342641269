import { Col, Row } from 'antd';
import React, { FC } from 'react';

import Form from './Form';
import Grid from './Grid';
import Header from './Header';
import InfoModal from './InfoModal';
import RejectDialog from './RejectDialog';

const PlanScreen: FC = () => {
  return (
    <>
      <Row gutter={16} style={{ flexDirection: 'column', flexWrap: 'nowrap', height: '100%' }}>
        <Col flex="0 0 auto">
          <Header />
        </Col>
        <Col flex="1" style={{ display: 'flex' }}>
          <Grid />
        </Col>
      </Row>
      <Form />
      <RejectDialog />
      <InfoModal />
    </>
  );
};

export default PlanScreen;
