import { Card, Col, Row, Statistic } from 'antd';
import { observer } from 'mobx-react';
import TweenOne from 'rc-tween-one';
import Children from 'rc-tween-one/lib/plugin/ChildrenPlugin';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Counting from '~components/UI/Counting';

import store from './store';

TweenOne.plugins.push(Children);

const StatisticComponent: FC = observer(() => {
  const { t } = useTranslation();
  const { income } = store;

  const customTitle = (icon: string, string: string, color: string) => {
    return (
      <>
        <i style={{ marginRight: 8, color, fontSize: 17 }} className={icon} />
        <b style={{ color }}>{string}</b>
      </>
    );
  };

  const colSpan = {
    md: 24,
    sm: 12,
    flex: 1
  };

  return (
    <Row
      gutter={16}
      style={{
        paddingBottom: 12,
        paddingTop: 0,
        paddingLeft: 5,
        paddingRight: 5,
        marginLeft: 0,
        marginRight: 0,
        backgroundColor: '#fff'
      }}
    >
      <Col {...colSpan} style={{ maxWidth: 300 }}>
        <Card
          style={{
            borderLeft: '1px solid #0078D4',
            borderColor: '#0078D4',
            backgroundColor: 'rgba(0, 120, 212, 0.82)'
          }}
          title={customTitle('fas fa-eye', 'Click', 'white')}
          extra={
            <span style={{ fontSize: 20, float: 'right', color: 'white', position: 'relative' }}>
              <Counting value={+income?.click} />
            </span>
          }
          bodyStyle={{ padding: 0 }}
        ></Card>
      </Col>
      <Col {...colSpan} style={{ maxWidth: 300 }}>
        <Card
          style={{
            borderLeft: '1px solid #FFA242',
            borderColor: '#FFA242',
            backgroundColor: 'rgba(255, 162, 66, 0.89)'
          }}
          title={customTitle('fas fa-location-arrow', 'Income', 'white')}
          extra={
            <span style={{ fontSize: 20, float: 'right', color: 'white', position: 'relative' }}>
              <Counting value={+income?.income} />
            </span>
          }
          bodyStyle={{ padding: 0 }}
        ></Card>
      </Col>
      <Col {...colSpan} style={{ maxWidth: 300 }}>
        <Card
          style={{
            borderColor: 'green',
            borderLeft: '1px solid green',
            backgroundColor: 'green',
          }}
          title={customTitle('fas fa-location-arrow', 'Income', 'white')}
          extra={
            <span style={{ fontSize: 20, float: 'right', color: 'white', position: 'relative' }}>
              <Counting value={+income?.incomePartner} />
            </span>
          }
          bodyStyle={{ padding: 0 }}
        ></Card>
      </Col>
    </Row>
  );
});

export default StatisticComponent;
