import { observer } from 'mobx-react';
import React, { FC, useEffect } from 'react';

import { commonDataStore } from '~common-stores/store-index';

import Form from './Form';
import Grid from './Grid';
import Header from './Header';

const LocationCategoryScreen: FC = observer(() => {
  const {loadGroupType} = commonDataStore;
  useEffect(() => {
    loadGroupType()
  }, [])
  return (
    <>
      <Header />
      <Grid />
      <Form />
    </>
  );
});

export default LocationCategoryScreen;
