import { Button, Card, Col, Input, Row } from 'antd';
import { isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { LocationStatus, ROLE_CREATE_LOCATION } from '@source/common';

import Authorized from '~components/Authorized/Authorized';
import { FilterConfig } from '~components/Filter/filterType';
import UIFilterHorizontal from '~components/Filter/UIFilterHorizontal';
import OwnerType from '~components/UI/OwnerTypeRadio';
import { useStore } from '~context/react-context';

import { Export } from './Export';
import store from './store';

const LocationHeader: FC = observer(() => {
  const {
    setModalVisible,
    searchFilter,
    setOwnerType,
    loadDistrictData,
    districtData,
    setFilter,
    setFilterCategoryId,
    setFilterActiveDay,
    setFilterCity,
    setFilterDistrict,
    setFilterStatus,
    filterActiveDay,
    setFilterCreatedAt
  } = store;
  const { commonDataStore } = useStore();
  const { t } = useTranslation();

  const statusOptions = [
    { text: t('CREATED'), value: LocationStatus.CREATED },
    { text: t('ACTIVE'), value: LocationStatus.ACTIVE },
    { text: t('DISABLED'), value: LocationStatus.DISABLED },
    { text: t('COMING_SOON'), value: LocationStatus.COMING_SOON }
  ];

  const initFilter: FilterConfig = {
    select: [
      {
        dataOptions: {
          data: statusOptions,
          valueField: 'value',
          textField: 'text'
        },
        props: {
          placeholder: `${t('STATUS')}`,
          allowClear: true,
          onChange: (value) => {
            setFilterStatus(value);
          },
          defaultValue: 'active'
        },
        filterField: 'status',
        label: t('STATUS')
      },
      commonDataStore.locationCategories.length > 1 && {
        dataOptions: {
          data: commonDataStore.locationCategories,
          valueField: 'value',
          textField: 'text'
        },
        props: {
          placeholder: `${t('LOCATION')} ${t('CATEGORY')}`,
          allowClear: true,
          onChange: (value) => {
            if (!isEmpty(value)) {
              setFilterCategoryId({ categoryId: { in: value } });
            } else setFilterCategoryId({});
          }
        },
        filterField: 'categoryId',
        label: `${t('LOCATION')} ${t('CATEGORY')}`
      },
      {
        dataOptions: {
          data: commonDataStore.commonCity,
          valueField: 'value',
          textField: 'text'
        },

        props: {
          placeholder: `${t('CITY')}`,
          allowClear: true,
          onSelect: (value) => {
            loadDistrictData(value);
          },
          onChange: (value) => {
            if (!isEmpty(value)) {
              setFilterCity(value);
            } else setFilterCity(null);
          }
        },
        filterField: 'city',
        label: t('CITY')
      },
      {
        dataOptions: {
          data: districtData,
          valueField: 'value',
          textField: 'text'
        },
        props: {
          placeholder: `${t('DISTRICT')}`,
          allowClear: true,
          onChange: (value) => {
            setFilterDistrict(value);
          },
          defaultValue: null
        },
        filterField: 'district',
        label: t('DISTRICT')
      }
    ].filter(Boolean),
    dateRange: [
      {
        filterFields: ['createdAt'],
        label: t('CREATED_DATE'),
        props: {
          allowClear: false
        },
        onChange: (value) => {
          setFilterCreatedAt({ createdAt: { gte: value[0], lte: value[1] } });
        }
      },
      {
        filterFields: ['activeDay'],
        label: t('ACTIVE_DAY'),
        props: {
          allowClear: false
        },
        onChange: (value) => {
          if (filterActiveDay.onChange) return filterActiveDay.onChange(value);
          setFilterActiveDay({ activeDay: { gte: value[0], lte: value[1] } });
        }
      }
    ]
  };

  return (
    <Card size="small" className="card-filter">
      <Row gutter={16} justify="end">
        <Col style={{ marginRight: 'auto' }}>
          <OwnerType setOwnerFunc={setOwnerType} />
        </Col>
        <Col>
          <UIFilterHorizontal key="filter" config={initFilter} onFilterChanged={setFilter} />
        </Col>
        <Col>
          <Input
            key="search"
            suffix={<SearchOutlined />}
            placeholder={t('TYPE_TO_SEARCH')}
            allowClear
            onChange={(e) => searchFilter(e.target.value)}
            style={{ width: '200px' }}
          />
        </Col>
        <Col>
          <Authorized key="create" roles={[ROLE_CREATE_LOCATION]}>
            <Button
              key="btn"
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setModalVisible(null, 'basic')}
            />
          </Authorized>
        </Col>
        <Col>
          <Export key="export" />
        </Col>
      </Row>
    </Card>
  );
});

export default LocationHeader;
