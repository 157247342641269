import { observer } from 'mobx-react';
import React, { FC, useEffect } from 'react';

import Form from './Form';
import Grid from './Grid';
import Header from './Header';
import store from './store';

const CommonScreen: FC = observer(() => {
  const { fetchTypes } = store;

  useEffect(() => {
    fetchTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header />
      <Grid />
      <Form />
    </>
  );
});

export default CommonScreen;
