/* eslint-disable react/jsx-no-target-blank */
import {
  Badge,
  Col,
  Collapse,
  Descriptions,
  Divider,
  Drawer,
  List,
  Row,
  Statistic,
  Tabs,
  Tag
} from 'antd';
import { BadgeProps } from 'antd/lib/badge';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import DescriptionItem from '~components/UI/DesciptionItem';
import Header from '~components/UI/Header';
import { DATE_FORMAT, format } from '~utils/formatDate';
import { formatNumber } from '~utils/formatNumber';

import store from './store';

const { TabPane } = Tabs;
const { Panel } = Collapse;

interface Props {
  visible: boolean;
  setModalVisible: () => void;
}

const ViewRender: FC<Props> = observer(({ visible, setModalVisible }) => {
  const { t } = useTranslation();

  const { selectedItem, preview } = store;
  let schedule = '';
  const scheduleFrom = `From ${format(get(selectedItem, 'scheduleFrom'), DATE_FORMAT)}`;
  const scheduleTo = get(selectedItem, 'scheduleTo')
    ? ` to ${format(get(selectedItem, 'scheduleTo'), DATE_FORMAT)}`
    : '';
  if (scheduleTo) {
    schedule = scheduleFrom + scheduleTo;
  } else {
    schedule = scheduleFrom;
  }

  let kpi = '';
  if (!get(selectedItem, 'kpi') || get(selectedItem, 'kpi') === 0) {
    kpi = 'Unlimited';
  } else {
    kpi = get(selectedItem, 'kpi', 0).toLocaleString();
  }

  const locations = get(selectedItem as any, 'locations', []).reduce((all, item) => {
    if (all[item.categoryName]) {
      all[item.categoryName] = [...all[item.categoryName], item];
    } else {
      all[item.categoryName] = [item];
    }
    return all;
  }, {});
  const status = get(selectedItem, 'status');
  let type = 'default';
  switch (status) {
    case 'scheduled':
      type = 'warning';
      break;

    case 'running':
      type = 'success';
      break;

    case 'finish':
      type = 'processing';
      break;

    default:
      type = 'default';
      break;
  }

  const landingPageUrl = get(selectedItem, 'config.landingPageUrl', '');

  return (
    <Drawer width={640} placement="right" onClose={() => setModalVisible()} visible={visible}>
      <Tabs defaultActiveKey="1">
        <TabPane tab={t('CAMPAIGN')} key="1">
          <Header title={t('INFO')} />
          <Descriptions bordered size="small" column={2}>
            <Descriptions.Item label={t('NAME')} span={1}>
              {get(selectedItem, 'name', '')}
            </Descriptions.Item>

            <Descriptions.Item label={t('TYPE')} span={1}>
              <Tag>{get(selectedItem, 'type', '')}</Tag>
            </Descriptions.Item>

            <Descriptions.Item label={t('SCHEDULE')} span={2}>
              {schedule}
            </Descriptions.Item>

            <Descriptions.Item label={t('STATUS')} span={1}>
              <Tag>
                <Badge status={type as BadgeProps['status']} text={status} />
              </Tag>
            </Descriptions.Item>

            <Descriptions.Item label={t('CREATOR')} span={1}>
              {get(selectedItem, 'creator.name', '')}
            </Descriptions.Item>
          </Descriptions>
          <Divider />
          <Row>
            <Col span={8}>
              <Statistic
                title={t('IMPRESSION')}
                value={(get(selectedItem, 'stats.impression') || 0).toLocaleString()}
              />
            </Col>

            <Col span={8}>
              <Statistic
                title={t('CLICK')}
                value={(get(selectedItem, 'stats.click') || 0).toLocaleString()}
              />
            </Col>

            <Col span={8}>
              <Statistic
                title={t('CTR')}
                value={get(selectedItem, 'stats.ctr', 0) || 0}
                suffix="%"
              />
            </Col>
          </Row>
          <Divider />
          <Header title={t('CONFIG')} />
          <a href={landingPageUrl} target="_blank">
            <DescriptionItem
              title={t('LANDING_PAGE')}
              content={get(selectedItem, 'config.landingPageUrl', '')}
            />
          </a>
          <DescriptionItem
            title="Banners"
            content={
              <List
                size="small"
                bordered
                dataSource={get(selectedItem, 'config.banners', []) || []}
                renderItem={(item: any) => (
                  <List.Item key={item.bannerId} onClick={() => preview(item.bannerId, landingPageUrl)}>
                    <a>
                      {get(item, 'bannerName', '')}
                    </a>
                  </List.Item>
                )}
              />
            }
          />
        </TabPane>
        <TabPane
          tab={
            <Badge offset={[10, -5]} count={get(selectedItem, 'locationId', []).length}>
              LOCATION
            </Badge>
          }
          key="2"
        >
          <Collapse defaultActiveKey={['0']} bordered={false} accordion>
            {Object.keys(locations).map((location, index) => (
              <Panel
                header={location}
                key={String(index)}
                extra={<Badge count={locations[location].length} />}
              >
                <List
                  size="small"
                  dataSource={locations[location]}
                  renderItem={(item: any) => (
                    <List.Item key={item._id}>
                      <Row style={{ width: '100%' }}>
                        <Col span={18}>{get(item, 'locationName', '')}</Col>
                        <Col span={4}>
                          {t('KPI')}: {formatNumber(get(item, 'kpi'))}
                        </Col>
                      </Row>
                    </List.Item>
                  )}
                />
              </Panel>
            ))}
          </Collapse>
        </TabPane>
      </Tabs>
    </Drawer>
  );
});

export default ViewRender;
