import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import { isEmpty } from 'lodash';
import React from 'react';

import { DownloadOutlined } from '@ant-design/icons';

import { useConfig } from '~config/ConfigProvider';

import { stringifyQuery } from '../../utils/query-string';

export interface UIButtonExportExcelProps extends ButtonProps {
  name: string;
  model: string;
  filter?: { [field: string]: any };
  token?: string;
}

export const UIButtonExportExcel = (props: UIButtonExportExcelProps) => {
  const { model, filter, name, token } = props;
  const { restApiEndpoint } = useConfig();

  const onExportExcel = async () => {
    if (!isEmpty(filter)) {
      const query = stringifyQuery(filter);
      window.open(`${restApiEndpoint}/${model}/export?${query}&token=${token}`, '_blank');
      return;
    }
    window.open(`${restApiEndpoint}/${model}/export?`, '_blank');
  };

  return (
    <Button
      {...props}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        onExportExcel();
      }}
      icon={<DownloadOutlined />}
    >
      {/* {name} */}
    </Button>
  );
};
