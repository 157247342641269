import { Button, Descriptions, Divider, Input, Select, Tag } from 'antd';
import { sumBy, throttle } from 'lodash';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { formatNumber } from '~utils/formatNumber';

import store, { RevenueInputType } from '../store';

const { Option } = Select;

const ConfigOption: FC = observer(() => {
  const { t } = useTranslation();
  const {
    revenue,
    totalMgnRevenue,
    totalCustomerRevenue,
    myRevenue,
    actualRevenue,
    contractCost,
    commission,
    invoiceFee,
    setContractCost,
    setCommission,
    setInvoiceFee,
    handleUpdateRevenue,
    totalKpiBooked,
  } = store;

  const handleOnChangeNumber = throttle((value, callback) => {
    let input = value.replace(/\D/g, '');

    input = parseInt(input, 10);
    if (input < 0 || Number.isNaN(input)) {
      input = 0;
    }
    callback(input);
  }, 300);

  return (
    <div style={{ width: '100%', height: '100%', backgroundColor: 'white', padding: 10 }}>
      <Divider orientation="left">Campaign Information</Divider>
      <Descriptions column={1} bordered size="small">
        <Descriptions.Item span={1} label="Campaign">
          {revenue?.campaignName}
        </Descriptions.Item>
        <Descriptions.Item span={1} label="KPI">
          <Tag color="geekblue">{formatNumber(totalKpiBooked)}</Tag>
        </Descriptions.Item>
        <Descriptions.Item span={1} label="Click">
          <Tag color="green">{formatNumber(revenue?.kpiRunned)}</Tag>
        </Descriptions.Item>
      </Descriptions>

      <Divider orientation="left">Income</Divider>
      <Descriptions column={1} bordered size="small">
        <Descriptions.Item span={1} label={t('MGN_REVENUE')}>
          <Tag color="#2db7f5">{formatNumber(totalMgnRevenue)}</Tag>
        </Descriptions.Item>
        <Descriptions.Item span={1} label={t('CUSTOMER_REVENUE')}>
          <Tag color="#87d068">{formatNumber(totalCustomerRevenue)}</Tag>
        </Descriptions.Item>
        <Descriptions.Item span={1} label={t('ACTUAL_REVENUE')}>
          <Tag color="#108ee9">{formatNumber(actualRevenue)}</Tag>
        </Descriptions.Item>
      </Descriptions>

      <Divider orientation="left">Setting</Divider>
      <Descriptions column={1} bordered size="small">
        <Descriptions.Item span={1} label={t('CONTRACT_COSTS')}>
          <Input
            value={formatNumber(contractCost)}
            onChange={e => handleOnChangeNumber(e.target.value, setContractCost)}
            style={{ width: '100%' }}
          />
        </Descriptions.Item>
        <Descriptions.Item span={1} label={t('COMMISSION')}>
          {/* <Select
            onChange={e => setCommission({ type: e })}
            style={{ width: '40%' }}
            value={commission.type}
            disabled
          >
            <Option value={RevenueInputType.Money}>{t('MONEY')}</Option>
            <Option value={RevenueInputType.Percent}>{t('PERCENTAGE')}</Option>
          </Select> */}
          <Input
            value={
              commission.type === RevenueInputType.Money
                ? formatNumber(commission?.value)
                : commission?.value
            }
            onChange={
              commission.type === RevenueInputType.Money
                ? e => handleOnChangeNumber(e.target.value, value => setCommission({ value }))
                : e => setCommission({ value: e.target.value })
            }
            addonAfter={commission.type === RevenueInputType.Money ? '$' : '%'}
            style={{ width: '100%' }}
          />
        </Descriptions.Item>
        <Descriptions.Item span={1} label={t('INVOICE_FEE')}>
          {/* <Select
            style={{ width: '40%' }}
            onChange={e => setInvoiceFee({ type: e })}
            value={invoiceFee.type}
            disabled
          >
            <Option value={RevenueInputType.Money}>{t('MONEY')}</Option>
            <Option value={RevenueInputType.Percent}>{t('PERCENTAGE')}</Option>
          </Select> */}
          <Input
            value={
              invoiceFee.type === RevenueInputType.Money
                ? formatNumber(invoiceFee?.value)
                : invoiceFee?.value
            }
            onChange={
              invoiceFee.type === RevenueInputType.Money
                ? e => handleOnChangeNumber(e.target.value, value => setInvoiceFee({ value }))
                : e => setInvoiceFee({ value: e.target.value })
            }
            addonAfter={invoiceFee.type === RevenueInputType.Money ? '$' : '%'}
            style={{ width: '100%' }}
          />
        </Descriptions.Item>
      </Descriptions>
      <Button onClick={() => handleUpdateRevenue()} type="primary" style={{ float: 'right', marginTop: 20 }}>
        Update
      </Button>

      <div style={{ width: '100%' }}>
        {/* <Row gutter={[16, 8]}>
          <Col span={8}>
            <h4>{t('REVENUE')}</h4>
          </Col>
          <Col span={16}>
            <Tag color="#f50">{formatNumber(myRevenue)}</Tag>
          </Col>
        </Row> */}
      </div>
    </div>
  );
});

export default ConfigOption;
