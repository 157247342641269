import '@ant-design/compatible/assets/index.css';

import { Avatar, Button, Col, Input, Row } from 'antd';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React from 'react';
import useForm from 'react-hook-form';
import * as yup from 'yup';

import { Form } from '@ant-design/compatible';
import { EnvironmentFilled, MailOutlined, PhoneOutlined, UserOutlined } from '@ant-design/icons';

import UIField from '~components/UI/UIField';
import UIUpload from '~components/UI/UIUpload';
import { useStore } from '~context/react-context';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

import store from './store';

export interface FormValues {
  name?: string;
  address?: string;
  email?: string;
  phone?: string;
  avatarLink?: string;
  activeDay?: Date;
}

const validationSchema = yup.object().shape<FormValues>({
  name: yup.string().required('Tên đầy đủ là bắt buộc!'),
  address: yup.string().nullable(),
  phone: yup.string().nullable(),
  activeDay: yup.date()
});

const UserInfomation = observer(() => {
  const {
    userStore: { currentUser }
  } = useStore();
  const { t } = useTranslation();

  const { setUserAvatar, userAvatar, handleUpdate } = store;

  const initialValues: FormValues = {
    name: get(currentUser, 'name', ''),
    email: get(currentUser, 'email', ''),
    address: get(currentUser, 'address', ''),
    phone: get(currentUser, 'phone', ''),
    avatarLink: get(currentUser, 'avatarLink', '')
  };

  const formProps = useForm<FormValues>({ validationSchema });

  const { handleSubmit } = formProps;

  const onSubmit = async (values) => {
    const { name, phone, address } = values;
    handleUpdate({ name, phone, address });
  };

  const handleOnChangeAvatar = (fileList) => {
    if (fileList.length <= 0) return;
    const avatar = fileList[fileList.length - 1];
    setUserAvatar(avatar.url);
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={24} md={{ push: 16, span: 8 }} lg={{ push: 10, span: 4 }}>
          <Avatar size={150} icon={<UserOutlined />} src={userAvatar || initialValues.avatarLink} />
          <Row style={{ margin: '10px 0 0 0' }}>
            <UIUpload
              key="upload-avatar"
              label=""
              bannerId={currentUser._id}
              quantity={1}
              handleChange={(fileList) => {
                handleOnChangeAvatar(fileList);
              }}
              defaultFileList={[]}
              listType="picture"
              width={200}
              height={200}
              accept="image/*"
              showUploadList={false}
              showButton
              buttonText="Change Avatar"
            />
          </Row>
        </Col>
        <Col xs={24} md={{ pull: 8, span: 16 }} lg={{ pull: 4, span: 10 }}>
          <div style={{ padding: '5px', width: '100%' }}>
            <h1>Thông tin cá nhân</h1>
            <Form layout="vertical" onSubmit={handleSubmit(onSubmit)} id="accountForm">
              <UIField
                {...formProps}
                name="name"
                label="Full Name"
                fieldProps={{
                  name: 'name',
                  type: 'text',
                  placeholder: t('NAME'),
                  prefix: <UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
                }}
                fieldComponent={Input}
                defaultValue={initialValues.name}
              />
              <UIField
                {...formProps}
                name="phone"
                label="Phone"
                fieldProps={{
                  name: 'phone',
                  type: 'text',
                  maxLength: 10,
                  placeholder: t('PHONE'),
                  prefix: <PhoneOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
                }}
                fieldComponent={Input}
                defaultValue={initialValues.phone}
              />
              <UIField
                {...formProps}
                name="email"
                label="Email"
                fieldProps={{
                  name: 'email',
                  type: 'text',
                  disabled: true,
                  placeholder: t('EMAIL'),
                  prefix: <MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
                }}
                fieldComponent={Input}
                defaultValue={initialValues.email}
              />
              <UIField
                {...formProps}
                name="address"
                label="Address"
                fieldProps={{
                  name: 'address',
                  type: 'text',
                  placeholder: t('ADDRESS'),
                  prefix: <EnvironmentFilled style={{ color: 'rgba(0,0,0,.25)' }} />
                }}
                fieldComponent={Input}
                defaultValue={initialValues.address}
              />
              <Button htmlType="submit" type="primary" form="accountForm">
                {t('SAVE')}
              </Button>
            </Form>
          </div>
        </Col>
      </Row>
    </>
  );
});

export default UserInfomation;
