import { Button, Dropdown, Menu, Modal } from 'antd';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ReactResizeDetector from 'react-resize-detector';

import { ColDef } from '@ag-grid-community/core';
import { DeleteOutlined, EditOutlined, EllipsisOutlined } from '@ant-design/icons';
import {
  AUTHORIZED_ROLES_DELETE_MODEL_DEVICE,
  AUTHORIZED_ROLES_UPDATE_MODEL_DEVICE,
  ROLE_DELETE_COMMON,
  ROLE_UPDATE_COMMON
} from '@source/common';

import Authorized from '~components/Authorized/Authorized';
import AgGridServer from '~components/UI/AgGrid/AgGridServer';
import { useGraphQLSDK } from '~graphql/GraphQLSDKProvider';

import store from './store';

const { confirm } = Modal;

const ModelDeviceGrid: FC = observer(() => {
  const { handleGridReady, setModalVisible, remove } = store;
  const { t } = useTranslation();
  const sdk = useGraphQLSDK();

  const showDeleteConfirm = (value, text) => {
    confirm({
      title: (
        <div>
          {t('ARE_YOU_SURE_DELETE')} <b>{text}</b>
        </div>
      ),
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        remove(value);
      }
    });
  };
  const columnDefs: ColDef[] = [
    {
      headerName: '',
      field: '_id',
      cellClass: 'text-center',
      minWidth: 70,
      maxWidth: 70,
      cellRendererFramework: ({ data, value }) => {
        const moreActions = (
          <Menu>
            <Menu.Item
              key="delete"
              style={{ color: 'red' }}
              onClick={() => showDeleteConfirm(value, data.text)}
            >
              <DeleteOutlined style={{ color: 'red' }} />
              {t('DELETE')}
            </Menu.Item>
          </Menu>
        );

        return (
          <Button.Group size="small">
            <Authorized roles={[ROLE_UPDATE_COMMON]}>
              <Button
                icon={<EditOutlined />}
                type="primary"
                onClick={() => setModalVisible(data)}
              />
            </Authorized>
            <Authorized roles={[ROLE_DELETE_COMMON]}>
              <Dropdown overlay={moreActions}>
                <Button icon={<EllipsisOutlined />} type="default" />
              </Dropdown>
            </Authorized>
          </Button.Group>
        );
      }
    },
    {
      headerName: t('NO'),
      field: '_id',
      minWidth: 50,
      maxWidth: 50,
      cellRenderer: ({ rowIndex }) => rowIndex + 1
    },
    {
      headerName: t('TEXT'),
      field: 'text'
    },
    {
      headerName: t('VALUE'),
      field: 'value'
    }
  ];

  return (
    <ReactResizeDetector handleHeight>
      {({ height }) => {
        return (
          <AgGridServer
            options={{
              columnDefs,
              cacheBlockSize: 50,
              paginationPageSize: 50
            }}
            onQuery={sdk.findManyCommon}
            queryKey="findManyCommon"
            onCountQuery={sdk.countCommon}
            countQueryKey="countCommon"
            onGridReady={handleGridReady}
            where={{ type: 'model_device' }}
            height={height - 80}
          />
        );
      }}
    </ReactResizeDetector>
  );
});

export default ModelDeviceGrid;
