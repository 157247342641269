import i18next from 'i18next';
import { debounce, merge, throttle } from 'lodash';
import { action, observable, runInAction } from 'mobx';
import { actionAsync, task } from 'mobx-utils';

import { GridReadyEvent } from '@ag-grid-community/core';
import { FilterSelect, WhereArgs } from '@source/common';

import authStore from '~common-stores/authStore';
import { ErrorNotify, SuccessNotify } from '~components/UI/Notification';
import { useConfig } from '~config/ConfigProvider';
import {
  Equipment,
  EquipmentInput,
  EquipmentUpdateArgs,
  FullEquipmentFragment
} from '~graphql/_sdk';
import { getSdk } from '~graphql/sdk';

class GridStore {
  @observable public loading: boolean;
  @observable public isModalVisible: boolean;
  @observable public where: WhereArgs;
  @observable public equipments: Equipment[];
  @observable public selectedItem: FullEquipmentFragment;
  @observable public Equipments: FullEquipmentFragment;
  @observable public initFilterConfig: FilterSelect[];
  @observable public fromLocation: string | undefined | null;
  @observable public ownerType: string;

  constructor() {
    runInAction(() => {
      this.selectedItem = null;
      this.loading = false;
      this.isModalVisible = false;
      this.where = {};
      this.fromLocation = null;
      this.ownerType = null;
      this.where = {};
    });
  }

  @action
  public setFilter = throttle(
    (where: WhereArgs) => {
      if (this.where._search) {
        runInAction(() => {
          this.where = { ...merge(this.where, where), _search: this.where._search };
        });
      } else {
        runInAction(() => {
          this.where = merge(this.where, where);
        });
      }
      this.reload();
    },
    300,
    { leading: false, trailing: true }
  );

  @action
  toggleModal = (item: FullEquipmentFragment = null, fromLocation?: string) => {
    this.selectedItem = item;
    if (fromLocation) {
      this.fromLocation = fromLocation;
    } else {
      this.fromLocation = null;
    }
    this.isModalVisible = !this.isModalVisible;
    if (!this.isModalVisible) {
      this.selectedItem = null;
    }
  };

  @actionAsync
  public create = async (record: EquipmentInput) => {
    const sdk = await task(getSdk());
    const {
      createEquipment: { error }
    } = await task(sdk.createEquipment({ record }));

    if (error) {
      ErrorNotify(error.code);
    } else {
      SuccessNotify(i18next.t('CREATE'));
      this.reload();
    }
  };

  @actionAsync
  update = async (_id: string, record: EquipmentUpdateArgs) => {
    const sdk = await task(getSdk());
    const {
      updateEquipmentById: { error }
    } = await task(sdk.updateEquipmentById({ _id, record }));

    if (error) {
      ErrorNotify(error.code);
    } else {
      SuccessNotify(i18next.t('UPDATE'));
      this.reload();
    }
  };

  @actionAsync
  remove = async (_id: string) => {
    const sdk = await task(getSdk());
    const {
      removeEquipmentById: { error }
    } = await task(
      sdk.removeEquipmentById({
        _id
      })
    );

    if (error) {
      ErrorNotify(error.code);
    } else {
      SuccessNotify(i18next.t('DELETE'));
      this.reload();
    }
  };

  @action
  public searchFilter = debounce(
    (text: string) => {
      runInAction(() => {
        Object.assign(this.where, { _search: text });
        this.reload();
      });
    },
    400,
    { leading: false, trailing: true }
  );

  @action
  public handleGridReady = ({ api }: GridReadyEvent) => {
    this.reload = () => {
      api.onFilterChanged();
    };
  };

  @action
  public reload = () => undefined;

  @action
  setWhere = (where) => {
    this.where = { ...this.where, ...where };
  };

  @action
  public resetFilter = () => {
    this.where = {};
  };

  @action
  public setOwnerType = (ownerType: string) => {
    this.ownerType = ownerType;
    this.setWhere({ ownerType });
    this.reload();
  };

  public downloadBanner = async (equipmentId: string) => {
    const { restApiEndpoint } = await useConfig();
    const { token } = authStore;

    window.open(
      `${restApiEndpoint}/equipments/dowload-login-page/${equipmentId}?token=${token}`,
      '_blank'
    );
  };
}
export default new GridStore();
