import { Button, Input, PageHeader } from 'antd';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { AUTHORIZED_ROLES_CREATE_COMMON, ROLE_CREATE_COMMON } from '@source/common';

import Authorized from '~components/Authorized/Authorized';

import store from './store';

const CommonHeader: FC = observer(() => {
  const  {t} = useTranslation();
  const { setSearchText, setModalVisible } = store;

  return (
    <PageHeader
      style={{ background: '#fff', marginBottom: 0 }}
      title=""
      extra={[
        <Input
          key="search"
          suffix={<SearchOutlined />}
          placeholder={t('SEARCH')}
          allowClear 
          onChange={e => {
            setSearchText(e.target.value);
          }}
          style={{ width: '200px', marginRight: '8px' }}
        />,
        <Authorized roles={[ROLE_CREATE_COMMON]} key="btn-group">
          <Button.Group>
            <Button key="create" type="primary" icon={<PlusOutlined />} onClick={() => setModalVisible()}>
              {t('CREATE')}
            </Button>
          </Button.Group>
        </Authorized>
      ]}
    />
  );
});

// <Filter dateFields={dateFields} filterSelect={initFilterConfig} />

export default CommonHeader;
