import i18next from 'i18next';
import { observable, runInAction } from 'mobx';
import { actionAsync, task } from 'mobx-utils';

import { ErrorNotify, SuccessNotify } from '~components/UI/Notification';
import { getSdk } from '~graphql/sdk';

class Store {
  @observable checkError: boolean;

  constructor(){
    runInAction(()=>{
      this.checkError = false;
    })
  }
  @actionAsync
  restorePassword = async (_id: string, newPass: string, verifyPass: string) => {
    const sdk = await task(getSdk());
    const {
      restorePassword: { error }
    } = await task(sdk.restorePassword({ _id, newPass, verifyPass }));

    if (error){
      ErrorNotify(error.code);
      return false;
    }
    
      SuccessNotify(i18next.t('NOTI_RESTORE_PASSWORD'))
      return true;
    ;
  };
}

export default new Store();
