import '@ant-design/compatible/assets/index.css';

import { Col, Descriptions, Modal, Row, Select } from 'antd';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { FC, useState } from 'react';
import useForm from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Form } from '@ant-design/compatible';
import { ROLE_READ_ALL } from '@source/common';

import Loading from '~components/UI/Loading';
import { ErrorNotify } from '~components/UI/Notification';
import { UISelect, UISelectProps } from '~components/UI/Select/UISelect';
import UIField from '~components/UI/UIField';
import { UIInputNumber } from '~components/UI/UIInputNumber';
import { useStore } from '~context/react-context';
import { DATE_FORMAT, format } from '~utils/formatDate';

import store from './store';

interface FormValues {
  additionalKpi?: number;
  reportLocationId?: string;
}

interface Props {
  visible: boolean;
  setModalVisible: () => void;
}

const { Item } = Form;

const EditRender: FC<Props> = observer(({ visible, setModalVisible }) => {
  const { t } = useTranslation();
  const {
    selectedItem,
    availableBooking,
    availableBookingLoading,
    setKpiLocation,
    addKpiManagement,
    reportLocations,
    loadAvailableBooking,
    handleCampaignSelect
  } = store;
  const {
    userStore: { currentUser }
  } = useStore();

  const [locationId] = useState();

  const display =
    selectedItem && selectedItem.locationId === selectedItem.reportLocationId ? 'none' : 'block';

  const formProps = useForm({});

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting }
  } = formProps;

  const onCancel = () => {
    reset();
    setModalVisible();
  };

  const viewReportLocationId = currentUser.profile.roleIds.includes(ROLE_READ_ALL);

  const onSubmit = async ({ additionalKpi, reportLocationId }: FormValues) => {
    // if (!additionalKpi && additionalKpi !== 0) {
    //   ErrorNotify('NOTI_TOTAL_BOOKING_IS_NOT_EMPTY');
    //   return;
    // }
    if (!viewReportLocationId && selectedItem?.stats?.kpiRemaining < 0) {
      ErrorNotify(t('NOTI_KPI_THAN_KPI_REMAINING'));
    }
    await addKpiManagement({
      campaignMappingId: selectedItem._id,
      additionalKpi,
      reportLocationId
    });
    handleCampaignSelect(selectedItem.campaignId);
    onCancel();
  };

  let schedule = '';
  const scheduleFrom = `From ${format(get(selectedItem, 'scheduleFrom'), DATE_FORMAT)}`;
  const scheduleTo = ` to ${format(get(selectedItem, 'scheduleTo'), DATE_FORMAT)}`;
  if (scheduleTo) {
    schedule = scheduleFrom + scheduleTo;
  } else {
    schedule = scheduleFrom;
  }

  let kpi = '';
  if (!get(selectedItem, 'completionStats.kpi')) {
    kpi = 'Unlimited';
  } else {
    kpi = get(selectedItem, 'completionStats.kpi', 0).toLocaleString();
  }

  return (
    <Modal
      title={t('KPI_MANAGEMENT')}
      visible={visible}
      cancelText="Close"
      cancelButtonProps={{ ghost: true }}
      onCancel={() => onCancel()}
      okText={selectedItem ? 'Update' : 'Create'}
      okButtonProps={{
        htmlType: 'submit',
        form: 'editForm',
        type: 'primary',
        loading: isSubmitting
      }}
    >
      <Form id="editForm" onSubmit={handleSubmit(onSubmit)} layout="vertical">
        <Descriptions bordered size="small">
          <Descriptions.Item label={t('NAME')} span={3}>
            <span>{get(selectedItem, 'location.name', '')}</span>
          </Descriptions.Item>

          <Descriptions.Item label={t('SCHEDULE')} span={3}>
            <span style={{ color: '#1890ff', marginTop: 100 }}>{schedule}</span>
          </Descriptions.Item>

          <Descriptions.Item label="BOOKING-KPI" span={2}>
            <span>
              {' '}
              {kpi} {t('CLICK')}
            </span>
          </Descriptions.Item>

          <Descriptions.Item label={t('COMPLETED')} span={2}>
            <span>
              {' '}
              {get(selectedItem, 'completionStats.click', '')} {t('CLICK')} (%)
            </span>
          </Descriptions.Item>
        </Descriptions>

        <Row style={{ display }}>
          <Col span={24}>
            {viewReportLocationId ? (
              <UIField<UISelectProps>
                {...formProps}
                name="reportLocationId"
                label={t('REPORT_LOCATION')}
                fieldProps={{
                  onChange: e => {
                    loadAvailableBooking([e], selectedItem.scheduleTo);
                  },
                  allowClear: true,
                  placeholder: t('CHOOSE_REPORT_LOCATION'),
                  disabled: !selectedItem,
                  dataOptions: {
                    data: reportLocations.filter(item => item._id !== locationId),
                    valueField: '_id',
                    textField: 'name'
                  }
                }}
                fieldComponent={UISelect}
              />
            ) : (
              <></>
            )}
          </Col>
        </Row>

        <Row gutter={8}>
          <Col span={12}>
            <Item label="Add Booking" labelAlign="left" style={{ marginBottom: 8 }}>
              <UIField
                {...formProps}
                name="additionalKpi"
                fieldProps={{
                  style: { width: '100%' },
                  placeholder: t('ADDITIONAL_KPI'),
                  onChange: e => setKpiLocation(e)
                }}
                defaultValue={0}
                fieldComponent={UIInputNumber}
              />
            </Item>
          </Col>

          {availableBookingLoading ? (
            <Loading />
          ) : (
            <Col span={12}>
              <UIField
                {...formProps}
                name="kpi"
                label="Available Booking"
                fieldProps={{
                  style: { width: '100%' },
                  placeholder: '0',
                  disabled: !selectedItem ? true : !locationId
                }}
                defaultValue={availableBooking}
                fieldComponent={UIInputNumber}
              />
            </Col>
          )}
        </Row>

        <div style={{ background: '#ccf1f7', padding: 8, marginTop: 8, borderRadius: 5 }}>
          <p>{t('KPI_CHANGE_WILL_BE_APPLIED')}</p>
        </div>
      </Form>
    </Modal>
  );
});

export default EditRender;
