import '@ant-design/compatible/assets/index.css';

import { Col, Form, Input, Modal, Row } from 'antd';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { UISelect } from '~components/UI/Select/UISelect';
import { useStore } from '~context/react-context';
import { BannerTemplateInput, BannerTemplateUpdateArg } from '~graphql/_sdk';

import store from './store';

const { TextArea } = Input;

interface FormValues {
  name?: string;
  htmlCode?: string;
  type?: string;
  options?: string;
}

const BannerTemplateForm: FC = observer(() => {
  const { t } = useTranslation();
  const { modalVisible, setModalVisible, create, update, selectedItem, loading } = store;

  const { commonDataStore } = useStore();

  const initialValues: FormValues = {
    name: get(selectedItem, 'name', ''),
    htmlCode: get(selectedItem, 'htmlCode', ''),
    type: get(selectedItem, 'type'),
    options: JSON.stringify(get(selectedItem, 'options', {}))
  };

  const onCancel = () => {
    form.resetFields();
    setModalVisible();
  };

  const onSubmit = async (record: FormValues) => {
    Object.assign(record, { options: JSON.parse(record.options) });

    if (selectedItem) {
      await update(get(selectedItem, '_id'), record as BannerTemplateUpdateArg);
    } else {
      await create(record as BannerTemplateInput);
    }
    onCancel();
  };

  const [form] = Form.useForm();
  const validateMessages = {
    required: '${label} is required!'
  };

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [selectedItem]);

  return (
    <Modal
      style={{ marginTop: -50 }}
      title={t('BANNER_TEMPLATE')}
      visible={modalVisible}
      width={1000}
      cancelText="Close"
      cancelButtonProps={{ type: 'text', ghost: true }}
      onCancel={() => onCancel()}
      okText={selectedItem ? t('UPDATE') : t('CREATE')}
      okButtonProps={{
        htmlType: 'submit',
        form: 'bannerTemplateForm',
        type: 'primary',
        loading
      }}
    >
      <Form
        id="bannerTemplateForm"
        onFinish={onSubmit}
        layout="vertical"
        initialValues={initialValues}
        validateMessages={validateMessages}
        form={form}
      >
        <Row gutter={8}>
          <Col md={12} xs={24}>
            <Row gutter={8}>
              <Col md={12} xs={24}>
                <Form.Item name="name" label={t('NAME')} rules={[{ required: true }]}>
                  <Input placeholder={t('CHOOSE_NAME')} allowClear />
                </Form.Item>
              </Col>
              <Col md={12} xs={24}>
                <Form.Item name="type" label={t('TYPE')}>
                  <UISelect
                    placeholder={t('CHOOSE_TYPE')}
                    dataOptions={{
                      data: commonDataStore.bannerTemplateType,
                      valueField: 'value',
                      textField: 'text'
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item name="options" label={t('OPTIONS')} rules={[{ required: true }]}>
              <TextArea placeholder={t('INPUT_OPTION')} allowClear rows={16} />
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item name="htmlCode" label={t('HTML_CODE')} rules={[{ required: true }]}>
              <TextArea placeholder={t('HTML_CODE')} allowClear rows={20} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
});

export default BannerTemplateForm;
