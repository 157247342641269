import './css.less';

import { Col, Layout, Row } from 'antd';
import { intersection } from 'lodash';
import React, { FC, useEffect } from 'react';

import { ACTIVE, COMING_SOON, ROLE_READ_ALL, ROLE_SUPERADMIN } from '@source/common';

import { useStore } from '~context/react-context';

import ReportFilter from './Filter';
import StatisticComponent from './Statistic';
import store from './store';
import { TabRPLocation } from './TabRPLocation';

const ReportLocationsScreen: FC = () => {
  const { commonDataStore, userStore } = useStore();
  const { initCommonData, reset, loadCustomer, setCustomerId } = store;

  useEffect(() => {
    commonDataStore
      .loadAllLocations()
      .finally(() => {
        initCommonData(commonDataStore.locations, 'locations');
        commonDataStore.loadLocationCategory().finally(() => {
          initCommonData(commonDataStore.categories, 'categories');
        });
      });

    commonDataStore.loadCommonArea().finally(() => {
      initCommonData(commonDataStore.commonArea, 'areas');
    });

    commonDataStore.loadCommonCity().finally(() => {
      initCommonData(commonDataStore.commonCity, 'cities');
    });

    commonDataStore.loadCommonDistrict().finally(() => {
      initCommonData(commonDataStore.commonDistrict, 'districts');
    });

    commonDataStore.loadCommonTags().finally(() => {
      initCommonData(commonDataStore.tagsType, 'tags');
    });

    if (intersection(userStore.roleIds, [ROLE_SUPERADMIN, ROLE_READ_ALL]).length > 0) {
      loadCustomer().then(() => {
        setCustomerId(userStore.currentUser.customerId);
      });
    } else {
      setCustomerId(userStore.currentUser.customerId);
    }

    return () => reset();
  }, []);

  return (
    <Layout style={{ background: '#fff', padding: 8 }}>
      <Row gutter={[0, 8]} style={{ height: '100%', flexFlow: 'column nowrap', marginBottom: 0 }}>
        <Col flex="0 0 auto">
          <ReportFilter />
        </Col>
        <Col flex="0 0 auto">
          <StatisticComponent />
        </Col>
        <Col flex="1 1 auto">
          <TabRPLocation />
        </Col>
      </Row>
    </Layout>
  );
};

export default ReportLocationsScreen;
