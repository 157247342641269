/* eslint-disable radix */
import './RevenueConfig.less';

import { Table } from 'antd';
import i18next from 'i18next';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import ReactResizeDetector from 'react-resize-detector';

import { formatNumber } from '~utils/formatNumber';

import store from '../store';
import EditableCell from './EditCellRender';

const MGNRevenue: FC = observer(() => {
  const { mgnRevenueTableData, changeMappingCategoryLocation } = store;

  const columnsDef = [
    { title: 'Category', dataIndex: 'name', key: 'name', width: 200 },
    {
      title: i18next.t('KPI'),
      dataIndex: 'kpiBooked',
      key: 'kpiBooked',
      width: 120,
      render: (text, record) =>
        record.key.split('_&_')[0] !== 'category' ? (
          <EditableCell
            record={record}
            value={text}
            editable
            editField="kpiBooked"
            handleSave={(key, value, editField, editedValue) => {
              changeMappingCategoryLocation(key, value, editField, editedValue);
            }}
          />
        ) : (
          <span style={{ float: 'right' }}>{formatNumber(text)}</span>
        )
    },
    {
      title: 'Click',
      dataIndex: 'kpiRunned',
      key: 'kpiRunned',
      width: 100,
      render: text => <span style={{ float: 'right' }}>{formatNumber(text)}</span>
    },
    {
      title: i18next.t('UNIT_PRICE'),
      dataIndex: 'unitPrice',
      key: 'unitPrice',
      width: 120,
      render: (text, record) => (
        <EditableCell
          record={record}
          value={text}
          editable
          editField="unitPrice"
          handleSave={(key, value, editField, editedValue) => {
            changeMappingCategoryLocation(key, value, editField, editedValue);
          }}
        />
      )
    },
    {
      title: i18next.t('TOTAL_AMOUNT'),
      dataIndex: 'total',
      key: 'total',
      width: 160,
      render: text => <span style={{ float: 'right' }}>{formatNumber(text)}</span>
    }
  ];

  return (
    <ReactResizeDetector handleHeight>
      {({ height }) => {
        const tableHeight = height ?? 500;
        return (
          <Table
            size="small"
            style={{ position: 'relative' }}
            bordered
            columns={columnsDef}
            dataSource={mgnRevenueTableData}
            pagination={false}
            scroll={{ y: tableHeight - 60 }}
            expandable={{
              defaultExpandAllRows: true
            }}
            rowClassName={record => {
              if (record.children) {
                return 'row-expandeble';
              }
              return 'custom-config';
            }}
          />
        );
      }}
    </ReactResizeDetector>
  );
});

export default MGNRevenue;
