import { Dropdown, Menu } from 'antd';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { GlobalOutlined } from '@ant-design/icons';

import { flexCenter } from '~assets/css-classes';
import england from '~assets/images/england.svg';
import vietnam from '~assets/images/vietnam.svg';
import { dropdownMenu_header } from '~components/Layout/css';

const { Item } = Menu;

const LangMenu: FC = props => {
  const { t, i18n } = useTranslation();

  const handleLangChange = (lang: string) => {
    if (!('value' in props)) {
      i18n.changeLanguage(lang);
    }
    window.location.reload();
  };

  const langMenu = (
    <Menu theme="light">
      <Item key="vn" onClick={() => handleLangChange('vn')}>
        <span role="img" aria-label="VNE" style={{ marginRight: '8px' }}>
          <img src={vietnam} alt="" width={16} height={16} />
        </span>
        {t('VN')}
      </Item>
      <Item key="en" onClick={() => handleLangChange('en')}>
        <span role="img" aria-label="ENG" style={{ marginRight: '8px' }}>
          <img src={england} alt="" width={16} height={16} />
        </span>
        {t('EN')}
      </Item>
    </Menu>
  );

  return (
    <Dropdown overlay={langMenu} className={dropdownMenu_header} trigger={['click']}>
      <span className={flexCenter}>
        <GlobalOutlined style={{ fontSize: '16px', verticalAlign: 'middle' }} />
      </span>
    </Dropdown>
  );
};

export default LangMenu;
