import { Button, Descriptions, Modal, Spin, Tag, Typography } from 'antd';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ColDef } from '@ag-grid-community/core';
import { DeleteRowOutlined } from '@ant-design/icons';

import AgGridClient from '~components/UI/AgGrid/AgGridClient';
import { ErrorNotify, SuccessNotify } from '~components/UI/Notification';
import { DATE_FORMAT, format } from '~utils/formatDate';
import { formatNumber } from '~utils/formatNumber';

import store from './store';

interface Props {
  visible: boolean;
  setModalVisible: () => void;
}
const { Text } = Typography;

const ApproveRender: FC<Props> = observer(({ visible, setModalVisible }) => {
  const { t } = useTranslation();
  const {
    selectedItem,
    getAvailableBooking,
    selectedAvailableBooking,
    handleApproveCampaign,
    locationBookingType,
    resetAvailableBooking,
    loadingForm,
    removeRow
  } = store;

  useEffect(() => {
    getAvailableBooking();
    return resetAvailableBooking();
  }, []);

  const handleApprove = async (allowed: boolean, text: string) => {
    const res = await handleApproveCampaign(
      store.selectedItem._id,
      allowed,
      store.selectedItem.locations
    );

    if (res.data) {
      SuccessNotify(text);
    } else {
      ErrorNotify(text);
    }

    setModalVisible();
    store.reload();
  };

  const footer = [
    <Button key="cancle" onClick={() => setModalVisible()}>
      {t('notify.CANCLE')}
    </Button>,
    <Button
      key="disapprove"
      danger
      onClick={() => handleApprove(false, t('DISAPPROVE'))}
      loading={store.loading}
    >
      {t('DISAPPROVE')}
    </Button>,
    <Button
      key="approve"
      type="primary"
      onClick={() => handleApprove(true, t('APPROVE'))}
      loading={store.loading}
    >
      {t('APPROVE')}
    </Button>
  ];

  const dataSource =
    selectedItem && selectedItem.locations
      ? selectedItem.locations.map(loc => {
          return {
            locationId: loc.locationId,
            location: loc.locationName,
            category: loc.categoryName,
            kpi: loc.kpi || 0,
            availableKpi: selectedAvailableBooking ? selectedAvailableBooking[loc.locationId] : 0,
            bookingType: locationBookingType ? locationBookingType[loc.locationId] : 'normal'
          };
        })
      : [];

  const columnDefs: ColDef[] = [
    {
      headerName: 'Category',
      field: 'category',
      rowGroup: true,
      sortable: true,
      hide: true,
      minWidth: 100,
      maxWidth: 100
    },
    {
      headerName: 'Location Name',
      field: 'location',
      sortable: true,
      resizable: true,
      minWidth: 200,
      cellRendererFramework: ({ value, data }) => {
        if (!data) {
          return null;
        }

        if (data.bookingType && data.bookingType !== 'normal') {
          return (
            <>
              <Text type="warning">{value}</Text>
            </>
          );
        }
        return value;
      }
    },
    {
      headerName: 'Kpi',
      field: 'kpi',
      sortable: true,
      sort: 'asc',
      minWidth: 100,
      maxWidth: 100,
      aggFunc: 'sum',
      enableValue: true,
      cellClass: 'text-right',
      cellRenderer: ({ value }) => formatNumber(value || 0)
    },
    {
      headerName: 'Available',
      field: 'availableKpi',
      sortable: true,
      minWidth: 120,
      maxWidth: 120,
      aggFunc: 'sum',
      enableValue: true,
      cellClass: 'text-right',
      cellRenderer: ({ value }) => formatNumber(value || 0)
    }
  ];

  return (
    <Modal
      title={`${t('APPROVE_CAMPAIGN')}: ${get(selectedItem, 'name')}`}
      closable
      visible={visible}
      footer={footer}
      onCancel={() => setModalVisible()}
      width={800}
      style={{ marginTop: -50 }}
    >
      <Spin spinning={loadingForm}>
        <Descriptions column={3} bordered>
          <Descriptions.Item label={t('SCHEDULE')}>
            {format(get(selectedItem, 'scheduleFrom'), DATE_FORMAT)} -
            {format(get(selectedItem, 'scheduleTo'), DATE_FORMAT)}
          </Descriptions.Item>
          <Descriptions.Item label={t('KPI')}>
            {formatNumber(get(selectedItem, 'kpi'))}
          </Descriptions.Item>
          <Descriptions.Item label={t('STATUS')}>
            <Tag color="#f50">{get(selectedItem, 'status', '').toUpperCase()}</Tag>
          </Descriptions.Item>
          <Descriptions.Item label={t('TIMES')}>
            <Tag color="#f50">{get(selectedItem, 'times', '')}</Tag>
          </Descriptions.Item>
        </Descriptions>

        <br />
        <AgGridClient
          columnDefs={columnDefs}
          rowData={dataSource}
          height={500}
          animateRows
          autoGroupColumnDef={{ minWidth: 170, maxWidth: 200 }}
          suppressAggFuncInHeader
          statusBar={{
            statusPanels: [
              {
                statusPanel: 'agTotalRowCountComponent',
                align: 'left',
                key: 'totalRowComponent'
              }
            ]
          }}
        />
      </Spin>
    </Modal>
  );
});

export default ApproveRender;
