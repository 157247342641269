import { action, observable, runInAction } from 'mobx';
import { actionAsync, task } from 'mobx-utils';

import { getSdk } from '~graphql/sdk';

export class SessionStore {
  @observable public todayLogin: any;
  @observable public onlineSession: any;
  @observable public loading: boolean;
  @observable public loadingChart: boolean;
  private initialized: boolean;

  constructor() {
    runInAction(() => {
      this.todayLogin = [];
      this.onlineSession = [];
    });
  }

  @action
  public init = (forceReinit = false) => {
    if (this.initialized && !forceReinit) return;
    this.loading = true;
    this.fetchData().finally(
      action(() => {
        this.loading = false;
        this.initialized = true;
      })
    );
  };

  @actionAsync
  public getReportTodayLogin = async () => {
    this.loadingChart = true;
    const sdk = await task(getSdk());

    const { getReportTodayLogin } = await sdk.getReportTodayLogin();

    this.todayLogin = getReportTodayLogin;
    this.loadingChart = false;
  };

  @actionAsync
  public countReportOnlineSession = async () => {
    const sdk = await task(getSdk());

    const { countReportOnlineSession } = await sdk.countReportOnlineSession();

    return { countReportOnlineSession };
  };

  public fetchData = async () => {
    await this.getReportTodayLogin();
  };
}

export default new SessionStore();
