import { isArray } from 'lodash';
import { useLocation } from 'react-router-dom';

import { routes } from '~screens/routes';

export const useRoute = () => {
  const location = useLocation();
  const newRoutes = [];
  routes.forEach((route) => {
    if (route.children?.length) {
      route.children.forEach((child) => {
        newRoutes.push(child);
      });
    } else {
      newRoutes.push(route);
    }
  });

  return newRoutes.filter((item) => item.path === location.pathname).pop();
};
