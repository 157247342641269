import '@ant-design/compatible/assets/index.css';

import { Col, Modal, Row } from 'antd';
import { InputNumberProps } from 'antd/lib/input-number';
import i18next from 'i18next';
import { differenceBy } from 'lodash';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { FC, useState } from 'react';
import useForm from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { Form } from '@ant-design/compatible';
import { ROLE_READ_ALL } from '@source/common';

import { UISelect, UISelectProps } from '~components/UI/Select/UISelect';
import { UIDatePicker } from '~components/UI/UIDatePicker';
import UIField from '~components/UI/UIField';
import { UIInputNumber } from '~components/UI/UIInputNumber';
import { useStore } from '~context/react-context';

import store from './store';

interface FormValues {
  locationId?: string;
  reportLocationId?: string;
  scheduleFrom?: Date;
  kpi?: number;
}

const validationSchema = yup.object().shape<FormValues>({
  locationId: yup.string().required(i18next.t('LOCATION_IS_REQUIRED')),
  reportLocationId: yup.string()
});

interface Props {
  visible: boolean;
  setModalVisible: () => void;
}

const CreateRender: FC<Props> = observer(({ visible, setModalVisible }) => {
  const { t } = useTranslation();
  const {
    campaignEndDate,
    create,
    selectedItem,
    reportLocations,
    availableBooking,
    loadAvailableBooking,
    setKpiLocation,
    campaignMapping
  } = store;
  const [locationId, setLocationId] = useState();
  const {
    commonDataStore,
    userStore: { currentUser }
  } = useStore();

  const formProps = useForm({ validationSchema });
  const {
    reset,
    setValue,
    handleSubmit,
    formState: { isSubmitting }
  } = formProps;

  const handleLocationChange = value => {
    setLocationId(value);
    setValue('locationId', value);
    if (campaignEndDate) {
      loadAvailableBooking([value], new Date(campaignEndDate));
    }
  };

  const onCancel = () => {
    reset();
    setModalVisible();
  };

  const onSubmit = async (record: FormValues) => {
    await create(record);
    onCancel();
  };

  const currentLocations = campaignMapping.map(item => ({
    _id: item.locationId.toString(),
    name: item.locationName
  }));

  const filterLocations = differenceBy(commonDataStore.locations, currentLocations, '_id');
  const viewReportLocationId = currentUser.profile.roleIds.includes(ROLE_READ_ALL);

  return (
    <Modal
      title={t('KPI_MANAGEMENT')}
      visible={visible}
      cancelText="Close"
      cancelButtonProps={{ danger: true, ghost: true }}
      onCancel={() => onCancel()}
      okText={selectedItem ? 'Update' : 'Create'}
      okButtonProps={{
        htmlType: 'submit',
        form: 'createForm',
        type: 'primary',
        loading: isSubmitting
      }}
    >
      <Form id="createForm" onSubmit={handleSubmit(onSubmit)} layout="vertical">
        <UIField<UISelectProps>
          {...formProps}
          name="locationId"
          label={t('LOCATION')}
          fieldProps={{
            placeholder: t('CHOOSE_LOCATION'),
            showSearch: true,
            onSelect: value => handleLocationChange(value),
            disabled: !!selectedItem,
            dataOptions: {
              data: filterLocations,
              valueField: '_id',
              textField: 'name'
            }
          }}
          fieldComponent={UISelect}
        />
        {viewReportLocationId ? (
          <UIField<UISelectProps>
            {...formProps}
            name="reportLocationId"
            label={t('REPORT_LOCATION')}
            fieldProps={{
              allowClear: true,
              placeholder: t('CHOOSE_REPORT_LOCATION'),
              disabled: selectedItem ? true : !locationId,
              dataOptions: {
                data: reportLocations.filter(item => item._id !== locationId),
                valueField: '_id',
                textField: 'name'
              }
            }}
            fieldComponent={UISelect}
          />
        ) : (
          <></>
        )}

        <Row gutter={8}>
          <Col span={12}>
            <UIField
              {...formProps}
              name="scheduleFrom"
              label={t('SCHEDULE_FROM')}
              fieldProps={{
                style: { width: '100%' },
                placeholder: t('DATE'),
                onChange: value => {
                  setValue('scheduleFrom', value);
                },
                disabledDate: current => current && current < moment(new Date()).startOf('days')
              }}
              defaultValue={moment(new Date())
                .add(1, 'days')
                .startOf('days')
                .valueOf()}
              fieldComponent={UIDatePicker}
            />
          </Col>
          <Col span={12}>
            <UIField
              label={t('SCHEDULE_TO')}
              name="scheduleTo"
              {...formProps}
              fieldProps={{
                style: { width: '100%' },
                placeholder: t('DATE'),
                onChange: value => {
                  setValue('scheduleTo', value);
                  loadAvailableBooking([locationId], value);
                  // SetScheduleTo(value);
                }
                // disabledDate: (current) =>
                //   current &&
                //   (current > moment(campaignEndDate).endOf('day') ||
                //     current < moment(new Date()).add(1, 'days').startOf('days'))
              }}
              defaultValue={campaignEndDate}
              fieldComponent={UIDatePicker}
            />
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <UIField<InputNumberProps>
              {...formProps}
              style={{ marginBottom: '8px' }}
              name="kpi"
              label="KPI"
              fieldProps={{
                placeholder: 'Input KPI',
                min: 0,
                onChange: value => {
                  setKpiLocation(value);
                }
              }}
              defaultValue={0}
              fieldComponent={UIInputNumber}
            />
          </Col>
          <Col span={12} style={{ marginTop: 49 }}>
            <span>{t('AVAILABLE_KPI')}: </span>
            <span style={{ color: '#1E90FF' }}>
              {availableBooking} {t('CLICK')}
            </span>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
});

export default CreateRender;
