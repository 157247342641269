
import './AgGridTable.less';

import { observer } from 'mobx-react';
import React, { FC, ReactNode, Suspense } from 'react';

import { GridOptions, GridReadyEvent } from '@ag-grid-community/core';
import { AgGridReactProps } from '@ag-grid-community/react';

import Loading from '../Loading';
import AgGridReact from './AgGridReact.lazy';

interface Props extends AgGridReactProps {
  title?: ReactNode;
  height?: string | number;
  loading?: boolean;
}

export const defaultOptions: GridOptions = {
  defaultColDef: {
    menuTabs: [],
    suppressColumnsToolPanel: true,
    suppressFiltersToolPanel: true,
    resizable: true
  },
  animateRows: true,
  suppressContextMenu: true,
  suppressRowClickSelection: true,
  masterDetail: true,
  loadingOverlayComponentFramework: Loading,
  suppressMovableColumns: true,
  pagination: false,
  paginationPageSize: 50,
  rowHeight: 40,
  headerHeight: 40,
  getRowNodeId: row => row.id || row._id,
  statusBar: {
    statusPanels: [
      {
        statusPanel: 'agTotalRowCountComponent',
        align: 'left',
        key: 'totalRowComponent',
      }
    ]
  }
};

const AgGridClient: FC<Props> = observer(props => {
  const { title, height, ...remainingProps } = props;
  const onTableReady = (event: GridReadyEvent) => {
    const { onGridReady } = props;

    if (typeof onGridReady === 'function') {
      onGridReady(event);
    }
  };

  const autoResize = (event: GridReadyEvent) => {
    return event?.api?.sizeColumnsToFit();
  };

  return (
    <Suspense fallback={<Loading />}>
      <div className="ag-theme-material" style={{ height: height || 200 }}>
        {title}
        <AgGridReact
          onGridReady={onTableReady}
          onFirstDataRendered={autoResize}
          onGridSizeChanged={autoResize}
          onColumnEverythingChanged={autoResize}
          {...defaultOptions}
          {...remainingProps}
        />
      </div>
    </Suspense>
  );
});

export default AgGridClient;
