import './css.less';

import { Col, Row, Select, Tooltip } from 'antd';
import { isEmpty, uniqBy } from 'lodash';
import { observer } from 'mobx-react';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import shortid from 'shortid';

import { MGN, prop } from '@source/common';

import { commonDataStore } from '~common-stores/store-index';
import { filterLocations } from '~components/UI/Select/SelectTemplates';
import { UISelect } from '~components/UI/Select/UISelect';
import { UIButtonExportExcel } from '~components/UI/UIButtonExportExcel';
import { UIDateRangePicker } from '~components/UI/UIDateRangePicker';
import { useStore } from '~context/react-context';
import useQuery from '~utils/useQuery';

import store from './store';

const colSpan = {
  sm: 24,
  xs: 24,
  flex: 'auto'
};

interface ExportProps {
  exportExcel: boolean;
}


const LocationRPFilter: FC<ExportProps> = observer(exportExcel => {
  const {
    initData,
    categoryLoading,
    handleOnChange,
    getCategories,
    city,
    districtByCity,
    tmp,
    locationLoading,
    loadAllLocations,
    categories,
    locations,
    date,
    cleanUp,
    valueDistrict,
    loadSharedTrafficCustomer,
    where
  } = store;

  const { Option } = Select;
  const {
    // commonDataStore: { loadCommonTags, tagsType },
    userStore: { currentUser },
    authStore
  } = useStore();

  const currentTab = useQuery().get('tab') || undefined;
  const [cityLoading, setcityLoading] = useState(true);
  // const [tagLoading, settagLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    commonDataStore.loadCommonCity().finally(() => {
      initData(commonDataStore.commonCity, 'city');
      setcityLoading(false);
    });
    getCategories();
    commonDataStore.loadCommonDistrict().finally(() => {
      initData(commonDataStore.commonDistrict, 'disctrict');
    });
    loadAllLocations();
    loadSharedTrafficCustomer();
    // loadCommonTags().finally(() => {
    //   settagLoading(false);
    // });
    return () => {
      cleanUp();
    };
  }, []);

  return (
    <Row gutter={[8, 8]}>
      <Col sm={24} md={6}>
        <UIDateRangePicker
          size="middle"
          allowClear={false}
          style={{ width: '100%', fontSize: 13 }}
          value={[date.gte, date.lte]}
          onChange={e => {
            handleOnChange(e, 'date', undefined, currentTab === 'Share');
          }}
        />
      </Col>
      <Col {...colSpan}>
        <UISelect
          size="middle"
          loading={categoryLoading}
          mode="multiple"
          dropdownStyle={{ minWidth: 220 }}
          dataOptions={{
            data: categories,
            valueField: 'value',
            textField: 'text'
          }}
          placeholder={t('CHOOSE_CATEGORY')}
          key="selectCategory"
          style={{ width: '100%', fontSize: 13 }}
          onChange={e => {
            // setParams();
            handleOnChange(e, 'cate');
          }}
          allowClear
          showSearch
          showArrow
        />
      </Col>
      {/* <Col {...colSpan}>
        <UISelect
          dropdownStyle={{ minWidth: 220 }}
          size="middle"
          mode="multiple"
          loading={tagLoading}
          disabled={tagLoading}
          onChange={e => {
            handleOnChange(e, 'tags');
          }}
          dataOptions={{
            data: tagsType,
            valueField: 'value',
            textField: 'text'
          }}
          placeholder={t('CHOOSE_TAGS')}
          key="selectTags"
          style={{ width: '100%', fontSize: 13 }}
          allowClear
          showSearch
          showArrow
        />
      </Col> */}
      <Col {...colSpan}>
        <Select
          dropdownStyle={{ minWidth: 220 }}
          size="middle"
          loading={cityLoading}
          disabled={cityLoading}
          mode="multiple"
          placeholder={t('CHOOSE_CITY')}
          key="selectCity"
          onChange={(o, e) => {
            handleOnChange(o, 'city', e);
          }}
          style={{ width: '100%', fontSize: 13 }}
          allowClear
          showSearch
          showArrow
        >
          {city.map(i => {
            return (
              <Option label={i.value} key={shortid.generate()} value={i.text}>
                {i.text}
              </Option>
            );
          })}
        </Select>
      </Col>
      <Col {...colSpan}>
        <Select
          dropdownStyle={{ minWidth: 220 }}
          size="middle"
          disabled={isEmpty(tmp)}
          onChange={(o, e) => {
            handleOnChange(o, 'district', e);
          }}
          mode="multiple"
          placeholder={t('CHOOSE_DISTRICT')}
          key="selectDistrict"
          style={{ width: '100%', fontSize: 13 }}
          value={valueDistrict}
          allowClear
          showSearch
          showArrow
        >
          {uniqBy(districtByCity, '_id').map(i => {
            return (
              <Option label={i.city} key={`${shortid.generate()}+${i.value}`} value={i.text}>
                {i.text}
              </Option>
            );
          })}
        </Select>
      </Col>
      <Col {...colSpan}>
        <UISelect
          size="middle"
          dropdownStyle={{ minWidth: 220 }}
          loading={locationLoading}
          disabled={locationLoading}
          labelInValue
          dataOptions={{
            data: locations,
            valueField: '_id',
            textField: currentUser.ownerType === MGN ? 'name' : 'licenseName',
            template: filterLocations
          }}
          onChange={e => {
            handleOnChange(e, 'location');
          }}
          mode="multiple"
          placeholder={t('CHOOSE_LOCATION')}
          key="searchCampaign"
          style={{ width: '100%', fontSize: 13 }}
          showSearch
          allowClear
          showArrow
        />
      </Col>
      {/* {currentTab === 'Share' && (
          <Col sm={24} md={4}>
            <UISelect
              size="middle"
              dropdownStyle={{ minWidth: 220 }}
              loading={sharedTrafficLoading}
              disabled={sharedTrafficLoading}
              labelInValue
              dataOptions={{
                data: dataSharedTraffic,
                valueField: '_id',
                textField: 'name'
                // template: filterLocations
              }}
              onChange={(o, e) => {
                handleOnChange(o, 'customer', e);
              }}
              // mode="multiple"
              placeholder="Choose Customer"
              key="chooseCustomer"
              style={{ width: '100%', fontSize: 13 }}
              showSearch
              allowClear
              showArrow
            />
          </Col>
        )} */}
      {!exportExcel ? <></> : (
        <Col>
          <Tooltip title="Export">
            <UIButtonExportExcel
              shape="circle"
              type="primary"
              token={authStore.token}
              key="exportExcel"
              name={null}
              model="report/location"
              filter={where}
            />
          </Tooltip>
        </Col>
      )}
    </Row>
  );
});

export default LocationRPFilter;
