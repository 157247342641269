import '../../../components/UI/HighChart/theme';

import { Spin } from 'antd';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Hc from 'highcharts/highcharts-more';
import variablePie from 'highcharts/modules/variable-pie.js';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { FC } from 'react';

import store from './store';

Hc(Highcharts);

variablePie(Highcharts);
const TodayLoginChart: FC = observer(() => {
  const { todayLogin, loadingChart } = store;
  let getColor = 0;
  const colors = ['#32C9BC', '#F75A5A', '#595959', '#FFA242'];

  const dataOptions = todayLogin.map((item) => {
    const colorIndex = getColor;
    getColor += 1;
    if (getColor > 3) {
      getColor = 0;
    }
    return {
      color: colors[colorIndex],
      hour: moment(item.hour).hour(),
      count: item.count
    };
  });

  const categories = [];

  for (let i = 0; i < 24; i += 1) {
    categories.push(i);
  }
  const a = dataOptions.map((item) => item.hour);

  const options = {
    series: [
      {
        type: 'column',
        showInLegend: false,

        data: dataOptions.map((item) => {
          return {
            name: item.hour,
            y: item.count,
            color: item.color
          };
        })
      }
    ],
    credits: {
      enabled: false
    },

    chart: {
      polar: true,
      type: 'column'
    },

    title: {
      text: ''
    },

    pane: {
      size: '80%'
    },

    xAxis: {
      categories
    },
    exporting: {
      buttons: false
    },
    yAxis: {
      endOnTick: false,
      showLastLabel: false,
      reversedStacks: false
    },

    tooltip: {
      pointFormat:
        '<span style="color:{point.color}":00>\u25CF</span> Clicks : <b>{point.y}</b><br/>'
    },

    plotOptions: {
      series: {
        stacking: 'normal',
        shadow: false,
        groupPadding: 0
      },
      pie: {
        borderColor: '#000000'
      }
    }
  };
  return (
    <>
      <Spin spinning={loadingChart}>
        <HighchartsReact highcharts={Highcharts} options={options as any} />
      </Spin>
    </>
  );
});

export default TodayLoginChart;
