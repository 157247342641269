import { cloneDeep } from 'lodash';
import queryString from 'query-string';

export function isJSON(str: string) {
  try {
    return JSON.parse(str) && !!str;
  } catch (e) {
    return false;
  }
}

export function parseQuery(query: string) {
  const parsed = queryString.parse(query) as object;
  if (typeof parsed === 'object') {
    Object.keys(parsed).forEach((key) => {
      const checkJson = isJSON(parsed[key]);
      if (checkJson) {
        parsed[key] = JSON.parse(parsed[key]);
      }
    });

    return parsed;
  }
  return parsed;
}

export function stringifyQuery(query: object) {
  const clone_query = cloneDeep(query);
  Object.keys(clone_query).forEach((key) => {
    if (typeof clone_query[key] === 'object') {
      clone_query[key] = JSON.stringify(clone_query[key]);
    }
  });
  return queryString.stringify(clone_query);
}
