import { UploadFile } from 'antd/lib/upload/interface';
import i18next from 'i18next';
import { debounce, get } from 'lodash';
import { action, observable, runInAction } from 'mobx';
import { actionAsync, asyncAction, task } from 'mobx-utils';

import { GridReadyEvent } from '@ag-grid-community/core';

import { ErrorNotify, SuccessNotify } from '~components/UI/Notification';
import { getConfig } from '~config/index';
import { Image, ImageFilter, ImageInput, ImageUpdateArg } from '~graphql/_sdk';
import { getSdk } from '~graphql/sdk';

export class ImageStore {
  @observable public loading: boolean;
  @observable public modalVisible: boolean;
  @observable public selectedItem?: Image;
  @observable public where: ImageFilter;
  @observable public link: string;
  public fileList: UploadFile<any>[];

  constructor() {
    runInAction(() => {
      this.loading = false;
      this.modalVisible = false;
      this.selectedItem = undefined;
      this.where = {};
      this.fileList = [];
      this.link = '';
    });
  }

  @action
  setSearchText = debounce(text => {
    runInAction(() => {
      this.where = { _search: text };
      this.reload();
    });
  }, 500);

  @action
  setModalVisible = (item: Image = undefined) => {
    if (item && item.image) {
      this.fileList = [
        {
          uid: item!.image!.id,
          size: 0,
          name: item!.image!.id,
          type: '',
          url: item!.image!.path!
        }
      ];
    } else {
      this.fileList = [];
    }
    this.selectedItem = item;
    this.modalVisible = !this.modalVisible;
  };

  @asyncAction
  public async setFileList(fileList: UploadFile[]) {
    this.fileList = fileList;

    if (fileList[0]) {
      const { minioEndpoint } = await task(getConfig());
      this.link = `${minioEndpoint}/image_template/${fileList[0].uid}`;
    } else {
      this.link = '';
    }
  };

  @actionAsync
  create = async (record: ImageInput) => {
    const sdk = await task(getSdk());
    const {
      createImage: { error, data }
    } = await task(sdk.createImage({ record }));

    if (error) {
      ErrorNotify(error.title);
    } else {
      SuccessNotify(i18next.t('CREATE'));
      this.selectedItem = data;
      this.reload();
    }
  };

  @actionAsync
  update = async (_id: string, record: ImageUpdateArg) => {
    const imageId = get(this.fileList, '[0].uid', '');
    Object.assign(record, { imageId });

    const sdk = await task(getSdk());
    const {
      updateImageById: { error, data }
    } = await task(sdk.updateImageById({ _id, record }));

    if (error) {
      ErrorNotify(error.title);
    } else {
      SuccessNotify(i18next.t('UPDATE'));
      this.selectedItem = data;
      this.reload();
    }
  };

  @actionAsync
  remove = async (_id: string) => {
    const sdk = await task(getSdk());
    const {
      removeImageById: { error }
    } = await task(sdk.removeImageById({ _id }));

    if (error) {
      ErrorNotify(error.title);
    } else {
      SuccessNotify(i18next.t('DELETE'));
      this.reload();
    }
  };

  @action
  public handleGridReady = ({ api }: GridReadyEvent) => {
    this.reload = () => {
      api.onFilterChanged();
    };
  };

  @action
  public reload = () => undefined;
}

export default new ImageStore();
