import { Card, Col, Divider, Row } from 'antd';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import shortid from 'shortid';

import Counting from '~components/UI/Counting';

import store from '../store';

const Header: FC = observer(() => {
  const { campaignCount, runningCampaignCount } = store;
  const middleSpan = runningCampaignCount.length > 2 ? 12 : 8;
  const outsideSpan = (24 - middleSpan) / 2;

  return (
    <>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={outsideSpan}>
          <Card
            size="small"
            title="Total"
            style={{
              textAlign: 'center',
              fontSize: 32,
              color: '#FFA242',
              borderColor: '#FFA242',
              height: '100%'
            }}
            headStyle={{
              background: '#FFA242',
              color: '#fff',
              textAlign: 'center',
              fontSize: 14
            }}
          >
            <Counting value={campaignCount.total} />
          </Card>
        </Col>
        <Col xs={24} sm={24} md={middleSpan}>
          <Card
            size="small"
            title="Running"
            style={{
              textAlign: 'center',
              borderColor: '#32C9BC',
              color: '#32C9BC',
              height: '100%'
            }}
            headStyle={{
              background: '#32C9BC',
              color: '#fff',
              textAlign: 'center',
              fontSize: 14
            }}
          >
            {runningCampaignCount.length === 1 ? (
              <span style={{ fontSize: 32 }}>{runningCampaignCount[0]?.value}</span>
            ) : (
              <Row>
                {runningCampaignCount.map((i) => {
                  return (
                    <Col key={shortid.generate()} span={24 / runningCampaignCount.length}>
                      <div style={{ fontSize: 12 }}>{i.name}</div>
                      <Divider style={{ margin: 0 }} />
                      <div style={{ fontSize: 24 }}>
                        <Counting value={i.value} />
                      </div>
                    </Col>
                  );
                })}
              </Row>
            )}
          </Card>
        </Col>
        <Col xs={24} sm={24} md={outsideSpan}>
          <Card
            size="small"
            title="Finish"
            style={{
              textAlign: 'center',
              fontSize: 32,
              color: '#F75A5A',
              borderColor: '#F75A5A',
              height: '100%'
            }}
            headStyle={{
              background: '#F75A5A',
              color: '#fff',
              textAlign: 'center',
              fontSize: 14
            }}
          >
            <Counting value={campaignCount.finish} />
          </Card>
        </Col>
      </Row>
    </>
  );
});

export default Header;
