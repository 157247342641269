const translations = {
  PLATFORM_INFO: 'NỀN TẢNG QUẢN LÝ QUẢNG CÁO',
  INPUT_REFERRAL: 'Nhập mã giới thiệu',
  YOU_MUST_AGREE_WITH_CONDITION: 'Bạn phải đồng ý với điều khoản',
  MAXIMUM: 'Tối đa',
  MINIMUM: 'Tối thiểu',
  MUST_BE_DIGITS: 'Phải là số',
  REGISTER_SUCCESS: 'Đăng ký thành công',
  CHECK_EMAIL_TO_ACTIVE: 'Vùi lòng kiểm tra email của bạn để kích hoạt tài khoản',
  EMAIL_EXISTED_ERR: 'Email đã tồn tại',
  SEND_EMAIL_VERIFY: 'Gửi email xác nhận',
  SEND_EMAIL: 'Gửi email',

  // Menu
  DASHBOARD: 'Bảng chung',
  INVENTORY: 'KPI',
  AVAILABLE_KPI: 'KPI trống',
  HOTSPOT: 'Điểm Wifi',
  LOCATION_LIST: 'Địa điểm',
  BOOK_CAMPAIGN: 'Tạo Q/C',
  CAMPAIGNBASIC: 'Q/C cơ bản',
  CAMPAIGN_BASIC: 'Q/C cơ bản',
  CAMPAIGN_BASIC_LIST: 'Q/C cơ bản',
  CAMPAIGN: 'Q/C nâng cao',
  CAMPAIGN_LIST: 'Q/C nâng cao',
  EQUIPMENT_LIST: 'Điểm Wifi',
  CAMPAIGNS_PRIORITY: 'Thứ tự Quảng cáo',
  LOCATIONS_PRIORITY: 'Tráo đổi thứ tự',
  ACTIVE_CAMPAIGN: 'Active Campaigns',
  CREATIVE_ADS: 'Trang chào',

  BOOKING_ONLINE: 'Đặt trước Chiến dịch',

  REPORT: 'Báo cáo',
  CAMPAIGN_BOOKED: 'Quảng cáo',
  TRAFFIC_LOCATION: 'Địa điểm',

  PERMISSION: 'Phân quyền',
  USER_LIST: 'Tài khoản',
  PROFILE_LIST: 'Hồ sơ tài khoản',

  ASSIGN_CAMPAIGN: 'Gán Chiến dịch',
  ASSIGN_LOCATION: 'Gán Địa điểm',
  ASSIGN_PROFILE: 'Gán Hồ sơ',

  Payment: 'Thanh toán',

  CUSTOMER_TYPE: 'Loại Customer',
  CUSTOMER: 'Customer',

  SESSION_START: ' Phiên bắt đầu',
  TIMES: 'Khung thời gian',
  KPI_BOOKING: 'KPI đã đặt',
  TOTAL_CLICK: 'Click đã chạy',
  FROM: 'Bắt đầu',
  TO: 'Kết thúc',

  CHART: 'Biểu đồ',
  GRID: 'Lưới',
  BACK: 'Lui',
  REPORT_DETAIL: 'Báo cáo chi tiết',
  REMAINING_DAY: 'Ngày còn lại',
  KPI_COMPLETED: 'KPI đã chạy',

  MANUAL: 'HDSD',

  UP_DOWN_TRAFFIC: 'Tăng / Giảm',
  CAMPAIGN_TYPE: 'Loại chiến dịch',
  SHARE_TRAFFIC: 'Chia sẻ lưu lượng',

  SELECT_CAMPAIGN: 'Chọn chiến dịch quảng cáo',
  WAIT_A_SECOND: 'Vui lòng chờ',

  HTML: 'Nâng cao (HTML/CSS)',

  VIEW_BANNER: 'Xem',
  ACTIVE_BANNER: 'Kích hoạt',
  INSTRUCTIONS: 'Hướng dẫn sử dụng',

  ADS: 'Quảng cáo',
  TRAFFIC_ADS: 'Lưu lượng Quảng cáo',
  TRAFFIC_TOTAL: 'Lưu lượng tất cả',

  UPDATING: 'Đang cập nhật',

  NO_DATA: 'Không có dữ liệu',
  ADVANCE_FEATURES: 'Tính năng nâng cao',

  BASIC_SETTING: 'Chung',
  SECRET_SETTING: 'Bảo mật',
  INVITE_LICENSE: 'Mời đăng ký',

  VERIFY_EMAIL: 'Xác minh email',
  RESET_PASSWORD_DEFAULT: ' Khôi phục mật khẩu mặc định',
  PASSWORD_RULES: 'Mật khẩu phải có từ 8 ký tự bao gồm chữ số chữ hoa chữ thường và ký tự đặc biệt',
  INVALID_EMAIL: 'Email không đúng định dạng',

  LOGS: 'Nhật ký',

  USER_AGENT: 'Thiết bị người dùng',
  CAMPAIGN_VIEW: 'Lượt xem chiến dịch',
  CURRENT_PASSWORD: 'Mật khẩu hiện tại',
  VERIFY_PASSWORD: 'Xác nhận mật khẩu',

  RANDOM_PERCENT: ' Tỉ lệ xuất hiện',

  TYPE_TO_SEARCH: 'Tìm kiếm',
  RETURN_USER: 'Nguời dùng quay lại',

  dashboard: {
    TRAFFIC_SUMMARY: 'Lưu lượng',
    SESSION: 'Phiên truy cập',
    TODAY_LOGIN: 'Phiên hôm nay',
    SESSION_2_HOURS: 'Phiên 2 giờ trước',
    AUDIENCE: 'Khách',
    DEVICE: 'Thiết bị',
    VISIT: 'Lượt ghé thăm',
    AVERAGE_WEEK: 'Trung bình / Tuần',
    AVERAGE_DAY: 'Trung bình / Ngày',
    HOTSPOT: 'Điểm Wifi',

    HOTSPOT_NEW: 'Mới',
    HOTSPOT_TOTAL: 'Tổng',
    HOTSPOT_ON: 'Hoạt động',
    HOTSPOT_OFF: 'Không hoạt động',
    UNIQUE_DEVICE: 'Người dùng'
  },

  region: {
    NAME: 'Khu vực',
    AREA: 'Vùng Miền',
    CITY: 'Tỉnh/Thành phố',
    DISTRICT: 'Quận/Huyện'
  },

  category: {
    name: 'loại điểm',
    NAME: 'Loại điểm',
    names: 'loại điểm',
    NAMES: 'Loại điểm'
  },

  location: {
    name: 'địa điểm',
    NAME: 'Địa điểm',
    names: 'địa điểm',
    NAMES: 'Địa điểm',
    MONTHLY_CLICK: 'Click/tháng',
    REPORT_TRAFFIC: 'Báo cáo',
    VIEW_ADS: 'Xem quảng cáo'
  },

  banner: {
    BANNER_NANE: 'Tên',
    NAME: 'Banner',
    MODE: 'Chế độ',
    TEMPLATE: 'Mẫu',
    SIMPLE: 'Cơ bản',
    VIDEO: 'Video',
    FORM: 'Form thông tin',
    SLIDE: 'Trượt',
    UPLOAD: 'Tải lên',
    contentImg: 'Hình',
    contentImg1: 'Hình',
    contentImg2: 'Hình',
    contentImage: 'Hình 01',
    contentImage1: 'Hình 02',
    contentImage2: 'Hình 03',
    loginButton: 'Nút kết nối',
    contentGallery: 'Thư viện ảnh',
    contentGallery01: 'Thư viện ảnh',
    contentGallery02: 'Thư viện ảnh',
    fields: 'Trường thông tin',
    video: 'Video',
    thumbnailImg: 'Thumbmail',
    form: 'Form',
    buttonTextVn: 'Text VietNamese',
    buttonTextEng: 'Text English',
    buttonBackgroundColor: 'Mã màu nút',
    buttonTextColor: 'Mã màu chữ',
    countDown: 'Thời gian đếm ngược',
    DEMO_COLOR: 'Bảng màu tham khảo'
  },

  campaign: {
    CREATE: 'Tạo chiến dịch',
    NAME: 'Chiến dịch',
    LANDING_PAGE: 'Trang đích',
    PLACE_BOOKING: 'Hoàn tất',
    INFO: 'Thông tin Chiến dịch'
  },

  pricing: {
    NAME: 'Đơn giá',
    PRICE: 'Giá'
  },

  booking: {
    START_DATE: 'Ngày bắt đầu',
    END_DATE: 'Ngày kết thúc',
    SCHEDULE: 'Thời gian',
    BALANCE_SOURCE: 'Tài khoản nguồn',
    BUDGET: 'Số tiền',
    KPI: 'KPI',
    KPI_AVAILABLE: 'KPI còn trống',
    STEP_LOCATION: 'Địa điểm',
    STEP_LOCATION_DESC: 'Chọn địa điểm mong muốn',
    STEP_SCHEDULE_KPI: 'Lịch trình & Ngân sách',
    STEP_SCHEDULE_KPI_DESC: 'Thiếp lập lịch trình và ngân sách',
    STEP_CONTENT: 'Nội dung',
    STEP_CONTENT_DESC: 'Thiết lập trang chào và trang đích',
    STEP_REVIEW: 'Xem lại',
    STEP_REVIEW_DESC: 'Xem lại booking của bạn',
    ERR_BOOKING_NO_LOCATION: 'Bạn chưa chọn địa điểm nào!',
    ERR_BOOKING_MISSING_INFO: 'VUi lòng kiểm tra lại',
    ERR_BUGDET_IS_HIGHER_THAN_BALANCE: 'Tài khoản của bạn không đủ',
    ERR_BALANCE_SOURCE_IS_EMPTY: 'Tài khoản nguồn trống',
    ERR_KPI_IS_HIGHER_THAN_AVAILABLE: 'Địa điểm không đủ tài nguyên',
    CAMPAIGN_NAME: 'Tên Chiến dịch',
    SELECTED_LOCATION: 'Đã chọn',
    BOOKING_SUCCESS: 'Booking của bạn đã được đặt thành công!'
  },

  error: {
    EMAIL_EXISTED_ERR: 'Email đã tồn tại',
    INVALID_CAPTCHA_ERR: 'Hãy làm mới captcha'
  },

  user: {
    INFORMATION: 'Thông tin',
    LOGOUT: 'Đăng xuất'
  },

  COMMON_DATA: 'Dữ liệu chung',
  BANNER_IMAGE: 'Hình ảnh chung',
  EQUIPMENT_MODEL: 'Model Wifi',
  EQUIPMENT_BRAND: 'Brand Wifi',
  LOCATION_TAGS: 'Thẻ địa điểm',
  LOCATION_CATEGORY_GROUP: 'Nhóm danh mục địa điểm',
  LOCATION_CATEGORY: 'Loại địa điểm',
  EQUIPMENT_GROUP: 'Nhóm Wifi',
  COMMON_REGION: 'Khu vực',
  CAMPAIGN_BRAND: 'Brand Chiến dịch',
  SETTING: 'Cài đặt'
};

export default translations;
