import { DatePicker } from 'antd';
import { PickerDateProps } from 'antd/lib/date-picker/generatePicker';
import moment, { Moment } from 'moment';
import React, { Component } from 'react';

import { Without } from '@source/common';

export interface UIDatePickerProps extends Without<PickerDateProps<Moment>, 'value' | 'onChange'> {
  value?: string | number | Date | Moment;
  onChange?: (value: any) => any;
}

export class UIDatePicker extends Component<UIDatePickerProps> {
  private onChange = (date?: Moment) => {
    const { onChange } = this.props;
    if (typeof onChange === 'function') {
      onChange(date && date.toISOString());
    }
  };
  public render() {
    const { value, ...props } = this.props;
    const valueObj: any = value ? moment(value) : null;

    return <DatePicker {...props} value={valueObj} onChange={this.onChange} />;
  }
}
