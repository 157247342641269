import { isEmpty } from 'lodash';
import { action, observable, runInAction } from 'mobx';
import { actionAsync, task } from 'mobx-utils';
import moment from 'moment';

import { GridReadyEvent } from '@ag-grid-community/core';

import { BasicCustomerFragment, DateArgs, ReportIncomeResult } from '~graphql/_sdk';
import { getSdk } from '~graphql/sdk';

class RPIncomeLocationStore {
  @observable public loading: boolean;
  @observable public details;
  @observable public countDetail;
  @observable public count;
  @observable public categoryId: string[];
  @observable public income: ReportIncomeResult;
  @observable public filter;
  @observable public nodeHeight: number;
  @observable public customers: BasicCustomerFragment[];
  @observable public loadingCustomer: boolean;
  @observable public dateFilter: DateArgs;
  @observable public selectedCutomerId: string;

  public where;
  public datasource: any;

  constructor() {
    runInAction(() => {
      this.customers = [];
      this.dateFilter = {
        gte: moment()
          .subtract(30, 'days')
          .startOf('day'),
        lte: moment().startOf('day')
      };
      this.cleanUp();
    });
  }

  @action
  public setSelectedCustomer = (customerId: string) => {
    this.selectedCutomerId = customerId;
  };

  @action
  public setDateFilter = (date: number[]) => {
    if (isEmpty(date)) {
      this.dateFilter = { gte: null, lte: null };
      return;
    }
    this.dateFilter = { gte: moment(date[0]), lte: moment(date[1]) };
  };

  public handleFilterOnChange = (field: string, value: any) => {
    switch (field) {
      case 'customer':
        this.setSelectedCustomer(value);
        break;
      case 'date':
        this.setDateFilter(value);
        break;
      default:
        break;
    }

    this.getReportIncome();
  };

  @action
  public cleanUp = () => {
    this.filter = {};
    this.where = {};
    this.details = [];
    this.income = {};
  };

  @action
  public handleGridReady = ({ api }: GridReadyEvent) => {
    this.reload = () => {
      api.onFilterChanged();
    };
    this.resetRowHeight = () => {
      api.resetRowHeights();
    };
  };

  @action
  public setWhere = (w, tab?: string) => {
    this.filter = w;
    if (tab === 'grid') {
      this.reload();
    }
  };

  @actionAsync
  public getCustomer = async () => {
    this.loadingCustomer = true;

    const sdk = await task(getSdk());
    const { findManyCustomer } = await task(
      sdk.findManyCustomer({
        where: { shareRevenue: { enabled: true } }
      })
    );

    this.customers = findManyCustomer;
    this.loadingCustomer = false;
  };

  @actionAsync
  public getReportIncome = async () => {
    const sdk = await task(getSdk());
    this.loading = true;

    const { getReportIncomePartner } = await task(
      sdk.getReportIncomePartner({
        filter: {
          date: this.dateFilter,
          customerId: this.selectedCutomerId
        }
      })
    );
    this.loading = false;
    this.income = getReportIncomePartner;
  };

  @action
  public resetRowHeight = () => undefined;

  @action
  public reload = () => undefined;
}
export default new RPIncomeLocationStore();
