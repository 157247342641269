/* eslint-disable no-shadow */
/* eslint-disable array-callback-return */
import { Button, Input, PageHeader, Select } from 'antd';
import { observer } from 'mobx-react';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { PlusOutlined, RedoOutlined } from '@ant-design/icons';
import {
  AUTHORIZED_ROLES_CREATE_CAMPAIGN_MAPPING,
  ROLE_CREATE_CAMPAIGN_MAPPING
} from '@source/common';

import Authorized from '~components/Authorized/Authorized';
import OwnerType from '~components/UI/OwnerTypeRadio';
import { useStore } from '~context/react-context';
import { stringifyQuery } from '~utils/query-string';

import store from './store';

const KPIManagementHeader: FC = observer(() => {
  const { t } = useTranslation();
  const { Option } = Select;
  const {
    setModalVisible,
    handleCampaignSelect,
    value,
    setValue,
    campaignIdChosen,
    setOwnerType,
    handleSearch,
    setAvailableBooking,
    campaigns
  } = store;

  useEffect(() => {
    setOwnerType();
    return;
  }, []);

  const { commonDataStore } = useStore();
  const history = useHistory();

  return (
    <PageHeader
      style={{ background: '#fff', marginBottom: 0 }}
      title={<OwnerType setOwnerFunc={setOwnerType} />}
      extra={[
        <Button
          key="4"
          onClick={() => {
            if (campaignIdChosen) {
              handleCampaignSelect(campaignIdChosen);
            }
          }}
        >
          <RedoOutlined />
        </Button>,
        <Select
          disabled={commonDataStore.loading}
          key="2"
          loading={commonDataStore.loading}
          placeholder={t('CHOOSE_CAMPAIGN')}
          showSearch
          value={value}
          style={{ minWidth: 200 }}
          onChange={value => {
            setValue(value);
            handleCampaignSelect(value);
            const query = stringifyQuery({ id: value });
            history.push({ search: query });
          }}
          filterOption={(input, option) =>
            option.props.children
              .toString()
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
        >
          {campaigns.map((item, idx) => (
            <Option value={item._id} key={String(idx)}>
              {item.name}
            </Option>
          ))}
        </Select>,
        <Authorized roles={[ROLE_CREATE_CAMPAIGN_MAPPING]} key="btn-group">
          <Button.Group style={{ marginLeft: 5 }}>
            <Button
              key="create"
              type="primary"
              icon={<PlusOutlined />}
              disabled={!value}
              onClick={() => {
                setModalVisible(undefined, 'create');
                setAvailableBooking(0);
              }}
            >
              {t('ADD_LOCATION')}
            </Button>
          </Button.Group>
        </Authorized>
      ]}
    />
  );
});

export default KPIManagementHeader;
