import emptySVG from './empty.svg';
import Hinh1 from './hinh1.png';
import Hinh10 from './hinh10.png';
import Hinh11 from './hinh11.png';
import Hinh12 from './hinh12.png';
import Hinh13 from './hinh13.png';
import Hinh14 from './hinh14.png';
import Hinh2 from './hinh2.png';
import Hinh3 from './hinh3.png';
import Hinh4 from './hinh4.png';
import Hinh5 from './hinh5.png';
import Hinh6 from './hinh6.png';
import Hinh7 from './hinh7.png';
import Hinh8 from './hinh8.png';
import Hinh9 from './hinh9.png';
import DemoColor from './bang-ma-mau-css.png'
import MegaNet from './picturemessage_ai0rauut.0kt.png';

export {
  emptySVG,
  Hinh1,
  Hinh2,
  Hinh3,
  Hinh4,
  Hinh5,
  Hinh6,
  Hinh7,
  Hinh8,
  Hinh9,
  Hinh10,
  Hinh11,
  Hinh12,
  Hinh13,
  Hinh14,
  MegaNet,
  DemoColor
};
