import i18next from 'i18next';
import { debounce, findIndex, get, groupBy, meanBy, orderBy, sumBy, uniqBy } from 'lodash';
import { action, computed, observable, reaction, runInAction } from 'mobx';
import { actionAsync, task } from 'mobx-utils';
import moment from 'moment';

import { GridReadyEvent } from '@ag-grid-community/core';
import {
  FINISHED,
  LICENSE,
  mapReduce,
  RUNNING,
  SCHEDULED,
  STOPPED,
  WhereArgs
} from '@source/common';

import commonDataStore from '~common-stores/commonDataStore';
import userStore from '~common-stores/userStore';
import { ErrorNotify, SuccessNotify } from '~components/UI/Notification';
import {
  AddKpiInput,
  Booking,
  CampaignMapping,
  FullLocationFragment,
  IdFilterInput
} from '~graphql/_sdk';
import campaign from '~graphql/fragments/campaign';
import { getSdk } from '~graphql/sdk';

export class KPIManagementStore {
  @observable public loading: boolean;
  @observable public visible: boolean;
  @observable public modalType?: string;
  @observable public selectedItem?: any;
  @observable public campaigns: any;
  @observable public reportLocations: any;
  @observable public camMapRawMap: any;
  @observable public campaignIdChosen: string;
  @observable public campaignData: any;
  @observable public campaign: any;
  @observable public campaignVisible: boolean;
  @observable public ownerType: string;
  @observable public availableBooking: number;
  @observable public allKpiData: Array<Booking>;
  @observable public kpiData: Array<Booking>;
  @observable public campaignEndDate: string;
  @observable public where: WhereArgs;
  @observable public drawerVisible: boolean;
  @observable public selected: FullLocationFragment;
  @observable public filterVisible: boolean;
  @observable public availableBookingLoading: boolean;
  @observable public locationAvailableBooking: any;
  @observable public totalResult: any;
  @observable public value: string;
  @observable public checkButton: boolean;
  @observable public totalKpi: number;
  @observable public quantityLocation: number;
  @observable public search: string;
  @observable public parseId: string;
  @observable public locationIds: string[];
  @observable.ref public campaignMapping: Array<any>;

  private reportLocationMap: any;

  private campaignMappingData: Array<CampaignMapping>;

  constructor() {
    runInAction(() => {
      this.loading = false;
      this.search = '';
      this.visible = false;
      this.modalType = undefined;
      this.selectedItem = undefined;
      this.availableBookingLoading = false;
      this.campaigns = [];
      this.checkButton = false;
      this.locationIds = [];
      this.reportLocations = [];
      this.campaignMapping = [];
      this.campaignIdChosen = undefined;
      this.campaignData = [];
      this.campaign = undefined;
      this.campaignVisible = false;
      this.ownerType = null;
      this.availableBooking = 0;
      this.quantityLocation = 0;
      this.allKpiData = [];
      this.kpiData = [];
      this.totalKpi = 0;
      this.campaignEndDate = '';
      this.drawerVisible = false;
      this.selected = undefined;
      this.filterVisible = false;
      this.camMapRawMap = undefined;
      this.locationAvailableBooking = {};
      this.totalResult = {};
      this.where = {};
    });
    reaction(
      () => this.parseId,
      id => {
        this.setValue(id);
        this.handleCampaignSelect(id);
      }
    );

    reaction(
      () => this.searchCommonLocations,
      result => {
        this.setTotalValue(result);
      }
    );
  }

  @actionAsync
  public loadAvailableBooking = async (locationIds: [string], scheduleTo: Date) => {
    const tomorrow = moment().add(1, 'days');
    this.availableBookingLoading = true;
    const sdk = await task(getSdk());
    const {
      getAvailableBooking: { locations }
    } = await task(
      sdk.getAvailableBooking({
        locationIds,
        date: { from: tomorrow, to: scheduleTo },
        detail: false
      })
    );
    if (locations?.length) {
      [this.locationAvailableBooking] = locations;
      this.availableBooking = locations[0].availableTotalKpi;
    } else {
      this.availableBooking = 0;
    }
    this.availableBookingLoading = false;
  };

  @action
  public setAvailableBooking = (value: number) => {
    this.availableBooking = value;
  };

  @computed
  get searchCommonLocations() {
    if (this.search) {
      const re = new RegExp(`${this.search}`, 'gi');
      return this.campaignMapping.filter(i => re.test(i.location.name));
    }

    if (!this.campaignMapping || !this.campaignMapping.length) {
      return;
    }

    const categoryReduce = mapReduce(commonDataStore.categories, 'value', item => item.text);

    this.campaignMapping.map((item: any) => {
      if (!item.reportLocationId) {
        item.reportLocationId = item.locationId;
      } else {
        const indexRealLocation = findIndex(
          this.campaignMapping,
          o => get(item, 'reportLocation._id', '') === o.locationId
        );
        if (indexRealLocation > -1) {
          item.location.categoryId = get(
            this.campaignMapping[indexRealLocation],
            'location.categoryId',
            ''
          );
          item.reportLocationName = get(item, 'reportLocation.name', '');
        }
      }

      item.locationName = get(item, 'location.name', '');
      item.categoryName = categoryReduce[get(item, 'location.categoryId', '')];

      item.statsKpi = item.stats?.kpi;
      item.statsClick = item.stats?.click;
      item.statsKpiCompleted = item.stats?.kpiCompleted;
      item.statsKpiRemaining = item.stats?.kpiRemaining;

      item.yesterdayKpi = item.yesterdayStats?.kpi;
      item.yesterdayClick = item.yesterdayStats?.click;
      item.yesterdayKpiCompleted = item.yesterdayStats?.kpiCompleted;

      item.todayKpi = item.todayStats?.kpi;
      item.todayClick = item.todayStats?.click;
      item.todayKpiCompleted = item.todayStats?.kpiCompleted;

      return item;
    });
    const categoryGroup = groupBy(
      orderBy(this.campaignMapping, ['reportLocationId'], ['asc']),
      'categoryName'
    );

    const result = [];
    Object.keys(categoryGroup).forEach((item, index) => {
      const realItems = categoryGroup[item].filter(o => o.reportLocationId === o.locationId);

      const statsKpi = sumBy(realItems, 'statsKpi');
      const statsClick = sumBy(realItems, 'statsClick');
      const statsKpiCompleted = meanBy(realItems, 'statsKpiCompleted');
      const statsKpiRemaining = sumBy(realItems, 'statsKpiRemaining');

      const yesterdayKpi = sumBy(realItems, 'yesterdayKpi');
      const yesterdayClick = sumBy(realItems, 'yesterdayClick');
      const yesterdayKpiCompleted = meanBy(realItems, 'yesterdayKpiCompleted');

      const todayKpi = sumBy(realItems, 'todayKpi');
      const todayClick = sumBy(realItems, 'todayClick');
      const todayKpiCompleted = meanBy(realItems, 'todayKpiCompleted');

      let count = 0;
      categoryGroup[item].map((i, _index) => {
        if (i.reportLocationId === i.locationId) {
          count += 1;
          i.index = count;
        }
        return i;
      });

      let children = [];
      const groupItem = groupBy(categoryGroup[item], 'reportLocationId');

      Object.keys(groupItem).forEach((i, index) => {
        const currentItem = groupItem[i];
        currentItem.map((_i, _index) => {
          if (_i.reportLocationId === _i.locationId) {
            _i.key = index * 1000;
          } else {
            _i.key = index * 1000 + _index + 1;
          }
          return i;
        });
        children = children.concat(currentItem);
      });

      result.push({
        locationName: `${item} (${count})`,
        locations: count,
        key: index,
        color: '#f4f4f4',
        index,
        statsKpi,
        statsClick,
        statsKpiCompleted,
        statsKpiRemaining,
        yesterdayKpi,
        yesterdayClick,
        yesterdayKpiCompleted,
        todayKpi,
        todayClick,
        todayKpiCompleted,
        children: orderBy(children, ['key'], ['asc'])
      });
    });

    return result;
  }

  @action
  setTotalValue = result => {
    this.totalResult = {
      key: 'totalResult',
      index: sumBy(result, 'index'),
      locations: sumBy(result, 'locations'),
      statsClick: sumBy(result, 'statsClick'),
      statsKpi: sumBy(result, 'statsKpi'),
      statsKpiCompleted: Number(meanBy(result, 'statsKpiCompleted')).toFixed(0),
      statsKpiRemaining: sumBy(result, 'statsKpiRemaining'),
      yesterdayClick: sumBy(result, 'yesterdayClick'),
      yesterdayKpi: sumBy(result, 'yesterdayKpi'),
      yesterdayKpiCompleted: Number(meanBy(result, 'yesterdayKpiCompleted')).toFixed(0),
      todayClick: sumBy(result, 'todayClick'),
      todayKpi: sumBy(result, 'todayKpi'),
      todayKpiCompleted: Number(meanBy(result, 'todayKpiCompleted')).toFixed(0)
    };
  };

  @action
  public handleSearch = value => {
    runInAction(() => {
      this.search = value;
    });
  };

  @action
  setWhere = where => {
    this.where = { ...this.where, ...where };
  };

  setKpiLocation = debounce(
    kpi => {
      runInAction(() => {
        if (kpi === '') {
          this.availableBooking = this.locationAvailableBooking.availableTotalKpi;
        } else {
          this.availableBooking = get(this.locationAvailableBooking, 'availableTotalKpi', 0) - kpi;
        }
      });
    },
    100,
    { leading: false, trailing: true }
  );

  @action
  setCheckButton = (check: boolean) => {
    this.checkButton = check;
  };

  @action
  setFilterVisible = async (item: FullLocationFragment = null) => {
    this.selected = item;
    this.filterVisible = !this.filterVisible;
  };

  @action
  public setValue = (value: string) => {
    commonDataStore.campaigns.find(item => item.name === value);
    this.value = value;
  };

  @action
  setModalVisible = (id = undefined, type = undefined) => {
    const campaignMapping = id ? this.campaignMapping.find(item => item.locationId === id) : null;
    this.selectedItem = campaignMapping;
    this.visible = !this.visible;
    this.modalType = type;
    if (campaignMapping && type === 'kpi') {
      this.kpiData = campaignMapping.everydayStats;
    }
    if (campaignMapping && type === 'edit')
      this.loadAvailableBooking([id], campaignMapping.scheduleTo);
  };

  @action
  resetCampaignSelect = () => {
    this.campaignMapping = undefined;
    this.campaignIdChosen = undefined;
    this.campaignMappingData = [];
    this.loading = false;
    this.setValue(undefined);
  };

  @action
  setParseId = (id: string) => {
    this.parseId = id;
  };
  getCurrentUser = () => {
    const { currentUser } = userStore;
    return currentUser.ownerType;
  };

  getListLocationName = (field?: string) => {
    if (!field) {
      field = this.getCurrentUser() === LICENSE ? 'licenseName' : 'name';
    }
    const locationMap = commonDataStore.locations.reduce((all, item) => {
      all[item._id] = get(item, `${field}`, item.name);
      return all;
    }, {});
    return locationMap;
  };

  @actionAsync
  handleCampaignSelect = async (campaignId: string) => {
    this.loading = true;
    const sdk = await task(getSdk());

    const { fetchCampaignMapping } = await task(sdk.fetchCampaignMapping({ campaignId }));
    const { findManyCampaign } = await task(sdk.findManyCampaign({ where: { _id: campaignId } }));

    this.campaign = findManyCampaign[0];

    if (!fetchCampaignMapping) {
      ErrorNotify('ERROR ! NOT LOAD DATA');
    } else {
      this.campaignMapping = fetchCampaignMapping;

      this.quantityLocation = sumBy(this.campaignMapping, item => {
        return get(item, 'reportLocationId') ? 0 : 1;
      });

      this.campaignEndDate = this.campaign.scheduleTo;
      this.locationIds = fetchCampaignMapping.map(item => item.locationId);

      this.totalKpi = sumBy(this.campaignMapping, item => {
        return get(item, 'reportLocationId') ? 0 : get(item, 'kpi', 0);
      });
    }

    this.reportLocations = uniqBy(fetchCampaignMapping, 'locationId').map(item => ({
      _id: item.locationId,
      name: this.getListLocationName('name')[item.locationId]
    }));

    this.reportLocationMap = this.reportLocations.reduce((all, item) => {
      all[item._id] = item.name;
      return all;
    }, {});

    this.campaignIdChosen = campaignId;
    this.loading = false;
  };

  public filterLocation = (status: string) => {
    this.campaignMapping = this.campaignMappingData.filter(c => c.status === status);
  };

  @actionAsync
  public stopBooking = async (idFilterArg: IdFilterInput) => {
    const sdk = await task(getSdk());
    const {
      stopBooking: { error }
    } = await task(sdk.stopBooking({ idFilterArg }));
    if (error) {
      ErrorNotify(error.code);
    } else {
      SuccessNotify(i18next.t('STOP_BOOKING'));
    }
  };

  @actionAsync
  public setOwnerType = async (ownerType: string = null) => {
    this.resetCampaignSelect();
    this.ownerType = ownerType;
    this.setWhere({ ownerType });
    this.campaignIdChosen = undefined;
    commonDataStore.setLoading();

    await task(
      commonDataStore.loadCampaigns({
        ownerType,
        _operators: { status: { in: [SCHEDULED, RUNNING, STOPPED] } }
      })
    );

    this.campaigns = commonDataStore.campaigns.map(item => {
      return {
        _id: item._id,
        name: item.name
      };
    });
  };

  @actionAsync
  create = async (record: any) => {
    if (!this.reportLocationMap[record.locationId]) {
      const submit = {
        ...record,
        campaignId: this.campaignIdChosen
      };
      const sdk = await task(getSdk());

      const {
        addLocation: { error }
      } = await task(sdk.addLocation({ addLocationParams: submit }));

      if (error) {
        ErrorNotify(error.code);
      } else {
        SuccessNotify(i18next.t('CREATE'));
        this.handleCampaignSelect(this.campaignIdChosen);
      }
    } else {
      ErrorNotify(i18next.t('NOTI_LOCATION_EXISTED'));
    }
  };

  @actionAsync
  update = async (camMap: any) => {
    const sdk = await task(getSdk());
    const {
      updateBooking: { error }
    } = await task(sdk.updateBooking({ campaignMapping: camMap }));
    if (error) {
      ErrorNotify(error.code);
    } else {
      SuccessNotify(i18next.t('UPDATE_BOOKING'));
    }
  };

  @actionAsync
  addKpiManagement = async (camMap: AddKpiInput) => {
    const sdk = await task(getSdk());
    const {
      addKpiManagement: { error }
    } = await task(sdk.addKpiManagement({ campaignMapping: camMap }));
    if (error) {
      ErrorNotify(error.code);
    } else {
      SuccessNotify(i18next.t('UPDATE_BOOKING'));
    }
  };

  @actionAsync
  updateByCalendar = async (bookings: any) => {
    const sdk = await task(getSdk());
    const {
      updateCalendarBooking: { error }
    } = await task(sdk.updateCalendarBooking({ booking: bookings }));
    if (error) {
      ErrorNotify(error.code);
    } else {
      SuccessNotify(i18next.t('UPDATE_CALENDAR'));
    }
  };

  @action
  public handleGridReady = ({ api }: GridReadyEvent) => {
    api.onFilterChanged();
  };

  @action
  public reload = () => undefined;
}

export default new KPIManagementStore();
