import React, { FC, useEffect } from 'react';

import { useStore } from '~context/react-context';

import AudienceChart from './audience/AudienceChart';
import HotspotDashboard from './hotspot/Hotspot';
// import AudiencePie from './audience/AudiencePie';
import SessionScreen from './session/SessionScreen';
// import Traffic from './traffic-overview/TrafficOverview';
import TrafficSummary from './traffic-summary/TrafficSummary';

const Dashboard: FC = () => {
  const { commonDataStore } = useStore();

  useEffect(() => {
    commonDataStore.loadAllLocations();
  }, []);

  return (
    <>
      <HotspotDashboard />
      <TrafficSummary />
      <SessionScreen />
      <AudienceChart />
      {/* <AudiencePie /> */}
      {/* <Traffic /> */}
    </>
  );
};

export default Dashboard;
