import { Tabs, Tooltip } from 'antd';
import { observer } from 'mobx-react';
import React, { FC, useEffect } from 'react';
import ReactResizeDetector from 'react-resize-detector';

import Chart from './Chart';
import Grid from './Grid';
import StatisticComponent from './Statistic';
import store from './store';

export const Report: FC = observer(() => {
  const { getReportIncome, filter, setWhere, getCampaignMapping } = store;

  useEffect(() => {
    setWhere(filter);
    getReportIncome();
  }, [filter]);

  const { TabPane } = Tabs;

  return (
    <>
      <StatisticComponent />

      <ReactResizeDetector handleHeight handleWidth>
        {({ height, width }) => {
          return (
            <Tabs
              style={{
                backgroundColor: '#fff'
              }}
              size="small"
              tabPosition="left"
              defaultActiveKey="Date"
            >
              <TabPane
                forceRender
                tab={
                  <Tooltip placement="right" title="Date">
                    <i className="fas fa-chart-bar" />
                  </Tooltip>
                }
                key="Date"
              >
                <Chart widthChart={width - 80 || 1000} heightChart={height - 130 || 230} />
              </TabPane>
              <TabPane
                forceRender
                style={{ width: width - 75 || 1000, height: height - 130 || 560 }}
                tab={
                  <Tooltip placement="right" title="Location">
                    <i className="fas fa-th" />
                  </Tooltip>
                }
                key="Location"
              >
                <Grid />
              </TabPane>
            </Tabs>
          );
        }}
      </ReactResizeDetector>
    </>
  );
});
