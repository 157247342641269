import { Button, Input, PageHeader } from 'antd';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import {
  AUTHORIZED_ROLES_CREATE_USER,
  AUTHORIZED_ROLES_EXPORT_USER,
  ROLE_CREATE_USER
} from '@source/common';

import Authorized from '~components/Authorized/Authorized';
import { FilterConfig } from '~components/Filter/filterType';
import Filter from '~components/Filter/UIFilter';
import OwnerType from '~components/UI/OwnerTypeRadio';
import { isMobile } from '~utils/isMobile';

import { Export } from './Export';
import store from './store';

const UserHeader: FC = observer(() => {
  const { t } = useTranslation();
  const { searchFilter, setModalVisible, listProfile, setFilter, setOwnerType } = store;

  const initFilter: FilterConfig = {
    select: [
      {
        dataOptions: {
          data: listProfile,
          valueField: 'profileId',
          textField: 'display'
        },
        props: {
          placeholder: 'Select Type',
          allowClear: true,
          mode: 'multiple'
        },
        filterField: 'profileId',
        label: t('PROFILE')
      }
    ],
    dateRange: [
      {
        filterFields: ['createdAt'],
        label: t('CREATED_AT')
      }
    ]
  };

  return (
    <PageHeader
      style={{ background: '#fff', marginBottom: 0, padding: '16px' }}
      title={<OwnerType setOwnerFunc={setOwnerType} />}
      extra={[
        <Filter key="filter" config={initFilter} onFilterChanged={setFilter} />,
        <Input
          key="search"
          suffix={<SearchOutlined />}
          placeholder={t('SEARCH')}
          allowClear
          onChange={(e) => searchFilter(e.target.value)}
          style={{ width: '200px' }}
        />,
        <Authorized roles={[ROLE_CREATE_USER]} key="create">
          <Button
            key="btn"
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setModalVisible(undefined, 'user')}
          >
            {isMobile() ? '' : t('CREATE')}
          </Button>
        </Authorized>,
        <Export key="export" />
      ]}
    />
  );
});

export default UserHeader;
