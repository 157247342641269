import { Button, Col, Row } from 'antd';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { UISelect } from '~components/UI/Select/UISelect';
import { UIDateRangePicker } from '~components/UI/UIDateRangePicker';
import { useTranslation } from 'react-i18next';

import store from '../store';
import { Export } from './Export';

const FilterReportCampaign: FC = observer(() => {
  const {
    locationOptions,
    selectedTab,
    reportFilter,
    onReportFilterChange,
    onSelectReportFilterChange,
    bannerOptions,
    categoryOptions,
    locationReportFilter,
    categoryReportFilter,
    bannerReportFilter,
    setDrawerVisible
  } = store;

  const dateValue = [reportFilter.date?.gte, reportFilter.date?.lte];
  const { t } = useTranslation();

  return (
    <Row style={{ marginBottom: 8 }} gutter={8}>
      <Col sm={24} md={6}>
        <UIDateRangePicker
          style={{ width: '100%' }}
          value={dateValue as any}
          onChange={(e) => onReportFilterChange('date', e, selectedTab)}
          allowClear
        />
      </Col>
      <Col sm={24} flex="auto">
        <UISelect
          placeholder="Category"
          dataOptions={{
            data: categoryOptions,
            valueField: 'value',
            textField: 'text'
          }}
          mode="multiple"
          value={categoryReportFilter}
          onChange={(e) => onSelectReportFilterChange('categoryId', e)}
          allowClear
          showArrow
        />
      </Col>
      <Col sm={24} flex="auto">
        <UISelect
          placeholder="Location"
          dataOptions={{
            data: locationOptions,
            valueField: 'value',
            textField: 'text'
          }}
          style={{ width: '100%' }}
          onChange={(e) => onReportFilterChange('locationId', e, selectedTab)}
          showSearch
          mode="multiple"
          value={locationReportFilter}
          allowClear
          showArrow
        />
      </Col>
      <Col sm={24} flex="auto">
        <UISelect
          placeholder="Banner"
          dataOptions={{
            data: bannerOptions,
            valueField: 'value',
            textField: 'text'
          }}
          style={{ width: '100%' }}
          onChange={(e) => onReportFilterChange('bannerId', e, selectedTab)}
          showSearch
          allowClear
          mode="multiple"
          showArrow
          value={bannerReportFilter}
        />
      </Col>
      {['detail', 'mac'].includes(selectedTab) && (
        <Col sm={24} flex="auto">
          <Button title="Filter" style={{ marginRight: 5 }} onClick={() => setDrawerVisible()}>
            {t('FILTER')}
          </Button>
          <Export />
        </Col>
      )}
    </Row>
  );
});

export default FilterReportCampaign;
