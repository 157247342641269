import { UploadFile } from 'antd/lib/upload/interface';
import i18next from 'i18next';
import { debounce } from 'lodash';
import { action, computed, observable, reaction, runInAction } from 'mobx';
import { actionAsync, task } from 'mobx-utils';

import { GridReadyEvent } from '@ag-grid-community/core';
import { WhereArgs } from '@source/common';

import { ErrorNotify, SuccessNotify } from '~components/UI/Notification';
import { CustomerInput, CustomerUpdateArg } from '~graphql/_sdk';
import { getSdk } from '~graphql/sdk';

class CustomerStore {
  @observable modalVisible: boolean;
  @observable selectedItem: any;
  @observable public modalType: string;
  @observable public loading: boolean;
  @observable public pictureIds: string[];
  @observable public previewImage: string;
  @observable public previewVisible: boolean;
  @observable public urlLogo: string;
  @observable public where: WhereArgs;

  public fileList: UploadFile<any>[];

  constructor() {
    runInAction(() => {
      this.reset();
      reaction(
        () => this.selectedItem,
        selectedItem => {
          let fileListTmp = [];
          if (selectedItem?.urlLogo) {
            const obj = {
              uid: selectedItem?.logos.id,
              name: selectedItem?.logos?.id.split('/')[1],
              type: '',
              url: selectedItem?.logos?.path
            };
            fileListTmp = [...fileListTmp, obj];
          }
          this.setFileList(fileListTmp);
        }
      );
    });
  }

  @action public reset = () => {
    this.previewVisible = false;
    this.where = {};
    this.modalVisible = false;
    this.modalType = null;
    this.loading = true;
  };

  @action
  public handlePreview = e => {
    this.previewImage = e.url;
    this.previewVisible = !this.previewVisible;
  };

  @computed get computedWhere() {
    this.reload();
    return this.where;
  }

  @action public setModalVisible = (item = undefined, type: string = undefined) => {
    if (type) {
      this.modalType = type;
    }
    this.modalVisible = !this.modalVisible;
    this.selectedItem = item;
  };

  @actionAsync
  setFileList = async fileList => {
    this.fileList = fileList;
    this.urlLogo = fileList[0]?.uid || '';
  };

  @actionAsync
  public create = async (record: CustomerInput) => {
    const sdk = await task(getSdk());
    const { createCustomer } = await task(sdk.createCustomer({ record }));
    const { data, error } = createCustomer;

    if (error) {
      ErrorNotify(i18next.t(error.code));
    } else {
      SuccessNotify(i18next.t('CREATE'));
      this.loading = false;
      this.reload();
    }
  };

  @actionAsync
  public remove = async (_id: string) => {
    
    // const sdk = await task(getSdk());
    // const { removeCustomerById } = await task(sdk.removeCustomerById({ _id }));
    // const { data, error } = removeCustomerById;
    // if (error) {
    //   ErrorNotify(i18next.t(error.code));
    // } else {
    //   SuccessNotify(i18next.t('DELETE'));
    //   this.loading = false;
    //   this.reload();
    // }
  };

  @action
  public handleSearch = debounce(
    value => {
      runInAction(() => {
        this.where = { ...this.where, _search: value };
      });
    },
    500,
    { leading: false, trailing: true }
  );

  @actionAsync
  public update = async (record: CustomerUpdateArg) => {
    const sdk = await task(getSdk());
    const { updateCustomerById } = await task(
      sdk.updateCustomerById({ _id: this.selectedItem?._id, record })
    );
    const { data, error } = updateCustomerById;

    if (error) {
      ErrorNotify(i18next.t(error.code));
    } else {
      SuccessNotify(i18next.t('UPDATE'));
      this.loading = false;
      this.reload();
    }
  };

  @action public handleGridReady = ({ api }: GridReadyEvent) => {
    this.reload = () => {
      api.onFilterChanged();
    };
  };

  @action
  public reload = () => undefined;
}

export default new CustomerStore();
