import React from 'react';

import { usePromise } from '~utils/usePromise';

import { getSdk, GraphQLSDK } from './sdk';

const Context = React.createContext<GraphQLSDK>(null);

const GraphqQLSDKProvider = ({ children }) => {
  const sdk = usePromise(getSdk);
  return (
    <Context.Provider value={sdk}>{children}</Context.Provider>
  );
};

const useGraphQLSDK = (): GraphQLSDK => {
  const sdk = usePromise(getSdk);
  return sdk;
}

export { Context as GraphqQLSDKContext, useGraphQLSDK };
export default GraphqQLSDKProvider;