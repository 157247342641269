import { Descriptions, Drawer } from 'antd';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import store from './store';

const Drawers: FC = observer(() => {
  const { t } = useTranslation();
  const { selectedItem, drawerVisible, setDrawerVisible } = store;
  const initValues = {
    email: get(selectedItem, 'email', ''),
    name: get(selectedItem, 'name', ''),
    phone: get(selectedItem, 'phone', ''),
    profileId: get(selectedItem, 'profile.display'),
    ownerType: get(selectedItem, 'ownerType'),
    password: get(selectedItem, 'password', ''),
    address: get(selectedItem, 'extra.address', ''),
    company: get(selectedItem, 'extra.company', ''),
    tax: get(selectedItem, 'extra.tax', ''),
    website: get(selectedItem, 'extra.website', ''),
    nameUser: get(selectedItem, 'extra.nameUser', ''),
    phoneUser: get(selectedItem, 'extra.phoneUser', ''),
    emailUser: get(selectedItem, 'extra.emailUser', '')
  };
  return (
    <Drawer
      width={640}
      title={t('DETAIL')}
      placement="right"
      closable={false}
      onClose={setDrawerVisible}
      visible={drawerVisible}
    >
      <Descriptions bordered title="USERS_INFORMATION" size="small">
        <Descriptions.Item label={t('EMAIL')}>{initValues.email}</Descriptions.Item>
        <Descriptions.Item span={2} label={t('NAME')}>
          {initValues.name}
        </Descriptions.Item>
        <Descriptions.Item label={t('PROFILE')}>{initValues.profileId}</Descriptions.Item>
        <Descriptions.Item span={2} label={t('Phone')}>
          {initValues.phone}
        </Descriptions.Item>
      </Descriptions>

      <Descriptions
        bordered
        title={t('OWNER_INFOMATION')}
        style={{ marginTop: '20px' }}
        size="small"
      >
        <Descriptions.Item span={3} label={t('COMPANY')}>
          {initValues.company}
        </Descriptions.Item>
        <Descriptions.Item label={t('ADDRESS')}>{initValues.address}</Descriptions.Item>
        <Descriptions.Item span={2} label={t('TAX')}>
          {initValues.tax}
        </Descriptions.Item>
        <Descriptions.Item label={t('WEBSITE')}>{initValues.website}</Descriptions.Item>
      </Descriptions>

      <Descriptions
        bordered
        title={t('REFERENCES_INFOMATION')}
        style={{ marginTop: '20px' }}
        size="small"
      >
        <Descriptions.Item span={2} label={t('NAME_USER')}>
          {initValues.nameUser}
        </Descriptions.Item>
        <Descriptions.Item label="Email User">{initValues.emailUser}</Descriptions.Item>
        <Descriptions.Item span={2} label={t('PHONE_USER')}>
          {initValues.phoneUser}
        </Descriptions.Item>
      </Descriptions>
    </Drawer>
  );
});

export default Drawers;
