import '@ant-design/compatible/assets/index.css';

import { Button, Modal } from 'antd';
import Input from 'antd/lib/input';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import useForm from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { Form } from '@ant-design/compatible';
import { MailFilled } from '@ant-design/icons';

import UIField from '~components/UI/UIField';

import store from './store';

const ModalForgotScreen: FC = observer(() => {
  const { t } = useTranslation();
  const { setModalVisible, modalVisible, sendMail } = store;

  interface FormValues {
    email: string;
  }

  const validationSchema = yup.object().shape<FormValues>({
    email: yup.string().required('Email is required')
  });

  const formProps = useForm<FormValues>({ validationSchema });

  const {
    handleSubmit,
    // reset,
    formState: { isSubmitting }
  } = formProps;

  const onSubmit = async ({ email }) => {
    sendMail(email);
  };

  const onCancel = () => {
    setModalVisible();
  };

  const footer = (
    <div>
      <Button danger ghost onClick={() => onCancel()}>
        {t('CANCEL')}
      </Button>
      <Button htmlType="submit" form="forgetPassForm" type="primary" loading={isSubmitting}>
        {t('RESTORE_PASSWORD')}
      </Button>
    </div>
  );

  const handleCancel = () => {
    setModalVisible();
  };
  return (
    <Modal
      title={t('FORGOT_PASSWORD')}
      visible={modalVisible}
      cancelButtonProps={{ danger: true, ghost: true }}
      footer={footer}
      onCancel={handleCancel}
    >
      <Form onSubmit={handleSubmit(onSubmit)} id="forgetPassForm">
        <UIField
          {...formProps}
          name="email"
          label={t('EMAIL')}
          fieldProps={{
            name: 'email',
            type: 'email',
            placeholder: t('INPUT_EMAIL'),
            prefix: <MailFilled style={{ color: 'rgba(0,0,0,.25)' }} />
          }}
          fieldComponent={Input}
        />
      </Form>
    </Modal>
  );
});

export default ModalForgotScreen;
