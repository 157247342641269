import i18next from 'i18next';
import { debounce } from 'lodash';
import { action, computed, observable, runInAction } from 'mobx';
import { actionAsync, task } from 'mobx-utils';

import { GridReadyEvent } from '@ag-grid-community/core';

import { ErrorNotify, SuccessNotify } from '~components/UI/Notification';
import * as _sdk from '~graphql/_sdk';
import { getSdk } from '~graphql/sdk';
import * as common from '@source/common';

export class LogsStore {
  @observable public loading: boolean;
  @observable public modalVisible: boolean;
  @observable public selectedItem?: _sdk.LogsFragment;
  @observable public where: _sdk.LogsFilter;
  @observable public whereCampaignConfig: _sdk.LogsFilter;

  constructor() {
    runInAction(() => {
      this.loading = false;
      this.modalVisible = false;
      this.selectedItem = undefined;
      this.where = {
        _operators: {
          type: {
            in: [common.LogsType.LOGIN, common.LogsType.LOGOUT]
          }
        }
      };
      this.whereCampaignConfig = {
        type: common.LogsType.CAMPAIGN_CONFIG
      };
    });
  }

  @action
  setSearchText = debounce((text) => {
    runInAction(() => {
      this.where = { ...this.where, _search: text };
      this.reload();
    });
  }, 500);

  @action
  setModalVisible = (item: any = undefined) => {
    this.selectedItem = item;
    this.modalVisible = !this.modalVisible;
  };

  @action
  public handleGridReady = ({ api }: GridReadyEvent) => {
    this.reload = () => {
      api.onFilterChanged();
    };
  };

  @action
  public handleGridCampaignConfigReady = ({ api }: GridReadyEvent) => {
    this.reload = () => {
      api.onFilterChanged();
    };
  };

  @action
  public reload = () => undefined;
}

export default new LogsStore();
