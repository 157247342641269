import { observable, runInAction } from 'mobx';
import { actionAsync, task } from 'mobx-utils';

import { getSdk } from '~graphql/sdk';

class Store {
  @observable public isVerifiedEmail: boolean;

  @actionAsync
  verifyEmail = async ( key: string) => {
    const sdk = await task(getSdk());
    const { verifyEmail } = await task(sdk.verifyEmail({ key }));
    runInAction(() => {
      this.isVerifiedEmail = verifyEmail;
    });
  };
}

export default new Store();
