import arrayMove from 'array-move';
import i18next from 'i18next';
import { filter, findIndex, get, intersection, isEmpty, without } from 'lodash';
import { action, computed, observable, runInAction } from 'mobx';
import { Fields, JsonTree } from 'react-awesome-query-builder';

import commonDataStore from '~common-stores/commonDataStore';
import { ErrorNotify, SuccessNotify, WarningNotify } from '~components/UI/Notification';
import { getConfig } from '~config/index';
import { Campaign, CampaignConfigBanner } from '~graphql/_sdk';
import { getSdk } from '~graphql/sdk';

export interface BannerConfigInterface {
  bannerId: string;
  bannerName: string;
  query: string;
  queryTree: JsonTree;
}

export interface ParamConfigInterface {
  name: string;
  value: string;
}

export interface LocationGroup {
  groupName: string;
  locations: CampaignLocations[];
}

export interface CustomFieldInterface {
  isChecked: boolean;
  fieldName: string;
  fieldValue: string;
  fieldOptions: string[];
}

export interface Audience {
  query: JSON;
  queryTree: JsonTree;
}

export interface Banner {
  _id: string;
  name: string;
}

export interface CampaignLocations {
  locationId?: string;
  locationName?: string;
  categoryId?: string;
}

export class CampaignConfig {
  @observable checkboxLocations: CampaignLocations[];
  @observable campaign: Campaign;
  @observable loading: boolean;
  @observable loadingBanner: boolean;

  // TargetObject
  @observable audience: Audience;

  // BannerConfig
  @observable.shallow listBanners: CampaignConfigBanner[];
  @observable.shallow listLocations: CampaignLocations[];
  @observable.shallow locationMapping: { [field: string]: any };

  // WebhookConfig
  @observable.shallow webhookParams: ParamConfigInterface[];
  @observable webhookEnable: boolean;
  // CRMConfig
  @observable fields: string[];
  @observable customFields: CustomFieldInterface[];

  // LocationGroup
  @observable isShowGroup: boolean;
  @observable locationGroups: LocationGroup[];
  @observable addLocationsMode: boolean;
  @observable currentGroupId: number;
  @observable selectedLocations: CampaignLocations[];
  @observable isCheckAll: boolean;

  // validataion
  @observable isValidLocationGroup: boolean;

  constructor() {
    runInAction(() => {
      this.loading = true;
      this.isValidLocationGroup = true;

      // BannerConfig
      this.listBanners = [];
      this.listLocations = [];

      // WebhookParams
      this.webhookParams = [];
      this.webhookEnable = false;

      // LocationGroup
      this.locationGroups = [];
      this.selectedLocations = [];
      this.checkboxLocations = [];
      this.addLocationsMode = false;
      this.isShowGroup = false;
      this.isCheckAll = false;

      // CRMConfig
      this.fields = [];
      this.customFields = [
        {
          isChecked: false,
          fieldName: 'field0',
          fieldValue: '',
          fieldOptions: []
        },
        {
          isChecked: false,
          fieldValue: '',
          fieldName: 'field1',
          fieldOptions: []
        },
        {
          isChecked: false,
          fieldName: 'field2',
          fieldValue: '',
          fieldOptions: []
        },
        {
          isChecked: false,
          fieldName: 'field3',
          fieldValue: '',
          fieldOptions: []
        },
        {
          isChecked: false,
          fieldName: 'field4',
          fieldValue: '',
          fieldOptions: []
        },
        {
          isChecked: false,
          fieldName: 'field5',
          fieldValue: '',
          fieldOptions: []
        },
        {
          isChecked: false,
          fieldName: 'field6',
          fieldValue: '',
          fieldOptions: []
        },
        {
          isChecked: false,
          fieldName: 'field7',
          fieldValue: '',
          fieldOptions: []
        },
        {
          isChecked: false,
          fieldName: 'field8',
          fieldValue: '',
          fieldOptions: []
        },
        {
          isChecked: false,
          fieldName: 'field9',
          fieldValue: '',
          fieldOptions: []
        }
      ];
    });
  }

  @computed public get listBanner() {
    return this.listBanners;
  }

  // Taqrget Object
  @action setAudienceQuery = (query: JSON) => {
    this.audience = { ...this.audience, query };
  };

  @action setAudienceQueryTree = (queryTree: JsonTree) => {
    this.audience = { ...this.audience, queryTree };
  };

  @action getAudience = () => {
    return this.audience;
  };

  // CRMConfig
  @action updateCustomField = (index: number, record: CustomFieldInterface) => {
    this.customFields[index] = record;
  };

  @action updateFields = (value) => {
    this.fields = value;
  };

  // BannerConfig

  @action addBanner = (record: BannerConfigInterface) => {
    this.listBanners.push(record);
  };

  @action removeBanner = (index: number) => {
    this.listBanners.splice(index, 1);
  };

  @action addWebhookParam = (record: ParamConfigInterface) => {
    this.webhookParams.push(record);
  };

  @action removeWebhookParams = (index: number) => {
    this.webhookParams.splice(index, 1);
  };

  @action updateParam = (index: number, field: string, value: string) => {
    this.webhookParams[index][field] = value;
  };

  @action updateTitleBanner = (index: number, value: string) => {
    let banner = null;
    try {
      banner = JSON.parse(value);
      // eslint-disable-next-line no-empty
    } catch (e) {}

    if (banner) {
      this.listBanners[index].bannerName = banner.name;
      this.listBanners[index].bannerId = banner._id;
    }
  };

  @action updateQueryTreeBanner = (index: number, value: JsonTree) => {
    this.listBanners[index].queryTree = value;
  };

  @action updateQueryBanner = (index: number, value: string) => {
    this.listBanners[index].query = value;
  };

  @action sortArray = ({ oldIndex, newIndex }) => {
    this.listBanners = arrayMove(this.listBanners, oldIndex, newIndex);
  };

  @action setIsShowGroup = (value: boolean) => {
    this.isShowGroup = value;
  };

  @action setWebhookEnable = (value: boolean) => {
    this.webhookEnable = value;
  };

  @action addLocationGroup = (record: LocationGroup) => {
    this.locationGroups.push(record);
  };

  @action removeLocationGroup = (index) => {
    let check;
    this.locationGroups = this.locationGroups.filter((item, idx) => {
      check = idx !== index;
      if (!check) {
        this.checkboxLocations.push(...item.locations);
      }
      return check;
    });
  };

  @action updateLocationGroup = (index: number, record: LocationGroup) => {
    this.locationGroups[index] = record;
  };

  @action addLocation = (location) => {
    this.checkboxLocations.push(location);
  };

  @action removeLocation = (location) => {
    this.checkboxLocations.filter((item) => item !== location);
  };

  @action setupAddLocationMode = (value: boolean, index: number) => {
    this.addLocationsMode = value;
    this.currentGroupId = index;
  };

  @action addSelectedLocations = (values: CampaignLocations[]) => {
    this.selectedLocations = values;
  };

  @action clearSelectedLocations = () => {
    this.selectedLocations = [];
  };

  @action handleAddLocations = () => {
    this.checkboxLocations = without(this.checkboxLocations, ...this.selectedLocations);
    const record = this.locationGroups[this.currentGroupId];
    const locations = [...record.locations, ...this.selectedLocations];

    this.locationGroups[this.currentGroupId].locations = locations;
    this.setupAddLocationMode(false, -1);
    this.setCheckAll(false);
  };

  @action setCheckAll = (value: boolean) => {
    if (value) {
      this.selectedLocations = this.checkboxLocations;
    } else {
      this.selectedLocations = [];
    }
    this.isCheckAll = value || false;
  };

  @action handleSearchOnchange = (value: string) => {
    if (!value) {
      this.filterAvailableLocation(this.locationGroups);
    } else {
      this.checkboxLocations = this.checkboxLocations.filter((location) => {
        return location.locationName.includes(value);
      });
      this.selectedLocations = intersection(this.checkboxLocations, this.selectedLocations);
    }
  };

  // submit data
  @action updateCustom = () => {
    const groups = [];
    let isValid = true;
    this.locationGroups.forEach((e) => {
      if (!e.groupName || (!!e.locations && e.locations.length === 0)) {
        isValid = false;
      } else {
        groups.push(e);
      }
    });

    const request = {
      group: {
        show: this.isShowGroup,
        list: groups
      }
    };

    return { request, isValid };
  };

  @action updateConfig = async (params) => {
    const banners = [];
    this.listBanners.forEach((banner) => {
      if (banner.bannerName) {
        const result = {
          bannerId: banner.bannerId,
          bannerName: banner.bannerName,
          query: banner.query,
          queryTree: banner.queryTree
        };
        banners.push(result);
      }
    });

    if (banners.length <= 0) {
      WarningNotify(i18next.t('BANNER_CANNOT_BE_BLANK'));
      return;
    }

    const audience = {
      acceptRules: this.audience.query ? this.audience : []
    };

    const crm = {};
    if (this.fields.length > 0) {
      this.fields.forEach((e) => {
        crm[e] = e;
      });
    }
    const crmCustomValues = {};

    this.customFields.forEach((e) => {
      if (e.isChecked) {
        crm[e.fieldName] = e.fieldValue;
        crmCustomValues[e.fieldName] = e.fieldOptions;
      }
    });

    const { isValid, request: customs } = this.updateCustom();
    if (!isValid) {
      ErrorNotify('Location Group is invalid!, please check location group');
      this.isValidLocationGroup = false;
      return;
    }
    this.isValidLocationGroup = true;

    const { webhook } = params;
    if (webhook) {
      webhook.enabled = this.webhookEnable;
      webhook.params = this.webhookParams;
    }

    const sdk = await getSdk();
    const record = {
      config: { audience, banners, crm, crmValue: crmCustomValues, ...params },
      customs
    };

    const {
      updateConfig: { data }
    } = await sdk.updateCampaignConfigById({
      id: this.campaign._id,
      record
    });

    if (data) {
      SuccessNotify(i18next.t('UPDATED'));
    } else {
      ErrorNotify(i18next.t('UPDATED'));
    }
  };

  // query

  @action getCampaignById = async (id: string) => {
    const sdk = await getSdk();
    const { locations } = commonDataStore;

    const campaign = await sdk.findCampaignConfigById({ _id: id });
    if (campaign.findCampaignById) {
      const data = campaign.findCampaignById;
      runInAction(() => {
        this.campaign = data as Campaign;
        this.audience = get(this.campaign, 'config.audience.acceptRules', {}) as Audience;
        this.listBanners = get(this.campaign, 'config.banners') || [];

        this.webhookParams = get(this.campaign, 'config.webhook.params') || [];
        this.webhookEnable = get(this.campaign, 'config.webhook.enabled', false);

        const fields = { ...get(this.campaign, 'config.crm', {}) };
        this.customFields = this.customFields.map((e) => {
          delete fields[e.fieldName];
          return {
            isChecked: !!get(this.campaign, `config.crm[${e.fieldName}]`, false),
            fieldName: e.fieldName,
            fieldValue: get(this.campaign, `config.crm[${e.fieldName}]`),
            fieldOptions: get(this.campaign, `config.crmValue[${e.fieldName}]`)
          };
        });

        this.fields = Object.values(fields);

        const locationGroups = get(this.campaign.customs, 'group.list', []);

        this.filterAvailableLocation(locationGroups);

        this.locationGroups = locationGroups;
        this.isShowGroup = get(this.campaign.customs, 'group.show', false);

        this.loading = false;
      });
    }

    const { findManyCampaignMapping } = await sdk.findManyCampaignMapping({
      where: { campaignId: id }
    });

    runInAction(() => {
      if (isEmpty(this.locationMapping)) {
        this.locationMapping = locations.reduce((all, value) => {
          all[value._id] = value.name;
          return all;
        }, {});
      }
      this.listLocations = findManyCampaignMapping.map((item) => {
        return {
          locationId: item.locationId,
          locationName: this.locationMapping[item.locationId]
        };
      });
    });
  };

  @action filterAvailableLocation = (locationGroups) => {
    const customLocations = [];

    if (locationGroups.length > 0) {
      locationGroups.forEach((e) => {
        customLocations.push(...e.locations);
      });
    }

    this.checkboxLocations = filter(
      this.campaign.locations,
      (o) => findIndex(customLocations, ['locationId', o.locationId]) < 0
    );
  };

  @action
  genFields = (customFields: CustomFieldInterface[] = []) => {
    const subfields = {};
    customFields.forEach((element) => {
      if (element.isChecked) {
        const tmp = {
          label: element.fieldValue,
          type: 'select',
          valueSources: ['value'],
          listValues:
            element.fieldOptions &&
            element.fieldOptions.map((e) => {
              return { value: e, title: e };
            }),
          operators: ['select_any_in', 'select_not_any_in', 'is_empty', 'is_not_empty']
        };
        subfields[element.fieldName] = tmp;
      }
    });

    const survey = {
      label: 'Survey',
      tooltip: '',
      type: '!struct',
      subfields
    };

    const fields: Fields = {
      ua: {
        label: i18next.t('USER_AGENT'),
        tooltip: 'Group of fields',
        type: '!struct',
        subfields: {
          device: {
            label: i18next.t('DEVICE'),
            type: 'select',
            valueSources: ['value', 'field'],
            listValues: {
              Mobile: 'Mobile',
              Tablet: 'Tablet',
              PC: 'PC',
              Laptop: 'Laptop'
            },
            operators: ['select_any_in', 'select_not_any_in', 'is_empty', 'is_not_empty']
          },
          os: {
            label: i18next.t('OS'),
            type: 'select',
            valueSources: ['value', 'field'],
            listValues: {
              Android: 'Android',
              iOS: 'iOS',
              Windows: 'Windows',
              maxOs: 'Mac OS X',
              Linux: 'Linux'
            },
            operators: ['select_any_in', 'select_not_any_in', 'is_empty', 'is_not_empty']
          }
          // deviceCode: {
          //   label: i18next.t('MODEL_DEVICE'),
          //   type: 'select',
          //   valueSources: ['value'],
          //   allowCustomValues: true,
          //   operators: ['select_any_in', 'select_not_any_in', 'is_empty', 'is_not_empty']
          // }
        }
      },
      audience: {
        label: i18next.t('AUDIENCE'),
        tooltip: 'Group of fields',
        type: '!struct',
        subfields: {
          campaign: {
            label: i18next.t('CAMPAIGN_VIEW'),
            type: 'number',
            operators: ['equal']
          },
          name: {
            label: i18next.t('NAME'),
            type: 'text',
            operators: ['equal', 'not_equal', 'is_empty', 'is_not_empty']
          },
          phone: {
            label: i18next.t('PHONE'),
            type: 'text',
            operators: ['equal', 'not_equal', 'is_empty', 'is_not_empty']
          },
          email: {
            label2: 'Email',
            type: 'text',
            operators: ['equal', 'not_equal', 'is_empty', 'is_not_empty']
          },
          gender: {
            label: 'Gender',
            type: 'select',
            valueSources: ['value'],
            listValues: {
              male: 'Male',
              female: 'Female',
              other: 'Other'
            },
            allowCustomValues: true,
            operators: ['select_any_in', 'select_not_any_in', 'is_empty', 'is_not_empty']
          },
          age: {
            label: 'Age',
            type: 'select',
            valueSources: ['value'],
            listValues: {
              under_18: 'Under 18',
              from_18_to_24: 'From 18 to 24',
              from_25_to_34: 'From 25 to 34',
              from_35_to_45: 'From 35 to 45',
              higher_45: 'Higher 45'
            },
            allowCustomValues: true,
            operators: ['select_any_in', 'select_not_any_in', 'is_empty', 'is_not_empty']
          },
          income: {
            label: 'Income',
            type: 'select',
            valueSources: ['value'],
            listValues: {
              under_10_mil: 'Under 10 Million',
              from_10_to_20_mil: 'From 10 to 20 Million',
              from_20_to_50_mil: 'From 20 to 50 Million',
              higher_50_mil: 'Higher 50 Million'
            },
            allowCustomValues: true,
            operators: ['select_any_in', 'select_not_any_in', 'is_empty', 'is_not_empty']
          },
          marital: {
            label: 'Mariage',
            type: 'select',
            listValues: {
              single: 'Single',
              in_a_relationship: 'In a relationshiop',
              married: 'Married',
              with_kids: 'with_kids'
            },
            allowCustomValues: true,
            operators: ['select_any_in', 'select_not_any_in', 'is_empty', 'is_not_empty']
          }
        }
      },
      currentLocationId: {
        label: i18next.t('LOCATION'),
        type: 'select',
        valueSources: ['value'],
        listValues: this.listLocations.map((e) => {
          return { value: e.locationId, title: e.locationName };
        }),
        operators: ['select_any_in', 'select_not_any_in', 'is_empty', 'is_not_empty']
      },
      survey
    };

    return fields;
  };

  @action
  public async preview(bannerId: string, redirect = 'https://google.com') {
    const { restApiEndpoint } = await getConfig();
    const params = [`bannerId=${bannerId}`, redirect && `redirect=${encodeURIComponent(redirect)}`]
      .filter(Boolean)
      .join('&');
    window.open(`${restApiEndpoint}/renderer/preview?${params}`, '_blank');
  }
}

export default new CampaignConfig();
