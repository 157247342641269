import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ReactResizeDetector from 'react-resize-detector';

import { ColDef } from '@ag-grid-community/core';

import AgGridServer from '~components/UI/AgGrid/AgGridServer';
import { useGraphQLSDK } from '~graphql/GraphQLSDKProvider';

import store from './store';
import { DATE_TIME_FORMAT, LogsType, WhereArgs } from '@source/common';
import { format } from '~utils/formatDate';

const LogsGridLogin: FC = observer(() => {
  const { t } = useTranslation();
  const sdk = useGraphQLSDK();
  const { handleGridReady, where } = store;

  const columnDefs: ColDef[] = [
    {
      headerName: t('NO'),
      field: '_id',
      minWidth: 50,
      maxWidth: 50,
      sortable: false,
      cellRenderer: ({ rowIndex }) => rowIndex + 1
    },
    {
      headerName: t('TYPE'),
      field: 'type',
      minWidth: 120,
      cellRenderer: ({ value }) => {
        let result = t('LOGIN');
        if (value === LogsType.LOGOUT) {
          result = t('user.LOGOUT');
        }
        return result;
      }
    },
    {
      headerName: t('DATE'),
      field: 'createdAt',
      sort: 'desc',
      minWidth: 150,
      maxWidth: 150,
      cellRenderer: ({ value }) => format(value, DATE_TIME_FORMAT)
    },
    {
      headerName: t('NAME'),
      field: 'user.name',
      minWidth: 150,
      sortable: false
    },
    {
      headerName: t('EMAIL'),
      field: 'user.email',
      minWidth: 150,
      sortable: false
    },
    {
      headerName: t('DESCRIPTION'),
      field: 'description',
      minWidth: 110,
      sortable: false
    }
  ];

  return (
    <ReactResizeDetector handleHeight>
      {({ height }) => {
        return (
          <AgGridServer
            options={{
              columnDefs,
              cacheBlockSize: 50,
              paginationPageSize: 50,
              getRowHeight: () => 50
            }}
            onQuery={sdk.findManyLogs}
            queryKey="findManyLogs"
            onCountQuery={sdk.countLogs}
            countQueryKey="countLogs"
            onGridReady={handleGridReady}
            where={where as WhereArgs}
            height={height}
          />
        );
      }}
    </ReactResizeDetector>
  );
});

export default LogsGridLogin;
