import i18next from 'i18next';
import { debounce } from 'lodash';
import { action, observable, runInAction } from 'mobx';
import { actionAsync, task } from 'mobx-utils';

import { GridReadyEvent } from '@ag-grid-community/core';
import { stringToJson } from '@source/common/src/utils/stringToJson';

import { ErrorNotify, SuccessNotify } from '~components/UI/Notification';
import { Common, CommonFilter, CommonInput, CommonUpdateArg } from '~graphql/_sdk';
import { getSdk } from '~graphql/sdk';

class SelectOptionStore {
  @observable public loading: boolean;
  @observable public modalVisible: boolean;
  @observable public selectedItem?: Common;
  @observable public where: CommonFilter;
  @observable public types: any;
  @observable public grou;
  constructor() {
    runInAction(() => {
      this.loading = false;
      this.modalVisible = false;
      this.selectedItem = undefined;
      this.where = {};
      this.types = [];
    });
  }
  @action
  setSearchText = debounce((text) => {
    runInAction(() => {
      this.where = { ...this.where, _search: text };
      this.reload();
    });
  }, 500);

  @action
  setModalVisible = (item: Common = undefined) => {
    this.selectedItem = item;
    this.modalVisible = !this.modalVisible;
  };

  @actionAsync
  public create = async (record: CommonInput) => {
    const sdk = await task(getSdk());
    if (record.meta) {
      record.meta = JSON.parse(record.meta);
      if (typeof record.meta === 'string') {
        record = stringToJson(record, 'meta');
      }
    }
    const {
      createCommon: { error }
    } = await task(sdk.createCommon({ record }));

    if (error) {
      ErrorNotify(error.code);
    } else {
      SuccessNotify(i18next.t('CREATE'));
      this.reload();
    }
  };

  @actionAsync
  public update = async (_id: string, record: CommonUpdateArg) => {
    const sdk = await task(getSdk());
    if (record.meta) {
      record.meta = JSON.parse(`${record.meta}`);
      if (typeof record.meta === 'string') {
        record = stringToJson(record, 'meta');
      }
    }
    const {
      updateCommonById: { error }
    } = await task(sdk.updateCommonById({ _id, record }));

    if (error) {
      ErrorNotify(error.code);
    } else {
      SuccessNotify(i18next.t('UPDATE'));
      this.reload();
    }
  };

  @actionAsync
  public remove = async (_id: string) => {
    const sdk = await task(getSdk());
    const {
      removeCommonById: { error }
    } = await task(sdk.removeCommonById({ _id }));
    if (error) {
      ErrorNotify(error.code);
    } else {
      SuccessNotify(i18next.t('DELETE'));
      this.reload();
    }
  };

  @action
  public handleGridReady = ({ api }: GridReadyEvent) => {
    this.reload = () => {
      api.onFilterChanged();
    };
  };

  @action
  public reload = () => undefined;
}
export default new SelectOptionStore();
