import { Button, Col, PageHeader, Result, Row, Switch } from 'antd';
import { observer } from 'mobx-react';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { CheckOutlined, SmileTwoTone } from '@ant-design/icons';
import { LocationStatus } from '@source/common';

import { UISelect } from '~components/UI/Select/UISelect';
import { useStore } from '~context/react-context';
import { parseQuery, stringifyQuery } from '~utils/query-string';
import useQuery from '~utils/useQuery';

import Grid from './Grid';
import store from './store';

const EditorView: FC = observer(() => {
  const { t } = useTranslation();
  const {
    commonDataStore: { locations, loadAllLocations, commonCity, loadCommonCity, categories }
  } = useStore();
  const { setLocation, locationId, updatePriority, resetFields, loading } = store;

  const location = useLocation();
  const { locationId: initLocationId } = parseQuery(location.search) as any;

  const history = useHistory();

  const query = useQuery();
  const id = query.get('locationId') || undefined;

  useEffect(() => {
    let fetch = true;
    if (fetch) {
      loadAllLocations({ status: LocationStatus.ACTIVE }).finally(() => {
        if (id) {
          setLocation(id);
        }
      });
    }
    loadCommonCity();
    return () => {
      fetch = false;
      resetFields();
    };
  }, []);

  const onChange = (value) => {
    history.push({ search: stringifyQuery({ locationId: value }) });
    setLocation(value);
  };

  const loadLocation = () => {
    const cityMap = commonCity.reduce((all, item) => {
      all[item.value] = item.text;
      return all;
    }, {});

    const categoryMap = categories.reduce((all, item) => {
      all[item.value] = item.icon;
      return all;
    }, {});

    const locationMap = locations.map((item) => ({
      ...item,
      city: cityMap[item.city] || '',
      icon: categoryMap[item.categoryId] || ''
    }));
    return locationMap;
  };

  const WaitingForSelect = () => (
    <Result icon={<SmileTwoTone />} title={t('PLEASE_SELECT_A_LOCATION')} />
  );

  return (
    <>
      <PageHeader
        style={{ background: '#fff', padding: '16px' }}
        title={t('CAMPAIGN_PRIORITY')}
        extra={[
          <Row key="extra" justify="center">
            <Col>
              <UISelect
                dataOptions={{
                  data: loadLocation(),
                  valueField: '_id',
                  textField: 'name'
                  // template: locationsTemplate
                }}
                placeholder={t('CHOOSE_LOCATION')}
                loading={loading}
                key="searchLocation"
                onChange={onChange}
                defaultValue={id}
                style={{ width: 300 }}
                showSearch
                showArrow
              />
            </Col>
            <Col>
              <Button
                style={{ marginLeft: '8px' }}
                disabled={!locationId}
                type="primary"
                key="saveButton"
                icon={<CheckOutlined />}
                onClick={() => {
                  updatePriority();
                }}
              >
                {t('SAVE')}
              </Button>
            </Col>
          </Row>,
          <div key="random" style={{ textAlign: 'right' }}>
            <span>{t('RANDOM_CAMPAIGN')}</span>
            <Switch
              key="switch"
              style={{ marginLeft: 5, width: '50px' }}
              checkedChildren={t('ON')}
              unCheckedChildren={t('OFF')}
              // onChange={(e) => setSwitch(e)}
            />
          </div>
        ]}
      />
      {!locationId && !initLocationId ? (
        <WaitingForSelect />
      ) : (
        locationId && (
          <>
            <Grid />
          </>
        )
      )}
    </>
  );
});

export default EditorView;
