import '@ant-design/compatible/assets/index.css';

import { Col, Input, Modal, Row } from 'antd';
import i18next from 'i18next';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import useForm from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { Form } from '@ant-design/compatible';

import UIField from '~components/UI/UIField';
import { CommonInput, CommonUpdateArg } from '~graphql/_sdk';

import store from './store';

interface FormValues {
  value?: string;
  text?: string;
  type?: string;
  icon?: string;
  meta?: string;
}
const isValidJSON = (string) => {
  try {
    JSON.parse(string);
  } catch (e) {
    return false;
  }
  return true;
};

yup.addMethod(yup.string, 'JSON', function (message: string) {
  return this.test('validate-JSON', message, (value) => isValidJSON(value));
});

const validationSchema = yup.object().shape<FormValues>({
  text: yup.string().required(i18next.t('COMMON_DATA_VALIDATE_TEXT_REQUIRE')),
  value: yup.string().required(i18next.t('COMMON_DATA_VALIDATE_VALUE_REQUIRE')),
  type: yup.string().required(i18next.t('COMMON_DATA_VALIDATE_TYPE_REQUIRE')),
  icon: yup.string(),
  meta: yup.string().JSON(i18next.t('NOTI_FORMAT_DATA_JSON'))
});

const BrandAPForm: FC = observer(() => {
  const { modalVisible, setModalVisible, create, update, selectedItem } = store;
  const { t } = useTranslation();

  const initValues: FormValues = {
    text: get(selectedItem, 'text', ''),
    value: get(selectedItem, 'value', ''),
    type: get(selectedItem, 'type', 'brand_ap'),
    icon: get(selectedItem, 'icon', ''),
    meta: JSON.stringify(get(selectedItem, 'meta', JSON.parse('{}')))
  };

  const formProps = useForm({ validationSchema });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting }
  } = formProps;

  const onCancel = () => {
    reset(initValues);
    setModalVisible();
  };

  const onSubmit = async (record: FormValues) => {
    const { text, value, type, icon, meta } = record;
    const records = { text, value: `brand_${value}`, type, icon, meta };
    if (selectedItem) {
      await update(get(selectedItem, '_id'), records as CommonUpdateArg);
    } else {
      await create(records as CommonInput);
    }
    onCancel();
  };

  return (
    <Modal
      title={t('BRAND_AP')}
      visible={modalVisible}
      cancelText={t('CLOSE')}
      cancelButtonProps={{ danger: true, ghost: true }}
      onCancel={() => onCancel()}
      okText={selectedItem ? t('UPDATE') : t('CREATE')}
      okButtonProps={{
        htmlType: 'submit',
        form: 'BrandAPForm',
        type: 'primary',
        loading: isSubmitting
      }}
    >
      <Form
        id="BrandAPForm"
        onSubmit={handleSubmit(onSubmit)}
        layout='vertical'
      >
        <Row gutter={8}>
          <Col xs={24} md={12}>
            <UIField
              {...formProps}
              name="text"
              label={t('TEXT')}
              fieldProps={{
                placeholder: t('COMMON_DATA_PLACEHOLDER_TEXT')
              }}
              fieldComponent={Input}
              defaultValue={initValues.text}
            />
          </Col>
          <Col xs={24} md={12}>
            <UIField
              {...formProps}
              name="value"
              label={t('VALUE')}
              fieldProps={{
                placeholder: t('COMMON_DATA_PLACEHOLDER_VALUE'),
                disabled: !!selectedItem
              }}
              fieldComponent={Input}
              defaultValue={initValues.value}
            />
          </Col>
        </Row>
        <Row gutter={8}>
          <Col xs={24} md={12}>
            <UIField
              {...formProps}
              name="type"
              label={t('TYPE')}
              fieldProps={{
                placeholder: t('COMMON_DATA_PLACEHOLDER_TYPE'),
                readOnly: true
              }}
              fieldComponent={Input}
              defaultValue={initValues.type}
            />
          </Col>
          <Col xs={24} md={12}>
            <UIField
              {...formProps}
              name="icon"
              label="Icon"
              fieldProps={{
                placeholder: t('COMMON_DATA_PLACEHOLDER_ICON')
              }}
              fieldComponent={Input}
              defaultValue={initValues.icon}
            />
          </Col>
        </Row>
        <Form.Item label="Meta" >
          <UIField
            {...formProps}
            name="meta"
            fieldProps={{
              placeholder: i18next.t('COMMON_DATA_PLACEHOLDER_META'),
              rows: 6
            }}
            fieldComponent={Input.TextArea}
            defaultValue={initValues.meta}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
});

export default BrandAPForm;
