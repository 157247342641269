import moment from 'moment';

const DATE_FORMAT = 'DD-MM-YYYY';
const TIME_FORMAT = 'HH:mm';
const DATE_TIME_FORMAT = `${DATE_FORMAT}, ${TIME_FORMAT}`;

const RANGE_HOUR = 'hour';
const RANGE_DAY = 'day';
const RANGE_WEEK = 'week';
const RANGE_MONTH = 'month';
const RANGE_QUARTER = 'quarter';
const RANGE_YEAR = 'year';
const RANGE_HOUR_FORMAT = 'HH:mm';
const RANGE_DAY_FORMAT = 'DD/MM';
const RANGE_WEEK_FORMAT = RANGE_DAY_FORMAT;
const RANGE_MONTH_FORMAT = 'MM/YYYY';
const RANGE_QUARTER_FORMAT = RANGE_MONTH_FORMAT;
const RANGE_YEAR_FORMAT = 'YYYY';

const format = (date, dateFormat = DATE_TIME_FORMAT) => moment(date).format(dateFormat);

const formatRange = (date, range) => {
  switch (range) {
    case RANGE_HOUR:
      return moment(date).format(RANGE_HOUR_FORMAT);
    case RANGE_DAY:
      return moment(date).format(RANGE_DAY_FORMAT);
    case RANGE_WEEK:
      return `${moment(date).format(RANGE_WEEK_FORMAT)} -> ${moment(date)
        .add(6, 'days')
        .format(RANGE_WEEK_FORMAT)}`;
    case RANGE_MONTH:
      return moment(date).format(RANGE_MONTH_FORMAT);
    case RANGE_QUARTER:
      return `${moment(date).format(RANGE_QUARTER_FORMAT)} -> ${moment(date)
        .add(2, 'months')
        .format(RANGE_QUARTER_FORMAT)}`;
    case RANGE_YEAR:
      return moment(date).format(RANGE_YEAR_FORMAT);
    default:
  }
};

const getRanges = date => {
  const result = { date: '', week: '', month: '', quarter: '', year: '' };

  result.date = format(date, DATE_FORMAT);
  result.week = formatRange(moment(date).startOf('week'), 'week');
  result.month = formatRange(date, 'month');
  result.quarter = formatRange(moment(date).startOf('quarter'), 'quarter');
  result.year = formatRange(date, 'year');

  return result;
};

export {
  DATE_FORMAT,
  TIME_FORMAT,
  DATE_TIME_FORMAT,
  format,
  RANGE_HOUR,
  RANGE_DAY,
  RANGE_WEEK,
  RANGE_MONTH,
  RANGE_QUARTER,
  RANGE_YEAR,
  RANGE_HOUR_FORMAT,
  RANGE_DAY_FORMAT,
  RANGE_WEEK_FORMAT,
  RANGE_MONTH_FORMAT,
  RANGE_QUARTER_FORMAT,
  RANGE_YEAR_FORMAT,
  formatRange,
  getRanges
};
