import { Button, Descriptions, Input } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { observer } from 'mobx-react';
import React, { FC, useState } from 'react';

import { formatNumber } from '~utils/formatNumber';

import store from './store';

const { TextArea } = Input;

const RejectDialog: FC = observer(() => {
  const { isRejectDialogVisible, setIsRejectDialogVisible, rejectPayment, selectedItem } = store;

  const [reason, setReason] = useState('');

  const handleReject = async (rejectReason) => {
    await rejectPayment(rejectReason);
    setIsRejectDialogVisible(false);
    setReason('')
  };

  if (!selectedItem) return null;

  return (
    <Modal
      visible={isRejectDialogVisible}
      title="Reject Confirm"
      onCancel={() => setIsRejectDialogVisible(false)}
      onOk={() => setIsRejectDialogVisible(false)}
      closable={false}
      footer={[
        <Button key="reject-cancle" onClick={() => setIsRejectDialogVisible(false)}>
          Cancle
        </Button>,
        <Button key="reject-ok" danger onClick={() => handleReject(reason)}>
          Reject
        </Button>
      ]}
    >
      <Descriptions column={2}>
        <Descriptions.Item span={1} label="Amount">
          {formatNumber(selectedItem?.amount)}
        </Descriptions.Item>
        <Descriptions.Item span={1} label="By">
          {selectedItem?.userPayment.name}
        </Descriptions.Item>
        <Descriptions.Item span={1} label="Email">
          {selectedItem?.userPayment.email}
        </Descriptions.Item>
        <Descriptions.Item span={1} label="Phone">
          {selectedItem?.userPayment.phone}
        </Descriptions.Item>
      </Descriptions>

      <h3 style={{ marginBottom: 10 }}>Reason</h3>
      <TextArea value={reason} rows={4} onChange={(e) => setReason(e.target.value)} />
    </Modal>
  );
});

export default RejectDialog;
