import { Card, Checkbox, Typography } from 'antd';
import { get } from 'lodash';
import React, { FC, useState } from 'react';

import { Role } from '~graphql/_sdk';

import store from './store';

const { Text } = Typography;

interface RoleCardProps {
  record: Role;
}
const gridStyle: React.CSSProperties = {
  width: 420,
  padding: 0,
  margin: 5
};

const RoleCard: FC<RoleCardProps> = ({ record }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [description, setDescription] = useState(get(record, 'description'));
  const { updateRoleDescriptionById } = store;

  const handleEdit = (value, _id) => {
    setDescription(value);
    updateRoleDescriptionById(_id, value);
    setTimeout(() => {
      setIsEdit(false);
    }, 200);
  };

  return (
    <Card.Grid style={gridStyle}>
      <Checkbox
        className="custom-checkbox"
        key={String(record._id)}
        value={record.roleId}
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          padding: 10,
          border: '1px solid #8db1de'
        }}
        disabled={isEdit}
      >
        <div
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <p style={{ color: '#e83e8c', fontSize: 15 }}>{get(record, 'name', null)}</p>
          <Text
            editable={{
              onStart: () => setIsEdit(true),
              onChange: (e) => handleEdit(e, record._id)
            }}
          >
            {description}
          </Text>
        </div>
      </Checkbox>
    </Card.Grid>
  );
};

export default RoleCard;
