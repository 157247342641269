import { Breadcrumb } from 'antd';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { HomeOutlined } from '@ant-design/icons';

import { bread_crumb } from '~components/Layout/css';
import { useRoute } from '~utils/useRoute';

const BreadCrumbMenu: FC = () => {
  const { t } = useTranslation();
  const currentRoute = useRoute();

  return (
    <Breadcrumb className={bread_crumb}>
      <Breadcrumb.Item>
        <HomeOutlined />
      </Breadcrumb.Item>
      <Breadcrumb.Item>{t(currentRoute?.groupId?.toUpperCase())}</Breadcrumb.Item>
      <Breadcrumb.Item>{t(currentRoute?.name)}</Breadcrumb.Item>
    </Breadcrumb>
  );
};

export default BreadCrumbMenu;
