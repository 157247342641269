/* eslint-disable array-callback-return */
import { Card, Col, Descriptions, Drawer, Row, Statistic, Tag } from 'antd';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import commonDataStore from '~common-stores/commonDataStore';
import { DATE_TIME_FORMAT, format } from '~utils/formatDate';
import { formatNumber } from '~utils/formatNumber';

import store from './store';

interface DrawerProps {
  onClose?: (e: any) => void;
}

const Drawers: FC<DrawerProps> = observer(props => {
  const { t } = useTranslation();

  const { selectedItem, cityDataMapping, districtDataMapping, drawerVisible } = store;
  const { locationCategoryMap } = commonDataStore;

  const initValues = {
    name: get(selectedItem, 'name', ''),
    addressText: get(selectedItem, 'address.text', ''),
    categoryId: get(selectedItem, 'categoryId'),
    city: get(selectedItem, 'city'),
    district: get(selectedItem, 'district'),
    status: get(selectedItem, 'status', ''),
    clickPerMonth: get(selectedItem, 'stats.monthlyClick', 0) || 0,
    activeDay: get(selectedItem, 'activeDay'),
    noOfRouter: get(selectedItem, 'noOfRouter', 0),
    noOfAccessPoint: get(selectedItem, 'noOfAccessPoint', 0),
    configAuth: get(selectedItem, 'configAuth', 'No') || 'No'
  };
  switch (initValues.status) {
    case 'active':
      initValues.status = 'Active';
      break;
    case 'disable':
      initValues.status = 'Disable';
      break;
    default:
      break;
  }
  switch (initValues.configAuth) {
    case 'no_authen':
      initValues.configAuth = t('NO_AUTHENTICATION');
      break;
    case 'voucher':
      initValues.configAuth = t('BY_VOUCHER');
      break;
    case 'user_pass':
      initValues.configAuth = t('BY_USERNAME_AND_PASSWORD');
      break;
    default:
      break;
  }

  const className = `fa fa-fw ${locationCategoryMap[initValues.categoryId]}`;

  return (
    <>
      <Drawer
        width={640}
        title={t('DETAIL')}
        placement="right"
        closable={false}
        onClose={props.onClose}
        visible={drawerVisible}
      >
        <Descriptions bordered title={t('BASIC_INFO')} size="middle">
          <Descriptions.Item label="Name" span={2}>
            {initValues.name}
          </Descriptions.Item>

          <Descriptions.Item span={1} label={t('CATEGORY')}>
            <i className={className}></i>
          </Descriptions.Item>

          <Descriptions.Item label={t('CITY')} span={2}>
            {cityDataMapping[initValues.city]?.text}
          </Descriptions.Item>

          <Descriptions.Item span={2} label={t('DISTRICT')}>
            {districtDataMapping[initValues.district]?.text}
          </Descriptions.Item>

          <Descriptions.Item span={3} label={t('ADDRESS')}>
            {initValues.addressText}
          </Descriptions.Item>

          <Descriptions.Item span={2} label={t('NO_ROUTER')}>
            {initValues.noOfRouter}
          </Descriptions.Item>

          <Descriptions.Item span={2} label={t('NO_ACCESS_POINT')}>
            {initValues.noOfAccessPoint}
          </Descriptions.Item>
        </Descriptions>

        <Descriptions bordered title={t('ADVANCE_INFO')} style={{ marginTop: '20px' }} size="small">
          <Descriptions.Item label={t('CLICK_PER_MONTH')}>
            {formatNumber(initValues.clickPerMonth)}
          </Descriptions.Item>

          <Descriptions.Item span={2} label={t('STATUS')}>
            <Tag>{initValues.status}</Tag>
          </Descriptions.Item>

          <Descriptions.Item label={t('CONFIG_AUTH')}>{initValues.configAuth}</Descriptions.Item>

          <Descriptions.Item span={2} label={t('ACTIVE_DAY')}>
            {format(initValues.activeDay, DATE_TIME_FORMAT)}
          </Descriptions.Item>
        </Descriptions>
        {/* <h3 style={{ marginTop: '20px', color: 'black' }}>{t('STATISTICS')}</h3>

        <p style={{ color: 'black', marginTop: '5px' }}>{t('AVARAGE_PER_MONTH')}</p>

        <Row>
          <Col span={8}>
            <Card>
              <Statistic
                title={t('IMPRESSION')}
                value={39000}
                valueStyle={{ color: 'black', textAlign: 'right' }}
              />
            </Card>
          </Col>
          <Col span={8}>
            <Card>
              <Statistic
                title={t('CLICK')}
                value={23000}
                valueStyle={{ color: 'black', textAlign: 'right' }}
              />
            </Card>
          </Col>
          <Col span={8}>
            <Card>
              <Statistic
                title={t('CTR')}
                value={18}
                suffix="%"
                valueStyle={{ color: 'black', textAlign: 'right' }}
              />
            </Card>
          </Col>
        </Row> */}

        {/* <p style={{ color: 'black', marginTop: '20px' }}>{t('LAST_THREE_MONTHS')}</p> */}

        {/* <Row>
          {get(store, 'selectedItemStatistic', []).map((s, idx) => (
            <Col span={8} key={String(idx)}>
              <Card>
                <Statistic
                  title={moment(s.month).format('MMMM')}
                  value={s.click}
                  valueStyle={{ color: 'black', textAlign: 'right' }}
                />
              </Card>
            </Col>
          ))}
        </Row> */}
      </Drawer>
    </>
  );
});

export default Drawers;
