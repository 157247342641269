import i18next from 'i18next';
import { debounce } from 'lodash';
import { action, observable, runInAction } from 'mobx';
import { actionAsync, task } from 'mobx-utils';

import { GridApi, GridReadyEvent } from '@ag-grid-community/core';
import { WhereArgs } from '@source/common';

import { ErrorNotify, SuccessNotify } from '~components/UI/Notification';
import {
  CustomerType,
  CustomerTypeFilter,
  CustomerTypeInput,
  MinimalProfileFragment
} from '~graphql/_sdk';
import { getSdk } from '~graphql/sdk';

class CustomerTypeStore {
  @observable public profiles: MinimalProfileFragment[];
  @observable public selectedItem: CustomerType;
  @observable public isModalVisible: boolean;
  @observable public where: CustomerTypeFilter;
  @observable public modalType: string;
  private gridApi: GridApi;

  constructor() {
    runInAction(() => {
      this.profiles = [];
      this.isModalVisible = false;
      this.modalType = null;
      this.where = {};
    });
  }

  @action
  public toggleModal = (type?: string, selectedItem?: CustomerType) => {
    if (type) {
      this.modalType = type;
    }
    this.isModalVisible = !this.isModalVisible;
    this.selectedItem = selectedItem;
  };

  @actionAsync
  public getProfiles = async () => {
    const sdk = await task(getSdk());
    const { findManyProfile } = await task(sdk.findManyProfileMinimal());
    this.profiles = findManyProfile;
  };

  @actionAsync
  public find = async () => {
    this.gridApi.showLoadingOverlay();
    const sdk = await task(getSdk());
    const { findManyCustomerType } = await task(sdk.findManyCustomerType({ where: this.where }));

    this.gridApi.setRowData(findManyCustomerType);
    this.gridApi.hideOverlay();
  };

  @actionAsync
  public create = async (record: CustomerTypeInput) => {
    const sdk = await task(getSdk());
    const { createCustomerType } = await task(sdk.createCustomerType({ record }));
    const { data, error } = createCustomerType;

    if (error) {
      ErrorNotify(i18next.t(error.code));
    } else {
      SuccessNotify(i18next.t('CREATE'));
      this.updateRow(null, data);
    }
  };

  @actionAsync
  public update = async (record: CustomerTypeInput) => {
    const sdk = await task(getSdk());
    const { updateCustomerTypeById } = await task(
      sdk.updateCustomerTypeById({ _id: this.selectedItem?._id, record })
    );
    const { data, error } = updateCustomerTypeById;

    if (error) {
      ErrorNotify(i18next.t(error.code));
    } else {
      SuccessNotify(i18next.t('UPDATE'));
      this.updateRow(data, data);
    }
  };

  @actionAsync
  public remove = async (_id: string) => {
    const sdk = await task(getSdk());
    const { removeCustomerTypeById } = await task(sdk.removeCustomerTypeById({ _id }));
    const { error, data } = removeCustomerTypeById;

    if (error) {
      ErrorNotify(i18next.t(error.code));
    } else {
      SuccessNotify(i18next.t('DELETE'));
      this.updateRow(data);
    }
  };

  public handleSearch = debounce(
    (text: string) => {
      if (!text) {
        this.gridApi.resetQuickFilter();
      } else {
        this.gridApi.setQuickFilter(text);
      }
    },
    200,
    { leading: false, trailing: true }
  );

  public reload = () => {
    if (this.gridApi) {
      this.gridApi.onFilterChanged();
    }
  };
  public handleGridReady = ({ api }: GridReadyEvent) => {
    this.gridApi = api;
    this.find();
  };
  private updateRow(oldRow?: any, newRow?: any) {
    const gridUpdates: Record<string, any> = {};
    if (!oldRow && newRow) {
      gridUpdates.add = [newRow];
    } else if (newRow) {
      gridUpdates.update = [newRow];
    } else {
      gridUpdates.remove = [oldRow];
    }
    const result = this.gridApi.applyTransaction(gridUpdates);
    if (result.update) {
      this.gridApi.redrawRows({ rowNodes: result.update });
    }
  }
}

export default new CustomerTypeStore();
