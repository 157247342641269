import { get } from 'lodash';

import store from './store';

export const categorieLoginChart = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

const createClickPerWeekChartOptions = (chartData = {}, title: string): Highcharts.Options => {
  const { setSelectDay } = store;

  const values = Object.values(get(chartData, 'day', {}));

  let day = new Date().getDay() - 1;
  if (day < 0) day = 6;
  const data = categorieLoginChart.map((e, i) => {
    return {
      name: e,
      y: values[i] as number,
      color: i === day ? '#FFA242' : ''
    };
  });

  return {
    chart: {
      type: 'column'
    },
    credits: {
      enabled: false
    },
    title: {
      style: {
        textTransform: 'uppercase',
        fontSize: '15px'
      },
      text: title || 'Avarage / Week'
    },
    tooltip: {
      shared: true,
      headerFormat: '<span style="font-size: 13px">{point.point.name}</span><br/>',
      pointFormat:
        '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}</b><br/>'
    },
    xAxis: {
      categories: categorieLoginChart,
      gridLineWidth: 1
    },
    yAxis: {
      title: {
        text: 'Click'
      }
    },
    plotOptions: {
      series: {
        cursor: 'pointer',
        events: {
          click(event) {
            setSelectDay(event.point.category as string);
          }
        }
      },
      line: {
        dataLabels: {
          enabled: false
        }
      }
      // candlestick: {
      //   lineColor: '#404048'
      // }
    },
    series: [
      {
        type: 'column',
        name: 'Clicks',
        color: '#0078D4',
        data
      }
    ]
  };
};

const createClickPerDayChartOptions = (chartData = {}, day: number, title: string): Highcharts.Options => {
  const values =
    day >= 0 ? get(chartData, 'hour', [])[day] : Object.values(get(chartData, 'time', []));

  const currentHour = new Date().getHours();

  const data = values.map((v, i) => {
    return {
      name: i,
      y: v,
      color: currentHour === i ? '#FFA242' : ''
    };
  });
  return {
    chart: {
      type: 'column'
    },
    credits: {
      enabled: false
    },
    title: {
      style: {
        textTransform: 'uppercase',
        fontSize: '15px'
      },
      text: title || 'Avarage / Day'
    },
    xAxis: {
      categories: Object.keys(get(chartData, 'time', {}))
    },
    yAxis: {
      title: {
        text: 'Click'
      }
    },
    plotOptions: {
      line: {
        dataLabels: {
          enabled: false
        }
      },
      candlestick: {
        lineColor: '#404048'
      }
    },
    series: [
      {
        type: 'column',
        name: 'Clicks',
        data,
        color: '#0078D4'
      }
    ]
  };
};

export { createClickPerWeekChartOptions, createClickPerDayChartOptions };
