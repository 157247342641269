import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ColDef, ColGroupDef } from '@ag-grid-community/core';
import {
  AndroidOutlined,
  AppleOutlined,
  LaptopOutlined,
  MobileOutlined,
  QqOutlined,
  QuestionOutlined,
  TabletOutlined,
  WindowsOutlined
} from '@ant-design/icons';
import { format } from '@source/common';

import AgGrid from '~components/UI/AgGrid/AgGridServer';
import { useGraphQLSDK } from '~graphql/GraphQLSDKProvider';

const OnlineSessionGrid: FC = observer(() => {
  const sdk = useGraphQLSDK();

  const { t } = useTranslation();
  const columnDefs: ColDef[] | ColGroupDef[] = [
    {
      headerName: '',
      field: 'os',
      minWidth: 40,
      maxWidth: 40,
      cellClass: 'text-center',
      cellRendererFramework: ({ value }) => {
        switch (value) {
          case 'Android':
            return <AndroidOutlined />;
          case 'Windows':
            return <WindowsOutlined />;
          case 'MacOS':
          case 'iOS':
            return <AppleOutlined />;
          case 'Linux':
            return <QqOutlined />;
          default:
            return <QuestionOutlined />;
        }
      }
    },
    {
      headerName: '',
      field: 'deviceType',
      minWidth: 40,
      maxWidth: 40,
      cellClass: 'text-center',
      cellRendererFramework: ({ value }) => {
        switch (value) {
          case 'PC':
            return <LaptopOutlined />;
          case 'Mobile':
            return <MobileOutlined />;
          case 'Tablet':
            return <TabletOutlined />;
          default:
            return <QuestionOutlined />;
        }
      }
    },
    {
      headerName: t('DEVICE_NAME'),
      field: 'deviceName',
      valueGetter: params => {
        if (!params.data?.deviceName) {
          return `.${params.data?.device_name}`;
        }
        return params.data?.deviceName;
      }
    },
    {
      headerName: t('DEVICE_MAC'),
      field: 'deviceMac',
      minWidth: 160,
      maxWidth: 160
    },

    {
      headerName: t('LOCATION'),
      field: 'locationName'
    },
    {
      headerName: t('SESSION_START'),
      field: 'startedAt',
      minWidth: 120,
      maxWidth: 120,
      cellRenderer: ({ value }) => format(value, 'DD-MM, HH:mm')
    }
  ];

  return (
    <AgGrid
      options={{
        columnDefs,
        cacheBlockSize: 50,
        paginationPageSize: 50
      }}
      onQuery={sdk.getReportOnlineSession}
      queryKey="getReportOnlineSession"
      onCountQuery={sdk.countReportOnlineSession}
      countQueryKey="countReportOnlineSession"
      height={470}
    />
  );
});

export default OnlineSessionGrid;
