import { Button, Col, Input, Row, Select } from 'antd';
import React, { useState } from 'react';

import { DeleteOutlined } from '@ant-design/icons';

const { Option } = Select;

interface WebhookParamConfigElementInterface {
  index: number;
  param: any;
  removeIndex: Function;
  updateParam: Function;
}

const ParamConfigElement = (props: WebhookParamConfigElementInterface) => {
  const { name, value } = props.param;
  const paramsUri = [
    {
      key: 'api_uri',
      children: 'api_uri',
      value: 'api_uri'
    },
    {
      key: 'created_at',
      children: 'created_at',
      value: 'created_at'
    },
    {
      key: 'campaign_id',
      children: 'campaign_id',
      value: 'campaign_id'
    },
    {
      key: 'location_id',
      children: 'location_id',
      value: 'location_id'
    },
    {
      key: 'city_id',
      children: 'city_id',
      value: 'city_id'
    },
    {
      key: 'banner_id',
      children: 'banner_id',
      value: 'banner_id'
    },
    {
      key: 'name',
      children: 'name',
      value: 'name'
    },
    {
      key: 'email',
      children: 'email',
      value: 'email'
    },
    {
      key: 'phone',
      children: 'phone',
      value: 'phone'
    },
    {
      key: 'gender',
      children: 'gender',
      value: 'gender'
    },
    {
      key: 'age',
      children: 'age',
      value: 'age'
    },
    {
      key: 'income',
      children: 'income',
      value: 'income'
    },
    {
      key: 'marital',
      children: 'marital',
      value: 'marital'
    },
    {
      key: 'country',
      children: 'country',
      value: 'country'
    },
    {
      key: 'source',
      children: 'source',
      value: 'source'
    },
    {
      key: 'field_0',
      children: 'field_0',
      value: 'field_0'
    },
    {
      key: 'field_1',
      children: 'field_1',
      value: 'field_1'
    },
    {
      key: 'field_2',
      children: 'field_2',
      value: 'field_2'
    },
    {
      key: 'field_3',
      children: 'field_3',
      value: 'field_3'
    },
    {
      key: 'field_4',
      children: 'field_4',
      value: 'field_4'
    },
    {
      key: 'browser',
      children: 'browser',
      value: 'browser'
    },
    {
      key: 'major',
      children: 'major',
      value: 'major'
    },
    {
      key: 'minor',
      children: 'minor',
      value: 'minor'
    },
    {
      key: 'patch',
      children: 'patch',
      value: 'patch'
    },
    {
      key: 'os',
      children: 'os',
      value: 'os'
    },
    {
      key: 'os_name',
      children: 'os_name',
      value: 'os_name'
    },
    {
      key: 'os_minor',
      children: 'os_minor',
      value: 'os_minor'
    },
    {
      key: 'device',
      children: 'device',
      value: 'device'
    },
    {
      key: 'device_name',
      children: 'device_name',
      value: 'device_name'
    },
    {
      key: 'mac',
      children: 'mac',
      value: 'mac'
    }
  ];

  return (
    <Row gutter={16} style={{ marginBottom: '16px' }}>
      <Col span={10}>
        <Input
          placeholder="Input params name"
          defaultValue={name}
          style={{ width: '100%' }}
          onChange={e => props.updateParam(props.index, 'name', e.target.value)}
        />
      </Col>
      <Col span={10}>
        <Select
          className="custom-select"
          style={{ width: '100%' }}
          showSearch
          onChange={e => props.updateParam(props.index, 'value', e)}
          placeholder="MegaNet Params"
          defaultValue={value}
        >
          {paramsUri.map(e => (
            <Option value={e.value} key={e.key}>
              {e.children}
            </Option>
          ))}
        </Select>
      </Col>
      <Col span={4}>
        <Button
          onClick={() => props.removeIndex(props.index)}
          style={{
            border: 'none',
            width: '10%',
            color: 'rgb(223, 91, 91)'
          }}
          icon={<DeleteOutlined />}
        />
      </Col>
    </Row>
  );
};

export default ParamConfigElement;
