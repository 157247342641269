import { isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import HighChart from '~components/UI/HighChart/HighChart.lazy';

import store from '../store';

export interface Props {
  idx: number;
}

const ColumnChart: FC<Props> = observer(props => {
  const { result } = store;
  const { idx } = props;
  let data = [];
  if (!isEmpty(result)) {
    const mapObj = Object.keys(result[idx].options);
    for (let i = 0; i < mapObj.length; i += 1) {
      data = [...data, Object.values(result[idx].options)[i]];
    }
  }

  const { t } = useTranslation();
  const options: Highcharts.Options = {
    title: {
      text: ''
    },
    series: [
      {
        name: '',
        type: 'column',
        color: 'rgb(66, 146, 198)',
        data
      }
    ],
    xAxis: {
      categories: Object.keys(result[idx].options),
      title: {
        text: null
      }
    },
    yAxis: {
      min: 0,
      title: {
        text: t('Number'),
        align: 'middle'
      },
      labels: {
        overflow: 'justify'
      }
    },
    tooltip: {
      shared: true
    },
    credits: {
      enabled: false
    }
  };
  return (
    <>
      <HighChart options={options} />
    </>
  );
});

export default ColumnChart;
