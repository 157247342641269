import { Alert, Button, PageHeader, Switch, Tooltip } from 'antd';
import { isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { CheckOutlined, SwapOutlined } from '@ant-design/icons';

import { UISelect } from '~components/UI/Select/UISelect';

import store from './store';

const Header: FC = observer(() => {
  const {
    dataAdvance,
    setId,
    firstCampaign,
    setSwitch,
    secondCampaign,
    updateMultiPriority,
    commonLocations,
    status
  } = store;
  const SaveButton = () => {
    return (
      <Button
        disabled={isEmpty(commonLocations)}
        type="primary"
        icon={<CheckOutlined />}
        onClick={() => {
          updateMultiPriority();
        }}
      >
        {status === 'switch' ? 'Swap' : 'Force'}
      </Button>
    );
  };
  return <>
    <PageHeader
      style={{ background: '#fff', marginBottom: 0, padding: '16px' }}
      title="Locations Priority"
      extra={[
        <span key="span" style={{ fontWeight: 600 }}>
          Force:
        </span>,
        <Switch
          key="switch"
          style={{ marginLeft: 5 }}
          checkedChildren="ON"
          unCheckedChildren="OFF"
          onChange={(e) => setSwitch(e)}
        />,
        <UISelect
          key="FirstCamp"
          disabled={isEmpty(dataAdvance)}
          allowClear
          style={{ width: 200 }}
          onChange={(id) => setId(id, 'first')}
          dataOptions={{
            data: dataAdvance,
            valueField: '_id',
            textField: 'name'
          }}
          value={firstCampaign}
          placeholder="Select First Campaign"
        />,
        <Tooltip key="tooltip" placement="bottom" title="Quick Swap">
          <SwapOutlined
            onClick={store.swapCampaign}
            style={{ cursor: 'pointer', touchAction: 'manipulation' }}
            key="icon" />
        </Tooltip>,
        <UISelect
          disabled={isEmpty(dataAdvance)}
          allowClear
          key="SecondCamp"
          value={secondCampaign}
          style={{ width: 200 }}
          onChange={(id) => setId(id, 'second')}
          dataOptions={{
            data: dataAdvance,
            valueField: '_id',
            textField: 'name'
          }}
          placeholder="Select Second Campaign"
        />,
        <SaveButton key="button" />
      ]}
    />
  </>;
});

export default Header;
