import { Tag } from 'antd';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ReactResizeDetector from 'react-resize-detector';

import { ColDef } from '@ag-grid-community/core';
import { MinusOutlined } from '@ant-design/icons';

import AgGridServer from '~components/UI/AgGrid/AgGridServer';
import { useGraphQLSDK } from '~graphql/GraphQLSDKProvider';

import ActionRenderer from './ActionRenderer';
import store from './store';

const CustomerGrid: FC = observer(() => {
  const { handleGridReady, computedWhere } = store;
  const sdk = useGraphQLSDK();
  const { t } = useTranslation();

  const columnDefs: ColDef[] = [
    {
      headerName: '',
      field: '_id',
      cellClass: 'text-center',
      minWidth: 70,
      maxWidth: 70,
      cellRendererFramework: ActionRenderer
    },
    {
      headerName: t('NO'),
      field: '',
      cellClass: 'text-center',
      sortable: false,
      minWidth: 50,
      maxWidth: 50,
      cellRendererFramework: ({ rowIndex }) => rowIndex + 1
    },
    {
      headerName: t('NAME'),
      field: 'name',
      minWidth: 200,
      cellRendererFramework: ({ value }) => {
        return value || <MinusOutlined />;
      }
    },
    {
      headerName: t('Share'),
      sortable: false,
      minWidth: 150,
      maxWidth: 150,
      cellRendererFramework: ({ data }) => {
        return (
          <>
            {data?.shareTraffic?.enabled && <Tag color="#108ee9">ShareTraffic</Tag>}
            {data?.shareRevenue?.enabled && <Tag color="#87d068">ShareRevenue</Tag>}
          </>
        );
      }
    },
    {
      headerName: t('Email'),
      field: 'email',
      sortable: false,
      minWidth: 250,
      cellRendererFramework: ({ value }) => {
        return value || <MinusOutlined />;
      }
    },
    {
      headerName: t('Phone'),
      field: 'phone',
      minWidth: 180,
      sortable: false,
      maxWidth: 180,
      cellRendererFramework: ({ value }) => {
        return value || <MinusOutlined />;
      }
    },
    {
      headerName: t('Address'),
      field: 'address',
      minWidth: 180,
      sortable: false,
      cellRendererFramework: ({ value }) => {
        return value || <MinusOutlined />;
      }
    },
    {
      headerName: t('Website'),
      field: 'website',
      sortable: false,
      minWidth: 180,
      maxWidth: 180,
      cellRendererFramework: ({ value }) => {
        return value || <MinusOutlined />;
      }
    },
    {
      headerName: t('Tax Code'),
      field: 'taxCode',
      minWidth: 180,
      maxWidth: 180,
      cellRendererFramework: ({ value }) => {
        return value || <MinusOutlined />;
      }
    },
    {
      headerName: t('Type Code'),
      field: 'typeCode',
      minWidth: 180,
      maxWidth: 180,
      cellRendererFramework: ({ value }) => {
        return <Tag color="#108ee9">{value ? value.toUpperCase() : '-'}</Tag>;
      }
    }
  ];

  return (
    <ReactResizeDetector handleHeight>
      {({ height }) => {
        return (
          <AgGridServer
            options={{
              columnDefs
            }}
            emptyHandler={() => store.setModalVisible()}
            screen="customer"
            height={height - 80}
            onQuery={sdk.findManyCustomer}
            queryKey="findManyCustomer"
            onCountQuery={sdk.countCustomer}
            countQueryKey="countCustomer"
            onGridReady={handleGridReady}
            where={computedWhere}
          />
        );
      }}
    </ReactResizeDetector>
  );
});

export default CustomerGrid;
