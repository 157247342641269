import { observer } from 'mobx-react';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactResizeDetector from 'react-resize-detector';

import { ColDef } from '@ag-grid-community/core';

import AgGrid from '~components/UI/AgGrid/AgGridClient';
import { format } from '~utils/formatDate';

import ActionRenderer from './ActionRenderer';
import store from './store';

const EquipmentGrid: FC = observer(() => {
  const { t } = useTranslation();

  const { handleGridReady } = store;

  const columnDefs: ColDef[] = [
    {
      headerName: '',
      field: '_id',
      cellClass: 'text-center',
      minWidth: 70,
      maxWidth: 70,
      cellRenderer: 'ActionRenderer'
    },
    {
      headerName: t('NO'),
      field: '',
      cellClass: 'text-center',
      minWidth: 50,
      maxWidth: 50,
      cellRendererFramework: ({ rowIndex }) => rowIndex + 1
    },
    {
      headerName: t('NAME'),
      field: 'name',
      minWidth: 165
    },
    {
      headerName: t('PROFILE'),
      field: 'profile.display',
      colId: 'profileId',
      minWidth: 180,
      maxWidth: 180
    },
    {
      headerName: t('CREATED'),
      field: 'createdAt',
      minWidth: 120,
      maxWidth: 120,
      sort: 'desc',
      cellRenderer: ({ value }) => format(value, 'DD-MM-YYYY')
    }
  ];

  return (
    <ReactResizeDetector handleHeight>
      {({ height }) => {
        const gridHeight = height ? height - 80 : 500;
        return (
          <AgGrid
            columnDefs={columnDefs}
            getRowNodeId={(data) => data._id}
            height={gridHeight}
            onGridReady={handleGridReady}
            frameworkComponents={{ ActionRenderer }}
          />
        );
      }}
    </ReactResizeDetector>
  );
});

export default EquipmentGrid;
