import { Col, Divider, Row, Statistic, Tabs, Tooltip } from 'antd';
import { isNaN } from 'lodash';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import store from '../store';
import Chart from './Chart';
import CRMFilter from './CRMFilter';
import FilterReportCampaign from './FilterReportCampaign';
import GridDetail from './GridDetail';
import Mac from './GridMac';
import Info from './Info';
import OsDevice from './OsDevice';
import ReportCRM from './ReportCRM';

const { TabPane } = Tabs;

const ReportCampaignTab: FC = observer(() => {
  const { setTab, totalChartData } = store;
  const { t } = useTranslation();

  return (
    <Tabs
      style={{ height: 'calc(100vh - 160px)' }}
      defaultActiveKey="info"
      tabBarStyle={{ backgroundColor: '#F0F2F5' }}
      tabBarGutter={50}
      onChange={setTab}
    >
      <TabPane
        tab={
          <span style={{ paddingLeft: 15, paddingRight: 15 }}>
            <i className="fas fa-fw fa-info-circle" />
            Infomation
          </span>
        }
        key="info"
      >
        <Info />
      </TabPane>
      <TabPane
        tab={
          <span style={{ paddingLeft: 15, paddingRight: 15 }}>
            <i className="fas fa-fw fa-chart-bar" />
            {t('CHART')}
          </span>
        }
        key="chart"
      >
        <FilterReportCampaign />
        <Row gutter={16} style={{ marginBottom: 8 }}>
          <Col span={8}>
            <Tooltip title="Total impression">
              <Statistic
                valueStyle={{ color: '#737373', textAlign: 'center' }}
                value={totalChartData.impression}
                prefix={<i className="fas fa-eye" style={{ color: '#0078D4' }} />}
              />
            </Tooltip>
          </Col>
          <Col span={8}>
            <Tooltip title="Total click">
              <Statistic
                valueStyle={{ color: '#737373', textAlign: 'center' }}
                value={totalChartData.click}
                prefix={<i className="fas fa-location-arrow" style={{ color: '#FFA242' }} />}
              />
            </Tooltip>
          </Col>
          <Col span={8}>
            <Tooltip title="Average Ctr">
              <Statistic
                valueStyle={{ color: '#737373', textAlign: 'center' }}
                value={!isNaN(totalChartData?.ctr) ? totalChartData.ctr : 0}
                suffix="%"
                prefix={<i className="fas fa-percentage" style={{ color: '#F75A5A' }} />}
              />
            </Tooltip>
          </Col>
        </Row>
        <Chart />
      </TabPane>
      <TabPane
        tab={
          <span style={{ paddingLeft: 15, paddingRight: 15 }}>
            <i className="fas fa-fw fa-th" />
            {t('GRID')}
          </span>
        }
        // style={{ display: 'flex', flexFlow: 'column' }}
        key="detail"
      >
        <FilterReportCampaign />
        <GridDetail />
      </TabPane>
      <TabPane
        tab={
          <span style={{ paddingLeft: 15, paddingRight: 15 }}>
            <i className="fas fa-fw fa-users" />
            {t('USER')}
          </span>
        }
        key="mac"
      >
        <div>
          <FilterReportCampaign />
          <CRMFilter />
          <Mac />
        </div>
      </TabPane>
      <TabPane
        tab={
          <span style={{ paddingLeft: 15, paddingRight: 15 }}>
            <i className="fas fa-fw fa-mobile-alt" />
            {t('OS')} / {t('DEVICE')}
          </span>
        }
        key="os-de"
      >
        <FilterReportCampaign />
        {/* <CRMFilter /> */}
        <OsDevice />
      </TabPane>
      <TabPane
        tab={
          <span style={{ paddingLeft: 15, paddingRight: 15 }}>
            <i className="fas fa-fw fa-chart-bar" />
            {t('CRM')}
          </span>
        }
        key="crm"
      >
        <div style={{ display: 'flex', flexFlow: 'column' }}>
          {/* <FilterReportCampaign /> */}
          <ReportCRM />
        </div>
      </TabPane>
    </Tabs>
  );
});
export default ReportCampaignTab;
