import i18next from 'i18next';
import { action, computed, observable, reaction, runInAction } from 'mobx';
import { actionAsync, task } from 'mobx-utils';

import { GridReadyEvent } from '@ag-grid-community/core';

import { ErrorNotify, SuccessNotify } from '~components/UI/Notification';
import { CmPositionInput, FullCampaignMappingFragment, ResultInput } from '~graphql/_sdk';
import { getSdk } from '~graphql/sdk';

class CampaignPriorityStore {
  @observable public loading: boolean;
  @observable.ref public data: any;
  @observable public locationId: string;
  @observable public modalVisible: boolean;
  @observable public dataAdvance: any;
  @observable public firstId: string;
  @observable public secondId: string;
  @observable public gridAdvance: any;
  @observable public commonLocations: any;
  @observable public status: string;
  @observable public locations: ResultInput[];
  @observable public checked: boolean;
  @observable public checkedList: any;

  @observable public campaignId: string;

  private campaignMappingMap: CmPositionInput[];
  private currentcampaignMappingMap: CmPositionInput[];

  constructor() {
    this.resetFields();
    reaction(
      () => this.locationId,
      lId => {
        if (lId) {
          this.fetchLocationMapping(lId);
        }
      }
    );
  }

  @action
  public resetFields = () => {
    this.locations = [];
    this.checkedList = [];
    this.checked = true;
    this.status = 'switch';
    this.commonLocations = [];
    this.gridAdvance = [];
    this.dataAdvance = [];
    this.modalVisible = false;
    this.loading = false;
    this.data = [];
    this.locationId = undefined;
    this.campaignMappingMap = [];
    this.currentcampaignMappingMap = [];
  };

  @computed
  public get campaignMapping() {
    return this.data;
  }

  @action
  public setLocation = (locationId: string) => {
    this.locationId = locationId;
  };

  @action
  public onRowDrag = () => {
    this.currentcampaignMappingMap = [];
    this.handleRowDrag();
  };

  @actionAsync
  public fetchLocationMapping = async (locationId: string) => {
    this.campaignMappingMap = [];
    this.currentcampaignMappingMap = [];

    const sdk = await task(getSdk());
    const { fetchLocationMapping } = await task(sdk.fetchLocationMapping({ locationId }));

    this.data = fetchLocationMapping;

    this.initCampaignMappingMap(this.data);
    this.reload();
  };

  @actionAsync
  public updatePriority = async () => {
    const sdk = await task(getSdk());
    const {
      updateCampaignPriority: { error }
    } = await task(
      sdk.updateCampaignPriority({
        old: this.campaignMappingMap,
        current: this.currentcampaignMappingMap,
        locationId: this.locationId
      })
    );
    if (error) {
      ErrorNotify(error.code);
    } else {
      SuccessNotify(i18next.t('UPDATE'));
    }
  };

  @action
  public handleGridReady = (e: GridReadyEvent) => {
    this.reload = () => {
      e.api.onFilterChanged();
    };
    this.handleRowDrag = () => {
      e.api.forEachNode(node => {
        const item = this.data.find(i => i._id.toString() === node.id);
        runInAction(() => {
          this.currentcampaignMappingMap.push({ id: item.campaignId, priority: item.priority });
        });
      });
    };
  };

  @action
  private initCampaignMappingMap = (data: FullCampaignMappingFragment[]) => {
    this.campaignMappingMap = data.map(m => ({
      id: m.campaignId.toString(),
      priority: m.priority
    }));
  };

  @action
  private reload = () => undefined;

  private handleRowDrag = () => undefined;
}

export default new CampaignPriorityStore();
