import i18next from 'i18next';
import { debounce } from 'lodash';
import { action, observable, runInAction } from 'mobx';
import { actionAsync, task } from 'mobx-utils';

import { GridReadyEvent } from '@ag-grid-community/core';

import { ErrorNotify, SuccessNotify } from '~components/UI/Notification';
import {
  BannerFilter,
  BannerTemplate,
  BannerTemplateInput,
  BannerTemplateUpdateArg
} from '~graphql/_sdk';
import { getSdk } from '~graphql/sdk';

export class BannerTemplateStore {
  @observable public loading: boolean;
  @observable public modalVisible: boolean;
  @observable public selectedItem?: BannerTemplate;
  @observable public where: BannerFilter;

  constructor() {
    runInAction(() => {
      this.loading = false;
      this.modalVisible = false;
      this.selectedItem = undefined;
      this.where = {};
    });
  }

  @action
  setSearchText = debounce(text => {
    runInAction(() => {
      this.where = { _search: text };
      this.reload();
    });
  }, 500);

  @action
  setModalVisible = (item: BannerTemplate = undefined) => {
    this.selectedItem = item;
    this.modalVisible = !this.modalVisible;
  };

  @actionAsync
  create = async (record: BannerTemplateInput) => {
    const sdk = await task(getSdk());
    const {
      createBannerTemplate: { error }
    } = await task(sdk.createBannerTemplate({ record }));

    if (error) {
      ErrorNotify(error.title);
    } else {
      SuccessNotify(i18next.t('CREATE'));
      this.reload();
    }
  };

  @actionAsync
  update = async (_id: string, record: BannerTemplateUpdateArg) => {
    this.loading = true;
    const sdk = await task(getSdk());
    const {
      updateBannerTemplateById: { error }
    } = await task(sdk.updateBannerTemplateById({ _id, record }));

    if (error) {
      ErrorNotify(error.title);
    } else {
      SuccessNotify(i18next.t('UPDATE'));
      this.reload();
    }
    this.loading = false;
  };

  @actionAsync
  remove = async (_id: string) => {
    const sdk = await task(getSdk());
    const {
      removeBannerTemplateById: { error }
    } = await task(sdk.removeBannerTemplateById({ _id }));

    if (error) {
      ErrorNotify(error.title);
    } else {
      SuccessNotify(i18next.t('DELETE'));
      this.reload();
    }
  };

  @action
  public handleGridReady = ({ api }: GridReadyEvent) => {
    this.reload = () => {
      api.onFilterChanged();
    };
  };

  @action
  public reload = () => undefined;
}

export default new BannerTemplateStore();
