import { Empty } from 'antd';
import { isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { CodepenOutlined } from '@ant-design/icons';

import { useStore } from '~context/react-context';
import { parseQuery } from '~utils/query-string';

import CampaignInfo from './CampaignInfo';
import Form from './Form';
import Header from './Header';
import store from './store';
import Table from './Table';

interface URLParams {
  id: string;
}

const KpiManagementScreen: FC = observer(() => {
  const { commonDataStore } = useStore();
  const { campaignMapping, setParseId, value, resetCampaignSelect } = store;

  const { t } = useTranslation();

  const location = useLocation();
  const parsed = parseQuery(location.search) as URLParams;
  React.useEffect(() => {
    resetCampaignSelect();
    commonDataStore.loadCampaigns();
    commonDataStore.loadLocationCategory();
    commonDataStore.loadAllLocations();
    if (!commonDataStore.loading) {
      if (parsed.id) {
        setParseId(parsed.id);
      }
    }
  }, [commonDataStore.loading]);

  return (
    <>
      <Header />
      {isEmpty(campaignMapping) ? (
        <Empty
          style={{ backgroundColor: '#fff', height: 'calc(100% - 74px)', margin: 0 }}
          image={<CodepenOutlined style={{ color: 'steelblue', width: '7%' }} />}
          description={<b>{!value ? t('SELECT_CAMPAIGN') : t('WAIT_A_SECOND')}</b>}
        />
      ) : (
        <>
          <div style={{ backgroundColor: '#fff', padding: 20 }}>
            <CampaignInfo />
            <Table />
          </div>
        </>
      )}
      <Form />
    </>
  );
});

export default KpiManagementScreen;
