import '@ant-design/compatible/assets/index.css';

import { Button, Col, Input, Row } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import useForm from 'react-hook-form';
import * as yup from 'yup';


import { useStore } from '~context/react-context';

import store from './store';
import { ErrorNotify, SuccessNotify } from '~components/UI/Notification';

export interface FormValues {
  email?: string;
}

const validationSchema = yup.object().shape<FormValues>({
  email: yup.string().required('Input Email')
});

const SendMail: React.FC = observer(() => {
  const { sendMailByLicense } = store;
  const {
    userStore: { currentUser }
  } = useStore();
  const onSubmit = async ({ email }: FormValues) => {
    sendMailByLicense(currentUser._id, email);
  };
  const formProps = useForm<FormValues>({ validationSchema });
  const { handleSubmit } = formProps;
  const referralLink = `${window.origin  }/signup?referralId=${  currentUser._id}`;

  const copyReferral = () => {
    const ele = document.createElement('textarea');
    ele.textContent = referralLink;
    document.body.appendChild(ele);
    ele.select();
    try {
      if (document.execCommand('copy')) {
        SuccessNotify('Copy');
      } else {
        ErrorNotify('Copy');
      }
    } catch (err) {
      ErrorNotify('Copy');
    } finally {
      ele.parentNode.removeChild(ele);
    }
  };

  return (
    <Row>
      <Col xs={24} md={16} lg={12}>
        <Row gutter={16}>
          <Col span={24}>
            <p>Dùng link bên dưới để mời License mới</p>
          </Col>
          <Col span={18}>
            <Input placeholder="Basic usage" value={referralLink} readOnly />
          </Col>
          <Col span={6}>
            <Button type="primary" style={{ width: '100%' }} onClick={copyReferral}>
              Copy
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
});

export default SendMail;
