import '@ant-design/compatible/assets/index.css';

import * as CSS from 'csstype';
import { get, isEmpty } from 'lodash';
import React, { ReactNode, useEffect } from 'react';
import { RHFInput } from 'react-hook-form-input';
import { FormContextValues } from 'react-hook-form/dist/contextTypes';

import { Form } from '@ant-design/compatible';

interface FieldHandler {
  onChange?: (value: any) => void;
  onBlur?: () => any;
  children?: ReactNode[];
}

export interface UIFieldProps<T> {
  label?: string;
  fieldComponent: new (props: T) => React.Component;
  fieldProps?: T;
  name: string;
  defaultValue?: any;
  checkbox?: boolean;
  style?: CSS.Properties<string | number>;
}

const UIField = <T extends unknown>(props: FormContextValues & UIFieldProps<T & FieldHandler>) => {
  const {
    name,
    setValue,
    register,
    errors,
    fieldProps = {},
    fieldComponent: FieldComponent,
    label,
    triggerValidation,
    defaultValue,
    checkbox,
    unregister,
    style,
    clearError
  } = props;

  const { onBlur, onChange } = fieldProps as FieldHandler;
  useEffect(() => {
    setValue(name, defaultValue);
  }, [defaultValue, name]);

  useEffect(() => {
    return () => unregister(name);
  }, [unregister]);

  const handleChange = async (e: any) => {
    const value = checkbox ? get(e, 'target.checked', e) : get(e, 'target.value', e);
    if (typeof onChange === 'function') {
      onChange(value);
      setValue(name, value);
      clearError(name);
    } else {
      setValue(name, value);
      clearError(name);
    }
  };

  const handleBlur = async () => {
    await triggerValidation({ name });
    if (typeof onBlur === 'function') {
      onBlur();
    }
  };

  const errorStatus = !isEmpty(errors[name]) ? 'error' : 'success';
  const errorMessage = !isEmpty(errors) && get(errors, `[${name}].message`, '');

  return (
    <Form.Item label={label} validateStatus={errorStatus} help={errorMessage} style={style}>
      {!checkbox ? (
        <RHFInput
          as={<FieldComponent {...fieldProps} />}
          name={name}
          register={register}
          setValue={setValue}
          onBlur={async () => {
            await handleBlur();
          }}
          onChange={async e => {
            await handleChange(e);
          }}
        />
      ) : (
        <RHFInput
          as={<FieldComponent {...fieldProps} />}
          name={name}
          type="checkbox"
          register={register}
          setValue={setValue}
          onBlur={async () => {
            await handleBlur();
          }}
          onChange={async e => {
            await handleChange(e);
          }}
        />
      )}
    </Form.Item>
  );
};

export default UIField;
