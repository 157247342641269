import { Form, Input, Modal } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import { Rule } from 'antd/lib/form';
import i18next from 'i18next';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { UISelect } from '~components/UI/Select/UISelect';
import { useStore } from '~context/react-context';
import { EquipmentInput, FullCommonFragment } from '~graphql/_sdk';

import store from './store';

export interface FormValues {
  name?: string;
  mac?: string;
  serial?: string;
  type?: string;
  model?: string;
  locationId?: string;
  brand?: string;
  gateway?: string;
}

type ValidationSchema<T> = {
  [k in keyof T]: Rule[];
};

const validationSchema: ValidationSchema<FormValues> = {
  name: [{ required: true, message: i18next.t('REQF') }],
  mac: [{ required: true, message: i18next.t('REQF') }],
  serial: [],
  model: [{ required: true, message: i18next.t('MODEL_IS_REQUIRED') }],
  locationId: [{ required: true, message: i18next.t('LOCATION_IS_REQUIRED') }],
  brand: [{ required: true, message: i18next.t('BRAND_IS_REQUIRED') }]
};

const { Item, useForm } = Form;

const layout = {
  labelCol: { sm: 24, md: 8, lg: 6 },
  wrapperCol: { sm: 24, md: 16, lg: 18 }
};

const typeOption = [
  {
    key: 'router',
    value: 'router',
    children: 'Router'
  },
  {
    key: 'ap',
    value: 'ap',
    children: 'Access Point'
  }
];

const EquipmentForm: FC = observer(() => {
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);

  const { isModalVisible, toggleModal, selectedItem, fromLocation, update, create } = store;
  const { commonDataStore } = useStore();

  const initModel = selectedItem
    ? commonDataStore.equipmentType.filter(
        (item) => item.meta.type === selectedItem.type && item.meta.brand === selectedItem.brand
      )
    : [];
  const [modelEquipment, setModelEquipment] = useState([]);

  useEffect(() => {
    setModelEquipment(initModel);
  }, [initModel.length]);

  const [form] = useForm();
  const { getFieldValue, setFieldsValue, resetFields } = form;

  const initialValues: FormValues = {
    name: get(selectedItem, 'name', ''),
    mac: get(selectedItem, 'mac', ''),
    serial: get(selectedItem, 'serial', ''),
    type: get(selectedItem, 'type', undefined),
    model: get(selectedItem, 'model', undefined),
    locationId: get(selectedItem, 'locationId', fromLocation || undefined),
    brand: get(selectedItem, 'brand', undefined),
    gateway: get(selectedItem, 'gateway', undefined)
  };

  useEffect(() => {
    setFieldsValue(initialValues);
  }, [selectedItem]);

  const submit = async (record: FormValues) => {
    if (selectedItem) {
      await update(get(selectedItem, '_id'), record);
    } else {
      await create(record as EquipmentInput);
    }
  };

  const onCancel = () => {
    resetFields();
    toggleModal();
  };

  const onOk = () => {
    setSubmitting(true);
    form
      .validateFields()
      .then(async (record) => {
        await submit(record);
        form.resetFields();
        toggleModal();
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const filterEquipmentModel = (brand: string, type: string, data: FullCommonFragment[]) => {
    if (!brand && type) {
      return data.filter((item) => item.meta.type === type);
    }
    if (!type && brand) {
      return data.filter((item) => item.meta.brand === brand);
    }
    return data.filter((item) => item.meta.type === type && item.meta.brand === brand);
  };

  const handleBrandChanged = (brand?: string) => {
    const type = getFieldValue('type');
    setFieldsValue({ model: undefined });
    const filteredModel = filterEquipmentModel(brand, type, commonDataStore.equipmentType);
    setModelEquipment(filteredModel);
  };

  const handleTypeChanged = (type?: string) => {
    const brand = getFieldValue('brand');
    setFieldsValue({ model: undefined });
    const filteredModel = filterEquipmentModel(brand, type, commonDataStore.equipmentType);
    setModelEquipment(filteredModel);
  };

  return (
    <Modal
      title={t('EQUIPMENT_MANAGEMENT')}
      visible={isModalVisible}
      cancelText={t('CLOSE')}
      onCancel={onCancel}
      okText={selectedItem ? t('UPDATE') : t('CREATE')}
      okButtonProps={{
        type: 'primary',
        form: 'equipForm',
        htmlType: 'submit'
      }}
      onOk={onOk}
      maskClosable={false}
      forceRender
      confirmLoading={submitting}
    >
      <Form {...layout} form={form} name="equipForm">
        <Item label={t('LOCATION')} name="locationId" rules={validationSchema.locationId}>
          <UISelect
            dataOptions={{
              data: commonDataStore.locations,
              valueField: '_id',
              textField: 'name'
            }}
            placeholder={t('TYPE_TO_SEARCH_LOCATION')}
          />
        </Item>
        <Item label={t('NAME')} name="name" required>
          <Input placeholder={t('NAME')} />
        </Item>
        <Item label={t('BRAND')} name="brand" rules={validationSchema.brand}>
          <UISelect
            dataOptions={{
              data: commonDataStore.brandAp,
              valueField: 'value',
              textField: 'text'
            }}
            allowClear
            onChange={handleBrandChanged}
            placeholder={t('CHOOSE_BRAND')}
          />
        </Item>
        <Item label={t('TYPE')} name="type" rules={validationSchema.type}>
          <UISelect
            dataOptions={{
              data: typeOption,
              valueField: 'value',
              textField: 'children'
            }}
            allowClear
            onChange={handleTypeChanged}
            placeholder={t('CHOOSE_TYPE')}
          />
        </Item>
        <Item label={t('MODEL')} name="model" rules={validationSchema.model}>
          <UISelect
            dataOptions={{
              data: modelEquipment,
              valueField: 'value',
              textField: 'text'
            }}
            allowClear
            placeholder={t('CHOOSE_MODEL')}
            disabled={!getFieldValue('brand') && !getFieldValue('type')}
          />
        </Item>
        <Item label={t('DEVICE_MAC')} name="mac">
          <MaskedInput
            mask="11:11:11:11:11:11"
            placeholder="AA:BB:CC:DD:EE:FF"
            disabled={!!selectedItem}
            formatCharacters={{
              '1': {
                validate(char: string) {
                  return /[0-9A-Fa-f]/.test(char);
                },
                transform(char: string) {
                  return char.toUpperCase();
                }
              }
            }}
          />
        </Item>
        <Item label='Gateway' name="gateway">
          {/* <MaskedInput mask={DUMB_IP_MASK} placeholder="192.168.1.1" /> */}
          <Input placeholder='192.168.1.1' />
        </Item>
        <Item label={t('SERIAL')} name="serial">
          <Input placeholder={t('SERIAL')} disabled={!!selectedItem} />
        </Item>
      </Form>
    </Modal>
  );
});

export default EquipmentForm;
