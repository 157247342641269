import { Button, Input, Modal } from 'antd';
import { observer } from 'mobx-react';
import React, { FC, useEffect } from 'react';

import store from './store';

const TFAForm: FC = observer(() => {
  const { resetStore } = store;
  useEffect(() => {
    return () => {
      resetStore();
    };
  }, []);

  const {
    isShowTFAForm,
    setIsShowTFAForm,
    setPassword,
    confirmLogin,
    currentUser,
    step,
    nextStep,
    prevousStep,
    otpauthUrl,
    isValidCode,
    setVerifiedCode,
    verifyTwoFactorAuthenticationCode,
    enableTwoFactorAuthentication,
    disableTwoFactorAuthentication,
    isEnable
  } = store;

  if (!isShowTFAForm) return null;

  let content;
  let footer;

  switch (step) {
    case 'info':
      content = (
        <p>
          Two-Factor Authentication adds an extra layer of protection to your account. Whenever you
          log in, you'll need to enter both your password and also a security code from an app on
          your mobile phone.
        </p>
      );
      footer = (
        <Button type="primary" onClick={() => nextStep()}>
          Next
        </Button>
      );
      break;
    case 'login':
      content = (
        <div>
          <p>
            For security, please enter your password for email:{' '}
            <span style={{ fontWeight: 'bold' }}>{currentUser.email}</span>
          </p>
          <br />
          <Input.Password
            onChange={(e) => setPassword(e.target.value)}
            style={{ marginTop: 15, minWidth: 150, maxWidth: 300 }}
            placeholder="password"
          />
        </div>
      );
      footer = (
        <Button type="primary" onClick={() => confirmLogin()}>
          Next
        </Button>
      );
      break;

    case 'qrScan':
      content = (
        <div>
          <p>
            An authenticator app lets you generate security codes on your phone without needing to
            receive text messages.
          </p>
          <br />

          <p>Use your app to scan the QR-Code below</p>
          <br />
          <img
            style={{ height: 200, width: 200, margin: 'auto', marginTop: 5, display: 'block' }}
            src={otpauthUrl}
            alt="QR code"
          />
        </div>
      );
      footer = (
        <Button type="primary" onClick={() => nextStep()}>
          Next
        </Button>
      );
      break;
    case 'verify':
      content = (
        <div>
          <p>
            Enter the security code generated by your authentication app for verifying the settings.
          </p>
          <br />
          <Input
            onChange={(e) => setVerifiedCode(e.target.value)}
            style={{ marginTop: 15, minWidth: 150, maxWidth: 300 }}
            placeholder="6-digit code"
            maxLength={6}
          />
          <br />
          {!isValidCode && <span style={{ fontSize: 15, color: '#FFAA00' }}>Invalid Code</span>}
        </div>
      );
      footer = (
        <>
          <Button onClick={() => prevousStep()}>Back</Button>
          <Button type="primary" onClick={() => verifyTwoFactorAuthenticationCode()}>
            Confirm
          </Button>
        </>
      );
      break;
    case 'enable':
      content = <p>Do you want to active Two-Factor Authentication?</p>;
      footer = (
        <>
          <Button onClick={() => prevousStep(2)}>Back</Button>
          <Button type="primary" onClick={() => enableTwoFactorAuthentication()}>
            Active
          </Button>
        </>
      );
      break;
    case 'disable':
      content = <p>Do you want to deactive Two-Factor Authentication?</p>;
      footer = (
        <>
          <Button onClick={() => setIsShowTFAForm(false)}>Close</Button>
          <Button type="primary" onClick={() => disableTwoFactorAuthentication()}>
            Deactive
          </Button>
        </>
      );
      break;
    case 'done':
      content = (
        <p>
          From now on, when you log in to the website, you'll need to enter a security code from
          your phone.
        </p>
      );
      footer = (
        <>
          <Button onClick={() => setIsShowTFAForm(false)}>Done</Button>
        </>
      );
      break;
    default:
      content = <p>Something wrong!</p>;
      footer = <Button onClick={() => setIsShowTFAForm(false)}>Clode</Button>;
      break;
  }

  return (
    <Modal
      title={`${isEnable ? 'Disable' : 'Enable'} Two-Factor Authentication`}
      visible={isShowTFAForm}
      onCancel={() => setIsShowTFAForm(false)}
      footer={footer}
      maskClosable={false}
    >
      {content}
    </Modal>
  );
});

export default TFAForm;
