import { Badge, Tag } from 'antd';
import React from 'react';

import { ACTIVE, COMING_SOON, DISABLED, DISAPPROVED, FINISHED, RUNNING } from '@source/common';

// report campaign
export const campaignsTemplate = (campaign: any) => {
  return (
    <>
      {campaign.status === 'running' ? (
        <span>
          <Badge status="success" />
        </span>
      ) : (
        <span>
          <Badge status="processing" />
        </span>
      )}
      {/* <Tag color="magenta">{campaign.ownerType}</Tag> */}
      {/* <Tag color="gold">{campaign.type}</Tag> */}
      <span>{campaign.name}</span>
    </>
  );
};

// filter locaiton
export const filterLocations = (l: any) => {
  return (
    <>
      {l.status === 'active' ? (
        <span>
          <Badge status="success" />
        </span>
      ) : (
        <span>
          <Badge status="default" />
        </span>
      )}
      <span>{l.name}</span>
    </>
  );
};

// report Location
export const locationsTemplate = (location: any) => {
  let status: string;
  switch (location.status) {
    case ACTIVE:
      status = 'processing';
      break;
    case COMING_SOON:
    default:
      status = 'warning';
  }
  return (
    <>
      <span>
        <Badge status={status as any} />
      </span>
      <span style={{ color: '#FFA242' }}>{location.name}</span>
    </>
  );
};

export const selectCampaignTemplate = (campaign: any) => {
  let status: string;
  let color: string;
  switch (campaign.status) {
    case RUNNING:
      status = 'processing';
      color = '#87d068';
      break;
    case FINISHED:
      status = 'success';
      color = '#108ee9';
      break;
    default:
      status = 'default';
      color = 'gray';
  }
  return (
    <>
      <span>
        <Badge status={status as any} color={color} />
      </span>
      <span style={{ color: '#FFA242' }}>
        <Tag>{campaign.ownerType}</Tag>
        {campaign.name}
      </span>
    </>
  );
};
