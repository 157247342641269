import { verify } from 'crypto';
import { isEmpty, throttle } from 'lodash';
import { action, observable, runInAction } from 'mobx';
import { actionAsync, task } from 'mobx-utils';
import moment from 'moment';

import { GridReadyEvent } from '@ag-grid-community/core';
import { VERIFIED } from '@source/common';

import { ReportIncomeResult } from '~graphql/_sdk';
import { getSdk } from '~graphql/sdk';

class RPIncomeCampaignStore {
  @observable public loading: boolean;
  @observable public income: ReportIncomeResult;
  @observable public filter;
  @observable public filterReport;
  @observable public nodeHeight: number;
  @observable public campaigns;
  @observable public groupByLocation: any;
  @observable public hideGroupName: Boolean;
  @observable.ref public campaignMapping: any;
  public datasource: any;

  constructor() {
    runInAction(() => {
      this.cleanUp();
      this.hideGroupName = false;
    });
  }

  @action
  public setWhere = where => {
    this.filterReport = where;
  };

  @action
  public cleanUp = () => {
    this.filter = {
      date: {
        gte: moment()
          .subtract(30, 'days')
          .startOf('day'),
        lte: moment().startOf('day')
      },
      campaignId: []
    };
    this.income = {};
    this.campaigns = [];
  };

  @action
  public handleGridReady = ({ api }: GridReadyEvent) => {
    this.reload = () => {
      api.onFilterChanged();
    };
    this.resetRowHeight = () => {
      api.resetRowHeights();
    };
  };

  @actionAsync
  public getCampaignList = async () => {
    const sdk = await task(getSdk());
    const { findManyRevenue } = await task(sdk.getCampaignRevenue({ where: { status: VERIFIED } }));
    this.campaigns = findManyRevenue;
  };

  @actionAsync
  public getCampaignMapping = async campaignId => {
    const sdk = await task(getSdk());
    const { findManyCampaignMapping } = await task(
      sdk.findManyCampaignMapping({
        where: {
          campaignId
        }
      })
    );

    this.campaignMapping = findManyCampaignMapping.reduce((all, item) => {
      all[item.locationId] = item;
      return all;
    }, {});
    this.groupByLocation = [];

    this.income.groupByLocation.forEach((item: any) => {
      const reportLocationId = this.campaignMapping[item.locationId].reportLocationId;
      if (this.campaignMapping[item.locationId].reportLocationId) {
        item.groupName = this.income.groupByLocation.find(
          i => i.locationId === reportLocationId
        ).location.name;
      } else {
        item.groupName = item.location.name;
      }
      this.groupByLocation.push(item);
    });
  };

  @actionAsync
  public getReportIncome = async () => {
    const sdk = await task(getSdk());
    this.loading = true;

    const { getReportIncomeByCampaign } = await task(
      sdk.getReportIncomeByCampaign({
        filter: {
          campaignId: !isEmpty(this.filterReport.campaignId)
            ? this.filterReport.campaignId
            : this.campaigns.map(item => item.campaignId),
          date: this.filterReport.date
        }
      })
    );
    this.loading = false;
    this.income = getReportIncomeByCampaign;

    runInAction(() => {
      if (!isEmpty(this.filterReport.campaignId)) {
        this.hideGroupName = true;
        this.getCampaignMapping(this.filterReport.campaignId);
      } else {
        this.groupByLocation = this.income.groupByLocation;
      }
    });
  };

  @action
  public onReportFilterChange = (field: string, value: any[]) => {
    switch (field) {
      case 'date':
        this.filter = {
          ...this.filter,
          date: { gte: value[0], lte: value[1] }
        };
        break;
      default:
        this.filter = { ...this.filter, [field]: value ?? [] };
    }
  };

  @action
  public resetRowHeight = () => undefined;

  @action
  public reload = () => undefined;
}

export default new RPIncomeCampaignStore();
