/* eslint-disable jsx-a11y/alt-text */
import '@ant-design/compatible/assets/index.css';

import { Input, Modal } from 'antd';
import i18next from 'i18next';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { FC, useEffect, useState } from 'react';
import useForm from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { Form } from '@ant-design/compatible';

import SelectCommon from '~components/Select/SelectCommon';
import UIField from '~components/UI/UIField';
import UIUpload from '~components/UI/UIUpload';
import { ImageInput, ImageUpdateArg } from '~graphql/_sdk';

import store from './store';

interface FormValues {
  name?: string;
  type?: string;
  category?: string;
  link?: string;
}

const validationSchema = yup.object().shape<FormValues>({
  name: yup.string().required(i18next.t('NAME_IS_REQUIRED')),
  type: yup.string().required(i18next.t('TYPE_IS_REQUIRED')),
  category: yup.string(),
  link: yup.string()
});

const ImageForm: FC = observer(() => {
  const { t } = useTranslation();
  const {
    modalVisible,
    setModalVisible,
    create,
    update,
    selectedItem,
    fileList,
    setFileList,
    link
  } = store;
  const [type, setType] = useState();

  const formProps = useForm({ validationSchema });

  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });

  const {
    reset,
    setValue,
    handleSubmit,
    formState: { isSubmitting }
  } = formProps;

  // eslint-disable-next-line no-shadow
  const handleTypeChange = (type) => {
    setType(type);
    setValue('type', type);
    switch (type) {
      case 'contentImage':
        setImageSize({ width: 160, height: 250 });
        break;

      case 'footerImage':
        setImageSize({ width: 800, height: 100 });
        break;

      default:
        setImageSize({ width: 200, height: 50 });
        break;
    }
  };

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    selectedItem && handleTypeChange(selectedItem.type);
  }, [selectedItem]);

  const initValues: FormValues = {
    name: get(selectedItem, 'name', ''),
    type: get(selectedItem, 'type'),
    category: get(selectedItem, 'category'),
    link: get(selectedItem, 'link')
  };

  const onCancel = () => {
    reset();
    setModalVisible();
  };

  const onSubmit = async (record: FormValues) => {
    Object.assign(record, { link });

    if (selectedItem) {
      await update(get(selectedItem, '_id'), record as ImageUpdateArg);
    } else {
      await create(record as ImageInput);
    }
  };

  return (
    <Modal
      title={t('IMAGE')}
      visible={modalVisible}
      cancelText="Close"
      cancelButtonProps={{ danger: true, ghost: true }}
      onCancel={() => onCancel()}
      okText={selectedItem ? 'Update' : 'Create'}
      okButtonProps={{
        htmlType: 'submit',
        form: 'imageForm',
        type: 'primary',
        loading: isSubmitting
      }}
    >
      <Form id="imageForm" onSubmit={handleSubmit(onSubmit)} layout="vertical">
        <UIField
          {...formProps}
          name="name"
          label={t('NAME')}
          fieldProps={{
            placeholder: t('INPUT_NAME')
          }}
          fieldComponent={Input}
          defaultValue={initValues.name}
        />
        <img src={initValues.link} />
        <UIField
          {...formProps}
          name="type"
          label={t('TYPE')}
          fieldProps={{
            placeholder: t('CHOOSE_TYPE'),
            onChange: handleTypeChange,
            where: { type: 'image_category' }
          }}
          fieldComponent={SelectCommon}
          defaultValue={initValues.type}
        />
        {type === 'contentImage' && (
          <UIField
            {...formProps}
            name="category"
            label={t('CATEGORY')}
            fieldProps={{
              placeholder: t('CHOOSE_CATEGORY'),
              where: { type: 'location_category' }
            }}
            fieldComponent={SelectCommon}
            defaultValue={initValues.category}
          />
        )}
        {selectedItem && (
          <UIUpload
            label={t('IMAGE')}
            listType="picture"
            handleChange={setFileList}
            quantity={1}
            defaultFileList={fileList}
            accept="image/x-png,image/jpg,image/jpeg"
            {...imageSize}
          />
        )}
      </Form>
    </Modal>
  );
});

export default ImageForm;
