import { Divider, Space } from 'antd';
import React, { FC, useEffect } from 'react';

import OwnerType from '~components/UI/OwnerTypeRadio';
import { useStore } from '~context/react-context';

import store from '../store';
import FilterCampaignList from './FilterCampaignList';
import Grid from './Grid';
import Header from './Header';

const CampaignList: FC = () => {
  const { commonDataStore } = useStore();
  const { initCampaignCount, setOwnerType } = store;

  useEffect(() => {
    commonDataStore.loadCampaigns().finally(() => {
      initCampaignCount(commonDataStore.campaigns);
    });
  }, []);

  return (
    <Space
      direction="vertical"
      style={{
        flex: 1,
        background: '#fff',
        padding: 8
      }}
    >
      <Header />
      <Divider style={{ margin: 0, color: '#737373' }} orientation="left">
        {/* Campaign List */}
        <OwnerType setOwnerFunc={setOwnerType} />
      </Divider>
      <FilterCampaignList />
      <Grid />
    </Space>
  );
};

export default CampaignList;
