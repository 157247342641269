import '@ant-design/compatible/assets/index.css';

import { Input, Modal, Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import i18next from 'i18next';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { FC, ReactText, useState } from 'react';
import useForm from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { Form } from '@ant-design/compatible';

import UIField from '~components/UI/UIField';
import { CommonInput, CommonUpdateArg } from '~graphql/_sdk';

import store from './store';

interface FormValues {
  value?: string;
  text?: string;
  type?: string;
  devices?: string[];
}

const validationSchema = yup.object().shape<FormValues>({
  value: yup.string().required(i18next.t('COMMON_DATA_VALIDATE_VALUE_REQUIRE')),
  text: yup.string().required(i18next.t('COMMON_DATA_VALIDATE_TEXT_REQUIRE')),
  type: yup.string().required(i18next.t('COMMON_DATA_VALIDATE_TYPE_REQUIRE')),
  devices: yup.array(yup.string())
});

const DeviceGroupForm: FC = observer(() => {
  const { modalVisible, setModalVisible, create, update, selectedItem, devices } = store;
  const [type, setType] = useState();
  const { t } = useTranslation();

  const initValues: FormValues = {
    value: get(selectedItem, 'value', ''),
    text: get(selectedItem, 'text', ''),
    type: get(selectedItem, 'meta.type'),
    devices: get(selectedItem, 'meta.devices', [])
  };

  const formProps = useForm({ validationSchema });

  const {
    reset,
    setValue,
    handleSubmit,
    formState: { isSubmitting }
  } = formProps;

  const onCancel = () => {
    reset();
    setModalVisible();
  };

  // eslint-disable-next-line no-shadow
  const onSubmit = async ({ type: deviceType, devices, ...record }: FormValues) => {
    // eslint-disable-next-line no-shadow
    const type = 'group_device';

    Object.assign(record, { type, meta: { type: deviceType, devices } });

    if (selectedItem) {
      await update(get(selectedItem, '_id'), record as CommonUpdateArg);
    } else {
      await create(record as CommonInput);
    }
    onCancel();
  };

  const handleSelectChange = (value) => {
    setType(value);
    setValue('type', value);
  };

  return (
    <Modal
      title={t('DEVICE_GROUP')}
      visible={modalVisible}
      cancelText={t('CLOSE')}
      cancelButtonProps={{ danger: true, ghost: true }}
      onCancel={() => onCancel()}
      okText={selectedItem ? t('UPDATE') : t('CREATE')}
      okButtonProps={{
        htmlType: 'submit',
        form: 'bannerTemplateForm',
        type: 'primary',
        loading: isSubmitting
      }}
    >
      <Form id="bannerTemplateForm" onSubmit={handleSubmit(onSubmit)} layout='vertical'>
        <UIField
          {...formProps}
          name="text"
          label={t('NAME')}
          fieldProps={{
            placeholder: t('INPUT_NAME')
          }}
          fieldComponent={Input}
          defaultValue={initValues.text}
        />
        <UIField
          {...formProps}
          name="value"
          label={t('VALUE')}
          fieldProps={{
            placeholder: t('COMMON_DATA_PLACEHOLDER_VALUE'),
            disabled: !!selectedItem
          }}
          fieldComponent={Input}
          defaultValue={initValues.value}
        />
        <UIField
          {...formProps}
          name="type"
          label={t('TYPE')}
          fieldProps={{
            placeholder: t('CHOOSE_TYPE'),
            onChange: handleSelectChange,
            children: ['ap', 'router'].map((item, index) => (
              <Select.Option key={String(index)} value={item}>
                {item.toUpperCase()}
              </Select.Option>
            ))
          }}
          fieldComponent={Select}
          defaultValue={initValues.type}
        />
        <UIField<SelectProps<ReactText>>
          {...formProps}
          name="devices"
          label={t('DEVICE')}
          fieldProps={{
            placeholder: t('CHOOSE_DEVICE'),
            mode: 'multiple',
            children: devices
              .filter((item) => item.meta.type === type)
              .map((item, index) => (
                <Select.Option key={String(index)} value={item.value}>
                  {item.text}
                </Select.Option>
              ))
          }}
          fieldComponent={Select}
          defaultValue={initValues.devices}
        />
      </Form>
    </Modal>
  );
});

export default DeviceGroupForm;
