import { observer } from 'mobx-react';
import React, { FC, useEffect } from 'react';

import ModelDeviceForm from './Form';
import ModelDeviceGrid from './Grid';
import ModelDeviceHeader from './Header';
import store from './store';

const ModelDeviceScreen: FC = observer(() => {
  const { fetchBrandAp } = store;

  useEffect(() => {
    fetchBrandAp();
  }, [fetchBrandAp]);

  return (
    <>
      <ModelDeviceHeader />
      <ModelDeviceGrid />
      <ModelDeviceForm />
    </>
  );
});

export default ModelDeviceScreen;
