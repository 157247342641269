import { Button, Dropdown, Menu, Modal } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import { ICellRendererParams } from '@ag-grid-community/core';
import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons';
import {
  AUTHORIZED_ROLES_DELETE_EQUIPMENT,
  AUTHORIZED_ROLES_EDIT_EQUIPMENT,
  MODAL_UPDATE,
  ROLE_DELETE_CUSTOMER_TYPE,
  ROLE_DELETE_EQUIPMENT,
  ROLE_UPDATE_CUSTOMER_TYPE,
  ROLE_UPDATE_EQUIPMENT
} from '@source/common';

import Authorized from '~components/Authorized/Authorized';

import store from './store';

const { confirm } = Modal;

@observer
export default class ActionRenderer extends Component<ICellRendererParams> {
  public render() {
    const { remove, toggleModal } = store;

    const { data, value } = this.props;

    const confirmDelete = (id: string) => {
      confirm({
        title: '  Are you sure delete ?',
        okText: 'Yes',
        okType: 'danger',
        icon: <ExclamationCircleOutlined />,
        cancelText: 'No',
        onOk() {
          remove(id);
        }
      });
    };

    const moreActions = (
      <Menu>
        <Menu.Item key="delete" onClick={() => confirmDelete(value)}>
          <DeleteOutlined style={{ color: 'red' }} />
          Delete
        </Menu.Item>
      </Menu>
    );
    return (
      <Button.Group size="small">
        <Authorized roles={[ROLE_UPDATE_CUSTOMER_TYPE]}>
          <Button
            icon={<EditOutlined />}
            type="primary"
            onClick={() => toggleModal(MODAL_UPDATE, data)}
          />
        </Authorized>
        <Authorized roles={[ROLE_DELETE_CUSTOMER_TYPE]}>
          <Dropdown overlay={moreActions}>
            <Button icon={<EllipsisOutlined />} type="default" />
          </Dropdown>
        </Authorized>
      </Button.Group>
    );
  }
}
