/* eslint-disable react/destructuring-assignment */
import { Checkbox, Col, Input, Row, Select } from 'antd';
import React from 'react';

interface CustomFieldsInterface {
  index: number;
  record: any;
  onChange: Function;
}
const RenderCustomFields = (props: CustomFieldsInterface) => {
  const { record, index } = props;

  const handleCheckOnChange = e => {
    props.onChange(props.index, { ...record, isChecked: e.target.checked });
  };

  const handleInputOnChange = e => {
    props.onChange(props.index, { ...record, fieldValue: e.target.value });
  };

  const handleSelectOnChange = e => {
    props.onChange(props.index, { ...record, fieldOptions: e });
  };

  return (
    <div
      style={{
        border: '1px solid #d9d9d9',
        borderRadius: 4,
        padding: 10,
        marginTop: 10,
        width: '48%',
        minWidth: 200
      }}
      key={index}
    >
      <Row>
        <Col sm={24} md={5}>
          <Checkbox checked={record.isChecked} onChange={e => handleCheckOnChange(e)} value={index}>
            Fields {props.index}
          </Checkbox>
        </Col>
        <Col sm={24} md={19}>
          <Input
            value={record.fieldValue}
            disabled={!record.isChecked}
            onChange={handleInputOnChange}
          />
          <Select
            value={record.fieldOptions}
            disabled={!record.isChecked}
            onChange={e => handleSelectOnChange(e)}
            mode="tags"
            style={{ width: '100%', marginTop: 10 }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default RenderCustomFields;
