import { observer } from 'mobx-react';
import React from 'react';

import Grid from './Grid';
import Header from './Header';

const InventoryScreen = observer(() => {
  return (
    <>
      <Header />
      <Grid />
    </>
  );
});

export default InventoryScreen;
