import { Button, Input, PageHeader } from 'antd';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import {
  AUTHORIZED_ROLES_CREATE_EQUIPMENT,
  MODAL_CREATE,
  ROLE_CREATE_CUSTOMER,
  ROLE_CREATE_EQUIPMENT
} from '@source/common';

import Authorized from '~components/Authorized/Authorized';
import { isMobile } from '~utils/isMobile';

import store from './store';

const Header: FC = observer(() => {
  const { t } = useTranslation();
  const { setModalVisible,handleSearch } = store;
  return (
    <PageHeader
      title="Customer"
      style={{ background: '#fff', marginBottom: 0 }}
      extra={[
        <Authorized roles={[ROLE_CREATE_CUSTOMER]} key="create">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setModalVisible(undefined, MODAL_CREATE)}
          >
            {isMobile() ? '' : t('CREATE')}
          </Button>
        </Authorized>,
        <Input
          key="search"
          suffix={<SearchOutlined />}
          placeholder={t('SEARCH')}
          allowClear
          onChange={(e) => handleSearch(e.target.value)}
          style={{ width: '200px' }}
        />
      ]}
    />
  );
});

export default Header;
