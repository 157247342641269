import { Button, Input, PageHeader } from 'antd';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

/* eslint-disable no-shadow */
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import {
  AUTHORIZED_ROLES_CREATE_EQUIPMENT,
  MODAL_CREATE,
  ROLE_CREATE_CUSTOMER_TYPE,
  ROLE_CREATE_EQUIPMENT
} from '@source/common';

import Authorized from '~components/Authorized/Authorized';
import { isMobile } from '~utils/isMobile';

import store from './store';

const Header: FC = observer(() => {
  const { t } = useTranslation();

  const { handleSearch, toggleModal } = store;

  return (
    <PageHeader
      title={null}
      style={{ background: '#fff', marginBottom: 0 }}
      extra={[
        <Authorized roles={[ROLE_CREATE_CUSTOMER_TYPE]} key="create">
          <Button type="primary" icon={<PlusOutlined />} onClick={() => toggleModal(MODAL_CREATE)}>
            {isMobile() ? '' : t('CREATE')}
          </Button>
        </Authorized>,
        <Input
          key="search"
          suffix={<SearchOutlined />}
          placeholder={t('SEARCH')}
          allowClear
          onChange={(e) => handleSearch(e.target.value)}
          style={{ width: '200px' }}
        />
      ]}
    />
  );
});

export default Header;
