import { Button, Dropdown, Menu, Modal, Popover, Tag } from 'antd';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactResizeDetector from 'react-resize-detector';
import { useHistory } from 'react-router-dom';

import { ColDef, ColGroupDef } from '@ag-grid-community/core';
import {
  EllipsisOutlined,
  PlayCircleOutlined,
  SettingOutlined
} from '@ant-design/icons';
import {
  ADVERTISING,
  APPROVED,
  DISAPPROVED,
  FINISHED,
  PARTNER,
  ROLE_ADVANCE_UPDATE_CAMPAIGN,
  ROLE_APPROVE_CAMPAIGN,
  ROLE_CONFIG_CAMPAIGN,
  ROLE_DELETE_CAMPAIGN,
  ROLE_MANAGE_RUNNING_CAMPAIGN,
  ROLE_MANAGE_SCHEDULE_CAMPAIGN,
  ROLE_MANAGE_TIMES_CAMPAIGN,
  RUNNING,
  SCHEDULED,
  STOPPED
} from '@source/common';

import Authorized from '~components/Authorized/Authorized';
import AgGridServer from '~components/UI/AgGrid/AgGridServer';
import { useStore } from '~context/react-context';
import { useGraphQLSDK } from '~graphql/GraphQLSDKProvider';
import { DATE_FORMAT, DATE_TIME_FORMAT, format } from '~utils/formatDate';

import store from './store';

const { confirm } = Modal;

const CampaignsGrid: FC = observer(() => {
  const { t } = useTranslation();
  const { commonDataStore } = useStore();
  const { handleGridReady, whereFilter, remove, setAdsType } = store;

  useEffect(() => {
    setAdsType();
    commonDataStore.loadCampaignType();
  }, []);

  const history = useHistory();
  const sdk = useGraphQLSDK();

  const generateStatusTag = (status: string, data: any) => {
    let color: string;
    switch (status) {
      case RUNNING:
        color = 'success';
        break;
      case SCHEDULED:
      case APPROVED:
        color = 'warning';
        break;
      case STOPPED:
      case DISAPPROVED:
        color = 'error';
        break;
      case FINISHED:
        color = 'blue';
        break;
      default:
        color = 'default';
    }

    return status === 'stopped' ? (
      <Popover
        title="Running again at"
        content={format(data.stoppedUntil, DATE_TIME_FORMAT)}
        trigger="hover"
      >
        <Tag color={color}>{status.toUpperCase()}</Tag>
      </Popover>
    ) : (
      <Tag color={color}>{status.toUpperCase()}</Tag>
    );
  };

  const generateTypeTag = (type: string) => {
    let color: string;
    switch (type) {
      case ADVERTISING:
        color = '#87d068';
        break;
      case PARTNER:
        color = '#2db7f5';
        break;
      default:
        color = '#737373';
    }

    const result = commonDataStore.campaignType.find((item) => item.value === type)?.text || '-';

    return <Tag color={color}>{result.toUpperCase()}</Tag>;
  };

  const showDeleteConfirm = (value, text) => {
    confirm({
      width: 500,
      title: (
        <div>
          {t('ARE_YOU_SURE_DELETE')} <b>{text.toUpperCase()}</b> ?
        </div>
      ),
      okText: 'YES',
      okType: 'danger',
      cancelText: 'KO',
      onOk() {
        remove(value);
      }
    });
  };

  const showStopConfirm = (value, text) => {
    confirm({
      width: 500,
      title: (
        <div>
          {t('ARE_YOU_SURE_STOP')} <b>{text.toUpperCase()}</b> ?
        </div>
      ),
      okText: 'YES',
      okType: 'danger',
      cancelText: 'KO',
      onOk() {
        remove(value);
      }
    });
  };

  const columnDefs: ColDef[] | ColGroupDef[] = [
    {
      headerName: '',
      field: '_id',
      cellClass: 'text-center',
      minWidth: 70,
      maxWidth: 80,
      sortable: false,
      pinned: 'left',
      cellRendererFramework: ({ value, data }) => {
        const moreActions = (
          <Menu>
            <Authorized roles={[ROLE_CONFIG_CAMPAIGN]}>
              <Menu.Item
                key="config"
                style={{ color: 'green' }}
                onClick={() => history.push(`/campaigns/config?id=${value}`)}
              >
                <SettingOutlined />
                {t('CONFIG')}
              </Menu.Item>
            </Authorized>
            {/* <Authorized roles={[ROLE_MANAGE_RUNNING_CAMPAIGN]}>
              <Menu.Item
                key="disable"
                style={{ color: 'orange' }}
                onClick={() => showStopConfirm(value, data.name)}
              >
                <PauseOutlined />
                {t('DISABLE')}
              </Menu.Item>
            </Authorized> */}

            {get(data, 'status') === STOPPED && (
              <Authorized roles={[ROLE_MANAGE_RUNNING_CAMPAIGN]}>
                <Menu.Item
                  key="enable"
                  style={{ color: 'green' }}
                  onClick={() => store.enable(value)}
                >
                  <PlayCircleOutlined />
                  {t('ENABLE')}
                </Menu.Item>
              </Authorized>
            )}
          </Menu>
        );

        return (
          <Button.Group size="small">
            <Button
              type={data.isBookOnline ? 'primary' : 'default'}
              onClick={() => store.setModalVisible(data, 'view')}
            >
              <i className="fas fa-info-circle fa-1x" />
            </Button>
            <Authorized
              roles={[
                ROLE_MANAGE_SCHEDULE_CAMPAIGN,
                ROLE_MANAGE_TIMES_CAMPAIGN,
                ROLE_ADVANCE_UPDATE_CAMPAIGN,
                ROLE_APPROVE_CAMPAIGN,
                ROLE_CONFIG_CAMPAIGN,
                ROLE_CONFIG_CAMPAIGN,
                ROLE_MANAGE_RUNNING_CAMPAIGN,
                ROLE_DELETE_CAMPAIGN
              ]}
            >
              <Dropdown overlay={moreActions}>
                <Button icon={<EllipsisOutlined />} type="default" />
              </Dropdown>
            </Authorized>
          </Button.Group>
        );
      }
    },
    {
      headerName: t('NO'),
      field: '_id',
      cellClass: 'text-center',
      minWidth: 50,
      maxWidth: 50,
      sortable: false,
      pinned: 'left',
      cellRenderer: ({ rowIndex }) => rowIndex + 1
    },
    {
      headerName: t('NAME'),
      field: 'name',
      pinned: 'left',
      minWidth: 250
      // cellRendererFramework: ({ value, data }) => {
      //   const isBookOnline = data.isBookOnline;
      //   return isBookOnline ? (
      //     <>
      //       <Tag color={'blue'}>BO</Tag> <span>{value}</span>
      //     </>
      //   ) : (
      //     value
      //   );
      // }
    },
    {
      headerName: t('TYPE'),
      field: 'type',
      // sort: 'desc',
      minWidth: 140,
      maxWidth: 150,
      cellRendererFramework: ({ value }) => {
        return value ? generateTypeTag(value) : '';
      }
    },
    {
      headerName: t('STATUS'),
      field: 'status',
      // sort: 'desc',
      minWidth: 120,
      maxWidth: 120,
      cellRendererFramework: ({ value, data }) => {
        return generateStatusTag(value, data);
      }
    },
    {
      headerName: t('FROM'),
      field: 'scheduleFrom',
      cellClass: 'text-right',
      minWidth: 110,
      maxWidth: 110,
      sort: 'desc',
      cellRenderer: ({ value }) => format(value, DATE_FORMAT)
    },
    {
      headerName: t('TO'),
      field: 'scheduleTo',
      cellClass: 'text-right',
      minWidth: 110,
      maxWidth: 110,
      cellRenderer: ({ value }) => (value ? format(value, DATE_FORMAT) : '-')
    },
    {
      headerName: t('BOOKED'),
      field: 'kpi',
      headerClass: 'text-center',
      cellClass: 'text-right',
      minWidth: 100,
      maxWidth: 120,
      cellRenderer: ({ value }) => `${value ? value.toLocaleString() : 'Unlimited'}`
    },
    {
      headerName: t('CLICK'),
      field: 'stats.click',
      headerClass: 'text-center',
      cellClass: 'text-right',
      sortable: false,
      minWidth: 110,
      maxWidth: 120,
      cellRenderer: ({ value }) => `${value ? value.toLocaleString() : 0}`
    },
    {
      headerName: t('COMPLETED'),
      headerClass: 'text-center',
      cellClass: 'text-right',
      field: 'stats.kpiCompleted',
      sortable: false,
      minWidth: 100,
      maxWidth: 100,
      cellRenderer: ({ value }) => `${value ? value.toLocaleString() : 0} %`
    },
    {
      headerName: t('CREATED_AT'),
      field: 'createdAt',
      sort: 'desc',
      minWidth: 110,
      maxWidth: 110,
      cellRenderer: ({ value }) => format(value, DATE_FORMAT)
    }
  ];

  return (
    <>
      <ReactResizeDetector handleHeight>
        {({ height }) => (
          <AgGridServer
            emptyHandler={() => store.setModalCreateVisible()}
            screen="campaign-basic"
            options={{
              columnDefs
              //  cacheBlockSize: 50, paginationPageSize: 50
            }}
            onQuery={sdk.findManyCampaign}
            queryKey="findManyCampaign"
            onCountQuery={sdk.countCampaign}
            countQueryKey="countCampaign"
            onGridReady={handleGridReady}
            where={whereFilter}
            height={height}
            // showWhere
          />
        )}
      </ReactResizeDetector>
    </>
  );
});

export default CampaignsGrid;
