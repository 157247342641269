import { Button, Input, PageHeader, Radio } from 'antd';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import {
  ROLE_CREATE_INSTRUCTION
} from '@source/common';

import Authorized from '~components/Authorized/Authorized';

import store from './store';

export const fcName = (name) => {
  switch (name) {
    case 'megaplus_user':
      return 'Thao tác trên MKT';

    case 'megaplus_router':
      return 'Tích hợp Router';

    case 'megaplus_access_point':
      return 'Tích hợp Access Point';

    default:
      return 'Hướng Dẫn Khác';
  }
};
const InstructionHeader: FC = observer(() => {
  const {
    setSearchText,
    instructions,
    selectInstructionTypeInst,
    title,
  } = store;
  const { t } = useTranslation();


  return (
    <PageHeader
      style={{ background: '#fff', marginBottom: 0 }}
      title={title}
      extra={[
        // <UISelect
        //   allowClear
        //   autoClearSearchValue
        //   dataOptions={{ data, valueField: '_id', textField: 'name' }}
        //   disabled={loading}
        //   loading={loading}
        //   placeholder="Choose Instructions...."
        //   key="Instructions"
        //   onChange={(value, opt) => selectTypeInst(value, opt)}
        //   style={{ width: 200 }}
        //   showArrow
        //   notFoundContent={[] ? <Spin size="small" /> : null}
        // />,
        <Radio.Group
          key="radio"
          onChange={(e) => selectInstructionTypeInst(e.target.value, null)}
          defaultValue="megaplus_mkt"
        >
          <Radio.Button value="megaplus_mkt">Hướng dẫn quản trị AdsPlatform</Radio.Button>
          <Radio.Button value="megaplus_device">Hướng dẫn tích hợp</Radio.Button>
          {/* <Radio.Button value="megaplus_access_point">Tích hợp Access Point</Radio.Button> */}
        </Radio.Group>,
        <Input
          key="search"
          suffix={<SearchOutlined />}
          placeholder={t('SEARCH')}
          allowClear
          onChange={(e) => setSearchText(e.target.value)}
          style={{ width: '200px', marginRight: '8px' }}
        />,
        <Authorized roles={[ROLE_CREATE_INSTRUCTION]} key="tuto">
          <Button onClick={() => store.setModalVisible()} type="primary" icon={<PlusOutlined />}>
            {t('CREATE')}
          </Button>
        </Authorized>
      ]}
    />
  );
});

// <Filter dateFields={dateFields} filterSelect={initFilterConfig} />

export default InstructionHeader;
