import { flatMap, uniq } from 'lodash';
import { observer } from 'mobx-react';
import React, { FC, useEffect } from 'react';

import { blue, gold, green, grey, magenta } from '@ant-design/colors';
import { ADVERTISING, DEMO, PARTNER, UNLIMITED } from '@source/common';

import HighChart from '~components/UI/HighChart/HighChart.lazy';
import { useStore } from '~context/react-context';
import { format, RANGE_DAY_FORMAT } from '~utils/formatDate';

import store from './store';

interface Chart {
  heightChart: number;
  widthChart: number;
}

const colorMap = {
  [ADVERTISING]: green.primary,
  [DEMO]: grey.primary,
  [PARTNER]: blue.primary,
  [UNLIMITED]: gold.primary,
  others: magenta.primary
};

const CampaignRatio: FC<Chart> = observer((props) => {
  const { heightChart, widthChart } = props;
  const { campaignRatioData } = store;
  const { commonDataStore } = useStore();

  const categories = uniq(flatMap(campaignRatioData.map((d) => d.detail)).map((i) => i.date));

  useEffect(() => {
    commonDataStore.loadCampaignType();
  }, []);

  const options: Highcharts.Options = {
    title: {
      text: 'Campaign Type Ratio',
      y: 20,
    },
    xAxis: {
      categories: categories.map((c) => format(c, RANGE_DAY_FORMAT)),
      tickmarkPlacement: 'on',
      tickInterval: categories.length > 20 ? 5 : 0
    },
    yAxis: {
      title: {
        text: 'Clicks'
      }
    },
    plotOptions: {
      area: {
        stacking: 'normal',
        lineColor: '#666666',
        lineWidth: 1,
        marker: {
          enabled: false
        }
      }
    },
    series: campaignRatioData.map((d) => ({
      type: 'area',
      color: colorMap[d.type],
      name: commonDataStore.campaignType.find(item => item.value === d.type)?.text || '-',
      data: d.detail.map((item) => {
        if (categories.includes(item.date)) {
          return item.click;
        }
        return 0;
      })
    })),
    chart: {
      type: 'area',
      events: {
        redraw: (e) => {
          e.preventDefault();
        }
      },
      height: heightChart,
      width: widthChart
    },
    boost: { enabled: true },
    tooltip: {
      shared: true,
      pointFormat:
        '<span style="color:{series.color}">{series.name}</span>: <b>{point.percentage:.1f}%</b> ({point.y:,.0f})<br/>',
      split: true
    }
  };
  return (
    <HighChart options={options} />
  );
});

export default CampaignRatio;
