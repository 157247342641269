import { Spin } from 'antd';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import HighChart from '~components/UI/HighChart/HighChart.lazy';
import { format, RANGE_DAY_FORMAT } from '~utils/formatDate';

import store from './store';

interface Chart {
  heightChart: number;
  widthChart: number;
}
const Chart: FC<Chart> = observer(props => {
  const { heightChart, widthChart } = props;
  const { income, loading } = store;
  const { t } = useTranslation();

  const getItem = (key, isDate = false) => {
    const dataChart = income.time;
    const realData = dataChart?.reduce((all, item) => {
      all = [...all, isDate ? format(get(item, key, []), RANGE_DAY_FORMAT) : get(item, key, [])];
      return all;
    }, []);
    return realData || null;
  };

  const options: Highcharts.Options = {
    title: {
      text: ''
    },
    plotOptions: {
      series: {
        label: {
          enabled: false
        }
      }
    },
    series: [
      // {
      //   name: t('CLICK'),
      //   color: '#0078D4',
      //   type: 'column',
      //   data: getItem('click'),
      //   boostThreshold: 1
      // },
      {
        name: t('INCOME'),
        color: '#FFA242',
        type: 'column',
        data: getItem('incomePartner') || []
      }
    ],
    xAxis: {
      categories: getItem('date', true),
      labels: {
        rotation: -45
      },
      title: {
        text: null
      },
      tickInterval: 5
    },
    yAxis: [
      {
        title: {
          text: t('CLICK'),
          align: 'middle'
        },
        labels: {
          overflow: 'justify'
        }
      },
      {
        title: {
          text: t('INCOME'),
          align: 'middle'
        },
        labels: {
          overflow: 'justify'
        },
        opposite: true
      }
    ],
    chart: {
      events: {
        redraw: e => {
          e.preventDefault();
        }
      },
      height: heightChart,
      width: widthChart
    },
    boost: { enabled: true },
    tooltip: {
      shared: true
    },
    credits: {
      enabled: false
    }
  };
  return (
    <Spin style={{ width: widthChart }} spinning={loading} tip="Loading...">
      <HighChart options={options} />
    </Spin>
  );
});

export default Chart;
