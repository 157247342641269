import { Button, Dropdown, Menu, Modal, Popconfirm, Row, Tag } from 'antd';
import i18next from 'i18next';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ReactResizeDetector from 'react-resize-detector';

import { ColDef } from '@ag-grid-community/core';
import { DeleteOutlined, EditOutlined, EllipsisOutlined } from '@ant-design/icons';
import {
  AUTHORIZED_ROLES_DELETE_DEVICE_GROUP,
  AUTHORIZED_ROLES_UPDATE_DEVICE_GROUP,
  ROLE_DELETE_COMMON,
  ROLE_UPDATE_COMMON
} from '@source/common';

import Authorized from '~components/Authorized/Authorized';
import AgGridServer from '~components/UI/AgGrid/AgGridServer';
import { useGraphQLSDK } from '~graphql/GraphQLSDKProvider';

import store from './store';

const { confirm } = Modal;

const DeviceGroupGrid: FC = observer(() => {
  const { t } = useTranslation();
  const sdk = useGraphQLSDK();

  const { handleGridReady, where, remove, setModalVisible } = store;
  const showDeleteConfirm = (value, text) => {
    confirm({
      title: (
        <div>
          {t('ARE_YOU_SURE_DELETE')} <b>{text}</b>
        </div>
      ),
      okText: t('YES'),
      okType: 'danger',
      cancelText: 'NO',
      onOk() {
        remove(value);
      }
    });
  };

  const columnDefs: ColDef[] = [
    {
      headerName: '',
      field: '_id',
      minWidth: 70,
      maxWidth: 70,
      cellRendererFramework: ({ value, data }) => {
        const moreActions = (
          <Menu>
            <Menu.Item
              key="delete"
              onClick={() => showDeleteConfirm(value, data.text)}
              style={{ color: 'red' }}
            >
              <DeleteOutlined style={{ color: 'red' }} />
              {t('DELETE')}
            </Menu.Item>
          </Menu>
        );

        return (
          <Button.Group size="small">
            <Authorized roles={[ROLE_UPDATE_COMMON]}>
              <Button icon={<EditOutlined />} type="primary" onClick={() => setModalVisible(data)} />
            </Authorized>
            <Authorized roles={[ROLE_DELETE_COMMON]}>
              <Dropdown overlay={moreActions}>
                <Button icon={<EllipsisOutlined />} type="default" />
              </Dropdown>
            </Authorized>
          </Button.Group>
        );
      }
    },
    {
      headerName: t('NO'),
      field: '_id',
      minWidth: 50,
      maxWidth: 50,
      cellRenderer: ({ rowIndex }) => rowIndex + 1
    },
    {
      headerName: t('NAME'),
      field: 'text',
      minWidth: 150,
      maxWidth: 150
    },
    {
      headerName: t('TYPE'),
      field: 'meta.type',
      minWidth: 110,
      maxWidth: 110,
      cellRenderer: ({ value }) => value && value.toUpperCase()
    },
    {
      headerName: t('DEVICE'),
      field: 'meta.devices',
      autoHeight: true,
      cellRendererFramework: ({ value }) =>
        value && (
          <Row align="middle" style={{ overflow: 'auto', height: 50 }}>
            {value.map((item, index) => (
              <Tag key={String(index)} style={{ flexWrap: 'wrap', margin: '4px 4px' }}>
                {item}
              </Tag>
            ))}
          </Row>
        )
    }
  ];

  return (
    <ReactResizeDetector handleHeight>
      {({ height }) => {
        return (
          <AgGridServer
            options={{
              columnDefs,
              cacheBlockSize: 50,
              paginationPageSize: 50,
              getRowHeight: () => 50
            }}
            onQuery={sdk.findManyCommon}
            queryKey="findManyCommon" 
            onCountQuery={sdk.countCommon}
            countQueryKey="countCommon"
            onGridReady={handleGridReady}
            where={where}
            height={height - 80}
          />
        );
      }}
    </ReactResizeDetector>
  );
});

export default DeviceGroupGrid;
