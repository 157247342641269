import { fail } from 'assert';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import LocationRPFilter from '~components/Filter/FilterRPLocation/Filter';

import { Report } from './Report';

const ReportIncomeLocationScreen: FC = observer(() => {
  return (
    <>
      <div style={{ padding: 10, backgroundColor: '#ffffff' }}>
        <LocationRPFilter exportExcel={false}/>
      </div>
      <Report />
    </>
  );
});
export default ReportIncomeLocationScreen;
