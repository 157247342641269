import i18next from 'i18next';
import { orderBy, throttle } from 'lodash';
import { action, observable, runInAction } from 'mobx';
import { actionAsync, task } from 'mobx-utils';

import { GridReadyEvent } from '@ag-grid-community/core';
import { FilterSelect, WhereArgs } from '@source/common';

import { ErrorNotify, SuccessNotify } from '~components/UI/Notification';
import { BasicUserFragment, PaymentFragment, PaymentInput, SortDirection } from '~graphql/_sdk';
import { getSdk } from '~graphql/sdk';

class GridStore {
  @observable public isModalVisible: boolean;
  @observable public isRejectDialogVisible: boolean;
  @observable public isPaymentInfoVisible: boolean;
  @observable public where: WhereArgs;
  @observable public selectedItem: PaymentFragment;
  @observable public initFilterConfig: FilterSelect[];
  @observable public fromUser: string | undefined | null;
  @observable public ownerType: string;
  @observable public userSelectOption: BasicUserFragment[];
  constructor() {
    runInAction(() => {
      this.selectedItem = null;
      this.isModalVisible = false;
      this.where = {};
      this.fromUser = null;
      this.ownerType = null;
      this.where = {};
      this.userSelectOption = [];
    });
  }

  @action setUserSelectOption = (users: BasicUserFragment[]) => {
    this.userSelectOption = orderBy(users, ['createdAt'], ['desc']);
  };

  @action
  public setIsRejectDialogVisible = (value: boolean, item = null) => {
    this.isRejectDialogVisible = value;
    if (item) {
      this.selectedItem = item;
    }

    if (!this.isRejectDialogVisible) {
      this.selectedItem = null;
    }
  };

  @action
  public setPaymentInfoVisible = (value: boolean, item = null) => {
    this.isPaymentInfoVisible = value;
    if (item) {
      this.selectedItem = item;
    }

    if (!this.isPaymentInfoVisible) {
      this.selectedItem = null;
    }
  };

  @action
  public setFilter = throttle(
    (where: WhereArgs) => {
      if (this.where._search) {
        runInAction(() => {
          this.where = { ...where, _search: this.where._search };
        });
      } else {
        runInAction(() => {
          this.where = where;
        });
      }
      this.reload();
    },
    300,
    { leading: false, trailing: true }
  );

  @action
  toggleModal = (item: PaymentFragment = null, fromUser?: string) => {
    this.selectedItem = item;
    if (fromUser) {
      this.fromUser = fromUser;
    } else {
      this.fromUser = null;
    }
    this.isModalVisible = !this.isModalVisible;
    if (!this.isModalVisible) {
      this.selectedItem = null;
    }
  };

  @actionAsync
  public rejectPayment = async (rejectReason: string) => {
    const sdk = await task(getSdk());
    const {
      rejectPayment: { error, data }
    } = await task(sdk.rejectPayment({ _id: this.selectedItem._id, rejectReason }));

    if (error) {
      ErrorNotify('Verify');
    }

    if (data) {
      SuccessNotify('Verify');
      this.reload();
    }
  };

  @actionAsync
  public verifyPayment = async (id: string) => {
    const sdk = await task(getSdk());
    const {
      verifyPayment: { error, data }
    } = await task(sdk.verifyPayment({ id }));

    if (error) {
      ErrorNotify('Verify');
    }

    if (data) {
      SuccessNotify('Verify');
      this.reload();
    }
  };

  @actionAsync
  public create = async (record: PaymentInput) => {
    const sdk = await task(getSdk());
    const {
      createPayment: { error }
    } = await task(sdk.createPayment({ record }));

    if (error) {
      ErrorNotify(error.code);
    } else {
      SuccessNotify(i18next.t('CREATE'));
      this.reload();
    }
  };

  @action
  public resetStore = () => {
    this.userSelectOption = [];
    this.selectedItem = null;
  };

  @action
  public handleGridReady = ({ api }: GridReadyEvent) => {
    this.reload = () => {
      api.onFilterChanged();
    };
  };

  @action
  public reload = () => undefined;

  @action
  setWhere = (where) => {
    this.where = { ...this.where, ...where };
    this.reload();
  };

  @action
  public resetFilter = () => {
    this.where = {};
  };
}
export default new GridStore();
