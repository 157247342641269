import { observer } from 'mobx-react';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactResizeDetector from 'react-resize-detector';

import { ColDef } from '@ag-grid-community/core';
import { CheckOutlined } from '@ant-design/icons';
import { LICENSE } from '@source/common';

import AgGridServer from '~components/UI/AgGrid/AgGridServer';
import { useStore } from '~context/react-context';
import { useGraphQLSDK } from '~graphql/GraphQLSDKProvider';
import { DATE_FORMAT, format } from '~utils/formatDate';

import CellRenderer from './CellRenderer';
import store from './store';

const Grid: FC = observer(() => {
  const { t } = useTranslation();
  const sdk = useGraphQLSDK();
  const {
    userStore: { currentUser }
  } = useStore();
  const { handleGridReady, whereFilter, initColDef, colDefs, setOwnerType } = store;
  const columnDefs: ColDef[] = [
    {
      minWidth: 100,
      maxWidth: 100,
      headerName: '',
      field: '_id',
      cellClass: 'text-center',
      cellRendererFramework: ({ value, data }) => <CellRenderer value={value} data={data} />
    },
    {
      headerName: t('NO'),
      cellClass: 'text-center',
      minWidth: 50,
      maxWidth: 50,
      sortable: false,
      cellRendererFramework: ({ rowIndex }) => {
        return rowIndex + 1;
      }
    },
    {
      headerName: t('NAME'),
      field: 'name',
      minWidth: 200
    },
    {
      headerName: t('PHONE'),
      field: 'phone',
      minWidth: 150
    },
    {
      headerName: t('ACTIVE'),
      field: 'active',
      minWidth: 80,
      maxWidth: 80,
      cellClass: 'text-center',
      hide: true
    },
    {
      headerName: t('VERIFIED'),
      field: 'emailVerified',
      minWidth: 80,
      maxWidth: 80,
      hide: true,
      cellClass: 'text-center'
    },
    {
      headerName: t('EMAIL'),
      field: 'email',
      minWidth: 250,
      maxWidth: 250,
      cellRendererFramework: ({ value, data }) => {
        return (
          <span>
            {data.emailVerified && (
              <CheckOutlined style={{ color: '#999966', paddingRight: '5px' }} />
            )}
            {value}
          </span>
        );
      }
    },
    {
      headerName: t('COMPANY'),
      field: 'extra.company',
      sortable: false,
      hide: true
    },
    {
      headerName: t('PHONE'),
      field: 'phone',
      hide: true
    },
    {
      headerName: t('PROFILE'),
      field: 'profile.display',
      minWidth: 200,
      maxWidth: 250,
      cellClass: 'text-left',
      sortable: false
    },
    {
      headerName: t('CREATOR'),
      field: 'creator.name',
      minWidth: 200,
      maxWidth: 250,
      sortable: false
    },
    {
      headerName: t('TIME_EXPIRED'),
      field: 'expiredDate',
      minWidth: 110,
      maxWidth: 110,
      sortable: true,
      cellRenderer: ({ value }) => {
        return value ? format(value, DATE_FORMAT) : '-';
      },
      hide: currentUser.ownerType !== LICENSE
    },
    {
      headerName: t('CREATED_AT'),
      field: 'createdAt',
      sort: 'desc',
      minWidth: 110,
      maxWidth: 110,
      cellRenderer: ({ value }) => format(value, DATE_FORMAT)
    }
  ];

  useEffect(() => {
    initColDef(columnDefs);
    setOwnerType('');
  }, []);

  return (
    <ReactResizeDetector handleHeight>
      {({ height }) => {
        return (
          <AgGridServer
            options={{ columnDefs: colDefs }}
            onQuery={sdk.fetchUserGrid}
            queryKey="findManyUser"
            onCountQuery={sdk.countUser}
            countQueryKey="countUser"
            onGridReady={handleGridReady}
            where={whereFilter}
            height={height - 80}
          />
        );
      }}
    </ReactResizeDetector>
  );
});

export default Grid;
