import './RedirectPageConfig.less';

import { Col, Collapse, Input, Row, Table } from 'antd';
import i18next from 'i18next';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CaretRightOutlined } from '@ant-design/icons';

import UIField from '~components/UI/UIField';

import store from '../store';

const { Panel } = Collapse;

const customPanelStyle = {
  borderRadius: 4,
  marginBottom: 24
};

const genColumns = (formProps: any) => [
  {
    title: '#',
    dataIndex: 'order',
    order: 'order'
  },
  {
    title: i18next.t('LOCATION'),
    dataIndex: 'location',
    key: 'location'
  },
  {
    title: i18next.t('REDIRECT_PAGE'),
    dataIndex: 'redirectPage',
    key: 'redirectPage',
    width: '60%',
    render: (_text, record) => (
      <Row>
        <Col span={12}>
          <UIField
            {...formProps}
            name={`${record.key}-trackingUrl`}
            label=""
            fieldProps={{
              placeholder: 'http://somedomain.com',
              addonBefore: 'Url'
            }}
            fieldComponent={Input}
            defaultValue={record.redirectPage}
          />
        </Col>

        <Col span={12}>
          <UIField
            {...formProps}
            name={`${record.key}-impressionUrl`}
            label=""
            fieldProps={{
              placeholder: 'impression',
              addonBefore: 'Impression'
            }}
            fieldComponent={Input}
            defaultValue={record.impression}
          />
        </Col>
      </Row>
    )
  }
];

interface RedirectPageConfigProps {
  formProps: any;
}

const RedirectPageConfig = observer((props: RedirectPageConfigProps) => {
  const { t } = useTranslation();
  const mappingTrackingUrl = (locations = [], trackingLocation = {}) => {
    if (locations.length > 0) {
      return locations.map((e, index) => {
        return {
          order: index + 1,
          key: e.locationId,
          location: e.locationName,
          redirectPage: get(trackingLocation, `[${e.locationId}].trackingUrl`, ''),
          impression: get(trackingLocation, `[${e.locationId}].impressionUrl`, '')
        };
      });
    }
    return [];
  };

  return (
    <div style={{ padding: '16px 24px 16px 0px' }}>
      <Collapse
        bordered={false}
        defaultActiveKey={['1', '2', '3']}
        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
      >
        <Panel header={t('MAIN_LANDING_PAGE')} key="1" style={customPanelStyle}>
          <UIField
            {...props.formProps}
            name="landingPageUrl"
            fieldProps={{
              placeholder: 'https://landingPageDomain.com'
            }}
            fieldComponent={Input}
            defaultValue={get(store.campaign, 'config.landingPageUrl')}
          />
        </Panel>
        <Panel header={t('MAIN_IMPRESSION')} key="2" style={customPanelStyle}>
          <UIField
            {...props.formProps}
            name="impressionUrl"
            fieldProps={{
              placeholder: 'https://impressionDomain.com'
            }}
            fieldComponent={Input}
            defaultValue={get(store.campaign, 'config.impressionUrl')}
          />
        </Panel>
        <Panel header={t('LOCATIONS_LANDING_PAGE')} key="3" style={customPanelStyle}>
          <Table
            pagination={false}
            columns={genColumns(props.formProps)}
            dataSource={mappingTrackingUrl(
              get(store.campaign, 'locations', []),
              get(store.campaign, 'config.trackingLocation', {})
            )}
            rowClassName={() => 'custom-table-row'}
          />
        </Panel>
      </Collapse>
    </div>
  );
});

export default RedirectPageConfig;
