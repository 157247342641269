import { observer } from 'mobx-react';
import React, { FC, useEffect } from 'react';

import useQuery from '~utils/useQuery';

import CampaignList from './campaignList/CampaignList';
import Report from './reportEachCampaign/Report';
import store from './store';

const ReportCampaignsScreen: FC = observer(() => {
  const { reset, campaignId } = store;
  const id = useQuery().get('id');

  useEffect(() => {
    return () => reset();
  }, []);

  return !campaignId && !id ? <CampaignList /> : <Report />;
});
export default ReportCampaignsScreen;
