import { Col, Row } from 'antd';
import { observer } from 'mobx-react';
import React, { FC, useEffect } from 'react';

import DeviceForm from './Form';
import EquipmentGrid from './Grid';
import EquipmentHeader from './Header';
import store from './store';

const EquipmentScreen: FC = observer(() => {
  useEffect(() => {
    return () => store.resetFilter();
  }, []);
  return (
    <Row style={{ height: '100%', flexFlow: 'column nowrap' }}>
      <Col flex="0 0 auto"><EquipmentHeader /></Col>
      <Col flex="1 1 auto"><EquipmentGrid /></Col>
      <DeviceForm />
    </Row>
  );
});

export default EquipmentScreen;
