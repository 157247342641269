import { Button, Card, Col, Input, InputNumber, Row, Select, Switch } from 'antd';
import { SelectProps } from 'antd/lib/select';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PlusOutlined } from '@ant-design/icons';

import UIField from '~components/UI/UIField';

import store from '../store';
import ParamConfigElement from './ParamsConfigElement';

const { Option } = Select;

interface WebhookConfigProps {
  formProps: any;
}

interface ParamsConfig {
  name: string;
  value: string;
}

const methodUri = [
  {
    key: 'get',
    value: 'get',
    children: 'GET'
  },
  {
    key: 'post',
    value: 'post',
    children: 'POST'
  }
];

const WebhookApiConfig = observer((props: WebhookConfigProps) => {
  const { formProps } = props;
  const {
    campaign,
    removeWebhookParams,
    webhookEnable,
    setWebhookEnable,
    addWebhookParam,
    updateParam
  } = store;
  const { t } = useTranslation();

  const [method, setMethod] = useState(get(campaign, 'config.webhook.method', 'get'));
  const handleSelectOnChange = (e) => {
    setMethod(e);
  };

  const handleAddParams = () => {
    const param: ParamsConfig = {
      name: null,
      value: null
    };
    addWebhookParam(param);
  };

  return (
    <Card
      title={t('URL WEBHOOK API')}
      extra={
        <Switch
          checkedChildren={t('ENABLED')}
          unCheckedChildren={t('DISABLED')}
          checked={webhookEnable}
          onChange={setWebhookEnable}
        />
      }
    >
      <Row gutter={16}>
        <Col span={12}>
          <h3>{t('API_URL')}</h3>
          <UIField
            {...formProps}
            name="webhook.api_url"
            fieldProps={{
              placeholder: 'https://somedomain.com/api/webhook/tracking'
            }}
            fieldComponent={Input}
            defaultValue={get(campaign, 'config.webhook.api_url', '')}
          />
        </Col>
        <Col span={12}>
          <h3>{t('METHOD')}</h3>
          <UIField<SelectProps<any>>
            {...formProps}
            name="webhook.method"
            fieldProps={{
              style: { width: '100%' },
              onChange: handleSelectOnChange,
              children: methodUri.map(({ key, ...prop }) => <Option key={key} {...prop} />)
            }}
            fieldComponent={Select}
            defaultValue={method}
          />
        </Col>
        <Col span={12}>
          <h3>{t('TOKEN')}</h3>
          <UIField
            {...formProps}
            name="webhook.token"
            fieldProps={{
              placeholder: 'Token'
            }}
            fieldComponent={Input}
            defaultValue={get(campaign, 'config.webhook.token', '')}
          />
        </Col>
        <Col span={12}>
          <h3>{t('TIMEOUT')}</h3>
          <UIField
            {...formProps}
            name="webhook.timeout"
            fieldProps={{
              placeholder: 'Time out of Request (mls)'
            }}
            fieldComponent={InputNumber}
            defaultValue={get(campaign, 'config.webhook.timeout', null)}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24} style={{ marginBlock: 8 }}>
          <h3>{t('PARAMS')}</h3>
          <Button onClick={handleAddParams}>
            <PlusOutlined /> {t('ADD_PARAM')}
          </Button>
        </Col>
        {get(campaign, 'config.webhook.params', []).map((item: any, index: number) => {
          return (
            <Col span={12} key={`col-${index + 1}`}>
              {ParamConfigElement({
                param: item,
                index,
                removeIndex: removeWebhookParams,
                updateParam
              })}
            </Col>
          );
        })}
      </Row>
    </Card>
  );
});

export default WebhookApiConfig;
