import { Result } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import StackTrace, { StackFrame } from 'stacktrace-js';

import userStore from '~common-stores/userStore';
import { CreateErrorLogMutationVariables } from '~graphql/_sdk';
import { getSdk } from '~graphql/sdk';

interface State {
  hasError: boolean;
}

@observer
class ErrorBoundary extends Component<any, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      hasError: false
    };
  }

  public componentDidCatch(error: Error) {
    this.setState({
      hasError: true
    });

    const callback = (stackframes: StackFrame[]) => {
      const stringifiedStack = stackframes.map((sf) => sf.toString()).join('\n');
      this.handleErrorLogging(stringifiedStack);
    };

    const errback = (err) => {
      console.info(err.message);
    };

    StackTrace.fromError(error).then(callback).catch(errback);
  }
  public handleErrorLogging = async (errorLog: string) => {
    const errorLogInput: CreateErrorLogMutationVariables = {
      record: {
        userId: userStore.currentUser._id.toString(),
        error: errorLog
      }
    };
    const sdk = await getSdk();
    await sdk.createErrorLog(errorLogInput);
  };

  public render() {
    const { hasError } = this.state;
    const { children } = this.props;
    return hasError ? (
      <Result status="500" title="Error !" subTitle="Sorry, something went wrong." />
    ) : (
      children
    );
  }
}

export default ErrorBoundary;
