import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IStatusPanelComp, IStatusPanelParams } from '@ag-grid-community/core';

import { formatNumber } from '~utils/formatNumber';

const CountStatusBar = forwardRef<IStatusPanelComp, IStatusPanelParams>((props, ref) => {
  const { t } = useTranslation();
  const [count, setCount] = useState(0);

  const handleRowCount = () => {
    setCount(props.api.getModel().getRowCount());
  };

  props.api.addEventListener('gridReady', handleRowCount);
  props.api.addEventListener('modelUpdated', handleRowCount);

  useImperativeHandle(ref, () => ({
    getGui: () => null,
    destroy: () => {
      props.api.removeEventListener('gridReady', handleRowCount);
      props.api.removeEventListener('modelUpdated', handleRowCount);
    }
  }));

  return (
    <span style={{ padding: 15 }}>
      {t('common.TOTAL')}: {formatNumber(count)}
    </span>
  );
});

export default CountStatusBar;
