import { Input, Result } from 'antd';
import { isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import { SmileTwoTone } from '@ant-design/icons';

import useQuery from '~utils/useQuery';

import store from './store';

const { Password } = Input;

const Invite: React.FC = observer(() => {
  const {t} = useTranslation();
  const { verifyEmail, isVerifiedEmail } = store;
  const query = useQuery();

  const key = query.get('key');
  const email = query.get('email');
  const { id } = useParams();

  useEffect(() => {
    verifyEmail(id, email, key);
  }, [email, key, verifyEmail]);
  const Layout = () => {
    return (
      <>
        <p>{t('ACCOUNT')} : {email}</p>
        {t('PASSWORD')} : <Password style={{ width: '195px' }} size="small" value="123456" />
        <Link to="/login" style={{ display: 'block' }}>
          {t('GO_BACK_TO_HOMEPAGE')}
        </Link>
      </>
    );
  };
  return (
    <div style={{ margin: 'auto', textAlign: 'center' }}>
      {!isEmpty(isVerifiedEmail) ? (
        <Result status="success" title={t('YOUR_ACCOUNT_ACTIVATED_SUCCESSFULLY')} extra={<Layout />} />
      ) : (
        <Result
          icon={<SmileTwoTone />}
          title={t('ACTIVATION_FAILED')}
          // eslint-disable-next-line react/jsx-key
          extra={[<Link to="/login">{t('GO_BACK_TO_HOMEPAGE')}</Link>]}
        />
      )}
    </div>
  );
});

export default Invite;
