import { Radio } from 'antd';
import React, { FC, useEffect } from 'react';

import { LICENSE, MGN, ROLE_READ_ALL } from '@source/common';

import Authorized from '~components/Authorized/Authorized';
import { useStore } from '~context/react-context';

interface OwnerTypeRadioProps {
  setOwnerFunc: Function;
  initialOwnerType?: string;
}

const OwnerTypeRadio: FC<OwnerTypeRadioProps> = (props) => {
  const { setOwnerFunc, initialOwnerType = '' } = props;

  const {
    userStore: { currentUser }
  } = useStore();

  const handleOnChange = (value) => {
    setOwnerFunc(value);
  };

  const returnDefaultValue = () => {
    if (initialOwnerType) {
      return initialOwnerType;
    }
    return currentUser.ownerType === LICENSE ? LICENSE : '';
  };

  return (
    <Radio.Group
      onChange={(e) => handleOnChange(e.target.value)}
      defaultValue={currentUser.ownerType === LICENSE ? LICENSE : ''}
      style={{ display: currentUser.ownerType === LICENSE ? 'none' : 'inline-block' }}
      // style={{ display: 'none' }}
    >
      <Radio.Button value="">All</Radio.Button>
      <Radio.Button value={MGN}>MegaNet</Radio.Button>
      <Radio.Button value={LICENSE}>MegaPlus</Radio.Button>
    </Radio.Group>
  );
};

export default OwnerTypeRadio;
