import { Button, Modal } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import SignupStore from './store';

const UserModal: React.FC = observer(() => {
  const { t } = useTranslation();
  const { isRegisterConditionModalVisible, toggleRegisterConditionModal } = SignupStore;

  const footer = (
    <Button
      type="default"
      style={{ background: '#858796', color: 'white' }}
      onClick={() => toggleRegisterConditionModal()}
    >
      {t('CLOSE')}
    </Button>
  );

  return (
    <Modal
      title={t('REGISTER_CONDITION')}
      width="45%"
      visible={isRegisterConditionModalVisible}
      footer={footer}
    >
      <div style={{ textAlign: 'justify', height: '500px', overflow: 'scroll', padding: '8px' }}>
        <b style={{ textAlign: 'center', display: 'block', fontSize: '20px' }}>
          ĐIỀU KHOẢN SỬ DỤNG PHẦN MỀM WIFI MARKETING MEGANET
        </b>
        <br />
        <p>
          Phần mềm Wifi Marketing MegaNet là Phần mềm được cung cấp bởi CTCP Công nghệ Nguồn mở Thế
          Hệ Mới (MegaNet), cho phép Bạn thực hiện việc đăng tải và quản trị các chiến dịch quảng
          cáo, theo dõi, tổng hợp và phân tích số liệu báo cáo.
        </p>
        <p>
          Bằng việc sử dụng Gói Phần mềm (Gói Sử dụng) của chúng tôi, Bạn đang đồng ý với các điều
          khoản này. Vui lòng đọc kỹ các điều khoản.
        </p>
        <b style={{ fontSize: '18px' }}>ĐIỀU 1. SỬ DỤNG PHẦN MỀM CỦA CHÚNG TÔI</b>
        <ol>
          <li>Bạn sẽ được cấp tài khoản (account) tương ứng với Gói sử dụng Bạn đăng ký.</li>
          <li>Bạn phải tuân thủ mọi chính sách đã cung cấp cho Bạn trong phạm vi Gói Sử dụng.</li>
          <li>
            Để bảo vệ tài khoản của Bạn, hãy giữ bí mật mật khẩu của Bạn. Khi tiếp nhận bàn giao tài
            khoản truy cập từ Chúng tôi, Bạn có trách nhiệm thiết lập hoặc thay đổi mật khẩu trong
            lần đầu tiên sử dụng hệ thống và tự chịu trách nhiệm trong việc quản lý tất cả các tài
            khoản của mình.{' '}
          </li>
          <li>
            Bạn không được sử dụng trái phép Phần mềm Wifi Marketing MegaNet. Ví dụ, Bạn không được
            phép thực hiện hoặc cho phép người khác thực hiện việc sửa đổi, tháo rời, dịch ngược,
            tái xuất bản bản hoặc sao chép bất kỳ phần nào của Phần mềm (bao gồm cả cấu trúc dữ liệu
            hoặc các tài liệu tương tự được sinh ra bởi các chương trình; không cấp phép, bán,
            chuyển nhượng, giao, phân phối, thuê ngoài, cho phép chia sẻ thời gian hoặc sử dụng theo
            kiểu cung cấp dịch vụ, khai thác thương mại hoặc cung cấp Phần mềm cho bất kỳ bên thứ ba
            nào mà không được sự cho phép của Chúng tôi.{' '}
          </li>
          <li>
            Chúng tôi có thể tạm ngừng hoặc ngừng cung cấp Gói Sử dụng của chúng tôi cho Bạn nếu Bạn
            không tuân thủ các điều khoản/chính sách của chúng tôi hoặc nếu chúng tôi đang điều tra
            hành vi bị nghi ngờ là sai phạm của Bạn hoặc có bất kỳ khoản thanh toán nào quá hạn.
          </li>
          <li>
            Bạn có thể ngừng sử dụng Gói Phần mềm của chúng tôi bất kỳ lúc nào, mặc dù Chúng tôi rất
            tiếc khi Bạn không còn sử dụng Phần mềm của chúng tôi nữa. Chúng tôi cũng có thể ngừng
            cung cấp cho Bạn hoặc thêm/tạo ra những giới hạn mới cho Chúng tôi vào bất kỳ lúc nào.
          </li>
          <li>
            Việc Bạn sử dụng Phần mềm của chúng tôi không có nghĩa là Bạn được sở hữu bất cứ các
            quyền sở hữu trí tuệ nào đối với Phần mềm của chúng tôi hoặc nội dung khác mà Bạn có thể
            truy cập từ Phần mềm.
          </li>
          <li>
            Lưu ý đối với việc dùng thử Gói Phần mềm: Chúng tôi không chịu trách nhiệm về bất cứ
            quyền lợi nào của Bạn liên quan tới việc dùng thử. Tuy nhiên các Điều khoản sử dụng này
            vẫn được áp dụng cho Bạn.
          </li>
        </ol>
        <b style={{ fontSize: '18px' }}>ĐIỀU 2. NỘI DUNG CỦA BẠN</b>
        <ol>
          <li>
            “Nội dung của Bạn”: Có nghĩa là tất cả các dữ liệu, văn bản, hình ảnh, âm thanh, video,
            các nội dung và tài liệu khác dưới bất kỳ hình thức nào do Bạn cung cấp, được lưu trữ
            trong hoặc chạy trên hoặc thông qua Phần mềm thông qua việc sử dụng Gói Phần mềm.
          </li>
          <li>
            Bạn cấp cho chúng tôi quyền lưu trữ, sử dụng (cho mục đích thống kê, hỗ trợ), xử lý,
            hiển thị và truyền tải Nội dung của Bạn để thực hiện các chức năng của Phần mềm.
          </li>
          <li>
            Chúng tôi có thể (i) biên soạn thông tin thống kê và các thông tin khác liên quan đến
            việc thực hiện, vận hành và sử dụng Phần mềm và (ii) sử dụng dữ liệu từ Phần mềm dưới
            dạng tổng hợp để quản lý an ninh và vận hành, nhằm tạo ra các phân tích thống kê và để
            phục vụ các mục đích nghiên cứu và phát triển.
          </li>
        </ol>
        <b style={{ fontSize: '18px' }}>ĐIỀU 3. BẢO ĐẢM VÀ TUYÊN BỐ PHỦ NHẬN</b>
        <ol>
          <li>
            Chúng tôi sẽ cung cấp Phần mềm với các tính năng tương ứng với các Gói sử dụng theo thỏa
            thuận tại Đặt hàng của Bạn.
          </li>
          <li>
            Nếu Phần mềm được cung cấp cho Bạn không được thực hiện như đã được bảo đảm, Bạn phải
            nhanh chóng cung cấp cho Chúng tôi thông báo bằng văn bản và phối hợp cùng chúng tôi
            khắc phục, giải quyết thiếu sót để hoàn thiện việc cung cấp Phần mềm. Chúng tôi hy vọng
            Bạn có được trải nghiệm tốt nhất khi sử dụng Phần mềm của chúng tôi. Tuy nhiên, Chúng
            tôi không đưa ra hứa hẹn về sự hoạt động hoàn hảo của các tính năng hoặc khả năng đáp
            ứng tất cả các mong đợi của Bạn đối với Phần mềm.
          </li>
        </ol>
        <b style={{ fontSize: '18px' }}>ĐIỀU 4. SỬA ĐỔI ĐIỀU KHOẢN</b>
        <ol>
          <li>
            Phần mềm của chúng tôi đa dạng tính năng và có tính đặc thù về mặt viễn thông nên đôi
            khi cần thiết áp dụng các điều khoản bổ sung hoặc các yêu cầu bổ sung liên quan sản phẩm
            được phép đăng tải trên Phần mềm. Các điều khoản bổ sung sẽ được cung cấp kèm theo Gói
            sử dụng Phần mềm liên quan và các điều khoản bổ sung đó sẽ trở thành một phần thỏa thuận
            của Bạn và chúng tôi nếu Bạn sử dụng các Gói Sử dụng đó.
          </li>
          <li>
            Bạn nên thường xuyên tìm hiểu các điều khoản này. Chúng tôi sẽ cập nhập sự thay đổi điều
            khoản này trên website của Chúng tôi meganet.com.vn. Các thay đổi sẽ không áp dụng để
            giải thích, giải quyết những vấn đề xảy ra trước khi có hiệu lực và không sớm hơn 15
            ngày kể từ khi các thay đổi đó được đăng. Tuy nhiên, những thay đổi liên quan đến chức
            năng của Phần mềm hoặc các thay đổi được thực hiện vì lý do pháp lý sẽ có hiệu lực ngay
            lập tức. Trong trường hợp có mâu thuẫn giữa các sửa đổi với các điều khoản cũ; các sửa
            đổi, bổ sung được ưu tiên áp dụng. Việc Bạn tiếp tục sử dụng Phần mềm sau khi các sửa
            đổi, bổ sung có hiệu lực đồng nghĩa với việc Bạn chấp nhận những thay đổi của chúng tôi.
          </li>
        </ol>
        <b style={{ fontSize: '18px' }}>ĐIỀU 5. ĐIỀU KHOẢN KHÁC</b>
        <ol>
          <li>
            Trong phạm vi cho phép tối đa của pháp luật, Bạn đồng ý bảo vệ, bồi thường và giữ vô hại
            cho Chúng tôi khỏi bất kỳ và tất cả các khiếu nại, hành vi, khởi kiện hoặc thủ tục tố
            tụng, cũng như bất kỳ và tất cả các thiệt hại, trách nhiệm và các chi phí (bao gồm cả
            phí luật sư) phát sinh từ hoặc liên quan đến Nội dung của Bạn hoặc việc Bạn sử dụng Phần
            mềm vi phạm các quy định của Pháp luật và/hoặc vi phạm Điều khoản sử dụng.
          </li>
          <li>
            Nếu có bất kỳ mâu thuẫn nào trong quá trình sử dụng dịch vụ, Bạn được khuyến khích liên
            lạc và hợp tác với bộ phận hỗ trợ của Chúng tôi để giải quyết.
          </li>
          <li>
            Mọi tranh chấp phát sinh trong quá trình thực hiện phải được giải quyết trước hết trên
            cơ sở thương lượng giữa Các Bên. Trong mọi trường hợp tranh chấp không giải quyết được
            thông qua thương lượng thì Tòa án nhân dân Hồ Chí Minh sẽ được lựa chọn làm nơi giải
            quyết tranh chấp.
          </li>
        </ol>
      </div>
    </Modal>
  );
});

export default UserModal;
