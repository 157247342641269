import { Badge, Tag } from 'antd';
import { BadgeProps } from 'antd/lib/badge';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ReactResizeDetector from 'react-resize-detector';

import { ColDef } from '@ag-grid-community/core';

import AgGridClient from '~components/UI/AgGrid/AgGridClient';
import Loading from '~components/UI/Loading';
import { DATE_FORMAT, format } from '~utils/formatDate';
import { formatNumber } from '~utils/formatNumber';

import store from './store';

const Grid: FC = observer(() => {
  const { campaignMapping, loading, handleGridReady, onRowDrag } = store;
  const { t } = useTranslation();

  const columnDefs: ColDef[] = [
    {
      headerName: '',
      field: 'status',
      minWidth: 120,
      maxWidth: 120,
      cellClass: 'text-center',
      cellRendererFramework: ({ value }) => {
        let colorBadge = 'default';
        let colorTag = 'gray';
        switch (value) {
          case 'scheduled':
            colorBadge = 'warning';
            colorTag = 'orange';
            break;
          case 'running':
            colorBadge = 'success';
            colorTag = 'green';
            break;
          case 'finish':
            colorBadge = 'processing';
            colorTag = 'blue';
            break;
          default:
            colorBadge = 'default';
            break;
        }
        return (
          <div style={{ textAlign: 'center' }}>
            <Badge status={colorBadge as BadgeProps['status']} />{' '}
            <Tag color={colorTag}>{value}</Tag>
          </div>
        );
      }
    },
    {
      headerName: t('CAMPAIGN'),
      field: 'campaign.name',
      minWidth: 250,
      rowDrag: true
    },
    {
      headerName: t('SCHEDULE_FROM'),
      field: 'scheduleFrom',
      minWidth: 120,
      maxWidth: 120,
      cellClass: 'text-right',
      cellRenderer: ({ value }) => format(value, DATE_FORMAT)
    },
    {
      headerName: t('SCHEDULE_TO'),
      field: 'scheduleTo',
      minWidth: 120,
      maxWidth: 120,
      cellClass: 'text-right',
      cellRenderer: ({ value }) => (value ? format(value, DATE_FORMAT) : '-')
    },
    {
      headerName: t('TIMES'),
      field: 'times',
      minWidth: 120,
      maxWidth: 120,
      cellClass: 'text-left',
    },
    {
      headerName: t('PRIORITY'),
      field: 'priority',
      minWidth: 100,
      maxWidth: 100,
      hide: true
    },
    {
      headerName: t('KPI_BOOKING'),
      field: 'stats.kpi',
      cellClass: 'text-right',
      minWidth: 120,
      maxWidth: 120,
      cellRenderer: ({ value }) => `${value ? formatNumber(value) : 'Unlimited'}`
    },
    {
      headerName: t('TOTAL_CLICK'),
      field: 'stats.click',
      minWidth: 120,
      maxWidth: 120,
      cellClass: 'text-right',
      cellRenderer: ({ value }) => {
        return formatNumber(value);
      }
    },
    {
      headerName: t('TODAY'),
      field: 'todayStats',
      minWidth: 120,
      maxWidth: 140,
      cellClass: 'text-right',
      cellRenderer: ({ value }) => {
        return `${formatNumber(value.click || 0)} / ${
          value.kpi ? formatNumber(value.kpi) : 'Unlimited'
        }`;
      }
    },
    {
      headerName: t('KPI_COMPLETE'),
      field: 'stats.kpiCompleted',
      minWidth: 100,
      maxWidth: 100,
      cellClass: 'text-right',
      cellRenderer: ({ value }) => `${formatNumber(value || 0)} %`
    }
  ];

  return loading ? (
    <Loading />
  ) : (
    <>
      <ReactResizeDetector handleHeight>
        {({ height }) => {
          const gridHeight = height ? height - 74 : 500;
          return (
            <AgGridClient
              columnDefs={columnDefs}
              height={gridHeight}
              rowDragManaged
              suppressRowDrag={false}
              animateRows
              onRowDragEnd={onRowDrag}
              rowData={campaignMapping}
              onGridReady={handleGridReady}
            />
          );
        }}
      </ReactResizeDetector>
    </>
  );
});

export default Grid;
