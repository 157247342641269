import { createContext, useContext } from 'react';

import {
  authStore,
  commonDataStore,
  commonStore,
  filterStore,
  layoutStore,
  userStore
} from '~common-stores/store-index';

export const storeContext = createContext({
  commonStore,
  authStore,
  layoutStore,
  commonDataStore,
  userStore,
  filterStore
});

export const useStore = () => useContext(storeContext);
