import { Spin } from 'antd';
import React, { FC } from 'react';

import { LoadingOutlined } from '@ant-design/icons';

import { flexCenter } from '~assets/css-classes';

interface Props {
  size?: number;
  title?: string;
}

const Loading: FC<Props> = (props) => {
  const { size, title } = props;

  const customSize = size || 24;
  const loadingIcon = <LoadingOutlined style={{ fontSize: customSize }} spin />;
  return (
    <div className={flexCenter} style={{ textAlign: 'center', padding: 8, height: '100%' }}>
      <Spin tip={title || 'Loading...'} indicator={loadingIcon} />
    </div>
  );
};

export default Loading;
