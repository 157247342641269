import { Col, Row, Spin } from 'antd';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import HighChart from '~components/UI/HighChart/HighChart';
import { format, RANGE_DAY_FORMAT } from '~utils/formatDate';

import store from './store';

interface Chart {
  heightChart: number;
  widthChart: number;
}

const Chart: FC<Chart> = observer(props => {
  const { heightChart, widthChart } = props;
  const { t } = useTranslation();
  const { income, loading } = store;

  const getItem = (key: any, isDate = false) => {
    const dataChart = income.time;
    const realData = dataChart?.reduce((all, item) => {
      all = [...all, isDate ? format(get(item, key, []), RANGE_DAY_FORMAT) : get(item, key, [])];
      return all;
    }, []);
    return realData || null;
  };

  const options: Highcharts.Options = {
    title: {
      text: ''
    },
    plotOptions: {
      series: {
        label: {
          enabled: false
        }
      }
    },
    series: [
      {
        name: t('INCOME_PARTNER'),
        color: 'green',
        type: 'area',
        data: getItem('incomePartner') ?? [],
        boostThreshold: 1
      },
      {
        name: t('INCOME'),
        color: '#FFA242',
        type: 'area',
        data: getItem('income') ?? [],
        boostThreshold: 1
      }
    ],
    xAxis: {
      categories: getItem('date', true),
      labels: {
        rotation: -45
      },
      title: {
        text: null
      },
      tickInterval: 5
    },
    yAxis: [
      {
        min: 0,
        title: {
          text: t('INCOME'),
          align: 'middle'
        },
        labels: {
          overflow: 'justify'
        }
      }
    ],
    chart: {
      events: {
        redraw: e => {
          e.preventDefault();
        }
      },
      height: heightChart,
      width: widthChart
    },
    boost: { enabled: true },
    tooltip: {
      shared: true
    },
    credits: {
      enabled: false
    }
  };
  return (
    <Row>
      <Col span={24}>
        <Spin style={{ width: widthChart }} spinning={loading} tip="Loading...">
          <HighChart options={options} />
        </Spin>
      </Col>
    </Row>
  );
});

export default Chart;
