import { Col, Row } from 'antd';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { ACTIVE, COMING_SOON, DISABLED, LICENSE, MGN } from '@source/common';

import { locationsTemplate } from '~components/UI/Select/SelectTemplates';
import { UISelect } from '~components/UI/Select/UISelect';
import { UIButtonExportExcel } from '~components/UI/UIButtonExportExcel';
import { UIDateRangePicker } from '~components/UI/UIDateRangePicker';
import { useStore } from '~context/react-context';
import { stringifyQuery } from '~utils/query-string';
import useQuery from '~utils/useQuery';

import store from './store';

const colSpan = {
  sm: 24,
  xs: 24,
  flex: 'auto'
};

const statusOptions = [
  { text: 'Active', value: ACTIVE },
  { text: 'Disabled', value: DISABLED },
  { text: 'Coming soon', value: COMING_SOON }
];

const Filter: FC = observer(() => {
  const {
    getFilter,
    onFilterChange,
    categoryOptions,
    cityOptions,
    districtOptions,
    locationOptions,
    locations
  } = store;

  const {
    authStore,
    userStore: { currentUser }
  } = useStore();
  const { t } = useTranslation();
  const history = useHistory();

  const locationId = useQuery().get('_id');
  let currentLocation = [];

  if (locationId) {
    try {
      currentLocation = JSON.parse(locationId);
    } catch (err) {
      console.log(err)
    }
  }

  const onLocationChange = (field, locs) => {
    const query = stringifyQuery({ [field]: locs });
    history.push({ search: query });
    onFilterChange(field, locs);
  };

  return (
    <Row gutter={8}>
      <Col sm={24} md={6}>
        <UIDateRangePicker
          size="middle"
          allowClear={false}
          style={{ width: '100%', fontSize: 13 }}
          value={[getFilter.date?.gte, getFilter.date?.lte]}
          onChange={v => {
            onFilterChange('date', v);
          }}
        />
      </Col>
      {categoryOptions.length > 1 ? (
        <Col {...colSpan}>
          <UISelect
            size="middle"
            mode="multiple"
            dropdownStyle={{ minWidth: 220 }}
            dataOptions={{
              data: categoryOptions,
              valueField: 'value',
              textField: 'text'
            }}
            placeholder={t('CHOOSE_CATEGORY')}
            style={{ width: '100%', fontSize: 13 }}
            onChange={v => {
              onFilterChange('categoryId', v);
            }}
            value={getFilter.categoryId}
            allowClear
            showArrow
          />
        </Col>
      ) : (
        <></>
      )}
      <Col {...colSpan}>
        <UISelect
          dropdownStyle={{ minWidth: 220 }}
          size="middle"
          mode="multiple"
          placeholder={t('CHOOSE_CITY')}
          key="selectCity"
          onChange={v => {
            onFilterChange('city', v);
          }}
          dataOptions={{
            data: cityOptions,
            valueField: 'value',
            textField: 'text'
          }}
          value={getFilter.city}
          style={{ width: '100%', fontSize: 13 }}
          allowClear
          showArrow
        />
      </Col>
      <Col {...colSpan}>
        <UISelect
          dropdownStyle={{ minWidth: 220 }}
          size="middle"
          dataOptions={{
            data: districtOptions,
            valueField: 'value',
            textField: 'text'
          }}
          onChange={v => {
            onFilterChange('district', v);
          }}
          disabled={!districtOptions?.length}
          mode="multiple"
          placeholder={t('CHOOSE_DISTRICT')}
          style={{ width: '100%', fontSize: 13 }}
          value={getFilter.district}
          allowClear
          showArrow
        />
      </Col>
      <Col {...colSpan}>
        <UISelect
          dropdownStyle={{ maxWidth: '150px' }}
          size="middle"
          mode="multiple"
          onChange={v => {
            onFilterChange('status', v);
          }}
          dataOptions={{
            data: statusOptions,
            valueField: 'value',
            textField: 'text'
          }}
          placeholder={t('CHOOSE_STATUS')}
          style={{ width: '100%', fontSize: 13 }}
          value={getFilter.status}
          allowClear
          showArrow
        />
      </Col>
      <Col
        {...colSpan}
        style={{ display: currentUser.ownerType === LICENSE ? 'none' : 'inline-block' }}
      >
        <UISelect
          dropdownStyle={{ minWidth: 150 }}
          size="middle"
          mode="multiple"
          placeholder="Owner Type"
          onChange={v => {
            onFilterChange('ownerType', v);
          }}
          dataOptions={{
            data: [
              { value: '', text: 'All' },
              { value: MGN, text: 'MegaNet' },
              { value: LICENSE, text: 'MegaPlus' }
            ],
            valueField: 'value',
            textField: 'text'
          }}
          style={{ width: '100%', fontSize: 13 }}
          allowClear
          showArrow
          // defaultValue={currentLocation.length === 1 ? currentLocation[0] : null}
        />
      </Col>
      <Col {...colSpan}>
        <UISelect
          dropdownStyle={{ minWidth: 220 }}
          size="middle"
          mode="multiple"
          placeholder={t('CHOOSE_LOCATION')}
          onChange={v => {
            onLocationChange('_id', v);
          }}
          dataOptions={{
            data: locationOptions,
            valueField: 'value',
            textField: 'text'
            // template: locationsTemplate
          }}
          value={getFilter._id}
          style={{ width: '100%', fontSize: 13 }}
          allowClear
          showArrow
          // defaultValue={currentLocation.length === 1 ? currentLocation[0] : null}
        />
      </Col>
      <Col>
        <UIButtonExportExcel
          shape="circle"
          type="primary"
          token={authStore.token}
          key="exportExcel"
          name={null}
          model="report/location"
          filter={getFilter}
        />
      </Col>
    </Row>
  );
});

export default Filter;
