import './css.less';

import { Divider } from 'antd';
import { observer } from 'mobx-react';
import React, { FC, useEffect } from 'react';

import Filter from './Filter';
// import { Header } from './Header';
import { Report } from './Report';
import store from './store';

const ReportIncomeCampaignScreen: FC = observer(() => {
  const { cleanUp } = store;
  useEffect(() => {
    return () => cleanUp();
  }, []);

  return (
    <>
      {/* <Header /> */}
      <Filter />
      <Report />
    </>
  );
});

export default ReportIncomeCampaignScreen;
