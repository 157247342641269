import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { SelectValue } from 'antd/lib/select';
import {
  differenceWith,
  intersectionWith,
  isEmpty,
  isEqual,
  merge,
  mergeWith,
  remove
} from 'lodash';
import { action, computed, observable, runInAction } from 'mobx';

import { WhereArgs } from '@source/common';

interface SelectedOption {
  [key: string]: any;
}

const customizer = (objValue, srcValue) => {
  if (objValue === srcValue) return;
  if (Array.isArray(objValue)) {
    return objValue.concat(srcValue);
  }
  return [objValue, srcValue].filter(Boolean);
};

export class FilterStore {
  @observable public singleSelectFilter: SelectedOption;
  @observable.shallow public multipleSelectFilter: SelectedOption[];
  @observable public dateRangeFilter: WhereArgs;
  @observable public initFilter: WhereArgs;
  @observable public ORFilter: WhereArgs[];
  @observable public ANDFilter: WhereArgs[];

  constructor() {
    runInAction(() => {
      this.singleSelectFilter = {};
      this.multipleSelectFilter = [];
      this.dateRangeFilter = {};
      this.ORFilter = [];
      this.ANDFilter = [];
    });
  }

  @computed public get selectFilter(): WhereArgs {
    const object = this.multipleSelectFilter.slice().reduce((all, item) => {
      return mergeWith(all, item, customizer);
    }, {});
    const _operators = Object.keys(object).reduce((all, key) => {
      all[key] = { in: object[key] };
      return all;
    }, {});

    return { ...this.singleSelectFilter, _operators };
  }

  @computed public get logicFilter(): WhereArgs {
    return { OR: this.ORFilter };
  }

  @computed public get whereFilter(): WhereArgs {
    const where = merge({}, this.dateRangeFilter, this.selectFilter, this.logicFilter);
    if (isEmpty(where._operators)) {
      delete where._operators;
    }
    if (isEmpty(where.OR)) {
      delete where.OR;
    }
    if (isEmpty(where.AND)) {
      delete where.AND;
    }
    return where;
  }

  @action
  public init = (filter: WhereArgs) => {
    Object.keys(filter).forEach((key) => {
      const _operators = filter[key];
      switch (key) {
        case '_operators':
          Object.keys(_operators).forEach((innerKey) => {
            if (!isEmpty(_operators[innerKey].in)) {
              _operators[innerKey].in.forEach((item) => {
                this.multipleSelectFilter.push({ [`${innerKey}`]: item });
              });
            } else {
              merge(this.dateRangeFilter, {
                _operators: { [`${innerKey}`]: _operators[innerKey] }
              });
            }
          });
          break;
        case 'OR':
          this.ORFilter = filter[key];
          break;
        case 'AND':
          this.ANDFilter = filter[key];
          break;
        default:
          merge(this.singleSelectFilter, { [`${key}`]: filter[key] });
      }
    });
  };

  @action
  public onSingleSelectChanged = (
    value: SelectValue | string | number | boolean,
    field: string
  ) => {
    this.singleSelectFilter[field] = value;
  };

  @action
  public onMultiSelectChanged = (value: SelectValue, field: string) => {
    if (!(value as (string | number)[]).length) {
      remove(this.multipleSelectFilter, (item) => Object.keys(item)[0] === field);
    } else {
      const arrayObject = (value as (string | number)[]).map((item) => ({ [`${field}`]: item }));
      const intersecValue = intersectionWith(this.multipleSelectFilter, arrayObject, isEqual);
      this.multipleSelectFilter.push(...differenceWith(arrayObject, intersecValue, isEqual));
    }
  };

  @action
  public onMultiUncheckChanged = (value: CheckboxValueType[], field: string) => {
    const arrayObject = (value as (string | number)[]).map((item) => ({ [`${field}`]: item }));
    remove(this.multipleSelectFilter, (item) => Object.keys(item)[0] === field);
    this.multipleSelectFilter.push(...arrayObject);
  };

  @action
  public onDeselectChanged = (value: SelectValue, field: string) => {
    const object = { [`${field}`]: value as string | number };
    remove(this.multipleSelectFilter, (item) => isEqual(item, object));
  };

  @action
  public onDateRangeChanged = (value: number[], field: string[]) => {
    if (field.length === 1) {
      const _operators = { [`${field[0]}`]: { gte: value[0], lte: value[1] } };
      merge(this.dateRangeFilter, { _operators });
    }
    if (field.length > 1) {
      remove(
        this.ORFilter,
        (item) =>
          Object.keys(item._operators)[0] === field[0] ||
          Object.keys(item._operators)[0] === field[1]
      );
      if (!value.length) {
        return;
      }
      this.ORFilter.push(
        {
          _operators: { [`${field[0]}`]: { gte: value[0], lte: value[1] } }
        },
        { _operators: { [`${field[1]}`]: { gte: value[0], lte: value[1] } } }
      );
    }
  };

  @action
  public clearFilter = () => {
    this.singleSelectFilter = {};
    this.multipleSelectFilter = [];
    this.ORFilter = [];
    this.ANDFilter = [];
    this.dateRangeFilter = {};
    this.initFilter = {};
    return this.whereFilter;
  };
}
