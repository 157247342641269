/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-shadow */
/* eslint-disable prefer-const */
import '@ant-design/compatible/assets/index.css';

import {
  Button,
  Card,
  Checkbox,
  Col,
  Input,
  Radio,
  Row,
  Select,
  Statistic,
  Tag
} from 'antd';
import { get, intersectionWith, isEmpty, isEqual, set, sumBy, uniq } from 'lodash';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import ReactResizeDetector from 'react-resize-detector';

import { Form } from '@ant-design/compatible';
import { ArrowLeftOutlined, CloseOutlined } from '@ant-design/icons';
import { LICENSE, mapReduce, MGN, removeSign } from '@source/common';

import { flexAlign, flexCenter } from '~assets/css-classes';
import userStore from '~common-stores/userStore';
import Loading from '~components/UI/Loading';
import { useStore } from '~context/react-context';

import store from './store';

const { Option } = Select;
const { Item } = Form;

const ChooseLocationForm = observer(() => {
  const {
    loading,
    setFilter,
    clearFilter,
    setOwnerType,
    categoryName,
    groupLocation,
    setCheckedList,
    isSelectCategory,
    filteredLocation,
    setSelectCategory,
    locationCheckedList,
    groupCheckedCategory,
    filteredCheckedLocation,
    bookingRangeDate
  } = store;
  const { currentUser } = userStore;
  useEffect(() => {
    setFilter({ ownerType: currentUser.ownerType });
  }, []);

  const { commonDataStore } = useStore();
  const { commonCity } = commonDataStore;
  const [city, setCity] = useState([]);
  const [isClearFilter, setClearFilter] = useState(false);
  let ownerTypeOption = [LICENSE];
  if (currentUser.ownerType === MGN) {
    ownerTypeOption.unshift(currentUser.ownerType);
  }
  const [group, setGroup] = useState([]);

  const categoryMap = mapReduce(get(commonDataStore, 'categories', []), 'value', (data) => ({
    text: data.text,
    group: data.meta.group
  }));

  const groupMap = mapReduce(get(commonDataStore, 'groupType', []), 'value', (data) => ({
    text: data.text,
    price: get(data, 'meta.price', 0)
  }));

  const onCheckAllChange = (e) => {
    const {
      target: { checked }
    } = e;

    if (checked) {
      Object.keys(groupCheckedCategory).forEach((e) => {
        set(groupCheckedCategory, e, 1);
      });
      setCheckedList(filteredLocation);
    } else {
      Object.keys(groupCheckedCategory).forEach((e) => {
        set(groupCheckedCategory, e, 0);
      });
      setCheckedList([]);
    }
  };

  const clearAllFilter = () => {
    setClearFilter(true);
    clearFilter();
    setCity([]);
    setOwnerType(currentUser.ownerType);
    // setDistrict([]);
  };

  const onCheckCategory = (e) => {
    const {
      target: { checked, value }
    } = e;
    e.stopPropagation();
    const category = value || categoryName;

    if (checked) {
      setCheckedList(
        uniq([...locationCheckedList, ...filteredLocation.filter((l) => l.categoryId === category)])
      );
      setSelectCategory(category);

      set(groupCheckedCategory, category, 1);
    } else {
      setCheckedList(uniq(locationCheckedList.filter((l) => l.categoryId !== category)));
      set(groupCheckedCategory, category, 0);
      setSelectCategory(category);
    }
  };

  const handleSelectCity = (e) => {
    setCity(e);
    setFilter({ city: e });
    setClearFilter(false);
  };

  const handleSelectGroup = (e) => {
    setGroup(e);
    setFilter({ group: e });
    setClearFilter(false);
  };

  const onChooseLocation = async (e, value?) => {
    const {
      target: { checked, name }
    } = e;

    if (!value) {
      setCheckedList(
        checked
          ? [...locationCheckedList, filteredLocation.find((i) => i.name === name)]
          : locationCheckedList.filter((i) => name !== i.name)
      );
    }

    if (value) {
      const findLocation = locationCheckedList.find((i) => i.name === value);
      if (findLocation) {
        setCheckedList(locationCheckedList.filter((i) => value !== i.name));
        set(groupCheckedCategory, categoryName, 0);
      }

      if (!findLocation) {
        setCheckedList([...locationCheckedList, filteredLocation.find((i) => i.name === value)]);

        if (
          isEqual(
            intersectionWith(
              groupLocation[categoryName],
              [...locationCheckedList, filteredLocation.find((i) => i.name === value)],
              isEqual
            ),
            groupLocation[categoryName]
          )
        ) {
          set(groupCheckedCategory, categoryName, 1);
        }
      }
    }
    if (!isEmpty(bookingRangeDate.to)) {
      const locationIds = locationCheckedList.map((l) => l._id);
      store.getAvailableBooking(locationIds, bookingRangeDate.to);
    }
  };

  const onSelectCategory = (e, category) => {
    e.stopPropagation();
    setSelectCategory(category, true);
  };
  const onRemoveLocation = (loc) => {
    setCheckedList(locationCheckedList.filter((i) => i.name !== loc));
  };
  return loading ? (
    <div className={flexCenter} style={{ height: 'calc(100% - 80px)' }}>
      <Loading />
    </div>
  ) : (
    <>
      <Row style={{ background: '#fff', padding: '0 16px', height: 'calc(100vh - 176px)' }}>
        <Col span={4} style={{ padding: '8px 8px 0 0' }}>
          {currentUser.ownerType !== LICENSE ? (
            <Radio.Group
              onChange={(e) => {
                setOwnerType(e.target.value);
                setFilter({ ownerType: e.target.value });
                setClearFilter(false);
              }}
              defaultValue={currentUser.ownerType}
              buttonStyle="solid"
              style={{ width: '100%', marginBottom: '8px' }}
            >
              {ownerTypeOption.map((o) => (
                <Radio.Button
                  defaultChecked={o === ownerTypeOption[0] ? true : false}
                  key={o}
                  value={o}
                >
                  {o}
                </Radio.Button>
              ))}
            </Radio.Group>
          ) : null}
          <Form id="filterLocationForm" layout="vertical">
            <Item label="City" labelAlign="left" style={{ marginBottom: 8 }}>
              <Select
                style={{ width: '100%' }}
                placeholder="Select City"
                showSearch
                onChange={(e) => handleSelectCity(e)}
                value={isClearFilter ? undefined : city}
                mode="multiple"
                allowClear
                filterOption={(input, option) =>
                  removeSign(option.props.children)
                    .toString()
                    .toLowerCase()
                    .indexOf(removeSign(input).toLowerCase()) >= 0
                }
              >
                {commonCity.map(({ text, value }, index) => {
                  return (
                    <Option key={String(index)} value={value}>
                      {text}
                    </Option>
                  );
                })}
              </Select>
            </Item>
            <Item label="Group" labelAlign="left" style={{ marginBottom: 8 }}>
              <Select
                style={{ width: '100%' }}
                placeholder="Group"
                showSearch
                onChange={handleSelectGroup}
                value={isClearFilter ? undefined : group}
                removeIcon={<></>}
                mode="multiple"
                filterOption={(input, option) =>
                  option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {commonDataStore.groupType.map((item, idx) => (
                  <Option key={String(idx)} value={item.value}>
                    {item.text}
                  </Option>
                ))}
              </Select>
            </Item>
          </Form>

          <Button ghost danger onClick={clearAllFilter} style={{ marginTop: 12 }}>
            Clear Filter
          </Button>
        </Col>

        {/* location */}
        <ReactResizeDetector handleHeight>
          {({ height }) => (
            <Col
              span={10}
              style={{
                minHeight: height,
                height
              }}
            >
              <Row style={{ padding: '8px 0 0 8px' }} justify="center" align="middle">
                {/* Search Location */}
                <Col span={16}>
                  {isSelectCategory && (
                    <>
                      <ArrowLeftOutlined
                        style={{ margin: '0 16px' }}
                        onClick={(e) => {
                          onSelectCategory(e, null);
                        }}
                      />
                      <Input
                        style={{ width: '80%' }}
                        placeholder="Search Location..."
                        allowClear
                        onChange={(e) => setFilter({ _search: e.target.value })}
                      />
                    </>
                  )}
                </Col>
                <Col span={8}>
                  {!isEmpty(groupLocation) && (
                    <Checkbox
                      style={{ float: 'right' }}
                      {...(isSelectCategory
                        ? { onChange: onCheckCategory }
                        : { onChange: onCheckAllChange })}
                      checked={
                        isSelectCategory
                          ? filteredLocation.length > 0 && !!groupCheckedCategory[categoryName]
                          : Object.keys(groupCheckedCategory).every(
                              (group) => !!groupCheckedCategory[group]
                            )
                      }
                    >
                      <span style={{ fontSize: 16, fontWeight: 'bold', color: '#1890ff' }}>
                        Check All
                      </span>
                    </Checkbox>
                  )}
                </Col>
              </Row>

              <Row
                style={{
                  height: height ? height - 40 : 0,
                  minHeight: height ? height - 40 : 0,
                  overflow: 'auto'
                }}
              >
                {!isSelectCategory
                  ? Object.keys(get(store, 'groupLocation', {})).map((category) => {
                      const categoryMapping = categoryMap[category];
                      const group = groupMap[categoryMapping.group];
                      return (
                        <Col span={12} style={{ padding: 8, minHeight: 160 }} key={category}>
                          <Card
                            hoverable
                            onClick={(e) => onSelectCategory(e, category)}
                            size="small"
                            style={{ minHeight: 160 }}
                            title={
                              group && (
                                <>
                                  <Tag color="blue">{group.text}</Tag>
                                  <span style={{ float: 'right' }}>
                                    Price: <Tag color="cyan">{group.price}</Tag>
                                  </span>
                                </>
                              )
                            }
                          >
                            <b style={{ fontSize: 20 }}>{categoryMapping.text}</b>
                            <Checkbox
                              style={{ float: 'right' }}
                              value={category}
                              onClick={onCheckCategory}
                              checked={!!groupCheckedCategory[category]}
                            />

                            <Row>
                              <Col span={12}>
                                <Statistic
                                  title="Location"
                                  value={groupLocation[category].length}
                                />
                              </Col>

                              <Col span={12}>
                                <Statistic
                                  title="KPI/Month"
                                  value={sumBy(groupLocation[category], 'stats.monthlyClick')}
                                />
                              </Col>
                            </Row>
                          </Card>
                        </Col>
                      );
                    })
                  : get(groupLocation, categoryName, []).map((locData, idx) => {
                      const { name, stats } = locData;
                      return (
                        <Col
                          span={12}
                          key={String(idx)}
                          style={{
                            maxHeight: 100,
                            minHeight: 100,
                            padding: 8,
                            marginBottom: 16
                          }}
                        >
                          <Card
                            size="small"
                            hoverable
                            style={{ maxHeight: 100, minHeight: 100, marginBottom: 16 }}
                            onClick={(e) => onChooseLocation(e, name)}
                          >
                            <Checkbox
                              onChange={onChooseLocation}
                              checked={
                                !isEmpty(locationCheckedList) &&
                                locationCheckedList.some((i) => i.name === name)
                              }
                              name={name}
                            />
                            <span style={{ fontWeight: 'bold', paddingLeft: 4 }}>{name}</span>

                            <p style={{ bottom: 0, position: 'absolute' }}>
                              Clicks/Day : <Tag>{get(stats, 'dailyClick', 0)}</Tag>
                            </p>
                          </Card>
                        </Col>
                      );
                    })}
              </Row>
            </Col>
          )}
        </ReactResizeDetector>

        <ReactResizeDetector handleHeight>
          {({ height }) => (
            <Col
              span={10}
              style={{
                minHeight: height,
                height
              }}
            >
              {!loading && (
                <Row className={flexAlign} style={{ padding: '8px 8px 0 8px' }}>
                  <Col span={10}>
                    <Tag color="geekblue">Total Location: {locationCheckedList.length}</Tag>
                  </Col>

                  <Col span={14}>
                    <Input
                      placeholder="Search Checked Location..."
                      allowClear
                      onChange={(e) => setFilter({ search: e.target.value })}
                    />
                  </Col>
                </Row>
              )}

              <Row
                style={{
                  height: height ? height - 40 : 0,
                  minHeight: height ? height - 40 : 0,
                  overflow: 'auto'
                }}
              >
                {filteredCheckedLocation.map((i, idx) => (
                  <Col span={12} key={String(idx)}>
                    <Card
                      style={{ margin: 8 }}
                      title={get(i, 'name', '')}
                      size="small"
                      extra={
                        <CloseOutlined
                          style={{ color: 'red' }}
                          onClick={() => onRemoveLocation(get(i, 'name', ''))}
                        />
                      }
                    >
                      Clicks/Day: <Tag color="#108ee9">{get(i, 'stats.dailyClick', 0)}</Tag>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Col>
          )}
        </ReactResizeDetector>
      </Row>
    </>
  );
});

export default ChooseLocationForm;
