import { Button, Collapse, Divider, Input, List } from 'antd';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DeleteOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import store, { LocationGroup } from '../store';

const { Panel } = Collapse;

interface GenExtraProps {
  index: number;
  element: LocationGroup;
  setIsValid?: Function;
  isValid?: boolean;
}

const GenExtra = (props: GenExtraProps) => {
  const { element, index, setIsValid, isValid } = props;
  const { addLocationsMode, removeLocationGroup, updateLocationGroup, locationGroups } = store;

  // eslint-disable-next-line no-shadow
  const handleOnChangeInput = (e, index) => {
    const {value} = e.target;
    if (value.trim().length === 0) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
    const record = { ...locationGroups[index] };
    record.groupName = e.target.value;
    updateLocationGroup(index, record);
  };

  return (
    <div
      onClick={event => {
        event.stopPropagation();
      }}
      style={{ width: '100%' }}
    >
      <Input
        onChange={e => handleOnChangeInput(e, index)}
        style={{ width: '90%', borderColor: !isValid && '#ef4f57' }}
        value={element.groupName}
        disabled={addLocationsMode}
      />
      <div
        style={{
          fontSize: 18,
          width: '10%',
          display: 'inline-block',
          textAlign: 'right',
          color: '#df5b5b'
        }}
      >
        {!addLocationsMode && (
          <MinusCircleOutlined onClick={() => removeLocationGroup(index)} />
        )}
      </div>
    </div>
  );
};

const LocationGroupElement: FC<GenExtraProps> = props => {
  const { addLocationsMode, updateLocationGroup, setupAddLocationMode, addLocation } = store;
  const { index, element } = props;

  const [isValid, setIsValid] = useState(!!element.groupName);
  const { t } = useTranslation();

  // eslint-disable-next-line no-shadow
  const handleDeleteLocation = (index: number, record: LocationGroup, location) => {
    const newValue = { ...record };
    newValue.locations = newValue.locations.filter(e => e !== location);
    updateLocationGroup(index, newValue);
    addLocation(location);
  };

  return (
    <Collapse key={String(index)} style={{ width: '100%', marginBottom: 10 }}>
      <Panel
        disabled={addLocationsMode}
        style={{ padding: 0 }}
        header={
          <GenExtra element={element} index={index} setIsValid={setIsValid} isValid={isValid} />
        }
        key={String(index)}
      >
        <Button
          onClick={() => setupAddLocationMode(true, index)}
          type="primary"
          style={{ width: 200 }}
          disabled={addLocationsMode || !isValid}
        >
          <PlusOutlined /> {t('ADD_LOCATION')}
        </Button>
        <Divider style={{ marginBottom: 10 }} />
        <List
          size="small"
          style={{
            maxHeight: 500,
            overflow: 'scroll'
          }}
          dataSource={element.locations}
          renderItem={item => (
            <List.Item
              actions={[
                // eslint-disable-next-line react/jsx-key
                <DeleteOutlined onClick={() => handleDeleteLocation(index, element, item)} />
              ]}
            >
              {item.locationName}
            </List.Item>
          )}
        />
      </Panel>
    </Collapse>
  );
};

export default LocationGroupElement;
