import { css } from 'emotion';

export const logo_sider = css({
  height: '64px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'all 0.3s'
});

export const initLayoutBreakpoint = css({
  marginLeft: '80px',
  '@media(max-width: 576px)': {
    marginLeft: 0
  }
});

export const initLayout = css({
  marginLeft: '201px',
  '@media(max-width: 576px)': {
    marginLeft: 0
  },
  display: 'flex',
  flexFlow: 'column'
});

export const collapseLayout = css({
  marginLeft: '80px',
  '@media(max-width: 576px)': {
    marginLeft: 0
  },
  display: 'flex',
  flexFlow: 'column'
});

export const collapse = css({
  marginLeft: '80px',
  '@media(max-width: 576px)': {
    marginLeft: 0
  },
  WebkitTransition: 'margin .2s',
  transition: 'margin .2s'
});

export const expand = css({
  marginLeft: '200px',
  '@media(max-width: 576px)': {
    marginLeft: 0
  },
  WebkitTransition: 'margin .2s',
  transition: 'margin .2s'
});

export const wrapper_content = css({
  padding: '8px',
  // backgroundColor: '#fff',
  height: 'calc(100vh - 64px)',
  // maxHeight: 'calc(100vh - 64px)',
  flex: '1 1 auto',
  overflowX: 'hidden',
  overflowY: 'auto',
  display: 'flex',
  flexFlow: 'column'
});

export const fixed_header = css({
  position: 'sticky',
  padding: 0,
  top: 0,
  zIndex: 2,
  width: '100%',
  backgroundColor: '#fff',
  WebkitBoxShadow: '0 1px 4px rgba(0,21,41,.08)',
  boxShadow: '0 1px 4px rgba(0,21,41,.08)'
});

export const componentWrapper_header = css({
  height: '100%',
  float: 'right',
  marginLeft: 'auto',
  overflow: 'hidden'
});

export const dropdownMenu_header = css({
  display: 'inline-block',
  height: '100%',
  padding: '0 12px',
  cursor: 'pointer',
  transition: 'all 0.3s',
  ':hover': {
    background: '#f0f2f5'
  }
});

export const fixed_sider = css({
  height: '100vh',
  position: 'fixed',
  left: 0,
  top: 0,
  background: '#fff',
  zIndex: 2,
  boxShadow: '2px 0 8px 0 rgba(29,35,41,.05)',
  '@media(max-width: 576px)': {
    display: 'none'
  }
});

export const alignMenuItem = css({
  display: 'flex !important',
  alignItems: 'center'
});

export const horDivider = css({
  height: '1px',
  margin: '4px 0',
  overflow: 'hidden',
  lineHeight: 0,
  backgroundColor: '#e8e8e8'
});

// export const Authorizeds = css({
//   borderTop:"1px solid red",
//   width:'100%'
// });

export const menu_sider = css({
  padding: '16px 0',
  width: '100%',
  border: 'none'
});

export const trigger_button = css({
  fontSize: '20px',
  lineHeight: '64px',
  padding: '0 24px',
  cursor: 'pointer',
  transition: 'color 0.3s',
  ':hover': {
    color: '#1890ff'
  }
});

export const trigger_button_lg = css(
  {
    '@media(max-width: 576px)': {
      display: 'none'
    }
  },
  trigger_button
);

export const trigger_button_sm = css(
  {
    '@media(min-width: 576px)': {
      display: 'none'
    }
  },
  trigger_button
);

export const info_name = css({
  marginLeft: '8px',
  '@media(max-width: 576px)': {
    display: 'none'
  }
});

export const menu_route = css(dropdownMenu_header, {
  // maxWidth: '200px',
  '@media(min-width: 576px)': {
    marginLeft: '16px'
  }
});

export const bread_crumb = css({
  position: 'absolute',
  top: 16,
  left: 16,
  color: '#737373',
  fontSize: 'large',
  '@media(max-width: 576px)': {
    display: 'none'
  }
});
// export
