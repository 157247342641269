import { action, computed, observable, reaction, runInAction, toJS } from 'mobx';
import { actionAsync, task } from 'mobx-utils';
import moment from 'moment';

import { GridReadyEvent } from '@ag-grid-community/core';
import { mapReduce } from '@source/common';

import { DateFilterInput, GetAvailableBookingQueryVariables } from '~graphql/_sdk';
import { getSdk } from '~graphql/sdk';
import { DATE_FORMAT, format } from '~utils/formatDate';

class InventoryStore {
  @observable public dayRange;
  @observable public loading: boolean;
  @observable public listAvailableKpi;
  @observable public filterDate: DateFilterInput;
  @observable public filterCategogyId: string[];
  @observable public filerInterval: string;
  @observable public date: DateFilterInput;
  @observable public checked: boolean;
  @observable public detail: boolean;
  @observable public mapCategory;
  constructor() {
    const dateFrom = moment().startOf('day');
    const dateTo = moment().add(30, 'days').startOf('day');

    runInAction(() => {
      this.dayRange = [];
      this.date = {
        from: dateFrom,
        to: dateTo
      };
      this.listAvailableKpi = [];
      this.checked = false;
      this.loading = true;
      this.detail = false;
      this.mapCategory = {};
      this.filterDate = {
        from: dateFrom,
        to: dateTo
      };
      this.filerInterval = 'day';
      this.filterCategogyId = [];
      reaction(
        () => this.whereFilter,
        (whereFilter) => {
          if (!whereFilter) return;
          this.loading = true;
          this.findAvailableKpi();
        }
      );
    });
  }

  @computed
  get whereFilter() {
    const where: GetAvailableBookingQueryVariables = {
      date: this.filterDate,
      categoryIds: this.filterCategogyId,
      detail: this.detail
    };
    return where;
  }

  @action
  public onTableReady = ({ api }: GridReadyEvent) => {
    this.onFilterChanged = () => {
      api.onFilterChanged();
    };
  };

  @action
  public setFilterDate = (days: DateFilterInput) => {
    this.filterDate = days;
  };

  @action
  public setFilterCategoryId = (categoryids: string[]) => {
    this.filterCategogyId = categoryids;
  };

  @action
  public setFilterInterval = (interval: string) => {
    this.filerInterval = interval;
  };

  @action setDate = (date: DateFilterInput) => {
    this.date = date;
  };

  @action
  setChecked = () => {
    this.checked = !this.checked;
    this.onFilterChanged();
  };

  @action
  setDetail = (checked: boolean) => {
    this.detail = checked;
  };

  @action
  doesExternalFilterPass = (node) => {
    if (this.checked) {
      return node.isSelected();
    }
    return node;
  };

  @actionAsync
  public findAvailableKpi = async () => {
    const sdk = await task(getSdk());

    const { findManyCommon: categories } = await task(
      sdk.findManyCommon({
        where: {
          _operators: {
            type: { in: ['location_category', 'group_category'] }
          }
        }
      })
    );

    this.mapCategory = mapReduce(categories, 'value', (item) => ({
      value: item.value,
      text: item.text
    }));

    const { getAvailableBooking } = await task(sdk.getAvailableBooking(this.whereFilter));
    this.listAvailableKpi = getAvailableBooking.locations;

    if (this.filerInterval === 'day') {
      this.dayRange = getAvailableBooking.locations[0].everyDay.map((item) =>
        format(item.date, DATE_FORMAT)
      );
    }
    this.loading = false;
  };

  @action
  onFilterChanged = () => undefined;
}

export default new InventoryStore();
