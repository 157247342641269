import Bluebird from 'bluebird';
import { debounce, merge } from 'lodash';
import { action, observable, runInAction } from 'mobx';
import { actionAsync, task } from 'mobx-utils';

import { GridReadyEvent } from '@ag-grid-community/core';
import { FilterSelect, Options, SelectOptions, WhereArgs } from '@source/common';

class FilterStore {
  @observable public selectData: SelectOptions[];
  @observable public where: WhereArgs;
  @observable public preFilter: WhereArgs;
  @observable public loading: boolean;

  constructor() {
    runInAction(() => {
      this.selectData = [];
      this.where = {};
      this.preFilter = {};
      this.loading = true;
    });
  }

  @action
  public addSelectData = (data: SelectOptions) => {
    this.selectData.push(data);
  };

  @action
  public clearSelectData = () => {
    this.selectData = [];
  };

  @action
  public clearFilterData = () => {
    this.preFilter = {};
    this.where = {};
    this.reload();
  };

  @action
  public setPreFilter = (filter: WhereArgs) => {
    merge(this.preFilter, filter);
  };

  @action
  public applyFilter = () => {
    Object.assign(this.where, { ...this.preFilter });
    this.reload();
  };

  @action searchFilter = debounce(
    (text: string) => {
      runInAction(() => {
        Object.assign(this.where, { _search: text });
        this.reload();
      });
    },
    500,
    { leading: false, trailing: true }
  );

  @actionAsync
  public handleFilterSelect = async (filterSelect: FilterSelect[] = []) => {
    this.loading = true;
    await task(
      Bluebird.mapSeries(filterSelect, async (filter) => {
        const { config, query, queryKey } = filter;
        if (filter.query) {
          let data: any[] = await query();
          data = queryKey ? data[queryKey] : data;
          const options: Options[] =
            data.length &&
            data.map((item) => ({
              value: item[filter.fieldValue],
              text: item[filter.fieldText]
            }));
          const selectOptions: SelectOptions = {
            field: config.field,
            placeholder: config.placeholder,
            multiple: config.multiple,
            label: config.label,
            options
          };
          this.addSelectData(selectOptions);
        }
      })
    );
    this.loading = false;
  };

  public handleGridReady = ({ api }: GridReadyEvent) => {
    this.reload = () => {
      api.onFilterChanged();
    };
  };

  public reload = () => undefined;
}

export default new FilterStore();
