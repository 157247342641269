// import 'react-awesome-query-builder/css/antd.less'; // or import "antd/dist/antd.css";
import 'react-awesome-query-builder/css/styles.scss';
import 'react-awesome-query-builder/css/compact_styles.scss';

import { isEqual, merge } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  Builder,
  BuilderProps,
  Config,
  Conjunctions,
  Fields,
  Funcs,
  ImmutableTree,
  JsonTree,
  LocaleSettings,
  Query,
  Settings,
  Types,
  Utils
} from 'react-awesome-query-builder';
import AntdConfig from 'react-awesome-query-builder/lib/config/antd';

const { stringify } = JSON;
const { mongodbFormat, checkTree, getTree, loadTree, uuid } = Utils;
const InitialConfig = AntdConfig;

const localeSettings: LocaleSettings = {
  valueLabel: 'Value',
  valuePlaceholder: 'Value',
  fieldLabel: 'Field',
  operatorLabel: 'Operator',
  fieldPlaceholder: 'Select field',
  operatorPlaceholder: 'Select operator',
  deleteLabel: null,
  addGroupLabel: 'Add group',
  addRuleLabel: 'Add rule',
  delGroupLabel: null,
  notLabel: 'Not',
  valueSourcesPopupTitle: 'Select value source',
  removeRuleConfirmOptions: {
    title: 'Are you sure delete this rule?',
    okText: 'Yes',
    okType: 'danger'
  },
  removeGroupConfirmOptions: {
    title: 'Are you sure delete this group?',
    okText: 'Yes',
    okType: 'danger'
  }
};

const settings: Settings = {
  ...InitialConfig.settings,
  ...localeSettings,

  valueSourcesInfo: {
    value: {
      label: 'Value'
    },
    field: {
      label: 'Field',
      widget: 'field'
    },
    func: {
      label: 'Function',
      widget: 'func'
    }
  },
  canReorder: false,
  canRegroup: false,
  showNot: false,
  // showLabels: true,
  maxNesting: 3,
  canLeaveEmptyGroup: true // after deletion

  // renderField: (props) => <FieldCascader {...props} />,
  // renderOperator: (props) => <FieldDropdown {...props} />,
  // renderFunc: (props) => <FieldSelect {...props} />,
};

const widgets = {
  ...InitialConfig.widgets,
  slider: {
    ...InitialConfig.widgets.slider,
    customProps: {
      width: '200px'
    }
  },
  rangeslider: {
    ...InitialConfig.widgets.rangeslider,
    customProps: {
      width: '200px'
    }
  },
  number: {
    ...InitialConfig.widgets.number,
    customProps: {
      width: '500px'
    }
  },
  date: {
    ...InitialConfig.widgets.date,
    dateFormat: 'DD.MM.YYYY',
    valueFormat: 'YYYY-MM-DD'
  },
  time: {
    ...InitialConfig.widgets.time,
    timeFormat: 'HH:mm',
    valueFormat: 'HH:mm:ss'
  },
  datetime: {
    ...InitialConfig.widgets.datetime,
    timeFormat: 'HH:mm',
    dateFormat: 'DD.MM.YYYY',
    valueFormat: 'YYYY-MM-DD HH:mm:ss'
  },
  func: {
    ...InitialConfig.widgets.func,
    customProps: {
      showSearch: true
    }
  }
};

const funcs: Funcs = {
  LOWER: {
    label: 'Lowercase',
    mongoFunc: '$toLower',
    returnType: 'text',
    args: {
      str: {
        label: 'String',
        type: 'text',
        valueSources: ['value', 'field']
      }
    }
  },
  LINEAR_REGRESSION: {
    label: 'Linear regression',
    returnType: 'number',
    formatFunc: ({ coef, bias, val }, _) => `(${coef} * ${val} + ${bias})`,
    sqlFormatFunc: ({ coef, bias, val }) => `(${coef} * ${val} + ${bias})`,
    mongoFormatFunc: ({ coef, bias, val }) => ({ $sum: [{ $multiply: [coef, val] }, bias] }),
    renderBrackets: ['', ''],
    renderSeps: [' * ', ' + '],
    args: {
      coef: {
        label: 'Coef',
        type: 'number',
        defaultValue: 1,
        valueSources: ['value']
      },
      val: {
        label: 'Value',
        type: 'number',
        valueSources: ['value']
      },
      bias: {
        label: 'Bias',
        type: 'number',
        defaultValue: 0,
        valueSources: ['value']
      }
    }
  }
};

const conjunctions: Conjunctions = {
  AND: InitialConfig.conjunctions.AND,
  OR: InitialConfig.conjunctions.OR
};

const types: Types = {
  ...InitialConfig.types,
  // examples of  overriding
  boolean: merge(InitialConfig.types.boolean, {
    widgets: {
      boolean: {
        widgetProps: {
          hideOperator: true,
          operatorInlineLabel: 'is'
        }
      }
    }
  })
};

const queryValue: JsonTree = { id: uuid(), type: 'group' };

interface QueryBuilderProps {
  fields: Fields;
  initValue?: JsonTree;
  getQuery?: Function;
  getTree?: Function;
}

const QueryBuilder = (props: QueryBuilderProps) => {
  const { fields, initValue } = props;
  const loadedConfig: Config = {
    ...InitialConfig,
    widgets,
    settings,
    funcs,
    fields,
    conjunctions,
    types
  };

  const [treeValue, setTreeValue] = useState(initValue || queryValue);
  const [config, setConfig] = useState(loadedConfig);

  const [tree, setTree] = useState(() => {
    return checkTree(loadTree(treeValue), loadedConfig);
  });

  useEffect(() => {
    if (initValue && !isEqual(treeValue, initValue)) {
      setTreeValue(initValue);
      setTree(checkTree(loadTree(initValue), loadedConfig));
    }
    if (!initValue) {
      setTreeValue(queryValue);
      setTree(checkTree(loadTree(queryValue), loadedConfig));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initValue]);

  const renderBuilder = (renderBuilderProps: BuilderProps) => <Builder {...renderBuilderProps} />;

  const onChange = (immutableTree: ImmutableTree, configOnChange: Config) => {
    setTree(immutableTree);
    setConfig(configOnChange);
    props.getTree(getTree(immutableTree));
    const query = stringify(mongodbFormat(immutableTree, configOnChange), undefined, 2);
    props.getQuery(query);
  };

  return (
    <div style={{ padding: 0 }}>
      <Query {...loadedConfig} value={tree} onChange={onChange} renderBuilder={renderBuilder} />
    </div>
  );
};

export default QueryBuilder;
