const translations = {
  ACTION_SUCCESS: '{{action}} completed successfully',
  ACTION_FAIL: '{{action}} failed',
  ACTION_CANCELED: '{{action}} is canceled',
  CONFIRM_ACTION_ITEM: 'Are you sure to {{action}} {{item}}?',
  CONFIRM_DELETE_ITEM: 'Are you sure to delete {{item}}?',
  DELETE_THIS: 'Delete this?',
  SELECT_ITEM: 'Select {{item}}',
  INPUT_ITEM: 'Input {{item}}',
  ITEM_REQUIRED: '{{item}} is required',
  ITEM_MUST_BE_ATLEAST_VALUE: '{{item}} must be at least {{value}}',
  YOU_HAVE_UNSAVED_CHANGE: 'You have unsaved changes. Do you want to save?'
};

export default translations;
