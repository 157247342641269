import { observer } from 'mobx-react';
import React, { FC, useEffect } from 'react';

import Form from './Form';
import Grid from './Grid';
import Header from './Header';
import store from './store';

const InstructionScreen: FC = observer(() => {
  const { selectInstructionTypeInst } = store;
  useEffect(() => {
    selectInstructionTypeInst('megaplus_mkt', null);
  }, []);
  return (
    <>
      <Header />
      <Grid />
      <Form />
    </>
  );
});

export default InstructionScreen;
