import { Card, Col, Row, Spin, Tag } from 'antd';
import * as CSS from 'csstype';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { observer } from 'mobx-react';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { UndoOutlined } from '@ant-design/icons';

import { createClickPerDayChartOptions, createClickPerWeekChartOptions } from './chartOptions';
import store from './store';

export interface LoginReportProps {
  isShow: boolean;
  style?: CSS.Properties<string | number>;
}
const LoginReport: FC<LoginReportProps> = observer((props) => {
  const { isShow, style } = props;
  const {
    reportLogin,
    selectDay,
    selectDayText,
    refreshSelectDay,
    loginLoading,
    setEnableLogin
  } = store;
  const { t } = useTranslation();

  useEffect(() => {
    setEnableLogin(isShow);
  }, []);

  return isShow ? (
    <Row gutter={8} style={{ marginBottom: 16, ...style}}>
      <Col xs={24} md={10}>
        <div>
          <Spin spinning={loginLoading}>
            <Card>
              <HighchartsReact
                highcharts={Highcharts}
                options={createClickPerWeekChartOptions(reportLogin, t('dashboard.AVERAGE_WEEK')) as any}
              />
            </Card>
          </Spin>
        </div>
      </Col>
      <Col xs={24} md={14}>
        <div>
          <Tag
            color="#108ee9"
            onClick={() => refreshSelectDay()}
            style={{
              display: 'inline-block',
              position: 'absolute',
              top: '4px',
              zIndex: 2,
              left: '70px'
            }}
          >
            {selectDayText}
            <UndoOutlined style={{ marginLeft: 10 }} />
          </Tag>
          <Spin spinning={loginLoading}>
            <Card>
              <HighchartsReact
                highcharts={Highcharts}
                options={createClickPerDayChartOptions(reportLogin, selectDay, t('dashboard.AVERAGE_DAY')) as any}
              />
            </Card>
          </Spin>
        </div>
      </Col>
    </Row>
  ) : null;
});
export default LoginReport;
