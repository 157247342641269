import { Card, Col, Progress, Row, Spin } from 'antd';
import { observer } from 'mobx-react';
import React, { FC, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { dataChart } from './dataChart';

import { DataOptions } from '~components/Filter/filterType';
import { UISelect } from '~components/UI/Select/UISelect';
import { formatNumber } from '~utils/formatNumber';

import store from './store';
import { useTranslation } from 'react-i18next';

const Device: FC = observer(() => {
  const { t } = useTranslation();

  const { reportDevice, setDate, loadingDevice } = store;
  const dataDevice = [
    {
      name: 'Mobile',
      y: reportDevice?.percentMobile,
      color: '#0078D4'
    },
    {
      name: 'Laptop',
      y: reportDevice?.percentPC,
      color: '#FFA242'
    },
    {
      name: 'Tablet',
      y: reportDevice?.percentTablet,
      color: '#F75A5A'
    }
  ];

  const dataOS = [
    {
      name: 'Android',
      y: reportDevice?.percentAndroid,
      color: '#0078D4'
    },
    {
      name: 'iOS',
      y: reportDevice?.percentIOS,
      color: '#FFA242'
    },
    {
      name: 'Other',
      y: reportDevice?.percentTablet,
      color: '#F75A5A'
    }
  ];

  const data = [
    {
      text: '7 days',
      value: 7
    },
    {
      text: '15 days',
      value: 15
    },
    {
      text: '30 days',
      value: 30
    }
  ];

  const dataOptions: DataOptions = {
    data,
    textField: 'text',
    valueField: 'value'
  };

  const textTitle = (title: string) => {
    return `<div style='text-align: center'>${title ? t(title) : t('TOTAL')}</div>`;
  };

  const optionsDevice = dataChart(textTitle(t('DEVICE')), dataDevice, -45);
  const optionsOs = dataChart(textTitle(t('OS')), dataOS, -45);

  return (
    <Card
      title={`${t('dashboard.DEVICE')} (${formatNumber(reportDevice?.totalDevice)})`}
      extra={
        <UISelect
          key="select"
          style={{ width: '100px', marginBottom: 8, height: '25px' }}
          onChange={(e) => setDate(e)}
          dataOptions={dataOptions}
          defaultValue={7}
        />
      }
      style={{ height: '100%' }}
    >
      <Spin spinning={loadingDevice}>
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <HighchartsReact highcharts={Highcharts} options={optionsDevice as any} />
          </Col>
          <Col span={12}>
            <HighchartsReact highcharts={Highcharts} options={optionsOs as any} />
          </Col>
        </Row>
      </Spin>
    </Card>
  );
});

export default Device;
