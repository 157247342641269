/* eslint-disable radix */
import './RevenueConfig.less';

import { Button, Col, Row, Spin, Tabs } from 'antd';
import { observer } from 'mobx-react';
import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { RollbackOutlined } from '@ant-design/icons';

import { useStore } from '~context/react-context';
import { RevenueScreenLocation } from '~screens/routes';
import useQuery from '~utils/useQuery';

import store from '../store';
import ConfigOption from './ConfigOptions';
import CustomerRevenue from './CustomerRevenue';
import MgnRevenue from './MGNRevenue';

const { TabPane } = Tabs;

const RevenueConfigScreen: FC = observer(() => {
  const {
    setRevenueConfigData,
    setMappingCategoryName,
    setMappingCustomerName,
    resetStore,
    loading
  } = store;

  const { commonDataStore } = useStore();
  const query = useQuery();
  const id = query.get('id');
  const history = useHistory();

  useEffect(() => {
    return () => resetStore();
  }, []);

  useEffect(() => {
    if (!commonDataStore.categories.length) {
      commonDataStore.loadLocationCategory();
    } else {
      setMappingCategoryName(commonDataStore.categories);
    }
  }, [commonDataStore.categories]);

  useEffect(() => {
    if (!commonDataStore?.customers.length) {
      commonDataStore.loadCustomers();
    } else {
      setMappingCustomerName(commonDataStore?.customers);
    }
  }, [commonDataStore?.customers]);

  useEffect(() => {
    if (!commonDataStore?.customers.length || !commonDataStore.categories.length) return;
    setRevenueConfigData(id);
  }, [id, commonDataStore.customers, commonDataStore.categories]);

  const backToRevenueList = () => {
    history.push({ search: '', pathname: RevenueScreenLocation.path });
  };

  return (
    <Spin spinning={loading}>
      <Row gutter={16} style={{ height: 'calc(100vh - 80px)' }}>
        <Col className="gutter-row" span={16} style={{ backgroundColor: '#fff', padding: '10px' }}>
          <Tabs
            defaultActiveKey="1"
            type="card"
            size="small"
            tabBarExtraContent={
              <Button onClick={() => backToRevenueList()}>
                <RollbackOutlined />
                Back
              </Button>
            }
          >
            <TabPane tab="My Revenue" key="myRevenue">
              <div style={{ height: 'calc(100vh - 150px)' }}>
                <MgnRevenue />
              </div>
            </TabPane>
            <TabPane tab="Share" key="customerShare">
              <div style={{ height: 'calc(100vh - 150px)' }}>
                <CustomerRevenue />
              </div>
            </TabPane>
          </Tabs>
        </Col>
        <Col className="gutter-row" span={8}>
          <ConfigOption />
        </Col>
      </Row>
    </Spin>
  );
});

export default RevenueConfigScreen;
