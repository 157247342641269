import { Layout } from 'antd';
import { observer } from 'mobx-react';
import React, { FC, lazy, Suspense, useEffect } from 'react';
import { Route, Switch, useLocation, withRouter } from 'react-router-dom';

import { flexCenter } from '~assets/css-classes';
import { wrapper_content } from '~components/Layout/css';
import Sidebar from '~components/Layout/Sidebar';
import Topbar from '~components/Layout/Topbar';
import Loading from '~components/UI/Loading';
import { useStore } from '~context/react-context';

import forgotPasswordScreen from './forgotPassword/ForgotPasswordScreen';
import Invite from './inviteLicense/InviteScreen';
import LoginScreen from './login/Login';
import Routes from './routes';
import SignUp from './signUp/SignupScreen';
import Email from './verifyEmail/Email';

const { Content } = Layout;

const CustomerTypeScreen = lazy(() => import('./customerType/CustomerTypeScreen'))

const ScreenRoutes: FC = observer(() => {
  const { authStore, layoutStore } = useStore();
  const { layout } = layoutStore;

  return (
    <Layout className={layout}>
      {authStore.isAuthenticated && <Topbar />}
      <Content className={wrapper_content}>
        <Suspense fallback={<Loading />}>
          <Routes />
          <Route path="/customerType" exact component={CustomerTypeScreen} />
        </Suspense>
      </Content>
    </Layout>
  );
});

const Screen: FC = observer(() => {
  const { commonStore, authStore, userStore } = useStore();
  const { pathname } = useLocation();

  useEffect(() => {
    if (authStore.token) {
      userStore.getCurrentUser().finally(() => {
        commonStore.setAppLoaded();
      });
    } else {
      commonStore.setAppLoaded();
    }
  }, [pathname]);

  if (!commonStore.appLoaded) {
    return (
      <div className={flexCenter} style={{ height: '100vh' }}>
        <Loading />
      </div>
    );
  }

  return (
    <Layout style={{ background: 'transparent', minHeight: '100vh', height: '100%', padding: 0 }}>
      {authStore.isAuthenticated && <Sidebar />}
      <Switch>
        <Route path="/signup" component={SignUp} />
        <Route path="/login" component={LoginScreen} />
        <Route path="/verifyEmail" component={Email} />
        <Route path="/inviteEmail/:id" component={Invite} />
        <Route path="/forgotPassword/:id" component={forgotPasswordScreen} />
        <ScreenRoutes />
      </Switch>
    </Layout>
  );
});

export default withRouter(Screen);
