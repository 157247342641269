import { Form, Input, InputNumber, Modal, Radio } from 'antd';
import { Rule } from 'antd/lib/form';
import i18next from 'i18next';
import { get, orderBy } from 'lodash';
import { observer } from 'mobx-react';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BalanceType } from '@source/common';

import { UISelect } from '~components/UI/Select/UISelect';
import { PaymentInput } from '~graphql/_sdk';
import { formatNumber } from '~utils/formatNumber';

import store from './store';

interface FormValues {
  userId?: string;
  amount?: number;
  note?: string;
}

type ValidationSchema<T> = {
  [k in keyof T]: Rule[];
};

const { Item, useForm } = Form;

const layout = {
  labelCol: { sm: 24, md: 8, lg: 6 },
  wrapperCol: { sm: 24, md: 16, lg: 18 }
};

const validationSchema: ValidationSchema<FormValues> = {
  userId: [
    { required: true, message: i18next.t('message.ITEM_REQUIRED', { item: i18next.t('USER') }) }
  ],
  amount: [{ required: true }],
  note: []
};

const PaymentForm: FC = observer(() => {
  const [form] = useForm();
  const { setFieldsValue, resetFields } = form;
  const { isModalVisible, toggleModal, create, selectedItem, fromUser, userSelectOption } = store;

  const [submitting, setSubmitting] = useState(false);

  const { t } = useTranslation();

  const initialValues: FormValues = {
    userId: get(selectedItem, 'userId', fromUser || undefined),
    type: get(selectedItem, 'type'),
    amount: get(selectedItem, 'amount', 0),
    note: get(selectedItem, 'note', '')
  };

  useEffect(() => {
    setFieldsValue(initialValues);
  }, [selectedItem]);

  const submit = async (record: FormValues) => {
    await create((record as unknown) as PaymentInput);
  };

  const onCancel = () => {
    resetFields();
    toggleModal();
  };

  const onOk = () => {
    setSubmitting(true);
    form
      .validateFields()
      .then(async (record) => {
        await submit(record);
        form.resetFields();
        toggleModal();
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Modal
      title={t('PAYMENT_MANAGEMENT')}
      visible={isModalVisible}
      cancelText={t('CLOSE')}
      onCancel={onCancel}
      okText={selectedItem ? t('UPDATE') : t('CREATE')}
      okButtonProps={{
        type: 'primary',
        form: 'equipForm',
        htmlType: 'submit'
      }}
      onOk={onOk}
      maskClosable={false}
      forceRender
      confirmLoading={submitting}
    >
      <Form {...layout} form={form} name="paymentCreateForm">
        <Item label={t('USER')} name="userId" rules={validationSchema.userId}>
          <UISelect
            dataOptions={{
              data: userSelectOption,
              valueField: '_id',
              textField: 'name'
            }}
            placeholder={t('TYPE_TO_SEARCH_USER')}
          />
        </Item>
        <Item name="type" label="Type" initialValue={BalanceType.PRIMARY}>
          <Radio.Group>
            <Radio value={BalanceType.PRIMARY}>{t('balance.PRIMARY_BALANCE')}</Radio>
            <Radio value={BalanceType.SECONDARY}>{t('balance.SECONDARY_BALANCE')}</Radio>
          </Radio.Group>
        </Item>
        <Item label={t('AMOUNT')} name="amount" rules={validationSchema.amount}>
          <InputNumber
            formatter={(value) => `VND ${formatNumber(value)}`}
            parser={(value) => value.replace(/\D?|(,*)/g, '')}
            placeholder={t('AMOUNT')}
            width="100%"
            step="500000"
            min={100000}
          />
        </Item>
        <Item label={t('NOTE')} name="note" rules={validationSchema.note}>
          <Input.TextArea placeholder={t('NOTE')} rows={4} />
        </Item>
      </Form>
    </Modal>
  );
});

export default PaymentForm;
