import { observer } from 'mobx-react';
import React, { FC } from 'react';

import Form from './Form';
import Grid from './Grid';
import Header from './Header';

const BrandAPScreen: FC = observer(() => {


  return (
    <>
      <Header />
      <Grid />
      <Form />
    </>
  );
});

export default BrandAPScreen;
