import { css } from 'emotion';

export const flexCenter = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

export const flexAlign = css({
  display: 'flex',
  alignItems: 'center',
});