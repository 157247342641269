const translations = {
  message: {
    ACTION_SUCCESS: '{action} thành công',
    ACTION_FAIL: '{action} thất bại',
    ACTION_CANCELED: '{action} đã huỷ',
    CONFIRM_ACTION_ITEM: 'Bạn có chắc chắn muốn {action} {item}?',
    CONFIRM_DELETE_ITEM: 'Bạn có chắc chắn muốn xoá {item}?',
    DELETE_THIS: 'Xoá?',
    SELECT_ITEM: 'Chọn {{item}}',
    INPUT_ITEM: 'Nhập {{item}}',
    ITEM_REQUIRED: '{{item}} là bắt buộc',
    ITEM_MUST_BE_ATLEAST_VALUE: '{{item}} tối thiểu là {{value}}',
    YOU_HAVE_UNSAVED_CHANGE: 'Bạn có muốn lưu thay đổi trước khi thoát?'
  }
};

export default translations;
