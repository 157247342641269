import '@ant-design/compatible/assets/index.css';

import { Button, Col, Modal, Row } from 'antd';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import useForm from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Form } from '@ant-design/compatible';
import { LICENSE, MGN } from '@source/common';

import UIField from '~components/UI/UIField';
import { UIInputNumber } from '~components/UI/UIInputNumber';
import { useStore } from '~context/react-context';
import { SystemInfoInput } from '~graphql/_sdk';

import store from './store';

interface FormValues {
  name: string;
  licenseName?: string;
  noOfAccessPoint?: number;
  noOfRouter?: number;
}

const SystemInfoForm: FC = observer(() => {
  const { t } = useTranslation();
  const { selectedItem, updateSystemInfo, modalSystemInfoVisible, setModalVisible } = store;

  const {
    userStore: { currentUser }
  } = useStore();

  const nameField = currentUser.ownerType === MGN ? 'name' : 'licenseName';
  const initialValues: FormValues = {
    name: get(selectedItem, nameField),
    noOfAccessPoint: get(selectedItem, 'noOfAccessPoint', 0),
    noOfRouter: get(selectedItem, 'noOfRouter', 0)
  };

  const formProps = useForm<FormValues>({});
  const {
    formState: { isSubmitting },
    handleSubmit,
    reset
  } = formProps;

  const onCancel = () => {
    setModalVisible(null, 'systemInfo');
    reset(initialValues);
  };

  const footer = (
    <>
      <Button type="ghost" onClick={() => onCancel()}>
        {t('CLOSE')}
      </Button>
      <Button htmlType="submit" form="SystemInfoForm" loading={isSubmitting} type="primary">
        UPDATE
      </Button>
    </>
  );
  const onSubmit = async (record: FormValues) => {
    if (currentUser.ownerType === LICENSE) {
      record.licenseName = record.name;
      delete record.name;
    }
    const data = Object.assign(record);
    if (selectedItem) {
      await updateSystemInfo(get(selectedItem, '_id'), data as SystemInfoInput);
      onCancel();
    }
  };

  return (
    <Modal
      title={<strong>{initialValues.name || 'System Location'}</strong>}
      onCancel={() => onCancel()}
      closable={false}
      visible={modalSystemInfoVisible}
      footer={footer}
      width={800}
      style={{ top: 20 }}
      okText="Save"
    >
      <Form id="SystemInfoForm" onSubmit={handleSubmit(onSubmit)} layout="vertical">
        <Row gutter={8}>
          <Col span={12}>
            <UIField<UIInputNumber>
              {...formProps}
              name="noOfAccessPoint"
              label={t('NO_OF_AP')}
              fieldComponent={UIInputNumber}
              defaultValue={initialValues.noOfAccessPoint}
            />
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <UIField<UIInputNumber>
              {...formProps}
              name="noOfRouter"
              label={t('NO_OF_ROUTER')}
              fieldComponent={UIInputNumber}
              defaultValue={initialValues.noOfRouter}
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
});

export default SystemInfoForm;
