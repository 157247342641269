import { ConfigProvider, Empty } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router } from 'react-router-dom';

import Screen from '~screens/index';

const App: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Router>
      <ConfigProvider renderEmpty={() => <Empty description={t('common.NO_DATA')} />}>
        <div className="basic-layout">
          <Screen />
        </div>
      </ConfigProvider>
    </Router>
  );
};

export default App;
