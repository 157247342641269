import { intersection } from 'lodash';
import React, { FC, ReactElement } from 'react';

import { PERMISSION_SUPERADMIN, ROLE_SUPERADMIN } from '@source/common';

import { useStore } from '~context/react-context';

interface AuthorizedProps {
  roles: string[];
  unAuthorizedComponent?: ReactElement;
  children?: ReactElement;
  onClick?: Function;
  condition?: boolean;
}

const isAuthorizedRoles = (roles: string[], roleIds: string[]) => {
  if (roleIds.includes(ROLE_SUPERADMIN)) return true;

  const checkRoles = intersection(roleIds, roles);
  return checkRoles.length > 0;
};

export const useAuthorized = (roles: string[]) => {
  const {
    userStore: { roleIds }
  } = useStore();

  return isAuthorizedRoles(roles, roleIds);
};

const Authorized: FC<AuthorizedProps> = props => {
  const { children, unAuthorizedComponent, roles, condition, ...remaining } = props;
  const {
    userStore: { roleIds }
  } = useStore();
  if (!children) return null;
  if (children.props.onClick) delete remaining.onClick;
  const rendered = React.cloneElement(children, remaining);
  const passedCondition = condition || true;
  const authorized = isAuthorizedRoles(roles, roleIds);

  if (passedCondition && authorized) {
    return rendered;
  }

  return unAuthorizedComponent || null;
};

export default Authorized;
