import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ReactResizeDetector from 'react-resize-detector';

import { ColDef } from '@ag-grid-community/core';

import AgGridServer from '~components/UI/AgGrid/AgGridServer';
import { useGraphQLSDK } from '~graphql/GraphQLSDKProvider';

import store from './store';
import { DATE_TIME_FORMAT, LogsType, WhereArgs } from '@source/common';
import { format } from '~utils/formatDate';

const LogsGridCampaignConfig: FC = observer(() => {
  const { t } = useTranslation();
  const sdk = useGraphQLSDK();
  const { handleGridCampaignConfigReady, whereCampaignConfig } = store;

  const columnDefs: ColDef[] = [
    {
      headerName: t('NO'),
      field: '_id',
      minWidth: 50,
      maxWidth: 50,
      cellRenderer: ({ rowIndex }) => rowIndex + 1
    },
    {
      headerName: t('TYPE'),
      field: 'type',
      minWidth: 120,
      cellRenderer: ({ value }) => {
        let result = '';
        if (value === LogsType.CAMPAIGN_CONFIG) {
          result = t('CAMPAIGN_CONFIG');
        }
        return result;
      }
    },
    {
      headerName: t('DATE'),
      field: 'createdAt',
      sort: 'desc',
      minWidth: 150,
      maxWidth: 150,
      cellRenderer: ({ value }) => format(value, DATE_TIME_FORMAT)
    },
    {
      headerName: t('USER'),
      field: 'user.name',
      minWidth: 150
    },
    {
      headerName: t('EMAIL'),
      field: 'user.email',
      minWidth: 150
    },
    {
      headerName: t('RESOURCE'),
      field: 'resourceCampaign.name',
      minWidth: 110,
      cellRenderer: ({ value }) => {
        return `${t('CAMPAIGN_NAME')}: ${value}`;
      }
    },
    {
      headerName: t('OLD'),
      field: 'old',
      minWidth: 150,
      cellRenderer: ({ value }) => {
        let result = '';
        if (!value) {
          return '';
        }
        const { banners } = value;
        if (banners && banners.length) {
          banners.forEach((banner) => {
            result += `<p>${t('BANNER')}: ${banner.bannerName}<p>`;
          });
        }
        return result;
      }
    },
    {
      headerName: t('NEW'),
      field: 'new',
      minWidth: 150,
      cellRenderer: ({ value }) => {
        let result = '';
        if (!value) {
          return '';
        }
        const { banners } = value;
        if (banners && banners.length) {
          banners.forEach((banner) => {
            result += `<p>${t('BANNER')}: ${banner.bannerName}<p>`;
          });
        }
        return result;
      }
    },
    {
      headerName: t('DESCRIPTION'),
      field: 'description',
      minWidth: 110
    }
  ];

  return (
    <ReactResizeDetector handleHeight>
      {({ height }) => {
        return (
          <AgGridServer
            options={{
              columnDefs,
              cacheBlockSize: 50,
              paginationPageSize: 50,
              getRowHeight: () => 50
            }}
            onQuery={sdk.findFullLogs}
            queryKey="findFullLogs"
            onCountQuery={sdk.countLogs}
            countQueryKey="countLogs"
            onGridReady={handleGridCampaignConfigReady}
            where={whereCampaignConfig as WhereArgs}
            height={height}
          />
        );
      }}
    </ReactResizeDetector>
  );
});

export default LogsGridCampaignConfig;
