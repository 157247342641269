import { Button } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactResizeDetector from 'react-resize-detector';

import { ColDef, ColGroupDef } from '@ag-grid-community/core';
import { ScheduleOutlined } from '@ant-design/icons';

import Authorized from '~components/Authorized/Authorized';
import AgGridServer from '~components/UI/AgGrid/AgGridServer';

import store from './store';

interface GridProps {
  assignRoles?: string[];
}

const Grid = observer((props: GridProps) => {
  const {t} = useTranslation();
  const { setCurrentSelect, setIsShowModal, handleGridReady, where } = store;
  const { assignRoles } = props;
  const handleAssign = data => {
    setCurrentSelect(data);
    setIsShowModal(true);
  };

  const columnDefs: ColDef[] | ColGroupDef[] = [
    {
      headerName: 'Id',
      field: '_id',
      rowGroup: true,
      hide: true,
      minWidth: 250,
      cellRenderer: ({ data }) => data.name
    },
    {
      headerName: t('EMAIL'),
      field: 'email'
    },
    {
      headerName: t('RESOURCES'),
      field: 'resourceName',
      sortable: false,
    },
    {
      headerName: '',
      field: '_id',
      cellClass: 'text-center',
      minWidth: 100,
      maxWidth: 100,
      cellRendererFramework: ({ data }) => {
        const content =
          'userId' in data ? null : (
            <Authorized roles={assignRoles}>
              <Button icon={<ScheduleOutlined />} type="primary" onClick={() => handleAssign(data)} />
            </Authorized>
          );
        return <div>{content}</div>;
      }
    },
  ];
  
  return (
    <>
      <ReactResizeDetector handleHeight>
        {({ height }) => (
          <AgGridServer
            options={{ columnDefs, cacheBlockSize: 50, paginationPageSize: 50 }}
            onQuery={store.getUserOwner}
            queryKey="findManyUserWithOwner"
            onCountQuery={store.countUserOwner}
            countQueryKey="countManyUserWithOwner"
            onGridReady={handleGridReady}
            where={where}
            height={height - 80}
          />
        )}
      </ReactResizeDetector>
    </>
  );
});

export default Grid;
