/* eslint-disable jsx-a11y/anchor-is-valid */
import '@ant-design/compatible/assets/index.css';

import { Button, Checkbox, Col, Input, Row } from 'antd';
import { CheckboxProps } from 'antd/lib/checkbox';
import i18next from 'i18next';
import { observer } from 'mobx-react';
import React, { FC, MouseEvent } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import useForm from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Form } from '@ant-design/compatible';
import {
  HddOutlined,
  LockOutlined,
  MailOutlined,
  PhoneOutlined,
  UserOutlined
} from '@ant-design/icons';

import UIField from '~components/UI/UIField';
import { useConfig } from '~config/ConfigProvider';
import { UserRegisterInput } from '~graphql/_sdk';

import { form_login_fb, signup_button } from './css';
import RegisterConditionModal from './Modal';
import SignUpSuccessModal from './ModalSignUpSuccess';
import store from './store';
import useQuery from '~utils/useQuery';
import * as yup from 'yup';
import YupPassword from 'yup-password';

YupPassword(yup);

const { Item } = Form;

type FormValues = UserRegisterInput & {
  verifyPassword: string;
  agree?: boolean;
};

const SignupForm: FC = observer(() => {
  const {
    signUp,
    isRegisterConditionModalVisible,
    isSuccessModalVisible,
    toggleRegisterConditionModal,
    toggleSuccessModal
  } = store;

  const config = useConfig();
  const { t } = useTranslation();
  const query = useQuery();
  const referralId = query.get('referralId');

  const validationSchema = yup.object().shape<FormValues>({
    name: yup.string().required(i18next.t('REQF')),
    phone: yup
      .string()
      .matches(/^[0-9]+$/, `${i18next.t('MUST_BE_DIGITS')}`)
      .min(10, `${i18next.t('MINIMUM')}: 10`)
      .max(10, `${i18next.t('MAXIMUM')}: 10`)
      .required(i18next.t('REQF')),
    email: yup.string().email(i18next.t('INVALID_EMAIL')).required(i18next.t('REQF')),
    password: yup
      .string()
      .required(i18next.t('REQF'))
      .min(8, t('PASSWORD_RULES'))
      .minLowercase(1, t('PASSWORD_RULES'))
      .minUppercase(1, t('PASSWORD_RULES'))
      .minNumbers(1, t('PASSWORD_RULES'))
      .minSymbols(1, t('PASSWORD_RULES')),
    verifyPassword: yup
      .string()
      .oneOf([yup.ref('password')], i18next.t('VERIFY_PASSWORD_IS_NOT_EXACTLY'))
      .required(i18next.t('REQF')),
    recaptcha: yup.string().required(i18next.t('REQF')),
    agree: yup.boolean().oneOf([true], i18next.t('YOU_MUST_AGREE_WITH_CONDITION'))
  });

  const initValues: FormValues = {
    name: '',
    email: '',
    password: '',
    verifyPassword: '',
    recaptcha: '',
    referralId,
    agree: false
  };

  const formProps = useForm<FormValues>({ validationSchema });
  const {
    handleSubmit,
    formState: { isSubmitting },
    setValue,
    setError
  } = formProps;

  const onSubmit = async (record: FormValues) => {
    const { verifyPassword, agree, ...input } = record;
    const error = await signUp(input);

    if (error) {
      setError('email', 'required', t(`error.${error.code}`));
    } else {
      toggleSuccessModal();
    }
  };

  const setRecaptcha = (value: string) => {
    setValue('recaptcha', value);
  };

  const handleShowRegisterModal = (e: MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation();
    toggleRegisterConditionModal();
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <UIField
          {...formProps}
          name="name"
          fieldProps={{
            name: 'name',
            placeholder: `* ${t('INPUT_NAME')}`,
            prefix: <UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
          }}
          fieldComponent={Input}
          defaultValue={initValues.name}
        />
        <UIField
          {...formProps}
          name="phone"
          fieldProps={{
            name: 'phone',
            placeholder: t('INPUT_PHONE'),
            prefix: <PhoneOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
            // minLength: 10,
            // maxLength: 10
          }}
          fieldComponent={Input}
          defaultValue={initValues.phone}
        />
        <Row gutter={16}>
          <Col span={24}>
            <UIField
              {...formProps}
              name="company"
              fieldProps={{
                name: 'company',
                placeholder: t('INPUT_NAME_COMPANY'),
                prefix: <HddOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
              }}
              fieldComponent={Input}
              defaultValue={initValues.company}
            />
          </Col>

          {/* <Col span={9}>
          <UIField
            {...formProps}
            name="signature"
            fieldProps={{
              name: 'signature',
              placeholder: t('INPUT_SIGNATURE'),
              prefix: <HighlightOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
            }}
            fieldComponent={Input}
            defaultValue={initValues.signature}
          />
        </Col> */}
        </Row>
        <UIField
          {...formProps}
          name="email"
          fieldProps={{
            name: 'email',
            // type: 'email',
            placeholder: `* ${t('login:email')}`,
            prefix: <MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
          }}
          fieldComponent={Input}
          defaultValue={initValues.email}
        />
        <UIField
          {...formProps}
          name="password"
          fieldProps={{
            name: 'password',
            type: 'password',
            placeholder: `* ${t('INPUT_PASSWORD')}`,
            prefix: <LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
          }}
          fieldComponent={Input}
          defaultValue={initValues.password}
        />
        <small>{t('PASSWORD_RULES')}</small>
        <UIField
          {...formProps}
          name="verifyPassword"
          fieldProps={{
            name: 'verifyPassword',
            type: 'password',
            placeholder: `* ${t('INPUT_COMFIRM_PASSWORD')}`,
            prefix: <LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
          }}
          fieldComponent={Input}
          defaultValue={initValues.verifyPassword}
        />
        <UIField
          {...formProps}
          name="referralId"
          fieldProps={{
            name: 'referralId',
            type: 'text',
            placeholder: t('INPUT_REFERRAL'),
            prefix: <UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />,
            readOnly: !!initValues.referralId
          }}
          fieldComponent={Input}
          defaultValue={initValues.referralId}
        />
        <Item>
          <UIField<CheckboxProps>
            {...formProps}
            name="agree"
            fieldProps={{
              name: 'agree',
              children: [
                <>
                  {`* ${t('AGREE_WITH')}`}{' '}
                  <a onClick={handleShowRegisterModal}>{t('REGISTER_CONDITION')}</a>
                </>
              ]
            }}
            fieldComponent={Checkbox}
            checkbox
            defaultValue={initValues.agree}
          />

          {isRegisterConditionModalVisible && <RegisterConditionModal />}

          {!config.isTestEnv && (
            <ReCAPTCHA sitekey={config.recapchaSiteKey} onChange={setRecaptcha} />
          )}
          <UIField
            {...formProps}
            name="recaptcha"
            fieldProps={{
              name: 'recaptcha',
              type: 'hidden'
            }}
            fieldComponent={Input}
            defaultValue={config.isTestEnv ? 'test' : initValues.recaptcha}
          />

          <Button
            htmlType="submit"
            type="primary"
            // disabled={!isValid}
            loading={isSubmitting}
            className={signup_button}
          >
            {t('REGISTER')}
          </Button>

          <div className={form_login_fb}>
            <span>
              {t('YOU_HAVE_AN_ACCOUNT')} ?<Link to="/login"> {t('LOGIN')}</Link>
            </span>
          </div>
        </Item>
      </Form>
      {isSuccessModalVisible && <SignUpSuccessModal />}
    </>
  );
});

export default SignupForm;
