import { GraphQLClient } from 'graphql-request';

import authStore from '~common-stores/authStore';
import { getConfig } from '~config/index';
import { ReturnType } from '~utils/returnType';

import { getSdk as _sdk } from './_sdk';

export type GraphQLSDK = ReturnType<typeof _sdk>;

const AUTH_HEADER = 'Authorization';

class SDK {
  private client: Promise<GraphQLClient>;
  private sdk: Promise<GraphQLSDK>;

  public async getClient(): Promise<GraphQLClient> {
    if (this.client) return this.client;
    const clientConfig = await getConfig();
    const headers = authStore.token ? { [AUTH_HEADER]: `Bearer: ${authStore.token}` } : {};
    this.client = Promise.resolve(new GraphQLClient(clientConfig.graphQLEndpoint, { headers }));
    return this.client;
  }

  public async getSdk() {
    if (this.sdk) return this.sdk;
    const client = await this.getClient();
    this.sdk = Promise.resolve(_sdk(client));
    return this.sdk;
  }

  public async setAuthToken(token: string) {
    const client = await this.getClient();
    client.setHeader(AUTH_HEADER, token && `Bearer ${token}`);
  }
}

const sdk = new SDK();
const getClient = sdk.getClient.bind(sdk);
const getSdk = sdk.getSdk.bind(sdk);
const setAuthToken = sdk.setAuthToken.bind(sdk);

export { getClient, getSdk, setAuthToken };
export default sdk;
